<template>
    <collapsed-static id="pets-details" :title="$t('Pet(s) Details')">
        <add-pet v-for="(participant, index) in participants"
                 :participant="participant"
                 :added-client-id="addedClientId"
                 :key="index"
                 :index="index"
                 @petAdded="updateParticipant">
        </add-pet>
    </collapsed-static>
</template>

<script>
    import { mapState } from 'vuex';
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";
    import CollapsedStaticSection from '../Shared/Layout/CollapsedStaticSection.vue';
    import BookingAddPet from "./BookingAddPet.vue";

    export default {
        name: 'BookingAddPets',
        mixins: [BookingCacheMixin, StatusEmitterMixin],
        props: {
            id: ''
        },
        data() {
            return {
                error: '',
                loading: false,
                completed: false,
                addedParticipants: [],
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            bookingId() {
                return !!this.id ? this.id : '';
            },
            participants() {
                return this.bookingParticipants.length > 0 ? this.bookingParticipants.map(participant => ({
                    ...participant,
                    status: {
                        loading: false,
                        error: '',
                        completed: false
                    }
                })) : [];
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                };
            }
        },
        watch: {
            completed(value) {
                if (!!value) {
                    const simplifiedParticipants = this.participants.map(participant => ({
                        petId: participant.petId,
                        resourceId: participant.resourceId,
                        serviceItems: participant.serviceItems
                    }));
                    this.addedParticipants = simplifiedParticipants;
                    this.$emit('participantsAdded', simplifiedParticipants);
                }
            },
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            }
        },
        methods: {
            updateParticipant(value) {
                this.$set(this.participants, value.index, value.participant);
                this.updateStatus();
            },
            updateStatus() {
                this.loading = this.participants.some(p => p.status && !!p.status.loading);
                this.completed = this.participants.every(p => p.status && !!p.status.completed);
            }
        },
        components: {
            'collapsed-static': CollapsedStaticSection,
            'add-pet': BookingAddPet
        }
    }
</script>
