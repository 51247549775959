import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin"
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const CreditPacksCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CreditPacks', [
            'findCreditPacksCache'
        ]),
        creditTypeId() {
            console.log('OVERRIDE COMPUTED - creditTypeId');
            return 0;
        },
        creditPacksCacheKey() {
            return this.siteId + this.creditTypeId;
        },
        creditPacksCache() {
            return this.findCreditPacksCache(this.creditPacksCacheKey);
        },
        creditPacks() {
            return !!this.creditPacksCache ? this.creditPacksCache.values : [];
        },
        loadingCreditPacks() {
            return !!this.creditPacksCache && this.creditPacksCache.loading;
        },
        creditPacksRequestModel() {
            return {
                creditTypeId: this.creditTypeId
            }
        },
        creditPacksRequestModelValid() {
            const model = this.creditPacksRequestModel;
            return !!this.siteId && !!model.creditTypeId;
        }
    },
    watch: {
        creditPacksCacheKey() {
            this.refreshCreditPacks();
        }
    },
    methods: {
        ...mapActions('CreditPacks', [
            'findCreditPacks'
        ]),
        refreshCreditPacks() {
            if (!this.creditPacksCache || !!this.creditPacksCache.retry) {
                this.tryFindCreditPacks();
            }
        },
        tryFindCreditPacks() {
            const self = this;
            if (!self.creditPacksRequestModelValid) {
                return;
            }
            self.error = '';
            self.findCreditPacks({ key: self.creditPacksCacheKey, payload: self.creditPacksRequestModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCreditPacks();
    }
}
