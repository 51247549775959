import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper"
import { ErrorHelper } from "../../helpers/ErrorHelper"
import { SortableSearchMixin } from "./Searching/SortableSearchMixin"

export const EmployeeSearchMixin = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            timeoutId: null,
            searchTerm: '',
            skip: 0,
            take: 10
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'userAddedEvent',
            'userUpdatedEvent',
            'userDeletedEvent'
        ]),
        ...mapGetters('EmployeeSearches', [
            'findEmployeeSearch'
        ]),
        employeeSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                skip: this.skip,
                take: this.take,
                ascending: this.ascending
            }
        },
        employeeSearchKey() {
            return CacheHelper.objectCacheKey(this.employeeSearchModel);
        },
        employeeSearch() {
            return this.findEmployeeSearch(this.employeeSearchKey);
        },
        employees() {
            return !!this.employeeSearch ? this.employeeSearch.values : [];
        },
        searchingEmployees() {
            return !!this.employeeSearch && this.employeeSearch.searching;
        },
        finished() {
            return this.employees.length < this.employeeSearchModel.take;
        },
        pageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshEmployeeSearch();
            }, 500);
        },
        userAddedEvent() {
            this.clearAndRefresh();
        },
        userUpdatedEvent() {
            this.clearAndRefresh();
        },
        userDeletedEvent() {
            this.clearAndRefresh();
        },
        sortModel() {
            this.refreshEmployeeSearch();
        }
    },
    methods: {
        ...mapMutations('EmployeeSearches', [
            'clearSearches'
        ]),
        ...mapActions('EmployeeSearches', [
            'searchEmployees'
        ]),
        refreshEmployeeSearch() {
            if (!this.employeeSearch || !!this.employeeSearch.retry) {
                this.trySearchEmployees();
            }
        },
        trySearchEmployees() {
            const self = this;
            self.searchEmployees({ key: self.employeeSearchKey, payload: self.employeeSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        next() {
            if (!this.finished) {
                this.skip += this.take;
                this.refreshEmployeeSearch();
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshEmployeeSearch();
        },
        clearAndRefresh() {
            this.clearSearches();
            this.refreshEmployeeSearch();
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshEmployeeSearch();
        }
    },
    created() {
        this.refreshEmployeeSearch();
    }
}
