<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="number">{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteText" class="form-control" rows="3" cols="50" :placeholder="$t('Add notes (optional)')"></textarea>
        </div>
    </div>
</template>

<script>
    import { PetAddNoteMixin } from "../../Pet/PetAdd/PetAddNoteMixin";

    export default {
        name: 'OptionsPetNotes',
        mixins: [PetAddNoteMixin]
    }
</script>