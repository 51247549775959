import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SignatureCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Signatures', [
            'findSignatureCache'
        ]),
        contractId() {
            console.log('!!!OVERRIDE COMPUTED - contractId!!!');
        },
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        signatureCache() {
            return this.findSignatureCache(this.clientId);
        },
        signatures() {
            return !!this.signatureCache ? this.signatureCache.values : [];
        },
        signature() {
            return this.signatures.find(s => s.contractId === this.contractId);
        },
        loadingSignatures() {
            return !!this.signatureCache && this.signatureCache.loading;
        }
    },
    watch: {
        clientId() {
            this.refreshSignatures();
        }
    },
    methods: {
        ...mapActions('Signatures', [
            'searchSignatures'
        ]),
        refreshSignatures() {
            if (!this.signatureCache || !!this.signatureCache.retry) {
                this.trySearchSignatures();
            }
        },
        trySearchSignatures() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.searchSignatures(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSignatures();
    }
}
