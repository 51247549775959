<template>
    <modal-dialog title="Image gallery">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="tab-pane fade show active">
            <div @click="showFileSelect" class="file-upload">
                <label>{{$t('Upload photos')}}</label>
                <p class="mb-0">{{$t('Select or drop files here, max.')}} ({{maxUploadSize}})</p>
                <input ref="fileInput" @change="onFilePicked" type="file" style="display: none" accept="image/*" multiple="multiple" />
            </div>
            <div class="image-uploads">
                <ul>
                    <li v-for="upload in imagePreviewSet" :ref="upload.id" class="active">
                        <button @click="removeUpload(upload)" class="btn" type="button">
                            <i class="far fa-times"></i>
                        </button>
                        <figure>
                            <img :src="upload.url" width="120">
                        </figure>
                    </li>
                </ul>
            </div>
        </div>
        <div class="modal-footer">
            <button @click="save" class="btn btn-primary" v-bind:disabled="loadingImages" type="button">
                {{$t('Save')}}
                <span v-if="loadingImages" class="inline-loader"></span>
            </button>
            <button @click="clearUploads" class="btn btn-outline-secondary" type="button" data-dismiss="modal">{{$t('Cancel')}}</button>
            <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
        </div>
    </modal-dialog>
</template>

<script>
    import ModalDialog from '../Layout/ModalDialog.vue'
    import { ImageAddMixin } from '../Shared/Images/ImageAddMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'PetImageAdd',
        mixins: [ImageAddMixin],
        props: {
            pet: Object
        },
        computed: {
            ownerId() {
                return !!this.pet ? this.pet.id : '';
            },
            ownerTypeId() {
                return 3;
            }
        },
        watch: {
            imagesAddedEvent() {
                const button = this.$refs.closeModal;
                if (!!button) {
                    button.click();
                }
            }
        },
        methods: {
            showFileSelect() {
                this.$refs.fileInput.click();
            },
            save() {
                this.tryAddImages();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>
