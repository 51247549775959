<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-box-open fa-1x"></i>
                    {{$t('Resources')}}
                    <small>({{resourceCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <week-select v-model="date" class="d-md-block d-none"></week-select>
            </div>
            <div class="header-nav">
                <div class="d-block">
                    <button @click="openResourceAdd" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add resource</span>
                    </button>
                </div>
            </div>
            <div class="header-tools">
                <priority-order-btn @click="openPriorityOrder" class="d-md-block d-none"></priority-order-btn>
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button @click="openResourceAdd" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add resource')}}
                </button>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <div class="d-flex justify-content-between align-items-center">
                <priority-order-btn class="d-md-none d-block" @click="openPriorityOrder"></priority-order-btn>
                <week-select v-model="date" class="d-md-none d-block"></week-select>
            </div>
        </div>
        <resource-hours @resource-clicked="onResourceClicked" :date="date"></resource-hours>
        <resource-add ref="resourceAdd"></resource-add>
        <resource-update :resourceId="resourceId" ref="resourceUpdate"></resource-update>
        <priority-order ref="resourceOrder"></priority-order>
    </div>
</template>

<script>
    import router from "../../routing";
    import { Api } from "../../services/Api";
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { ResourceCountCacheMixin } from "../Shared/ResourceCountCacheMixin";
    import ResourceAdd from "./ResourceAdd.vue";
    import ResourceHours from "./ResourceHours/ResourceHours.vue";
    import ResourceUpdate from "./ResourceUpdate.vue";
    import ResourcePriorityOrderButton from "./ResourcePriorityOrderButton.vue"
    import ResourcePriorityOrder from "./ResourcePriorityOrder.vue";
    import SiteSelect from '../Account/SiteSelect.vue';
    import WeekSelector from "./ResourceHours/WeekSelector.vue";

    export default {
        name: 'Resources',
        mixins: [ResourceCountCacheMixin],
        data() {
            return {
                resource: null,
                resourceAddAllowed: false,
                fetchingResourceAddAllowed: false,
                date: new Date()
            }
        },
        computed: {
            resourceId() {
                return !!this.resource ? this.resource.id : '';
            },
            resourceCountString() {
                return this.resourceCount ? this.resourceCount.count : '-';
            }
        },
        methods: {
            openResourceAdd() {
                const self = this;

                self.fetchingResourceAddAllowed = true;

                return new Promise((resolve) => {
                    Api.get('Resource/Allowed')
                        .then(response => {
                            self.resourceAddAllowed = response.data.allowed;

                            if (self.resourceAddAllowed) {
                                const element = this.$refs.resourceAdd.$el;
                                $(element).modal('show');
                            }
                            else if (self.$route.name !== 'upsell') {
                                router.push({ name: 'upsell' });
                            }

                            resolve(response);
                        })
                        .catch(error => self.error = ErrorHelper.getError(error))
                        .finally(() => self.fetchingResourceAddAllowed = false);
                })
            },
            onResourceClicked(resource) {
                this.resource = resource;
                const element = this.$refs.resourceUpdate.$el;
                $(element).modal('show');
            },
            openPriorityOrder() {
                const element = this.$refs.resourceOrder.$el;
                $(element).modal('show');
            }
        },
        components: {
            'resource-hours': ResourceHours,
            'resource-add': ResourceAdd,
            'resource-update': ResourceUpdate,
            'priority-order-btn': ResourcePriorityOrderButton,
            'priority-order': ResourcePriorityOrder,
            'site-select': SiteSelect,
            'week-select': WeekSelector
        }
    }
</script>