import { mapGetters, mapActions } from 'vuex';
import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin";
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { HashHelper } from "../../helpers/HashHelper";

export const AppointmentGroupSearchMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentGroupSearches', [
            'findAppointmentGroupSearch'
        ]),
        ...mapGetters('AppointmentGroups', [
            'findAppointmentGroupCache'
        ]),
        appointmentGroupIds() {
            console.log('OVERRIDE COMPUTED - appointmentGroupIds');
            return [];
        },
        missingAppointmentGroupIds() {
            return this.appointmentGroupIds.filter(id => !this.findAppointmentGroupCache(id));
        },
        appointmentGroupSearchModel() {
            return {
                groupIds: this.missingAppointmentGroupIds
            }
        },
        appointmentGroupSearchModelValid() {
            const model = this.appointmentGroupSearchModel;
            return !!this.siteId && model.groupIds.length > 0;
        },
        appointmentGroupSearchKey() {
            return HashHelper.createStringHash(this.siteId + this.appointmentGroupIds.join('')).toString();
        },
        appointmentGroupSearch() {
            return this.findAppointmentGroupSearch(this.appointmentGroupSearchKey);
        },
        appointmentGroupCaches() {
            return this.appointmentGroupIds.map(id => this.findAppointmentGroupCache(id)).filter(agc => !!agc);
        },
        appointmentGroups() {
            return this.appointmentGroupCaches.map(agc => agc.value);
        },
        searchingAppointmentGroups() {
            return !!this.appointmentGroupSearch && this.appointmentGroupSearch.loading;
        }
    },
    watch: {
        appointmentGroupSearchKey() {
            this.refreshAppointmentGroupSearch();
        }
    },
    methods: {
        ...mapActions('AppointmentGroupSearches', [
            'searchAppointmentGroups'
        ]),
        refreshAppointmentGroupSearch() {
            if (!this.appointmentGroupSearch || !!this.appointmentGroupSearch.retry) {
                this.trySearchAppointmentGroups();
            }
        },
        trySearchAppointmentGroups() {
            const self = this;
            if (!self.appointmentGroupSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchAppointmentGroups({ key: self.appointmentGroupSearchKey, payload: self.appointmentGroupSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentGroupSearch();
    }
}
