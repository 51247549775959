<template>

</template>

<script>
    import { ImageCachesMixin } from "../Shared/Images/ImageCachesMixin";
    import { AllResourcesMixin } from "../Shared/Resources/AllResourcesMixin";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'ResourceImagesSearch',
        mixins: [AllResourcesMixin, ImageCachesMixin, StatusEmitterMixin],
        computed: {
            ownerIds() {
                return this.allResources.map(r => r.id);
            },
            ownerTypeId() {
                return 14;
            },
            status() {
                return {
                    loading: this.fetchingImages
                }
            }
        }
    }
</script>
