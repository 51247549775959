<template>
    <modal-dialog :title="subject" @opened="onOpened" @closed="onClosed">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-html="html"></div>
    </modal-dialog>
</template>

<script>
    import { ErrorHelper } from '../../../helpers/ErrorHelper';
    import { Api } from '../../../services/Api';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'EmailPreview',
        mixins: [SiteIdMixin],
        data() {
            return {
                error: '',
                loading: false,
                subject: '',
                html: '',
                opened: false
            }
        },
        props: {
            communicationTypeId: Number
        },
        computed: {
            previewSearchModel() {
                return this.opened ? {
                    communicationTypeId: this.communicationTypeId,
                    siteId: this.siteId
                } : {};
            },
            previewSearchModelValid() {
                return !!this.previewSearchModel.communicationTypeId && !!this.previewSearchModel.siteId;
            }
        },
        watch: {
            previewSearchModel() {
                this.refreshEmailPreview();
            }
        },
        methods: {
            refreshEmailPreview() {
                const self = this;
                self.subject = '';
                self.html = '';
                if (!self.previewSearchModelValid) {
                    return;
                }
                self.error = '';
                self.loading = true;
                Api.post('Communication/Email/Preview', self.previewSearchModel)
                    .then(response => {
                        self.subject = response.data.subject;
                        self.html = response.data.body;
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            onOpened() {
                this.opened = true;
            },
            onClosed() {
                this.opened = false;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>
