<template>
    <div class="table-responsive">
        <table class="table table-striped ps-table table-hover">
            <thead>
                <tr>
                    <th>{{$t('Date')}}</th>
                    <th>{{$t('Time')}}</th>
                    <th class="text-center">{{$t('Status')}}</th>
                    <th>{{$t('Created')}}</th>
                    <th class="text-center">{{$t('View')}}</th>
                </tr>
            </thead>
            <tbody>
                <appointment-item v-for="appointment in appointments" :key="appointment.id" :appointment="appointment" :status-class="getStatusClass(appointment)"></appointment-item>
            </tbody>
        </table>
        <button v-if="more" @click="searchAndCacheAppointments" type="button" class="btn btn-outline-secondary" v-bind:disabled="loadingAppointments || !more">
            {{$t('Load more')}}
            <span v-if="loadingAppointments" class="inline-loader"></span>
        </button>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AppointmentSearchMixin } from "../../Shared/AppointmentSearchMixin";
    import PetAppointmentSectionItem from "./PetAppointmentSectionItem.vue";

    export default {
        name: 'PetAppointmentsTable',
        mixins: [AppointmentSearchMixin, StatusEmitterMixin],
        props: {
            petId: String
        },
        computed: {
            searchModel() {
                return { petId: this.petId };
            },
            searchModelValid() {
                return !!this.searchModel.petId;
            },
            status() {
                return {
                    loading: this.loadingAppointments,
                    error: this.error
                }
            }
        },
        components: {
            'appointment-item': PetAppointmentSectionItem
        }
    }
</script>
