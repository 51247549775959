<template>
    <div ref="displayContainerRef" class="display-container">
        <span class="display-summary">{{ finalString }}</span>
        <div ref="displayBadgeRef" class="d-flex align-items-center">
            <div v-if="numTruncated > 0" class="display-badge">+{{numTruncated}}</div>
            <i class="far fa-chevron-down ml-1"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MultiSelectDropdownDisplay',
        props: {
            values: Array
        },
        data() {
            return {
                numTruncated: 0,
                finalString: ''
            };
        },
        computed: {
            displayContainerRef() {
                return this.$refs.displayContainerRef;
            }
        },
        watch: {
            values() {
                this.updateDisplay();
            }
        },
        methods: {
            calculateBadgeWidth(numTruncated) {
                const badgeContainer = this.$refs.displayBadgeRef;
                if (!badgeContainer) return 0;

                const tempBadge = document.createElement('div');
                tempBadge.classList.add('display-badge');
                tempBadge.innerText = `+${numTruncated }`;
                badgeContainer.appendChild(tempBadge);
                const width = tempBadge.clientWidth;
                badgeContainer.removeChild(tempBadge);

                return width;
            },
            updateDisplay() {
                if (this.values.length === 1) {
                    this.numTruncated = 0;
                    this.finalString = this.values[0];
                    return;
                }

                if (!this.displayContainerRef) return;
                const containerWidth = this.displayContainerRef.clientWidth;

                let currentValues = [...this.values];
                let joinedString = currentValues.join(', ');

                const tempSpan = document.createElement('span');
                tempSpan.style.visibility = 'hidden';
                tempSpan.style.position = 'absolute';
                tempSpan.style.whiteSpace = 'nowrap';
                document.body.appendChild(tempSpan);

                let availableWidth = containerWidth;

                for (let i = 0; i < this.values.length; i++) {
                    tempSpan.innerText = joinedString;
                    const joinedWidth = tempSpan.clientWidth;

                    if (joinedWidth <= availableWidth) {
                        this.numTruncated = this.values.length - currentValues.length;
                        break;
                    }

                    if (currentValues.length > 1) {
                        currentValues.pop();
                        this.numTruncated = this.values.length - currentValues.length;
                        const badgeWidth = this.calculateBadgeWidth(this.numTruncated);
                        availableWidth = containerWidth - badgeWidth;
                        joinedString = currentValues.join(', ') + ', ...';
                    } else {
                        joinedString = currentValues[0];
                        this.numTruncated = this.values.length - 1;
                        break;
                    }
                }

                this.finalString = joinedString;
                document.body.removeChild(tempSpan);
            }
        },
        mounted() {
            this.updateDisplay();
        }
    };
</script>

<style scoped>
    .display-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .display-summary {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .display-badge {
        padding: .1rem .1rem;
        font-size: .75rem;
        border-radius: .15rem;
        background-color: #f4457b;
        color: white;
    }
</style>