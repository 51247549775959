import { mapState, mapMutations } from 'vuex';
import { TimeZoneCacheMixin } from './TimeZoneCacheMixin';

export const TimeZoneMixin = {
    mixins: [TimeZoneCacheMixin],
    computed: {
        ...mapState('TimeZones', [
            'selectedTimeZoneId'
        ])
    },
    watch: {
        timeZone() {
            this.refreshSelectedTimeZone();
        }
    },
    methods: {
        ...mapMutations('TimeZones', [
            'setSelectedTimeZone'
        ]),
        refreshSelectedTimeZone() {
            if (this.timeZone) {
                this.setSelectedTimeZone(this.timeZone.timeZoneId);
            }
        }
    },
    created() {
        this.refreshSelectedTimeZone();
    }
}
