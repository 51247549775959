import { mapState, mapActions } from 'vuex';
import { SiteIdMixin } from '../../components/Shared/Site/SiteIdMixin';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ReferrerCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Referrers', [
            'fetchedReferrer',
            'fetchingReferrer',
            'referrer'
        ]),
        referrerCode() {
            return !!this.referrer ? this.referrer.code : '';
        },
        referrerScore() {
            return !!this.referrer ? this.referrer.score : 0;
        }
    },
    watch: {
        siteId() {
            this.refreshReferrer();
        }
    },
    methods: {
        ...mapActions('Referrers', [
            'fetchReferrer'
        ]),
        tryFetchReferrer() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchReferrer()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshReferrer() {
            if (!this.fetchedReferrer && !this.fetchingReferrer) {
                this.tryFetchReferrer();
            }
        }
    },
    created() {
        this.refreshReferrer();
    }
}
