<template>
    <tr @click="gotoClient" class="link pointer" v-bind:class="classType">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <div class="text-truncate">{{firstName}}</div>
            </div>
        </td>
        <td class="text-truncate">{{lastName}}</td>
        <td class="text-truncate">{{mobileCell}}</td>
        <td class="text-truncate">{{emailCell}}</td>
        <td class="text-truncate" v-if="displaySite">{{siteName}}</td>
        <td class="text-truncate">{{petString}}</td>
    </tr>
</template>

<script>
    import { ClientItemMixin } from './ClientItemMixin';

    export default {
        name: 'ClientItemDesktop',
        mixins: [ClientItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
