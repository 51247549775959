<template>
    <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
        <ul class="dropdown-menu inner show" role="presentation">
            <li v-for="breed in breeds" :key="breed.id">
                <a @click="clicked(breed)" role="option" class="dropdown-item pointer" v-bind:class="{ 'active selected': breed.id===selectedId }">
                    <span class="text">
                        {{breed.name}}
                    </span>
                </a>
            </li>
            <li v-if="breeds.length === 0">
                <a role="option" class="dropdown-item">
                    No results found
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'BreedSearchResults',
        props: {
            breeds: Array,
            selectedId: String
        },
        methods: {
            clicked(breed) {
                this.$emit('clicked', breed);
            }
        }
    }
</script>
