import { mapActions, mapState } from "vuex";
import { BreedHelper } from "../../helpers/BreedHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { BreedCacheMixin } from "./BreedCacheMixin";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const BreedUpdateMixin = {
    mixins: [BreedCacheMixin, SiteIdMixin],
    data() {
        return {
            name: '',
            speciesId: '',
            breedValidator: BreedHelper.getBreedUpdateValidator()
        };
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'breedUpdatedEvent'
        ]),
        breedUpdateModel() {
            return !!this.breed ? Object.assign({}, this.breed, {
                name: this.name,
                speciesId: this.speciesId
            }) : {};
        },
        breedUpdateModelValid() {
            return this.breedValidator.isValid;
        },
        breedUpdateModelModified() {
            return JSON.stringify(this.breed) !== JSON.stringify(this.breedUpdateModel);
        }
    },
    watch: {
        breed() {
            this.refreshBreedUploadModel();
        }
    },
    methods: {
        ...mapActions('Breeds', [
            'updateBreed',
            'deleteBreed'
        ]),
        refreshBreedUploadModel() {
            this.name = !!this.breed ? this.breed.name : '';
            this.speciesId = !!this.breed ? this.breed.speciesId : '';
        },
        tryUpdateBreed() {
            const self = this;
            if (!self.breed) {
                return;
            }
            if (!self.breedUpdateModelModified) {
                return;
            }
            self.breedValidator.check(self.breedUpdateModel);
            if (!self.breedUpdateModelValid) {
                return;
            }
            self.error = '';
            self.updateBreed({ key: self.breed.id, payload: self.breedUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteBreed() {
            const self = this;
            if (!self.breed) {
                return;
            }
            self.error = '';
            self.deleteBreed({ key: self.siteId, id: self.breed.id })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBreedUploadModel();
    }
}
