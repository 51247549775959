<template>
    <div>
        <site-details v-model="siteDetailsStatus"></site-details>
        <site-branding v-model="siteBrandingStatus"></site-branding>
        <site-location v-model="siteLocationStatus"></site-location>
        <site-localization v-model="siteLocalizationStatus"></site-localization>
        <site-appointment v-model="siteAppointmentStatus"></site-appointment>
        <site-resources v-model="siteResourceStatus"></site-resources>
        <site-contact v-model="siteContactStatus"></site-contact>
        <site-tax v-model="siteTaxStatus"></site-tax>
        <site-personalization v-model="sitePersonalizationStatus"></site-personalization>
        <site-credits v-model="siteCreditsStatus"></site-credits>
        <site-booking v-model="siteBookingStatus"></site-booking>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <save-button :status="status" @clicked="triggerSaveSite"></save-button>
            <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
        </div>
    </div>
</template>

<script>
    import SaveButton from "../../Shared/Buttons/SaveButton.vue";
    import SiteUpdateAppointment from "./SiteUpdateAppointment.vue";
    import SiteUpdateBranding from "./SiteUpdateBranding.vue";
    import SiteUpdateContact from "./SiteUpdateContact.vue";
    import SiteUpdateCredits from "./SiteUpdateCredits.vue";
    import SiteUpdateDetails from "./SiteUpdateDetails.vue";
    import SiteUpdateLocalization from "./SiteUpdateLocalization.vue";
    import SiteUpdateLocation from "./SiteUpdateLocation.vue";
    import SiteUpdatePersonalization from "./SiteUpdatePersonalization.vue";
    import SiteUpdateTax from "./SiteUpdateTax.vue";
    import SiteUpdateBooking from "./SiteUpdateBooking.vue";
    import SiteUpdateResources from "./SiteUpdateResources.vue";
    import { mapMutations } from 'vuex';

    export default {
        name: 'SiteUpdateV2',
        data() {
            return {
                siteDetailsStatus: {},
                siteBrandingStatus: {},
                siteLocationStatus: {},
                siteLocalizationStatus: {},
                siteAppointmentStatus: {},
                siteContactStatus: {},
                siteTaxStatus: {},
                sitePersonalizationStatus: {},
                siteCreditsStatus: {},
                siteBookingStatus: {},
                siteResourceStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.siteDetailsStatus,
                    this.siteBrandingStatus,
                    this.siteLocationStatus,
                    this.siteLocalizationStatus,
                    this.siteAppointmentStatus,
                    this.siteContactStatus,
                    this.siteTaxStatus,
                    this.sitePersonalizationStatus,
                    this.siteCreditsStatus,
                    this.siteBookingStatus,
                    this.siteResourceStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.some(s => s.error)
                }
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveSite'
            ]),
            cancel() {
                this.$router.push({ name: 'sites' })
            }
        },
        components: {
            'site-details': SiteUpdateDetails,
            'site-branding': SiteUpdateBranding,
            'site-location': SiteUpdateLocation,
            'site-localization': SiteUpdateLocalization,
            'site-appointment': SiteUpdateAppointment,
            'site-contact': SiteUpdateContact,
            'site-tax': SiteUpdateTax,
            'site-personalization': SiteUpdatePersonalization,
            'site-credits': SiteUpdateCredits,
            'site-booking': SiteUpdateBooking,
            'site-resources': SiteUpdateResources,
            'save-button': SaveButton
        }
    }
</script>
