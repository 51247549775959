import { SubscriptionCacheMixin } from "./SubscriptionCacheMixin";
import { SubscriptionSettingsCacheMixin } from "./SubscriptionSettingsCacheMixin";

export const SubscriptionFeatureMixin = {
    mixins: [SubscriptionCacheMixin, SubscriptionSettingsCacheMixin],
    props: {
        // this prop is used by the NavPanelDesktop and NavPanelExpand to display whether or not the badge
        featureKey: {
            type: String,
            default: ''
        }
    },
    computed: {
        // this property is overriden on pages where subscription feature has to be checked 
        computedFeatureKey() {
            return this.featureKey;
        },
        subscriptionFeatureFound() {
            return !!this.subscriptionFeatures && !!this.subscriptionFeatures.hasOwnProperty(this.computedFeatureKey);
        },
        subscriptionFeatureValue() {
            return this.subscriptionFeatureFound ? this.subscriptionFeatures[this.computedFeatureKey] : true;
        },
        subscriptionFeatureAllowed() {
            if (this.computedFeatureKey !== '') {
                return this.subscriptionFeatureValue;
            }
            else {
                return true;
            }
        },
        // this 2 properties can be moved to the NavLinkMixin because these are specific to the displaying or not the badge in Navs
        showUpgradeBadgeSetting() {
            return this.subscriptionSettings && this.subscriptionSettings.hasOwnProperty('showUpgradeBadge') ? this.subscriptionSettings['showUpgradeBadge'] : false;
        },
        displayUpgradeBadge() {
            return !!this.showUpgradeBadgeSetting && !this.subscriptionFeatureAllowed;
        }
    }
}