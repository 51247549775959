<template>
    <tr @click="gotoBooking" class="link pointer" v-bind:class="classType">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <div class="text-truncate">{{firstName}}</div>
            </div>
        </td>
        <td class="text-truncate">{{lastName}}</td>
        <td class="text-truncate">{{mobile}}</td>
        <td class="text-truncate">{{email}}</td>
        <td class="text-truncate">{{clientTypeDescription}}</td>
        <td class="text-truncate">{{createdDateTime}}</td>
        <td class="text-truncate">{{appointmentDateTime}}</td>
        <td class="text-truncate">{{statusDescription}}</td>
    </tr>
</template>

<script>
    import { BookingItemMixin } from './BookingItemMixin';

    export default {
        name: 'BookingItemDesktop',
        mixins: [BookingItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
