import { SettingCacheMixin } from "../../components/Shared/SettingCacheMixin";
import { SiteDepositSettingMixin } from "../Settings/Site/SiteDepositSettingMixin";

export const DepositCalculatorMixin = {
    mixins: [SettingCacheMixin, SiteDepositSettingMixin],
    data() {
        return {
            amount: 0
        }
    },
    props: {
        total: Number
    },
    computed: {
        defaultAmount() {
            return this.settingObject.isPercentage ?
                this.total * this.settingObject.percentage * 0.01 :
                this.settingObject.fixedAmount;
        }
    },
    watch: {
        defaultAmount(value) {
            this.amount = value;
        }
    },
    created() {
        this.amount = this.defaultAmount;
    }
}
