import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const HistoriesCacheMixin = {
    props: {
        clientIds: Array
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Histories', [
            'fetchingHistories'
        ]),
        historySearchModel() {
            return {
                clientIds: this.clientIds
            }
        },
        historySearchModelValid() {
            const model = this.historySearchModel;
            return model.clientIds.length > 0;
        }
    },
    watch: {
        historySearchModel() {
            this.refreshHistories();
        }
    },
    methods: {
        ...mapActions('Histories', [
            'searchHistories'
        ]),
        refreshHistories() {
            if (!this.fetchingHistories) {
                this.trySearchHistories();
            }
        },
        trySearchHistories() {
            const self = this;
            if (!self.historySearchModelValid) {
                return;
            }
            self.error = '';
            self.searchHistories({ keys: self.clientIds, payload: self.historySearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshHistories();
    }
}
