import { CacheHelper } from '../../helpers/CacheHelper';
import { Api } from '../../services/Api';

const state = () => ({
    imageCaches: []
})

const getters = {
    findImageCache: (state) => (key) => {
        return state.imageCaches.find(cc => cc.key === key);
    }
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.imageCaches.push({ key: key, values: [], loading: !!loading });
            }
        }
    },
    setImages(state, { keys, images }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const ownerImages = images.filter(i => CacheHelper.ownerCacheKey(i) === key);
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.values = ownerImages;
                existing.loading = false;
            } else {
                state.imageCaches.push({ key: key, values: ownerImages, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    },
    appendImages(state, { key, images }) {
        var existing = state.imageCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = existing.values.concat(images);
        } else {
            state.imageCaches.push({ key: key, values: images, loading: false });
        }
    },
    removeImage(state, { key, id }) {
        var existing = state.imageCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(i => i.id !== id);
        }
    }
}

const actions = {
    searchImages({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            Api.post('Image/Search', payload)
                .then(response => {
                    commit('setImages', { keys: keys, images: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', keys);
                    reject(error);
                });
        });
    },
    addImages({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [key], loading: true });
            var form = new FormData();
            form.append('ownerId', payload.ownerId);
            form.append('ownerTypeId', payload.ownerTypeId);
            for (var i = 0; i < payload.files.length; i++) {
                form.append('files', payload.files[i]);
            }
            const config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            Api.post('Image', form, config)
                .then(response => {
                    commit('appendImages', { key: key, images: response.data });
                    commit('ApplicationEvents/triggerImagesAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [key], loading: false }));
        });
    },
    deleteImage({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [key], loading: true });
            Api.delete('Image/' + id)
                .then(response => {
                    commit('removeImage', { key: key, id: id });
                    commit('ApplicationEvents/triggerImageDeleted', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [key], loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
