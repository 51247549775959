import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const StripoDefaultTemplateSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('StripoDefaultTemplateSettings', [
            'loadingStripoDefaultTemplateSettings',
            'loadedStripoDefaultTemplateSettings',
            'stripoDefaultTemplateSettings'
        ])
    },
    methods: {
        ...mapActions('StripoDefaultTemplateSettings', [
            'getStripoDefaultTemplateSettings'
        ]),
        refreshStripoDefaultTemplateSettings() {
            if (!this.loadingStripoDefaultTemplateSettings && !this.loadedStripoDefaultTemplateSettings) {
                this.tryGetStripoDefaultTemplateSettings();
            }
        },
        tryGetStripoDefaultTemplateSettings() {
            const self = this;
            self.getStripoDefaultTemplateSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshStripoDefaultTemplateSettings();
    }
}
