import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { LogosCacheMixin } from "./LogosCacheMixin";

export const LogoDeleteMixin = {
    mixins: [LogosCacheMixin],
    methods: {
        ...mapActions('Logos', [
            'deleteLogo'
        ]),
        tryDeleteLogo() {
            const self = this;
            if (!self.logo) {
                return;
            }
            self.error = '';
            self.deleteLogo({ id: self.logo.id, siteId: self.logo.siteId })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
