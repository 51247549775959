<template>
    <div class="input-group form-group">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="fal fa-envelope"></i></span>
        </div>
        <input v-model="email" type="email" class="form-control authBorder" :placeholder="$t('Email')" aria-label="Email">
    </div>
</template>

<script>
import { EmailHelper } from "../../../helpers/EmailHelper";

    export default {
        name: 'EmailInput',
        props: {
            value: String
        },
        data() {
            return {
                email: this.value
            }
        },
        watch: {
            email(newValue) {
                const valid = EmailHelper.isValid(newValue);
                this.$emit('input', valid ? this.email : '');
            }
        }
    }
</script>
