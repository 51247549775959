<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";
    import { ResourceCacheMixin } from "../../../Shared/Resources/ResourceCacheMixin";

    export default {
        name: 'AppointmentRebookResources',
        mixins: [ResourceCacheMixin, StatusEmitterMixin],
        props: {
            appointment: Object
        },
        computed: {
            siteId() {
                return !!this.appointment ? this.appointment.siteId : '';
            },
            status() {
                return {
                    completed: this.completedResources,
                    loading: this.loadingResources,
                    error: this.error,
                    resources: this.resources
                }
            }
        }
    }
</script>
