import { ArrayHelper } from "../../helpers/ArrayHelper";
import { CacheHelper } from "../../helpers/CacheHelper";
import { Api } from "../../services/Api";

const state = () => ({
    addressSearches: []
})

const getters = {
    findAddressSearch: (state) => key => state.addressSearches.find(as => as.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.addressSearches.find(as => as.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.addressSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setAddresses(state, { key, addresses }) {
        const existing = state.addressSearches.find(as => as.key === key);
        if (!!existing) {
            existing.values = addresses;
            existing.loading = false;
        } else {
            state.addressSearches.push({ key: key, values: addresses, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.addressSearches.find(as => as.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchAddresses({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Address/Search', payload)
                .then(response => {
                    commit('setAddresses', { key: key, addresses: response.data });
                    const keyAddresses = response.data.map(a => ({ key: CacheHelper.ownerCacheKey(a), address: a }));
                    const groups = ArrayHelper.groupBy(keyAddresses, 'key');
                    for (var i = 0; i < groups.length; i++) {
                        const addresses = groups[i].values.map(ka => ka.address);
                        commit('Addresses/setAddresses', { key: groups[i].key, addresses: addresses }, { root: true });
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
