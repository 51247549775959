<template>
    <section>
        <div class="title-divider">
            <h2>{{orderItem.name}}</h2>
        </div>
        <div class="row form-group">
            <div class="col-6">
                <label>{{$t('Price')}} ({{currencySymbol}}):</label>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <input v-model="amountInput" @blur="processInput" class="form-control short-input text-right" type="text">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-6">
                <label>{{$t('Quantity:')}}</label>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <input v-model="quantity" class="form-control short-input text-right" type="number" min="1">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-6">
                <label>{{$t('Tax')}}:</label>
            </div>
            <div class="col-6 text-right">
                {{payableTaxString}}
            </div>
        </div>
    </section>
</template>

<script>
    import { ProductInputMixin } from "../../Products/ProductInputMixin";
    import { AppointmentTotalMixin } from "../../Shared/AppointmentTotalMixin";

    export default {
        name: 'AppointmentOverviewOrderItem',
        mixins: [AppointmentTotalMixin, ProductInputMixin],
        props: {
            value: Array,
            index: Number
        },
        computed: {
            orderItem: {
                get() {
                    return this.value[this.index];
                },
                set(value) {
                    const items = [...this.value];
                    items.splice(this.index, 1, value);
                    this.$emit('input', items);
                }
            },
            orderItems() {
                return [this.orderItem];
            },
            quantity: {
                get() {
                    return this.orderItem.quantity;
                },
                set(value) {
                    this.orderItem = Object.assign({}, this.orderItem, {
                        quantity: value
                    });
                }
            }
        },
        watch: {
            orderItem() {
                this.setInitial();
            },
            amount(value) {
                this.orderItem = Object.assign({}, this.orderItem, {
                    amount: value
                });
            }
        },
        methods: {
            setInitial() {
                this.setInitialAmount(parseFloat(this.orderItem.amount));
            }
        },
        created() {
            this.setInitial();
        }
    }
</script>
