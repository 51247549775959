import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const CountryOptionCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CountryOptions', [
            'findCountryOptionCache'
        ]),
        countryId() {
            console.log('OVERRIDE COMPUTED - countryId');
            return '';
        },
        countryOptionCache() {
            return this.findCountryOptionCache(this.countryId);
        },
        countryOption() {
            return !!this.countryOptionCache ? this.countryOptionCache.value : null;
        },
        loadingCountryOption() {
            return !!this.countryOptionCache && this.countryOptionCache.loading;
        }
    },
    watch: {
        countryId() {
            this.refreshCountryOption();
        }
    },
    methods: {
        ...mapActions('CountryOptions', [
            'getCountryOption'
        ]),
        tryGetCountryOption() {
            const self = this;
            if (!self.countryId) {
                return;
            }
            self.error = '';
            self.getCountryOption(self.countryId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshCountryOption() {
            if (!this.countryOptionCache || !!this.countryOptionCache.retry) {
                this.tryGetCountryOption();
            }
        }
    },
    created() {
        this.refreshCountryOption();
    }
}
