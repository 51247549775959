import { PaymentHelper } from "../../helpers/PaymentHelper";
import moment from 'moment';
import { Api } from "../../services/Api";
import axios from 'axios';

const state = () => ({
    paymentTokenPromise: null
})

const mutations = {
    setPaymentTokenPromise(state, promise) {
        state.paymentTokenPromise = promise;
    },
    clearPaymentTokenPromise(state) {
        state.paymentTokenPromise = null;
    }
}

const actions = {
    fetchPaymentToken({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            const token = PaymentHelper.getToken();
            if (!!token && moment(token.expiresUtc).isAfter(moment())) {
                resolve(token);
            } else {
                if (!state.paymentTokenPromise) {
                    commit('setPaymentTokenPromise', new Promise((resolve, reject) => {
                        (!!token ? dispatch('refreshPaymentToken', token) : dispatch('loginPaymentUser'))
                            .then(response => resolve(response))
                            .catch(error => reject(error))
                            .finally(() => commit('clearPaymentTokenPromise'));
                    }));
                }
                state.paymentTokenPromise
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            }
        });
    },
    refreshPaymentToken({ dispatch }, token) {
        return new Promise((resolve, reject) => {
            axios.create({
                baseURL: PaymentHelper.getBaseUrl()
            }).put('User/Token/Refresh', token)
                .then(response => {
                    PaymentHelper.setToken(response.data);
                    resolve(response.data);
                })
                .catch(() => {
                    PaymentHelper.clearToken();
                    dispatch('loginPaymentUser')
                        .then(response => resolve(response))
                        .catch(error => reject(error));
                });
        });
    },
    loginPaymentUser() {
        return new Promise((resolve, reject) => {
            Api.post('PaymentUser/Login')
                .then(response => {
                    PaymentHelper.setToken(response.data);
                    resolve(response.data);
                })
                .catch(error => reject(error));
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
