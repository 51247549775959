import { Api } from "../../services/Api";

const state = () => ({
    upgradingSubscription: false,
    fetchedSubscription: false,
    fetchingSubscription: false,
    subscription: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSubscription = !!fetching;
        state.fetchedSubscription = false;
    },
    setSubscription(state, subscription) {
        state.subscription = subscription;
        state.fetchingSubscription = false;
        state.fetchedSubscription = true;
    },
    setUpgrading(state, upgrading) {
        state.upgradingSubscription = !!upgrading;
    }
}

const actions = {
    createTrial({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.post('Subscription')
                .then(response => {
                    commit('setSubscription', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    },
    fetchSubscription({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Subscription')
                .then(response => {
                    commit('setSubscription', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    },
    upgradeSubscription({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setUpgrading', true);
            Api.put('Subscription')
                .then(response => {
                    commit('setSubscription', response.data);
                    commit('SubscriptionUpgrade/clearSubscriptionUpgrade', null, { root: true });
                    commit('PaymentApiUser/clearPaymentApiUser', null, { root: true });
                    dispatch('SmsSettings/upgradeSmsSettings', null, { root: true });
                    dispatch('CampaignSettings/upgradeCampaignSettings', null, { root: true });
                    dispatch('EmailTemplateSettings/upgradeEmailTemplateSettings', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpgrading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
