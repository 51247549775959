<template>
    <collapsed-section id="site-credits" :title="$t('Credits')">
        <sms-credits v-model="smsCreditsStatus"></sms-credits>
        <campaign-credits v-model="campaignCreditsStatus"></campaign-credits>
        <email-credits v-model="emailCreditsStatus"></email-credits>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateCreditsCampaign from "./SiteUpdateCreditsCampaign.vue";
    import SiteUpdateCreditsEmail from "./SiteUpdateCreditsEmail.vue";
    import SiteUpdateCreditsSms from "./SiteUpdateCreditsSms.vue";

    export default {
        name: 'SiteUpdateCredits',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                smsCreditsStatus: {},
                campaignCreditsStatus: {},
                emailCreditsStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.smsCreditsStatus,
                    this.campaignCreditsStatus,
                    this.emailCreditsStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'sms-credits': SiteUpdateCreditsSms,
            'campaign-credits': SiteUpdateCreditsCampaign,
            'email-credits': SiteUpdateCreditsEmail
        }
    }
</script>
