<template>

</template>

<script>
    import { NotesSearchMixin } from "../Shared/Notes/NotesSearchMixin";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'AppointmentNotesSearch',
        mixins: [NotesSearchMixin, StatusEmitterMixin],
        props: {
            appointments: []
        },
        computed: {
            ownerIds() {
                return [...new Set(this.appointments.map(a => a.scheduleId))];
            },
            ownerTypeId() {
                return 8;
            },
            status() {
                return {
                    loading: this.searchingNotes
                }
            }
        }
    }
</script>
