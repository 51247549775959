import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    breedProductCaches: []
})

const getters = {
    findBreedProductCache: (state) => key => state.breedProductCaches.find(bpc => bpc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.breedProductCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setBreedProduct(state, product) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === product.siteBreedId);
        if (!!existing) {
            existing.value = product;
            existing.loading = false;
        } else {
            state.breedProductCaches.push({ key: product.siteBreedId, value: product, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setBreedProducts(state, products) {
        for (var i = 0; i < products.length; i++) {
            const product = products[i];
            const existing = state.breedProductCaches.find(bpc => bpc.key === product.siteBreedId);
            if (!!existing) {
                existing.value = product;
                existing.loading = false;
            } else {
                state.breedProductCaches.push({ key: product.siteBreedId, value: product, loading: false });
            }
        }
    },
    setEmptyBreedProducts(state, siteBreedIds) {
        for (var i = 0; i < siteBreedIds.length; i++) {
            const siteBreedId = siteBreedIds[i];
            const existing = state.breedProductCaches.find(bpc => bpc.key === siteBreedId);
            if (!existing) {
                state.breedProductCaches.push({ key: siteBreedId, value: null, loading: false });
            }
        }
    }
}

const actions = {
    findBreedProduct({ commit }, siteBreedId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteBreedId, loading: true });
            Api.get('Breed/' + siteBreedId + '/BreedProduct')
                .then(response => {
                    commit('setBreedProduct', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: siteBreedId, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', siteBreedId);
                        reject(error);
                    }
                });
        });
    },
    addBreedProduct({ commit }, { siteBreedId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteBreedId, loading: true });
            Api.post('Breed/' + siteBreedId + '/BreedProduct', payload)
                .then(response => {
                    commit('setBreedProduct', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: siteBreedId, loading: false }));
        });
    },
    updateBreedProduct({ commit }, { siteBreedId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteBreedId, loading: true });
            Api.put('BreedProduct/' + payload.id, payload)
                .then(response => {
                    commit('setBreedProduct', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: siteBreedId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
