import { EmailHelper } from "../../helpers/EmailHelper";

export const ClientOnboardingInviteValidMixin = {
    props: {
        savingType: Number
    },
    data() {
        return {
            isSavingTypeValid: true,
            error: ''
        }
    },
    methods: {
        checkSavingType(model) {
            const self = this;
            self.error = '';
            switch (self.savingType) {
                case 1:
                    const emailFormatValid = EmailHelper.isValid(model.email);
                    if (model.email === '') {
                        self.error = "Client lacks an email for registration invite.";
                        self.isSavingTypeValid = false;
                    }
                    else if (!emailFormatValid) {
                        self.error = "Client email format is invalid.";
                        self.isSavingTypeValid = false;
                    }
                    else {
                        self.isSavingTypeValid = true;
                    }
                    break;
                case 2:
                    if (self.telephoneMobile === '') {
                        self.error = "Client lacks mobile tel for registration invite.";
                        self.isSavingTypeValid = false;
                    }
                    break;
                default:
                    self.error = "";
                    self.isSavingTypeValid = true;
                    break;
            }
        }
    }
}
