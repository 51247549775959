import { BreedProductCacheMixin } from "../../mixins/Products/BreedProductCacheMixin";
import { PetProductCacheMixinV2 } from "../../mixins/Products/PetProductCacheMixinV2";
import { ServiceProductsSearchMixin } from "../../mixins/Products/ServiceProductsSearchMixin";
import { PetCacheMixinV2 } from "./PetCacheMixinV2";

export const AppointmentProductMixin = {
    mixins: [PetCacheMixinV2, ServiceProductsSearchMixin, BreedProductCacheMixin, PetProductCacheMixinV2],
    computed: {
        orderItems() {
            console.log('OVERRIDE COMPUTED - orderItems');
            return [];
        },
        petId() {
            console.log('OVERRIDE COMPUTED - petId')
            return '';
        },
        pet() {
            return this.pets.find(p => p.id === this.petId);
        },
        siteBreedId() {
            return !!this.pet ? this.pet.breedId : '';
        },
        basicProduct() {
            return !!this.petProduct ? this.petProduct : this.breedProduct;
        },
        allProducts() {
            return (!!this.basicProduct ? [this.basicProduct] : []).concat(this.serviceProducts);
        },
        options() {
            return this.allProducts.map(p => Object.assign({}, p, {
                selected: this.orderItems.some(oi => oi.productId === p.productId)
            }));
        }
    },
    methods: {
        onClick(option) {
            var selected = [...this.orderItems];
            if (option.selected) {
                selected = selected.filter(oi => oi.productId !== option.productId);
            } else {
                selected.push({
                    productId: option.productId,
                    name: option.name,
                    description: option.description,
                    amount: option.amount,
                    quantity: 1
                });
            }
            this.setSelected(selected);
        },
        setSelected() {
            console.log('!!!OVERRIDE METHOD - setSelected(selected)!!!')
        }
    }
}
