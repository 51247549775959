import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const AppointmentCountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentCounts', [
            'findAppointmentCountCache'
        ]),
        resourceId() {
            console.log('!!!OVERRIDE COMPUTED - resourceId!!!');
        },
        statusId() {
            console.log('!!!OVERRIDE COMPUTED - statusId!!!');
        },
        appointmentCountModel() {
            return {
                resourceId: this.resourceId,
                statusId: this.statusId
            }
        },
        appointmentCountCacheKey() {
            return CacheHelper.objectCacheKey(this.appointmentCountModel);
        },
        appointmentCountModelValid() {
            const model = this.appointmentCountModel;
            return !!model.resourceId && !isNaN(parseInt(this.statusId));
        },
        appointmentCountCache() {
            return this.findAppointmentCountCache(this.appointmentCountCacheKey);
        },
        appointmentCount() {
            return !!this.appointmentCountCache ? this.appointmentCountCache.value : null;
        },
        loadingAppointmentCount() {
            return !!this.appointmentCountCache && this.appointmentCountCache.loading;
        }
    },
    watch: {
        appointmentCountCacheKey() {
            this.refreshAppointmentCount();
        }
    },
    methods: {
        ...mapActions('AppointmentCounts', [
            'countAppointments'
        ]),
        refreshAppointmentCount() {
            if (!this.appointmentCountCache || !!this.appointmentCountCache.retry) {
                this.tryCountAppointments();
            }
        },
        tryCountAppointments() {
            const self = this;
            if (!self.appointmentCountModelValid) {
                return;
            }
            self.error = '';
            self.countAppointments({ key: self.appointmentCountCacheKey, payload: self.appointmentCountModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentCount();
    }
}
