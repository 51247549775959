import { Api } from "../../services/Api";

const state = () => ({
    emailCampaignTemplatesCaches: []
})

const getters = {
    findEmailCampaignTemplatesCache: (state) => key => state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.emailCampaignTemplatesCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setEmailCampaignTemplates(state, { key, templates }) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            existing.values = templates;
            existing.loading = false;
        } else {
            state.emailCampaignTemplatesCaches.push({ key: key, values: templates, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendEmailCampaignTemplate(state, { key, template }) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            existing.values.push(template);
        } else {
            state.emailCampaignTemplatesCaches.push({ key: key, values: [template], loading: false });
        }
    },
    insertEmailCampaignTemplate(state, { key, template }) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            const old = existing.values.find(t => t.id === template.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, template);
            } else {
                existing.values.push(template);
            }
        } else {
            state.emailCampaignTemplatesCaches.push({ key: key, values: [template], loading: false });
        }
    },
    appendEmailCampaignTemplates(state, { key, templates }) {
        const existing = state.emailCampaignTemplatesCaches.find(ectc => ectc.key === key);
        if (!!existing) {
            existing.values = existing.values.concat(templates);
        } else {
            state.emailCampaignTemplatesCaches.push({ key: key, values: templates, loading: false });
        }
    }
}

const actions = {
    findEmailCampaignTemplates({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('EmailCampaignTemplate')
                .then(response => {
                    commit('setEmailCampaignTemplates', { key: key, templates: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addEmailCampaignTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('EmailCampaignTemplate', payload)
                .then(response => {
                    commit('appendEmailCampaignTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateEmailCampaignTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('EmailCampaignTemplate', payload)
                .then(response => {
                    commit('insertEmailCampaignTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addEmailCampaignTemplatesFromPurchased({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('StripoDefaultTemplate/EmailCampaignTemplate')
                .then(response => {
                    commit('appendEmailCampaignTemplates', { key: key, templates: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
