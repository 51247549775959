import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    fetchingPaymentProviders: false,
    fetchedPaymentProviders: false,
    paymentProviders: []
});

const mutations = {
    setFetching(state, fetching) {
        state.fetchingPaymentProviders = !!fetching;
        state.fetchedPaymentProviders = false;
    },
    setPaymentProviders(state, paymentProviders) {
        state.paymentProviders = paymentProviders;
        state.fetchedPaymentProviders = true;
        state.fetchingPaymentProviders = false;
    }
}

const actions = {
    fetchPaymentProviders({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            PaymentApi.get('Provider/Setting')
                .then(response => {
                    commit('setPaymentProviders', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
