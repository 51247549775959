<template>
    <div></div>
</template>

<script>
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { Api } from '../../services/Api';

    export default {
        name: 'BookingSuggestionCommunicationAdd',
        mixins: [StatusEmitterMixin],
        props: {
            declined: Boolean,
            submitEvent: Number,
            addedSuggestion: Object,
            suggestDateTime: Boolean
        },
        data() {
            return {
                communicationSent: false,
                sendingCommunication: false
            }
        },
        computed: {
            clientId() {
                return 'Online Booking';
            },
            subjectId() {
                return !!this.addedSuggestion ? this.addedSuggestion.id : '';
            },
            methodTypeId() {
                return 1;
            },
            communicationTypeId() {
                return 16;
            },
            communicationSendModel() {
                return {
                    clientId: this.clientId,
                    communicationTypeId: this.communicationTypeId,
                    subjectId: this.subjectId,
                    methodTypeId: this.methodTypeId
                }
            },
            communicationSendModelValid() {
                const model = this.communicationSendModel;
                return !!model.clientId && !!model.communicationTypeId && !!model.subjectId && !!model.methodTypeId;
            },
            canSendBookingSuggestionCommunication() {
                return !!this.declined && !!this.addedSuggestion && !!this.communicationSendModelValid && !this.communicationSent
            },
            completed() {
                return !this.suggestDateTime || !!this.communicationSent;
            },
            status() {
                return {
                    error: '',
                    completed: this.completed,
                    loading: this.sendingCommunication
                }
            }
        },
        watch: {
            submitEvent() {
                this.onValidatedChanged();
            },
            addedSuggestion() {
                this.onValidatedChanged();
            },
            declined() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!!this.canSendBookingSuggestionCommunication) {
                    this.sendBookingSuggestionCommunication();
                }
            },
            sendBookingSuggestionCommunication() {
                this.sendingCommunication = true;
                Api.post('BookingSuggestion/' + this.subjectId + '/Communication', this.communicationSendModel)
                    .finally(() => {
                        this.communicationSent = true
                        this.sendingCommunication = false;
                    });
            }
        }
    }
</script>
