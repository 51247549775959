<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Client Details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <client-name v-model="clientName" :validator="clientValidator" :set-focus="true"></client-name>
                <client-mobile v-model="telephoneMobile" :validation="mobileValidation"></client-mobile>
                <client-home-tel v-model="telephoneHome"></client-home-tel>
                <client-work-tel v-model="telephoneWork"></client-work-tel>
                <client-email v-model="email"></client-email>
                <site-select></site-select>
                <address-input></address-input>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import { ClientAddMixin } from "../Shared/ClientAddMixin";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";
    import ClientAddAddressInput from "./ClientAddAddressInput.vue";
    import ClientNameFields from './ClientNameFields.vue';
    import ClientMobileFieldV2 from './ClientMobileFieldV2.vue';
    import ClientHomeTelField from './ClientHomeTelField.vue';
    import ClientWorkTelField from './ClientWorkTelField.vue';
    import ClientEmailFieldV2 from './ClientEmailFieldV2.vue';
    import SiteSelectColumn from "../Shared/SiteSelectColumn.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'ClientAddDetailsSection',
        mixins: [ClientAddMixin, StatusEmitterMixin],
        data() {
            return {
                addedClientId: '',
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent',
                'clientAddedEvent'
            ]),
            clientName: {
                get() {
                    return {
                        firstName: this.firstName,
                        lastName: this.lastName
                    }
                },
                set(value) {
                    this.firstName = value.firstName;
                    this.lastName = value.lastName;
                }
            },
            clientId() {
                return this.addedClientId;
            },
            completed() {
                return !!this.addedClientId;
            },
            status() {
                return {
                    loading: !!this.addingClient,
                    error: this.error,
                    completed: !!this.completed
                }
            },
            canAddClient() {
                return !this.completed;
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            },
            addClientEvent() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddClient || !!this.addingClient) {
                    return;
                }
                this.tryAddClient();
            }
        },
        components: {
            'address-input': ClientAddAddressInput,
            'client-name': ClientNameFields,
            'client-mobile': ClientMobileFieldV2,
            'client-email': ClientEmailFieldV2,
            'client-home-tel': ClientHomeTelField,
            'client-work-tel': ClientWorkTelField,
            'site-select': SiteSelectColumn,
            'error-banner': ErrorBanner
        }
    }
</script>
