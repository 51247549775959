import { UserCacheMixin } from "../../components/Shared/UserCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const PaymentUserAddMixin = {
    mixins: [UserCacheMixin],
    computed: {
        ...mapGetters('PaymentUsers', [
            'findPaymentUserCache'
        ]),
        userId() {
            return !!this.user ? this.user.id : '';
        },
        paymentUserCache() {
            return this.findPaymentUserCache(this.userId);
        },
        paymentUserPromise() {
            return !!this.paymentUserCache ? this.paymentUserCache.promise : null;
        },
        paymentUserRequest() {
            return {
                userId: this.userId
            }
        },
        paymentUserRequestValid() {
            return this.isOwner && !!this.paymentUserRequest.userId;
        }
    },
    methods: {
        ...mapActions('PaymentUsers', [
            'addPaymentUser'
        ]),
        tryAddPaymentUserWithCompletion(completion) {
            const self = this;
            if (!self.paymentUserRequestValid) {
                return;
            }
            self.error = '';
            self.addPaymentUser({ userId: self.userId, payload: self.paymentUserRequest })
                .then(() => completion())
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
