import { mapActions } from 'vuex';
import { CommunicationHelper } from '../../helpers/CommunicationHelper';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { EmailMarketingTemplateCacheMixin } from './EmailMarketingTemplateCacheMixin';
import { StripoAddMixin } from "@/components/Shared/Stripo/StripoAddMixin";
import { EmailMarketingStripoDefaultCacheMixin } from './EmailMarketingStripoDefaultCacheMixin';
import { EmailMarketingDefaultSubjectsAddMixin } from './EmailMarketingDefaultSubjectsAddMixin';

export const EmailMarketingTemplatesAddMixin = {
    mixins: [EmailMarketingTemplateCacheMixin, EmailMarketingStripoDefaultCacheMixin, EmailMarketingDefaultSubjectsAddMixin, StripoAddMixin],
    data() {
        return {
            templateId: '',
            emailMarketingTemplateValidator: CommunicationHelper.getEmailMarketingTemplateValidator()
        }
    },
    computed: {
        defaultStripoTemplate() {
            return this.emailMarketingStripoDefaultTemplate;
        },
        emailMarketingTemplateAddModel() {
            return {
                subject: this.subject,
                communicationTypeId: this.communicationTypeId,
                stripoTemplateId: this.templateId
            }
        }
    },
    methods: {
        ...mapActions('EmailMarketingTemplates', [
            'addEmailMarketingTemplate',
        ]),
        tryUploadEmailMarketingTemplate() {
            if (!!this.templateId) {
                this.tryAddEmailMarketingTemplate();
            } else {
                this.tryAddStripoTemplate();
            }
        },
        afterStripoTemplateAdded(stripoTemplate) {
            this.templateId = stripoTemplate.id;
            this.tryAddEmailMarketingTemplate();
        },
        tryAddEmailMarketingTemplate() {
            const self = this;
            self.error = '';
            self.emailMarketingTemplateValidator.check(self.emailMarketingTemplateAddModel);

            if (!self.emailMarketingTemplateValidator.isValid) {
                return;
            }

            self.addEmailMarketingTemplate({ key: self.emailMarketingTemplateCackeKey, payload: self.emailMarketingTemplateAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
