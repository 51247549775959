import { mapState } from "vuex";

export const SiteIdMixin = {
    computed: {
        ...mapState('Sites', [
            'selected'
        ]),
        siteId() {
            return !!this.selected ? this.selected.id : '';
        }
    }
}
