export const ScheduleViewsMixin = {
    data() {
        return {
            scheduleViews: [
                { name: 'Day', value: 'timeGridDay' },
                { name: 'Week', value: 'timeGridWeek' },
                { name: 'Month', value: 'dayGridMonth' },
                { name: 'Resources', value: 'resourceTimeGridDay' }
            ]
        }
    }
}
