<template>
    <div class="form-group">
        <label class="d-none d-md-block" for="title">{{$t('Template')}}</label>
        <span v-if="loading" class="inline-loader"></span>
        <template v-else>
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOption.name}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="template in templateOptions"
                            class="dropdown-item pointer"
                            :key="template.id"
                            @click="setSelectedTemplateId(template.id)"
                            v-bind:class="{'active': template.id === selectedOption.id}">
                            {{template.name}}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { PurchasedCampaignTemplatesCacheMixin } from "../Shared/PurchasedCampaignTemplatesCacheMixin";
    import { StripoDefaultTemplateSettingsCacheMixin } from "../Shared/StripoDefaultTemplateSettings/StripoDefaultTemplateSettingsCacheMixin";

    export default {
        name: 'StripoDefaultTemplateSettingsSelect',
        mixins: [PurchasedCampaignTemplatesCacheMixin, StripoDefaultTemplateSettingsCacheMixin],
        props: {
            value: Number
        },
        data() {
            return {
                selectedTemplateId: ''
            }
        },
        computed: {
            selectedOption() {
                return this.templateOptions.find(to => to.id == this.selectedTemplateId);
            },
            templateOptions() {
                const emptyTemplate = {
                    id: '0',
                    name: 'Empty Template'
                };
                const options = this.purchasedCampaignTemplates
                    .map(template => {
                        const setting = this.stripoDefaultTemplateSettings.find(setting => setting.id == template.stripoDefaultTemplateId);
                        return {
                            id: template.stripoDefaultTemplateId,
                            name: setting ? setting.name : 'Unknown'
                        };
                    });
                options.unshift(emptyTemplate);
                return options;
            },
            loading() {
                return !!this.loadingPurchasedCampaignTemplates || !!this.loadingStripoDefaultTemplateSettings
            }
        },
        methods: {
            setSelectedTemplateId(value) {
                this.selectedTemplateId = value;
                this.$emit('input', value);
            }
        },
        watch: {
            purchasedCampaignTemplates() {
                this.$emit('input', 0)
            }
        },
        created() {
            this.setSelectedTemplateId(this.value);
        }
    }
</script>
