import { mapState, mapActions } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteHelper } from "../../helpers/SiteHelper"
import { AccountSettingCacheMixin } from "../../mixins/Settings/Account/AccountSettingCacheMixin";

export const SiteAddMixin = {
    mixins: [AccountSettingCacheMixin],
    data() {
        return {
            error: '',
            companyName: '',
            name: '',
            email: '',
            tagLine: '',
            contactNumber: '',
            website: '',
            siteValidator: SiteHelper.getSiteValidator()
        }
    },
    computed: {
        ...mapState('Sites', [
            'addingSite'
        ]),
        siteAddModel() {
            return {
                companyName: this.companyName,
                name: this.name,
                email: this.email,
                tagLine: this.tagLine,
                contactNumber: this.contactNumber,
                website: this.website
            }
        },
        siteAddModelValid() {
            return this.siteValidator.isValid;
        },
        accountSettingKey() {
            return 'DefaultDetails';
        }
    },
    watch: {
        accountSettingObject() {
            this.refreshSiteModel();
        }
    },
    methods: {
        ...mapActions('Sites', [
            'addSite'
        ]),
        refreshSiteModel() {
            this.companyName = this.accountSettingObject ? this.accountSettingObject.companyName : '';
            this.name = '';
            this.email = '';
            this.tagLine = '';
            this.contactNumber = '';
            this.website = '';
        },
        tryAddSite() {
            const self = this;
            self.siteValidator.check(self.siteAddModel);
            if (!self.siteAddModelValid) {
                return;
            }
            self.error = '';
            self.addSite(self.siteAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSiteModel();
    }
}
