<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{$t('Document Templates')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="openContractAdd" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">{{$t('Add Template')}}</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button @click="openContractAdd" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add Template')}}
                </button>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <table-mobile>
                <contract-mobile v-for="contractTemplate in contractTemplatesForSite" :key="contractTemplate.id" :contractTemplate="contractTemplate"></contract-mobile>
            </table-mobile>
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th aria-controls="clients-table" rowspan="1" colspan="1">{{$t('Name')}}</th>
                        <th aria-controls="clients-table" rowspan="1" colspan="1">{{$t('Signature?')}}</th>
                        <th aria-controls="clients-table" rowspan="1" colspan="1">{{$t('Version')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <contract-desktop v-for="(contractTemplate, index) in contractTemplatesForSite" :key="contractTemplate.id" :number="index" :contractTemplate="contractTemplate"></contract-desktop>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import { ContractTemplateSiteCacheMixin } from "../../Shared/ContractTemplateSiteCacheMixin";
    import SiteSelect from '../../Account/SiteSelect.vue';
    import TableDesktop from '../../Shared/Layout/TableDesktop.vue';
    import ContractItemDesktop from './ContractItemDesktop.vue';
    import TableMobile from '../../Shared/Layout/TableMobile.vue';
    import ContractItemMobile from './ContractItemMobile.vue';

    export default {
        name: 'Contracts',
        mixins: [ContractTemplateSiteCacheMixin],
        methods: {
            openContractAdd() {
                router.push({ name: 'contractAdd' });
            }
        },
        components: {
            'site-select': SiteSelect,
            'table-desktop': TableDesktop,
            'contract-desktop': ContractItemDesktop,
            'table-mobile': TableMobile,
            'contract-mobile': ContractItemMobile
        }
    }
</script>
