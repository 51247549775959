import { StripoUpdateMixin } from "../../components/Shared/Stripo/StripoUpdateMixin";
import { CommunicationHelper } from "../../helpers/CommunicationHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { EmailMarketingTemplateCacheMixin } from "./EmailMarketingTemplateCacheMixin";
import { mapActions } from 'vuex';
import { EmailMarketingStripoDefaultCacheMixin } from './EmailMarketingStripoDefaultCacheMixin';

export const EmailMarketingTemplatesUpdateMixin = {
    mixins: [EmailMarketingTemplateCacheMixin, EmailMarketingStripoDefaultCacheMixin, StripoUpdateMixin],
    data() {
        return {
            modified: false,
            subject: '',
            templateId: '',
            emailMarketingTemplateValidator: CommunicationHelper.getEmailMarketingTemplateValidator()
        }
    },
    computed: {
        stripoTemplateId() {
            return this.templateId;
        },
        defaultStripoTemplate() {
            return this.emailMarketingStripoDefaultTemplate;
        },
        emailMarketingTemplateUpdateModel() {
            return !!this.emailMarketingTemplate ? Object.assign({}, this.emailMarketingTemplate, {
                subject: this.subject,
                stripoTemplateId: this.templateId
            }) : {};
        },
        emailMarketingTemplateUpdateModelValid() {
            return this.emailMarketingTemplateValidator.isValid && !!this.templateId && !!this.siteId;
        },
        emailMarketingTemplateUpdateModelModified() {
            return this.modified || JSON.stringify(this.emailMarketingTemplateUpdateModel) !== JSON.stringify(this.emailMarketingTemplate);
        }
    },
    watch: {
        emailMarketingTemplate() {
            this.refreshEmailMarketingTemplateModel();
        }
    },
    methods: {
        ...mapActions('EmailMarketingTemplates', [
            'updateEmailMarketingTemplate',
            'deleteEmailMarketingTemplate'
        ]),
        tryUploadEmailMarketingTemplate() {
            if (!!this.stripoTemplate) {
                if (!!this.stripoUpdateModelModified) {
                    this.tryUpdateStripoTemplate();
                } else {
                    this.tryUpdateEmailMarketingTemplate();
                }
            } else {
                this.tryAddStripoTemplate();
            }
        },
        refreshEmailMarketingTemplateModel() {
            this.subject = !!this.emailMarketingTemplate ? this.emailMarketingTemplate.subject : '';
            this.templateId = !!this.emailMarketingTemplate ? this.emailMarketingTemplate.stripoTemplateId : '';
        },
        afterStripoTemplateAdded(stripoTemplate) {
            this.modified = true;
            this.templateId = stripoTemplate.id;
            this.tryUpdateEmailMarketingTemplate();
        },
        afterStripoTemplateUpdated() {
            this.modified = true;
            this.tryUpdateEmailMarketingTemplate();
        },
        tryUpdateEmailMarketingTemplate() {
            const self = this;
            self.emailMarketingTemplateValidator.check(self.emailMarketingTemplateUpdateModel);

            if (!self.emailMarketingTemplateUpdateModelValid || !self.emailMarketingTemplateUpdateModelModified) {
                return;
            }

            self.error = '';
            self.updateEmailMarketingTemplate({ key: self.emailMarketingTemplateCackeKey, payload: self.emailMarketingTemplateUpdateModel })
                .then(() => self.modified = false)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteEmailMarketingTemplate() {
            const self = this;
            if (!self.emailMarketingTemplate) {
                return;
            }
            self.error = '';
            self.deleteEmailMarketingTemplate({ key: self.emailMarketingTemplateCackeKey, id: self.emailMarketingTemplate.id })
                .then(() => self.afterEmailMarketingDelete())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterEmailMarketingDelete() {
            // for overriding
        }
    },
    created() {
        this.refreshEmailMarketingTemplateModel();
    }
}
