import { mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const PaymentApiUserClaimAddMixin = {
    data() {
        return {
            error: '',
            adminAccounts: [],
            refunderAccounts: []
        }
    },
    computed: {
        userId() {
            console.log('OVERRIDE COMPUTED - userId');
        },
        paymentApiUserClaimAddModel() {
            return {
                username: this.userId,
                admin: this.adminAccounts,
                refunder: this.refunderAccounts
            };
        }
    },
    methods: {
        ...mapActions('PaymentApiUserSearches', [
            'updatePaymentApiUserClaims'
        ]),
        tryAddPaymentApiUserClaims() {
            const self = this;
            if (!self.userId) {
                return;
            }
            self.error = '';
            self.updatePaymentApiUserClaims({ userId: self.userId, payload: self.paymentApiUserClaimAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
