import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SortableSearchMixin } from "./Searching/SortableSearchMixin";

export const ClientSearchNoCacheMixin = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            searchTerm: '',
            skip: 0,
            take: 11,
            returnDefault: true,
            timeoutId: null,
            attempted: false
        }
    },
    computed: {
        ...mapGetters('ClientSearchesV2', [
            'findClientSearch'
        ]),
        clientSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                ascending: this.ascending,
                returnDefault: this.returnDefault,
                skip: this.skip,
                take: this.take
            }
        },
        clientSearchModelValid() {
            return !!this.searchTerm || this.returnDefault;
        },
        clientSearchKey() {
            return CacheHelper.objectCacheKey(this.clientSearchModel);
        },
        clientSearch() {
            return this.findClientSearch(this.clientSearchKey);
        },
        clients() {
            return !!this.clientSearch ? this.clientSearch.values : [];
        },
        searchingClients() {
            return !!this.clientSearch && this.clientSearch.loading;
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.attempted = true;
                this.refreshClientSearch();
            }, 500);
        },
        sortModel() {
            this.refreshClientSearch();
        }
    },
    methods: {
        ...mapActions('ClientSearchesV2', [
            'searchClientsNoCache'
        ]),
        refreshClientSearch() {
            if (!this.clientSearch || !!this.clientSearch.retry) {
                this.trySearchClientsNoCache();
            }
        },
        trySearchClientsNoCache() {
            const self = this;
            self.error = '';
            if (!self.clientSearchModelValid) {
                return;
            }
            self.searchClientsNoCache({ key: self.clientSearchKey, payload: self.clientSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
