import { Api } from '../../services/Api';

const state = () => ({
    resourceCaches: []
})

const getters = {
    findResourceCache: (state) => key => state.resourceCaches.find(rc => rc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.resourceCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setResources(state, { key, resources }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            const index = state.resourceCaches.indexOf(existing);
            state.resourceCaches.splice(index, 1, Object.assign({}, existing, { values: resources, loading: false }));
        } else {
            state.resourceCaches.push({ key: key, values: resources, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendResource(state, { key, resource }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            existing.values.push(resource);
        } else {
            state.resourceCaches.push({ key: key, values: [resource], loading: false });
        }
    },
    insertResource(state, { key, resource }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            const old = existing.values.find(r => r.id === resource.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, resource);
            } else {
                existing.values.push(resource);
            }
        } else {
            state.resourceCaches.push({ key: key, values: [resource], loading: false });
        }
    },
    removeResource(state, { key, id }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            const resource = existing.values.find(r => r.id === id);
            if (!!resource) {
                resource.hidden = true;
            }
        } else {
            state.resourceCaches.push({ key: key, values: [], loading: false });
        }
    },
    clearResourcesCache(state) {
        state.resourceCaches = [];
    },
    updateResourcesPriorityOrder(state, { key, resources }) {
        const existing = state.resourceCaches.find(rc => rc.key === key);
        if (!!existing) {
            resources.forEach(updatedResource => {
                const resource = existing.values.find(res => res.id === updatedResource.id);
                if (!!resource) {
                    resource.priorityOrder = updatedResource.priorityOrder;
                }
            });
        }
    }
}

const actions = {
    getResources({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Resource?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setResources', { key: key, resources: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addResource({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Resource', payload)
                .then(response => {
                    commit('appendResource', { key: key, resource: response.data });
                    commit('ApplicationEvents/triggerResourceAdded', response.data, { root: true });
                    commit('ResourceCount/clearResourceCount', key, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateResource({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Resource', payload)
                .then(response => {
                    commit('insertResource', { key: key, resource: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteResource({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('Resource/' + id)
                .then(response => {
                    commit('removeResource', { key: key, id: id });
                    commit('ApplicationEvents/triggerResourceDeleted', null, { root: true });
                    commit('ResourceCount/clearResourceCount', key, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
