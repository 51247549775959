import { Api } from '../../services/Api';

const state = () => ({
    addingStripoTemplate: false,
    stripoTemplateCaches: []
});

const getters = {
    findStripoTemplateCache: (state) => key => state.stripoTemplateCaches.find(stc => stc.key === key)
}

const mutations = {
    setAdding(state, adding) {
        state.addingStripoTemplate = !!adding;
    },
    setTemplate(state, template) {
        const existing = state.stripoTemplateCaches.find(stc => stc.key === template.id);
        if (!!existing) {
            existing.value = template;
            existing.loading = false;
        } else {
            state.stripoTemplateCaches.push({ key: template.id, value: template, loading: false });
        }
    },
    setLoading(state, { key, loading }) {
        const existing = state.stripoTemplateCaches.find(stc => stc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.stripoTemplateCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.stripoTemplateCaches.find(stc => stc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getStripoTemplate({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Stripo/Template/' + id)
                .then(response => {
                    commit('setTemplate', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error)
                });
        });
    },
    addStripoTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            var form = new FormData();
            form.append('html', payload.html);
            form.append('css', payload.css);
            const config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            Api.post('Stripo/Template', form, config)
                .then(response => {
                    commit('setTemplate', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateStripoTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: payload.id, loading: true });
            var form = new FormData();
            form.append('id', payload.id);
            form.append('html', payload.html);
            form.append('css', payload.css);
            const config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            Api.put('Stripo/Template', form, config)
                .then(response => {
                    commit('setTemplate', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: payload.id, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
