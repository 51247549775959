import { Api } from '../../services/Api';

const state = () => ({
    addingSchedule: false,
    appointmentScheduleCaches: []
})

const getters = {
    findAppointmentScheduleCache: (state) => key => state.appointmentScheduleCaches.find(asc => asc.key === key)
}

const mutations = {
    setAdding(state, value) {
        state.addingSchedule = !!value;
    },
    setLoading(state, { key, loading }) {
        const existing = state.appointmentScheduleCaches.find(asc => asc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.appointmentScheduleCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setAppointmentSchedule(state, schedule) {
        const existing = state.appointmentScheduleCaches.find(asc => asc.key === schedule.id);
        if (!!existing) {
            const index = state.appointmentScheduleCaches.indexOf(existing);
            state.appointmentScheduleCaches.splice(index, 1, Object.assign({}, existing, { value: schedule, loading: false }));
        } else {
            state.appointmentScheduleCaches.push({ key: schedule.id, value: schedule, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.appointmentScheduleCaches.find(asc => asc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getAppointmentSchedule({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('AppointmentSchedule/' + id)
                .then(response => {
                    commit('setAppointmentSchedule', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id)
                    reject(error);
                });
        });
    },
    addAppointmentSchedule({ commit }, { payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('AppointmentSchedule', payload)
                .then(response => {
                    commit('setAppointmentSchedule', response.data);
                    commit('AppointmentSearches/clearAppointmentSearches', null, { root: true });
                    commit('ConflictSearches/clearConflictSearches', null, { root: true });
                    commit('ApplicationEvents/triggerAppointmentScheduleAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
