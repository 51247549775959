<template>
    <draggable v-model="resources" class="text-center" @end="updatePriorities">
        <div v-for="(resource, index) in resources" :key="resource.id">
            <draggable-item :resource="resource"></draggable-item>
        </div>
    </draggable>
</template>

<script>
    import draggable from 'vuedraggable';
    import ResourcePriorityOrderDraggableItem from './ResourcePriorityOrderDraggableItem.vue';

    export default {
        name: 'ResourcePriorityOrderDraggable',
        props: {
            value: Array
        },
        data() {
            return {
                resources: []
            }
        },
        watch: {
            value() {
                this.refreshResources()
            }
        },
        methods: {
            updatePriorities() {
                this.resources.forEach((resource, index) => {
                    resource.priorityOrder = index + 1;
                });
                this.$emit('updatePriorities', this.resources);
            },
            refreshResources() {
                this.resources = JSON.parse(JSON.stringify(this.value));
                this.resources.sort((a, b) => a.priorityOrder - b.priorityOrder);
            }
        },
        created() {
            this.refreshResources();
        },
        components: {
            'draggable-item': ResourcePriorityOrderDraggableItem,
            draggable
        }
    }
</script>
