<template>
    <div class="mb-5">
        <rewards-item v-for="(reward, index) in referralRewardSettings" :key="index" :reward="reward" :score="score"></rewards-item>
    </div>
</template>

<script>
    import { ReferralRewardSettingsCacheMixin } from "../../mixins/Referrals/ReferralRewardSettingsCacheMixin"
    import ReferralsRewardsItem from "./ReferralsRewardsItem.vue"

    export default {
        name: 'ReferralsRewards',
        mixins: [ReferralRewardSettingsCacheMixin],
        props: {
            score: Number
        },
        components: {
            'rewards-item': ReferralsRewardsItem
        }
    }
</script>
