<template>
    <span class="text text-truncate">
        {{pet.name}} - {{breedName}} ({{clientFullname}})
    </span>
</template>

<script>
    import { BreedCacheMixin } from '../../Shared/BreedCacheMixin';
    import { ClientCacheMixin } from '../../Shared/ClientCacheMixin';

    export default {
        name: 'PetDisplayItem',
        mixins: [ClientCacheMixin, BreedCacheMixin],
        props: {
            pet: Object
        },
        computed: {
            clientId() {
                return this.pet.clientId;
            },
            breedId() {
                return this.pet.breedId;
            }
        },
        methods: {
            tryGetClient() {
                // overriding to do nothing
            },
            tryFetchBreed() {
                // overriding to do nothing
            }
        }
    }
</script>
