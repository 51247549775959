import { Api } from '../../services/Api';

const state = () => ({
    countryOptionSearches: []
})

const getters = {
    findCountryOptionSearch: (state) => key => state.countryOptionSearches.find(cos => cos.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.countryOptionSearches.find(cos => cos.key === key);
        if (!!existing) {
            existing.loading = loading;
            existing.retry = false;
        } else {
            state.countryOptionSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCountryOptions(state, { key, countryOptions }) {
        const existing = state.countryOptionSearches.find(cos => cos.key === key);
        if (!!existing) {
            existing.values = countryOptions;
            existing.loading = false;
        } else {
            state.countryOptionSearches.push({ key: key, values: countryOptions, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.countryOptionSearches.find(cos => cos.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchCountryOptions({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('CountryOptions/Search', payload)
                .then(response => {
                    commit('setCountryOptions', { key: key, countryOptions: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
