<template>
    <div>
        <div v-if="error" class="alert alert-warning  alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t(error)}}</p>
            </div>
        </div>
        <fieldset>
            <language-select></language-select>
            <email-input v-model="email"></email-input>
            <div class="input-group form-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fal fa-lock-alt"></i></span>
                </div>
                <input v-model="password" type="password" class="form-control authBorder" :placeholder="$t('Password')" aria-label="Password" required="" @keydown.enter="tryLogin">
            </div>
            <div class="form-group">
                <label class="custom-control custom-checkbox">
                    <input v-model="rememberEmail" type="checkbox" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{$t('Remember me')}}</span>
                </label>
            </div>
            <div class="form-group mt-4">
                <button @click="tryLogin" class="btn btn-secondary w-100" v-bind:disabled="disabled">
                    {{$t('Login')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
            </div>
        </fieldset>
        <div class="row bottom-links">
            <div class="col text-left">
                <router-link :to="{ name: 'forgot' }">{{$t('Forgot password?')}}</router-link>
            </div>
            <div class="col text-right">
                {{$t('Not registered?')}} <router-link :to="{ name: 'register' }">{{$t('Signup')}}</router-link>
            </div>
        </div>
        <div class="row bottom-links">
            <div class="col text-center">
                <a href="https://schedule.shakeyourtail.com/">{{$t('Log into our classic service here')}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import EmailInput from '../Shared/Credentials/EmailInput.vue';
    import vueCookies from 'vue-cookies';
    import LanguageSelect from '../Account/LanguageSelect.vue';

    export default {
        name: 'Auth',
        data: () => ({
            error: '',
            email: '',
            password: '',
            rememberEmail: false
        }),
        computed: {
            ...mapState('Authentication', [
                'loading'
            ]),
            loginModel() {
                return {
                    email: this.email,
                    password: this.password
                }
            },
            loginModelValid() {
                const model = this.loginModel;
                return !!model.email && !!model.password;
            },
            disabled() {
                return !!this.loading || !this.loginModelValid;
            },
            rememberedEmail: {
                get() {
                    return vueCookies.get('email');
                },
                set(value) {
                    vueCookies.set('email', value, -1, null, null, true, 'Strict');
                }
            }
        },
        methods: {
            ...mapActions('Authentication', [
                'login'
            ]),
            refreshEmail() {
                this.email = !!this.rememberedEmail ? this.rememberedEmail : '';
                this.rememberEmail = !!this.email;
            },
            tryLogin() {
                const self = this;
                if (!self.loginModelValid) {
                    return;
                }
                self.error = '';
                this.login(self.loginModel)
                    .then(() => {
                        if (self.rememberEmail) {
                            self.rememberedEmail = self.email;
                        } else {
                            vueCookies.remove('email');
                        }
                        self.$router.push({ path: '/', query: self.$route.query });
                    })
                    .catch(error => self.error = ErrorHelper.isNotAuthenticated(error) ?
                        'Invalid username or password' :
                        ErrorHelper.getError(error));
            }
        },
        components: {
            'email-input': EmailInput,
            'language-select': LanguageSelect
        },
        created() {
            this.refreshEmail();
        }
    }
</script>
