export const ProductInputMixin = {
    data() {
        return {
            amountInput: '',
            amount: null,
            defaultAmount: null
        }
    },
    computed: {
        amountIsDefault() {
            return this.amount === this.defaultAmount;
        }
    },
    methods: {
        processInput() {
            const amount = parseFloat(this.amountInput);
            this.amount = isNaN(amount) ? this.defaultAmount : amount;
            this.setAmountInput();
        },
        setInitialAmount(amount) {
            amount = parseFloat(amount);
            this.defaultAmount = isNaN(amount) ? null : amount;
            this.amount = this.defaultAmount;
            this.setAmountInput();
        },
        setAmountInput() {
            this.amountInput = this.amount == null ? '' : this.amount.toFixed(2).toString();
        }
    }
}
