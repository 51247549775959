export const PasswordSetMixin = {
    data() {
        return {
            password: '',
            loading: false,
            success: false,
            error: ''
        }
    },
    computed: {
        token() {
            return this.$route.query.token;
        },
        uploadModel() {
            return {
                password: this.password,
                token: this.token
            }
        },
        disabled() {
            return this.password.length === 0;
        }
    }
}