<template>
    <th @click="onClick" class="text-center pointer col-1" aria-controls="clients-table">
        <div>
            {{$t(daysOfWeek[dayId])}}
        </div>
        <div>
            {{openingString}}
        </div>
    </th>
</template>

<script>
    import { OpeningHoursMixin } from "./OpeningHoursMixin";

    export default {
        name: 'OpeningHoursDesktop',
        mixins: [OpeningHoursMixin]
    }
</script>
