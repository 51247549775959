import { mapActions, mapGetters } from "vuex"
import moment from 'moment';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { CacheHelper } from '../../../helpers/CacheHelper';
import { DaysOrderMixin } from "../DaysOrderMixin";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const OneOffHoursSearchMixin = {
    mixins: [DaysOrderMixin, SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('OneOffHours', [
            'findOneOffHoursCache'
        ]),
        now() {
            return new Date();
        },
        oneOffHoursSearchModel() {
            const start = this.getWeekStart();
            return {
                fromLocal: start.format().substring(0, 19),
                toLocal: start.add(6, 'days').endOf('day').format().substring(0, 19),
                siteId: this.siteId
            }
        },
        oneOffHoursSearchModelValid() {
            return !!this.oneOffHoursSearchModel.siteId;
        },
        oneOffHoursSearchKey() {
            return CacheHelper.objectCacheKey(this.oneOffHoursSearchModel);
        },
        oneOffHoursSearch() {
            return this.findOneOffHoursCache(this.oneOffHoursSearchKey);
        },
        oneOffHours() {
            return !!this.oneOffHoursSearch ? this.oneOffHoursSearch.values : [];
        },
        loadingOneOffHours() {
            return !!this.oneOffHoursSearch && this.oneOffHoursSearch.loading;
        }
    },
    watch: {
        oneOffHoursSearchModel() {
            this.refreshOneOffHoursSearch();
        }
    },
    methods: {
        ...mapActions('OneOffHours', [
            'searchOneOffHours'
        ]),
        getWeekStart() {
            return moment(this.now).subtract(this.dayIds.indexOf(moment(this.now).day()), 'days').startOf('day');
        },
        refreshOneOffHoursSearch() {
            if (!this.oneOffHoursSearch || !!this.oneOffHoursSearch.retry) {
                this.trySearchOneOffHours();
            }
        },
        trySearchOneOffHours() {
            const self = this;
            if (!self.oneOffHoursSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchOneOffHours({ key: self.oneOffHoursSearchKey, payload: self.oneOffHoursSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOneOffHoursSearch();
    }
}
