import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PetProductAddMixinV2 } from "./PetProductAddMixinV2";

export const PetProductUploadMixinV2 = {
    mixins: [PetProductAddMixinV2],
    computed: {
        petProductId() {
            return !!this.petProduct ? this.petProduct.id : '';
        },
        petProductUpdateModel() {
            return {
                id: this.petProductId,
                amount: this.amount
            }
        },
        petProductUpdateModelValid() {
            const model = this.petProductUpdateModel;
            return !!model.id && !isNaN(parseFloat(model.amount));
        },
        petProductUpdateModelModified() {
            const model = this.petProductUpdateModel;
            return this.petProduct.amount !== model.amount;
        }
    },
    watch: {
        petProduct() {
            this.refreshPetProductModel();
        }
    },
    methods: {
        ...mapActions('PetProductsV2', [
            'updatePetProduct'
        ]),
        tryUploadPetProduct() {
            if (!!this.petProduct) {
                this.tryUpdatePetProduct();
            } else {
                this.tryAddPetProduct();
            }
        },
        tryUpdatePetProduct() {
            const self = this;
            if (!self.petProductUpdateModelValid || !self.petProductUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updatePetProduct({ petId: self.petId, payload: self.petProductUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        getDefaultAmount() {
            return !!this.petProduct ? this.petProduct.amount : (!!this.breedProduct ? this.breedProduct.amount : null);
        }
    }
}
