import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ContactAddMixin } from "./ContactAddMixin";

export const ContactUploadMixin = {
    mixins: [ContactAddMixin],
    computed: {
        contact() {
            return this.contacts.find(c => c.contactType.id === this.contactTypeId);
        },
        contactUpdateModel() {
            return !!this.contact ? Object.assign({}, this.contact, {
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            }) : {};
        },
        contactUpdateModelValid() {
            return !!this.contactUpdateModel.id && this.contactValidator.isValid;
        },
        contactUpdateModelModified() {
            return JSON.stringify(this.contactUpdateModel) !== JSON.stringify(this.contact);
        }
    },
    watch: {
        contact() {
            this.refreshContactUploadModel();
        }
    },
    methods: {
        ...mapActions('Contacts', [
            'addContact',
            'updateContact'
        ]),
        refreshContactUploadModel() {
            this.name = !!this.contact ? this.contact.name : '';
            this.email = !!this.contact ? this.contact.email : '';
            this.mobile = !!this.contact ? this.contact.mobile : '';
            this.otherInfo = !!this.contact ? this.contact.otherInfo : '';
        },
        tryUploadContact() {
            if (!!this.contact) {
                this.tryUpdateContact();
            } else {
                this.tryAddContact();
            }
        },
        tryUpdateContact() {
            const self = this;
            self.contactValidator.check(self.contactUpdateModel);
            if (!self.contactUpdateModelValid) {
                return;
            }
            if (!self.contactUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateContact({ key: self.contactCacheKey, payload: self.contactUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContactUploadModel();
    }
}
