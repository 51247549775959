<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-cart-shopping fa-1x"></i>
                    {{$t('Payment Reports')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <error-banner v-if="error" :message="error"></error-banner>
            <div v-if="!isPaymentOwner">
                <div class="alert-wrapper px-0">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content d-flex align-items-center">
                            <p>{{$t('This site is not currently connected to a payment provider. To set up payment integration, please')}}</p>
                            <a @click="goToPaymentProviders" class="ml-1">{{$t('click here')}}</a>.
                        </div>
                    </div>
                </div>
            </div>
            <table-mobile>
                <report-mobile v-for="(checkout, index) in checkouts" :key="index" :checkout="checkout" :date-format="momentJSFormat" :time-format="timeMomentJSFormat" :currency-symbol="currencySymbol"></report-mobile>
            </table-mobile>
            <table-desktop :page-number="pageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <th class="sorting" v-bind:class="getSortClass('')" @click="setSort('')">{{$t('Created Date')}}</th>
                        <th>{{$t('Client')}}</th>
                        <th>{{$t('Appointment Date')}}</th>
                        <th>{{$t('Time')}}</th>
                        <th style="text-align: center">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    {{$t('Status')}}
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-item" v-bind:class="{ 'active': statusId===0 }">
                                        <a class="pointer" @click="statusId=0">{{$t('All')}}</a>
                                    </div>
                                    <div v-for="status in paymentStatuses" class="dropdown-item" v-bind:class="{ 'active': statusId===status.id }">
                                        <a class="pointer" @click="statusId=status.id">{{$t(status.description)}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th class="sorting" v-bind:class="getSortClass('amount')" style="text-align: right" @click="setSort('amount')">{{$t('Amount')}} ({{currencySymbol}})</th>
                        <th style="text-align: right">{{$t('Refunds')}} ({{currencySymbol}})</th>
                    </tr>
                </template>
                <template #body>
                    <report-desktop v-for="(checkout, index) in checkouts" :key="index" :checkout="checkout" :date-format="momentJSFormat" :time-format="timeMomentJSFormat"
                                    :number="index"></report-desktop>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import { PaymentSearchMixin } from "../../mixins/Payments/PaymentSearchMixin";
    import { AppointmentGroupSearchMixin } from "../../mixins/AppointmentGroups/AppointmentGroupSearchMixin";
    import { PetClientsSearchMixin } from '../Pet/Search/PetClientsSearchMixin';
    import { DateDisplayMixin } from "../Shared/Time/DateDisplayMixin";
    import { TimeDisplayMixin } from "../Shared/Time/TimeDisplayMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { PaymentStatusesCacheMixin } from "../../mixins/Payments/PaymentStatusesCacheMixin";
    import TableDesktopNoBody from "../Shared/Layout/TableDesktopNoBody.vue";
    import PaymentReportItemDesktop from "./PaymentReportItemDesktop.vue";
    import PaymentReportSiteSelect from "./PaymentReportSiteSelect.vue";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import PaymentReportItemMobile from "./PaymentReportItemMobile.vue";

    export default {
        name: 'PaymentReports',
        mixins: [PaymentSearchMixin, AppointmentGroupSearchMixin, PetClientsSearchMixin, DateDisplayMixin, TimeDisplayMixin, CountryCacheMixin, PaymentStatusesCacheMixin],
        computed: {
            appointmentGroupIds() {
                return [...new Set(this.checkouts.map(c => c.reference))];
            },
            appointments() {
                return this.appointmentGroups.map(ag => ag.appointments[0]);
            },
            clientIds() {
                return [...new Set(this.appointments.map(a => a.clientId))];
            }
        },
        methods: {
            setSort(key) {
                if (this.sort === key) {
                    this.ascending = !this.ascending;
                } else {
                    this.sort = key;
                    this.ascending = false;
                }
            },
            getSortClass(key) {
                return this.sort === key ? this.ascending ? 'sorting_asc' : 'sorting_desc' : '';
            },
            goToPaymentProviders() {
                this.$router.push({ name: 'paymentProviders' });
            }
        },
        components: {
            'site-select': PaymentReportSiteSelect,
            'table-desktop': TableDesktopNoBody,
            'report-desktop': PaymentReportItemDesktop,
            'table-mobile': TableMobile,
            'report-mobile': PaymentReportItemMobile,
            'error-banner': ErrorBanner
        }
    }
</script>
