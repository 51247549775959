<template>
    <tr :class="classType">
        <td>
            <button @click="onRowClicked" v-bind:style="{ borderColor: resourceColour, }" class="btn btn-outline-resource">
                {{resourceName}}
            </button>
        </td>
        <day-cell v-for="dayId in dayIds" :key="dayId" :day-id="dayId" :working-hours="resourceWorkingHours" :oneOffHours="resourceOneOffHours" @cell-clicked="onCellClicked"></day-cell>
    </tr>
</template>

<script>
    import ResourceHoursDayDesktop from "./ResourceHoursDayDesktop.vue";
    import { ResourceHoursMixin } from "./ResourceHoursMixin";

    export default {
        name: 'ResourceHoursDesktop',
        mixins: [ResourceHoursMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        },
        components: {
            'day-cell': ResourceHoursDayDesktop
        }
    }
</script>
