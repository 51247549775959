import { mapActions, mapGetters, mapState } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const AddressCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Addresses', [
            'addressCaches'
        ]),
        ...mapGetters('Addresses', [
            'findAddressCache'
        ]),
        ownerQueryModel() {
            console.log('!!!OVERRIDE COMPUTED - ownerQueryModel!!!');
        },
        ownerQueryModelValid() {
            const model = this.ownerQueryModel;
            return !!model && !!model.ownerTypeId && !!model.ownerId;
        },
        addressCacheKey() {
            return CacheHelper.ownerRequestCacheKey(this.ownerQueryModel);
        },
        addressCache() {
            return this.findAddressCache(this.addressCacheKey);
        },
        address() {
            return !!this.addressCache ? this.addressCache.values[0] : null;
        },
        loadingAddress() {
            return !!this.addressCache && this.addressCache.loading;
        }
    },
    watch: {
        addressCacheKey() {
            this.refreshAddress();
        }
    },
    methods: {
        ...mapActions('Addresses', [
            'searchAddresses'
        ]),
        refreshAddress() {
            if (!this.addressCache || !!this.addressCache.retry) {
                this.trySearchAddresses();
            }
        },
        trySearchAddresses() {
            const self = this;
            if (!self.ownerQueryModelValid) {
                return;
            }
            self.error = '';
            self.searchAddresses({ key: self.addressCacheKey, payload: self.ownerQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAddress();
    }
}
