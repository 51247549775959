<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header collapsed" data-target="#collapse-mco-1" data-toggle="collapse">
            {{$t('More client options')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mco-1">
            <div class="card card-body">
                <client-address></client-address>
                <client-notes></client-notes>
                <client-preferences></client-preferences>
            </div>
        </div>
    </section>
</template>

<script>
    import ClientAddAddressInput from '../../Clients/ClientAddAddressInput.vue';
    import OptionsClientNotes from './OptionsClientNotes.vue';
    import OptionsClientPreferences from './OptionsClientPreferences.vue';

    export default {
        name: 'OptionsClient',
        components: {
            'client-address': ClientAddAddressInput,
            'client-notes': OptionsClientNotes,
            'client-preferences': OptionsClientPreferences
        }
    }
</script>