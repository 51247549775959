<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <ul>
            <tag-item v-for="tagType in tagTypes" :tag-type="tagType" :tags="tags" :key="tagType.id" @toggled="toggleTag"></tag-item>
        </ul>
    </div>
</template>

<script>
    import { TagUploadMixin } from "../TagUploadMixin";
    import TagItem from "./TagItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagSelector',
        mixins: [TagUploadMixin],
        props: {
            tagTypes: Array
        },
        components: {
            'tag-item': TagItem,
            'error-banner': ErrorBanner
        }
    }
</script>
