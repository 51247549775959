<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Sites')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="dropdown w-100">
                <button aria-expanded="false"
                        aria-haspopup="true"
                        data-toggle="dropdown"
                        id="dropdownMenuButton2"
                        type="button"
                        class="filter-dd text-left w-100 mw-400">
                    <multi-select-display :values="summary"></multi-select-display>
                </button>
                <validation-message v-if="validator.siteIds" :message="validator.siteIds"></validation-message>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100 mw-400">
                    <div v-for="site in siteOptions" :key="site.id" class="dropdown-item px-2">
                        <label class="custom-control custom-checkbox">
                            <input v-model="site.selected" @change="onOptionsChanged" type="checkbox" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{site.name}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import MultiSelectDropdownDisplay from "../Shared/Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'UserSitesSelect',
        mixins: [SiteCacheMixin],
        props: {
            value: Array,
            validator: Object
        },
        computed: {
            siteOptions() {
                return this.sites.map(s => Object.assign({}, s, { selected: this.value.indexOf(s.id) > -1 }));
            },
            summary() {
                const selected = this.siteOptions.filter(o => o.selected).map(o => o.name);
                return selected.length > 0 ? selected : ['Sites'];
            }
        },
        methods: {
            onOptionsChanged() {
                this.$emit('input', this.siteOptions.filter(o => o.selected).map(o => o.id));
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
