import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { HashHelper } from "../../../helpers/HashHelper";
import { ImageCacheMixin } from "./ImageCacheMixin";
import { ImageSettingsCacheMixin } from "./ImageSettingsCacheMixin";

export const ImageAddMixin = {
    mixins: [ImageCacheMixin, ImageSettingsCacheMixin],
    data() {
        return {
            imageFiles: []
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'imagesAddedEvent'
        ]),
        imageUploadModelIsValid() {
            return !!this.imageFilterModelIsValid && this.imageFiles.length > 0;
        },
        imagePreviewSet() {
            return this.imageFiles.map(u => ({
                id: u.id,
                url: URL.createObjectURL(u)
            }));
        },
        imageUploadModel() {
            return Object.assign({}, this.imageFilterModel, {
                files: this.imageFiles
            });
        }
    },
    watch: {
        imagePreviewSet(newValue, oldValue) {
            for (var i = 0; i < oldValue.length; i++) {
                if (!newValue.some(v => v.id === oldValue[i].id)) {
                    URL.revokeObjectURL(oldValue[i].url);
                }
            }
        },
        imageFilterModel() {
            this.clearUploads();
        },
        imagesAddedEvent() {
            this.clearUploads();
        }
    },
    methods: {
        ...mapActions('Images', [
            'addImages'
        ]),
        onFilePicked(event) {
            for (var i = 0; i < event.target.files.length; i++) {
                event.target.files[i].id = HashHelper.randomId();
                this.imageFiles.push(event.target.files[i]);
            }
        },
        removeUpload(upload) {
            this.imageFiles = this.imageFiles.filter(u => u.id !== upload.id);
        },
        clearUploads() {
            this.imageFiles = [];
        },
        tryAddImages() {
            const self = this;
            self.error = '';
            if (!self.imageUploadModelIsValid) {
                return;
            }
            return self.addImages({ key: self.imageCacheKey, payload: self.imageUploadModel })
                .then(response => self.afterImagesAdded(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterImagesAdded() {
            // override this
        }
    }
}
