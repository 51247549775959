export const TaxHelper = {
    getDefaultTax() {
        return {
            rate: null,
            number: ''
        }
    },
    getTaxValidator() {
        return {
            isValid: false,
            rate: '',
            check(model) {
                const isDefault = JSON.stringify(model) === JSON.stringify(TaxHelper.getDefaultTax());
                const amount = parseFloat(model.rate);
                this.rate = !isDefault && (isNaN(amount) || amount < 0) ? 'Please enter a non-negative tax rate' : '';
                this.isValid = !isDefault && !this.rate;
            }
        }
    },
    getTaxValidatorV2() {
        return {
            isValid: false,
            rate: '',
            validate(model) {
                const rate = parseFloat(model.rate);
                this.rate = isNaN(rate) || rate < 0 ? 'Please enter a non-negative tax rate' : '';
                this.isValid = !this.rate;
            }
        }
    }
}
