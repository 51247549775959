<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <label>{{maxUploadSize}} {{$t('max upload size. Logo will be resized to fit 160x160px.')}}</label>
        <div v-if="!file" class="file-upload mw-400">
            <label>
                <span>{{$t('Browse files')}}</span>
                <input @change="onFilePicked" type="file" accept="image/*" />
            </label>
        </div>
        <div v-if="preview" class="image-uploads d-flex flex-column justify-content-center align-items-center">
            <ul>
                <li class="active">
                    <button @click="clearUpload" class="btn" type="button">
                        <i class="far fa-times"></i>
                    </button>
                    <figure>
                        <img :src="preview.url" width="120">
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { LogoAddMixin } from "../../Shared/LogoAddMixin";
    import { LogoSettingsCacheMixin } from "../../Shared/LogoSettingsCacheMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLogoAdd',
        mixins: [LogoAddMixin, LogoSettingsCacheMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ...mapState('Logos', [
                'addingLogo'
            ]),
            status() {
                return {
                    loading: this.addingLogo,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryAddLogo();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
