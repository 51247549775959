<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">Subscription Types<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <span v-if="fetchingSubscriptionTypes" class="inline-loader"></span>
            <div v-else class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="filter-dd" data-toggle="dropdown" type="button">
                    {{summary}}
                    <i class="far fa-chevron-down"></i>
                </button>
                <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                    <li v-for="subscriptionType in options" :key="subscriptionType.id" class="dropdown-item">
                        <label class="custom-control custom-checkbox">
                            <input v-model="subscriptionType.selected" @change="onChange" class="custom-control-input" type="checkbox">
                            <span class="custom-control-indicator"></span>
                            <p class="custom-control-description">{{subscriptionType.description}}</p>
                        </label>
                    </li>
                    <li @click="onSelectAll" class="dropdown-item pointer">
                        Select all
                    </li>
                    <li v-if="manySelected" @click="onClear" class="dropdown-item pointer">
                        Clear
                    </li>
                </ul>
            </div>
            <validation-message v-if="validationError" :message="validationError"></validation-message>
        </div>
    </div>
</template>

<script>
    import { SubscriptionTypesCacheMixin } from "../../mixins/Subscriptions/SubscriptionTypesCacheMixin";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";

    export default {
        name: 'SubscriptionMultiSelect',
        mixins: [SubscriptionTypesCacheMixin],
        props: {
            value: Array,
            validationError: String
        },
        computed: {
            options() {
                return this.subscriptionTypes.map(s => Object.assign({}, s, { selected: this.value.indexOf(s.id) > -1 }));
            },
            selectedOptions() {
                return this.options.filter(o => o.selected);
            },
            summary() {
                return this.selectedOptions.length > 0 ? this.selectedOptions.map(o => o.description).join(', ') : 'Please select...';
            },
            manySelected() {
                return this.selectedOptions.length > 0;
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.filter(o => o.selected).map(o => o.id));
            },
            onSelectAll() {
                this.$emit('input', this.options.map(o => o.id));
            },
            onClear() {
                this.$emit('input', []);
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
