<template>
    <div class="title-divider">
        <h2>{{appointmentTitle}}</h2>
    </div>
</template>

<script>
    import { PetDisplayMixin } from "../../Shared/Appointments/PetDisplayMixin";

    export default {
        name: 'EditOverrideTitle',
        mixins: [PetDisplayMixin],
        props: {
            appointment: Object
        },
        computed: {
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            petId() {
                return !!this.appointment ? this.appointment.petId : '';
            },
            sequenceNumber() {
                return !!this.appointment ? this.appointment.sequenceNumber + 1 : '';
            },
            title() {
                return this.lines.length > 0 ? this.lines[0] : 'Unknown';
            },
            appointmentTitle() {
                return 'Appointment ' + this.sequenceNumber + ': ' + this.title;
            }
        }
    }
</script>
