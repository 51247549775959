<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-pd" data-toggle="collapse">
            {{$t('Duration')}}&nbsp;
            <tooltip>
                <span>{{$t('This allows you to personalize the default appointment duration for this pet.')}}</span>
            </tooltip>
            <span v-if="loadingPetDuration" class="inline-loader"></span>
            <div class="ml-auto">
                <i class="far fa-chevron-down ml-auto"></i>
            </div>
        </div>
        <div class="collapse show" id="collapse-pd">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <!-- fields -->
                <div class="row">
                    <div class="col-6">
                        <label for="title">{{$t('Hours')}}</label>
                        <input v-model="hoursInput" @blur="processHours" class="form-control" type="number">
                    </div>
                    <div class="col-6">
                        <label for="title">{{$t('Minutes')}}</label>
                        <input v-model="minutesInput" @blur="processMinutes" class="form-control" type="number">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { PetDurationUploadMixin } from "./PetDurationUploadMixin";
    import { mapState } from 'vuex';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import Tooltip from "../../Shared/Layout/Tooltip.vue";

    export default {
        name: 'PetDuration',
        mixins: [PetDurationUploadMixin, StatusEmitterMixin],
        props: {
            pet: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent'
            ]),
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            status() {
                return {
                    loading: this.loadingPetDuration,
                    error: this.error
                }
            }
        },
        watch: {
            savePetEvent() {
                this.tryUploadPetDuration();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'tooltip': Tooltip
        }
    }
</script>
