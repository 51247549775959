<template>

</template>

<script>
    import { ErrorHelper } from "../../../helpers/ErrorHelper";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { Api } from "../../../services/Api";

    export default {
        name: 'PostRegisterSmsTemplates',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                error: '',
                loading: false,
                completed: false
            }
        },
        computed: {
            canAddSmsTemplates() {
                return !this.completed;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                }
            }
        },
        watch: {
            canAddSmsTemplates() {
                this.addDefaultSmsTemplates();
            }
        },
        methods: {
            addDefaultSmsTemplates() {
                const self = this;
                if (!self.canAddSmsTemplates) {
                    return;
                }
                self.error = '';
                self.loading = true;
                Api.post('Site/SmsTemplate')
                    .then(() => self.completed = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        created() {
            this.addDefaultSmsTemplates();
        }
    }
</script>
