import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    fetchedReferrer: false,
    fetchingReferrer: false,
    referrer: null,
    addingReferrer: false
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingReferrer = !!fetching;
        state.fetchedReferrer = false;
    },
    setReferrer(state, referrer) {
        state.referrer = referrer;
        state.fetchedReferrer = true;
        state.fetchingReferrer = false;
    },
    setAdding(state, adding) {
        state.addingReferrer = !!adding;
    }
}

const actions = {
    fetchReferrer({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Referrer')
                .then(response => {
                    commit('setReferrer', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setReferrer', null);
                        resolve(false);
                    } else {
                        commit('setFetching', false);
                        reject(error);
                    }
                });
        });
    },
    addReferrer({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Referrer')
                .then(response => {
                    commit('setReferrer', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
