import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { AppointmentDefaultsSettingMixin } from "./AppointmentDefaultsSettingMixin";

export const AppointmentDefaultsSettingUploadMixin = {
    mixins: [SettingUploadMixin, AppointmentDefaultsSettingMixin],
    data() {
        return {
            petDurationMinutes: 120
        }
    },
    computed: {
        settingEditModel() {
            return {
                petDurationMinutes: this.petDurationMinutes
            }
        },
        settingEditModelValid() {
            const model = this.settingEditModel;
            const petDurationMinutes = !isNaN(model.petDurationMinutes) && !(model.petDurationMinutes < 0);
            return petDurationMinutes;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.petDurationMinutes = this.settingObject.petDurationMinutes;
        }
    }
}
