<template>
    <tr>
        <td class="col-3">SMS</td>
        <td class="col-3">
            <span v-if="loadingClientOnboardingInvite" class="inline-loader"></span>
            {{$t(onboardingInviteStatus)}}
        </td>
        <td class="col-3">
            <span v-if="loadingCommunications" class="inline-loader"></span>
            {{$t(communicationStatus)}}
        </td>
        <td class="col-3">
            <template v-if="!clientOnboardingInviteCompleted">
                <button v-if="clientOnboardingInvite" style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary" @click="tryResend" v-bind:disabled="loading">
                    <i class="fal fa-paper-plane"></i> {{$t('Resend')}}
                </button>
                <button v-else style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary" @click="trySend" v-bind:disabled="loading">
                    <i class="fal fa-paper-plane"></i> {{$t('Send')}}
                </button>
            </template>
        </td>
    </tr>
</template>

<script>
    import { ClientOnboardingCommunicationMixin } from '../../mixins/ClientOnboardingInvites/ClientOnboardingCommunicationMixin.js';
    import { ClientCacheMixin } from '../Shared/ClientCacheMixin.js';

    export default {
        name: 'ClientOnboardingInviteSms',
        mixins: [ClientOnboardingCommunicationMixin, ClientCacheMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return !!this.id ? this.id : '';
            },
            methodTypeId() {
                return 2;
            },
            clientMobile() {
                return !!this.client ? this.client.telephoneMobile : '';
            }
        },
        methods: {
            tryResend() {
                if (!this.clientMobile) {
                    this.$emit('error', "Client has no mobile tel.");
                }
                else {
                    this.$emit('error', "");
                    this.tryUploadCommunication();
                }
            },
            trySend() {
                if (!this.clientMobile) {
                    this.$emit('error', "Client has no mobile tel.");
                }
                else {
                    this.$emit('error', "");
                    this.tryAddClientOnboardingInvite();
                }
            }
        }
    }
</script>
