export const ErrorHelper = {
    getError(axiosError) {
        return ErrorHelper.getErrorList(axiosError).join(' ');
    },
    isNotFound(axiosError) {
        return !!axiosError.response && axiosError.response.status === 404;
    },
    isNotAuthenticated(axiosError) {
        return !!axiosError.response && axiosError.response.status === 401;
    },
    getErrorList(axiosError) {
        const errors = [];
        if (!!axiosError.response && !!axiosError.response.data) {
            const errorData = axiosError.response.data;
            if (typeof errorData === 'string') {
                errors.push(errorData);
            } else if (!!errorData.errors) {
                const errorModel = errorData.errors;
                for (const property in errorModel) {
                    const messages = errorModel[property];
                    for (var i = 0; i < messages.length; i++) {
                        errors.push(messages[i]);
                    }
                }
            }
        }
        if (errors.length === 0) {
            errors.push('An unknown error occurred - please check your network connection.');
        }
        return errors;
    }
}
