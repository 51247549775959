<template>
    <div class="search-field">
        <input v-model="searchTerm" class="search-field-input form-control" :placeholder="$t('Search breed name')" type="search">
        <button class="search-field-btn" type="submit">
            <span v-if="loadingBreedSearch" class="inline-loader"></span>
            <template v-else>
                <i class="fas fa-search"></i>
            </template>
        </button>
        <search-results v-if="breedResultsReady" :breeds="breeds" :selected-id="selectedId" @clicked="onClicked"></search-results>
    </div>
</template>

<script>
    import { BreedSearchMixin } from "../../Shared/BreedSearchMixin";
    import BreedSearchResults from "./BreedSearchResults.vue";

    export default {
        name: 'BreedSearchField',
        mixins: [BreedSearchMixin],
        props: {
            value: Object
        },
        computed: {
            selectedId() {
                return !!this.value ? this.value.id : '';
            }
        },
        methods: {
            onClicked(breed) {
                this.$emit('input', Object.assign({}, breed));
                this.searchTerm = '';
            }
        },
        components: {
            'search-results': BreedSearchResults
        }
    }
</script>
