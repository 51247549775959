<template>
    <edit-resource v-model="resourceId" :appointment="appointment">
        <div class="form-group has-error has-feedback">
            <validation-message v-if="validationError" :message="validationError"></validation-message>
        </div>
    </edit-resource>
</template>

<script>
    import { AppointmentScheduleValidationMixin } from '../../Shared/AppointmentScheduleValidationMixin.js';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import moment from 'moment';
    import EditResource from '../EditAppointment/EditResource.vue';
    import { ResourceCacheMixin } from '../../Shared/Resources/ResourceCacheMixin.js';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'AppointmentResourceSelect',
        mixins: [AppointmentScheduleValidationMixin, ResourceCacheMixin],
        props: {
            index: Number
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'startUtc',
                'duration'
            ]),
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            validationErrorText() {
                return this.scheduleAddModelValidation.appointmentOrders[this.index].resourceId;
            },
            appointmentOrder() {
                return this.indexAppointmentOrder(this.index);
            },
            pet() {
                return this.appointmentOrder.pet;
            },
            endUtc() {
                const end = moment(this.startUtc).add(this.duration, 'minutes');
                return end.utc().format();
            },
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            resourceId: {
                get() {
                    return this.appointmentOrder.resourceId;
                },
                set(value) {
                    const resource = this.resources.find(r => r.id === value);
                    this.setResource({ index: this.index, resource: resource });
                }
            },
            appointment() {
                return {
                    startUtc: this.startUtc,
                    endUtc: this.endUtc,
                    petId: this.petId
                }
            }
        },
        watch: {
            resources() {
                this.tryAutoSetResource();
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setResource'
            ]),
            tryAutoSetResource() {
                if (!this.resourceId && this.resources.length === 1) {
                    this.resourceId = this.resources[0].id;
                }
            }
        },
        components: {
            'edit-resource': EditResource,
            'validation-message': ValidationMessage
        },
        created() {
            this.tryAutoSetResource();
        }
    }
</script>
