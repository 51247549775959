<template>
    <div v-if="displaySelect" class="form-group row">
        <div class="col-md-4">
            <label>{{title}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown">
                <button class="filter-dd" data-toggle="dropdown" type="button">
                    {{displayString}}
                    <i class="far fa-chevron-down"></i>
                </button>
                <div class="dropdown-menu">
                    <div v-for="site in paymentSiteOptions" :key="site.id" class="dropdown-item">
                        <label class="custom-control custom-checkbox">
                            <input v-model="site.selected" @change="onOptionsChanged" class="custom-control-input" type="checkbox">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{site.name}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";

    export default {
        name: 'PaymentSiteMultiSelect',
        mixins: [SiteCacheMixin],
        props: {
            value: Array,
            title: String,
            siteIds: Array
        },
        computed: {
            paymentSites() {
                return this.siteIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s);
            },
            paymentSiteOptions() {
                return this.paymentSites.map(s => Object.assign({}, s, { selected: this.value.indexOf(s.id) > -1 }))
            },
            displaySelect() {
                return this.paymentSites.length > 0;
            },
            displayString() {
                const selected = this.paymentSiteOptions.filter(o => o.selected).map(o => o.name);
                return selected.length > 0 ? selected.join(', ') : 'Locations';
            }
        },
        methods: {
            onOptionsChanged() {
                this.$emit('input', this.paymentSiteOptions.filter(o => o.selected).map(o => o.id));
            }
        }
    }
</script>
