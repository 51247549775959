<template>
    <div>
        <div class="row form-group">
            <div class="col-md-4 d-none d-md-block">
                <label for="title">{{$t('When')}}</label>
            </div>
            <div class="col-md-8">
                <picker v-model="start"></picker>
            </div>
        </div>
        <duration></duration>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin';
    import moment from 'moment';
    import AppointmentDuration from './AppointmentDuration.vue';
    import DateTimePicker from '../../Shared/Time/DateTimePicker.vue';

    export default {
        name: 'AppointmentDateSelect',
        mixins: [DateDisplayMixin],
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'startUtc'
            ]),
            start: {
                get() {
                    return this.startUtc;
                },
                set(value) {
                    this.setStartUtc(moment(value));
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setStartUtc'
            ])
        },
        components: {
            'duration': AppointmentDuration,
            'picker': DateTimePicker
        }
    }
</script>
