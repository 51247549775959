import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { UserHelper } from "../../helpers/UserHelper";

export const EmployeeUpdateMixin = {
    data() {
        return {
            error: '',
            empFirstName: '',
            empLastName: '',
            empIsDisabled: false,
            empEmail: '',
            empIsSiteAdmin: false,
            empCanDelete: false,
            empSiteIds: [],
            userUpdateValidator: UserHelper.getUserUpdateValidator()
        }
    },
    computed: {
        ...mapState('Employees', [
            'updatingEmployee',
            'employees'
        ]),
        employeeId() {
            console.log('!!!OVERRIDE COMPUTED - employeeId!!!');
        },
        employee() {
            return this.employees.find(e => e.id === this.employeeId);
        },
        employeeUpdateModel() {
            return !!this.employee ? Object.assign({}, this.employee, {
                firstName: this.empFirstName,
                lastName: this.empLastName,
                isDisabled: this.empIsDisabled,
                email: this.empEmail,
                isSiteAdmin: this.empIsSiteAdmin,
                canDelete: this.empCanDelete,
                siteIds: this.empSiteIds
            }) : {};
        },
        employeeUpdateModelValid() {
            return !!this.employeeUpdateModel.id && this.userUpdateValidator.isValid;
        },
        employeeUpdateModelModified() {
            return JSON.stringify(this.employeeUpdateModel) !== JSON.stringify(this.employee);
        }
    },
    watch: {
        employee() {
            this.error = '';
            this.refreshEmployeeModel();
        }
    },
    methods: {
        ...mapActions('Employees', [
            'updateEmployee',
            'deleteEmployee'
        ]),
        refreshEmployeeModel() {
            this.empFirstName = !!this.employee ? this.employee.firstName : '';
            this.empLastName = !!this.employee ? this.employee.lastName : '';
            this.empIsDisabled = !!this.employee ? this.employee.isDisabled : false;
            this.empEmail = !!this.employee ? this.employee.email : '';
            this.empIsSiteAdmin = !!this.employee ? this.employee.isSiteAdmin : false;
            this.empCanDelete = !!this.employee ? this.employee.canDelete : false;
            this.empSiteIds = !!this.employee ? this.employee.siteIds : [];
        },
        tryUpdateEmployee() {
            const self = this;
            self.userUpdateValidator.check(self.employeeUpdateModel);
            if (!self.employeeUpdateModelValid) {
                return;
            }
            if (!self.employeeUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateEmployee(self.employeeUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteEmployee() {
            const self = this;
            if (!self.employee) {
                return;
            }
            self.error = '';
            self.deleteEmployee(self.employee.id)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmployeeModel();
    }
}
