<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label>{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteValue" class="form-control" cols="50" :placeholder="$t('Add notes (optional)')" rows="3"></textarea>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'AppointmentOptionsNotes',
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'notes'
            ]),
            noteValue: {
                get() {
                    return this.notes;
                },
                set(value) {
                    this.setNotes(value);
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setNotes'
            ])
        }
    }
</script>