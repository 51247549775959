import router from '../../routing';
import moment from 'moment';
import { mapMutations } from 'vuex';
import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';
import { TimeDisplayMixin } from '../Shared/Time/TimeDisplayMixin';

export const BookingItemMixin = {
    mixins: [DateDisplayMixin, TimeDisplayMixin],
    props: {
        booking: Object
    },
    computed: {
        bookingId() {
            return !!this.booking ? this.booking.id : '';
        },
        createdDate() {
            return !!this.booking ? moment(this.booking.createdDate).format(this.momentJSFormat) : "-";
        },
        createdTime() {
            return !!this.booking ? moment(this.booking.createdDate).format(this.timeMomentJSFormat) : "-";
        },
        createdDateTime() {
            return this.createdDate + ' ' + this.createdTime;
        },
        appointmentDate() {
            return !!this.booking ? moment(this.booking.startUtc).format(this.momentJSFormat) : "-";
        },
        appointmentTime() {
            return !!this.booking ? moment(this.booking.startUtc).format(this.timeMomentJSFormat) : "-";
        },
        appointmentDateTime() {
            return this.appointmentDate + ' ' + this.appointmentTime;
        },
        firstName() {
            return !!this.booking ? this.booking.firstName : '';
        },
        lastName() {
            return !!this.booking ? this.booking.lastName : '';
        },
        startLetter() {
            return this.lastName.toUpperCase()[0];
        },
        email() {
            return !!this.booking ? this.booking.email : '';
        },
        mobile() {
            return !!this.booking ? this.booking.telephoneMobile : '';
        },
        contactSummary() {
            return this.email && this.mobile ? this.email + ' / ' + this.mobile : this.email;
        },
        status() {
            return !!this.booking ? this.booking.status : null;
        },
        statusDescription() {
            return !!this.status ? this.$t(this.status.description) : this.$t('Unknown');
        },
        clientType() {
            return !!this.booking ? this.booking.clientType : null;
        },
        clientTypeDescription() {
            const description = !!this.clientType ? this.clientType.description : 'Unknown';
            return this.$t(`${description} Client`);
        }
    },
    methods: {
        ...mapMutations('Bookings', [
            'setBooking'
        ]),
        gotoBooking() {
            if (!!this.booking) {
                this.setBooking(this.booking);
                router.push({ name: 'bookingEdit', params: { id: this.booking.id } });
            }
        }
    }
}
