import { Api } from "../../services/Api";

const state = () => ({
    creditPacksCaches: []
})

const getters = {
    findCreditPacksCache: (state) => key => state.creditPacksCaches.find(cpc => cpc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.creditPacksCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCreditPacks(state, { key, creditPacks }) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            existing.values = creditPacks;
            existing.loading = false;
        } else {
            state.creditPacksCaches.push({ key: key, values: creditPacks, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearCreditPacks(state) {
        state.creditPacksCaches = [];
    },
    appendCreditPack(state, { key, creditPack }) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            existing.values.push(creditPack);
        } else {
            state.creditPacksCaches.push({ key: key, values: [creditPack], loading: false });
        }
    },
    insertCreditPack(state, { key, creditPack }) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            const previous = existing.values.find(cp => cp.id === creditPack.id);
            if (!!previous) {
                const index = existing.values.indexOf(previous);
                existing.values.splice(index, 1, creditPack);
            } else {
                existing.values.push(creditPack);
            }
        } else {
            state.creditPacksCaches.push({ key: key, values: [creditPack], loading: false });
        }
    },
    removeCreditPack(state, { key, id }) {
        const existing = state.creditPacksCaches.find(cpc => cpc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(p => p.id !== id);
        }
    }
}

const actions = {
    findCreditPacks({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('CreditPack?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setCreditPacks', { key: key, creditPacks: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addSmsCreditPack({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('SmsSetting/CreditPack', payload)
                .then(response => {
                    commit('appendCreditPack', { key: key, creditPack: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addCampaignCreditPack({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('CampaignSetting/CreditPack', payload)
                .then(response => {
                    commit('appendCreditPack', { key: key, creditPack: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addEmailTemplateCreditPack({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('EmailTemplateSetting/CreditPack', payload)
                .then(response => {
                    commit('appendCreditPack', { key: key, creditPack: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateCreditPack({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('CreditPack', payload)
                .then(response => {
                    commit('insertCreditPack', { key: key, creditPack: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteCreditPack({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('CreditPack/' + id)
                .then(response => {
                    commit('removeCreditPack', { key: key, id: id });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
