<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="false" class="accordion-header collapsed" data-target="#collapse-tag-list" data-toggle="collapse">
            {{$t('Tags')}} ({{tags.length}})
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-tag-list">
            <div class="card card-body">
                <tags :owner-id="ownerId" :owner-type-id="ownerTypeId"></tags>
                <button @click="openTagAdd" class="btn btn-outline-secondary" type="button">
                    <i class="fal fa-edit"></i> {{$t('Edit')}}
                </button>
            </div>
        </div>
        <modal-dialog :title="$t('Add Tag')" ref="tagListModal">
            <section class="rounded-card-white mb-3">
                <tag-types :owner-id="ownerId" :owner-type-id="ownerTypeId"></tag-types>
            </section>
        </modal-dialog>
    </section>
</template>

<script>
    import { TagsCacheMixin } from "../TagsCacheMixin";
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import TagList from "./TagList.vue";
    import TagTypeList from "./TagTypeList.vue";

    export default {
        name: 'TagSection',
        mixins: [TagsCacheMixin],
        props: {
            ownerId: String,
            ownerTypeId: Number
        },
        methods: {
            openTagAdd() {
                const element = this.$refs.tagListModal.$el;
                $(element).modal('show');
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'tag-types': TagTypeList,
            'tags': TagList
        }
    }
</script>
