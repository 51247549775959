import { mapGetters } from 'vuex';

export const AppointmentCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Appointments', [
            'findAppointmentCache'
        ]),
        appointmentId() {
            console.log('OVERRIDE COMPUTED - appointmentId');
            return '';
        },
        appointmentCache() {
            return this.findAppointmentCache(this.appointmentId);
        },
        appointment() {
            return !!this.appointmentCache ? this.appointmentCache.value : null;
        },
        loadingAppointment() {
            return !!this.appointmentCache && this.appointmentCache.loading;
        }
    },
    watch: {
        appointmentId() {
            this.refreshAppointment();
        }
    },
    methods: {
        refreshAppointment() {
            if (!this.appointmentCache || !!this.appointmentCache.retry) {
                this.tryGetAppointment();
            }
        },
        tryGetAppointment() {
            // just here to override
        }
    },
    created() {
        this.refreshAppointment();
    }
}
