<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-cart-shopping fa-1x"></i>
                    {{$t('Payment Providers')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <page-loader v-if="loadingData"></page-loader>
            <div class="row">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="!isPaymentOwner" class="col text-center">
                    <div class="form-group">
                        {{$t('Click the button below to integrate this site with a payment service provider')}}
                    </div>
                    <button @click="register" class="btn btn-primary" type="button" v-bind:disabled="loadingData">
                        {{$t('Register')}}
                    </button>
                </div>
                <template v-else>
                    <div class="col">
                        <provider-list v-model="loadingProviders"></provider-list>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import SiteSelect from "../Account/SiteSelect.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";
    import ProviderInfoList from "./ProviderInfoList.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { PaymentUserAddMixin } from "../../mixins/Payments/PaymentUserAddMixin";
    import { PaymentApiUserOwnerUpdateMixin } from "../../mixins/Payments/PaymentApiUserOwnerUpdateMixin";

    export default {
        name: 'PaymentProviders',
        mixins: [PaymentApiUserOwnerUpdateMixin, PaymentUserAddMixin],
        data() {
            return {
                loadingProviders: false
            }
        },
        computed: {
            loadingData() {
                return this.fetchingPaymentApiUser || this.loadingPaymentApiUser || this.paymentUserPromise;
            }
        },
        methods: {
            register() {
                if (!!this.paymentApiUser) {
                    this.tryUpdatePaymentApiUserOwner();
                } else {
                    this.tryAddPaymentUserWithCompletion(this.tryUpdatePaymentApiUserOwner);
                }
            }
        },
        components: {
            'site-select': SiteSelect,
            'page-loader': PageLoader,
            'provider-list': ProviderInfoList,
            'error-banner': ErrorBanner
        },
        created() {
            const error = this.$route.query.error;
            if (!!error) {
                this.error = error;
            }
        }
    }
</script>
