<template>
    <center>
        <div class="pet-photo">
            <div v-if="showImages">
                <img v-if="imageSrc" alt="Resource image" :src="imageSrc" />
                <template v-else>
                    <img alt="Default" src="../../assets/images/Dog_Pink_BG_128.png" width="100">
                </template>
            </div>

            {{title}}
        </div>
    </center>
</template>

<script>
    import { ImageCacheMixin } from "../Shared/Images/ImageCacheMixin";
    import { UserSettingCacheMixin } from '../Shared/UserSettingCacheMixin';

    export default {
        name: 'CalendarResource',
        mixins: [ImageCacheMixin, UserSettingCacheMixin],
        props: {
            resource: Object
        },
        computed: {
            ownerId() {
                return this.resource.id;
            },
            ownerTypeId() {
                return 14;
            },
            defaultUserSettingValue() {
                return {
                    images: false
                }
            },
            userSettingKey() {
                return 'CalendarResource'
            },
            title() {
                return this.resource.title;
            },
            image() {
                return this.images.length > 0 ? this.images[0] : null;
            },
            imageSrc() {
                return !!this.image ? this.image.thumbnails.small : '';
            },
            showImages() {
                return this.userSettingValue.images;
            }
        },
        methods: {
            trySearchImages() {
                // do nothing
            }
        }
    }
</script>
