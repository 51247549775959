import { ErrorHelper } from '../../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';
import { SiteIdMixin } from '../Site/SiteIdMixin';
import { SortableSearchMixin } from '../Searching/SortableSearchMixin.js';

export const ResourceCacheMixin = {
    mixins: [SiteIdMixin, SortableSearchMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Resources', [
            'findResourceCache'
        ]),
        resourceCacheKey() {
            return this.siteId;
        },
        resourceCache() {
            return this.findResourceCache(this.resourceCacheKey);
        },
        resourceFilterModel() {
            return {
                siteId: this.siteId
            }
        },
        resources() {
            return !!this.resourceCache ? this.resourceCache.values.filter(r => !r.hidden) : [];
        },
        loadingResources() {
            return !!this.resourceCache && this.resourceCache.loading;
        },
        completedResources() {
            return !!this.resourceCache && !this.resourceCache.loading;
        }
    },
    watch: {
        resourceFilterModel() {
            this.refreshResources();
        }
    },
    methods: {
        ...mapActions('Resources', [
            'getResources'
        ]),
        refreshResources() {
            if (!this.resourceCache || !!this.resourceCache.retry) {
                this.tryGetResources();
            }
        },
        tryGetResources() {
            const self = this;
            if (!this.siteId) {
                return;
            }
            self.error = '';
            self.getResources({ key: self.resourceCacheKey, payload: self.resourceFilterModel })
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshResources();
    }
}
