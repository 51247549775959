import { Api } from '../../services/Api';

const state = () => ({
    userResourceCaches: []
})

const getters = {
    findUserResourceCache: (state) => key => {
        return state.userResourceCaches.find(urc => urc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.userResourceCaches.find(urc => urc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.userResourceCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setUserResources(state, { key, resources }) {
        const existing = state.userResourceCaches.find(urc => urc.key === key);
        if (!!existing) {
            existing.values = resources;
            existing.loading = false;
        } else {
            state.userResourceCaches.push({ key: key, values: resources, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.userResourceCaches.find(urc => urc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendUserResources(state, { key, resources }) {
        const existing = state.userResourceCaches.find(urc => urc.key === key);
        if (!!existing) {
            for (var i = 0; i < resources.length; i++) {
                existing.values.push(resources[i]);
            }
        } else {
            state.userResourceCaches.push({ key: key, values: resources, loading: false });
        }
    },
    removeUserResources(state, key) {
        const existing = state.userResourceCaches.find(urc => urc.key === key);
        if (!!existing) {
            existing.values = existing.values.map(r => Object.assign({}, r, { hidden: true }));
        } else {
            state.userResourceCaches.push({ key: key, values: [], loading: false });
        }
    }
}

const actions = {
    getUserResources({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('User/' + id + '/Resource')
                .then(response => {
                    commit('setUserResources', { key: id, resources: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                });
        });
    },
    addUserResources({ commit }, { id, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.post('User/Resource', payload)
                .then(response => {
                    commit('appendUserResources', { key: id, resources: response.data });
                    commit('Resources/clearResourcesCache', null, { root: true });
                    commit('ApplicationEvents/triggerUserResourcesAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: id, loading: false }));
        });
    },
    deleteUserResources({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.delete('User/' + id + '/Resource')
                .then(response => {
                    commit('removeUserResources', id);
                    commit('Resources/clearResourcesCache', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: id, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
