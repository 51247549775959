<template>
    <div class="form-group">
        <div class="search-field">
            <input v-model="searchTerm" class="search-field-input form-control" :placeholder="$t('Search country')" type="search">
            <button class="search-field-btn" type="submit">
                <span v-if="searchingCountryOptions" class="inline-loader"></span>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </button>
            <search-results v-if="showCountryResults" :countries="countryOptions" :selected-id="selectedId" @selected="onSelected"></search-results>
        </div>
    </div>
</template>

<script>
    import { CountryOptionSearchMixin } from "../../Shared/Country/CountryOptionSearchMixin";
    import CountryOptionSearchResults from './CountryOptionSearchResults.vue';

    export default {
        name: 'CountryOptionSearch',
        mixins: [CountryOptionSearchMixin],
        props: {
            value: Object
        },
        computed: {
            selectedId() {
                return !!this.value ? this.value.id : '';
            }
        },
        methods: {
            onSelected(country) {
                this.$emit('input', country);
            }
        },
        components: {
            'search-results': CountryOptionSearchResults
        }
    }
</script>
