import { mapActions, mapGetters } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const PaymentReceiptSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentReceipts', [
            'findPaymentReceiptCache',
        ]),
        subscriptionPaymentIds() {
            console.log('!!!OVERRIDE COMPUTED - subscriptionPaymentIds!!!');
            return [];
        },
        missingSubscriptionPaymentIds() {
            return this.subscriptionPaymentIds.filter(id => !this.findPaymentReceiptCache(id));
        },
        paymentReceiptSearchModel() {
            return {
                subscriptionPaymentIds: this.missingSubscriptionPaymentIds
            }
        },
        paymentReceiptSearchModelValid() {
            return this.paymentReceiptSearchModel.subscriptionPaymentIds.length > 0;
        }
    },
    watch: {
        paymentReceiptSearchModel() {
            this.trySearchPaymentReceipts();
        }
    },
    methods: {
        ...mapActions('PaymentReceipts', [
            'searchPaymentReceipts'
        ]),
        trySearchPaymentReceipts() {
            const self = this;
            if (!self.paymentReceiptSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchPaymentReceipts({ keys: self.missingSubscriptionPaymentIds, payload: self.paymentReceiptSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
