<template>
    <tr>
        <td>
            <div class="client-row">
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{fullName}}
                    </div>
                    <div class="crd-contact">
                        {{communicationTypeDescription}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Method')}}: {{methodTypeDescription}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Sent')}}: {{lastUpdatedDateTime}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CommunicationItemMixin } from "./CommunicationItemMixin";

    export default {
        name: 'CommunicationItemMobile',
        mixins: [CommunicationItemMixin]
    }
</script>
