import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentClientCheckoutCaches: []
});

const getters = {
    findPaymentClientCheckoutsCache: (state) => key => state.paymentClientCheckoutCaches.find(pccc => pccc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentClientCheckoutCaches.find(pccc => pccc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentClientCheckoutCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setClientCheckouts(state, { key, checkouts }) {
        const existing = state.paymentClientCheckoutCaches.find(pccc => pccc.key === key);
        if (!!existing) {
            existing.values = checkouts;
            existing.loading = false;
        } else {
            state.paymentClientCheckoutCaches.push({ key: key, values: checkouts, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentClientCheckoutCaches.find(pccc => pccc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    insertClientCheckout(state, { key, checkout }) {
        const existing = state.paymentClientCheckoutCaches.find(pccc => pccc.key === key);
        if (!!existing) {
            const previous = existing.values.find(c => c.id === checkout.id);
            if (!!previous) {
                const index = existing.values.indexOf(previous);
                existing.values.splice(index, 1, checkout);
            } else {
                existing.values.push(checkout);
            }
        } else {
            state.paymentClientCheckoutCaches.push({ key: key, values: [checkout], loading: false });
        }
    }
}

const actions = {
    findClientCheckouts({ commit }, reference) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: reference, loading: true });
            PaymentApi.get('ClientCheckout/' + reference)
                .then(response => {
                    commit('setClientCheckouts', { key: reference, checkouts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', reference);
                    reject(error);
                });
        });
    },
    updateClientCheckout({ commit }, { reference, checkout }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: reference, loading: true });
            PaymentApi.put('ClientCheckout/' + reference, checkout)
                .then(response => {
                    commit('insertClientCheckout', { key: reference, checkout: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', reference);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
