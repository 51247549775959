import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ResourceAddMixin } from "./ResourceAddMixin";
import { mapActions } from 'vuex';

export const ResourceUploadMixin = {
    mixins: [ResourceAddMixin],
    props: {
        resourceId: String
    },
    computed: {
        resource() {
            return this.resources.find(r => r.id === this.resourceId);
        },
        resourceUpdateModel() {
            return !!this.resource ? Object.assign({}, this.resource, {
                name: this.name,
                colour: this.colour
            }) : {};
        },
        resourceUpdateModelValid() {
            return !!this.resourceUpdateModel.id && this.resourceValidator.isValid;
        },
        resourceUpdateModelModified() {
            return JSON.stringify(this.resourceUpdateModel) !== JSON.stringify(this.resource);
        }
    },
    watch: {
        resource() {
            this.refreshResourceModel();
        }
    },
    methods: {
        ...mapActions('Resources', [
            'updateResource',
            'deleteResource'
        ]),
        refreshResourceModel() {
            this.name = !!this.resource ? this.resource.name : '';
            this.colour = !!this.resource ? this.resource.colour : '#FFE781';
            this.priorityOrder = !!this.resource ? this.resource.priorityOrder : 0;
        },
        tryUploadResource() {
            if (!!this.resource) {
                this.tryUpdateResource();
            } else {
                this.tryAddResource();
            }
        },
        tryUpdateResource() {
            const self = this;
            self.resourceValidator.check(self.resourceUpdateModel);
            if (!self.resourceUpdateModelValid) {
                return;
            }
            if (!self.resourceUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateResource({ key: self.resourceCacheKey, payload: self.resourceUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteResource() {
            const self = this;
            if (!self.resource) {
                return;
            }
            self.error = '';
            self.deleteResource({ key: self.resourceCacheKey, id: self.resource.id })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
