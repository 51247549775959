<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="success" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('An email has been sent to your user email with a link to reset your password.')}}</p>
            </div>
            <button @click="clear" type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <template v-else>
            <fieldset>
                <language-select></language-select>
                <div class="input-group form-group auth">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fal fa-envelope"></i></span>
                    </div>
                    <input v-model="email" type="email" class="form-control" :placeholder="$t('Please enter your email')" aria-label="Email">
                </div>
                <div class="form-group mt-4">
                    <button @click="submit" class="btn btn-secondary-yellow w-100" v-bind:disabled="!valid">
                        {{$t('Send link')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </fieldset>
        </template>
        <div class="row bottom-links">
            <div class="col text-left">
                <router-link :to="{ name: 'auth' }">{{$t('Back to login')}}</router-link>
            </div>
            <div class="col text-right">
                {{$t('Not registered?')}} <router-link :to="{ name: 'register' }">{{$t('Signup')}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from '../../helpers/ErrorHelper.js';
    import { EmailHelper } from '../../helpers/EmailHelper.js';
    import { Api } from '../../services/Api.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import LanguageSelect from '../Account/LanguageSelect.vue';

    export default {
        name: 'ForgotPassword',
        data() {
            return {
                error: '',
                success: false,
                email: '',
                loading: false
            }
        },
        computed: {
            uploadModel() {
                return {
                    email: this.email
                }
            },
            valid() {
                return EmailHelper.isValid(this.email);
            }
        },
        methods: {
            submit() {
                const self = this;
                self.error = '';
                self.success = false;
                self.loading = true;
                Api.post('Credentials/Reset/Send', self.uploadModel)
                    .then(() => self.success = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            clear() {
                this.email = '';
                this.success = false;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'language-select': LanguageSelect
        }
    }
</script>

<style>
</style>
