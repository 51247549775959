<template>
    <display-tags :tags="campaignTags"></display-tags>
</template>

<script>
    import { CampaignTagsMixin } from "../../../mixins/Campaigns/CampaignTagsMixin";
    import TagsDisplay from "../../Shared/Tags/TagsDisplay.vue";

    export default {
        name: 'CampaignTemplateTags',
        mixins: [CampaignTagsMixin],
        components: {
            'display-tags': TagsDisplay
        }
    }
</script>
