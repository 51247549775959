<template>
    <button v-if="resources.length > 0" @click="openPriorityOrder" class="btn-filter fa-regular fa-sort mr-md-3 mx-0" type="button">
    </button>
</template>

<script>
    import { ResourceCacheMixin } from '../Shared/Resources/ResourceCacheMixin';

    export default {
        name: 'ResourcePriorityOrderButton',
        mixins: [ResourceCacheMixin],
        methods: {
            openPriorityOrder() {
                this.$emit('click')
            }
        }
    }
</script>
