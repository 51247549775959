<template>
    <div></div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { BookingApproveMixin } from './BookingApproveMixin';
    import { BookingCacheMixin } from '../Shared/BookingCacheMixin';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin'

    export default {
        name: 'BookingApprove',
        mixins: [BookingApproveMixin, BookingCacheMixin, StatusEmitterMixin],
        props: {
            id: '',
            ordersStatus: {}
        },
        data() {
            return {
                bookingApproved: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'approveBookingEvent'
            ]),
            ordersCompleted() {
                return !!this.ordersStatus && !!this.ordersStatus.completed;
            },
            bookingId() {
                return !!this.id ? this.id : '';
            },
            loading() {
                return !!this.loadingBooking;
            },
            completed() {
                return !!this.bookingApproved;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canApproveBooking() {
                return !this.completed && !!this.ordersCompleted;
            }
        },
        watch: {
            ordersCompleted(value) {
                if(!!value) {
                    this.onValidatedChanged();
                }
            },
            approveBookingEvent() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canApproveBooking) {
                    return;
                }
                this.tryApproveBooking();
            },
            afterApproveBooking(){
                this.bookingApproved = true;
            }
        }
    }
</script>
