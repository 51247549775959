import { mapActions } from "vuex"
import { CommunicationHelper } from "../../helpers/CommunicationHelper"
import { ErrorHelper } from "../../helpers/ErrorHelper"
import { SmsTemplateCacheMixin } from "./SmsTemplateCacheMixin"

export const SmsTemplateUploadMixin = {
    mixins: [SmsTemplateCacheMixin],
    data() {
        return {
            value: '',
            smsTemplateValidator: CommunicationHelper.getSmsTemplateValidator()
        }
    },
    computed: {
        smsTemplateAddModel() {
            return {
                communicationTypeId: this.communicationTypeId,
                value: this.value
            }
        },
        smsTemplateUpdateModel() {
            return Object.assign({}, this.smsTemplate, { value: this.value });
        },
        loading() {
            return !!this.loadingSmsTemplate || !!this.loadingSmsDefault
        }
    },
    watch: {
        smsTemplate() {
            this.refreshSmsTemplateUpdateModel();
        },
        smsDefaultValue() {
            this.refreshSmsTemplateUpdateModel();
        }
    },
    methods: {
        ...mapActions('SmsTemplates', [
            'addSmsTemplate',
            'updateSmsTemplate'
        ]),
        uploadSmsTemplate() {
            if (!this.loadingSmsTemplate) {
                if (!!this.smsTemplate) {
                    this.updateAndCacheSmsTemplate();
                } else {
                    this.addAndCacheSmsTemplate();
                }
            }
        },
        refreshSmsTemplateUpdateModel() {
            this.value = !!this.smsTemplate ? this.smsTemplate.value : this.smsDefaultValue;
        },
        addAndCacheSmsTemplate() {
            const self = this;
            self.error = '';
            self.smsTemplateValidator.check(self.smsTemplateAddModel);
            if (!self.smsTemplateValidator.isValid) {
                return;
            }
            self.addSmsTemplate({ key: self.smsTemplateCacheKey, payload: self.smsTemplateAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        updateAndCacheSmsTemplate() {
            const self = this;
            self.error = '';
            self.smsTemplateValidator.check(self.smsTemplateUpdateModel);
            if (!self.smsTemplateValidator.isValid) {
                return;
            }
            if (JSON.stringify(self.smsTemplate) === JSON.stringify(self.smsTemplateUpdateModel)) {
                return;
            }
            self.updateSmsTemplate({ key: self.smsTemplateCacheKey, payload: self.smsTemplateUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsTemplateUpdateModel();
    }
}