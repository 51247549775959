import { ResourceCacheMixin } from "../Shared/Resources/ResourceCacheMixin";
import { SettingUploadMixin } from "../Shared/SettingUploadMixin";

export const PetDetailsPreferrredMixin = {
    mixins: [SettingUploadMixin, ResourceCacheMixin],
    data() {
        return {
            id: ''
        }
    },
    computed: {
        ownerTypeId() {
            return 3;
        },
        settingKey() {
            return 'PreferredResource';
        },
        defaultSettingObject() {
            return {
                id: ''
            }
        },
        settingEditModel() {
            return {
                id: this.id
            }
        },
        settingEditModelValid() {
            return !!this.settingEditModel.id;
        },
        settingEditModelModified() {
            return this.settingEditModel.id !== this.settingObject.id;
        },
        selectedResource: {
            get() {
                return this.resources.find(r => r.id === this.id);
            },
            set(value) {
                this.id = value.id;
            }
        },
        selectedId() {
            return !!this.selectedResource ? this.selectedResource.id : '';
        },
        selectedName() {
            return !!this.selectedResource ? this.selectedResource.name : `${this.$t('Please select')}...`;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.id = this.settingObject.id;
        }
    }
}
