/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';
import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';

export const BookingDeleteMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: '',
            deleting: false
        }
    },
    computed: {
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'deleteBooking'
        ]),
        tryDeleteBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.deleting = true;
            self.deleteBooking({ id: self.bookingId, siteId: self.siteId })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.deleting = false);
        },
    }
}
