<template>
    <div class="mw-400">
        <div class="row">
            <div class="col-12 col-md-6">
                <label for="title">{{$t('Date')}}</label>
                <date-picker v-model="date"></date-picker>
            </div>
            <div class="col-12 col-md-6 mt-4 mb-2 my-md-0">
                <label for="title">{{$t('Time')}}</label>
                <time-picker v-model="time" :format="timeMomentJSFormat" class="w-100"></time-picker>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from 'moment-timezone';
    import MyDatePicker from './MyDatePicker.vue';
    import MyTimePicker from './MyTimePicker.vue';
    import { TimeDisplayMixin } from './TimeDisplayMixin';

    export default {
        name: 'DateTimePicker',
        mixins: [TimeDisplayMixin],
        props: {
            value: String
        },
        computed: {
            ...mapState('TimeZones', [
                'selectedTimeZoneId'
            ]),
            date: {
                get() {
                    return moment(this.value).toDate();
                },
                set(value) {
                    this.$emit('input', moment(value).utc().format());
                }
            },
            time: {
                get() {
                    return moment(this.value).format(this.timeMomentJSFormat);
                },
                set(value) {
                    const update = moment(value, this.timeMomentJSFormat);
                    const existing = moment(this.value);
                    existing.hour(update.hour());
                    existing.minute(update.minute());
                    this.$emit('input', existing.utc().format());
                }
            }
        },
        watch: {
            selectedTimeZoneId(newValue, oldValue) {
                const newOffset = moment.tz.zone(newValue).utcOffset(this.date);
                const oldOffset = moment.tz.zone(oldValue).utcOffset(this.date);
                this.$emit('input', moment(this.date).add(newOffset - oldOffset, 'minutes').utc().format());
            }
        },
        components: {
            'date-picker': MyDatePicker,
            'time-picker': MyTimePicker
        }
    }
</script>
