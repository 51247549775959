import { Api } from '../../services/Api';
import router from '../../routing';
import { RoutingHelper } from '../../helpers/RoutingHelper';

const state = () => ({
    user: null,
    loading: false
})

const getters = {
    isLoading: state => state.loading
}

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setLoading(state, loading) {
        state.loading = !!loading;
    }
}

const actions = {
    login({ commit }, request) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.post('Authentication/Credentials/Login', request)
                .then(response => {
                    commit('setUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    register({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.post('Registration', payload)
                .then(response => {
                    commit('setUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    logout({ commit, getters }, queryObj) {
        if (getters.isLoading) {
            return;
        }
        commit('setLoading', true);
        Api.post('Authentication/Credentials/Logout')
            .then(() => {
                if (RoutingHelper.requiresNavigateToLogin(router.currentRoute)) {
                    router.push({ name: 'auth', query: queryObj })
                        .then(() => window.location.reload());
                }
            })
            .finally(() => commit('setLoading', false));
    },
    getUser({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('User')
                .then(response => {
                    commit('setUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
