import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SelfUserResourcesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SelfUserResources', [
            'fetchedSelfUserResources',
            'fetchingSelfUserResources',
            'selfUserResources'
        ])
    },
    methods: {
        ...mapActions('SelfUserResources', [
            'fetchSelfUserResources'
        ]),
        refreshSelfUserResources() {
            if (!this.fetchedSelfUserResources && !this.fetchingSelfUserResources) {
                this.tryFetchSelfUserResources();
            }
        },
        tryFetchSelfUserResources() {
            const self = this;
            self.error = '';
            self.fetchSelfUserResources()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSelfUserResources();
    }
}
