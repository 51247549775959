import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const SiteDepositSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Deposit';
        },
        defaultSettingObject() {
            return {
                isRequired: false,
                isPercentage: false,
                percentage: 0,
                fixedAmount: 0,
                hasDueDate: false,
                daysPrior: 0
            }
        }
    }
}
