<template>
    <country-format v-if="country" v-model="countryFormatStatus"></country-format>
</template>

<script>
    import { mapActions } from "vuex";
    import { ErrorHelper } from "../../../helpers/ErrorHelper";
    import { CountryCacheMixin } from "../../Shared/Country/CountryCacheMixin";
    import PostRegisterCountryFormat from "./PostRegisterCountryFormat.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'PostRegisterCountry',
        mixins: [CountryCacheMixin, StatusEmitterMixin],
        data() {
            return {
                countryFormatStatus: {}
            }
        },
        props: {
            countryId: String
        },
        computed: {
            countryComplete() {
                return !!this.country || !this.countryId;
            },
            countryFormatComplete() {
                return this.countryFormatStatus.completed || !!this.countryFormatStatus.error || !this.countryId;
            },
            canAddCountry() {
                return !this.countryComplete
                    && !!this.loadedCountries
                    && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: !!this.loadingCountries || !!this.countryFormatStatus.loading,
                    completed: this.countryComplete && this.countryFormatComplete
                }
            }
        },
        watch: {
            canAddCountry(newValue) {
                if (!!newValue) {
                    this.tryAddCountry();
                }
            }
        },
        methods: {
            ...mapActions('Countries', [
                'addCountry'
            ]),
            tryAddCountry() {
                const self = this;
                if (!self.canAddCountry) {
                    return;
                }
                self.error = '';
                self.addCountry(self.countryId)
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        components: {
            'country-format': PostRegisterCountryFormat
        },
        created() {
            this.tryAddCountry();
        }
    }
</script>
