<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-2" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Security')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-2">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="success" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>{{$t('An email has been sent to your user email with a link to reset your password.')}}</p>
                    </div>
                </div>
                <div class="form-group">
                    <button @click="submit" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        {{$t('Reset password')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'SecurityOptions',
        data() {
            return {
                error: '',
                loading: false,
                success: false
            }
        },
        methods: {
            submit() {
                const self = this;
                self.error = '';
                self.loading = true;
                self.success = false;
                Api.post('Credentials/User/Reset/Send')
                    .then(() => self.success = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>