import { Api } from "../../services/Api";

const state = () => ({
    loadingSubjects: false,
    loadedSubjects: false,
    emailMarketingDefaultSubjects: []
})

const getters = {
    findEmailMarketingDefaultSubjectCache: (state) => communicationTypeId => {
        return state.emailMarketingDefaultSubjects.find(s => s.communicationType.id === communicationTypeId);
    }
}

const mutations = {
    setSubjects(state, subjects) {
        state.emailMarketingDefaultSubjects = subjects;
        state.loadedSubjects = true;
    },
    setLoading(state, value) {
        state.loadingSubjects = !!value;
    }
}

const actions = {
    getEmailMarketingDefaultSubjects({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('EmailMarketingTemplate/Subject')
                .then(response => {
                    commit('setSubjects', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
