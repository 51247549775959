import { Api } from "../../services/Api";

const state = () => ({
    addingTax: false,
    updatingTax: false,
    loadingTaxes: false,
    loadedTaxes: false,
    taxes: []
})

const getters = {
    findTax: (state) => siteId => {
        return state.taxes.find(t => t.siteId === siteId);
    }
}

const mutations = {
    setTaxes(state, taxes) {
        state.taxes = taxes;
        state.loadedTaxes = true;
        state.loadingTaxes = false;
    },
    setLoading(state, value) {
        state.loadingTaxes = !!value;
    },
    setAdding(state, value) {
        state.addingTax = !!value;
    },
    setUpdating(state, value) {
        state.updatingTax = !!value;
    },
    appendTax(state, tax) {
        state.taxes.push(tax);
    },
    insertTax(state, tax) {
        const existing = state.taxes.find(t => t.id === tax.id);
        state.taxes.splice(state.taxes.indexOf(existing), 1, tax);
    }
}

const actions = {
    getTaxes({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('Tax')
                .then(response => {
                    commit('setTaxes', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    addTax({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Tax', payload)
                .then(response => {
                    commit('appendTax', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateTax({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Tax', payload)
                .then(response => {
                    commit('insertTax', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
