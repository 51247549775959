<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Duration')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{ `${getOptionNameValue(selectedOptionName)} ${$t(getOptionNameUnit(selectedOptionName))}` }}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in combinedOptions"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setPetDurationMinutes(option.value)"
                            v-bind:class="{'active': option.value === selectedOptionValue}">
                            {{ `${getOptionNameValue(option.name)} ${$t(getOptionNameUnit(option.name))}` }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { AppointmentDefaultsSettingUploadMixin } from "../../../mixins/Settings/Site/AppointmentDefaultsSettingUploadMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { AppointmentDurationOptionsMixin } from "../../Scheduler/AppointmentDurationOptionsMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateAppointmentDuration',
        mixins: [AppointmentDefaultsSettingUploadMixin, AppointmentDurationOptionsMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingSetting,
                    error: this.error
                }
            },
            selectedOption() {
                return this.getOptionForDuration(this.petDurationMinutes);
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.selectedOption.name : '15 mins'
            },
            selectedOptionValue() {
                return !!this.selectedOption ? this.selectedOption.value : 15;
            }
        },
        methods: {
            setPetDurationMinutes(value) {
                this.petDurationMinutes = value;
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
