import { mapState, mapActions } from 'vuex';
import { ClientHelper } from "../../helpers/ClientHelper"
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { MobileValidatorMixin } from './MobileValidatorMixin';

export const ClientAddMixin = {
    mixins: [MobileValidatorMixin],
    data() {
        return {
            error: '',
            firstName: '',
            lastName: '',
            email: '',
            telephoneHome: '',
            telephoneWork: '',
            telephoneMobile: '',
            clientValidator: ClientHelper.getClientValidator()
        }
    },
    computed: {
        ...mapState('Clients', [
            'addingClient'
        ]),
        clientAddModel() {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                telephoneHome: this.telephoneHome,
                telephoneWork: this.telephoneWork,
                telephoneMobile: this.telephoneMobile
            }
        },
        clientAddModelValid() {
            return this.clientValidator.isValid && !this.mobileValidation;
        }
    },
    methods: {
        ...mapActions('Clients', [
            'addClient'
        ]),
        tryAddClient() {
            const self = this;
            self.clientValidator.check(self.clientAddModel);
            self.validateMobile(self.telephoneMobile);
            if (!self.clientAddModelValid) {
                return;
            }
            self.error = '';
            self.addClient(self.clientAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
