<template>
    <modal-dialog :title="title" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="deleting" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{$t('Are you sure you want to delete this service from')}} {{siteName}}?</p>
            </div>
            <button @click="tryDeleteServiceProduct" v-bind:disabled="groupingServiceProducts" type="button" class="btn btn-danger" style="margin-right: 10px;">
                {{$t('Yes')}}
            </button>
            <button @click="deleting=false" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <section class="rounded-card-white mb-3">
            <site-select v-model="siteIds" :validation-error="serviceProductValidator.siteIds"></site-select>
            <slotted-input v-model="name" :title="$t('Name')">
                <label v-if="serviceProductValidator.name" class="error">{{serviceProductValidator.name}}</label>
            </slotted-input>
            <amount-input v-model="amount" :validation="serviceProductValidator.amount"></amount-input>
            <toggle-input v-model="publishedOnline" :title="$t('Published Online?')"></toggle-input>
            <description-input v-model="description"></description-input>
        </section>
        <div class="modal-footer">
            <button @click="tryUploadServiceProducts" v-bind:disabled="groupingServiceProducts" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="groupingServiceProducts" class="inline-loader"></span>
            </button>
            <delete-button @clicked="deleting=true"></delete-button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { ServiceProductsUploadMixin } from '../../mixins/Products/ServiceProductsUploadMixin';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ProductAmountInput from './ProductAmountInput.vue';
    import ProductDescriptionInput from './ProductDescriptionInput.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import SlottedInput from '../Shared/Input/SlottedInput.vue';
    import SiteMultiSelect from '../Shared/Site/SiteMultiSelect.vue';
    import SlottedToggle from '../Shared/Input/SlottedToggle.vue';

    export default {
        name: 'ServiceUpdate',
        mixins: [ServiceProductsUploadMixin],
        data() {
            return {
                deleting: false
            }
        },
        props: {
            product: Object
        },
        computed: {
            productId() {
                return !!this.product ? this.product.productId : '';
            },
            productName() {
                return !!this.product ? this.product.name : 'Unknown';
            },
            title() {
                return `${this.$t('Update')} ` + this.productName;
            }
        },
        methods: {
            onOpened() {
                this.deleting = false;
            },
            afterDeletingServiceProduct() {
                this.$refs.closeModal.click();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'site-select': SiteMultiSelect,
            'slotted-input': SlottedInput,
            'amount-input': ProductAmountInput,
            'description-input': ProductDescriptionInput,
            'delete-button': DeleteButton,
            'error-banner': ErrorBanner,
            'toggle-input': SlottedToggle
        }
    }
</script>
