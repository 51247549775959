import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const TagTypesCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('TagTypes', [
            'findTagTypesCache'
        ]),
        tagTypesFilterModel() {
            return {
                siteId: this.siteId
            }
        },
        tagTypesFilterModelValid() {
            return !!this.tagTypesFilterModel.siteId;
        },
        tagTypesCacheKey() {
            return CacheHelper.objectCacheKey(this.tagTypesFilterModel);
        },
        tagTypesCache() {
            return this.findTagTypesCache(this.tagTypesCacheKey);
        },
        tagTypes() {
            return !!this.tagTypesCache ? this.tagTypesCache.values : [];
        },
        loadingTagTypes() {
            return !!this.tagTypesCache && this.tagTypesCache.loading;
        }
    },
    watch: {
        tagTypesFilterModel() {
            this.refreshTagTypes();
        }
    },
    methods: {
        ...mapActions('TagTypes', [
            'findTagTypes'
        ]),
        refreshTagTypes() {
            if (!this.tagTypesCache || !!this.tagTypesCache.retry) {
                this.findAndCacheTagTypes();
            }
        },
        findAndCacheTagTypes() {
            const self = this;
            if (!self.tagTypesFilterModelValid) {
                return;
            }
            self.error = '';
            self.findTagTypes({ key: self.tagTypesCacheKey, payload: self.tagTypesFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTagTypes();
    }
}
