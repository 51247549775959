import { Api } from '../../services/Api';

const state = () => ({
    productSearchCaches: [],
    productSearchesCleared: 0
})

const getters = {
    findProductSearch: (state) => (key) => state.productSearchCaches.find(pc => pc.key === key),
    findProductSearches: (state) => (key) => state.productSearchCaches.filter(pc => pc.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.productSearchCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.productSearchCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setSearch(state, { key, products }) {
        const existing = state.productSearchCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.values = products;
            existing.loading = false;
        } else {
            state.productSearchCaches.push({ key: key, values: products, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.productSearchCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearProductSearches(state) {
        state.productSearchCaches = [];
        state.productSearchesCleared++;
    }
}

const actions = {
    searchProducts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Product/Search', payload)
                .then(response => {
                    commit('setSearch', { key: key, products: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
