<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ClientOnboardingInviteAddMixin } from '../../../mixins/ClientOnboardingInvites/ClientOnboardingInviteAddMixin';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'NewClientOnboardingInviteAdd',
        mixins: [ClientOnboardingInviteAddMixin],
        props: {
            savingType: Number,
            petsStatus: {},
            addedClientId: ''
        },
        data() {
            return {
                addedClientOnboardingInviteId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent',
                'clientOnboardingInviteAddedEvent'
            ]),
            clientId() {
                return this.addedClientId;
            },
            addedPets() {
                return !!this.petsStatus ? this.petsStatus.completed : false;
            },
            completed() {
                return !!this.addedClientOnboardingInviteId || (!!this.clientId & !this.savingType);
            },
            status() {
                return {
                    error: !!this.error,
                    completed: !!this.completed,
                    loading: !!this.loadingClientOnboardingInvite
                }
            },
            canAddClientOnboardingInvite() {
                return !this.completed && !!this.addedClientId && this.addedPets;
            }
        },
        watch: {
            // retry adding of sending onboarding invite
            addClientEvent() {
                this.onValidatedChanged();
            },
            canAddClientOnboardingInvite() {
                this.onValidatedChanged();
            },
            clientOnboardingInviteAddedEvent(invite) {
                this.addedClientOnboardingInviteId = invite.id;
            },
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddClientOnboardingInvite) {
                    return;
                }
                this.tryAddClientOnboardingInvite();
            },
            refreshClientOnboardingInvite() {
                // do nothing
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
