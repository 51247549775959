import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { DateFormatSettingAddMixin } from "./DateFormatSettingAddMixin";

export const DateFormatSettingUploadMixin = {
    mixins: [DateFormatSettingAddMixin],
    computed: {
        dateFormatSettingUpdateModel() {
            return Object.assign({}, this.dateFormatSetting, { dateFormatId: this.dateFormatId });
        },
        dateFormatSettingUpdateModelValid() {
            const model = this.dateFormatSettingUpdateModel;
            return !!model.id && model.dateFormatId > -1;
        },
        dateFormatSettingUpdateModelChanged() {
            return this.dateFormatSettingUpdateModel.dateFormatId !== this.dateFormatSetting.dateFormatId;
        }
    },
    watch: {
        dateFormatSetting() {
            this.refreshDateFormatUpload();
        }
    },
    methods: {
        ...mapActions('DateFormatSettings', [
            'updateDateFormatSetting'
        ]),
        refreshDateFormatUpload() {
            this.dateFormatId = !!this.dateFormatSetting ? this.dateFormatSetting.dateFormatId : 1;
        },
        uploadDateFormatSetting() {
            if (!!this.dateFormatSetting) {
                this.tryUpdateDateFormatSetting();
            } else {
                this.tryAddDateFormatSetting();
            }
        },
        tryUpdateDateFormatSetting() {
            const self = this;
            if (!self.dateFormatSettingUpdateModelValid) {
                return;
            }
            if (!self.dateFormatSettingUpdateModelChanged) {
                return;
            }
            self.error = '';
            self.updateDateFormatSetting({ key: self.siteId, payload: self.dateFormatSettingUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshDateFormatUpload();
    }
}
