import { mapState, mapGetters } from 'vuex';

export const AppointmentScheduleValidationMixin = {
    data() {
        return {
            showValidation: false
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'saveAppointmentScheduleEvent',
        ]),
        ...mapGetters('AppointmentScheduleUpload', [
            'scheduleAddModelValidation'
        ]),
        validationError() {
            return this.showValidation ? this.validationErrorText : '';
        },
        validationErrorText() {
            console.log('!!!OVERRIDE COMPUTED - validationErrorText!!!');
        }
    },
    watch: {
        saveAppointmentScheduleEvent() {
            this.showValidation = true;
        }
    }
}
