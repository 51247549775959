import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../../Site/SiteIdMixin";

export const TimeZoneCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('TimeZones', [
            'findTimeZone'
        ]),
        timeZoneSearchModel() {
            return {
                siteId: this.siteId
            }
        },
        timeZoneSearchModelValid() {
            return !!this.timeZoneSearchModel.siteId;
        },
        timeZoneCacheKey() {
            return CacheHelper.objectCacheKey(this.timeZoneSearchModel);
        },
        timeZoneCache() {
            return this.findTimeZone(this.timeZoneCacheKey);
        },
        timeZone() {
            return !!this.timeZoneCache ? this.timeZoneCache.value : null;
        },
        loadingTimeZone() {
            return !!this.timeZoneCache && this.timeZoneCache.loading;
        }
    },
    watch: {
        timeZoneCacheKey() {
            this.refreshTimeZone();
        }
    },
    methods: {
        ...mapActions('TimeZones', [
            'fetchTimeZone'
        ]),
        refreshTimeZone() {
            if (!this.timeZoneCache || !!this.timeZoneCache.retry) {
                this.tryFetchTimeZone();
            }
        },
        tryFetchTimeZone() {
            const self = this;
            if (!self.timeZoneSearchModelValid) {
                return;
            }
            self.error = '';
            self.fetchTimeZone({ key: self.timeZoneCacheKey, payload: self.timeZoneSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTimeZone();
    }
}
