<template>
    <number-input v-model="amount" :title="currencyTitle"></number-input>
</template>

<script>
    import { CountryCacheMixin } from "../Country/CountryCacheMixin";
    import NumberInput from "./NumberInput.vue";

    export default {
        name: 'MoneyInput',
        mixins: [CountryCacheMixin],
        props: {
            value: Number,
            title: String
        },
        computed: {
            amount: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            currencyTitle() {
                return this.title + this.currencyTitleSymbol;
            },
            currencyTitleSymbol() {
                return !!this.currencySymbol ? ' (' + this.currencySymbol + ')' : '';
            }
        },
        components: {
            'number-input': NumberInput
        }
    }
</script>
