import { Api } from '../../services/Api';

const state = () => ({
    bookingCountCaches: [],
    bookingCountCacheCleared: 0
})

const getters = {
    findBookingCountCache: (state) => key => state.bookingCountCaches.find(bc => bc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.bookingCountCaches.find(bc => bc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.bookingCountCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setBookingCount(state, { key, count }) {
        const existing = state.bookingCountCaches.find(bc => bc.key === key);
        if (!!existing) {
            existing.value = count;
            existing.loading = false;
        } else {
            state.bookingCountCaches.push({ key: key, value: count, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.bookingCountCaches.find(bc => bc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearBookingCount(state, key) {
        state.bookingCountCaches = state.bookingCountCaches.filter(bc => bc.key !== key);
        state.bookingCountCacheCleared++;
    }
}

const actions = {
    fetchBookingCount({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Booking/Count')
                .then(response => {
                    commit('setBookingCount', { key: key, count: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
