import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import router from '../../routing';

export const PaymentReportItemMixin = {
    data() {
        return {
            showRefunds: false
        }
    },
    props: {
        checkout: Object,
        dateFormat: String,
        timeFormat: String
    },
    computed: {
        ...mapGetters('AppointmentGroups', [
            'findAppointmentGroupCache'
        ]),
        ...mapGetters('Clients', [
            'findClientCache'
        ]),
        classType() {
            return this.number % 2 > 0 ? 'odd' : 'even';
        },
        date() {
            return !!this.checkout ? moment(this.checkout.createdUtc).format(this.dateFormat) : 'Unknown';
        },
        status() {
            return !!this.checkout ? this.checkout.status.description : 'Unknown';
        },
        amount() {
            return !!this.checkout ? this.checkout.amount.toFixed(2) : '0.00';
        },
        refunds() {
            return !!this.checkout ? this.checkout.refunds : [];
        },
        refundAmount() {
            var total = 0;
            for (var i = 0; i < this.refunds.length; i++) {
                total += this.refunds[i].amount;
            }
            return '-' + total.toFixed(2);
        },
        due() {
            return !!this.checkout && this.checkout.dueUtc ? moment(this.checkout.dueUtc).format(this.dateFormat) : '';
        },
        dueSummary() {
            return !!this.due ? 'Due ' + this.due : '';
        },
        paid() {
            return !!this.checkout && this.checkout.paidUtc ? moment(this.checkout.paidUtc).format(this.dateFormat) : '';
        },
        paidSummary() {
            return !!this.paid ? 'Paid ' + this.paid : '';
        },
        summary() {
            return [this.dueSummary, this.paidSummary].filter(s => !!s).join(' ');
        },
        appointmentGroupId() {
            return !!this.checkout ? this.checkout.reference : '';
        },
        appointmentGroupCache() {
            return this.findAppointmentGroupCache(this.appointmentGroupId);
        },
        appointmentGroup() {
            return !!this.appointmentGroupCache ? this.appointmentGroupCache.value : null;
        },
        appointment() {
            return !!this.appointmentGroup ? this.appointmentGroup.appointments[0] : null;
        },
        appointmentMoment() {
            return !!this.appointment ? moment(this.appointment.startUtc) : null;
        },
        appointmentDate() {
            return !!this.appointmentMoment ? this.appointmentMoment.format(this.dateFormat) : 'Unknown';
        },
        appointmentTime() {
            return !!this.appointmentMoment ? this.appointmentMoment.format(this.timeFormat) : 'Unknown';
        },
        isOverdue() {
            return !!this.checkout && this.checkout.status.id === 4;
        },
        isPaidLate() {
            return !!this.checkout && this.checkout.status.id === 2;
        },
        isPaid() {
            return !!this.checkout && this.checkout.status.id === 1;
        },
        statusIcon() {
            return this.isOverdue ?
                'fa-exclamation-circle' :
                this.isPaid ?
                    'fa-check' :
                    'fa-info-circle';
        },
        statusColor() {
            return this.isOverdue ?
                'text-danger' :
                this.isPaidLate ?
                    'text-warning' :
                    this.isPaid ?
                        'text-success' : ''
        },
        clientId() {
            return !!this.appointment ? this.appointment.clientId : '';
        },
        clientCache() {
            return this.findClientCache(this.clientId);
        },
        client() {
            return !!this.clientCache ? this.clientCache.value : null;
        },
        clientName() {
            return !!this.client ? this.client.fullName : 'Unknown';
        },
        startletter() {
            return this.clientName[0].toUpperCase();
        }
    },
    methods: {
        ...mapMutations('ApplicationEvents', [
            'triggerViewAppointment'
        ]),
        onClientClick() {
            router.push({ name: 'clientEdit', params: { id: this.clientId } });
        },
        onAppointmentClick() {
            if (!!this.appointment) {
                this.triggerViewAppointment(this.appointment);
            }
        }
    }
}
