import { NotificationsCacheMixin } from './NotificationsCacheMixin';
import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const NotificationsViewMixin = {
    mixins: [NotificationsCacheMixin],
    computed: {
        notViewedNotifications() {
            return this.notifications.filter(n => !n.viewed);
        },
        notificationsViewModel() {
            return {
                notificationIds: this.notViewedNotifications.map(n => n.id)
            }
        },
        notificationsViewModelValid() {
            return this.notificationsViewModel.notificationIds.length > 0
        }
    },
    methods: {
        ...mapActions('Notifications', [
            'viewNotifications'
        ]),
        tryViewNotifications() {
            const self = this;
            if (!self.notificationsViewModelValid) {
                return;
            }
            self.error = '';
            self.viewNotifications(self.notificationsViewModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
