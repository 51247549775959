<template>
    <div class="form-group">
        <div v-if="contacts.length > 0" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Contact Details')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <slotted-input v-model="name" :title="$t('Name')">
                <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
            </slotted-input>
            <slotted-input v-model="email" :title="$t('Email')"></slotted-input>
            <slotted-input v-model="mobile" :title="$t('Mobile')"></slotted-input>
            <slotted-text-area v-model="otherInfo" :title="$t('Other info')" :rows="10"></slotted-text-area>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { ContactAddMixin } from "../../Shared/Contacts/ContactAddMixin";
    import SlottedInput from "../../Shared/Input/SlottedInput.vue";
    import SlottedTextArea from "../../Shared/Input/SlottedTextArea.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AccountSettingCacheMixin } from "../../../mixins/Settings/Account/AccountSettingCacheMixin";

    export default {
        name: 'SiteAddContact',
        mixins: [ContactAddMixin, AccountSettingCacheMixin, StatusEmitterMixin],
        props: {
            siteCountryStatus: Object,
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ownerId() {
                return this.addedSiteId;
            },
            ownerTypeId() {
                return 12;
            },
            contactTypeId() {
                return 1;
            },
            accountSettingKey() {
                return 'DefaultContact'
            },
            siteCountryCompleted() {
                return !!this.siteCountryStatus.completed || !!this.siteCountryStatus.error;
            },
            canAddContact() {
                return this.siteCountryCompleted && !this.completed;
            },
            completed() {
                return !this.contactAddModelEdited || this.contacts.length > 0
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingContacts
                }
            }
        },
        watch: {
            accountSetting() {
                this.setDefaultContact();
            },
            canAddContact(value) {
                if (value) {
                    this.tryAddContact();
                }
            },
            saveSiteEvent() {
                if (this.canAddContact) {
                    this.tryAddContact();
                }
            }
        },
        methods: {
            setDefaultContact() {
                this.name = !!this.accountSettingObject ? this.accountSettingObject.name : '';
                this.mobile = !!this.accountSettingObject ? this.accountSettingObject.mobile : '';
                this.email = !!this.accountSettingObject ? this.accountSettingObject.email : '';
                this.otherInfo = !!this.accountSettingObject ? this.accountSettingObject.otherInfo : '';
            },
            searchAndCacheContacts() {
                // do nothing
            }
        },
        components: {
            'slotted-input': SlottedInput,
            'slotted-text-area': SlottedTextArea,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        },
        created() {
            this.setDefaultContact();
        }
    }
</script>
