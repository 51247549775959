<template>
    <article class="notifcation">
        <div class="notifcation-content">
            <div class="notifcation-date">{{durationString}}</div>
            <div v-if="isHtml" v-html="message"></div>
            <template v-else>
                <p>{{message}}</p>
            </template>
        </div>
        <div class="align-self-center">
            <span v-if="deletingNotification" class="inline-loader"></span>
            <template v-else>
                <i v-if="canDelete" @click="tryDeleteNotification" class="fal fa-trash-alt"></i>
            </template>
        </div>
    </article>
</template>

<script>
    import moment from 'moment';
    import { NotificationDeleteMixin } from '../Shared/Notifications/NotificationDeleteMixin';
    import { UserCacheMixin } from '../Shared/UserCacheMixin';

    export default {
        name: 'NotificationPanelItem',
        mixins: [NotificationDeleteMixin, UserCacheMixin],
        computed: {
            message() {
                return this.notification.text;
            },
            isHtml() {
                return this.notification.isHtml;
            },
            dateUtc() {
                return this.notification.createdUtc;
            },
            timeDiff() {
                return moment().diff(moment(this.dateUtc));
            },
            duration() {
                return moment.duration(this.timeDiff);
            },
            durationString() {
                return this.duration.humanize() + ' ago';
            }
        },
        methods: {
            tryGetUser() {
                // do nothing
            }
        }
    }
</script>
