import { mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { BookingTermAddMixin } from "./BookingTermAddMixin";

export const BookingTermUploadMixin = {
    mixins: [BookingTermAddMixin],
    data() {
        return {
            html: ''
        }
    },
    computed: {
        bookingTermUpdateModel() {
            return !!this.bookingTerm ? Object.assign({}, this.bookingTerm, {
                html: this.html
            }) : null;
        },
        bookingTermUpdateModelValid() {
            return !!this.bookingTermUpdateModel && !!this.bookingTermUpdateModel.html;
        },
        bookingTermUpdateModelChanged() {
            return JSON.stringify(this.bookingTermUpdateModel) !== JSON.stringify(this.bookingTerm);
        }
    },
    watch: {
        bookingTerm() {
            this.refreshBookingTermModel();
        }
    },
    methods: {
        ...mapActions('BookingTerms', [
            'updateBookingTerm'
        ]),
        refreshBookingTermModel() {
            this.html = !!this.bookingTerm ? this.bookingTerm.html : '';
        },
        tryUploadBookingTerm() {
            if (!!this.bookingTerm) {
                this.tryUpdateBookingTerm();
            } else {
                this.tryAddBookingTerm();
            }
        },
        tryUpdateBookingTerm() {
            const self = this;
            if (!self.siteId || !self.bookingTermUpdateModelValid || !self.bookingTermUpdateModelChanged) {
                return;
            }
            self.error = '';
            self.updateBookingTerm({ key: self.siteId, payload: self.bookingTermUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
