<template>
    <div v-if="!!note" class="text-truncate">
        {{noteText}}
    </div>
</template>

<script>
    import { NoteCacheMixin } from "../Shared/Notes/NoteCacheMixin";

    export default {
        name: 'CalendarAppointmentNotes',
        mixins: [NoteCacheMixin],
        props: {
            scheduleId: String
        },
        computed: {
            ownerId() {
                return this.scheduleId;
            },
            ownerTypeId() {
                return 8;
            },
            noteTypeId() {
                return 1;
            },
            note() {
                return this.notes.find(n => n.noteType.id === this.noteTypeId);
            },
            noteText() {
                return !!this.note ? this.note.text : '';
            }
        },
        methods: {
            trySearchNotes() {
                // do nothing
            }
        }
    }
</script>
