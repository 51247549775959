import { Api } from '../../services/Api';

const state = () => ({
    loadingStripoDefaultTemplateBundleSettings: false,
    loadedStripoDefaultTemplateBundleSettings: false,
    stripoDefaultTemplateBundleSettings: []
});

const mutations = {
    setSettings(state, settings) {
        state.loadedStripoDefaultTemplateBundleSettings = true;
        state.stripoDefaultTemplateBundleSettings = settings;
    },
    setLoading(state, value) {
        state.loadingStripoDefaultTemplateBundleSettings = value;
    },
}

const actions = {
    getStripoDefaultTemplateBundleSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('StripoDefaultTemplate/Bundle/Setting')
                .then(response => {
                    commit('setSettings', response.data);
                    resolve(response);
                })
                .catch(error => { reject(error) })
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
