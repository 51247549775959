<template>
    <div class="upgrade-plan-v2_list">
        <div>{{clients}}</div>
        <div>{{users}}</div>
        <div>{{contracts}}</div>
        <div>{{contracts}}</div>
        <div>{{email}}</div>
        <div>{{sms}}</div>
        <div>{{payments}}</div>
        <div>{{support}}</div>
        <div>{{campaigns}}</div>
        <div>{{editEmailTemplates}}</div>
        <div>{{buyEmailTemplates}}</div>
        <div>{{campaignCredits}}</div>
        <div>{{dashboard}}</div>
        <div>{{reporting}}</div>
        <div>{{multiSite}}</div>
        <div>{{onlineBooking}}</div>
        <div>{{smsCredits}}</div>
        <div>
            <span v-if="feature.multiSite" class="d-flex align-items-center">
                <i class="fa fa-minus-circle pointer" @click="removeSite"></i>
                <input v-model="additionalSites" min="0" type="number" class="text-center upgrade-plan-v2_input mx-1">
                <i class="fa fa-plus-circle pointer" @click="addSite"></i>
                <span class="ml-1" style="font-size: 0.8rem;">(additional sites)</span>
            </span>
            <span v-else>{{sites}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SubscriptionFeatureListV2',
        props: {
            feature: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                additionalSites: 0
            }
        },
        computed: {
            clients() {
                return this.feature.unlimitedClients ? 'Unlimited' : this.feature.clientAllowance ? this.feature.clientAllowance : '';
            },
            users() {
                return this.feature.unlimitedUsers ? 'Unlimited' : this.feature.userAllowance ? this.feature.userAllowance : '';
            },
            resources() {
                return this.feature.unlimitedResources ? 'Unlimited' : this.feature.resourceAllowance ? this.feature.resourceAllowance : '';
            },
            contracts() {
                return this.feature.contracts ? 'Yes' : 'No';
            },
            email() {
                return this.feature.email ? 'Yes' : 'No';
            },
            sms() {
                return this.feature.sms ? 'Yes' : 'No';
            },
            payments() {
                return this.feature.payments ? 'Yes' : 'No';
            },
            support() {
                return this.feature.support ? 'Yes' : 'No';
            },
            campaigns() {
                return this.feature.campaigns ? 'Yes' : 'No';
            },
            editEmailTemplates() {
                return this.feature.editEmailTemplates ? 'Yes' : 'Partial';
            },
            buyEmailTemplates() {
                return this.feature.buyEmailTemplates ? 'Yes' : 'No';
            },
            campaignCredits() {
                return this.feature.campaignCreditAllowance ? this.feature.campaignCreditAllowance : 0;
            },
            dashboard() {
                return this.feature.dashboard ? 'Yes' : 'No';
            },
            reporting() {
                return this.feature.reporting ? 'Yes' : 'No';
            },
            multiSite() {
                return this.feature.multiSite ? 'Yes' : 'No';
            },
            onlineBooking() {
                return this.feature.onlineBooking ? 'Yes' : 'No';
            },
            smsCredits() {
                return this.feature.smsCreditAllowance ? this.feature.smsCreditAllowance : 0;
            },
            sites() {
                return '1';
            },
        },
        methods: {
            addSite() {
                this.additionalSites++;
            },
            removeSite() {
                if (this.additionalSites > 0) {
                    this.additionalSites--;
                }
            }
        },
        watch: {
            additionalSites(value) {
                this.$emit('additionalSitesChanged', value);
            }
        }
    }
</script>
