<template>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { SelfNotificationAddMixin } from "../../Shared/Notifications/SelfNotificationAddMixin";

    export default {
        name: 'PostRegisterWelcomeNotification',
        mixins: [StatusEmitterMixin, SelfNotificationAddMixin],
        data() {
            return {
                error: '',
                loading: false,
                completed: false
            }
        },
        computed: {
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                }
            },
            notificationTypeId() {
                return 2;
            }
        }
    }
</script>
