/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';

export const BookingDeclineMixin = {
    data() {
        return {
            error: '',
            declining: false
        }
    },
    computed: {
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'declineBooking'
        ]),
        tryDeclineBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.declining = true;
            self.declineBooking(self.bookingId)
                .then(response => self.afterDeclineBooking(response.data))
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.declining = false);
        },
        afterDeclineBooking() {
            //override if necessary
        }
    }
}
