import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const ImageSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ImageSettings', [
            'fetchingImageSettings',
            'fetchedImageSettings',
            'imageSettings'
        ]),
        maxUploadSize() {
            return !!this.imageSettings ? this.imageSettings.maxMbUploadSize + 'MB' : '';
        }
    },
    methods: {
        ...mapActions('ImageSettings', [
            'fetchImageSettings'
        ]),
        refreshImageSettings() {
            if (!this.fetchedImageSettings && !this.fetchingImageSettings) {
                this.tryFetchImageSettings();
            }
        },
        tryFetchImageSettings() {
            const self = this;
            self.error = '';
            self.fetchImageSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshImageSettings();
    }
}
