import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionPaymentFailuresCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionPaymentFailures', [
            'fetchedPaymentFailures',
            'fetchingPaymentFailures',
            'paymentFailures'
        ])
    },
    methods: {
        ...mapActions('SubscriptionPaymentFailures', [
            'fetchPaymentFailures'
        ]),
        refreshPaymentFailures() {
            if (!this.fetchedPaymentFailures && !this.fetchingPaymentFailures) {
                this.tryFetchPaymentFailures();
            }
        },
        tryFetchPaymentFailures() {
            const self = this;
            self.error = '';
            self.fetchPaymentFailures()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentFailures();
    }
}
