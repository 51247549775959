import { Api } from "../../services/Api";

const state = () => ({
    addingCountry: false,
    updatingCountry: false,
    loadingCountries: false,
    loadedCountries: false,
    countries: []
})

const getters = {
    findCountry: (state) => siteId => {
        return state.countries.find(c => c.siteId === siteId);
    }
}

const mutations = {
    setCountries(state, countries) {
        state.countries = countries;
        state.loadedCountries = true;
    },
    setLoading(state, value) {
        state.loadingCountries = !!value;
    },
    setAdding(state, value) {
        state.addingCountry = !!value;
    },
    setUpdating(state, value) {
        state.updatingCountry = !!value;
    },
    appendCountry(state, country) {
        state.countries.push(country);
    },
    insertCountry(state, country) {
        const existing = state.countries.find(c => c.id === country.id);
        state.countries.splice(state.countries.indexOf(existing), 1, country);
    }
}

const actions = {
    getCountries({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('Country')
                .then(response => {
                    commit('setCountries', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    addCountry({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Country/' + id)
                .then(response => {
                    commit('appendCountry', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateCountry({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Country', payload)
                .then(response => {
                    commit('insertCountry', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
