import axios from 'axios';
import store from '../store';
import { PaymentHelper } from '../helpers/PaymentHelper';

export const PaymentApi = axios.create({
    baseURL: PaymentHelper.getBaseUrl()
});

PaymentApi.interceptors.request.use(config => {
    return new Promise((resolve, reject) => {
        store.dispatch('PaymentTokens/fetchPaymentToken')
            .then(token => {
                config.headers.Authorization = token.tokenType + ' ' + token.accessToken;
                const siteId = store.state.Sites.selected.id;
                if (!!siteId) {
                    config.headers.PaymentAccountName = siteId;
                }
                resolve(config);
            })
            .catch(error => reject(error));
    });
});

PaymentApi.interceptors.response.use(
    response => response,
    error => {
        return new Promise((resolve, reject) => {
            const config = error.config;
            if (!!error.response && (error.response.status === 403 || error.response.status === 401) && !config._retry) {
                config._retry = true;
                PaymentHelper.clearToken();
                PaymentApi(config)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            } else {
                reject(error);
            }
        });
    });
