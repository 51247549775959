export const OnboardingGuideMixin = {
    data() {
        return {
            tour: null
        }
    },
    methods: {
        createTour() {
            // override if necessary
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.createTour();
        });
    }
}
