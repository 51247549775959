import { Api } from '../../services/Api';

const state = () => ({
    openingHourCaches: []
})

const getters = {
    findOpeningHourCache: (state) => key => {
        return state.openingHourCaches.find(ohc => ohc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.openingHourCaches.find(ohc => ohc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.openingHourCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setOpeningHours(state, { key, openingHours }) {
        const existing = state.openingHourCaches.find(ohc => ohc.key === key);
        if (!!existing) {
            existing.values = openingHours;
            existing.loading = false;
        } else {
            state.openingHourCaches.push({ key: key, values: openingHours, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.openingHourCaches.find(ohc => ohc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendOpeningHour(state, { key, openingHour }) {
        const existing = state.openingHourCaches.find(ohc => ohc.key === key);
        if (!!existing) {
            existing.values.push(openingHour);
        } else {
            state.openingHourCaches.push({ key: key, values: [openingHour], loading: false });
        }
    },
    insertOpeningHour(state, { key, openingHour }) {
        const existing = state.openingHourCaches.find(ohc => ohc.key === key);
        if (!!existing) {
            const old = existing.values.find(oh => oh.id === openingHour.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, openingHour);
            } else {
                existing.values.push(openingHour);
            }
        } else {
            state.openingHourCaches.push({ key: key, values: [openingHour], loading: false });
        }
    }
}

const actions = {
    getOpeningHours({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('OpeningHours?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setOpeningHours', { key: key, openingHours: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    },
    addOpeningHours({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('OpeningHours', payload)
                .then(response => {
                    commit('appendOpeningHour', { key: key, openingHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateOpeningHours({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('OpeningHours', payload)
                .then(response => {
                    commit('insertOpeningHour', { key: key, openingHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addDefaultOpeningHours({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Site/OpeningHours')
                .then(response => {
                    commit('setOpeningHours', { key: key, openingHours: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
