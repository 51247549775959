<template>
    <modal-dialog id="image-viewer" title="Photo" @closed="setDeleting(false)" @opened="setMode()">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="deleting" class="alert alert-warning  alert-icon" role="alert">
            <div class="alert-content">
                <p> Are you sure you want to delete this photo? </p>
            </div>
            <button @click="confirmDelete" v-bind:disabled="loading" type="button" class="btn btn-danger" data-dismiss="modal" style="margin-right: 10px">
                <span v-if="loading" class="inline-loader"></span>
                <template v-else>
                    Yes
                </template>
            </button>
            <button @click="setDeleting(false)" type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <img v-if="image" class="img-responsive" :alt="image.uploadedName" :src="image.thumbnails.large">
        <div class="modal-footer">
            <button @click="setDeleting(true)" type="button" class="btn btn-danger">Delete</button>
            <button ref="closeModal" class="btn btn-outline-secondary" type="button" data-dismiss="modal">Close</button>
        </div>
    </modal-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'PetImageViewer',
        props: {
            image: Object,
            loading: Boolean,
            error: String,
            mode: String
        },
        data() {
            return {
                deleting: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'imageDeletedEvent'
            ])
        },
        watch: {
            imageDeletedEvent() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            confirmDelete() {
                this.$emit('delete');
            },
            setMode() {
                this.deleting = this.mode === 'delete' ? true : false;
            },
            setDeleting(value) {
                this.deleting = value;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>

<style scoped>
    .img-responsive {
        width: 100%;
    }
</style>
