<template>
    <div class="form-group">
        <div v-if="!!resources.length > 0" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Resource')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <validated-input v-model="name" :title="$t('Name')" :validation="resourceValidator.name" :inputType="'text'"></validated-input>
            <slotted-color-input v-model="colour" :title="$t('Colour')"></slotted-color-input>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ResourceAddMixin } from '../../Shared/Resources/ResourceAddMixin';
    import ValidatedInput from '../../Shared/Input/ValidatedInput.vue';
    import SlottedColorInput from '../../Shared/Input/SlottedColorInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { AccountSettingCacheMixin } from '../../../mixins/Settings/Account/AccountSettingCacheMixin';

    export default {
        name: 'SiteAddResource',
        mixins: [ResourceAddMixin, AccountSettingCacheMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            resourceAddModelEdited() {
                const model = this;
                return !!model.name || model.colour !== '#FFE781';
            },
            accountSettingKey() {
                return 'DefaultResource'
            },
            canAddResource() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !this.resourceAddModelEdited || this.resources.length > 0;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingResources
                }
            }
        },
        watch: {
            accountSetting() {
                this.refreshResourceModel();
            },
            canAddResource(value) {
                if (value) {
                    this.tryAddResource();
                }
            },
            saveSiteEvent() {
                if (this.canAddResource) {
                    this.tryAddResource();
                }
            }
        },
        methods: {
            refreshResourceModel() {
                this.name = !!this.accountSettingObject ? this.accountSettingObject.name : '';
                this.colour = !!this.accountSettingObject ? this.accountSettingObject.colour : '#FFE781';
            },
            tryGetResources() {
                // do nothing
            }
        },
        components: {
            'validated-input': ValidatedInput,
            'slotted-color-input': SlottedColorInput,
            'error-banner': ErrorBanner
        }
    }
</script>
