import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { NotificationSettingsCacheMixin } from "./NotificationSettingsCacheMixin"
import { SettingCacheMixin } from "./SettingCacheMixin";
import { SiteNotificationDefaultsSettingMixin } from "../../mixins/Settings/Site/SiteNotificationDefaultsSettingMixin";

export const NotificationSettingsAddMixin = {
    mixins: [NotificationSettingsCacheMixin, SettingCacheMixin, SiteNotificationDefaultsSettingMixin],
    data() {
        return {
            marketing: false,
            smsCancellation: false,
            smsUpdated: false,
            smsReminder: false,
            smsConfirmation: false,
            smsThankYou: false,
            smsNoShow: false,
            smsMissYou: false,
            emailCancellation: false,
            emailUpdated: false,
            emailReminder: false,
            emailConfirmation: false,
            emailThankYou: false,
            emailNoShow: false,
            emailMissYou: false
        }
    },
    computed: {
        smsAppointmentsEnabled: {
            get() {
                return !!this.smsCancellation || !!this.smsUpdated || !!this.smsReminder || !!this.smsConfirmation || !!this.smsThankYou || !!this.smsNoShow;
            },
            set(value) {
                this.smsCancellation = !!value;
                this.smsUpdated = !!value;
                this.smsReminder = !!value;
                this.smsConfirmation = !!value;
                this.smsThankYou = !!value;
                this.smsNoShow = !!value;
            }
        },
        emailAppointmentsEnabled: {
            get() {
                return !!this.emailCancellation || !!this.emailUpdated || !!this.emailReminder || !!this.emailConfirmation || !!this.emailThankYou || !!this.emailNoShow;
            },
            set(value) {
                this.emailCancellation = !!value;
                this.emailUpdated = !!value;
                this.emailReminder = !!value;
                this.emailConfirmation = !!value;
                this.emailThankYou = !!value;
                this.emailNoShow = !!value;
            }
        },
        smsAppointmentsPartiallyEnabled() {
            return (!!this.smsCancellation || !!this.smsUpdated || !!this.smsReminder || !!this.smsConfirmation || !!this.smsThankYou || !!this.smsNoShow) &&
                !(this.smsCancellation && this.smsUpdated && this.smsReminder && this.smsConfirmation && this.smsThankYou && this.smsNoShow);
        },
        emailAppointmentsPartiallyEnabled() {
            return (!!this.emailCancellation || !!this.emailUpdated || !!this.emailReminder || !!this.emailConfirmation || !!this.emailThankYou || !!this.emailNoShow) &&
                !(this.emailCancellation && this.emailUpdated && this.emailReminder && this.emailConfirmation && this.emailThankYou && this.emailNoShow);
        },
        notificationSettingsAddModel() {
            return {
                clientId: this.clientId,
                marketing: this.marketing,
                smsCancellation: this.smsCancellation,
                smsUpdated: this.smsUpdated,
                smsReminder: this.smsReminder,
                smsConfirmation: this.smsConfirmation,
                smsThankYou: this.smsThankYou,
                smsNoShow: this.smsNoShow,
                smsMissYou: this.smsMissYou,
                emailCancellation: this.emailCancellation,
                emailUpdated: this.emailUpdated,
                emailReminder: this.emailReminder,
                emailConfirmation: this.emailConfirmation,
                emailThankYou: this.emailThankYou,
                emailNoShow: this.emailNoShow,
                emailMissYou: this.emailMissYou
            }
        },
        notificationSettingsAddModelValid() {
            return !!this.notificationSettingsAddModel.clientId;
        }
    },
    watch: {
        settingObject() {
            this.refreshNotificationSettingsModel();
        }
    },
    methods: {
        ...mapActions('NotificationSettings', [
            'addNotificationSettings',
        ]),
        tryAddNotificationSettings() {
            const self = this;
            if (!self.notificationSettingsAddModelValid) {
                return;
            }
            self.error = '';
            self.addNotificationSettings({ clientId: self.clientId, payload: self.notificationSettingsAddModel })
                .then(response => { self.afterAddNotificationSettings(response.data) })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddNotificationSettings() {
            //override if necessary
        },
        refreshNotificationSettingsModel() {
            this.marketing = false;
            this.smsCancellation = this.settingObject.isSmsCancellationEnabled;
            this.smsUpdated = this.settingObject.isSmsUpdatedEnabled;
            this.smsReminder = this.settingObject.isSmsReminderEnabled;
            this.smsConfirmation = this.settingObject.isSmsConfirmationEnabled;
            this.smsThankYou = this.settingObject.isSmsThankYouEnabled;
            this.smsNoShow = this.settingObject.isSmsNoShowEnabled;
            this.smsMissYou = this.settingObject.isSmsMissYouEnabled;
            this.emailCancellation = this.settingObject.isEmailCancellationEnabled;
            this.emailUpdated = this.settingObject.isEmailUpdatedEnabled;
            this.emailReminder = this.settingObject.isEmailReminderEnabled;
            this.emailConfirmation = this.settingObject.isEmailConfirmationEnabled;
            this.emailThankYou = this.settingObject.isEmailThankYouEnabled;
            this.emailNoShow = this.settingObject.isEmailNoShowEnabled;
            this.emailMissYou = this.settingObject.isEmailMissYouEnabled;
        }
    },
    created() {
        this.refreshNotificationSettingsModel();
    }
}
