import { SettingCacheMixin } from "../../components/Shared/SettingCacheMixin";
import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin";
import { UserSettingCacheMixin } from "../../components/Shared/UserSettingCacheMixin";
import { SiteHelper } from "../../helpers/SiteHelper";
import { UserSettingsHelper } from "../../helpers/UserSettingsHelper";

export const CalendarDisplayMixin = {
    mixins: [UserSettingCacheMixin, SettingCacheMixin, SiteIdMixin],
    computed: {
        userSettingKey() {
            return 'Calendar';
        },
        settingKey() {
            return 'Calendar';
        },
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        defaultUserSettingValue() {
            return UserSettingsHelper.getDefaultCalendarSettings();
        },
        defaultSettingObject() {
            return SiteHelper.getDefaultCalendarSettings();
        },
        calendarSettingsReady() {
            return !!this.attempted && !!this.attemptedSetting;
        }
    }
}
