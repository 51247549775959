<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="adding">
            <tag-name v-model="name" :validator="tagTypeAddValidator"></tag-name>
            <tag-colour v-model="colour" :validator="tagTypeAddValidator"></tag-colour>
            <tag-entity v-model="entityTypeId"></tag-entity>
            <div class="action-btns">
                <button @click="tryAddTagType" v-bind:disabled="loadingTagTypes" class="btn btn-primary" type="button">
                    {{$t('Add')}}
                    <span v-if="loadingTagTypes" class="inline-loader"></span>
                </button>
                <button @click="adding=false" class="btn btn-outline-secondary">
                    {{$t('Cancel')}}
                </button>
            </div>
        </div>
        <template v-else>
            <tag-selector :tag-types="filteredTagTypes" :owner-id="ownerId" :owner-type-id="ownerTypeId"></tag-selector>
            <div class="action-btns">
                <button @click="adding=true" class="btn btn-primary" type="button">
                    <i class="fas fa-plus"></i>
                    {{$t('Create new')}}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import { TagTypeAddMixin } from "../TagTypeAddMixin";
    import TagSelector from "./TagSelector.vue";
    import TagTypeColourInput from "./TagTypeColourInput.vue";
    import TagTypeEntitySelect from "./TagTypeEntitySelect.vue";
    import TagTypeNameInput from "./TagTypeNameInput.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagTypeList',
        mixins: [TagTypeAddMixin],
        data() {
            return {
                adding: false
            }
        },
        props: {
            ownerId: String,
            ownerTypeId: Number
        },
        computed: {
            filteredTagTypes() {
                return this.tagTypes.filter(tt => !tt.entityType.id || tt.entityType.id === this.ownerTypeId);
            }
        },
        watch: {
            tagTypeAddedEvent() {
                this.adding = false;
            }
        },
        components: {
            'tag-selector': TagSelector,
            'tag-name': TagTypeNameInput,
            'tag-colour': TagTypeColourInput,
            'tag-entity': TagTypeEntitySelect,
            'error-banner': ErrorBanner
        }
    }
</script>
