<template>
    <div>
        <template v-if="error">
            <error-banner :message="error"></error-banner>
            <button @click="beginSessionFlow">Retry</button>
        </template>
        <page-loader v-else></page-loader>
    </div>
</template>

<script>
    import { StripeCheckoutMixin } from "../../mixins/Stripe/StripeCheckoutMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";

    export default {
        name: 'SubscriptionCheckout',
        mixins: [StripeCheckoutMixin],
        computed: {
            sessionUrl() {
                return 'Stripe/Subscription/Session';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'page-loader': PageLoader
        }
    }
</script>
