<template>
    <tr @click="onClick">
        <td align="center">
            {{$t(daysOfWeek[dayId])}}
            <div class="filter-group">
                <label>{{openingString}}</label>
            </div>
        </td>
    </tr>
</template>

<script>
    import { OpeningHoursMixin } from "./OpeningHoursMixin";

    export default {
        name: 'OpeningHoursMobile',
        mixins: [OpeningHoursMixin]
    }
</script>
