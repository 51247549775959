import { ErrorHelper } from '../../helpers/ErrorHelper';
import { Api } from '../../services/Api';

const state = () => ({
    petProductCaches: []
})

const getters = {
    findPetProductCache: (state) => key => state.petProductCaches.find(pc => pc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.petProductCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setPetProduct(state, { key, product }) {
        var existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.value = product;
            existing.loading = false;
        } else {
            state.petProductCaches.push({ key: key, value: product, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getPetProduct({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Pet/' + key + '/Product')
                .then(response => {
                    commit('setPetProduct', { key: key, product: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                    } else {
                        commit('setRetry', key);
                    }
                    reject(error);
                });
        });
    },
    addPetProduct({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Product', payload)
                .then(response => {
                    commit('setPetProduct', { key: key, product: response.data[0] });
                    commit('ApplicationEvents/triggerProductsAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updatePetProduct({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Product', payload)
                .then(response => {
                    commit('setPetProduct', { key: key, product: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
