<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { ReminderSettingAddMixin } from "../../Reminders/ReminderSettingAddMixin";

    export default {
        name: 'PostRegisterReminderSetting',
        mixins: [ReminderSettingAddMixin, StatusEmitterMixin],
        computed: {
            canAddReminderSetting() {
                return !!this.loadedReminderSettings && !this.reminderSetting && !!this.siteId
            },
            status() {
                return {
                    error: this.error,
                    loading: this.addingReminderSetting || this.loadingReminderSettings,
                    completed: !!this.reminderSetting
                }
            }
        },
        watch: {
            canAddReminderSetting() {
                this.tryUploadReminderSetting();
            }
        },
        methods: {
            tryUploadReminderSetting() {
                if (this.canAddReminderSetting) {
                    this.tryAddReminderSetting();
                }
            }
        },
        created() {
            this.tryUploadReminderSetting();
        }
    }
</script>
