import { ClientHelper } from "../../helpers/ClientHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ClientMessagesCacheMixin } from "./ClientMessagesCacheMixin";
import { mapActions } from 'vuex';

export const ClientMessageAddMixin = {
    mixins: [ClientMessagesCacheMixin],
    data() {
        return {
            subject: '',
            body: '',
            clientMessageAddValidator: ClientHelper.getClientMessageAddValidator()
        }
    },
    computed: {
        clientMessageAddModel() {
            return {
                clientId: this.clientId,
                methodTypeId: this.methodTypeId,
                subject: this.subject,
                body: this.body
            }
        },
        clientMessageAddModelValid() {
            const model = this.clientMessageAddModel;
            return !!model.clientId && !!model.methodTypeId && this.clientMessageAddValidator.isValid;
        }
    },
    watch: {
        clientMessagesCacheKey() {
            this.refreshClientMessageModel();
        }
    },
    methods: {
        ...mapActions('ClientMessages', [
            'addClientMessage'
        ]),
        tryAddClientMessage() {
            const self = this;
            self.clientMessageAddValidator.validate(self.clientMessageAddModel);
            if (!self.clientMessageAddModelValid) {
                return;
            }
            self.error = '';
            self.addClientMessage({ key: self.clientMessagesCacheKey, payload: self.clientMessageAddModel })
                .then(response => self.afterAddingClientMessage(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddingClientMessage() {
            // override if needed
        },
        refreshClientMessageModel() {
            this.subject = '';
            this.body = '';
        }
    },
    created() {
        this.refreshClientMessageModel();
    }
}
