import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { UserHelper } from "../../../helpers/UserHelper"

export const NotificationAddMixin = {
    data() {
        return {
            error: '',
            text: '',
            success: false,
            notificationAddValidator: UserHelper.getNotificationAddValidator()
        }
    },
    props: {
        user: Object
    },
    computed: {
        ...mapState('Notifications', [
            'addingNotification'
        ]),
        userId() {
            return !!this.user ? this.user.id : '';
        },
        notificationAddModel() {
            return {
                userId: this.userId,
                text: this.text
            }
        },
        notificationAddModelValid() {
            return !!this.notificationAddModel.userId && this.notificationAddValidator.isValid;
        }
    },
    methods: {
        ...mapActions('Notifications', [
            'addNotification'
        ]),
        tryAddNotification() {
            const self = this;
            self.notificationAddValidator.check(self.notificationAddModel);
            if (!self.notificationAddModelValid) {
                return;
            }
            self.error = '';
            self.addNotification(self.notificationAddModel)
                .then(() => {
                    self.text = '';
                    self.success = true;
                })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
