<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">
                {{$t('Terms and Conditions')}}
                <tooltip>
                    <span>{{$t('Customize the terms displayed on the booking portal.')}}</span>
                </tooltip>
            </label>
            <label for="version">{{$t('Version')}}: {{bookingTermVersion}}</label>
        </div>
        <div class="col-md-8 booking-term-editor">
            <ckeditor ref="editor" :editor="editor" v-model="html" :config="editorConfig"></ckeditor>
            <validation-message v-if="message" :message="message"></validation-message>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { BookingTermUploadMixin } from '../../Shared/BookingTermUploadMixin';
    import { CKEditorMixin } from '../../Shared/CKEditorMixin';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import Tooltip from '../../Shared/Layout/Tooltip.vue';

    export default {
        name: 'SiteUpdateBookingTerms',
        mixins: [BookingTermUploadMixin, StatusEmitterMixin, CKEditorMixin],
        data() {
            return {
                message: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingBookingTerm,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.check();
                this.tryUploadBookingTerm();
            }
        },
        methods: {
            check() {
                this.message = !this.html && !!this.bookingTerm ? 'Please enter some valid template content.' : '';
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'tooltip': Tooltip
        }
    }
</script>

<style>
    .booking-term-editor .ck-editor__editable {
        height: 400px !important;
    }
</style>
