import { mapState, mapGetters, mapActions } from 'vuex';
import { HashHelper } from '../../helpers/HashHelper';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BreedProductsSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BreedProductSearches', [
            'breedProductSearchesClearedEvent'
        ]),
        ...mapGetters('BreedProducts', [
            'findBreedProductCache'
        ]),
        ...mapGetters('BreedProductSearches', [
            'findBreedProductSearch'
        ]),
        siteBreedIds() {
            console.log('OVERRIDE COMPUTED - siteBreedIds');
            return [];
        },
        missingSiteBreedIds() {
            return this.siteBreedIds.filter(id => !this.findBreedProductCache(id));
        },
        breedProductSearchModel() {
            return {
                siteBreedIds: this.missingSiteBreedIds
            }
        },
        breedProductSearchModelValid() {
            const model = this.breedProductSearchModel;
            return model.siteBreedIds.length > 0;
        },
        breedProductSearchKey() {
            return HashHelper.createStringHash(this.siteBreedIds.join());
        },
        breedProductSearch() {
            return this.findBreedProductSearch(this.breedProductSearchKey);
        },
        breedProducts() {
            return !!this.breedProductSearch ? this.breedProductSearch.values : [];
        },
        searchingBreedProducts() {
            return !!this.breedProductSearch && this.breedProductSearch.loading;
        }
    },
    watch: {
        breedProductSearchKey() {
            this.refreshBreedProductSearch();
        },
        breedProductSearchesClearedEvent() {
            this.refreshBreedProductSearch();
        }
    },
    methods: {
        ...mapActions('BreedProductSearches', [
            'searchBreedProducts'
        ]),
        trySearchBreedProducts() {
            const self = this;
            if (!self.breedProductSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchBreedProducts({ key: self.breedProductSearchKey, payload: self.breedProductSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProductSearch() {
            if (!this.breedProductSearch || !!this.breedProductSearch.retry) {
                this.trySearchBreedProducts();
            }
        }
    },
    created() {
        this.refreshBreedProductSearch();
    }
}
