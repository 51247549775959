<template>
    <adyen-checkout @completed="onCompleted" @cancelled="onCancelled"></adyen-checkout>
</template>

<script>
    import AdyenCheckout from "../Shared/Adyen/AdyenCheckout.vue";
    import { mapMutations } from 'vuex';

    export default {
        name: 'StoreCheckout',
        computed: {
            onComplete() {
                return this.$route.query.onComplete;
            },
            siteId() {
                return this.$route.query.siteId;
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            onCompleted() {
                if (!!this.siteId) {
                    this.setSelected(this.siteId);
                }
                this.$router.push({ name: this.onComplete || 'store' });
            },
            onCancelled() {;
                this.$router.push({
                    name: 'store',
                    query: {
                        'warning': 'Checkout cancelled'
                    }
                });
            }
        },
        components: {
            'adyen-checkout': AdyenCheckout
        }
    }
</script>
