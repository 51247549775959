import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { WorkingHoursAddMixin } from "./WorkingHoursAddMixin";
import moment from 'moment';

export const WorkingHoursUploadMixin = {
    mixins: [WorkingHoursAddMixin],
    props: {
        defaultHours: Object
    },
    computed: {
        workingHour() {
            return this.workingHours.find(wh => wh.dayOfWeek.id === this.dayId && wh.resourceId === this.resourceId);
        },
        workingHourUpdateModel() {
            return !!this.workingHour ? Object.assign({}, this.workingHour, {
                startLocal: this.fullStartLocal,
                endLocal: this.fullEndLocal,
                dayOff: this.dayOff
            }) : {};
        },
        workingHourUpdateModelValid() {
            return !!this.workingHourUpdateModel.id && this.workingHourValidator.isValid;
        },
        workingHourUpdateModelModified() {
            return JSON.stringify(this.workingHourUpdateModel) !== JSON.stringify(this.workingHour);
        }
    },
    watch: {
        workingHour() {
            this.refreshWorkingHourModel();
        }
    },
    methods: {
        ...mapActions('WorkingHours', [
            'updateWorkingHour'
        ]),
        refreshWorkingHourModel() {
            this.startLocal = !!this.workingHour ? moment(this.workingHour.startLocal).format('HH:mm') : this.defaultHours ? this.defaultHours.startLocal : '08:00';
            this.endLocal = !!this.workingHour ? moment(this.workingHour.endLocal).format('HH:mm') : this.defaultHours ? this.defaultHours.endLocal : '17:00';
            this.dayOff = !!this.workingHour ? this.workingHour.dayOff : this.defaultHours ? this.defaultHours.dayOff : false;
        },
        tryUploadWorkingHour() {
            if (!!this.workingHour) {
                this.tryUpdateWorkingHour();
            } else {
                this.tryAddWorkingHour();
            }
        },
        tryUpdateWorkingHour() {
            const self = this;
            self.workingHourValidator.validate(this.workingHourUpdateModel);
            if (!self.workingHourUpdateModelValid) {
                return;
            }
            if (!self.workingHourUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateWorkingHour({ key: self.siteId, payload: self.workingHourUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshWorkingHourModel();
    }
}
