<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="text">{{$t('Owner Type')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="custom-select form-control text-left text-truncate" data-toggle="dropdown" type="button">
                    {{selectedOptionName}}
                </button>
                <div aria-labelledby="dropdownMenuButton" class="custom-select-dropdown-menu dropdown-menu">
                    <div v-for="option in options"
                         @click="setEntityType(option.value)"
                         :key="option.value"
                         class="custom-select-dropdown-item dropdown-item pointer" 
                         v-bind:class="{ 'active selected': option.value === selectionOptionValue}">
                        {{$t(option.name)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TagTypeEntitySelect',
        props: {
            value: Number
        },
        data() {
            return {
                options: [
                    { value: 0, name: 'Any' },
                    { value: 2, name: 'Client' },
                    { value: 3, name: 'Pet' }
                ],
                entityTypeId: Number
            }
        },
        watch: {
            value(value) {
                this.setEntityType(value);
            }
        },
        computed: {
            selectedOption() {
                return this.options.find(o => o.value === this.entityTypeId);
            },
            selectionOptionValue() {
                return !!this.selectedOption ? this.selectedOption.value : 0;
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.selectedOption.name : '';
            }
        },
        methods: {
            setEntityType(value) {
                this.entityTypeId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setEntityType(this.value);
        }
    }
</script>
