<template>
    <tr>
        <day-cell v-for="dayId in dayIds"
                  :key="dayId" 
                  :day-id="dayId"
                  :working-hours="resourceWorkingHours" 
                  :oneOffHours="resourceOneOffHours">
        </day-cell>
    </tr>
</template>

<script>
    import { ResourceHoursMixin } from "../Resources/ResourceHours/ResourceHoursMixin";
    import UserResourceHoursDay from "./UserResourceHoursDay.vue";
    
    export default {
        name: 'UserResourceHours',
        mixins: [ResourceHoursMixin],
        components: {
            'day-cell': UserResourceHoursDay
        }
    }
</script>
