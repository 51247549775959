import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentApiUserSearches: []
})

const getters = {
    findPaymentApiUserSearch: (state) => key => state.paymentApiUserSearches.find(paus => paus.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentApiUserSearches.find(paus => paus.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentApiUserSearches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setPaymentApiUser(state, { key, paymentApiUser }) {
        const existing = state.paymentApiUserSearches.find(paus => paus.key === key);
        if (!!existing) {
            existing.value = paymentApiUser;
            existing.loading = false;
        } else {
            state.paymentApiUserSearches.push({ key: key, value: paymentApiUser, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentApiUserSearches.find(paus => paus.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findPaymentApiUser({ commit }, { userId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: userId, loading: true });
            PaymentApi.get('User/Find?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setPaymentApiUser', { key: userId, paymentApiUser: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setPaymentApiUser', { key: userId, paymentApiUser: null });
                        resolve(false);
                    } else {
                        commit('setRetry', userId);
                        reject(error);
                    }
                });
        });
    },
    updatePaymentApiUserClaims({ commit }, { userId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: userId, loading: true });
            PaymentApi.put('User/Claim', payload)
                .then(response => {
                    commit('setPaymentApiUser', { key: userId, paymentApiUser: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', { key: userId, loading: false });
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
