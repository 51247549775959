<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Notifications SMS Templates')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <table-mobile>
                <template-mobile v-for="communicationType in smsSommunicationTypes" :key="communicationType.id" :communication-type="communicationType"></template-mobile>
            </table-mobile>
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th aria-controls="clients-table" rowspan="1" colspan="1">{{$t('Name')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <template-desktop v-for="(communicationType, index) in smsSommunicationTypes" :key="communicationType.id" :number="index" :communication-type="communicationType"></template-desktop>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import { CommunicationTypesCacheMixin } from '../../Shared/CommunicationTypesCacheMixin';
    import TableDesktop from '../../Shared/Layout/TableDesktop.vue';
    import TableMobile from '../../Shared/Layout/TableMobile.vue';
    import CommunicationTypeSmsItemDesktop from './CommunicationTypeSmsItemDesktop.vue';
    import CommunicationTypeSmsItemMobile from './CommunicationTypeSmsItemMobile.vue';
    import SiteSelect from '../../Account/SiteSelect.vue';

    export default {
        name: 'SmsMarketingTemplates',
        mixins: [CommunicationTypesCacheMixin],
        computed: {
            smsSommunicationTypes() {
                return this.communicationTypes.length > 0 ? this.communicationTypes.filter(type => type.id < 13) : []
            }
        },
        components: {
            'table-desktop': TableDesktop,
            'template-desktop': CommunicationTypeSmsItemDesktop,
            'table-mobile': TableMobile,
            'template-mobile': CommunicationTypeSmsItemMobile,
            'site-select': SiteSelect
        }
    }
</script>
