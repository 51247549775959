import { CampaignsCacheMixin } from "./CampaignsCacheMixin";
import moment from 'moment';
import { CampaignHelper } from "../../../helpers/CampaignHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { mapActions } from 'vuex';

export const CampaignAddMixin = {
    mixins: [CampaignsCacheMixin],
    data() {
        return {
            methodTypeId: 1,
            subject: '',
            templateId: '',
            siteIds: [],
            tagTypeIds: [],
            breedIds: [],
            notVisitedSinceUtc: null,
            sendDateUtc: moment().utc().format(),
            campaignValidator: CampaignHelper.getCampaignAddValidator()
        }
    },
    computed: {
        campaignAddModel() {
            return {
                methodTypeId: this.methodTypeId,
                subject: this.subject,
                templateId: this.templateId,
                siteIds: this.siteIds,
                tagTypeIds: this.tagTypeIds,
                breedIds: this.breedIds,
                notVisitedSinceUtc: this.notVisitedSinceUtc,
                sendDateUtc: this.sendDateUtc
            }
        },
        campaignAddModelValid() {
            const model = this.campaignAddModel;
            return this.campaignValidator.isValid && !!model.methodTypeId && this.siteId;
        }
    },
    methods: {
        ...mapActions('Campaigns', [
            'addCampaign'
        ]),
        tryAddCampaign() {
            const self = this;
            self.campaignValidator.check(self.campaignAddModel);
            if (!self.campaignAddModelValid) {
                return;
            }
            self.error = '';
            self.addCampaign({ key: self.siteId, payload: self.campaignAddModel })
                .then(() => self.afterCampaignAdd())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterCampaignAdd() {
            // for overriding
        }
    }
}
