<template>
    <tr>
        <td class="d-flex flex-column">
            <div class="client-row">
                <div class="name-avatar">
                    {{startletter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name crd-link" @click="onClientClick">
                        {{clientName}}
                    </div>
                    <div>{{$t('Created Date')}}: {{date}}</div>
                    <div class="crd-contact" @click="onAppointmentClick">
                        {{$t('Appointment')}}: <span class="crd-link">{{appointmentDate}} {{appointmentTime}}</span>
                    </div>
                    <div>{{$t('Status')}}: <span v-bind:class="statusColor">{{$t(status)}} <i v-if="summary" :title="summary" class="far" v-bind:class="statusIcon"></i></span></div>
                    <div>{{$t('Amount')}} ({{currencySymbol}}): {{amount}}</div>
                    <div v-if="refunds.length > 0" @click="showRefunds=!showRefunds">{{$t('Total Refunds')}} ({{currencySymbol}}): {{refundAmount}} <i class="fa fa-chevron-down"></i></div>
                    <div v-if="showRefunds" class="d-flex flex-column" style="font-size: 14px;">
                        <div class="d-flex">
                            <span class="col-5 px-0">{{$t('Date')}}</span>
                            <span class="col-3 px-0">{{$t('Type')}}</span>
                            <span class="col-4 px-0 text-right">{{$t('Amount')}} {{currencySymbol}})</span>
                        </div>
                        <div v-for="refund in refunds" class="d-flex">
                            <span class="col-5 px-0">{{refund.createdUtc | moment(dateFormat)}}</span>
                            <span class="col-3 px-0">{{refund.isPartial ? $t('Partial') : $t('Full')}}</span>
                            <span class="col-4 px-0 text-right">-{{refund.amount.toFixed(2)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { PaymentReportItemMixin } from "./PaymentReportItemMixin";

    export default {
        name: 'PaymentReportItemMobile',
        mixins: [PaymentReportItemMixin],
        props: {
            currencySymbol: String
        }
    }
</script>
