<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area col-md-6">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-file-spreadsheet fa-1x"></i>
                    {{$t('Reports')}}
                </h1>
            </div>
            <div class="col-md-6 px-0 d-md-block d-none">
                <div class="d-flex">
                    <div class="col-3 px-0 mr-1">
                        <site-select v-model="siteIds"></site-select>
                    </div>
                    <div class="col-3 px-0 mr-1">
                        <resource-select v-model="resourceIds" :siteIds="siteIds"></resource-select>
                    </div>
                    <div class="col-3 px-0 mr-1">
                        <tag-select v-model="tagTypeIds" :siteIds="siteIds"></tag-select>
                    </div>
                    <div class="col-3 px-0">
                        <time-period v-model="timePeriod" :has-future-option="true"></time-period>
                    </div>
                </div>
            </div>
            <div class="d-flex w-100 d-block d-md-none mb-2">
                <div class="col-6 pr-1">
                    <site-select v-model="siteIds"></site-select>
                    <resource-select v-model="resourceIds" :siteIds="siteIds" class="mt-1"></resource-select>
                </div>
                <div class="col-6 pl-1">
                    <tag-select v-model="tagTypeIds" :siteIds="siteIds"></tag-select>
                    <time-period v-model="timePeriod" :has-future-option="true" class="mt-1"></time-period>
                </div>
            </div>
        </header>
        <div class="content">
            <div class="row">
                <div v-for="type in reportTypes" class="col-md-3">
                    <report-type :report-type="type" @reportClicked="onReportClicked"></report-type>
                </div>
            </div>
            <div class="row">
                <report-list></report-list>
            </div>
        </div>
        <modal-dialog ref="generateReportConfirm" :title="$t('Generate report?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="modal-body">
                {{$t('Are you sure you want to generate a')}} {{$t(selectedReportName)}} {{$t('report with these parameters?')}}
            </div>
            <div class="modal-footer">
                <button @click="tryAddReport" class="btn btn-primary" type="button" v-bind:disabled="addingReport">
                    {{$t('Confirm')}}
                    <span v-if="addingReport" class="inline-loader"></span>
                </button>
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { ReportAddMixin } from "./ReportAddMixin";
    import { ReportTypesCacheMixin } from "./ReportTypesCacheMixin";
    import { ReportSettingsCacheMixin } from "./ReportSettingsCacheMixin";
    import ModalDialog from "../Layout/ModalDialog.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ReportList from "./ReportList.vue";
    import ReportType from "./ReportType.vue";
    import ResourceMultiSelectDropdown from "../Shared/Resources/ResourceMultiSelectDropdown.vue";
    import TagTypeMultiSelectDropdown from "../Shared/Tags/TagTypeMultiSelectDropdown.vue";
    import TimePeriodSelector from "../Shared/Time/TimePeriodSelector.vue";
    import SiteMultiSelectDropdown from "../Shared/Site/SiteMultiSelectDropdown.vue";

    export default {
        name: 'Reports',
        mixins: [ReportTypesCacheMixin, ReportAddMixin, ReportSettingsCacheMixin],
        computed: {
            selectedReport() {
                return this.reportTypes.find(r => r.id === this.reportTypeId);
            },
            selectedReportName() {
                return !!this.selectedReport ? this.selectedReport.description : 'Unknown';
            }
        },
        methods: {
            onReportClicked(reportType) {
                this.reportTypeId = reportType.id;
                const element = this.$refs.generateReportConfirm.$el;
                $(element).modal('show');
            },
            afterReportAdded() {
                this.$refs.cancelButton.click();
            }
        },
        components: {
            'report-list': ReportList,
            'report-type': ReportType,
            'site-select': SiteMultiSelectDropdown,
            'time-period': TimePeriodSelector,
            'resource-select': ResourceMultiSelectDropdown,
            'tag-select': TagTypeMultiSelectDropdown,
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>
