<template>
    <modal-dialog :title="$t('Schedule Settings')">
        <section class="rounded-card-white mb-3">
            <view-settings v-model="loadingViewSettings"></view-settings>
            <appointment-settings v-model="loadingAppointmentSettings"></appointment-settings>
            <staff-event-settings v-model="loadingStaffEventSettings"></staff-event-settings>
            <resource-settings v-model="loadingResourceSettings"></resource-settings>
            <site-settings v-model="loadingSiteSettings"></site-settings>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
    </modal-dialog>
</template>

<script>
    import { mapMutations } from 'vuex';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import CalendarAppointmentSettings from './CalendarAppointmentSettings.vue';
    import ScheduleSiteSettings from "./ScheduleSiteSettings.vue";
    import CalendarViewSettingsDesktop from "./CalendarViewSettingsDesktop.vue";
    import CalendarResourceSettings from './CalendarResourceSettings.vue';
    import CalendarStaffEventSettings from './CalendarStaffEventSettings.vue';

    export default {
        name: 'ScheduleSettingsDesktop',
        data() {
            return {
                loadingSiteSettings: false,
                loadingResourceSettings: false,
                loadingAppointmentSettings: false,
                loadingViewSettings: false,
                loadingStaffEventSettings: false
            }
        },
        computed: {
            loading() {
                return this.loadingSiteSettings || this.loadingResourceSettings || this.loadingAppointmentSettings || this.loadingViewSettings || this.loadingStaffEventSettings;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveScheduleSettings'
            ]),
            save() {
                this.triggerSaveScheduleSettings();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'site-settings': ScheduleSiteSettings,
            'view-settings': CalendarViewSettingsDesktop,
            'appointment-settings': CalendarAppointmentSettings,
            'resource-settings': CalendarResourceSettings,
            'staff-event-settings': CalendarStaffEventSettings
        }
    }
</script>
