import { ErrorHelper } from "../../helpers/ErrorHelper";
import { TaxAddMixin } from "./TaxAddMixin";
import { mapActions } from 'vuex';

export const TaxUploadMixin = {
    mixins: [TaxAddMixin],
    computed: {
        taxUpdateModel() {
            return Object.assign({}, this.tax, {
                rate: this.rate,
                number: this.number
            });
        },
        taxUpdateModelValid() {
            const model = this.taxUpdateModel;
            return !!model.id && !!model.siteId && this.taxValidator.isValid;
        },
        taxUpdateModelModified() {
            return JSON.stringify(this.taxUpdateModel) !== JSON.stringify(this.tax);
        }
    },
    watch: {
        tax() {
            this.refreshTaxModel();
        }
    },
    methods: {
        ...mapActions('Taxes', [
            'updateTax'
        ]),
        tryUpdateTax() {
            const self = this;
            self.taxValidator.validate(self.taxUpdateModel);
            if (!self.taxUpdateModelValid || !self.taxUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateTax(self.taxUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryUploadTax() {
            if (!!this.tax) {
                this.tryUpdateTax();
            } else {
                this.tryAddTax();
            }
        },
        refreshTaxModel() {
            this.rate = !!this.tax ? this.tax.rate : (!!this.accountSettingObject ? this.accountSettingObject.rate : null);
            this.number = !!this.tax ? this.tax.number : (!!this.accountSettingObject ? this.accountSettingObject.number : '');
        }
    }
}
