import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin";
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const MigrationCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Migrations', [
            'findMigrationCache'
        ]),
        migrationCache() {
            return this.findMigrationCache(this.siteId);
        },
        migration() {
            return !!this.migrationCache ? this.migrationCache.value : null;
        },
        loadingMigration() {
            return !!this.migrationCache && this.migrationCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshMigration();
        }
    },
    methods: {
        ...mapActions('Migrations', [
            'fetchMigration'
        ]),
        tryFetchMigration() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchMigration(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshMigration() {
            if (!this.migrationCache || !!this.migrationCache.retry) {
                this.tryFetchMigration();
            }
        }
    },
    created() {
        this.refreshMigration();
    }
}
