import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SpeciesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Species', [
            'fetching',
            'fetched',
            'species'
        ])
    },
    methods: {
        ...mapActions('Species', [
            'getSpecies'
        ]),
        refreshSpecies() {
            if (!this.fetched && !this.fetching) {
                this.getAndCacheSpecies();
            }
        },
        getAndCacheSpecies() {
            const self = this;
            self.getSpecies()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSpecies();
    }
}
