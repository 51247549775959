import { mapActions, mapGetters } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const CreditPackSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CreditPackSearches', [
            'findCreditPackCache',
        ]),
        creditPackIds() {
            console.log('!!!OVERRIDE COMPUTED - creditPackIds!!!');
            return [];
        },
        missingCreditPackIds() {
            return this.creditPackIds.filter(id => !this.findCreditPackCache(id));
        },
        creditPackSearchModel() {
            return {
                creditPackIds: this.missingCreditPackIds
            }
        },
        creditPackSearchModelValid() {
            return this.creditPackSearchModel.creditPackIds.length > 0;
        }
    },
    watch: {
        creditPackSearchModel() {
            this.trySearchCreditPacks();
        }
    },
    methods: {
        ...mapActions('CreditPackSearches', [
            'searchCreditPacks'
        ]),
        trySearchCreditPacks() {
            const self = this;
            if (!self.creditPackSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchCreditPacks({ keys: self.missingCreditPackIds, payload: self.creditPackSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
