<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-country" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Country')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-country">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <div v-if="isSearching">
                        <country-search v-model="country"></country-search>
                        <a v-if="id" @click="editing=false" class="link pointer">{{$t('Cancel')}}</a>
                    </div>
                    <template v-else>
                        <div class="mb-1">
                            {{name}}
                        </div>
                        <a v-if="accountSettingUploadModelModified" @click="clear" class="link pointer">Clear</a>
                        <template v-else>
                            <a @click="editing=true" class="link pointer">{{$t('Search')}}</a>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { AccountSettingSiteDefaultMixin } from "../../mixins/Settings/Account/AccountSettingSiteDefaultMixin";
    import CountryOptionSearch from "../Shared/Country/CountryOptionSearch.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'DefaultSiteCountry',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                id: '',
                name: '',
                currency: '',
                currencySymbol: '',
                editing: false
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultCountry';
            },
            accountSettingEditModel() {
                return {
                    id: this.id,
                    name: this.name,
                    currency: this.currency,
                    currencySymbol: this.currencySymbol
                }
            },
            accountSettingEditModelValid() {
                const model = this.accountSettingEditModel;
                return !!model.id;
            },
            isSearching() {
                return this.editing || !this.id;
            },
            country: {
                get() {
                    return this.accountSettingEditModel;
                },
                set(value) {
                    this.setEditModel(value);
                    this.editing = false;
                }
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.setEditModel(this.accountSettingObject);
            },
            setEditModel(countryInfo) {
                this.id = !!countryInfo ? countryInfo.id : '';
                this.name = !!countryInfo ? countryInfo.name : '';
                this.currency = !!countryInfo ? countryInfo.currency : '';
                this.currencySymbol = !!countryInfo ? countryInfo.currencySymbol : '';
            },
            clear() {
                this.editing = false;
                this.refreshAccountSettingEditModel();
            }
        },
        components: {
            'country-search': CountryOptionSearch,
            'error-banner': ErrorBanner
        }
    }
</script>
