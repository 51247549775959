import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapState, mapActions } from 'vuex';
import { ContractHelper } from '../../helpers/ContractHelper.js';
import { ContractCacheMixin } from './ContractCacheMixin.js';

export const ContractAddMixin = {
    mixins: [ContractCacheMixin],
    data() {
        return {
            templateId: '',
            note: '',
            contractValidator: ContractHelper.getContractValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'contractAddedEvent'
        ]),
        contractAddModel() {
            return {
                clientId: this.clientId,
                templateId: this.templateId,
                note: this.note
            }
        },
        contractAddModelValid() {
            return !!this.clientId && this.contractValidator.isValid;
        }
    },
    watch: {
        contractAddedEvent() {
            this.resetContractModel();
        }
    },
    methods: {
        ...mapActions('Contracts', [
            'addContract'
        ]),
        resetContractModel() {
            this.templateId = '';
            this.note = '';
        },
        afterContractAdd() {
            // override if necessary
        },
        tryAddContract() {
            const self = this;
            self.error = '';
            self.contractValidator.check(self.contractAddModel);
            if (!self.contractAddModelValid) {
                return;
            }
            self.addContract({ key: self.clientId, payload: self.contractAddModel })
                .then(response => self.afterContractAdd(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
