<template>
    <div class="row form-group">
        <div class="col-md-8 offset-md-4">
            <label for="title">{{$t('Duration')}}</label>
            <span v-if="loadingCustomDurations" class="inline-loader"></span>
            <div v-else class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{ `${getOptionNameValue(durationName)} ${$t(getOptionNameUnit(durationName))}` }}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in combinedOptions"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setDuration(option.value)"
                            v-bind:class="{'active': option.value === duration}">
                            {{ `${getOptionNameValue(option.name)} ${$t(getOptionNameUnit(option.name))}` }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { PetDurationCacheMixin } from '../../Pet/Durations/PetDurationCacheMixin';
    import { AppointmentDurationOptionsMixin } from '../AppointmentDurationOptionsMixin';

    export default {
        name: 'AppointmentDuration',
        mixins: [PetDurationCacheMixin, AppointmentDurationOptionsMixin],
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'duration'
            ]),
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            durationName() {
                const option = this.getOptionForDuration(this.duration);
                return !!option ? option.name : '';
            },
            appointmentOrder() {
                return this.indexAppointmentOrder(0);
            },
            pet() {
                return this.appointmentOrder.pet;
            },
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            requiredMinutes() {
                return !!this.petDuration ? this.petDuration.minutes + (this.petDuration.hours * 60) : this.duration;
            }
        },
        watch: {
            requiredMinutes() {
                this.setInitialDuration();
            },
            combinedOptions() {
                this.setInitialDuration();
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setDuration'
            ]),
            setInitialDuration() {
                const option = this.getOptionForDuration(this.requiredMinutes);
                this.setDuration(option.value);
            }
        },
        created() {
            this.setInitialDuration();
        }
    }
</script>
