<template>
    <div v-if="canDelete" class="ml-auto">
        <button @click="onClick" v-bind:disabled="loading" type="button" class="btn btn-delete">
            <i class="fal fa-trash-alt"></i>
            {{$t('Delete')}}
        </button>
    </div>
</template>

<script>
    import { UserCacheMixin } from "./UserCacheMixin";

    export default {
        name: 'DeleteButton',
        mixins: [UserCacheMixin],
        methods: {
            onClick() {
                this.$emit('clicked');
            }
        }
    }
</script>
