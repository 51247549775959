<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="number">{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteText" class="form-control" rows="3" cols="50" :placeholder="$t('Add notes (optional)')"></textarea>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { NoteAddMixin } from "../../Shared/Notes/NoteAddMixin";

    export default {
        name: 'OptionsClientNotes',
        mixins: [NoteAddMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            ownerId() {
                return this.addedClientId;
            },
            ownerTypeId() {
                return 2;
            },
            noteTypeId() {
                return 1;
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
                this.tryAddNote();
                this.addedClientId = '';
            }
        }
    }
</script>
