<template>
    <div>
        <ul class="nav nav-tabs-ps" role="tablist">
            <li class="nav-item">
                <a ref="addAppointmentTab" class="nav-link active" data-toggle="tab" href="#existing-client-pane" role="tab">{{$t('Existing client')}}</a>
            </li>
            <li class="nav-item">
                <a ref="addClientPetTab" class="nav-link" data-toggle="tab" href="#add-new-pane" role="tab">{{$t('Add new')}}</a>
            </li>
        </ul>
        <div class="tab-content">
            <br />
            <add-existing id="existing-client-pane"></add-existing>
            <add-client-pet id="add-new-pane"></add-client-pet>
        </div>
    </div>
</template>

<script>
    import AddAppointmentExisting from './AddAppointmentExisting.vue';
    import AddClientPet from '../AddClient/AddClientPet.vue';

    export default {
        name: 'AddAppointment',
        components: {
            'add-existing': AddAppointmentExisting,
            'add-client-pet': AddClientPet
        }
    }
</script>
