<template>
    <th @click="sort" class="sorting" v-bind:class="sortClass">
        <slot></slot>
    </th>
</template>

<script>
    export default {
        name: 'TableSortableHeader',
        props: {
            keyName: String,
            value: Object
        },
        computed: {
            sortKey() {
                return this.value.sortKey;
            },
            ascending() {
                return this.value.ascending;
            },
            active() {
                return this.keyName === this.sortKey;
            },
            sortClass() {
                return this.active ? this.ascending ? ' sorting_asc' : ' sorting_desc' : '';
            }
        },
        methods: {
            sort() {
                this.$emit('input', {
                    sortKey: this.keyName,
                    ascending: this.active ? !this.ascending : true
                });
            }
        }
    }
</script>
