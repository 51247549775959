import { Api } from '../../services/Api';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import router from '../../routing';

export const SiteAddAllowedMixin = {
    data() {
        return {
            siteAddAllowed: false,
            fetchingSiteAddAllowed: false
        }
    },
    methods: {
        openSiteAdd() {
            const self = this;

            self.fetchingSiteAddAllowed = true;

            return new Promise((resolve) => {
                Api.get('Site/Allowed')
                    .then(response => {
                        self.siteAddAllowed = response.data.allowed;

                        if (self.siteAddAllowed) {
                            router.push({ name: 'siteAdd' });
                        }
                        else if (self.$route.name !== 'upsell') {
                            router.push({ name: 'upsell' });
                        }

                        resolve(response);
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.fetchingSiteAddAllowed = false);
            })
        }
    }
}