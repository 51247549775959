<template>
    <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
        <ul class="dropdown-menu inner show" role="presentation">
            <li v-for="country in countries" :key="country.id" class="pointer">
                <a @click="select(country)" role="option" class="dropdown-item" v-bind:class="{ 'active selected': country.id===selectedId }">
                    {{country.name}}
                </a>
            </li>
            <li v-if="countries.length === 0">
                <a role="option" class="dropdown-item">
                    {{$t('No results found')}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'CountryOptionSearchResults',
        props: {
            countries: Array,
            selectedId: String
        },
        methods: {
            select(country) {
                this.$emit('selected', country);
            }
        }
    }
</script>
