<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{$t('Campaign SMS Templates')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="gotoAddTemplate" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">{{$t('Add Template')}}</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button @click="gotoAddTemplate" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add Template')}}
                </button>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <table-mobile>
                <item-mobile v-for="(template, index) in smsCampaignTemplates" :key="template.id" :template="template"></item-mobile>
            </table-mobile>
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th>{{$t('Subject')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <item-desktop v-for="(template, index) in smsCampaignTemplates" :key="template.id" :number="index" :template="template"></item-desktop>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import { SmsCampaignTemplatesCacheMixin } from "../../../mixins/Campaigns/SmsCampaignTemplatesCacheMixin";
    import SiteSelect from "../../Account/SiteSelect.vue";
    import TableDesktop from "../../Shared/Layout/TableDesktop.vue";
    import TableMobile from "../../Shared/Layout/TableMobile.vue";
    import CampaignSmsTemplateItemDesktop from "./CampaignSmsTemplateItemDesktop.vue";
    import CampaignSmsTemplateItemMobile from './CampaignSmsTemplateItemMobile.vue';

    export default {
        name: 'CampaignSmsTemplates',
        mixins: [SmsCampaignTemplatesCacheMixin],
        methods: {
            gotoAddTemplate() {
                router.push({ name: 'campaignSmsTemplateAdd' });
            }
        },
        components: {
            'site-select': SiteSelect,
            'table-mobile': TableMobile,
            'table-desktop': TableDesktop,
            'item-desktop': CampaignSmsTemplateItemDesktop,
            'item-mobile': CampaignSmsTemplateItemMobile
        }
    }
</script>
