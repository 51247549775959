import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { CountryCacheMixin } from "./CountryCacheMixin"

export const CountryAddMixin = {
    mixins: [CountryCacheMixin],
    data() {
        return {
            countryOption: null
        }
    },
    computed: {
        ...mapState('Countries', [
            'addingCountry'
        ])
    },
    methods: {
        ...mapActions('Countries', [
            'addCountry'
        ]),
        tryAddCountry() {
            const self = this;
            if (!self.countryOption) {
                return;
            }
            self.error = '';
            self.addCountry(self.countryOption.id)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
