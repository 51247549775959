import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { DateFormatSettingCacheMixin } from "./DateFormatSettingCacheMixin";

export const DateFormatSettingAddMixin = {
    mixins: [DateFormatSettingCacheMixin],
    data() {
        return {
            dateFormatId: 1
        }
    },
    computed: {
        dateFormatSettingAddModel() {
            return {
                dateFormatId: this.dateFormatId
            }
        },
        dateFormatSettingAddModelValid() {
            return this.dateFormatSettingAddModel.dateFormatId > -1;
        }
    },
    methods: {
        ...mapActions('DateFormatSettings', [
            'addDateFormatSetting'
        ]),
        tryAddDateFormatSetting() {
            const self = this;
            if (!self.dateFormatSettingAddModelValid) {
                return;
            }
            self.error = '';
            self.addDateFormatSetting({ key: self.siteId, payload: self.dateFormatSettingAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
