import { DateDisplayMixin } from "../../components/Shared/Time/DateDisplayMixin";
import { TimeDisplayMixin } from "../../components/Shared/Time/TimeDisplayMixin";
import moment from 'moment';

export const ClientLogItemMixin = {
    mixins: [DateDisplayMixin, TimeDisplayMixin],
    props: {
        log: {
            type: Object,
            required: true
        }
    },
    computed: {
        datestamp() {
            return moment(this.log.timestampUtc).format(this.momentJSFormat);
        },
        timestamp() {
            return moment(this.log.timestampUtc).format(this.timeMomentJSFormat);
        },
        level() {
            return this.log.level;
        },
        colour() {
            return this.level === 'Warning' ? '#ff0000' : '#000000';
        },
        type() {
            return this.log.filter;
        },
        message() {
            return this.log.message;
        }
    }
}
