import { Api } from '../../services/Api';

const state = () => ({
    addingEmployee: false,
    updatingEmployee: false,
    employees: []
})

const mutations = {
    setAdding(state, adding) {
        state.addingEmployee = !!adding;
    },
    setUpdating(state, updating) {
        state.updatingEmployee = !!updating;
    },
    appendEmployee(state, employee) {
        state.employees.push(employee);
    },
    insertEmployee(state, employee) {
        const existing = state.employees.find(e => e.id === employee.id);
        if (!!existing) {
            state.employees.splice(state.employees.indexOf(existing), 1, employee);
        } else {
            state.employees.push(employee);
        }
    },
    removeEmployee(state, id) {
        state.employees = state.employees.filter(e => e.id !== id);
    }
}

const actions = {
    addEmployee({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Employee/User', payload)
                .then(response => {
                    commit('appendEmployee', response.data);
                    commit('ApplicationEvents/triggerUserAdded', response.data, { root: true });
                    commit('EmployeeCount/clearEmployeeCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateEmployee({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Employee/User', payload)
                .then(response => {
                    commit('insertEmployee', response.data);
                    commit('ApplicationEvents/triggerUserUpdated', response.data, { root: true });
                    commit('EmployeeCount/clearEmployeeCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    },
    deleteEmployee({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.delete('Employee/User/' + id)
                .then(response => {
                    commit('removeEmployee', id);
                    commit('ApplicationEvents/triggerUserDeleted', null, { root: true });
                    commit('EmployeeCount/clearEmployeeCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
