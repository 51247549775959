<template>
    <div>
        <adyen-checkout @completed="onCompleted" @cancelled="onCancelled"></adyen-checkout>
    </div>
</template>

<script>
    import AdyenCheckout from "../Shared/Adyen/AdyenCheckout.vue";
    import { mapMutations } from 'vuex';

    export default {
        name: 'PaymentRetry',
        methods: {
            ...mapMutations('SubscriptionPaymentFailures', [
                'clearPaymentFailures'
            ]),
            onCompleted() {
                this.returnToPayment();
            },
            onCancelled() {
                this.returnToPayment();
            },
            returnToPayment() {
                this.clearPaymentFailures();
                this.$router.push({ name: 'payment' });
            }
        },
        components: {
            'adyen-checkout': AdyenCheckout
        }
    }
</script>
