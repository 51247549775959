<template>
    <div class="ps-datatable d-md-none">
        <table class="display clients-table-mobile">
            <tbody>
                <slot></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'TableMobile',
        methods: {
            onScroll(event) {
                const height = event.target.documentElement.offsetHeight;
                if (window.innerHeight + window.pageYOffset > height - (height * 0.1)) {
                    this.$emit('next');
                }
            }
        },
        created() {
            if (window.innerWidth < 768) {
                window.addEventListener('scroll', this.onScroll);
            }
        },
        destroyed() {
            if (window.innerWidth < 768) {
                window.removeEventListener('scroll', this.onScroll);
            }
        }
    }
</script>
