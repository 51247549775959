<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label for="title">{{$t('User name')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <div class="row">
                    <div class="col-6">
                        <label for="text" class="d-md-none">{{$t('First name')}}</label>
                        <input v-model="firstName" type="text" value="" :placeholder="$t('First name')" class="form-control">
                        <validation-message v-if="validator.firstName" :message="validator.firstName"></validation-message>
                    </div>
                    <div class="col-6">
                        <label for="text" class="d-md-none">Last name</label>
                        <input v-model="lastName" type="text" value="" :placeholder="$t('Last name')" class="form-control">
                        <validation-message v-if="validator.lastName" :message="validator.lastName"></validation-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'UserNameInput',
        props: {
            value: Object,
            validator: Object
        },
        computed: {
            firstName: {
                get() {
                    return this.value.firstName;
                },
                set(value) {
                    this.$emit('input', Object.assign({}, this.value, {
                        firstName: value
                    }));
                }
            },
            lastName: {
                get() {
                    return this.value.lastName;
                },
                set(value) {
                    this.$emit('input', Object.assign({}, this.value, {
                        lastName: value
                    }));
                }
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
