<template>
    <div>
        <div v-if="!!tax" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Tax Rate')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <number-input v-model="rate" :title="`${$t('Tax Rate')} (%)`">
                <validation-message v-if="taxValidator.rate" :message="taxValidator.rate"></validation-message>
            </number-input>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Tax Number')}}</label>
                </div>
                <div class="col-md-8">
                    <input v-model="number" type="text" class="form-control" maxlength="50">
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { TaxAddMixin } from "../../../mixins/Tax/TaxAddMixin";
    import NumberInput from "../../Shared/Input/NumberInput.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'SiteAddTaxRate',
        mixins: [TaxAddMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            canAddTaxRate() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !this.taxAddModelModified || !!this.tax;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingTaxes
                }
            }
        },
        watch: {
            canAddTaxRate(value) {
                if (value) {
                    this.tryAddTax();
                }
            },
            saveSiteEvent() {
                if (this.canAddTaxRate) {
                    this.tryAddTax();
                }
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'error-banner': ErrorBanner,
            'number-input': NumberInput
        }
    }
</script>
