<template>
    <div class="form-group">
        <label>Template</label>
        <span v-if="loadingContractTemplatesForSite" class="inline-loader"></span>
        <template v-else>
            <div class="bootstrap-select form-control">
                <template v-if="contractTemplatesForSite.length > 0">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                        {{selectedName}}
                    </button>
                    <div class="inner show">
                        <ul class="dropdown-menu">
                            <li v-for="template in contractTemplatesForSite"
                                class="dropdown-item pointer"
                                :key="template.id"
                                @click="setTemplateId(template.id)"
                                v-bind:class="{'active': template.id === templateId}">
                                {{template.templateTypeName}}
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-else>
                    <button type="button" class="btn dropdown-toggle btn-light" disabled>
                        No template available
                    </button>
                </template>
            </div>
            <validation-message v-if="validator.templateId" :message="validator.templateId"></validation-message>
        </template>
    </div>
</template>

<script>
    import { ContractTemplateSiteCacheMixin } from "../../Shared/ContractTemplateSiteCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'DocumentTemplateSelect',
        mixins: [ContractTemplateSiteCacheMixin],
        props: {
            value: String,
            validator: Object
        },
        data() {
            return {
                templateId: String
            }
        },
        computed: {
            selectedOption() {
                return this.contractTemplatesForSite.find(c => c.id === this.templateId);
            },
            selectedName() {
                return !!this.selectedOption ? this.selectedOption.templateTypeName : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            setTemplateId(value) {
                this.templateId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setTemplateId(this.value);
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
