import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin"
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const EmailCampaignTemplatesCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('EmailCampaignTemplates', [
            'findEmailCampaignTemplatesCache'
        ]),
        emailCampaignTemplatesCache() {
            return this.findEmailCampaignTemplatesCache(this.siteId);
        },
        emailCampaignTemplates() {
            return !!this.emailCampaignTemplatesCache ? this.emailCampaignTemplatesCache.values : [];
        },
        loadingEmailCampaignTemplates() {
            return !!this.emailCampaignTemplatesCache && this.emailCampaignTemplatesCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshEmailCampaignTemplates();
        }
    },
    methods: {
        ...mapActions('EmailCampaignTemplates', [
            'findEmailCampaignTemplates'
        ]),
        refreshEmailCampaignTemplates() {
            if (!this.emailCampaignTemplatesCache || this.emailCampaignTemplatesCache.retry) {
                this.tryFindEmailCampaignTemplates();
            }
        },
        tryFindEmailCampaignTemplates() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.findEmailCampaignTemplates(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailCampaignTemplates();
    }
}
