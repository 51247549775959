import { Api } from "../../services/Api";

const state = () => ({
    fetchedSubscriptionTypes: false,
    fetchingSubscriptionTypes: false,
    subscriptionTypes: []
});

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSubscriptionTypes = !!fetching;
        state.fetchedSubscriptionTypes = false;
    },
    setSubscriptionTypes(state, types) {
        state.subscriptionTypes = types;
        state.fetchedSubscriptionTypes = true;
        state.fetchingSubscriptionTypes = false;
    }
}

const actions = {
    getSubscriptionTypes({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Subscription/Type')
                .then(response => {
                    commit('setSubscriptionTypes', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
