import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteNotificationDefaultsSettingMixin } from "./SiteNotificationDefaultsSettingMixin";

export const SiteNotificationDefaultsSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteNotificationDefaultsSettingMixin],
    data() {
        return {
            isSmsCancellationEnabled: false,
            isSmsUpdatedEnabled: false,
            isSmsReminderEnabled: false,
            isSmsConfirmationEnabled: false,
            isSmsThankYouEnabled: false,
            isSmsNoShowEnabled: false,
            isSmsMissYouEnabled: false,
            isEmailCancellationEnabled: false,
            isEmailUpdatedEnabled: false,
            isEmailReminderEnabled: false,
            isEmailConfirmationEnabled: false,
            isEmailThankYouEnabled: false,
            isEmailNoShowEnabled: false,
            isEmailMissYouEnabled: false
        }
    },
    computed: {
        isSmsAppointmentsEnabled: {
            get() {
                return !!this.isSmsCancellationEnabled || !!this.isSmsUpdatedEnabled || !!this.isSmsReminderEnabled || !!this.isSmsConfirmationEnabled || !!this.isSmsThankYouEnabled || !!this.isSmsNoShowEnabled;
            },
            set(value) {
                this.isSmsCancellationEnabled = !!value;
                this.isSmsUpdatedEnabled = !!value;
                this.isSmsReminderEnabled = !!value;
                this.isSmsConfirmationEnabled = !!value;
                this.isSmsThankYouEnabled = !!value;
                this.isSmsNoShowEnabled = !!value;
            }
        },
        isEmailAppointmentsEnabled: {
            get() {
                return !!this.isEmailCancellationEnabled || !!this.isEmailUpdatedEnabled || !!this.isEmailReminderEnabled || !!this.isEmailConfirmationEnabled || !!this.isEmailThankYouEnabled || !!this.isEmailNoShowEnabled;
            },
            set(value) {
                this.isEmailCancellationEnabled = !!value;
                this.isEmailUpdatedEnabled = !!value;
                this.isEmailReminderEnabled = !!value;
                this.isEmailConfirmationEnabled = !!value;
                this.isEmailThankYouEnabled = !!value;
                this.isEmailNoShowEnabled = !!value;
            }
        },
        isSmsAppointmentsPartiallyEnabled() {
            return (!!this.isSmsCancellationEnabled || !!this.isSmsUpdatedEnabled || !!this.isSmsReminderEnabled || !!this.isSmsConfirmationEnabled || !!this.isSmsThankYouEnabled || !!this.isSmsNoShowEnabled) &&
                !(this.isSmsCancellationEnabled && this.isSmsUpdatedEnabled && this.isSmsReminderEnabled && this.isSmsConfirmationEnabled && this.isSmsThankYouEnabled && this.isSmsNoShowEnabled);
        },
        isEmailAppointmentsPartiallyEnabled() {
            return (!!this.isEmailCancellationEnabled || !!this.isEmailUpdatedEnabled || !!this.isEmailReminderEnabled || !!this.isEmailConfirmationEnabled || !!this.isEmailThankYouEnabled || !!this.isEmailNoShowEnabled) &&
                !(this.isEmailCancellationEnabled && this.isEmailUpdatedEnabled && this.isEmailReminderEnabled && this.isEmailConfirmationEnabled && this.isEmailThankYouEnabled && this.isEmailNoShowEnabled);
        },
        settingEditModel() {
            return {
                isSmsCancellationEnabled: this.isSmsCancellationEnabled,
                isSmsUpdatedEnabled: this.isSmsUpdatedEnabled,
                isSmsReminderEnabled: this.isSmsReminderEnabled,
                isSmsConfirmationEnabled: this.isSmsConfirmationEnabled,
                isSmsThankYouEnabled: this.isSmsThankYouEnabled,
                isSmsNoShowEnabled: this.isSmsNoShowEnabled,
                isSmsMissYouEnabled: this.isSmsMissYouEnabled,
                isEmailCancellationEnabled: this.isEmailCancellationEnabled,
                isEmailUpdatedEnabled: this.isEmailUpdatedEnabled,
                isEmailReminderEnabled: this.isEmailReminderEnabled,
                isEmailConfirmationEnabled: this.isEmailConfirmationEnabled,
                isEmailThankYouEnabled: this.isEmailThankYouEnabled,
                isEmailNoShowEnabled: this.isEmailNoShowEnabled,
                isEmailMissYouEnabled: this.isEmailMissYouEnabled
            }
        },
        settingEditModelValid() {
            return true;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.isSmsCancellationEnabled = this.settingObject.isSmsCancellationEnabled;
            this.isSmsUpdatedEnabled = this.settingObject.isSmsUpdatedEnabled;
            this.isSmsReminderEnabled = this.settingObject.isSmsReminderEnabled;
            this.isSmsConfirmationEnabled = this.settingObject.isSmsConfirmationEnabled;
            this.isSmsThankYouEnabled = this.settingObject.isSmsThankYouEnabled;
            this.isSmsNoShowEnabled = this.settingObject.isSmsNoShowEnabled;
            this.isSmsMissYouEnabled = this.settingObject.isSmsMissYouEnabled;
            this.isEmailCancellationEnabled = this.settingObject.isEmailCancellationEnabled;
            this.isEmailUpdatedEnabled = this.settingObject.isEmailUpdatedEnabled;
            this.isEmailReminderEnabled = this.settingObject.isEmailReminderEnabled;
            this.isEmailConfirmationEnabled = this.settingObject.isEmailConfirmationEnabled;
            this.isEmailThankYouEnabled = this.settingObject.isEmailThankYouEnabled;
            this.isEmailNoShowEnabled = this.settingObject.isEmailNoShowEnabled;
            this.isEmailMissYouEnabled = this.settingObject.isEmailMissYouEnabled
        }
    }
}
