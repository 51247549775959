<template>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{$t('Template Name')}}<span class="required-field"></span></label>
            <input v-model="templateTypeName" class="form-control" type="text">
            <validation-message v-if="validator.templateTypeName" :message="validator.templateTypeName"></validation-message>
        </div>
    </div>
</template>

<script>
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'TemplateNameInput',
        props: {
            value: String,
            validator: Object
        },
        computed: {
            templateTypeName: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
