<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header sub" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Client Details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('First name')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <div>{{bookingFirstName}}</div>
                        <div v-if="clientFirstNameChanged" class="mb-0" style="color: #F4457B;">{{$t("Client's current first name:")}} <strong>{{clientFirstName}}</strong></div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('Last name')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <div>{{bookingLastName}}</div>
                        <div v-if="clientLastNameChanged" class="mb-0" style="color: #F4457B;">{{$t("Client's current last name:")}} <strong>{{clientLastName}}</strong></div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('Email')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <div>{{bookingEmail}}</div>
                        <div v-if="clientEmailChanged" class="mb-0" style="color: #F4457B;">{{$t("Client's current email:")}} <strong>{{clientEmail}}</strong></div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="email">{{$t('Mobile tel')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <div>{{bookingTelephoneMobile}}</div>
                        <div v-if="clientTelephoneMobileChanged" class="mb-0" style="color: #F4457B;">{{$t("Client's current mobile:")}} <strong>{{clientTelephoneMobile}}</strong></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";

    export default {
        name: 'BookingClientDetails',
        mixins: [BookingCacheMixin],
        props: {
            id: '',
            client: Object
        },
        computed: {
            bookingId() {
                return !!this.id ? this.id : '';
            },
            bookingFirstName() {
                return !!this.booking ? this.booking.firstName : '';
            },
            bookingLastName() {
                return !!this.booking ? this.booking.lastName : '';
            },
            bookingEmail() {
                return !!this.booking ? this.booking.email : '';
            },
            bookingTelephoneMobile() {
                return !!this.booking ? this.booking.telephoneMobile : '';
            },
            clientDeleted() {
                return !this.client;
            },
            clientFirstName() {
                return !!this.client ? this.client.firstName : ''
            },
            clientLastName() {
                return !!this.client ? this.client.lastName : ''
            },
            clientEmail() {
                return !!this.client ? this.client.email : ''
            },
            clientTelephoneMobile() {
                return !!this.client ? this.client.telephoneMobile : ''
            },
            clientFirstNameChanged() {
                return !!this.clientFirstName ? this.clientFirstName === this.bookingFirstName ? false : true : false;
            },
            clientLastNameChanged() {
                return !!this.clientLastName ? this.clientLastName === this.bookingLastName ? false : true : false;
            },
            clientEmailChanged() {
                return !!this.clientEmail ? this.clientEmail === this.bookingEmail ? false : true : false;
            },
            clientTelephoneMobileChanged() {
                return !!this.clientTelephoneMobile ? this.clientTelephoneMobile === this.bookingTelephoneMobile ? false : true : false;
            }
        }
    }
</script>
