import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BreedCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Breeds', [
            'findBreedCache'
        ]),
        breedId() {
            console.log('!!!OVERRIDE breedId() computed method!!!');
        },
        breedCache() {
            return this.findBreedCache(this.breedId);
        },
        breed() {
            return this.breedCache ? this.breedCache.value : null;
        },
        breedName() {
            return !!this.breed ? this.breed.name : 'Unknown';
        },
        loadingBreed() {
            return this.breedCache && this.breedCache.loading;
        }
    },
    watch: {
        breedId() {
            this.refreshBreed();
        }
    },
    methods: {
        ...mapActions('Breeds', [
            'fetchBreed'
        ]),
        refreshBreed() {
            if (!this.breedCache || !!this.breedCache.retry) {
                this.tryFetchBreed();
            }
        },
        tryFetchBreed() {
            const self = this;
            if (!self.breedId) {
                return;
            }
            self.error = '';
            self.fetchBreed(self.breedId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBreed();
    }
}
