<template>
    <div class="dropdown">
        <button aria-expanded="false" aria-haspopup="true" class="filter-dd" data-toggle="dropdown" id="dropdownMenuButton2" type="button">
            {{$t('Sites')}}
            <i class="far fa-chevron-down"></i>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1">
            <div v-for="site in options" :key="site.id" class="dropdown-item px-2">
                <label class="custom-control custom-checkbox">
                    <input v-model="site.selected" @change="onOptionsChanged" class="custom-control-input" type="checkbox" v-bind:disabled="selected.id === site.id">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{site.name}}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { ScheduleFilterLocationMixin } from './ScheduleFilterLocationMixin.js';

    export default {
        name: 'ScheduleFilterLocation',
        mixins: [ScheduleFilterLocationMixin]
    }
</script>
