<template>
    <span class="text">
        {{fullName}}{{petNames}}
    </span>
</template>

<script>
    import { PetCacheMixinV2 } from "../../Shared/PetCacheMixinV2";

    export default {
        name: 'ClientDisplayItem',
        mixins: [PetCacheMixinV2],
        props: {
            client: Object
        },
        computed: {
            clientId() {
                return this.client.id;
            },
            fullName() {
                return this.client.firstName + ' ' + this.client.lastName;
            },
            petNames() {
                return this.pets.length > 0 ? ' (' + this.pets.map(p => p.name).join(', ') + ')' : '';
            }
        },
        methods: {
            tryGetPets() {
                // do nothing
            }
        }
    }
</script>
