<template>
    <div>
        <div v-if="completed && addedPetId" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>Pet images successfully added.</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Add photos')}}</label>
                </div>
                <div class="col-md-8">
                    <div class="file-upload mw-400 ml-0">
                        <label>
                            <span>{{$t('Browse files')}}</span>
                            <input @change="onFilePicked" type="file" accept="image/*" multiple="multiple" />
                        </label>
                    </div>
                </div>
                <div class="image-uploads">
                    <ul>
                        <li v-for="upload in imagePreviewSet" :ref="upload.id" class="active">
                            <button @click="removeUpload(upload)" class="btn" type="button" v-if="!addedImages">
                                <i class="far fa-times"></i>
                            </button>
                            <figure>
                                <img :src="upload.url" width="120">
                            </figure>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AddPetImages',
        mixins: [ImageAddMixin],
        props: {
            addedPetId: ''  
        },
        data() {
            return {
                addedImages: null
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent'
            ]),
            ownerId() {
                return this.addedPetId;
            },
            ownerTypeId() {
                return 3;
            },
            completed() {
                return !!this.addedImages || this.imageFiles.length === 0;
            },
            status() {
                return {
                    error: !!this.error,
                    completed: !!this.completed,
                    validated: true,
                    loading: !!this.loadingImages
                }
            },
            canAddPetImages() {
                return !!this.addedPetId && !this.completed;
            }
        },
        watch: {
            // retry saving of pet note
            addClientEvent() {
                this.onPetIdChanged();
            },
            // initial save
            addedPetId() {
                this.onPetIdChanged();
            },
            status(value) {
                this.$emit('input', this.status);
            },
            imageFilterModel() {
                this.refreshImages();
            }
        },
        methods: {
            async onPetIdChanged() {
                if (!this.canAddPetImages) {
                    return;
                }
                this.tryAddImages();
            },
            afterImagesAdded(value) {
                this.addedImages = value;
            },
            clearUploads() {
                // overriding clear uploads to keep imageFiles during changed of ownerId from empty to a true addedPetId value
            },
            refreshImages() {
                // do nothing
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>