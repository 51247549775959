<template>

</template>

<script>
    import { ImageCachesMixin } from "../Shared/Images/ImageCachesMixin";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'PetImagesSearch',
        mixins: [ImageCachesMixin, StatusEmitterMixin],
        props: {
            petIds: Array
        },
        computed: {
            ownerIds() {
                return !!this.petIds ? this.petIds : [];
            },
            ownerTypeId() {
                return 3;
            },
            status() {
                return {
                    loading: this.fetchingImages
                }
            }
        }
    }
</script>
