<template>

</template>

<script>
    import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";
    import { UserResourceAddMixin } from "../../Shared/UserResourceAddMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { UserCacheMixin } from "../../Shared/UserCacheMixin";

    export default {
        name: 'PostRegisterResource',
        mixins: [UserResourceAddMixin, UserCacheMixin, SiteIdMixin, StatusEmitterMixin],
        computed: {
            userId() {
                return !!this.user ? this.user.id : '';
            },
            resourceComplete() {
                return this.userResources.length > 0;
            },
            fetchedUserResourceCache() {
                return !!this.userResourceCache && !this.loadingUserResources && !this.userResourceCache.retry;
            },
            canAddResource() {
                return this.fetchedUserResourceCache
                    && !this.resourceComplete
                    && !!this.siteId
                    && !!this.user;
            },
            status() {
                return {
                    error: this.error,
                    loading: !!this.loadingUserResources || !!this.loading,
                    completed: this.resourceComplete
                }
            }
        },
        watch: {
            canAddResource(newValue) {
                if (!!newValue) {
                    this.tryAddUserResources();
                }
            }
        },
        created() {
            if (this.canAddResource) {
                this.tryAddUserResources();
            }
        }
    }
</script>
