import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { WeightSettingMixin } from "./WeightSettingMixin";

export const WeightSettingUploadMixin = {
    mixins: [SettingUploadMixin, WeightSettingMixin],
    data() {
        return {
            unit: ''
        }
    },
    computed: {
        settingEditModel() {
            return {
                unit: this.unit
            }
        },
        settingEditModelValid() {
            return !!this.settingEditModel.unit;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.unit = this.settingObject.unit;
        }
    }
}
