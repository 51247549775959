import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionSettings', [
            'fetchedSubscriptionSettings',
            'fetchingSubscriptionSettings',
            'subscriptionSettings'
        ]),
        showUpgrades() {
            return !!this.subscriptionSettings && this.subscriptionSettings.showStoreUpgrades;
        }
    },
    methods: {
        ...mapActions('SubscriptionSettings', [
            'fetchSubscriptionSettings'
        ]),
        refreshSubscriptionSettings() {
            if (!this.fetchedSubscriptionSettings && !this.fetchingSubscriptionSettings) {
                this.tryFetchSubscriptionSettings();
            }
        },
        tryFetchSubscriptionSettings() {
            const self = this;

            self.error = '';
            self.fetchSubscriptionSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionSettings();
    }
}
