<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Occurrences')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{occurrences.toString()}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="num in count"
                            class="dropdown-item pointer"
                            :key="num"
                            @click="setOccurrences(num)"
                            v-bind:class="{'active': num === occurrences}">
                            {{num.toString()}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'AppointmentOptionsOccurrences',
        data() {
            return {
                count: 15
            }
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'occurrences'
            ])
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setOccurrences'
            ])
        }
    }
</script>