<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <history-item v-for="appointment in appointments" :key="appointment.id" :appointment="appointment"
                      :pets="pets" :statusClass="getStatusClass(appointment)"></history-item>
        <a v-if="more" @click="searchAndCacheAppointments" class="link pointer">{{$t('Load more')}}</a>
    </div>
</template>

<script>
    import { AppointmentSearchMixin } from "../../Shared/AppointmentSearchMixin";
    import { PetCacheMixinV2 } from "../../Shared/PetCacheMixinV2";
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import AppointmentClientInfoHistoryItem from "./AppointmentClientInfoHistoryItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AppointmentClientInfoHistory',
        mixins: [AppointmentSearchMixin, PetCacheMixinV2, ResourceCacheMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id
            },
            searchModel() {
                return { clientId: this.id };
            },
            searchModelValid() {
                return !!this.searchModel.clientId;
            }
        },
        components: {
            'history-item': AppointmentClientInfoHistoryItem,
            'error-banner': ErrorBanner
        }
    }
</script>
