import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const TimeFormatSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('TimeFormatSettings', [
            'findTimeFormatSettingCache'
        ]),
        timeFormatSettingCache() {
            return this.findTimeFormatSettingCache(this.siteId);
        },
        timeFormatSetting() {
            return !!this.timeFormatSettingCache ? this.timeFormatSettingCache.value : null;
        },
        loadingTimeFormatSetting() {
            return !!this.timeFormatSettingCache && this.timeFormatSettingCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshTimeFormatSetting();
        }
    },
    methods: {
        ...mapActions('TimeFormatSettings', [
            'getTimeFormatSetting'
        ]),
        refreshTimeFormatSetting() {
            if (!this.timeFormatSettingCache || !!this.timeFormatSettingCache.retry) {
                this.tryGetTimeFormatSetting();
            }
        },
        tryGetTimeFormatSetting() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getTimeFormatSetting(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTimeFormatSetting();
    }
}
