<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Provider')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedName}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="oa in paymentOAuths"
                            class="dropdown-item pointer"
                            :key="oa.providerType.id"
                            @click="setProviderId(oa.providerType.id)"
                            v-bind:class="{'active': oa.providerType.id === providerId}">
                            {{oa.providerType.description}}
                        </li>
                    </ul>
                </div>
            </div>
            <validation-message v-if="validation" :message="validation"></validation-message>
        </div>
    </div>
</template>

<script>
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";
    import { PaymentOAuthsCacheMixin } from "./PaymentOAuthsCacheMixin";

    export default {
        name: 'CheckoutProviderSelect',
        mixins: [PaymentOAuthsCacheMixin],
        props: {
            value: Number,
            validation: String
        },
        data() {
            return {
                providerId: 0
            }
        },
        computed: {
            selectedOption() {
                return this.paymentOAuths.find(p => p.providerType.id === this.providerId);
            },
            selectedName() {
                return !!this.selectedOption ? this.selectedOption.providerType.description : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            paymentOAuths() {
                this.tryAutoSelect();
            }
        },
        methods: {
            tryAutoSelect() {
                if (this.paymentOAuths.length === 1) {
                    this.$emit('input', this.paymentOAuths[0].providerType.id);
                }
            },
            setProviderId(value) {
                this.providerId = value;
                this.$emit('input', value);
            }
        },
        components: {
            'validation-message': ValidationMessage
        },
        created() {
            this.tryAutoSelect();
            this.setProviderId(this.value);
        }
    }
</script>
