import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const ContractTemplateTagCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ContractTemplateTags', [
            'fetchingTemplateTags',
            'fetchedTemplateTags',
            'contractTemplateTags'
        ]),
        tagList() {
            return !!this.contractTemplateTags ? this.contractTemplateTags.tags : [];
        }
    },
    methods: {
        ...mapActions('ContractTemplateTags', [
            'getContractTemplateTags'
        ]),
        refreshContractTemplateTags() {
            if (!this.fetchedTemplateTags && !this.fetchingTemplateTags) {
                this.getAndCacheContractTemplateTags();
            }
        },
        getAndCacheContractTemplateTags() {
            const self = this;
            self.error = '';
            self.getContractTemplateTags()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContractTemplateTags();
    }
}