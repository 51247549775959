import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const ClientCountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ClientCount', [
            'loadingClientCount',
            'loadedClientCount',
            'clientCount'
        ])
    },
    methods: {
        ...mapActions('ClientCount', [
            'fetchClientCount'
        ]),
        refreshClientCount() {
            if (!this.loadingClientCount && !this.loadedClientCount) {
                this.tryFetchClientCount();
            }
        },
        tryFetchClientCount() {
            const self = this;

            self.error = '';
            self.fetchClientCount()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientCount();
    }
}
