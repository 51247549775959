<template>
    <table-mobile @next="next">
        <breed-mobile v-for="breed in breeds" :key="breed.id" :breed="breed" @breedClicked="onBreedClicked"></breed-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import BreedItemMobile from "./BreedItemMobile.vue";

    export default {
        name: 'BreedsMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('BreedSearches', [
                'findBreedSearches'
            ]),
            breedSearches() {
                return this.findBreedSearches(this.searchKey);
            },
            breeds() {
                return [].concat(...this.breedSearches.map(s => s.values));
            },
            loading() {
                return this.breedSearches.some(s => !!s.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.breeds.length);
                }
            },
            onBreedClicked(breed) {
                this.$emit('breedClicked', breed);
            }
        },
        components: {
            'table-mobile': TableMobile,
            'breed-mobile': BreedItemMobile
        }
    }
</script>
