<template>
    <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel">
        <div class="form-group">
            <label class="custom-control custom-radio custom-radio-inline">
                <input v-model="selectedId" value="1" id="brand-new-client-pet-radio" class="custom-control-input" type="radio">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t('Brand new client & pets')}}</span>
            </label>
            <label class="custom-control custom-radio custom-radio-inline">
                <input v-model="selectedId" value="2" id="add-pet-existing-client-radio" class="custom-control-input" type="radio">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t('Add pet to existing client')}}</span>
            </label>
        </div>
        <new-client-pets v-if="selectedId==='1'"></new-client-pets>
        <new-pet v-if="selectedId==='2'"></new-pet>
    </div>
</template>

<script>
    import NewClientPets from './NewClientPets.vue';
    import NewPet from './NewPet.vue';

    export default {
        name: 'AddClientPet',
        data() {
            return {
                selectedId: '1'
            }
        },
        components: {
            'new-client-pets': NewClientPets,
            'new-pet': NewPet
        }
    }
</script>