<template>
    <div v-bind:style="{ borderColor: resourceColour }" class="btn btn-outline-resource mb-2 bg-white resource-button">
        <span class="resource-text text-truncate">{{resourceName}}</span>
        <i class="fal fa-sort resource-icon"></i>
    </div>
</template>

<script>
    export default {
        name: 'ResourcePriorityOrderDraggableItem',
        props: {
            resource: Object
        },
        computed: {
            resourceId() {
                return !!this.resource ? this.resource.id : '';
            },
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            resourceColour() {
                return !!this.resource ? this.resource.colour : '#ffffff';
            }
        }
    }
</script>

<style scoped>
    .resource-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0.5rem 1rem;
        margin-inline: auto;
    }

    .resource-text {
        flex-grow: 1;
        text-align: center;
    }

    .resource-icon {
        position: absolute;
        right: 1rem;
    }
</style>
