import { mapActions, mapState, mapGetters } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const PetClientsSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Clients', [
            'searchingClient'
        ]),
        ...mapGetters('Clients', [
            'findClientCache'
        ]),
        clientIds() {
            console.log('!!!OVERRIDE COMPUTED - clientIds!!!');
            return [];
        },
        missingPetClientIds() {
            return this.clientIds.filter(id => !this.findClientCache(id));
        },
        clientSearchModel() {
            return {
                clientIds: this.missingPetClientIds
            }
        },
        clientSearchModelValid() {
            return this.clientSearchModel.clientIds.filter(id => !!id).length > 0;
        }
    },
    watch: {
        clientSearchModel() {
            this.trySearchPetClients();
        }
    },
    methods: {
        ...mapActions('Clients', [
            'searchClients'
        ]),
        trySearchPetClients() {
            const self = this;
            if (!self.clientSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchClients(self.clientSearchModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
    },
    created() {
        this.trySearchPetClients();
    }
}
