import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const ClientOnboardingInviteCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ClientOnboardingInvites', [
            'findClientOnboardingInviteCache'
        ]),
        clientId() {
            console.log('OVERRIDE COMPUTED - ClientId');
            return '';
        },
        clientOnboardingInviteCache() {
            return this.findClientOnboardingInviteCache(this.clientId);
        },
        clientOnboardingInvite() {
            return this.clientOnboardingInviteCache ? this.clientOnboardingInviteCache.value : null;
        },
        loadingClientOnboardingInvite() {
            return this.clientOnboardingInviteCache && this.clientOnboardingInviteCache.loading;
        }
    },
    watch: {
        clientId() {
            this.refreshClientOnboardingInvite();
        }
    },
    methods: {
        ...mapActions('ClientOnboardingInvites', [
            'fetchClientOnboardingInvite'
        ]),
        refreshClientOnboardingInvite() {
            if (!this.clientOnboardingInviteCache || !!this.clientOnboardingInviteCache.retry) {
                this.tryFetchClientOnboardingInvite();
            }
        },
        tryFetchClientOnboardingInvite() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.fetchClientOnboardingInvite(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientOnboardingInvite();
    }
}
