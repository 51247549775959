import { CountryCacheMixin } from "../../components/Shared/Country/CountryCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { CreditPacksCacheMixin } from "./CreditPacksCacheMixin";
import { mapActions } from 'vuex';

export const CampaignCreditPackAddMixin = {
    mixins: [CreditPacksCacheMixin, CountryCacheMixin],
    data() {
        return {
            packId: 0
        }
    },
    computed: {
        creditTypeId() {
            return 2;
        },
        campaignCreditPackAddModel() {
            return {
                packId: this.packId,
                currency: this.currency === 'GBP' ? 'GBP' : 'USD',
            }
        },
        campaignCreditPackAddModelValid() {
            const model = this.campaignCreditPackAddModel;
            return !!this.siteId && !!model.packId;
        }
    },
    methods: {
        ...mapActions('CreditPacks', [
            'addCampaignCreditPack'
        ]),
        tryAddCampaignCreditPack() {
            const self = this;
            if (!self.campaignCreditPackAddModelValid) {
                return;
            }
            self.error = '';
            self.addCampaignCreditPack({ key: self.creditPacksCacheKey, payload: self.campaignCreditPackAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
