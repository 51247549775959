<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { DateFormatSettingAddMixin } from "../../Shared/Time/DateFormatSettingAddMixin";

    export default {
        name: 'PostRegisterDateFormat',
        mixins: [DateFormatSettingAddMixin, StatusEmitterMixin],
        props: {
            id: Number
        },
        computed: {
            fetchedDateFormatSetting() {
                return !!this.dateFormatSettingCache && !this.dateFormatSettingCache.retry && !this.loadingDateFormatSetting;
            },
            dateFormatSettingComplete() {
                return !!this.dateFormatSetting;
            },
            canAddDateFormatSetting() {
                return !!this.fetchedDateFormatSetting
                    && !this.dateFormatSettingComplete
                    && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingDateFormatSetting,
                    completed: this.dateFormatSettingComplete
                }
            }
        },
        watch: {
            canAddDateFormatSetting(value) {
                if (!!value) {
                    this.tryAddDateFormatSetting();
                }
            }
        },
        created() {
            this.dateFormatId = this.id;
            if (this.canAddDateFormatSetting) {
                this.tryAddDateFormatSetting();
            }
        }
    }
</script>
