<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Microchip')}}</label>
        </div>
        <div class="col-md-8">
            <input v-model="microchip" class="form-control" maxlength="9" type="text">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PetDetailsMicroship',
        props: {
            value: String
        },
        computed: {
            microchip: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
