import { SettingCacheMixin } from "../../Shared/SettingCacheMixin";
import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";

export const WeightSettingCacheMixin = {
    mixins: [SettingCacheMixin, SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Weight';
        },
        defaultSettingObject() {
            return {
                unit: 'kgs'
            }
        },
        weightUnit() {
            return this.settingObject.unit;
        }
    }
}
