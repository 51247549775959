import { mapState } from 'vuex';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
import { CampaignSettingAddMixin } from "./CampaignSettingAddMixin";

export const CampaignSettingUploadMixin = {
    mixins: [CampaignSettingAddMixin, SiteIdMixin],
    computed: {
        ...mapState('CampaignSettings', [
            'updatingCampaignSettings'
        ]),
        loadingSetting() {
            return this.updatingCampaignSettings || this.addingCampaignSettings || this.loadingCampaignSettings;
        }
    },
    methods: {
        tryUploadCampaignSetting() {
            if (!!this.campaignSetting) {
                return;
            } else {
                this.tryAddCampaignSetting();
            }
        }
    }
}
