<template>
    <section class="client-contact-section mb-3">
        <span v-if="loadingHistory" class="inline-loader"></span>
        <div v-if="history" class="row">
            <div class="form-group col-6 col-md-12">
                <label>{{$t('Rating')}}</label>
                <div v-if="history && history.total" class="star-rating mb-3">
                    <i v-for="star in history.rating" :title="history.description" class="far fa-star"></i>
                </div>
                <template v-else>
                    N/A
                </template>
            </div>
            <div class="form-group col-6 col-md-12">
                <label for="title">{{$t('Next appointment')}}</label> {{nextAppointment}}
            </div>
            <div class="form-group col-6 col-md-12">
                <label for="title">{{$t('Prev appointment')}}</label> {{previousAppointment}}
            </div>
            <div class="form-group col-6 col-md-12">
                <label for="title">{{$t('Last 12 months')}}</label> {{last12months}}
            </div>
            <div class="col-12">
                <div class="client-detail-btns">
                    <a v-if="phoneHref" class="btn btn-sm btn-outline" :href="phoneHref" target="_blank">
                        <i class="fal fa-phone-alt mr-1"></i> {{$t('Call')}}
                    </a>
                    <a v-if="smsHref" @click="openSmsSend" class="btn btn-sm btn-outline">
                        <i class="fal fa-comment-alt-dots mr-1"></i> {{$t('Text')}}
                    </a>
                    <a v-if="emailHref" @click="openEmailSend" class="btn btn-sm btn-outline">
                        <i class="fal fa-envelope mr-1"></i> {{$t('Email')}}
                    </a>
                </div>
            </div>
        </div>
        <modal-dialog ref="clientEmail" v-if="client" :title="clientFullname" :sub-title="$t('Email')">
            <client-email :client="client"></client-email>
        </modal-dialog>
        <modal-dialog ref="clientSms" v-if="client" :title="clientFullname" :sub-title="$t('SMS')">
            <client-sms :client="client"></client-sms>
        </modal-dialog>
    </section>
</template>

<script>
    import { HistoryCacheMixin } from '../Shared/HistoryCacheMixin.js';
    import { ClientCommButtonMixin } from '../Shared/ClientCommButtonMixin.js';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ClientEmailSend from './Communications/ClientEmailSend.vue';
    import ClientSmsSend from './Communications/ClientSmsSend.vue';

    export default {
        name: 'ClientHistorySection',
        mixins: [HistoryCacheMixin, ClientCommButtonMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id;
            }
        },
        methods: {
            openEmailSend() {
                const ref = this.$refs.clientEmail;
                if (!!ref) {
                    $(ref.$el).modal('show');
                }
            },
            openSmsSend() {
                const ref = this.$refs.clientSms;
                if (!!ref) {
                    $(ref.$el).modal('show');
                }
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'client-email': ClientEmailSend,
            'client-sms': ClientSmsSend
        }
    }
</script>
