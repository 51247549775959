import { ImageCacheMixin } from "../../../components/Shared/Images/ImageCacheMixin";
import { SettingCacheMixin } from "../../../components/Shared/SettingCacheMixin";
import { DefaultPetImageMixin } from "./DefaultPetImageMixin";

export const DefaultPetImageCacheMixin = {
    mixins: [ImageCacheMixin, SettingCacheMixin, DefaultPetImageMixin],
    computed: {
        image() {
            return this.images.find(i => i.id === this.settingObject.imageId);
        }
    }
}
