<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">{{$t(communicationTypeName)}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div v-if="canDisplay" class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <template v-if="displayStripoTemplator">
                <template-update v-if="emailMarketingTemplate" :id="id" @loading="onStripoLoading" @deleted="onEmailMarketingTemplateDelete"></template-update>
                <template-add v-else :id="id" @loading="onStripoLoading"></template-add>
            </template>
        </div>
        <div class="fixed-action-btns action-btns d-flex flex-column align-items-start pt-0">
            <template-tags :communication-type-id="communicationTypeId"></template-tags>
            <div class="d-flex justify-content-between align-items-center col-12 mt-2 px-0">
                <div class="w-100 d-flex">
                    <button @click="save" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        {{$t('Save')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                    <button @click="openPreview" class="btn btn-secondary" type="button">
                        <i class="far fa-eye"></i>
                    </button>
                    <button v-if="emailMarketingTemplate" 
                            class="btn btn-delete ml-auto" 
                            v-bind:disabled="loading" 
                            type="button" 
                            data-target="#reset-template-modal"
                            data-toggle="modal">
                        <i class="fal fa-refresh"></i>
                        {{$t('Reset')}}
                    </button>
                </div>
                <toggle-tags></toggle-tags>
            </div>
        </div>
        <email-preview :communication-type-id="communicationTypeId" ref="emailPreview"></email-preview>
        <modal-dialog id="reset-template-modal" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning alert-icon">
                <div class="alert-content">
                    <p>{{$t('Are you sure you want to reset')}} {{communicationTypeName}}?</p>
                </div>
                <button v-bind:disabled="loading" @click="reset" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="loading" class="inline-loader"></span>
                    <template v-else>
                        {{$t('Yes')}}
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { EmailMarketingTemplateCacheMixin } from "../../Shared/EmailMarketingTemplateCacheMixin";
    import { StripoCacheMixin } from '../../Shared/Stripo/StripoCacheMixin';
    import { CommunicationTemplateUploadMixin } from "./CommunicationTemplateUploadMixin";
    import SiteSelect from '../../Account/SiteSelect.vue';
    import EmailTemplateTags from './EmailTemplateTags.vue';
    import EmailPreview from "./EmailPreview.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import EmailMarketingTemplateAdd from "./EmailMarketingTemplateAdd.vue";
    import EmailMarketingTemplateUpdate from "./EmailMarketingTemplateUpdate.vue";
    import TagsDisplayToggleButton from '../../Shared/Tags/TagsDisplayToggleButton.vue';
    import ModalDialog from '../../Layout/ModalDialog.vue';

    export default {
        name: 'EmailMarketingTemplateUpload',
        mixins: [EmailMarketingTemplateCacheMixin, CommunicationTemplateUploadMixin, StripoCacheMixin],
        data() {
            return {
                stripoLoading: false
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveEmailMarketingTemplate',
                'triggerDeleteEmailMarketingTemplate'
            ]),
            save() {
                this.error = '';
                this.triggerSaveEmailMarketingTemplate();
            },
            reset() {
                this.error = '';
                this.triggerDeleteEmailMarketingTemplate();
            },
            openPreview() {
                const element = this.$refs.emailPreview.$el;
                $(element).modal('show');
            },
            onStripoLoading(value) {
                this.stripoLoading = value;
            },
            onEmailMarketingTemplateDelete() {
                this.$refs.cancelButton.click();
            }
        },
        computed: {
            displayStripoTemplator() {
                return this.fetchedEmailMarketingTemplate;
            },
            stripoTemplateId() {
                return !!this.emailMarketingTemplate ? this.emailMarketingTemplate.stripoTemplateId : '';
            },
            loading() {
                return !!this.loadingEmailMarketingTemplate || !!this.loadingStripoTemplate || !!this.stripoLoading;
            }
        },
        components: {
            'site-select': SiteSelect,
            'template-tags': EmailTemplateTags,
            'email-preview': EmailPreview,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'template-add': EmailMarketingTemplateAdd,
            'template-update': EmailMarketingTemplateUpdate,
            'toggle-tags': TagsDisplayToggleButton,
            'modal-dialog': ModalDialog
        }
    }
</script>