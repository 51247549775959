import { mapActions, mapState, mapGetters } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const ClientPetsSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PetsV2', [
            'searchingPets'
        ]),
        ...mapGetters('PetsV2', [
            'findPetCache',
            'findPets'
        ]),
        clientIds() {
            console.log('!!!OVERRIDE COMPUTED - clientIds!!!');
            return [];
        },
        missingClientIds() {
            return this.clientIds.filter(id => !this.findPetCache(id));
        },
        clientPetsSearchModel() {
            return {
                clientIds: this.missingClientIds
            }
        },
        clientPetsSearchModelValid() {
            return this.clientPetsSearchModel.clientIds.length > 0;
        }
    },
    watch: {
        clientPetsSearchModel() {
            this.trySearchClientPets();
        }
    },
    methods: {
        ...mapActions('PetsV2', [
            'searchPets'
        ]),
        trySearchClientPets() {
            const self = this;
            if (!self.clientPetsSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchPets({ keys: self.missingClientIds, payload: self.clientPetsSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.trySearchClientPets();
    }
}
