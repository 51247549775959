<template>
    <div class="w-100">
        <upgrade-modify v-if="subscriptionUpgrade"></upgrade-modify>
        <upgrade-select v-else></upgrade-select>
        <page-loader v-if="loading"></page-loader>
    </div>
</template>

<script>
    import { SubscriptionUpgradeCacheMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeCacheMixin";
    import PageLoader from "../Shared/Loading/PageLoader.vue";
    import SubscriptionUpgradeModifier from "./SubscriptionUpgradeModifier.vue";
    import SubscriptionUpgradeSelect from "./SubscriptionUpgradeSelect.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SubscriptionUpgrade',
        mixins: [SubscriptionUpgradeCacheMixin],
        computed: {
            ...mapState('SubscriptionUpgrade', [
                'addingSubscriptionUpgrade'
            ]),
            loading() {
                return this.fetchingSubscriptionUpgrade || this.addingSubscriptionUpgrade;
            }
        },
        components: {
            'page-loader': PageLoader,
            'upgrade-select': SubscriptionUpgradeSelect,
            'upgrade-modify': SubscriptionUpgradeModifier
        }
    }
</script>
