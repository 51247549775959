export const LanguageSettingOptionsMixin = {
    data() {
        return {
            options: [
                { value: 'de', name: 'Deutsch' },
                { value: 'en', name: 'English' },
                { value: 'es', name: 'Espa&ntilde;ol' },
                { value: 'fr', name: 'Fran&ccedil;ais' },
                { value: 'sv', name: 'Svenska' }
            ]
        }
    },
    computed: {
        selectedOption() {
            console.log('!!!OVERRIDE COMPUTED - selectedOption!!!');
        },
        selectedOptionName() {
            return !!this.selectedOption ? this.selectedOption.name : ''
        },
    },
    methods: {
        setLanguage() {
            // for overriding
        },
        getFlagImage(language) {
            return require(`../../assets/flags/${language}.svg`);
        }
    }
}
