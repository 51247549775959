<template>
    <div class="form-group row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
            <div class="mb-2 d-flex align-items-center mw-400">
                <label class="ps-toggle-switch">
                    <label class="d-inline-block">
                        {{$t('Custom Durations')}}
                        <tooltip>
                            <span>{{$t('Custom durations allow you to set specific appointment lengths beyond the standard options.')}}</span>
                        </tooltip>
                    </label>
                </label>
                <i ref="custom-durations-ref" aria-expanded="false" 
                   data-target="#custom-durations" data-toggle="collapse" 
                   class="far fa-chevron-circle-down appointment-notifications-icon">
                </i>
            </div>
            <div class="collapse mw-400" id="custom-durations">
                <div v-if="selectedCustomDuration" role="alert" class="alert alert-warning alert-icon mb-0">
                    <div class="alert-content">
                        <p>{{$t('Are you sure you want to delete this custom duration?')}}</p>
                    </div>
                    <button @click="tryDeleteCustomDuration" v-bind:disabled="loadingCustomDurations" type="button" class="btn btn-danger" style="margin-right: 10px;">
                        <span v-if="loadingCustomDurations" class="inline-loader"></span>
                        <template v-else>
                            {{$t('Yes')}}
                        </template>
                    </button>
                    <button @click="selectedCustomDuration=null" type="button" aria-label="Close" class="close">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="table-responsive">
                    <table class="table table-striped ps-table table-hover mb-0">
                        <thead>
                            <tr>
                                <th>{{$t('Date')}}</th>
                                <th>{{$t('Value')}} ({{$t('mins')}})</th>
                                <th v-if="canDelete" class="text-center">{{$t('Delete?')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cd in customDurations" :ref="cd.id">
                                <td>{{cd.createdUtc | moment(momentJSFormat)}}</td>
                                <td>{{cd.value}}</td>
                                <td v-if="canDelete" @click="selectedCustomDuration=cd" class="text-center pointer">
                                    <i class="fal fa-trash-alt text-danger"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>{{date | moment(momentJSFormat)}}</td>
                                <td>
                                    <input v-model="amount" class="form-control" type="number">
                                    <validation-message v-if="!!valueMessage" :message="valueMessage"></validation-message>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin.js';
    import { CustomDurationUpdateMixin } from '../../../mixins/Settings/Site/CustomDurationUpdateMixin.js';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin.js';
    import { UserCacheMixin } from '../../Shared/UserCacheMixin.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import Tooltip from "../../Shared/Layout/Tooltip.vue";

    export default {
        name: 'CustomDuration',
        mixins: [CustomDurationUpdateMixin, DateDisplayMixin, StatusEmitterMixin, UserCacheMixin],
        data() {
            return {
                selectedCustomDuration: null,
                date: new Date()
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            customDurationId() {
                return !!this.selectedCustomDuration ? this.selectedCustomDuration.id : '';
            },
            status() {
                return {
                    loading: this.loadingCustomDurations,
                    error: this.error
                }
            }
        },
        watch: {
            siteId() {
                this.selectedCustomDuration = null;
            },
            saveSiteEvent() {
                this.tryAddCustomDuration();
            }
        },
        methods: {
            afterCustomDurationDelete() {
                this.selectedCustomDuration = null;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'tooltip': Tooltip
        }
    }
</script>
