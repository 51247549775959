<template>
    <tr>
        <td>
            <div align="center" aria-expanded="false" class="filter-header resource-name-wrap-text" :data-target="'#' + id" data-toggle="collapse">
                {{resourceName}}
                <i class="far fa-chevron-down ml-3"></i>
            </div>
            <div :id="id" class="collapse">
                <div @click="onRowClicked" align="center" class="filter-group">
                    <label>{{$t('EDIT')}}</label>
                </div>
                <day-cell v-for="dayId in dayIds" :key="dayId" :day-id="dayId" :working-hours="resourceWorkingHours" :oneOffHours="resourceOneOffHours" @cell-clicked="onCellClicked"></day-cell>
            </div>
        </td>
    </tr>
</template>

<script>
    import ResourceHoursDayMobile from "./ResourceHoursDayMobile.vue";
    import { ResourceHoursMixin } from "./ResourceHoursMixin";

    export default {
        name: 'ResourceHoursMobile',
        mixins: [ResourceHoursMixin],
        computed: {
            id() {
                return 'collapse-resource-' + this.resourceId;
            }
        },
        components: {
            'day-cell': ResourceHoursDayMobile
        }
    }
</script>

<style scoped>
    .resource-name-wrap-text {
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }
</style>
