<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Address')}}</label>
        </div>
        <div class="col-md-8">
            <address-input v-model="addressLines"></address-input>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import AddressInputV2 from "../../Shared/Address/AddressInputV2.vue";
    import { AddressUploadMixin } from "../../Shared/Address/AddressUploadMixin";
    import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLocationAddress',
        mixins: [AddressUploadMixin, SiteIdMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ownerQueryModel() {
                return {
                    ownerId: this.siteId,
                    ownerTypeId: 12
                }
            },
            status() {
                return {
                    loading: this.loadingAddress,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.uploadAddress();
            }
        },
        components: {
            'address-input': AddressInputV2,
            'error-banner': ErrorBanner
        }
    }
</script>
