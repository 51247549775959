<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <div class="form-group row">
                <div class="col-md-4 col-8">
                    <label>{{$t('Suggest Alternative Date & Time?')}}</label>
                </div>
                <div class="col-md-8 col-4">
                    <label class="ps-toggle-switch">
                        <input v-model="showDateTimePicker" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                    </label>
                </div>
            </div>
            <div v-if="showDateTimePicker" class="form-group row">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <date-time-picker v-model="startUtc"></date-time-picker>
                </div>
            </div>
        </section>
        <decline-communication-add :booking-id="bookingId"
                                   :declined="declined"
                                   :submit-event="submitEvent"
                                   :suggest-date-time="showDateTimePicker"
                                   v-model="declineCommunicationStatus">
        </decline-communication-add>
        <suggestion-communication-add :added-suggestion="addedSuggestion"
                                      :declined="declined"
                                      :submit-event="submitEvent"
                                      :suggest-date-time="showDateTimePicker"
                                      v-model="suggestionCommunicationStatus">
        </suggestion-communication-add>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { BookingSuggestionAddMixin } from '../Shared/BookingSuggestionAddMixin';
    import BookingDeclineCommunicationAdd from './BookingDeclineCommunicationAdd.vue';
    import BookingSuggestionCommunicationAdd from './BookingSuggestionCommunicationAdd.vue';
    import DateTimePicker from '../Shared/Time/DateTimePicker.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BookingSuggestionAdd',
        mixins: [BookingSuggestionAddMixin, StatusEmitterMixin],
        props: {
            declined: Boolean,
            submitEvent: Number
        },
        data() {
            return {
                showDateTimePicker: false,
                addedSuggestion: null,
                suggestionCommunicationStatus: {},
                declineCommunicationStatus: {}
            }
        },
        computed: {
            clientId() {
                return 'Online Booking';
            },
            subjectId() {
                return this.bookingId;
            },
            methodTypeId() {
                return 1;
            },
            statuses() {
                return [
                    this.suggestionCommunicationStatus,
                    this.declineCommunicationStatus
                ];
            },
            loading() {
                return !!this.addingBookingSuggestion || this.statuses.some(s => !!s.loading);
            },
            completed() {
                return (!!this.addedSuggestion || !this.showDateTimePicker) && this.statuses.every(s => !!s.completed);
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canAddBookingSuggestion() {
                return !this.completed && !!this.declined && !!this.showDateTimePicker;
            }
        },
        watch: {
            submitEvent() {
                this.onValidatedChanged();
            },
            declined() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddBookingSuggestion) {
                    return;
                }
                this.tryAddBookingSuggestion();
            },
            afterAddBookingSuggestion(bookingSuggestion) {
                this.addedSuggestion = bookingSuggestion;
            }
        },
        components: {
            'decline-communication-add': BookingDeclineCommunicationAdd,
            'suggestion-communication-add': BookingSuggestionCommunicationAdd,
            'date-time-picker': DateTimePicker,
            'error-banner': ErrorBanner
        }
    }
</script>
