import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';

export const CampaignSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('CampaignSettings', [
            'loadingCampaignSettings',
            'loadedCampaignSettings',
            'campaignSettings'
        ]),
        campaignSetting() {
            return this.campaignSettings.find(s => s.siteId == this.siteId);
        }
    },
    watch: {
        siteId() {
            this.refreshCampaignSettings();
        }
    },
    methods: {
        ...mapActions('CampaignSettings', [
            'getCampaignSettings'
        ]),
        refreshCampaignSettings() {
            if (!this.loadedCampaignSettings && !this.loadingCampaignSettings) {
                this.tryGetCampaignSettings();
            }
        },
        tryGetCampaignSettings() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getCampaignSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCampaignSettings();
    }
}
