import { Api } from '../../services/Api';

const state = () => ({
    contactCaches: []
})

const getters = {
    findContactCache: (state) => (key) => {
        return state.contactCaches.find(cc => cc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.contactCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.contactCaches.push({ key: key, contacts: [], loading: !!loading });
        }
    },
    setContacts(state, { key, contacts }) {
        var existing = state.contactCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.contacts = contacts;
            existing.loading = false;
        } else {
            state.contactCaches.push({ key: key, contacts: contacts, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.contactCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendContact(state, { key, contact }) {
        var existing = state.contactCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.contacts.push(contact);
        } else {
            state.contactCaches.push({ key: key, contacts: [contact], loading: false });
        }
    },
    insertContact(state, { key, contact }) {
        var existing = state.contactCaches.find(cc => cc.key === key);
        if (!!existing) {
            const old = existing.contacts.find(c => c.id === contact.id);
            if (!!old) {
                const index = existing.contacts.indexOf(old);
                existing.contacts.splice(index, 1, contact);
            } else {
                existing.contacts.push(contact);
            }
        } else {
            state.contactCaches.push({ key: key, contacts: [contact], loading: false });
        }
    }
}

const actions = {
    searchContacts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Contact?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setContacts', { key: key, contacts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addContact({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Contact', payload)
                .then(response => {
                    commit('appendContact', { key: key, contact: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateContact({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Contact', payload)
                .then(response => {
                    commit('insertContact', { key: key, contact: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
