import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const OpeningHoursCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('OpeningHours', [
            'findOpeningHourCache'
        ]),
        openingHoursCacheKey() {
            return this.siteId;
        },
        openingHourCache() {
            return this.findOpeningHourCache(this.openingHoursCacheKey);
        },
        openingHoursQuery() {
            return {
                siteId: this.siteId
            }
        },
        openingHours() {
            return !!this.openingHourCache ? this.openingHourCache.values : [];
        },
        loadingOpeningHours() {
            return !!this.openingHourCache && this.openingHourCache.loading;
        }
    },
    watch: {
        openingHoursQuery() {
            this.refreshOpeningHours();
        }
    },
    methods: {
        ...mapActions('OpeningHours', [
            'getOpeningHours'
        ]),
        refreshOpeningHours() {
            if (!this.openingHourCache || !!this.openingHourCache.retry) {
                this.getAndCacheOpeningHours();
            }
        },
        getAndCacheOpeningHours() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getOpeningHours({ key: self.openingHoursCacheKey, payload: self.openingHoursQuery })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOpeningHours();
    }
}
