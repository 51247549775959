import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const EmailMarketingStripoDefaultCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('EmailMarketingStripoDefaults', [
            'findEmailMarketingStripoDefaultCache'
        ]),
        communicationTypeId() {
            console.log("OVERRIDE COMPUTED --- Communication Type ID!");
        },
        emailMarketingStripoDefaultTemplateCache() {
            return this.findEmailMarketingStripoDefaultCache(this.communicationTypeId);
        },
        emailMarketingStripoDefaultTemplate() {
            return !!this.emailMarketingStripoDefaultTemplateCache ? this.emailMarketingStripoDefaultTemplateCache.value : null;
        },
        loadingEmailMarketingStripoDefaultTemplate() {
            return !!this.emailMarketingStripoDefaultTemplateCache && this.emailMarketingStripoDefaultTemplateCache.loading;
        }
    },
    watch: {
        communicationTypeId() {
            this.refreshEmailMarketingStripoDefaultTemplate();
        }
    },
    methods: {
        ...mapActions('EmailMarketingStripoDefaults', [
            'findEmailMarketingStripoDefault'
        ]),
        refreshEmailMarketingStripoDefaultTemplate() {
            if (!this.emailMarketingStripoDefaultTemplateCache || !!this.emailMarketingStripoDefaultTemplateCache.retry) {
                this.tryfindEmailMarketingStripoDefault();
            }
        },
        tryfindEmailMarketingStripoDefault() {
            const self = this;
            if (!self.communicationTypeId) {
                return;
            }
            self.error = '';
            self.findEmailMarketingStripoDefault(self.communicationTypeId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailMarketingStripoDefaultTemplate();
    }
}
