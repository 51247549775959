import { PetCacheMixinV2 } from "../../components/Shared/PetCacheMixinV2";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PetHelper } from "../../helpers/PetHelper";
import { mapActions } from 'vuex';

export const PetUpdateMixinV3 = {
    mixins: [PetCacheMixinV2],
    data() {
        return {
            name: '',
            dateOfBirth: '',
            microChip: '',
            breedId: '',
            genderId: 1,
            neuteredId: 0,
            behaviourId: 0,
            deceased: false,
            petValidator: PetHelper.getPetValidatorV2()
        }
    },
    computed: {
        pet() {
            console.log('OVERRIDE COMPUTED - Pet');
            return null;
        },
        petUpdateModel() {
            return !!this.pet ? Object.assign({}, this.pet, {
                name: this.name,
                dateOfBirth: this.dateOfBirth,
                microChip: this.microChip,
                breedId: this.breedId,
                gender: Object.assign({}, this.pet.gender, { id: this.genderId }),
                neutered: Object.assign({}, this.pet.neutered, { id: this.neuteredId }),
                behaviour: Object.assign({}, this.pet.behaviour, { id: this.behaviourId }),
                deceased: this.deceased
            }) : null;
        },
        petUpdateModelValid() {
            const model = this.petUpdateModel;
            return !!model && !!model.clientId && this.petValidator.isValid;
        },
        petUpdateModelModified() {
            return JSON.stringify(this.pet) !== JSON.stringify(this.petUpdateModel);
        }
    },
    watch: {
        pet() {
            this.refreshPetModel();
        }
    },
    methods: {
        ...mapActions('PetsV2', [
            'updatePet'
        ]),
        tryUpdatePet() {
            const self = this;
            self.petValidator.validate(self.petUpdateModel);
            if (!self.petUpdateModelValid || !self.petUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updatePet({ key: self.clientId, payload: self.petUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshPetModel() {
            this.name = !!this.pet ? this.pet.name : '';
            this.dateOfBirth = !!this.pet ? this.pet.dateOfBirth : '';
            this.microChip = !!this.pet ? this.pet.microChip : '';
            this.breedId = !!this.pet ? this.pet.breedId : '';
            this.genderId = !!this.pet ? this.pet.gender.id : 1;
            this.neuteredId = !!this.pet ? this.pet.neutered.id : 0;
            this.behaviourId = !!this.pet ? this.pet.behaviour.id : 0;
            this.deceased = !!this.pet ? this.pet.deceased : false;
        }
    },
    created() {
        this.refreshPetModel();
    }
}
