import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AddressCacheMixin } from "../AddressCacheMixin"

export const AddressAddMixin = {
    mixins: [AddressCacheMixin],
    data() {
        return {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postCode: ''
        }
    },
    computed: {
        addressLines: {
            get() {
                return {
                    line1: this.line1,
                    line2: this.line2,
                    line3: this.line3,
                    town: this.town,
                    county: this.county,
                    postCode: this.postCode
                }
            },
            set(value) {
                this.setAddressLines(value);
            }
        },
        addressAddModel() {
            return Object.assign({}, this.ownerQueryModel, this.addressLines);
        },
        addressAddModelDefault() {
            const model = this.addressAddModel;
            return !model.line1 && !model.line2 && !model.line3 && !model.town && !model.county && !model.postCode;
        }
    },
    methods: {
        ...mapActions('Addresses', [
            'addAddress'
        ]),
        setAddressLines(address) {
            this.line1 = !!address ? address.line1 : '';
            this.line2 = !!address ? address.line2 : '';
            this.line3 = !!address ? address.line3 : '';
            this.town = !!address ? address.town : '';
            this.county = !!address ? address.county : '';
            this.postCode = !!address ? address.postCode : '';
        },
        tryAddAddress() {
            const self = this;
            if (!self.ownerQueryModelValid || self.addressAddModelDefault) {
                return;
            }
            self.error = '';
            self.addAddress({ key: self.addressCacheKey, payload: self.addressAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
