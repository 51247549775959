import { mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { BookingTermCacheMixin } from "./BookingTermCacheMixin";

export const BookingTermAddMixin = {
    mixins: [BookingTermCacheMixin],
    data() {
        return {
            html: ''
        }
    },
    computed: {
        bookingTermAddModel() {
            return {
                html: this.html
            }
        },
        bookingTermAddModelValid() {
            return !!this.bookingTermAddModel.html;
        }
    },
    methods: {
        ...mapActions('BookingTerms', [
            'addBookingTerm'
        ]),
        tryAddBookingTerm() {
            const self = this;
            if (!self.siteId || !self.bookingTermAddModelValid) {
                return;
            }
            self.error = '';
            self.addBookingTerm({ key: self.siteId, payload: self.bookingTermAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBookingTermModel() {
            this.html = '';
        }
    },
    created() {
        this.refreshBookingTermModel();
    }
}
