import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteResourceEventColourSettingMixin } from "./SiteResourceEventColourSettingMixin";

export const SiteResourceEventColourSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteResourceEventColourSettingMixin],
    data() {
        return {
            displayEventColour: false,
            Holiday: '#A9D86E',         // Lighter Olive Green
            Sickness: '#FF7198',        // Pink
            Personal: '#F5FBFE',        // Light Blue
            Complimentary: '#F5E3B3',   // Pastel Gold
            PublicHoliday: '#B9E8A3',   // Pastel Green
            Training: '#E6E6FA',        // Lavender
            Other: '#F6F8F8',           // Light Gray
            Unknown: '#FFFFFF'          // White
        }
    },
    computed: {
        settingEditModel() {
            return {
                displayEventColour: this.displayEventColour,
                Holiday: this.Holiday,
                Sickness: this.Sickness,
                Personal: this.Personal,
                Complimentary: this.Complimentary,
                PublicHoliday: this.PublicHoliday,
                Training: this.Training,
                Other: this.Other,
                Unknown: this.Unknown
            }
        },
        settingEditModelValid() {
            return true;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.displayEventColour = this.settingObject.displayEventColour;
            this.Holiday = this.settingObject.Holiday;
            this.Sickness = this.settingObject.Sickness;
            this.Personal = this.settingObject.Personal;
            this.Complimentary = this.settingObject.Complimentary;
            this.PublicHoliday = this.settingObject.PublicHoliday;
            this.Training = this.settingObject.Training;
            this.Other = this.settingObject.Other;
            this.Unknown = this.settingObject.Unknown;
        },
        resetColours() {
            this.Holiday = this.defaultSettingObject.Holiday;
            this.Sickness = this.defaultSettingObject.Sickness;
            this.Personal = this.defaultSettingObject.Personal;
            this.Complimentary = this.defaultSettingObject.Complimentary;
            this.PublicHoliday = this.defaultSettingObject.PublicHoliday;
            this.Training = this.defaultSettingObject.Training;
            this.Other = this.defaultSettingObject.Other;
            this.Unknown = this.defaultSettingObject.Unknown;
        }
    }
}
