import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const StripoDefaultTemplateCacheMixin = {
    data() {
        return {
            error: '',
            selectedStripoDefaultTemplateId: 0
        }
    },
    computed: {
        ...mapGetters('StripoDefaultTemplates', [
            'findStripoDefaultTemplateCache'
        ]),
        stripoDefaultTemplateCache() {
            return this.findStripoDefaultTemplateCache(this.selectedStripoDefaultTemplateId);
        },
        stripoDefaultTemplate() {
            return !!this.stripoDefaultTemplateCache ? this.stripoDefaultTemplateCache.value : null;
        }
    },
    watch: {
        selectedStripoDefaultTemplateId() {
            this.refreshStripoDefaultTemplate();
        }
    },
    methods: {
        ...mapActions('StripoDefaultTemplates', [
            'getStripoDefaultTemplate'
        ]),
        refreshStripoDefaultTemplate() {
            if (!this.stripoDefaultTemplateCache || !!this.stripoDefaultTemplateCache.retry) {
                this.tryFetchStripoDefaultTemplate();
            }
        },
        tryFetchStripoDefaultTemplate() {
            const self = this;

            self.error = '';
            self.getStripoDefaultTemplate(self.selectedStripoDefaultTemplateId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshStripoDefaultTemplate();
    }
}
