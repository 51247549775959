import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionUpgradeTypesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionUpgradeTypes', [
            'fetchedSubscriptionUpgradeTypes',
            'fetchingSubscriptionUpgradeTypes',
            'subscriptionUpgradeTypes'
        ])
    },
    methods: {
        ...mapActions('SubscriptionUpgradeTypes', [
            'getSubscriptionUpgradeTypes'
        ]),
        refreshSubscriptionUpgradeTypes() {
            if (!this.fetchedSubscriptionUpgradeTypes && !this.fetchingSubscriptionUpgradeTypes) {
                this.tryGetSubscriptionUpgradeTypes();
            }
        },
        tryGetSubscriptionUpgradeTypes() {
            const self = this;
            self.error = '';
            self.getSubscriptionUpgradeTypes()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionUpgradeTypes();
    }
}
