import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const EmailMarketingDefaultSubjectsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('EmailMarketingDefaultSubjects', [
            'loadingSubjects',
            'loadedSubjects'
        ])
    },
    methods: {
        ...mapActions('EmailMarketingDefaultSubjects', [
            'getEmailMarketingDefaultSubjects'
        ]),
        refreshEmailMarketingDefaultSubjects() {
            if (!this.loadingSubjects && !this.loadedSubjects) {
                this.tryGetEmailMarketingDefaultSubjects();
            }
        },
        tryGetEmailMarketingDefaultSubjects() {
            const self = this;
            self.getEmailMarketingDefaultSubjects()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailMarketingDefaultSubjects();
    }
}
