import { mapActions, mapGetters, mapState } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const NotesSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Notes', [
            'searchingNotes'
        ]),
        ...mapGetters('Notes', [
            'findNoteCache'
        ]),
        ownerIds() {
            console.log('!!!OVERRIDE COMPUTED - ownerIds!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        ownersQueryModel() {
            return this.ownerIds ? this.ownerIds.map((ownerId) => ({
                ownerId: ownerId,
                ownerTypeId: this.ownerTypeId
            })) : [];
        },
        noteCacheKeys() {
            return this.ownersQueryModel ? this.ownersQueryModel.map((model) => (
                CacheHelper.ownerRequestCacheKey(model)
            )) : [];
        },
        missingNotesCacheKey() {
            return this.noteCacheKeys.filter(key => !this.findNoteCache(key));
        },
        missingOwnerIds() {
            return this.missingNotesCacheKey ? this.missingNotesCacheKey.map((key) => {
                return key.slice(0, key.length - this.ownerTypeId.toString().length);
            }) : [];
        },
        noteSearchModel() {
            return {
                ownerIds: this.missingOwnerIds,
                ownerTypeId: this.ownerTypeId
            }
        },
        noteSearchModelValid() {
            return this.noteSearchModel.ownerIds.length > 0;
        }
    },
    watch: {
        noteSearchModel() {
            this.trySearchNotes();
        }
    },
    methods: {
        ...mapActions('Notes', [
            'searchAllNotes'
        ]),
        trySearchNotes() {
            const self = this;
            if (!self.noteSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchAllNotes({ keys: self.missingNotesCacheKey, payload: self.noteSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.trySearchNotes();
    }
}
