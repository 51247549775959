<template>
    <div>
        <label v-if="errors">{{errors}}</label>
        <rebook-pets v-model="petStatus" :appointment="appointment"></rebook-pets>
        <rebook-resources v-model="resourceStatus" :appointment="appointment"></rebook-resources>
        <rebook-orders v-if="completedAppointmentGroup" v-model="orderStatus" :appointments="appointments"></rebook-orders>
    </div>
</template>

<script>
    import { AppointmentGroupCacheMixin } from "../../../../mixins/AppointmentGroups/AppointmentGroupCacheMixin";
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";
    import AppointmentRebookOrders from "./AppointmentRebookOrders.vue";
    import AppointmentRebookPets from "./AppointmentRebookPets.vue";
    import AppointmentRebookResources from "./AppointmentRebookResources.vue";

    export default {
        name: 'AppointmentRebookGroup',
        mixins: [AppointmentGroupCacheMixin, StatusEmitterMixin],
        props: {
            appointment: Object
        },
        data() {
            return {
                petStatus: { pets: [] },
                resourceStatus: { resources: [] },
                orderStatus: { orders: [] }
            }
        },
        computed: {
            groupId() {
                return !!this.appointment ? this.appointment.groupId : '';
            },
            statuses() {
                return [this.petStatus, this.resourceStatus, this.orderStatus];
            },
            completed() {
                return this.completedAppointmentGroup && this.statuses.every(s => s.completed);
            },
            loading() {
                return this.loadingAppointmentGroup || this.statuses.some(s => s.loading);
            },
            errors() {
                return [this.error].concat(this.statuses.map(s => s.error)).filter(s => !!s).join(' ');
            },
            appointmentOrders() {
                return this.appointments.map(a => ({
                    appointment: a,
                    pet: this.petStatus.pets.find(p => p.id === a.petId),
                    resource: this.resourceStatus.resources.find(r => r.id === a.resourceId),
                    order: this.orderStatus.orders.find(o => o.appointmentId === a.id)
                }));
            },
            status() {
                return {
                    completed: this.completed,
                    loading: this.loading,
                    appointmentOrders: this.appointmentOrders
                }
            }
        },
        components: {
            'rebook-pets': AppointmentRebookPets,
            'rebook-resources': AppointmentRebookResources,
            'rebook-orders': AppointmentRebookOrders
        }
    }
</script>
