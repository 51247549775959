<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-3" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            Impersonate User
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-3">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <label>User email</label>
                    <input v-model="email" class="form-control" type="text">
                </div>
                <div class="form-group">
                    <button @click="submit" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Impersonate
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { SiteHelper } from "../../helpers/SiteHelper";
    import { Api } from "../../services/Api";
    import router from '../../routing';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'SystemAdminImpersonate',
        data() {
            return {
                error: '',
                email: '',
                loading: false
            }
        },
        computed: {
            impersonationModel() {
                return {
                    email: this.email
                }
            },
            impersonationModelValid() {
                return !!this.impersonationModel.email;
            }
        },
        methods: {
            submit() {
                const self = this;
                if (!self.impersonationModelValid) {
                    return;
                }
                self.error = '';
                self.loading = true;
                Api.post('Authentication/Impersonate/Login', self.impersonationModel)
                    .then(() => {
                        SiteHelper.clearCookie();
                        router.push({ name: 'interstitial' })
                            .then(() => window.location.reload());
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
