import { Api } from '../../services/Api';

const state = () => ({
    fetchedAccount: false,
    fetchingAccount: false,
    account: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingAccount = !!fetching;
        state.fetchedAccount = false;
    },
    setAccount(state, account) {
        state.account = account;
        state.fetchedAccount = true;
        state.fetchingAccount = false;
    }
}

const actions = {
    fetchAccount({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Account')
                .then(response => {
                    commit('setAccount', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
