<template>
    <div class="form-group">
        <label>{{$t('Default Pet Image')}}</label>
        <image-delete v-if="image" v-model="petImageStatus"></image-delete>
        <image-add v-else v-model="petImageStatus"></image-add>
    </div>
</template>

<script>
    import { DefaultPetImageCacheMixin } from "../../../mixins/Settings/Site/DefaultPetImageCacheMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import SiteUpdatePetImageAdd from "./SiteUpdatePetImageAdd.vue";
    import SiteUpdatePetImageDelete from "./SiteUpdatePetImageDelete.vue";

    export default {
        name: 'SiteUpdatePetImage',
        mixins: [DefaultPetImageCacheMixin, StatusEmitterMixin],
        data() {
            return {
                petImageStatus: {}
            }
        },
        computed: {
            status() {
                return {
                    loading: !!this.petImageStatus.loading,
                    error: this.error || this.petImageStatus.error
                }
            }
        },
        components: {
            'image-add': SiteUpdatePetImageAdd,
            'image-delete': SiteUpdatePetImageDelete
        }
    }
</script>
