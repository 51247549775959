import moment from 'moment';

export const TimePeriodMixin = {
    props: {
        hasFutureOption: false
    },
    data() {
        if (!!this.hasFutureOption) {
            return {
                options: [
                    {
                        id: 1,
                        name: 'Next 3 months',
                        frequencyTypeId: 2,
                        startLocal: moment().startOf('month').format().substring(0, 19),
                        endLocal: moment().add(2, 'months').endOf('month').format().substring(0, 19),
                    },
                    {
                        id: 2,
                        name: 'Next month',
                        frequencyTypeId: 2,
                        startLocal: moment().add(1, 'months').startOf('month').format().substring(0, 19),
                        endLocal: moment().add(1, 'months').endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 3,
                        name: 'This month',
                        frequencyTypeId: 2,
                        startLocal: moment().startOf('month').format().substring(0, 19),
                        endLocal: moment().utc().format().substring(0, 19)
                    },
                    {
                        id: 4,
                        name: 'Last month',
                        frequencyTypeId: 2,
                        startLocal: moment().subtract(1, 'months').startOf('month').format().substring(0, 19),
                        endLocal: moment().subtract(1, 'months').endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 5,
                        name: 'Last 3 months',
                        frequencyTypeId: 2,
                        startLocal: moment().subtract(2, 'months').startOf('month').format().substring(0, 19),
                        endLocal: moment().endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 6,
                        name: 'Next year',
                        frequencyTypeId: 3,
                        startLocal: moment().add(1, 'years').startOf('year').format().substring(0, 19),
                        endLocal: moment().add(1, 'years').endOf('year').format().substring(0, 19)
                    },
                    {
                        id: 7,
                        name: 'This year',
                        frequencyTypeId: 3,
                        startLocal: moment().startOf('year').format().substring(0, 19),
                        endLocal: moment().utc().format().substring(0, 19)
                    },
                    {
                        id: 8,
                        name: 'Last year',
                        frequencyTypeId: 3,
                        startLocal: moment().subtract(1, 'years').startOf('year').format().substring(0, 19),
                        endLocal: moment().subtract(1, 'years').endOf('year').format().substring(0, 19)
                    }
                ]
            }
        }
        else {
            return {
                options: [
                    {
                        id: 1,
                        name: 'This month',
                        frequencyTypeId: 2,
                        startLocal: moment().startOf('month').format().substring(0, 19),
                        endLocal: moment().endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 2,
                        name: 'Last month',
                        frequencyTypeId: 2,
                        startLocal: moment().subtract(1, 'months').startOf('month').format().substring(0, 19),
                        endLocal: moment().subtract(1, 'months').endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 3,
                        name: 'Last 3 months',
                        frequencyTypeId: 2,
                        startLocal: moment().subtract(2, 'months').startOf('month').format().substring(0, 19),
                        endLocal: moment().endOf('month').format().substring(0, 19)
                    },
                    {
                        id: 4,
                        name: 'This year',
                        frequencyTypeId: 3,
                        startLocal: moment().startOf('year').format().substring(0, 19),
                        endLocal: moment().endOf('year').format().substring(0, 19)
                    },
                    {
                        id: 5,
                        name: 'Last year',
                        frequencyTypeId: 3,
                        startLocal: moment().subtract(1, 'years').startOf('year').format().substring(0, 19),
                        endLocal: moment().subtract(1, 'years').endOf('year').format().substring(0, 19)
                    }
                ]
            }
        }
    }
}
