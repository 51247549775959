<template>
    <section class="mb-3">
        <button @click="onClick" class="btn btn-outline-secondary col-12" type="button">
            <i class="far fa-book text-info mr-1"></i>{{$t('Book Appointment')}}
        </button>
    </section>
</template>

<script>
    import { mapMutations } from "vuex";

    export default {
        name: 'PetBookAppointment',
        props: {
            pet: Object
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault',
                'setMainPet'
            ]),
            onClick() {
                this.setDefault();
                this.setMainPet(this.pet);
                this.$router.push({ name: 'schedule' });
            }
        }
    }
</script>
