<template>
    <div>
        <div class="d-flex flex-column justify-content-center align-items-center mb-3 ">
            <p class="text-center">Shake Your Tail and SumUp believe in supporting merchants of all shapes and sizes. We are here to provide them with a technology that anyone can use - so they can get on with what they do best!</p>
            <div v-bind:class="{'d-none': !toggleShowMore}">
                <p class="text-center">From paperless onboarding to taking the first payment, SumUp makes it easy. SumUp readers are durable and user-friendly. SumUp's offer comes with no monthly subscription, no contractual agreement, no hidden fees - just the one-off cost for the reader coupled with the lowest on the market transaction fee.</p>
                <p class="font-weight-bold mt-3">ABOUT SUMUP</p>
                <ul>
                    <li>The leading mobile point-of-sale (mPOS) company in Europe</li>
                    <li>1 Million active merchants in 31 countries</li>
                    <li>3,000 new merchants choose SumUp per day</li>
                    <li>SumUp is authorized as a Payment Institution by the Financial Conduct Authority and is EMV and PCI-DSS certified.</li>
                    <li>SumUp has more than 3,000 employees in 14 offices all around the world.</li>
                </ul>
            </div>
            <a class="btn-link" style="cursor: pointer" @click="toggleShowMore = !toggleShowMore"> {{ !toggleShowMore ? 'Read more...' : 'Show less...'}} </a>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-start w-100">
            <p class="font-weight-bold">TOP USPs</p>
            <ul>
                <li>Simple <b>Pay-as-you-go Pricing</b> without fixed costs</li>
                <li><b>No contract</b>, 5 minutes online registration</li>
                <li>Attractive Terminal <b>one-off price</b></li>
            </ul>
            <p class="font-weight-bold">ADDITIONAL USPs</p>
            <ul>
                <li>Integrated</li>
                <li>Secure - fully PCI compliant</li>
                <li>Proprietary - Beautifully designed in Berlin</li>
                <li>Mobile - Accept payments on the go</li>
                <li>Fast transactions - Charging station keeps terminal always on</li>
                <li>Accept major debit & credit cards, ready for contactless (incl. Apple, Google, Google Pay)</li>
            </ul>
        </div>
        <div v-if="displayConnect" class="d-flex flex-column justify-content-center align-items-center mt-1 mb-2 px-3 w-100">
            <a class="signup-link font-weight-bold w-100" href="https://www.sumup.com/en-gb/shakeyourtail/" target="_blank">{{$t('Signup')}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProviderInfoSumup',
        props: {
            displayConnect: Boolean
        },
        data() {
            return {
                toggleShowMore: false
            }
        }
    }
</script>

<style scoped>
    p, li {
        color: #000;
    }

    p {
        margin-bottom: 0.5rem;
    }

    li {
        margin-bottom: 0.25rem;
    }

    .signup-link {
        border-radius: 10rem;
        padding: 0.5rem;
        border: solid 2px #F4457B;
        text-align: center;
        font-size: 1rem;
        color: #F4457B;
        cursor: pointer;
    }

    .signup-link:hover {
        background: #F4457B;
        color: #FFF;
    }
</style>
