<template>
    <div>
        <span v-if="oneOffHoursLoading" class="inline-loader"></span>
        <template v-else>
            <full-calendar v-if="oneOffHoursAttempted" :events="eventsPerDay" v-model="value"></full-calendar>
        </template>
    </div>
</template>

<script>
    import { WorkingHoursCacheMixin } from "../Shared/WorkingHours/WorkingHoursCacheMixin";
    import { OneOffHoursSearchMixin } from "../Shared/OneOffHours/OneOffHoursSearchMixin";
    import moment from 'moment-timezone'
    import UserResourceFullCalendar from "./UserResourceFullCalendar.vue";

    export default {
        name: 'UserResourceEvents',
        mixins: [WorkingHoursCacheMixin, OneOffHoursSearchMixin],
        props: {
            value: Date,
            resource: Object
        },
        data() {
            return {
                oneOffHoursAttempted: false,
                oneOffHoursLoading: false
            }
        },
        computed: {
            now() {
                const date = moment(this.value);
                return this.getWorkingDate(date, 0, 0);
            },
            oneOffHoursSearchModel() {
                return {
                    fromLocal: this.now.format().substring(0, 19),
                    toLocal: this.now.add(42, 'days').endOf('day').format().substring(0, 19),
                    siteId: this.siteId
                }
            },
            resourceId() {
                return !!this.resource ? this.resource.id : '';
            },
            resourceWorkingHours() {
                return this.workingHours.filter(wh => wh.resourceId === this.resourceId);
            },
            workingHourEvents() {
                const events = [];
                //Create Working Hour Events for Every Week of the Month
                for (let week = 0; week < 7; week++) {
                    for (let i = 0; i < this.resourceWorkingHours.length; i++) {
                        const workingHour = this.resourceWorkingHours[i];
                        const dayId = workingHour.dayOfWeek.id;

                        // Get working date of the week
                        const workingDate = this.getWorkingDate(moment(this.value), week, dayId);

                        // Initialize Working Date Start and End Local Time
                        let workingDateStartLocal = moment(workingDate);
                        let workingDateEndLocal = moment(workingDate);

                        // Extract Local Time Component from Working Hour
                        const startTime = moment(workingHour.startLocal).format('HH:mm:ss');
                        const endTime = moment(workingHour.endLocal).format('HH:mm:ss');

                        // Set Working Date Start and End Local Time
                        workingDateStartLocal.set({ hour: startTime.substring(0, 2), minute: startTime.substring(3, 5), second: startTime.substring(6, 8) });                        
                        workingDateEndLocal.set({ hour: endTime.substring(0, 2), minute: endTime.substring(3, 5), second: endTime.substring(6, 8) });

                        let event = {
                            id: workingHour.id,
                            start: workingDateStartLocal.utc().toISOString(),
                            end: workingDateEndLocal.utc().toISOString(),
                            details: {
                                oneOff: false,
                                dayOff: !!workingHour.dayOff,
                                start: workingDateStartLocal.utc().toISOString(),
                                end: workingDateEndLocal.utc().toISOString(),
                                backgroundColor: !!workingHour.dayOff ? '#F2205C' : '#86BF4E'
                            },
                        };
                        events.push(event);
                    }
                }
                return events;
            },
            resourceOneOffHours() {
                return this.oneOffHours.length > 0 ? this.oneOffHours.filter(ooh => ooh.resourceId === this.resourceId) : [];
            },
            oneOffHourEvents() {
                const events = [];
                for (let i = 0; i < this.resourceOneOffHours.length; i++) {
                    const oneOffHour = this.resourceOneOffHours[i];

                    let event =  {
                        id: oneOffHour.id,
                        start: moment(oneOffHour.startLocal).utc().toISOString(),
                        end: moment(oneOffHour.endLocal).utc().toISOString(),
                        details: {
                            oneOff: true,
                            dayOff: !!oneOffHour.dayOff,
                            start: moment(oneOffHour.startLocal).utc().toISOString(),
                            end: moment(oneOffHour.endLocal).utc().toISOString(),
                            backgroundColor: !!oneOffHour.dayOff ? '#F2205C' : '#86BF4E'
                        }
                    }
                    events.push(event);
                }
                return events;
            },
            events() {
                return [...this.workingHourEvents, ...this.oneOffHourEvents];
            },
            eventsPerDay() {
                const eventMap = new Map();

                this.events.forEach(event => {
                    const dateKey = event.start.split('T')[0];
                    const existingEvent = eventMap.get(dateKey);

                    if (!existingEvent) {
                        eventMap.set(dateKey, event);
                    } else {
                        if (event.details.oneOff && !existingEvent.details.oneOff) {
                            eventMap.set(dateKey, event);
                        }
                    }
                });

                return Array.from(eventMap.values());
            }
        },
        methods: {
            getWorkingDate(date, week, dayId) {
                const monthStart = date.clone().startOf('month');
                const calendarStart = monthStart.add(dayId - monthStart.isoWeekday(), 'days');
                return calendarStart.add(week * 7, 'days');
            },
            // Overriding trySearchOneOffHours to mount full calendar only when OneOffHours has been fetched
            refreshOneOffHoursSearch() {
                if (!this.oneOffHoursSearch || !!this.oneOffHoursSearch.retry) {
                    this.trySearchOneOffHours();
                } else {
                    this.oneOffHoursAttempted = true;
                }
            },
            trySearchOneOffHours() {
                const self = this;
                self.oneOffHoursAttempted = false;
                self.oneOffHoursLoading = true;
                if (!self.oneOffHoursSearchModelValid) {
                    return;
                }
                self.error = '';
                self.searchOneOffHours({ key: self.oneOffHoursSearchKey, payload: self.oneOffHoursSearchModel })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => {
                        self.oneOffHoursAttempted = true
                        self.oneOffHoursLoading = false;
                    });
            }
        },
        components: {
            'full-calendar': UserResourceFullCalendar
        }
    }
</script>
