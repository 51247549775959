<template>
    <div v-if="selectedOptions.length > 0" class="applied-filters">
        <span v-for="item in selectedOptions" @click="removeItem(item)" class="active-filter">{{item.name}}</span>
        <a @click="clearItems" class="clear-filters pointer">Clear selection</a>
    </div>
</template>

<script>
    import { PetBreedsSearchMixin } from './PetBreedsSearchMixin';

    export default {
        name: 'BreedMultiSelectSummary',
        mixins: [PetBreedsSearchMixin],
        props: {
            value: Array
        },
        computed: {
            breedIds() {
                return this.value;
            },
            selectedOptions() {
                return this.value
                    .map(id => this.findBreedCache(id))
                    .filter(c => !!c)
                    .map(c => c.value)
                    .filter(b => !!b);
            }
        },
        methods: {
            removeItem(item) {
                this.$emit('remove', item);
            },
            clearItems() {
                this.$emit('clear');
            }
        }
    }
</script>
