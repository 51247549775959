import { ErrorHelper } from '../../helpers/ErrorHelper';
import { Api } from '../../services/Api';

const state = () => ({
    clientUserCaches: []
})

const getters = {
    findClientUserCache: (state) => key => state.clientUserCaches.find(cc => cc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.clientUserCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientUserCaches.push({ key: key, value: null, loading: loading });
        }
    },
    setClientUser(state, clientUser) {
        const existing = state.clientUserCaches.find(cc => cc.key === clientUser.clientId);
        if (!!existing) {
            existing.value = clientUser;
            existing.loading = false;
        } else {
            state.clientUserCaches.push({ key: clientUser.id, value: clientUser, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.clientUserCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getClientUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Client/' + id +'/User')
                .then(response => {
                    commit('setClientUser', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: id, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', id);
                        reject(error);
                    }
                });
        });
    },
    updateClientUser({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Client/User', payload)
                .then(response => {
                    commit('setClientUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }))
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
