<template>
    <span class="far fa-chevron-circle-up pointer color-medium-turquoise tags-toggle-icon"
          @click="toggleShowAvailableTags" 
          v-bind:class="{'tags-toggle-icon-rotate': showAvailableTags}">
    </span>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'TagToggleButton',
        computed: {
            ...mapState('ApplicationEvents', [
                'showAvailableTags'
            ])
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'toggleShowAvailableTags'
            ])
        }
    }
</script>

<style scoped>
    .tags-toggle-icon {
        font-size: 1.75rem;
        transform: rotate(0);
        transition: transform 0.3s ease-in-out;
    }

    .tags-toggle-icon-rotate {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
    }
</style>