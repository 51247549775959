<template>
    <div v-if="subscription">
        <sms-setting-add v-model="smsSettingStatus"></sms-setting-add>
        <campaign-setting-add v-model="campaignSettingStatus"></campaign-setting-add>
        <email-template-setting-add v-model="emailTemplateSettingStatus"></email-template-setting-add>
        <referral v-model="referralStatus" :code="referralCode"></referral>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { ErrorHelper } from "../../../helpers/ErrorHelper";
    import PostRegisterSmsSetting from "./PostRegisterSmsSetting.vue";
    import PostRegisterCampaignSetting from "./PostRegisterCampaignSetting.vue";
    import PostRegisterEmailTemplateSetting from './PostRegisterEmailTemplateSetting.vue';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import PostRegisterReferral from './PostRegisterReferral.vue';

    export default {
        name: 'PostRegisterSubscription',
        mixins: [StatusEmitterMixin],
        props: {
            referralCode: String
        },
        data() {
            return {
                error: '',
                smsSettingStatus: {},
                campaignSettingStatus: {},
                emailTemplateSettingStatus: {},
                referralStatus: {}
            }
        },
        computed: {
            ...mapState('Subscription', [
                'fetchingSubscription',
                'subscription'
            ]),
            statuses() {
                return [
                    this.smsSettingStatus,
                    this.campaignSettingStatus,
                    this.emailTemplateSettingStatus,
                    this.referralStatus
                ];
            },
            completed() {
                return !!this.subscription && this.statuses.every(s => !!s.completed);
            },
            errored() {
                return !!this.error || this.statuses.some(s => !!s.error);
            },
            loading() {
                return !!this.fetchingSubscription || this.statuses.some(s => !!s.loading);
            },
            smsSettingComplete() {
                return this.smsSettingStatus.completed || !!this.smsSettingStatus.error;
            },
            campaignSettingComplete() {
                return this.campaignSettingStatus.completed || !!this.campaignSettingStatus.error;
            },
            emailTemplateSettingComplete() {
                return this.emailTemplateSettingStatus.completed || !!this.emailTemplateSettingStatus.error;
            },
            status() {
                return {
                    error: this.errored,
                    loading: this.loading,
                    completed: this.completed
                }
            }
        },
        methods: {
            ...mapActions('Subscription', [
                'createTrial'
            ]),
            tryCreateTrial() {
                const self = this;
                self.createTrial()
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        components: {
            'sms-setting-add': PostRegisterSmsSetting,
            'campaign-setting-add': PostRegisterCampaignSetting,
            'email-template-setting-add': PostRegisterEmailTemplateSetting,
            'referral': PostRegisterReferral
        },
        created() {
            this.tryCreateTrial();
        }
    }
</script>
