<template>
    <section class="mb-3">
        <div :ref="ariaRef" aria-expanded="true" :data-target="dataTarget" data-toggle="collapse" class="accordion-header">
            {{title}}
            <div class="total-figure ml-auto"></div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" v-bind:class="{show: !isCollapsed}" :id="id">
            <div class="card card-body">
                <slot></slot>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'CollapsedStaticSection',
        props: {
            id: String,
            title: String,
            isCollapsed: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            ariaRef() {
                return this.id + '-ref';
            },
            dataTarget() {
                return '#' + this.id;
            }
        }
    }
</script>