<template>
    <div class="form-group">
        <div v-if="reminderSetting" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Reminder Settings')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <toggle-input v-model="enabled" :title="toggleMessage"></toggle-input>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Sent hours prior')}}</label>
                </div>
                <div class="col-md-8">
                    <input v-model="hoursPrior" class="form-control" type="number" min="1" />
                    <validation-message v-if="reminderSettingValidator.hoursPrior" :message="reminderSettingValidator.hoursPrior"></validation-message>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { ReminderSettingAddMixin } from "../../Reminders/ReminderSettingAddMixin";
    import { mapState } from 'vuex';
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'SiteAddReminderSetting',
        mixins: [ReminderSettingAddMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            canAddReminderSetting() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !!this.reminderSetting;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.addingReminderSetting
                }
            },
            toggleMessage() {
                return !!this.enabled ? this.$t('Reminders enabled') : this.$t('Reminders disabled');
            }
        },
        watch: {
            canAddReminderSetting(value) {
                if (value) {
                    this.tryAddReminderSetting();
                }
            },
            saveSiteEvent() {
                if (this.canAddReminderSetting) {
                    this.tryAddReminderSetting();
                }
            }
        },
        methods: {
            tryFetchReminderSettings() {
                // do nothing
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'toggle-input': SlottedToggle
        }
    }
</script>
