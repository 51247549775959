export const PetHelper = {
    getPetValidator() {
        return {
            name: '',
            breedId: '',
            isValid: false,
            check(model) {
                this.name = !!model.name ? '' : 'Please enter a name';
                this.breedId = !!model.breedId ? '' : 'Please select a breed';
                this.isValid = !this.name && !this.breedId;
            }
        }
    },
    getPetValidatorV2() {
        return {
            name: '',
            breedId: '',
            isValid: false,
            validate(model) {
                this.name = !!model.name ? '' : 'Please enter a name';
                this.breedId = !!model.breedId ? '' : 'Please select a breed';
                this.isValid = !this.name && !this.breedId;
            }
        }
    }
}
