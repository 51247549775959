<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('End')}}</label>
        </div>
        <div class="col-md-8">
            <date-time-picker v-model="end">
                <validation-message v-if="validator.endUtc" :message="validator.endUtc"></validation-message>
            </date-time-picker>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import DateTimePicker from '../../Shared/Time/DateTimePicker.vue';
    import moment from 'moment';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'EventEndDateInput',
        props: {
            value: String,
            validator: Object,
            allDay: Boolean
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'startUtc'
            ]),
            end: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        watch: {
            value() {
                this.refreshInput();
            },
            allDay(value) {
                if (value) {
                    const end = moment(this.value).endOf('day');
                    this.end = end.utc().format();
                }
            }
        },
        methods: {
            refreshInput() {
                if (!this.value) {
                    this.end = moment(this.startUtc).add(30, 'minutes').utc().format();
                }
            }
        },
        components: {
            'date-time-picker': DateTimePicker,
            'validation-message': ValidationMessage
        },
        created() {
            this.refreshInput();
        }
    }
</script>
