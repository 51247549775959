<template>
    <td class="text-center">
        <p v-if="hours" class="w-100">{{workingSummary}}</p>
        <p v-else>{{$t('NOT SET')}}</p>
    </td>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'UserResourceHoursDay',
        props: {
            dayId: Number,
            workingHours: Array,
            oneOffHours: Array
        },
        computed: {
            workingHour() {
                return this.workingHours.find(wh => wh.dayOfWeek.id === this.dayId);
            },
            oneOffHour() {
                return this.oneOffHours.find(ooh => moment(ooh.dateLocal).day() === this.dayId);
            },
            hours() {
                return !!this.oneOffHour ? this.oneOffHour : this.workingHour;
            },
            isOneOff() {
                return !!this.oneOffHour;
            },
            timeSummary() {
                return moment(this.hours.startLocal).format('HH:mm') + ' - ' + moment(this.hours.endLocal).format('HH:mm');
            },
            workingSummary() {
                return !!this.hours.dayOff ? this.$t('Day Off') : this.timeSummary;
            }
        }
    }
</script>
