import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BookingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Bookings', [
            'findBookingCache'
        ]),
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        },
        bookingCache() {
            return this.findBookingCache(this.bookingId);
        },
        booking() {
            return !!this.bookingCache ? this.bookingCache.value : null;
        },
        loadingBooking() {
            return !!this.bookingCache && this.bookingCache.loading;
        },
        bookingFullname() {
            return !!this.booking ? this.booking.firstName + ' ' + this.booking.lastName : '-';
        },
        bookingParticipants() {
            return !!this.booking ? this.booking.participants : [];
        }
    },
    watch: {
        bookingId() {
            this.refreshBooking();
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'getBooking'
        ]),
        refreshBooking() {
            if (!this.bookingCache || !!this.bookingCache.retry) {
                this.tryGetBooking();
            }
        },
        tryGetBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.getBooking(self.bookingId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBooking();
    }
}
