<template>
    <section class="mb-3">
        <div :ref="ariaRef" :data-target="dataTarget" data-toggle="collapse" class="accordion-header">
            <slot name="sectionIcon"></slot>
            {{title}}
            <span v-if="loading" class="inline-loader"></span>
            &nbsp;<slot name="tooltip"></slot>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div :id="id" class="collapse">
            <div class="card card-body">
                <slot v-if="expanded"></slot>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'CollapsedSection',
        data() {
            return {
                expanded: false,
                observer: MutationObserver
            }
        },
        props: {
            id: String,
            title: String,
            loading: Boolean
        },
        computed: {
            ariaRef() {
                return this.id + '-ref';
            },
            dataTarget() {
                return '#' + this.id;
            },
            observerRef() {
                return this.$refs[this.ariaRef];
            }
        },
        watch: {
            expanded(value) {
                this.$emit('expanded', value);
            }
        },
        methods: {
            onAriaChanged(attrValue) {
                if (attrValue === 'true') {
                    this.expanded = true;
                }
            }
        },
        mounted() {
            this.observer = new MutationObserver(mutations => {
                for (const m of mutations) {
                    if (m.attributeName === 'aria-expanded') {
                        const newValue = m.target.getAttribute(m.attributeName);
                        this.onAriaChanged(newValue);
                        break;
                    }
                }
            });
            this.observer.observe(this.observerRef, {
                attributes: true,
                atttributeFilter: ['aria-expanded']
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        }
    }
</script>
