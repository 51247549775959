<template>
    <bar :chart-data="chartData"></bar>
</template>

<script>
    import moment from 'moment';
    import { CalendarHelper } from "../../helpers/CalendarHelper";
    import BarChart from "../Shared/Graphs/BarChart.vue";

    export default {
        name: 'AppointmentAggregateStatusChart',
        props: {
            aggregates: []
        },
        computed: {
            statusAggregates() {
                return [].concat(...this.aggregates.map(a => a.statuses)).sort((a, b) => a.status.id - b.status.id);
            },
            statusLabels() {
                const names = this.aggregates.map(sa => moment(sa.startLocal).format('MMM'));
                const labels = names.length > 1 ? [names[0] + ' - ' + names[names.length - 1]] : names.length === 1 ? [names[0]] : [''];
                return labels.map(label => this.$t(label));
            },
            statusDatasets() {
                const datasets = [];
                for (var i = 0; i < this.statusAggregates.length; i++) {
                    const statusAggregate = this.statusAggregates[i];
                    const dataset = datasets.find(d => d.label == statusAggregate.status.description);
                    if (!!dataset) {
                        dataset.data[0] += statusAggregate.count;
                    } else {
                        const colour = CalendarHelper.getAppointmentStatusColour(statusAggregate.status.id);
                        datasets.push({
                            label: statusAggregate.status.description,
                            data: [statusAggregate.count],
                            borderColor: colour,
                            backgroundColor: colour
                        });
                    }
                }

                const translatedDatasets = datasets.map(dataset => {
                    return {
                        ...dataset,
                        label: this.$t(dataset.label)
                    }
                });

                return translatedDatasets;
            },
            chartData() {
                return {
                    labels: this.statusLabels,
                    datasets: this.statusDatasets
                };
            }
        },
        components: {
            'bar': BarChart
        }
    }
</script>
