import { Api } from '../../services/Api';

const state = () => ({
    oneOffHoursCaches: []
})

const getters = {
    findOneOffHoursCache: (state) => key => state.oneOffHoursCaches.find(oohc => oohc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.oneOffHoursCaches.find(oohc => oohc.key === key);
        if (!!existing) {
            existing.loading = loading;
            existing.retry = false;
        } else {
            state.oneOffHoursCaches.push({ key: key, values: [], loading: false });
        }
    },
    setOneOffHours(state, { key, oneOffHours }) {
        const existing = state.oneOffHoursCaches.find(oohc => oohc.key === key);
        if (!!existing) {
            existing.values = oneOffHours;
            existing.loading = false;
        } else {
            state.oneOffHoursCaches.push({ key: key, values: oneOffHours, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.oneOffHoursCaches.find(oohc => oohc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendOneOffHour(state, { key, oneOffHour }) {
        const existing = state.oneOffHoursCaches.find(oohc => oohc.key === key);
        if (!!existing) {
            existing.values.push(oneOffHour);
        } else {
            state.oneOffHoursCaches.push({ key: key, values: [oneOffHour], loading: false });
        }
    },
    insertOneOffHour(state, { key, oneOffHour }) {
        const existing = state.oneOffHoursCaches.find(oohc => oohc.key === key);
        if (!!existing) {
            const old = existing.values.find(ooh => ooh.id === oneOffHour.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, oneOffHour);
            } else {
                existing.values.push(oneOffHour);
            }
        } else {
            state.oneOffHoursCaches.push({ key: key, values: [oneOffHour], loading: false });
        }
    }
}

const actions = {
    searchOneOffHours({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('OneOffHours/Search', payload)
                .then(response => {
                    commit('setOneOffHours', { key: key, oneOffHours: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addOneOffHour({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('OneOffHours', payload)
                .then(response => {
                    commit('appendOneOffHour', { key: key, oneOffHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateOneOffHour({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('OneOffHours', payload)
                .then(response => {
                    commit('insertOneOffHour', { key: key, oneOffHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
