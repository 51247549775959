<template>
    <modal-dialog :title="$t('Add breed')">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <site-select v-model="siteIds" :validation-error="breedValidator.siteIds"></site-select>
            <name-input v-model="name" :validator="breedValidator"></name-input>
            <species-select v-model="speciesId" :validator="breedValidator"></species-select>
            <product-add></product-add>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="addingBreed" class="btn btn-primary">
                {{$t('Add')}}
                <span v-if="addingBreed" class="inline-loader"></span>
            </button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import BreedNameInput from "./BreedNameInput.vue";
    import BreedSpeciesSelect from "./BreedSpeciesSelect.vue";
    import { BreedAddMixin } from "../Shared/BreedAddMixin";
    import BreedAddProduct from './BreedAddProduct.vue';
    import SiteMultiSelect from '../Shared/Site/SiteMultiSelect.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BreedAdd',
        mixins: [BreedAddMixin],
        computed: {
            ...mapState('Breeds', [
                'addingBreed'
            ])
        },
        watch: {
            breedsAddedEvent() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            save() {
                this.tryAddBreed();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'site-select': SiteMultiSelect,
            'species-select': BreedSpeciesSelect,
            'name-input': BreedNameInput,
            'product-add': BreedAddProduct,
            'error-banner': ErrorBanner
        }
    }
</script>
