import { PaymentCheckoutsCacheMixin } from "../../components/Payments/PaymentCheckoutsCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentHelper } from "../../helpers/PaymentHelper";
import { mapActions } from 'vuex';

export const PaymentCheckoutUpdateMixin = {
    mixins: [PaymentCheckoutsCacheMixin],
    data() {
        return {
            isPartialRefund: false,
            refundAmount: 0,
            refundValidator: PaymentHelper.getRefundValidator()
        }
    },
    computed: {
        checkoutId() {
            console.log('OVERRIDE COMPUTED - checkoutId');
        },
        checkoutMixinIsValid() {
            return !!this.checkoutId && !!this.checkoutsReference;
        },
        checkout() {
            return this.checkouts.find(c => c.id === this.checkoutId);
        },
        refundRequestModel() {
            return {
                isPartial: this.isPartialRefund,
                amount: this.refundAmount
            }
        },
        refundRequestModelValid() {
            return !!this.checkoutMixinIsValid && this.refundValidator.isValid;
        }
    },
    methods: {
        ...mapActions('PaymentCheckouts', [
            'cancelCheckout',
            'refundCheckout'
        ]),
        tryCancelCheckout() {
            const self = this;
            if (!self.checkoutMixinIsValid) {
                return;
            }
            self.error = '';
            self.cancelCheckout({ key: self.checkoutsCacheKey, id: self.checkoutId })
                .then(response => self.afterCancelCheckout(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterCancelCheckout() {
            // for overriding
        },
        tryRefundCheckout() {
            const self = this;
            self.refundValidator.validate(self.refundRequestModel);
            if (!self.refundRequestModelValid) {
                return;
            }
            self.error = '';
            self.refundCheckout({ key: self.checkoutsCacheKey, id: self.checkoutId, payload: self.refundRequestModel })
                .then(response => self.afterRefundCheckout(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterRefundCheckout() {
            // for overriding
        }
    }
}
