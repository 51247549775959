<template>
    <table-mobile @next="next">
        <communication-mobile v-for="communication in communications" :key="communication.id" :communication="communication"></communication-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import CommunicationItemMobile from "./CommunicationItemMobile.vue";

    export default {
        name: 'CommunicationsMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('CommunicationSearches', [
                'findCommunicationSearches'
            ]),
            communicationSearches() {
                return this.findCommunicationSearches(this.searchKey);
            },
            communications() {
                return [].concat(...this.communicationSearches.map(cs => cs.values));
            },
            loading() {
                return this.communicationSearches.some(cs => !!cs.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.communications.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'communication-mobile': CommunicationItemMobile
        }
    }
</script>