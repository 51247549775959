import 'ckeditor5/ckeditor5.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {
    Alignment,
    Bold, Italic, Underline, Strikethrough, ClassicEditor,
    Subscript,
    Superscript,
    BlockQuote,
    FindAndReplace,
    Font,
    Title,
    Heading,
    HorizontalLine,
    Image, ImageUpload, ImageInsert, AutoImage, ImageCaption, ImageResize, ImageStyle, ImageToolbar, LinkImage, ImageResizeEditing, ImageResizeHandles,
    Indent, IndentBlock,
    List,
    Essentials,
    Paragraph,
    Table, TableToolbar,
    SpecialCharacters, SpecialCharactersEssentials,
    RemoveFormat,
    AutoLink, Link
} from 'ckeditor5';

export const CKEditorMixin = {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'undo', 'redo', '|',
                        'heading', '|',
                        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
                        'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|', 'removeFormat', '|',
                        'bulletedList', 'numberedList', '|',
                        'alignment', 'outdent', 'indent', '|',
                        'link', 'insertImage', 'insertTable', 'horizontalLine', '|',
                        'specialCharacters', '|', 'blockQuote', '|',
                        'findAndReplace'
                    ],
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                image: {
                    toolbar: [
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ],
                    insert: {
                        type: 'auto',
                        integrations: ['url']
                    }
                },
                plugins: [
                    Alignment,
                    Bold,
                    Italic,
                    Underline,
                    Strikethrough,
                    Subscript,
                    Superscript,
                    BlockQuote,
                    FindAndReplace,
                    Font,
                    Title,
                    Heading,
                    HorizontalLine,
                    Image, ImageUpload, ImageInsert, AutoImage, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageResizeEditing, ImageResizeHandles,
                    Indent, IndentBlock,
                    List,
                    Essentials,
                    Paragraph,
                    Table, TableToolbar,
                    SpecialCharacters, SpecialCharactersEssentials,
                    RemoveFormat,
                    AutoLink, Link
                ]
            }
        }
    },
    components: {
        ckeditor: CKEditor.component
    }
}
