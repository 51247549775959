<template>
    <div v-if="fetchedAccountSettings">
        <default-country v-model="defaultCountryStatus" :registration-info="registrationInfo"></default-country>
        <default-contact v-model="defaultContactStatus" :registration-info="registrationInfo"></default-contact>
        <default-details v-model="defaultDetailsStatus" :registration-info="registrationInfo"></default-details>
        <default-time-zone v-model="defaultTimeZoneStatus" :registration-info="registrationInfo"></default-time-zone>
        <default-resource v-model="defaultResourceStatus" :registration-info="registrationInfo"></default-resource>
    </div>
</template>

<script>
    import PostRegisterAccountSettingDefaultContact from "./PostRegisterAccountSettingDefaultContact.vue";
    import PostRegisterAccountSettingDefaultCountry from "./PostRegisterAccountSettingDefaultCountry.vue";
    import PostRegisterAccountSettingDefaultDetails from "./PostRegisterAccountSettingDefaultDetails.vue";
    import PostRegisterAccountSettingDefaultTimeZone from "./PostRegisterAccountSettingDefaultTimeZone.vue";
    import PostRegisterAccountSettingDefaultResource from "./PostRegisterAccountSettingDefaultResource.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AccountSettingsCacheMixinV2 } from "../../../mixins/Settings/Account/AccountSettingsCacheMixinV2";

    export default {
        name: 'PostRegisterAccountSettings',
        mixins: [AccountSettingsCacheMixinV2, StatusEmitterMixin],
        props: {
            registrationInfo: Object
        },
        data() {
            return {
                defaultCountryStatus: {},
                defaultContactStatus: {},
                defaultDetailsStatus: {},
                defaultTimeZoneStatus: {},
                defaultResourceStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.defaultCountryStatus,
                    this.defaultContactStatus,
                    this.defaultDetailsStatus,
                    this.defaultTimeZoneStatus,
                    this.defaultResourceStatus
                ];
            },
            status() {
                return {
                    error: this.error,
                    completed: this.statuses.every(s => !!s.completed || !!s.error),
                    loading: this.fetchingAccountSettings || this.statuses.some(s => !!s.loading)
                }
            }
        },
        components: {
            'default-country': PostRegisterAccountSettingDefaultCountry,
            'default-contact': PostRegisterAccountSettingDefaultContact,
            'default-details': PostRegisterAccountSettingDefaultDetails,
            'default-time-zone': PostRegisterAccountSettingDefaultTimeZone,
            'default-resource': PostRegisterAccountSettingDefaultResource
        }
    }
</script>
