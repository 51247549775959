import { Api } from "../../services/Api";

const state = () => ({
    fetchingReportTypes: false,
    fetchedReportTypes: false,
    reportTypes: []
})

const mutations = {
    setFetching(state) {
        state.fetchedReportTypes = false;
        state.fetchingReportTypes = true;
    },
    setReportTypes(state, reportTypes) {
        state.reportTypes = reportTypes;
        state.fetchedReportTypes = true;
        state.fetchingReportTypes = false;
    },
    setError(state) {
        state.fetchedReportTypes = false;
        state.fetchingReportTypes = false;
    }
}

const actions = {
    fetchReportTypes({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Report/Type')
                .then(response => {
                    commit('setReportTypes', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setError');
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
