<template>
    <div>
        <template v-if="error">
            <error-banner :message="error"></error-banner>
            <button @click="beginSessionFlow">Retry</button>
        </template>
        <page-loader v-else></page-loader>
    </div>
</template>

<script>
    import PageLoader from '../Shared/Loading/PageLoader.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';
    import { StripeCheckoutMixin } from '../../mixins/Stripe/StripeCheckoutMixin';

    export default {
        name: 'PaymentCheckout',
        mixins: [StripeCheckoutMixin],
        computed: {
            sessionUrl() {
                return 'Stripe/Payment/Session';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'page-loader': PageLoader
        }
    }
</script>
