import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionEntityPaymentsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SubscriptionEntityPayments', [
            'findSubscriptionEntityPaymentsCache'
        ]),
        subscriptionEntityId() {
            console.log('OVERRIDE COMPUTED - subscriptionEntityId');
            return '';
        },
        subscriptionEntityPaymentsCache() {
            return this.findSubscriptionEntityPaymentsCache(this.subscriptionEntityId);
        },
        subscriptionEntityPayments() {
            return !!this.subscriptionEntityPaymentsCache ? this.subscriptionEntityPaymentsCache.values : [];
        },
        loadingSubscriptionEntityPayments() {
            return !!this.subscriptionEntityPaymentsCache && this.subscriptionEntityPaymentsCache.loading;
        }
    },
    watch: {
        subscriptionEntityId() {
            this.refreshSubscriptionEntityPayments();
        }
    },
    methods: {
        ...mapActions('SubscriptionEntityPayments', [
            'findSubscriptionEntityPayments'
        ]),
        refreshSubscriptionEntityPayments() {
            if (!this.subscriptionEntityPaymentsCache || this.subscriptionEntityPaymentsCache.retry) {
                this.tryFindSubscriptionEntityPayments();
            }
        },
        tryFindSubscriptionEntityPayments() {
            const self = this;
            if (!self.subscriptionEntityId) {
                return;
            }
            self.error = '';
            self.findSubscriptionEntityPayments(self.subscriptionEntityId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionEntityPayments();
    }
}
