export const PetPreferredSettingMixin = {
    computed: {
        ownerTypeId() {
            return 3;
        },
        settingKey() {
            return 'PreferredResource';
        },
        defaultSettingObject() {
            return {
                id: '',
                name: ''
            }
        }
    }
}
