<template>
    <div v-if="showTags">
        <display-tags :tags="smsTemplateTags"></display-tags>
    </div>
</template>

<script>
    import { SmsTemplateTagsCacheMixin } from "../../Shared/SmsTemplateTagsCacheMixin";
    import TagsDisplay from "../../Shared/Tags/TagsDisplay.vue";

    export default {
        name: 'SmsTemplateTags',
        mixins: [SmsTemplateTagsCacheMixin],
        computed: {
            showTags() {
                return this.smsTemplateTags.length > 0;
            }
        },
        components: {
            'display-tags': TagsDisplay
        }
    }
</script>