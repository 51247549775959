import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const SiteBrandingSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Branding';
        },
        defaultSettingObject() {
            return {
                mainTextColor: "#434242",
                primaryButtonColor: "#F24C64",
                secondaryButtonColor: "#86BF4E",
                tertiaryButtonColor: "#51C5DE",
                headerBackgroundColor: "#EEEEEE",
                headerTextColor: "#434242",
                footerBackgroundColor: "#0A4F70",
                footerTextColor: "#51C5DE",
            }
        }
    }
}
