<template>
    <collapsed-section id="site-resources" :title="$t('Resources')">
        <event-colours v-model="eventColoursStatus"></event-colours>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateResourceEventColours from './SiteUpdateResourceEventColours.vue'; 

    export default {
        name: 'SiteUpdateResources',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                eventColoursStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.eventColoursStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'event-colours': SiteUpdateResourceEventColours
        }
    }
</script>
