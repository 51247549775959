import { SiteHelper } from '../../helpers/SiteHelper';
import { Api } from '../../services/Api';

const state = () => ({
    fetchingSites: false,
    fetchedSites: false,
    addingSite: false,
    updatingSite: false,
    sites: [],
    selected: SiteHelper.getDefaultSite()
})

const mutations = {
    setSites(state, sites) {
        state.sites = sites;
    },
    appendSite(state, site) {
        const local = state.sites.filter(s => s.id !== site.id);
        local.push(site);
        state.sites = local;
    },
    insertSite(state, site) {
        const existing = state.sites.find(s => s.id === site.id);
        state.sites.splice(state.sites.indexOf(existing), 1, site);
    },
    clearSelectedSite(state) {
        SiteHelper.clearCookie();
        state.selected = SiteHelper.getDefaultSite();
    },
    setFetched(state) {
        state.fetchedSites = true;
        state.fetchingSites = false;
    },
    setFetching(state, value) {
        state.fetchingSites = !!value;
    },
    setAdding(state, value) {
        state.addingSite = !!value;
    },
    setUpdating(state, value) {
        state.updatingSite = !!value;
    },
    setSelected(state, siteId) {
        const site = state.sites.find(s => s.id === siteId);
        const defaultSite = SiteHelper.getDefaultSite();
        state.selected = !!site ? site :
            !!state.sites.find(s => s.id === defaultSite.id) ? defaultSite :
                state.sites.length > 0 ? state.sites[0] :
                    defaultSite;
        SiteHelper.setDefaultSite(state.selected);
        this.commit('AppointmentScheduleUpload/setDefault');
    }
}

const actions = {
    getSites({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Site')
                .then(response => {
                    commit('setSites', response.data);
                    commit('setSelected');
                    commit('setFetched');
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error)
                });
        });
    },
    addSite({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Site', payload)
                .then(response => {
                    commit('appendSite', response.data);
                    commit('ApplicationEvents/triggerSiteAdded', response.data, { root: true });
                    dispatch('SiteCount/fetchSiteCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateSite({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Site', payload)
                .then(response => {
                    commit('insertSite', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
