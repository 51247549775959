import { render, staticRenderFns } from "./BookingAuthenticated.vue?vue&type=template&id=4638d534&scoped=true"
import script from "./BookingAuthenticated.vue?vue&type=script&lang=js"
export * from "./BookingAuthenticated.vue?vue&type=script&lang=js"
import style0 from "./BookingAuthenticated.vue?vue&type=style&index=0&id=4638d534&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4638d534",
  null
  
)

export default component.exports