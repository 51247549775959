import { mapActions, mapMutations, mapState } from 'vuex';
import { WeightHelper } from '../../../helpers/WeightHelper.js';
import { ErrorHelper } from '../../../helpers/ErrorHelper.js';
import { WeightCacheMixin } from './WeightCacheMixin.js';

export const WeightAddMixin = {
    mixins: [WeightCacheMixin],
    data() {
        return {
            error: '',
            value: 0,
            notes: '',
            weightValidator: WeightHelper.getValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'weightAddedEvent'
        ]),
        weightUploadModel() {
            return {
                petId: this.petId,
                value: this.value,
                notes: this.notes
            }
        },
        weightUploadModelValid() {
            return this.weightUploadModel.petId && this.weightValidator.isValid;
        },
        weightUploadModelModified() {
            const model = this.weightUploadModel;
            return !!model.petId && (model.value !== 0 || !!model.notes);
        }
    },
    watch: {
        weightAddedEvent() {
            this.resetWeightModel();
        }
    },
    methods: {
        ...mapMutations('ApplicationEvents', [
            'triggerWeightAdded'
        ]),
        ...mapActions('Weights', [
            'addWeight'
        ]),
        resetWeightModel() {
            this.value = 0;
            this.notes = '';
        },
        tryAddWeight() {
            const self = this;
            self.weightValidator.check(self.weightUploadModel);
            if (!self.weightUploadModelValid || !self.weightUploadModelModified) {
                return;
            }
            self.error = '';
            self.addWeight({ key: self.petId, payload: self.weightUploadModel })
                .then(response => self.triggerWeightAdded(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
