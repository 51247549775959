<template>
    <figure class="pet-photo pet-photo-appointment">
        <img v-if="heroImage" :src="heroImage.thumbnails.small" width="100" :alt="pet.name">
        <template v-else>
            <default-image></default-image>
        </template>
    </figure>
</template>

<script>
    import DefaultPetImageSmall from "../../Shared/Images/DefaultPetImageSmall.vue";
    import { ImageCacheMixin } from "../../Shared/Images/ImageCacheMixin";

    export default {
        name: 'AppointmentOverviewPetPhoto',
        mixins: [ImageCacheMixin],
        props: {
            pet: Object
        },
        computed: {
            ownerId() {
                return !!this.pet ? this.pet.id : '';
            },
            ownerTypeId() {
                return 3;
            },
            heroImage() {
                return this.images.length > 0 ? this.images[0] : null;
            }
        },
        components: {
            'default-image': DefaultPetImageSmall
        }
    }
</script>