import { mapActions, mapGetters, mapState } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SettingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Settings', [
            'settingsCaches'
        ]),
        ...mapGetters('Settings', [
            'findSettingsCache'
        ]),
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        settingKey() {
            console.log('!!!OVERRIDE COMPUTED - settingKey!!!');
        },
        defaultSettingObject() {
            console.log('!!!OVERRIDE COMPUTED - defaultSettingObject!!!');
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        ownerQueryModelValid() {
            const model = this.ownerQueryModel;
            return !!model.ownerId && !!model.ownerTypeId;
        },
        settingCacheKey() {
            return CacheHelper.objectCacheKey(this.ownerQueryModel);
        },
        settingCache() {
            return this.findSettingsCache(this.settingCacheKey);
        },
        loadingSetting() {
            return !!this.settingCache && this.settingCache.loading;
        },
        attemptedSetting() {
            return !!this.settingCache && this.settingCache.attempted;
        },
        settings() {
            return !!this.settingCache ? this.settingCache.values : [];
        },
        setting() {
            return this.settings.find(s => s.key === this.settingKey);
        },
        settingString() {
            return !!this.setting ? this.setting.value : '';
        },
        settingObject() {
            return Object.assign({}, this.defaultSettingObject, (!!this.settingString ? JSON.parse(this.settingString) : null));
        }
    },
    watch: {
        settingCacheKey() {
            this.refreshSetting();
        }
    },
    methods: {
        ...mapActions('Settings', [
            'getSetting'
        ]),
        refreshSetting() {
            if (!this.settingCache || !!this.settingCache.retry) {
                this.tryGetSetting();
            }
        },
        tryGetSetting() {
            const self = this;
            if (!self.ownerQueryModelValid) {
                return;
            }
            self.error = '';
            self.getSetting({ key: self.settingCacheKey, payload: self.ownerQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSetting();
    }
}
