import { Api } from "../../services/Api";

const state = () => ({
    countryOptionCaches: []
})

const getters = {
    findCountryOptionCache: (state) => key => state.countryOptionCaches.find(coc => coc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.countryOptionCaches.find(coc => coc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.countryOptionCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setCountryOption(state, countryOption) {
        const existing = state.countryOptionCaches.find(coc => coc.key === countryOption.id);
        if (!!existing) {
            existing.value = countryOption;
            existing.loading = false;
        } else {
            state.countryOptionCaches.push({ key: key, value: countryOption, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.countryOptionCaches.find(coc => coc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getCountryOption({ commit }, countryId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: countryId, loading: true });
            Api.get('CountryOptions/' + countryId)
                .then(response => {
                    commit('setCountryOption', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', countryId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
