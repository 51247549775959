import { ContactHelper } from "../../../helpers/ContactHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ContactCacheMixin } from "./ContactCacheMixin";
import { mapActions } from 'vuex';

export const ContactAddMixin = {
    mixins: [ContactCacheMixin],
    data() {
        return {
            name: '',
            mobile: '',
            email: '',
            otherInfo: '',
            contactValidator: ContactHelper.getContactValidator()
        }
    },
    computed: {
        contactTypeId() {
            console.log('!!!OVERRIDE COMPUTED - contactTypeId!!!');
        },
        contactAddModel() {
            return Object.assign({}, this.ownerQueryModel, {
                contactTypeId: this.contactTypeId,
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            });
        },
        contactAddModelValid() {
            return this.ownerQueryModelValid && !!this.contactTypeId && this.contactValidator.isValid;
        },
        contactAddModelEdited() {
            return !!this.name || !!this.email || !!this.mobile || !!this.otherInfo;
        }
    },
    methods: {
        ...mapActions('Contacts', [
            'addContact'
        ]),
        tryAddContact() {
            const self = this;
            if (!self.contactAddModelEdited) {
                return;
            }
            self.contactValidator.check(self.contactAddModel);
            if (!self.contactAddModelValid) {
                return;
            }
            self.error = '';
            self.addContact({ key: self.contactCacheKey, payload: self.contactAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
