<template>
    <tr @click="onClick" class="link pointer" v-bind:class="classType">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a>{{templateName}}</a>
            </div>
        </td>
        <td>{{$t(signature)}}</td>
        <td>{{version}}</td>
    </tr>
</template>

<script>
    import { ContractItemMixin } from './ContractItemMixin';

    export default {
        name: 'ContractItemDesktop',
        mixins: [ContractItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
