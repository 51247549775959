<template>
    <table-mobile @next="next">
        <client-mobile v-for="client in clients" :key="client.id" :client="client"></client-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import ClientItemMobile from "./ClientItemMobile.vue";

    export default {
        name: 'ClientsMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('ClientSearchesV2', [
                'findClientSearches'
            ]),
            clientSearches() {
                return this.findClientSearches(this.searchKey);
            },
            clients() {
                return [].concat(...this.clientSearches.map(cs => cs.values));
            },
            loading() {
                return this.clientSearches.some(cs => !!cs.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.clients.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'client-mobile': ClientItemMobile
        }
    }
</script>
