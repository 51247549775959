<template>
    <div class="form-group">
        <label for="title">Account Id(s)<span class="required-field"></span></label>
        <div class="form-group">
            <div class="d-flex justify-content-between form-control p-0">
                <input v-model="accountId" class="w-100 border-0 mr-auto px-2" style="border-radius: 0.25rem" placeholder="Please enter Account Id"/>
                <button @click="addItem(accountId)" class="px-3 btn-account-icon">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div v-if="accountIds.length > 0" class="applied-filters d-flex flex-wrap">
                <span v-for="item in accountIds" @click="removeItem(item)" class="active-filter mr-1 mb-1">{{item}}</span>
                <a @click="clearItems" class="clear-filters pointer mr-1 mb-1">Clear</a>
            </div>
            <validation-message v-if="!!validationError" :message="validationError"></validation-message>
        </div>
    </div>
</template>

<script>
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";

    export default {
        name: 'AccountIdMultiInput',
        props: {
            value: Array,
            validationError: String
        },
        data() {
            return {
                accountIds: [],
                accountId: ''
            }
        },
        watch: {
            accountIds() {
                this.setAccountIds();
            }
        },
        methods: {
            clearItems() {
                this.accountIds = [];
            },
            removeItem(value) {
                this.accountIds = this.accountIds.filter(ai => ai !== value);
            },
            addItem(value) {
                if (!!value) {
                    const existing = this.accountIds.find(ai => ai === value);

                    if (!existing) {
                        this.accountIds.push(value);
                        this.accountId = ''
                    }
                }
            },
            setAccountIds() {
                this.$emit('input', this.accountIds);
            }
        },
        created() {
            this.accountIds = this.value;
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>

<style scoped>
    .applied-filters {
        padding-left: 0;
    }
    .btn-account-icon {
        border: none;
        outline: none;
        color: #fff;
        background: #86bf4e;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
    .btn-account-icon:hover {
        background: #f4457b;
    }
    .btn-accounticon:focus {
        border: none;
        outline: none;
    }
</style>
