<template>
    <div class="row form-group">
        <div class="col-md-8 offset-md-4">
            <label for="title">{{$t('Duration')}}</label>
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{ `${getOptionNameValue(selectedOptionName)} ${$t(getOptionNameUnit(selectedOptionName))}` }}
                </button>
                <span v-if="loadingCustomDurations" class="inline-loader"></span>
                <div v-else class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in combinedOptions"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setDuration(option.value)"
                            v-bind:class="{'active': option.value === selectedOptionValue}">
                            {{ `${getOptionNameValue(option.name)} ${$t(getOptionNameUnit(option.name))}` }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { AppointmentDurationOptionsMixin } from '../AppointmentDurationOptionsMixin';

    export default {
        name: 'EditDuration',
        mixins: [AppointmentDurationOptionsMixin],
        props: {
            value: Number
        },
        data() {
            return {
                duration: 0
            }
        },
        computed: {
            selectedOption() {
                return this.getOptionForDuration(this.duration);
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.selectedOption.name : '15 mins'
            },
            selectedOptionValue() {
                return !!this.selectedOption ? this.selectedOption.value : 15;
            }
        },
        methods: {
            setDuration(value) {
                this.duration = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setDuration(this.value);
        }
    }
</script>
