import { Api } from '../../services/Api';

const state = () => ({
    addingCustomDuration: false,
    customDurationCaches: []
})

const getters = {
    findCustomDurationCache: (state) => (key) => {
        return state.customDurationCaches.find(cdc => cdc.key === key)
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.customDurationCaches.find(cdc => cdc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.customDurationCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCustomDurations(state, { key, customDurations }) {
        var existing = state.customDurationCaches.find(cdc => cdc.key === key);
        if (!!existing) {
            existing.values = customDurations;
            existing.loading = false;
        } else {
            state.customDurationCaches.push({ key: key, values: customDurations, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.customDurationCaches.find(cdc => cdc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendCustomDuration(state, { key, customDuration }) {
        var existing = state.customDurationCaches.find(cdc => cdc.key === key);
        if (!!existing) {
            existing.values.push(customDuration);
        } else {
            state.customDurationCaches.push({ key: key, values: [customDuration], loading: false });
        }
    },
    removeCustomDuration(state, { key, id }) {
        var existing = state.customDurationCaches.find(cdc => cdc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(cd => cd.id !== id);
        }
    }
}

const actions = {
    getCustomDurations({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteId, loading: true });
            Api.get('CustomDuration')
                .then(response => {
                    commit('setCustomDurations', { key: siteId, customDurations: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', siteId)
                    reject(error);
                });
        });
    },
    addCustomDuration({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('CustomDuration', payload)
                .then(response => {
                    commit('appendCustomDuration', { key: key, customDuration: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteCustomDuration({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('CustomDuration/' + id)
                .then(response => {
                    commit('removeCustomDuration', { key: key, id: id });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }))
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
