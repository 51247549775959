import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { PurchasedCampaignTemplatesCacheMixin } from '../../Shared/PurchasedCampaignTemplatesCacheMixin';

export const StripoDefaultTemplateBundleSettingsDisplayItemMixin = {
    mixins: [PurchasedCampaignTemplatesCacheMixin],
    data() {
        return {
            purchasingCurrentBundle: false
        }
    },
    props: {
        bundle: {
            type: Object,
            required: true
        }
    },
    computed: {
        bundleThumbnails() {
            if (!this.bundle) {
                return null;
            }

            return this.bundle.templates.map((template) => {
                return template.thumbnail;
            });
        },
        purchasedCampaignTemplateBundleAddModel() {
            return {
                stripoDefaultTemplateBundleId: this.bundle.id
            }
        },
        purchasedCampaignTemplateBundleAddModelValid() {
            return !!this.purchasedCampaignTemplateBundleAddModel.stripoDefaultTemplateBundleId;
        },
        allStripoDefaultTemplatesPurchased() {
            return this.bundle.templateIds.every(templateId => this.purchasedCampaignTemplates.some(template => template.stripoDefaultTemplateId == templateId))
        },
        campaignTemplateBundleCost() {
            return this.bundle.templateIds.filter(templateId => !this.purchasedCampaignTemplates.find(template => template.stripoDefaultTemplateId == templateId)).length
        }
    },
    methods: {
        ...mapActions('PurchasedCampaignTemplates', [
            'addPurchasedCampaignTemplateBundle'
        ]),

        ...mapActions('EmailCampaignTemplates', [
            'addEmailCampaignTemplatesFromPurchased'
        ]),

        tryPurchasedCampaignTemplateBundle() {
            if (this.allStripoDefaultTemplatesPurchased) {
                return;
            } else {
                this.tryAddPurchasedCampaignTemplateBundle();
            }
        },

        tryAddPurchasedCampaignTemplateBundle() {
            const self = this;

            if (!self.purchasedCampaignTemplateBundleAddModelValid) {
                return;
            }

            self.purchasingCurrentBundle = true;

            self.error = '';
            self.addPurchasedCampaignTemplateBundle({ key: self.siteId, payload: self.purchasedCampaignTemplateBundleAddModel })
                .then(() => self.afterAddPurchasedCampaignTemplateBundle())
                .catch(error => {
                    self.error = ErrorHelper.getError(error)
                    self.purchasingCurrentBundle = false;
                });
        },

        afterAddPurchasedCampaignTemplateBundle() {
            const self = this;

            self.error = '';
            self.addEmailCampaignTemplatesFromPurchased(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.purchasingCurrentBundle = false);
        }
    }
}
