<template>
    <div v-if="displayRebook">
        <a @click="rebooking=!rebooking" class="btn btn-sm btn-outline">
            <span v-if="loading" class="inline-loader"></span>
            <template v-else>
                <i class="fal fa-calendar"></i>
                {{$t('Rebook')}}
            </template>
        </a>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
        <rebooker v-if="rebooking" v-model="status" :appointment="appointment"></rebooker>
    </div>
</template>

<script>
    import AppointmentRebookGroup from "./AppointmentRebookGroup.vue";
    import { mapMutations } from 'vuex';
    import moment from 'moment';
    import { AppointmentScheduleCacheMixin } from "../../../../mixins/AppointmentSchedules/AppointmentScheduleCacheMixin";

    export default {
        name: 'AppointmentRebookButton',
        mixins: [AppointmentScheduleCacheMixin],
        props: {
            appointment: Object
        },
        data() {
            return {
                rebooking: false,
                status: { appointmentOrders: [] }
            }
        },
        computed: {
            appointmentScheduleId() {
                return !!this.appointment ? this.appointment.scheduleId : '';
            },
            sequenceNumber() {
                return !!this.appointment ? this.appointment.sequenceNumber : 0;
            },
            displayRebook() {
                return !!this.appointmentSchedule && this.sequenceNumber === this.appointmentSchedule.occurrences - 1;
            },
            loading() {
                return !!this.status.loading;
            },
            completed() {
                return this.rebooking && !!this.status.completed;
            },
            appointmentOrders() {
                return this.status.appointmentOrders;
            }
        },
        watch: {
            completed(value) {
                if (!!value) {
                    this.trySetUpload();
                    this.tryDisplayAddAppointment();
                }
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerAddAppointment'
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setStartUtc',
                'setStartDate',
                'setDuration',
                'setDefault',
                'setMainPet',
                'setOrderItems',
                'setPaymentMethod',
                'setResource',
                'addAdditionalOrder',
                'setAdditionalPet'
            ]),
            trySetUpload() {
                this.setDefault();
                for (var i = 0; i < this.appointmentOrders.length; i++) {
                    const appointmentOrder = this.appointmentOrders[i];
                    if (i === 0) {
                        const appointment = appointmentOrder.appointment;
                        this.setStartUtc(moment(appointment.startUtc));
                        this.setStartDate(new Date());
                        const duration = moment(appointment.endUtc).diff(moment(appointment.startUtc), 'minutes');
                        this.setDuration(duration);
                        if (!!appointmentOrder.pet) {
                            this.setMainPet(appointmentOrder.pet);
                        }
                    } else {
                        this.addAdditionalOrder();
                        if (!!appointmentOrder.pet) {
                            this.setAdditionalPet({ index: i, pet: appointmentOrder.pet });
                        }
                    }
                    if (!!appointmentOrder.resource) {
                        this.setResource({ index: i, resource: appointmentOrder.resource });
                    }
                    if (!!appointmentOrder.order) {
                        this.setOrderItems({ index: i, items: appointmentOrder.order.orderItems });
                    }
                }
                const orders = this.appointmentOrders.map(ao => ao.order).filter(o => !!o);
                if (orders.length > 0) {
                    this.setPaymentMethod(orders[0].paymentMethod.id);
                }
            },
            tryDisplayAddAppointment() {
                this.$refs.closeModal.click();
                setTimeout(this.triggerAddAppointment, 500);
            }
        },
        components: {
            'rebooker': AppointmentRebookGroup
        }
    }
</script>
