import { Api } from '../../services/Api';

const state = () => ({
    breedCountCaches: [],
    breedCountCacheCleared: 0
})

const getters = {
    findBreedCountCache: (state) => key => state.breedCountCaches.find(bcc => bcc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.breedCountCaches.find(bcc => bcc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.breedCountCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setBreedCount(state, { key, count }) {
        const existing = state.breedCountCaches.find(bcc => bcc.key === key);
        if (!!existing) {
            existing.value = count;
            existing.loading = false;
        } else {
            state.breedCountCaches.push({ key: key, value: count, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.breedCountCaches.find(bcc => bcc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearBreedCount(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            state.breedCountCaches = state.breedCountCaches.filter(bcc => bcc.key !== key);
        }

        state.breedCountCacheCleared++;
    }
}

const actions = {
    fetchBreedCount({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Breed/Count')
                .then(response => {
                    commit('setBreedCount', { key: key, count: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
