import { Api } from "../../services/Api";

const state = () => ({
    addingSmsSettings: false,
    updatingSmsSettings: false,
    loadingSmsSettings: false,
    loadedSmsSettings: false,
    smsSettings: []
})

const mutations = {
    setSmsSettings(state, setSmsSettings) {
        state.smsSettings = setSmsSettings;
        state.loadedSmsSettings = true;
        state.loadingSmsSettings = false;
    },
    setLoading(state, value) {
        state.loadingSmsSettings = !!value;
        state.loadedSmsSettings = false;
    },
    setAdding(state, value) {
        state.addingSmsSettings = !!value;
    },
    setUpdating(state, value) {
        state.updatingSmsSettings = !!value;
    },
    appendSmsSetting(state, smsSetting) {
        state.smsSettings.push(smsSetting);
    },
    insertSmsSetting(state, smsSetting) {
        const existing = state.smsSettings.find(s => s.id === smsSetting.id);
        if (!!existing) {
            const index = state.smsSettings.indexOf(existing);
            state.smsSettings.splice(index, 1, smsSetting);
        } else {
            state.smsSettings.push(smsSetting);
        }
    }
}

const actions = {
    getSmsSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('SmsSetting')
                .then(response => {
                    commit('setSmsSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
        });
    },
    addSmsSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('SmsSetting', payload)
                .then(response => {
                    commit('appendSmsSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateSmsSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('SmsSetting', payload)
                .then(response => {
                    commit('insertSmsSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    },
    updateSmsSettingCredits({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('CreditPack/SmsSetting')
                .then(response => {
                    commit('insertSmsSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    },
    upgradeSmsSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Subscription/SmsSetting')
                .then(response => {
                    commit('setSmsSettings', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}