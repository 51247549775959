<template>
    <div>
        <button ref="displayInstructionsButton" type="button" data-target="#instructions-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="instructions-modal" title="Important Information" @closed="closed">
            <section class="rounded-card-white mb-3">
                <div class="form-group row px-2">
                    <p style="margin-bottom: 0.5rem;">Please review the following instructions carefully:</p>
                    <ul>
                        <li>If you haven't already done so, we highly recommend reading our FAQ <a href="https://shakeyourtail.com/migration.html" target="_blank" alt="Migration FAQ">[here]</a>.</li>
                        <li>Note that our subscriptions and pricing have been updated. Ensure you've selected the correct subscription (refer to FAQ for details).</li>
                        <li>The duration of the migration process may vary based on your data volume. Typically, it takes up to 25 minutes, but it could extend beyond that. We advise initiating the process only if you can keep your computer on for up to 90 minutes.</li>
                        <li>Once the migration process begins, refrain from navigating away from this page as it may disrupt the process.</li>
                        <li><b class="text-danger">WARNING:</b> All existing data in the current system will be erased and replaced with imported data.</li>
                        <li>Upon successful migration, you'll receive a confirmation message. Remember to create new user accounts for any staff members who need access to the system, and review your Email and SMS templates for accuracy.</li>
                        <li>After successful migration, your classic account and any PayPal subscription will be cancelled within 72 hours.</li>
                        <li>If any errors occur during the migration process, please contact us at <b>hello@petstar.co.uk</b> for assistance.</li>
                    </ul>
                    <label class="custom-control custom-checkbox d-flex align-items-center justify-content-center px-3 px-md-5">
                        <input v-model="agreedToTerms" type="checkbox" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                        <span class="ml-2"><b>I acknowledge and agree to the above terms. I have read the FAQ and wish to proceed with migrating my account.</b></span>
                    </label>
                    <validation-message v-if="!!validationMessage" :message="validationMessage" class="text-center px-3 px-md-5"></validation-message>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('Cancel')}}</button>
                <button @click="proceed" type="button" class="btn btn-primary">
                    Proceed
                </button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import process from 'process';
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import ModalDialog from '../../Layout/ModalDialog.vue';

    export default {
        name: 'MigrationInstructions',
        props: {
            displayInstructionsEvent: 0
        },
        data() {
            return {
                agreedToTerms: false,
                validationMessage: '',
            }
        },
        watch: {
            displayInstructionsEvent() {
                this.$refs.displayInstructionsButton.click();
            },
            agreedToTerms() {
                this.checkTerms();
            }
        },
        methods: {
            proceed() {
                this.checkTerms();
                if (!!this.agreedToTerms) {
                    process.nextTick(() => {
                        this.$emit('runMigration');
                        this.$refs.cancelButton.click();
                    });
                }
            },
            checkTerms() {
                this.validationMessage = !!this.agreedToTerms ? '' : 'Please check the box to acknowledge and agree to the above terms.'
            },
            closed() {
                this.agreedToTerms = false;
                this.validationMessage = ''
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'validationMessage': ValidationMessage
        }
    }
</script>
