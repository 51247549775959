import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { CacheHelper } from "../../helpers/CacheHelper"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const PetSearchMixinV2 = {
    data() {
        return {
            error: '',
            searchTerm: '',
            skip: 0,
            take: 10,
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters('PetSearchesV2', [
            'findPetSearch'
        ]),
        petSearchModel() {
            return {
                searchTerm: this.searchTerm,
                skip: this.skip,
                take: this.take
            }
        },
        petSearchModelValid() {
            return !!this.searchTerm;
        },
        petSearchKey() {
            return CacheHelper.objectCacheKey(this.petSearchModel);
        },
        petSearch() {
            return this.findPetSearch(this.petSearchKey);
        },
        pets() {
            return !!this.petSearch ? this.petSearch.values : [];
        },
        searchingPets() {
            return !!this.petSearch && this.petSearch.loading;
        },
        petResultsReady() {
            return !!this.petSearch && !this.searchingPets;
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshPetSearch();
            }, 500);
        }
    },
    methods: {
        ...mapMutations('PetSearchesV2', [
            'clearPetSearches'
        ]),
        ...mapActions('PetSearchesV2', [
            'searchPets'
        ]),
        refreshPetSearch() {
            if (!this.petSearch || !!this.petSearch.retry) {
                this.searchAndCachePets();
            }
        },
        searchAndCachePets() {
            const self = this;
            if (!self.petSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchPets({ key: self.petSearchKey, payload: self.petSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
