import { mapState, mapMutations } from 'vuex';
import { AllResourcesMixin } from '../Shared/Resources/AllResourcesMixin.js';
import { SiteCacheMixin } from '../Shared/SiteCacheMixin';

export const ScheduleFilterResourceMixin = {
    mixins: [SiteCacheMixin, AllResourcesMixin],
    computed: {
        ...mapState('ScheduleFilter', [
            'siteIds',
            'resourceIds'
        ]),
        selectedResources: {
            get() {
                return this.resourceIds.map(id => this.allResources.find(r => r.id === id)).filter(r => !!r);
            },
            set(value) {
                this.setResources(value);
            }
        },
        selectedSites() {
            return this.siteIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s);
        }
    },
    watch: {
        siteIds(value) {
            this.selectedResources = this.selectedResources.filter(r => value.indexOf(r.siteId) > -1);
        }
    },
    methods: {
        ...mapMutations('ScheduleFilter', [
            'setResources'
        ])
    }
}
