import { ErrorHelper } from '../../helpers/ErrorHelper';
import { UserCacheMixin } from '../../components/Shared/UserCacheMixin';
import { mapState, mapActions } from 'vuex'

export const MigrationAllowedMixin = {
    mixins: [UserCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('AccountMigrationAllowed', [
            'fetchedAccountMigrationAllowed',
            'fetchingAccountMigrationAllowed',
            'accountMigrationAllowed'
        ]),
        migrationAllowed() {
            return !!this.isSystemAdmin || (!!this.accountMigrationAllowed && !!this.fetchedAccountMigrationAllowed && !this.fetchingAccountMigrationAllowed);
        }
    },
    watch: {
        user() {
            this.refreshMigrationAllowed();
        }
    },
    methods: {
        ...mapActions('AccountMigrationAllowed', [
            'fetchAccountMigrationAllowed'
        ]),
        refreshMigrationAllowed() {
            if (!this.isSystemAdmin) {
                this.tryfetchAccountMigrationAllowed();
            }
        },
        tryfetchAccountMigrationAllowed() {
            const self = this;
            if (!!self.fetchedAccountMigrationAllowed || !!self.fetchingAccountMigrationAllowed) {
                return;
            }
            if (!self.isOwner) {
                return;
            }
            self.error = '';
            self.fetchAccountMigrationAllowed()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshMigrationAllowed();
    }
}