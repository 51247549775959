import { mapState } from 'vuex';
import { DateDisplayMixin } from './DateDisplayMixin';
import moment from 'moment-timezone';

export const DatePickerFormatterMixin = {
    mixins: [DateDisplayMixin],
    computed: {
        ...mapState('TimeZones', [
            'selectedTimeZoneId'
        ])
    },
    methods: {
        customFormatter(date) {
            const weekDay = moment(date).tz(this.selectedTimeZoneId).format('ddd');
            const formattedDate = moment.tz(date, this.selectedTimeZoneId).format(this.momentJSFormat);

            return `${weekDay}, ${formattedDate}`;
        }
    }
}
