import moment from 'moment';
import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';
import { DateDisplayMixin } from './Time/DateDisplayMixin.js';

export const HistoryCacheMixin = {
    mixins: [DateDisplayMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Histories', [
            'findHistoryCache'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        historyCache() {
            return this.findHistoryCache(this.clientId);
        },
        history() {
            return this.historyCache ? this.historyCache.value : null;
        },
        loadingHistory() {
            return this.historyCache && this.historyCache.loading;
        },
        nextAppointment() {
            return this.history && this.history.nextAppointment ?
                moment(this.history.nextAppointment).format(this.momentJSFormat) :
                'N/A';
        },
        previousAppointment() {
            return this.history && this.history.previousAppointment ?
                moment(this.history.previousAppointment).format(this.momentJSFormat) :
                'N/A';
        },
        last12months() {
            return (!!this.history ? this.history.last12Months : '0') + ` ${this.$t('appointment(s)')}`;
        },
        total() {
            return !!this.history ? this.history.total : 0;
        },
        noShows() {
            return !!this.history ? this.history.noShows : 0;
        }
    },
    watch: {
        clientId() {
            this.refreshClientHistory();
        }
    },
    methods: {
        ...mapActions('Histories', [
            'getHistory'
        ]),
        refreshClientHistory() {
            if (!this.historyCache || !!this.historyCache.retry) {
                this.tryGetClientHistory();
            }
        },
        tryGetClientHistory() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.getHistory(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientHistory();
    }
}
