<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Work tel')}}</label>
        </div>
        <div class="col-md-8">
            <input v-model="telephoneWork" class="form-control" type="tel" :placeholder="$t('Work tel')">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ClientWorkTelField',
        props: {
            value: String
        },
        computed: {
            telephoneWork: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
