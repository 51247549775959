import { Api } from "../../services/Api";

const state = () => ({
    paymentReceiptCaches: []
})

const getters = {
    findPaymentReceiptCache: (state) => key => state.paymentReceiptCaches.find(pr => pr.key === key)
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.paymentReceiptCaches.find(pr => pr.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.paymentReceiptCaches.push({ key: key, value: null, loading: !!loading });
            }
        }
    },
    setPaymentReceipt(state, { key, paymentReceipt }) {
        const existing = state.paymentReceiptCaches.find(pr => pr.key === key);
        if (!!existing) {
            existing.value = paymentReceipt;
            existing.loading = false;
        } else {
            state.paymentReceiptCaches.push({ key: key, value: paymentReceipt, loading: false });
        }
    },
    setPaymentReceipts(state, { paymentReceipts }) {
        for (var i = 0; i < paymentReceipts.length; i++) {
            const paymentReceipt = paymentReceipts[i]
            const existing = state.paymentReceiptCaches.find(pr => pr.key === paymentReceipt.subscriptionPaymentId);
            if (!!existing) {
                existing.value = paymentReceipt;
                existing.loading = false;
            } else {
                state.paymentReceiptCaches.push({ key: paymentReceipt.subscriptionPaymentId, value: paymentReceipt, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.paymentReceiptCaches.find(pr => pr.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    }
}

const actions = {
    addPaymentReceipt({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [id], loading: true });
            Api.post('PaymentReceipt/' + id)
                .then(response => {
                    commit('setPaymentReceipt', { key: id, paymentReceipt: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', { keys: [id] });
                    reject(error);
                })
        });
    },
    searchPaymentReceipts({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            Api.post('PaymentReceipt/Search', payload)
                .then(response => {
                    commit('setPaymentReceipts', { paymentReceipts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', {keys: keys});
                    reject(error);
                })
                .finally(() => commit('setLoading', { keys: keys, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
