<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse2" data-toggle="collapse">
            {{$t('Total')}}
            <div class="total-figure ml-auto">
                {{totalString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse2">
            <div class="card card-body">
                <div v-if="noOrders" class="alert-wrapper px-0">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t("This booking does not include any orders, it's solely for scheduling an appointment.")}}</p>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <order-item v-for="(participant, index) in bookingParticipants" :participant="participant" :key="participant.id" :index="index"></order-item>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { AppointmentScheduleOrdersAddMixin } from "../Shared/AppointmentScheduleOrdersAddMixin";
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import BookingAddAppointmentScheduleOrdersItem from "./BookingAddAppointmentScheduleOrdersItem.vue";

    export default {
        name: 'BookingAddAppointmentScheduleOrders',
        mixins: [AppointmentScheduleOrdersAddMixin, BookingCacheMixin, CountryCacheMixin, StatusEmitterMixin],
        props: {
            id: '',
            participants: []
        },
        data() {
            return {
                addedScheduleId: '',
                addedOrders: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'approveBookingEvent',
                'appointmentScheduleAddedEvent',
            ]),
            bookingId() {
                return !!this.id ? this.id : '';
            },
            scheduleId() {
                return !!this.addedScheduleId ? this.addedScheduleId : '';
            },
            totalString() {
                let total = 0;
                this.bookingParticipants.forEach(participant => {
                    total += participant.serviceItems.length > 0 ? participant.serviceItems.reduce((sum, item) => {
                        return sum + item.amount;
                    }, 0) : 0;
                });
                return `${this.currencySymbol} ${total.toFixed(2).toString()}`;
            },
            noOrders() {
                return !!this.bookingParticipants ? this.bookingParticipants.every(participant => participant.serviceItems.length === 0) ? true : false : false;
            },
            appointmentOrders() {
                return this.participants.length > 0 ? this.participants.map((participant) => {
                    return {
                        paymentMethodId: 0,
                        petId: participant.petId,
                        orderItems: participant.serviceItems
                    }
                }) : []
            },
            appointmentScheduleOrdersAddModel() {
                return {
                    scheduleId: this.addedScheduleId,
                    appointmentOrders: this.appointmentOrders
                }
            },
            completed() {
                return !!this.addedOrders;
            },
            loading() {
                return !!this.loadingAppointmentScheduleOrders;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canAddOrder() {
                return !this.addedOrders && !!this.addedScheduleId && this.participants.length > 0;
            }
        },
        watch: {
            participants(value) {
                if (value.length > 0) {
                    this.onValidatedChanged();
                }
            },
            appointmentScheduleAddedEvent(schedule) {
                this.addedScheduleId = schedule.id
                this.onValidatedChanged();
            },
            approveBookingEvent() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddOrder || !!this.loading) {
                    return;
                }
                this.tryAddAppointmentScheduleOrders();
            },
            afterAddAppointmentScheduleOrders() {
                this.addedOrders = true;
            }
        },
        components: {
            'order-item': BookingAddAppointmentScheduleOrdersItem
        }
    }
</script>
