<template>
    <modal-dialog :title="breedName" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="deleting" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{$t('Are you sure you want to delete this breed?')}}</p>
            </div>
            <button @click="confirmDelete" v-bind:disabled="loading" type="button" class="btn btn-danger" style="margin-right: 10px;">
                {{$t('Yes')}}
            </button>
            <button @click="deleting=false" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <section class="rounded-card-white mb-3">
            <name-input v-model="name" :validator="breedValidator"></name-input>
            <species-select v-model="speciesId" :validator="breedValidator"></species-select>
            <product-update v-model="loadingProduct" :id="breedId"></product-update>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <delete-button @clicked="deleting=true"></delete-button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import { BreedUpdateMixin } from '../Shared/BreedUpdateMixin';
    import BreedNameInput from './BreedNameInput.vue';
    import BreedUpdateProduct from './BreedUpdateProduct.vue';
    import BreedSpeciesSelect from './BreedSpeciesSelect.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BreedUpdate',
        mixins: [BreedUpdateMixin],
        data() {
            return {
                deleting: false,
                loadingProduct: false
            }
        },
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveBreedEvent',
                'breedDeletedEvent'
            ]),
            breedId() {
                return this.id;
            },
            breedName() {
                return !!this.breed ? this.breed.name : '';
            },
            loading() {
                return this.loadingBreed || this.loadingProduct;
            }
        },
        watch: {
            breedDeletedEvent() {
                this.$refs.closeModal.click();
            },
            saveBreedEvent() {
                this.tryUpdateBreed();
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveBreed'
            ]),
            save() {
                this.triggerSaveBreed();
            },
            confirmDelete() {
                this.tryDeleteBreed();
            },
            onOpened() {
                this.deleting = false;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'name-input': BreedNameInput,
            'species-select': BreedSpeciesSelect,
            'product-update': BreedUpdateProduct,
            'delete-button': DeleteButton,
            'error-banner': ErrorBanner
        }
    }
</script>
