<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Preference')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select custom-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Select staff">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{resource.name}}</div>
                        </div>
                    </div>
                </button>
                <div class="dropdown-menu ">
                    <div class="bs-searchbox">
                        <input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list">
                    </div>
                    <div class="inner show" role="listbox" id="bs-select-3" tabindex="-1">
                        <ul class="dropdown-menu inner show" role="presentation">
                            <li v-for="r in resources" :key="r.id">
                                <a @click="resource=r" role="option" class="dropdown-item" v-bind:class="{ 'active selected': r.id===resource.id }" id="bs-select-1-0">
                                    {{r.name}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { PetPreferredSettingUploadMixin } from "../../../../mixins/Settings/Pet/PetPreferredSettingUploadMixin";
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";
    import { ResourceCacheMixin } from "../../../Shared/Resources/ResourceCacheMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'PetDetailsPreferred',
        mixins: [PetPreferredSettingUploadMixin, ResourceCacheMixin, StatusEmitterMixin],
        props: {
            petId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent'
            ]),
            ownerId() {
                return this.petId;
            },
            status() {
                return {
                    loading: this.loadingResources || this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            savePetEvent() {
                this.tryUploadSetting();
            }
        }
    }
</script>
