<template>

</template>

<script>
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { CampaignSettingAddMixin } from '../../Communication/Settings/CampaignSettingAddMixin';

    export default {
        name: 'PostRegisterCampaignSetting',
        mixins: [CampaignSettingAddMixin, StatusEmitterMixin],
        computed: {
            canAddCampaignSetting() {
                return !!this.loadedCampaignSettings && !this.campaignSetting && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingCampaignSetting,
                    completed: !!this.campaignSetting
                }
            }
        },
        watch: {
            canAddCampaignSetting() {
                this.tryUploadCampaignSetting();
            }
        },
        methods: {
            tryUploadCampaignSetting() {
                if (this.canAddCampaignSetting) {
                    this.tryAddCampaignSetting();
                }
            }
        },
        created() {
            this.tryUploadCampaignSetting();
        }
    }
</script>
