import { ErrorHelper } from '../../helpers/ErrorHelper';
import { Api } from '../../services/Api';

const state = () => ({
    clientOnboardingInviteCaches: []
})

const getters = {
    findClientOnboardingInviteCache: (state) => (key) => {
        return state.clientOnboardingInviteCaches.find(coic => coic.key === key);
    }
}

const mutations = {
    setClientOnboardingInvites(state, { key, invite }) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.value = invite;
            existing.loading = false;
        } else {
            state.clientOnboardingInviteCaches.push({ key: key, value: invite, loading: false });
        }
    },
    setLoading(state, { key, loading }) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientOnboardingInviteCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendClientOnboardingInvite(state, { key, invite }) {
        const existing = state.clientOnboardingInviteCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.value = invite;
        } else {
            state.clientOnboardingInviteCaches.push({ key: key, value: invite });
        }
    },
}

const actions = {
    fetchClientOnboardingInvite({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: clientId, loading: true });
            Api.get('OnboardingInvite?clientId=' + clientId)
                .then(response => {
                    commit('setClientOnboardingInvites', { key: clientId, invite: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: clientId, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', clientId);
                        reject(error);
                    }
                });
        });
    },
    addClientOnboardingInvite({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('OnboardingInvite', payload)
                .then(response => {
                    commit('appendClientOnboardingInvite', { key: key, invite: response.data });
                    commit('ApplicationEvents/triggerClientOnboardingInviteAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
