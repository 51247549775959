<template>
    <tr class="link pointer" v-bind:class="classType" @click="onClick">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a class="text-truncate">{{breedName}}</a>
            </div>
        </td>
        <td>{{$t(speciesName)}}</td>
        <td class="text-right">{{productString}}</td>
    </tr>
</template>

<script>
    import { BreedItemMixin } from "./BreedItemMixin";

    export default {
        name: 'BreedItemDesktop',
        mixins: [BreedItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
