import { Api } from '../../services/Api';

const state = () => ({
    fetchingCommunicationTypes: false,
    fetchedCommunicationTypes: false,
    communicationTypes: []
})

const mutations = {
    setFetching(state) {
        state.fetchingCommunicationTypes = true;
    },
    setCommunicationTypes(state, communicationTypes) {
        state.communicationTypes = communicationTypes;
        state.fetchedCommunicationTypes = true;
        state.fetchingCommunicationTypes = false;
    },
    setFailed(state) {
        state.communicationTypes = [];
        state.fetchedCommunicationTypes = false;
        state.fetchingCommunicationTypes = false;
    }
}

const actions = {
    fetchCommunicationTypes({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Communication/Type')
                .then(response => {
                    commit('setCommunicationTypes', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
