import { mapActions, mapState } from "vuex";
import { ContractHelper } from "../../helpers/ContractHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const ContractTemplateAddMixin = {
    data() {
        return {
            error: '',
            templateTypeName: '',
            html: '',
            requiresSignature: false,
            contractTemplateAddValidator: ContractHelper.getTemplateAddValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'contractTemplateAddedEvent'
        ]),
        ...mapState('ContractTemplates', [
            'uploadingContractTemplate'
        ]),
        contractTemplateAddModel() {
            return {
                templateTypeName: this.templateTypeName,
                html: this.html,
                requiresSignature: this.requiresSignature
            }
        },
        contractTemplateAddModelValid() {
            return this.contractTemplateAddValidator.isValid;
        }
    },
    watch: {
        contractTemplateAddedEvent() {
            this.refreshContractTemplateModel();
        }
    },
    methods: {
        ...mapActions('ContractTemplates', [
            'addContractTemplate'
        ]),
        refreshContractTemplateModel() {
            this.templateTypeName = '';
            this.html = '';
            this.requiresSignature = false;
        },
        tryAddContractTemplate() {
            const self = this;
            self.error = '';
            self.contractTemplateAddValidator.check(self.contractTemplateAddModel);
            if (!self.contractTemplateAddModelValid) {
                return;
            }
            self.addContractTemplate(self.contractTemplateAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
