<template>
    <li v-if="allowed">
        <a aria-expanded="false" class="dropdown-toggle" data-toggle="collapse" :href="'#pageSubmenu' + id">
            <i :class="iconClass"></i>
            <span class="nav-link-text ml-1 d-md-flex">
                {{$t(menuName)}}
                <i v-if="showIndicator" class="fas fa-circle red-dot-indicator"></i>
            </span>
        </a>
        <ul class="collapse submenu" :id="'pageSubmenu' + id">
            <slot></slot>
        </ul>
    </li>
</template>

<script>
    import { UserCacheMixin } from "../Shared/UserCacheMixin";
    import { PaymentApiUserCacheMixin } from "../../mixins/Payments/PaymentApiUserCacheMixin";

    export default {
        name: 'NavPanelExpando',
        mixins: [UserCacheMixin, PaymentApiUserCacheMixin],
        props: {
            id: Number,
            menuName: String,
            iconClass: String,
            showIndicator: false
        },
        data() {
            return {
                employeesAllowed: [
                    'Notifications',
                    'Account'
                ]
            }
        },
        computed: {
            isPaymentOwner() {
                return this.ownerAccounts.length > 0;
            },
            isPaymentAdmin() {
                return this.isPaymentOwner || this.adminAccounts.length > 0;
            },
            allowed() {
                if (this.menuName === 'Payments') {
                    return this.isOwner || this.isPaymentAdmin;
                }
                return this.isOwner || this.isSiteAdmin || this.employeesAllowed.indexOf(this.menuName) > -1 ;
            }
        }
    }
</script>
