<template>
    <div class="login-wrapper">
        <div class="row flex-grow-1">
            <div class="col-md-6 d-md-flex align-items-center">
                <div class="login-form-wrap">
                    <img class="logo" alt="Shake Your Tail" src="../../assets/images/logo-colour-stacked.svg" width="168">
                    <router-view></router-view>
                </div>
            </div>
            <div class="col-md-6 login-bg-desktop" v-bind:class="{ 'login-bg-desktop-forgot': showForgotten }">
            </div>
        </div>
    </div>
</template>

<script>
    import '../../assets/css/login.min.css';
    import { SiteHelper } from '../../helpers/SiteHelper';

    export default {
        name: 'Login',
        computed: {
            showForgotten() {
                const name = this.$route.name;
                return name === 'forgot'
                    || name === 'passwordReset';
            }
        },
        created() {
            SiteHelper.clearCookie();
        }
    }
</script>

<style>
    .app-body {
        flex-direction: row;
    }
</style>
