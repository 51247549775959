<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Add client')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav header-nav-left">
                <a @click="cancel" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <notification-bell></notification-bell>
            </div>
        </header>
        <div class="alert-wrapper">
            <div class="alert alert-info alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{$t('After adding a new client and saving, you will then be able to add their pet(s)')}}</p>
                </div>
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <div class="alert-wrapper">
            <error-banner v-if="errors" :message="errors"></error-banner>
        </div>
        <div class="content fixed-action-btns-visible">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-md-8">
                            <client-details v-model="clientDetailsStatus"></client-details>
                            <collapse-section id="alt-contact" :title="$t('Alternative Contact')">
                                <client-alt-contact></client-alt-contact>
                            </collapse-section>
                            <collapse-section id="vet-contact" :title="$t('Vet Details')">
                                <client-vet-contact></client-vet-contact>
                            </collapse-section>
                        </div>
                        <div class="col-md-4">
                            <client-notes></client-notes>
                            <collapse-section id="notification-settings" :is-collapsed="false" :title="$t('Notification Settings')">
                                <client-notification-settings v-model="notificationSettingsStatus"></client-notification-settings>
                            </collapse-section>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
                <div class="d-flex">
                    <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                        {{$t('Save')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../../routing';
    import process from 'process';
    import { mapState, mapMutations } from 'vuex';
    import ClientAltContactSection from './ClientAltContactSection.vue';
    import ClientVetContactSection from './ClientVetContactSection.vue';
    import AddClientNotesSection from './AddClientNotesSection.vue';
    import ClientAddDetailsSection from './ClientAddDetailsSection.vue';
    import NotificationBell from '../Shared/Notifications/NotificationBell.vue';
    import ClientAddNotificationSettings from './ClientAddNotificationSettings.vue';
    import CollapsedStaticSection from '../Shared/Layout/CollapsedStaticSection.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'ClientAdd',
        data() {
            return {
                savingType: 0,
                addedClientId: '',
                clientDetailsStatus: {},
                notificationSettingsStatus: {}
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            computedFeatureKey() {
                return 'clientOnboarding';
            },
            statuses() {
                return [
                    this.clientDetailsStatus,
                    this.notificationSettingsStatus
                ];
            },
            loading() {
                return this.statuses.some(s => !!s.loading);
            },
            completed() {
                return this.statuses.every(s => !!s.completed);
            },
            errors() {
                const statusErrors = this.statuses.map(s => s.error).filter(error => !!error);
                return statusErrors.join(' ');
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            },
            completed(value) {
                if (!!value) {
                    process.nextTick(() => {
                        router.push({ name: 'clientEdit', params: { id: this.addedClientId } });
                    });
                }
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerAddClient'
            ]),
            cancel() {
                router.go(-1);
            },
            save() {
                this.triggerAddClient({ savingType: this.savingType });
            }
        },
        components: {
            'client-details': ClientAddDetailsSection,
            'client-notes': AddClientNotesSection,
            'client-notification-settings': ClientAddNotificationSettings,
            'client-alt-contact': ClientAltContactSection,
            'client-vet-contact': ClientVetContactSection,
            'notification-bell': NotificationBell,
            'collapse-section': CollapsedStaticSection,
            'error-banner': ErrorBanner
        }
    }
</script>
