<template>
    <tr v-bind:class="classType">
        <td>{{datestamp}}</td>
        <td>{{timestamp}}</td>
        <td v-bind:style="{ color: colour }">
            <template v-if="!!level">
                <i v-if="level === 'Warning' " class="far fa-warning"></i>
                <i v-else class="far fa-info-circle"></i>
            </template>
            {{message}}
        </td>
    </tr>
</template>

<script>
    import { ClientLogItemMixin } from "../../../mixins/ClientLogs/ClientLogItemMixin";

    export default {
        name: 'ClientLogPreviewItem',
        mixins: [ClientLogItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
