<template>
    <section class="rounded-card-white mb-3">
        <div id="appointment-type-grooming-fields">
            <div v-if="sites.length > 1" class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Site')}}</label>
                </div>
                <div class="col-md-8">
                    <site-select :disabled="true"></site-select>
                </div>
            </div>
            <pet-select></pet-select>
            <order-item-select :index="0"></order-item-select>
            <resource-select :index="0"></resource-select>
            <date-select></date-select>
            <additional-pets></additional-pets>
        </div>
    </section>
</template>

<script>
    import AppointmentPetSelect from './AppointmentPetSelect.vue';
    import AppointmentOrderItemSelect from './AppointmentOrderItemSelect.vue';
    import AppointmentResourceSelect from './AppointmentResourceSelect.vue';
    import AppointmentDateSelect from './AppointmentDateSelect.vue';
    import AppointmentAdditionalPets from './AppointmentAdditionalPets.vue';
    import SiteSelect from '../../Account/SiteSelect.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'AppointmentDetailsGrooming',
        computed: {
            ...mapState('Sites', [
                'sites'
            ])
        },
        components: {
            'pet-select': AppointmentPetSelect,
            'order-item-select': AppointmentOrderItemSelect,
            'resource-select': AppointmentResourceSelect,
            'date-select': AppointmentDateSelect,
            'additional-pets': AppointmentAdditionalPets,
            'site-select': SiteSelect
        }
    }
</script>