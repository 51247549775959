<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Behaviour')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{$t(selectedOption.name)}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setBehaviour(option.value)"
                            v-bind:class="{'active': option.value === selectedOption.value}">
                            {{$t(option.name)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PetDetailsBehaviour',
        props: {
            value: Number
        },
        data() {
            return {
                options: [
                    { value: 0, name: 'Unknown' },
                    { value: 1, name: 'Good' },
                    { value: 2, name: 'Naughty' },
                    { value: 3, name: 'Handle with care' }
                ],
                behaviourId: 0
            }
        },
        computed: {
            selectedOption() {
                const option = this.options.find(o => o.value === this.behaviourId);
                return !!option ? option : this.options[0];
            }
        },
        methods: {
            setBehaviour(value) {
                this.behaviourId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setBehaviour(this.value);
        }
    }
</script>
