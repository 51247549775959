import { Api } from '../../services/Api';

const state = () => ({
    contractCaches: []
})

const getters = {
    findContractCache: (state) => (key) => {
        return state.contractCaches.find(cc => cc.key === key);
    }
}

const mutations = {
    setContracts(state, { key, contracts }) {
        const existing = state.contractCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = contracts;
            existing.loading = false;
        } else {
            state.contractCaches.push({ key: key, values: contracts, loading: false });
        }
    },
    setLoading(state, { key, loading }) {
        const existing = state.contractCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.contractCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.contractCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendContract(state, { key, value }) {
        const existing = state.contractCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values.push(value);
        } else {
            state.contractCaches.push({ key: key, values: [value] });
        }
    },
    removeContract(state, { key, id }) {
        const existing = state.contractCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(c => c.id !== id);
        }
    }
}

const actions = {
    searchContracts({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: clientId, loading: true });
            Api.get('Contract?clientId=' + clientId)
                .then(response => {
                    commit('setContracts', { key: clientId, contracts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', clientId);
                    reject(error);
                });
        });
    },
    addContract({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Contract', payload)
                .then(response => {
                    commit('appendContract', { key: key, value: response.data });
                    commit('ApplicationEvents/triggerContractAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        })
    },
    deleteContract({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('Contract/' + id)
                .then(response => {
                    commit('removeContract', { key: key, id: id });
                    commit('ApplicationEvents/triggerContractDeleted', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
