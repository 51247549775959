import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const PaymentReceiptCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentReceipts', [
            'findPaymentReceiptCache'
        ]),
        subscriptionPaymentId() {
            return console.log("!!!OVERRIDE COMPUTED - subscriptionPaymentId!!!")
        },
        paymentReceiptCache() {
            return this.findPaymentReceiptCache(this.subscriptionPaymentId);
        },
        paymentReceipt() {
            return !!this.paymentReceiptCache ? this.paymentReceiptCache.value : null;
        },
        loadingPaymentReceipt() {
            return !!this.paymentReceiptCache && this.paymentReceiptCache.loading;
        }
    },
    methods: {
        ...mapActions('PaymentReceipts', [
            'addPaymentReceipt'
        ]),
        refreshPaymentReceipt() {
            if (!this.paymentReceipt || !!this.paymentReceipt.retry) {
                this.tryAddPaymentReceipt();
            }
        },
        tryAddPaymentReceipt() {
            const self = this;
            if (!self.subscriptionPaymentId) {
                return;
            }
            self.error = '';
            self.addPaymentReceipt(self.subscriptionPaymentId)
                .then(response => self.afterPaymentReceiptAdded(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterPaymentReceiptAdded() {
            // override if necessary
        }
    }
}
