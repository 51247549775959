import { mapState, mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SiteIdMixin } from './Site/SiteIdMixin';

export const BookingTermCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingTerms', [
            'bookingTerms'
        ]),
        ...mapGetters('BookingTerms', [
            'findBookingTerm'
        ]),
        bookingTermCache() {
            return this.findBookingTerm(this.siteId);
        },
        bookingTerm() {
            return !!this.bookingTermCache ? this.bookingTermCache.value : null;
        },
        bookingTermVersion() {
            return !!this.bookingTerm ? this.bookingTerm.version : 0;
        },
        loadingBookingTerm() {
            return !!this.bookingTermCache && this.bookingTermCache.loading;
        },
        retryBookingTerm() {
            return !!this.bookingTermCache && this.bookingTermCache.retry;
        }
    },
    watch: {
        siteId() {
            this.refreshBookingTerm();
        }
    },
    methods: {
        ...mapActions('BookingTerms', [
            'fetchBookingTerm'
        ]),
        refreshBookingTerm() {
            if (!this.bookingTermCache || !!this.retryBookingTerm) {
                this.tryFetchBookingTerm();
            }
        },
        tryFetchBookingTerm() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchBookingTerm(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingTerm();
    }
}
