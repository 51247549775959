import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapState, mapActions } from 'vuex';

export const UserSettingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('UserSettings', [
            'adding',
            'updating',
            'fetching',
            'fetched',
            'attempted',
            'userSettings'
        ]),
        defaultUserSettingValue() {
            console.log('!!!OVERRIDE COMPUTED - defaultUserSettingValue!!!');
        },
        userSettingKey() {
            console.log('!!!OVERRIDE COMPUTED - userSettingKey!!!');
        },
        userSetting() {
            return this.userSettings.find(s => s.key === this.userSettingKey);
        },
        userSettingValue() {
            return Object.assign({}, this.defaultUserSettingValue, (!!this.userSetting ? JSON.parse(this.userSetting.value) : null));
        },
        loadingUserSettings() {
            return this.fetching || this.adding || this.updating;
        }
    },
    methods: {
        ...mapActions('UserSettings', [
            'getUserSettings'
        ]),
        refreshUserSetting() {
            if (!this.fetched && !this.fetching) {
                this.tryGetUserSettings();
            }
        },
        tryGetUserSettings() {
            const self = this;
            self.getUserSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUserSetting();
    }
}
