import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentApiUserSearchMixin } from "./PaymentApiUserSearchMixin";
import { mapActions } from 'vuex';

export const PaymentApiUserClaimUpdateMixin = {
    mixins: [PaymentApiUserSearchMixin],
    data() {
        return {
            adminAccounts: [],
            refunderAccounts: []
        }
    },
    computed: {
        paymentApiUserClaimModel() {
            return Object.assign({}, this.paymentApiUserSearchModel, {
                admin: this.adminAccounts,
                refunder: this.refunderAccounts
            });
        },
        paymentApiUserClaimModelModified() {
            if (!this.paymentApiUser) {
                return true;
            }
            const model = this.paymentApiUserClaimModel;
            return JSON.stringify(model.admin.sort().join('')) !== JSON.stringify(this.paymentApiUser.admin.sort().join(''))
                || JSON.stringify(model.refunder.sort().join('')) !== JSON.stringify(this.paymentApiUser.refunder.sort().join(''));
        }
    },
    watch: {
        paymentApiUser() {
            this.refreshPaymentApiUserClaimModel();
        }
    },
    methods: {
        ...mapActions('PaymentApiUserSearches', [
            'updatePaymentApiUserClaims'
        ]),
        refreshPaymentApiUserClaimModel() {
            this.adminAccounts = !!this.paymentApiUser ? [...this.paymentApiUser.admin] : [];
            this.refunderAccounts = !!this.paymentApiUser ? [...this.paymentApiUser.refunder] : [];
        },
        tryUpdatePaymentApiUserClaims() {
            const self = this;
            if (!self.userId || !self.paymentApiUserClaimModelModified) {
                return;
            }
            self.error = '';
            self.updatePaymentApiUserClaims({ userId: self.userId, payload: self.paymentApiUserClaimModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentApiUserClaimModel();
    }
}
