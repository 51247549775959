<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="success" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>
                    Your password has been successfully changed!
                </p>
            </div>
        </div>
        <template v-else>
            <fieldset>
                <div class="input-group form-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fal fa-lock-alt"></i></span>
                    </div>
                    <input v-model="password" type="password" class="form-control" placeholder="Please enter new password" aria-label="Password" required="">
                </div>
                <div class="form-group mt-4">
                    <button @click="submit" class="btn btn-primary w-100" :disabled="disabled">
                        Reset password
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </fieldset>
        </template>
        <div class="row bottom-links">
            <div class="col">
                <router-link :to="{ name: 'auth' }">Back to login?</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import { PasswordSetMixin } from "./PasswordSetMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'PasswordReset',
        mixins: [PasswordSetMixin],
        methods: {
            submit() {
                const self = this;
                self.error = '';
                self.success = false;
                self.loading = true;
                Api.post('Credentials/Reset', self.uploadModel)
                    .then(() => self.success = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>