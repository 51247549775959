<template>
    <div class="col">
        <div class="row">
            <div class="col-md-8">
                <pet-details v-model="detailsStatus" :client="client" :pet-id="petId"></pet-details>
                <pet-appointments v-model="appointmentsStatus" :pet="pet"></pet-appointments>
                <collapsed-section id="pet-weight" :title="$t('Weight')">
                    <pet-weight v-model="weightsStatus" :pet="pet"></pet-weight>
                </collapsed-section>
                <collapsed-section id="pet-images" :title="$t('Images')">
                    <pet-images v-model="imagesStatus" :pet="pet"></pet-images>
                </collapsed-section>
            </div>
            <div class="col-md-4">
                <pet-product v-model="productStatus" :pet="pet"></pet-product>
                <pet-duration v-model="petDurationStatus" :pet="pet"></pet-duration>
                <pet-notes-cut v-model="cutNotesStatus" :pet-id="petId"></pet-notes-cut>
                <pet-notes v-model="generalNotesStatus" :pet-id="petId"></pet-notes>
                <pet-notes-medical v-model="medicalNotesStatus" :pet-id="petId"></pet-notes-medical>
            </div>
        </div>
    </div>
</template>

<script>
    import PetNotesSection from './PetNotesSection.vue';
    import PetNotesCutSection from './PetNotesCutSection.vue';
    import PetNotesMedicalSection from './PetNotesMedicalSection.vue';
    import PetImagesSection from './PetImagesSection.vue';
    import PetProductSection from './PetProductSection.vue';
    import PetWeightSection from './Weight/PetWeightSection.vue';
    import PetDuration from './Durations/PetDuration.vue';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import PetDetailsSection from './PetEdit/PetDetails/PetDetailsSection.vue';
    import CollapsedSection from '../Shared/Layout/CollapsedSection.vue';
    import PetAppointmentsSectionV2 from './Appointments/PetAppointmentsSectionV2.vue';

    export default {
        name: 'PetEdit',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                detailsStatus: {},
                appointmentsStatus: {},
                weightsStatus: {},
                imagesStatus: {},
                productStatus: {},
                petDurationStatus: {},
                cutNotesStatus: {},
                generalNotesStatus: false,
                medicalNotesStatus: false
            }
        },
        props: {
            client: Object,
            pet: Object
        },
        computed: {
            statuses() {
                return [
                    this.detailsStatus,
                    this.appointmentsStatus,
                    this.weightsStatus,
                    this.imagesStatus,
                    this.productStatus,
                    this.petDurationStatus,
                    this.generalNotesStatus,
                    this.cutNotesStatus,
                    this.medicalNotesStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            },
            petId() {
                return !!this.pet ? this.pet.id : '';
            }
        },
        components: {
            'pet-details': PetDetailsSection,
            'pet-notes': PetNotesSection,
            'pet-notes-cut': PetNotesCutSection,
            'pet-notes-medical': PetNotesMedicalSection,
            'pet-weight': PetWeightSection,
            'pet-images': PetImagesSection,
            'pet-appointments': PetAppointmentsSectionV2,
            'pet-product': PetProductSection,
            'pet-duration': PetDuration,
            'collapsed-section': CollapsedSection
        }
    }
</script>
