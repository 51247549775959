import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const SmsDefaultCacheMixin = {
    data() {
        return {
            error: '',
            smsTemplateAttempted: false
        }
    },
    computed: {
        ...mapGetters('SmsDefaults', [
            'findSmsDefaultCache'
        ]),
        communicationTypeId() {
            console.log("OVERRIDE COMPUTED --- Communication Type ID!");
        },
        loadedSmsTemplate() {
            console.log("OVERRIDE COMPUTED --- loadedSmsTemplate!");
        },
        loading() {
            console.log("OVERRIDE COMPUTED --- loading!");
        },
        smsDefaultCache() {
            return this.findSmsDefaultCache(this.communicationTypeId);
        },
        smsDefault() {
            return !!this.smsDefaultCache ? this.smsDefaultCache.value : null;
        },
        smsDefaultValue() {
            return !!this.smsDefault & !this.loading ? this.smsDefault.value : '';
        },
        loadingSmsDefault() {
            return !!this.smsDefaultCache && this.smsDefaultCache.loading;
        },
        canFetchDefaultTemplate() {
            return !!this.smsTemplateAttempted && !this.loadedSmsTemplate 
        }
    },
    watch: {
        communicationTypeId() {
            this.refreshSmsDefault();
        },
        canFetchDefaultTemplate(value) {
            if (!!value) {
                this.refreshSmsDefault();
            }
        }
    },
    methods: {
        ...mapActions('SmsDefaults', [
            'findSmsDefault'
        ]),
        refreshSmsDefault() {
            if (!this.smsDefaultCache || !!this.smsDefaultCache.retry || !this.canFetchDefaultTemplate) {
                this.tryfindSmsDefault();
            }
        },
        tryfindSmsDefault() {
            const self = this;
            if (!self.communicationTypeId) {
                return;
            }
            self.error = '';
            self.findSmsDefault(self.communicationTypeId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsDefault();
    }
}
