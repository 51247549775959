import { Api } from '../../services/Api';

const state = () => ({
    emailTemplateTagCaches: []
})

const getters = {
    getEmailTemplateTagCache: state => key => {
        return state.emailTemplateTagCaches.find(ettc => ettc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.emailTemplateTagCaches.find(ettc => ettc.key === key);
        if (!!existing) {
            existing.retry = false;
            existing.loading = !!loading;
        } else {
            state.emailTemplateTagCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setTags(state, { key, tags }) {
        const existing = state.emailTemplateTagCaches.find(ettc => ettc.key === key);
        if (!!existing) {
            existing.values = tags;
            existing.retry = false;
            existing.loading = false;
        } else {
            state.emailTemplateTagCaches.push({ key: key, values: tags, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.emailTemplateTagCaches.find(ettc => ettc.key === key);
        if (!!existing) {
            existing.values = [];
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findEmailTemplateTags({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('EmailMarketingTemplate/Tag?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setTags', { key: key, tags: response.data.tags });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
