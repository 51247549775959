import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const AppointmentDefaultsSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'AppointmentDefaults';
        },
        defaultSettingObject() {
            return {
                petDurationMinutes: 120
            }
        }
    }
}
