<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>
                {{$t('Portal Banner')}}
                <tooltip>
                    <span>{{$t('Customize the banner image on the booking portal.')}}</span>
                </tooltip>
            </label>
        </div>
        <div class="col-md-8">
            <image-delete v-if="image" v-model="bookingPortalImageStatus"></image-delete>
            <image-add v-else v-model="bookingPortalImageStatus"></image-add>
        </div>
    </div>
</template>

<script>
    import { BookingPortalImageCacheMixin } from "../../../mixins/Settings/Site/BookingPortalImageCacheMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import SiteUpdateBookingPortalImageAdd from "./SiteUpdateBookingPortalImageAdd.vue";
    import SiteUpdateBookingPortalImageDelete from "./SiteUpdateBookingPortalImageDelete.vue";
    import Tooltip from "../../Shared/Layout/Tooltip.vue";

    export default {
        name: 'SiteUpdateBookingPortalImage',
        mixins: [BookingPortalImageCacheMixin, StatusEmitterMixin],
        data() {
            return {
                bookingPortalImageStatus: {}
            }
        },
        computed: {
            status() {
                return {
                    loading: !!this.bookingPortalImageStatus.loading,
                    error: this.error || this.bookingPortalImageStatus.error
                }
            }
        },
        components: {
            'image-add': SiteUpdateBookingPortalImageAdd,
            'image-delete': SiteUpdateBookingPortalImageDelete,
            'tooltip': Tooltip
        }
    }
</script>
