import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    petDurationCaches: []
})

const getters = {
    findPetDurationCache: (state) => key => state.petDurationCaches.find(pdc => pdc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.petDurationCaches.find(pdc => pdc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.petDurationCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setPetDuration(state, { key, petDuration }) {
        const existing = state.petDurationCaches.find(pdc => pdc.key === key);
        if (!!existing) {
            existing.value = petDuration;
            existing.loading = false;
        } else {
            state.petDurationCaches.push({ key: key, value: petDuration, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.petDurationCaches.find(pdc => pdc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchPetDuration({ commit }, petId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            Api.get('Pet/' + petId + '/Duration')
                .then(response => {
                    commit('setPetDuration', { key: petId, petDuration: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: petId, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', petId);
                        reject(error);
                    }
                })
        });
    },
    addPetDuration({ commit }, { petId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            Api.post('PetDuration', payload)
                .then(response => {
                    commit('setPetDuration', { key: petId, petDuration: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: petId, loading: false }));
        });
    },
    updatePetDuration({ commit }, { petId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            Api.put('PetDuration', payload)
                .then(response => {
                    commit('setPetDuration', { key: petId, petDuration: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: petId, loading: false }));
        });
    },
    deletePetDuration({ commit }, petId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            Api.delete('PetDuration/' + petId)
                .then(response => {
                    commit('setPetDuration', { key: petId, petDuration: null });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: petId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
