import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const BookingSuggestionCommunicationCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BookingSuggestionCommunications', [
            'findCommunicationsCache'
        ]),
        subjectId() {
            console.log('!!!OVERRIDE COMPUTED - subjectId!!!');
        },
        communicationSearchModel() {
            return {
                clientId: 'Online Booking',
                communicationTypeId: 16,
                methodTypeId: 1,
                subjectId: this.subjectId
            }
        },
        communicationSearchModelValid() {
            return !!this.subjectId;
        },
        communicationsCache() {
            return this.findCommunicationsCache(this.subjectId);
        },
        communications() {
            return !!this.communicationsCache ? this.communicationsCache.values : [];
        },
        loadingCommunications() {
            return !!this.communicationsCache && this.communicationsCache.loading;
        }
    },
    watch: {
        communicationSearchModel() {
            this.refreshCommunications();
        }
    },
    methods: {
        ...mapActions('BookingSuggestionCommunications', [
            'searchCommunications'
        ]),
        refreshCommunications() {
            if (!this.communicationsCache || !!this.communicationsCache.retry) {
                this.trySearchCommunications();
            }
        },
        trySearchCommunications() {
            const self = this;
            if (!self.communicationSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchCommunications({ key: self.subjectId, payload: self.communicationSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCommunications();
    }
}
