import { mapActions } from 'vuex';
import { SmsSettingCacheMixin } from "./SmsSettingCacheMixin";
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const SmsSettingAddMixin = {
    mixins: [SmsSettingCacheMixin],
    data() {
        return {
            fromName: '',
            balanceAlert: 20
        }
    },
    computed: {
        smsSettingAddModel() {
            return {
                fromName: this.fromName,
                balanceAlert: this.balanceAlert
            }
        }
    },
    methods: {
        ...mapActions('SmsSettings', [
            'addSmsSetting'
        ]),
        tryAddSmsSetting() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.addSmsSetting( self.smsSettingAddModel )
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshSmsSettingModel() {
            this.fromName = '';
            this.balanceAlert = 20;
        }
    },
    created() {
        this.refreshSmsSettingModel();
    }
}
