<template>
    <tr>
        <td><div class="text-nowrap">{{startDate}}</div></td>
        <td>{{startTime}}</td>
        <td class="text-center"><i :class="statusClass" :title="$t(appointment.status.name)"></i></td>
        <td><div class="text-nowrap">{{createdDate}}</div></td>
        <td @click="onClick" class="text-center"><i class="far fa-search md-trigger pointer" id="" title="View"></i></td>
    </tr>
</template>

<script>
    import { AppointmentSectionItemMixin } from "../../../mixins/Appointments/AppointmentSectionItemMixin";

    export default {
        name: 'PetAppointmentSectionItem',
        mixins: [AppointmentSectionItemMixin]
    }
</script>
