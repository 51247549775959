import { mapActions, mapGetters, mapState } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const CountryCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Countries', [
            'loadingCountries',
            'loadedCountries'
        ]),
        ...mapGetters('Countries', [
            'findCountry'
        ]),
        country() {
            return this.findCountry(this.siteId);
        },
        countryName() {
            return !!this.country ? this.country.name : '';
        },
        currency() {
            return !!this.country ? this.country.currency : '';
        },
        currencySymbol() {
            return !!this.country ? this.country.currencySymbol : '';
        },
        alpha2Code() {
            return !!this.country ? this.country.alpha2Code : '';
        }
    },
    watch: {
        siteId() {
            this.refreshCountries();
        }
    },
    methods: {
        ...mapActions('Countries', [
            'getCountries'
        ]),
        refreshCountries() {
            if (!this.loadingCountries && !this.loadedCountries) {
                this.tryGetCountries();
            }
        },
        tryGetCountries() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getCountries()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCountries();
    }
}
