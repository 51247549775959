<template>
    <modal-dialog :title="$t('Priority Order')" @opened="refreshSortedResources" @closed="resetUI">
        <section class="rounded-card-white mb-3">
            <error-banner v-if="error" :message="error"></error-banner>
            <div v-if="updatedPriorityOrder" role="alert" class="alert alert-success alert-icon">
                <div class="alert-content">
                    <p>{{$t("Priority order for resources updated successfully!")}}</p>
                </div>
            </div>
            <resource-draggable v-model="sortedResources" @updatePriorities="updatePriorities"></resource-draggable>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading || !updateModelModified" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import { ResourceCacheMixin } from '../Shared/Resources/ResourceCacheMixin';
    import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';
    import { Api } from '../../services/Api';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ResourcePriorityOrderDraggable from './ResourcePriorityOrderDraggable.vue';

    export default {
        name: 'ResourcePriorityOrder',
        mixins: [ResourceCacheMixin, SiteIdMixin],
        data() {
            return {
                sortedResources: [],
                updatedPriorityOrder: false,
                updatingPriorityOrder: false
            }
        },
        computed: {
            loading() {
                return !!this.updatingPriorityOrder || !!this.loadingResources
            },
            updateModel() {
                return {
                    resources: this.sortedResources
                }
            },
            updateModelValid() {
                return this.sortedResources.length > 0;
            },
            updateModelModified() {
                const original = this.resources.sort((a, b) => a.priorityOrder - b.priorityOrder);
                const modified = this.sortedResources;
                return JSON.stringify(original) !== JSON.stringify(modified);
            }
        },
        watch: {
            resources() {
                this.refreshSortedResources();
            }
        },
        methods: {
            ...mapMutations('Resources', [
                'updateResourcesPriorityOrder'
            ]),
            resetUI() {
                this.error = '';
                this.updatedPriorityOrder = false;
            },
            refreshSortedResources() {
                this.sortedResources = JSON.parse(JSON.stringify(this.resources));
                this.sortedResources.sort((a, b) => a.priorityOrder - b.priorityOrder);
            },
            save() {
                const self = this;
                if (!self.updateModelModified || !this.updateModelValid) {
                    return;
                }
                self.resetUI();
                self.updatingPriorityOrder = true
                Api.put('Resource/PriorityOrder', self.updateModel)
                    .then(response => {
                        self.updatedPriorityOrder = true;
                        self.updateResourcesPriorityOrder({ key: self.siteId, resources: response.data })
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => { self.updatingPriorityOrder = false });
            },
            updatePriorities(value) {
                this.sortedResources = value;
            }
        },
        created() {
            this.refreshSortedResources();
        },
        components: {
            'modal-dialog': ModalDialog,
            'resource-draggable': ResourcePriorityOrderDraggable,
            'error-banner': ErrorBanner
        }
    }
</script>
