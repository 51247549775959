import { CampaignHelper } from "../../helpers/CampaignHelper";
import { EmailCampaignTemplatesCacheMixin } from "./EmailCampaignTemplatesCacheMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { StripoAddMixin } from "../../components/Shared/Stripo/StripoAddMixin";
import { StripoDefaultTemplateCacheMixin } from "../../components/Shared/StripoDefaultTemplateCacheMixin";

export const EmailCampaignTemplateAddMixin = {
    mixins: [EmailCampaignTemplatesCacheMixin, StripoDefaultTemplateCacheMixin, StripoAddMixin],
    data() {
        return {
            subject: '',
            templateId: '',
            emailCampaignTemplateValidator: CampaignHelper.getEmailCampaignTemplateValidator()
        }
    },
    computed: {
        defaultStripoTemplate() {
            return this.stripoDefaultTemplate;
        },
        emailCampaignTemplateAddModel() {
            return {
                subject: this.subject,
                stripoTemplateId: this.templateId
            }
        },
        emailCampaignTemplateAddModelValid() {
            return !!this.templateId && this.emailCampaignTemplateValidator.isValid;
        }
    },
    methods: {
        ...mapActions('EmailCampaignTemplates', [
            'addEmailCampaignTemplate'
        ]),
        tryUploadEmailCampaignTemplate() {
            if (!!this.templateId) {
                this.tryAddEmailCampaignTemplate();
            } else {
                this.tryAddStripoTemplate();
            }
        },
        afterStripoTemplateAdded(stripoTemplate) {
            this.templateId = stripoTemplate.id;
            this.tryAddEmailCampaignTemplate();
        },
        tryAddEmailCampaignTemplate() {
            const self = this;
            self.emailCampaignTemplateValidator.check(self.emailCampaignTemplateAddModel);
            if (!self.emailCampaignTemplateValidator.isValid) {
                return;
            }
            self.error = '';
            self.addEmailCampaignTemplate({ key: self.siteId, payload: self.emailCampaignTemplateAddModel })
                .then(response => self.afterAddEmailCampaignTemplate(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddEmailCampaignTemplate() {
            // override if necessary
        }
    }
}
