<template>
    <li class="active" @click="selectSite">
        <a class="pointer" v-bind:class="{active : isSelectedSite}">
            <figure v-if="logo" class="pet-photo p-0 mr-0">
                <img class="p-0 m-0" :alt="logo.uploadedName" :src="logo.url" width="100">
            </figure>
            <div v-else class="name-widget p-0 mr-0">
                <div class="name-avatar p-0 mr-1">{{ startLetter }}</div>
            </div>
            <div class="d-flex flex-column w-100">
                <div class="pet-name">
                    {{ siteName }}
                </div>
                <div class="pet-type">
                    {{ companyName }}
                </div>
            </div>
        </a>
    </li>
</template>

<script>
    import { LogosCacheMixin } from "../Shared/LogosCacheMixin";

    export default {
        name: 'SiteListItem',
        mixins: [LogosCacheMixin],
        props: {
            site: Object,
            isSelectedSite: {
                value: false,
                type: Boolean
            }
        },
        computed: {
            siteName() {
                return this.site.name;
            },
            startLetter() {
                return this.siteName[0].toUpperCase();
            },
            companyName() {
                return this.site.companyName;
            },
            logo() {
                return this.logos.find(l => l.siteId == this.site.id);
            }
        },
        methods: {
            selectSite(value) {
                this.$emit('site-selected', value);
            }
        }
    }
</script>

