<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">{{$t('Add Template')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav header-nav-left">
                <a @click="cancel" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <div class="d-flex flex-column-reverse flex-md-row">
                <div class="w-100">
                    <div class="form-group">
                        <label>{{$t('Subject')}}<span class="required-field"></span></label>
                        <input v-model="subject" class="form-control" :placeholder="$t('Subject')">
                        <validation-message v-if="emailCampaignTemplateValidator.subject" :message="emailCampaignTemplateValidator.subject"></validation-message>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-flex justify-content-end">
                        <div style="width: 400px">
                            <template-select v-model="selectedStripoDefaultTemplateId"></template-select>
                        </div>
                    </div>
                </div>
            </div>
            <stripo-templator ref="stripoTemplator" v-if="displayTemplator" v-model="stripoModel" :accountId="accountId"></stripo-templator>
        </div>
        <div class="fixed-action-btns action-btns d-flex flex-column align-items-start pt-0">
            <template-tags :method-type-id="1"></template-tags>
            <div class="d-flex justify-content-between align-items-center col-12 mt-2 px-0">
                <div>
                    <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                        Add
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
                <toggle-tags></toggle-tags>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import { EmailCampaignTemplateAddMixin } from '../../../mixins/Campaigns/EmailCampaignTemplateAddMixin';
    import StripoTemplator from "../../Shared/Stripo/StripoTemplator.vue";
    import CampaignTemplateTags from './CampaignTemplateTags.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import SiteSelect from '../../Account/SiteSelect.vue';
    import StripoDefaultTemplateSettingsSelect from '../../Account/StripoDefaultTemplateSettingsSelect.vue';
    import TagsDisplayToggleButton from '../../Shared/Tags/TagsDisplayToggleButton.vue';
    import { AccountCacheMixin } from '../../../mixins/Account/AccountCacheMixin';

    export default {
        name: 'CampaignEmailTemplateAdd',
        mixins: [EmailCampaignTemplateAddMixin, AccountCacheMixin],
        computed: {
            stripoModel: {
                get() {
                    return {
                        html: this.html,
                        css: this.css,
                        templateId: this.selectedStripoDefaultTemplateId
                    };
                },
                set(value) {
                    this.html = value.html;
                    this.css = value.css;
                    this.tryUploadEmailCampaignTemplate();
                }
            },
            displayTemplator() {
                return !!this.html && !!this.css && !!this.account;
            },
            loading() {
                return this.loadingEmailCampaignTemplates || this.addingStripoTemplate || this.loadingDefaultTemplate;
            }
        },
        methods: {
            cancel() {
                router.go(-1);
            },
            save() {
                this.$refs.stripoTemplator.emitTemplate();
            },
            afterAddEmailCampaignTemplate() {
                this.cancel();
            }
        },
        components: {
            'stripo-templator': StripoTemplator,
            'template-tags': CampaignTemplateTags,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'site-select': SiteSelect,
            'template-select': StripoDefaultTemplateSettingsSelect,
            'toggle-tags': TagsDisplayToggleButton
        }
    }
</script>
