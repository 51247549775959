import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const ImageCachesMixin = {
    data() {
        return {
            error: '',
            fetchingImages: false
        }
    },
    computed: {
        ...mapGetters('Images', [
            'findImageCache'
        ]),
        ownerIds() {
            console.log('OVERRIDE COMPUTED - ownerIds');
            return [];
        },
        ownerTypeId() {
            console.log('OVERRIDE COMPUTED - ownerTypeId');
            return 0;
        },
        missingOwnerIds() {
            return this.ownerIds.filter(id => {
                const cache = this.findImageCache(this.getOwnerKey(id));
                return !cache || !!cache.retry;
            });
        },
        ownerImageSearchKeys() {
            return this.missingOwnerIds.map(id => this.getOwnerKey(id));
        },
        ownerImageSearchModel() {
            return {
                ownerTypeId: this.ownerTypeId,
                ownerIds: this.missingOwnerIds
            }
        },
        ownerImageSearchModelValid() {
            const model = this.ownerImageSearchModel;
            return !!model.ownerTypeId && model.ownerIds.length > 0;
        }
    },
    watch: {
        ownerImageSearchModel() {
            this.tryAndSearchImages();
        }
    },
    methods: {
        ...mapActions('Images', [
            'searchImages'
        ]),
        getOwnerKey(ownerId) {
            return CacheHelper.ownerRequestCacheKey({ ownerTypeId: this.ownerTypeId, ownerId: ownerId })
        },
        tryAndSearchImages() {
            const self = this;
            self.error = '';
            if (!self.ownerImageSearchModelValid) {
                return;
            }
            self.fetchingImages = true;
            self.searchImages({ keys: self.ownerImageSearchKeys, payload: self.ownerImageSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => { self.fetchingImages = false });
        }
    },
    created() {
        this.tryAndSearchImages();
    }
}
