<template>
    <div class="main-page h-100">
        <header class="header header-schedule-page">
            <div class="col text-left d-none d-md-flex align-items-center pr-0">
                <site-select class="m-0 mr-md-3 dropdown-site-selector"></site-select>
                <filter-location class="mr-md-1"></filter-location>
                <filter-resource></filter-resource>
            </div>
            <div class="content w-100 d-block d-md-none pb-0">
                <site-select class="dropdown-site-selector" style="margin-bottom: 0rem !important"></site-select>
            </div>
            <div v-if="calendarSettingsReady" class="col text-center px-0 pl-3 px-md-0">
                <div class="calendar-control day-selector btn-group">
                    <button class="btn" title="Prev" type="button" @click="movePrev">
                        <i class="fas fa-chevron-left"></i>
                        <span class="sr-only">Prev</span>
                    </button>
                    <schedule-date-select v-model="date"></schedule-date-select>
                    <div class="dropdown">
                        <button aria-expanded="false" aria-haspopup="true" 
                                class="custom-select schedule-jump-btn p-0" 
                                data-toggle="dropdown"
                                type="button">
                        </button>
                        <div aria-labelledby="dropdownMenuButton"
                             class="custom-select-dropdown-menu dropdown-menu dropdown-menu-calendar-selector">
                            <div class="font-italic text-center"><i class="far fa-share"></i>&nbsp;{{$t('Jump')}}...</div>
                            <div v-for="option in options" :key="option.id"
                                 @click="handleChangeSelection(option.id)"
                                 class="custom-select-dropdown-item dropdown-item pointer text-center">
                                <div v-if="option.id !== 0">{{$t(option.text)}}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn" title="Next" type="button" @click="moveNext">
                        <i class="fas fa-chevron-right"></i>
                        <span class="sr-only">Next</span>
                    </button>
                </div>
            </div>
            <div v-if="calendarSettingsReady" class="col text-right">
                <div class="d-flex align-content-center my-3 my-md-0">
                    <div class="calendar-control view-selector btn-group d-none d-md-flex">
                        <button v-for="view in scheduleViews" class="btn" v-bind:class="{ active: currentView===view.value }" type="button" @click="changeView(view.value)">{{$t(view.name)}}</button>
                    </div>
                    <search-pet class="d-md-flex d-none"></search-pet>
                    <button @click="triggerAddAppointmentWithDuration" class="btn-add btn btn-secondary d-none d-md-block">
                        <i class="fas fa-plus"></i>
                        {{$t('Add')}}
                    </button>
                    <button style="margin-left:15px" type="button" class="btn-add btn btn-secondary d-none d-md-block" data-toggle="modal" data-target="#schedule-settings-id">
                        <i style="margin-left:5px" class="fad fa-sliders-h"></i>
                    </button>
                    <div class="d-flex ml-auto align-items-center">
                        <div class="schedule-search-wrapper-mobile">
                            <search-pet class="d-md-none d-flex"></search-pet>
                        </div>
                        <notification-bell></notification-bell>
                        <button @click="showFilters=true" class="btn-filter fa-regular fa-filter d-md-none" type="button">
                            <span class="sr-only">Filters</span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
        <schedule-settings id="schedule-settings-id"></schedule-settings>
        <filter-list></filter-list>
        <div class="cal-wrap" id="calendarWrapper">
            <calendar v-model="calendarApiStatus" v-if="calendarSettingsReady" ref="myCalendar"
                      :events="calendarEvents" :resources="calendarResources" :user-setting="userSettingValue" :site-setting="settingObject"
                      @viewChanged="handleViewChanged" @slotClick="handleSlotClick" @eventClick="handleEventClick" @datesSet="handleDatesSet" @dateSet="handleDateSet"></calendar>
        </div>
        <!-- Mobile filters -->
        <mobile-filters v-model="showFilters"></mobile-filters>
        <!-- Notifcation panel-->
        <view-event :event-id="staffEventId" ref="viewEvent"></view-event>
        <resource-images v-if="!!requiredResourceImages" v-model="resourceImagesStatus"></resource-images>
        <pet-images :pet-ids="petIds" v-if="!!requiredPetImages" v-model="petImagesStatus"></pet-images>
        <client-addresses :client-ids="clientIds" v-if="!!requiredClientAddresses" v-model="clientAddressesStatus"></client-addresses>
        <client-histories :client-ids="clientIds" v-model="clientHistoriesStatus"></client-histories>
        <appointment-orders :appointments="appointments" v-if="!!requiredAppointmentOrders" v-model="appointmentOrdersStatus"></appointment-orders>
        <appointment-notes :appointments="appointments" v-if="!!requiredAppointmentNotes" v-model="appointmentNotesStatus"></appointment-notes>
        <site-resource-event-colour-setting v-model="resourceEventColourSetting"></site-resource-event-colour-setting>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { AppointmentsDataFinder } from '../Shared/Appointments/AppointmentsDataFinder';
    import { AppointmentSearchMixin } from '../Shared/AppointmentSearchMixin.js';
    import { EventSearchMixin } from '../Shared/Events/EventSearchMixin';
    import { ScheduleViewsMixin } from './ScheduleViewsMixin';
    import { AllResourcesMixin } from '../Shared/Resources/AllResourcesMixin';
    import { CalendarRequiredUserSettingsMixin } from '../../mixins/Calendar/CalendarRequiredUserSettingsMixin';
    import { CalendarHelper } from '../../helpers/CalendarHelper';
    import moment from 'moment';
    import Calendar from './Calendarx.vue';
    import ScheduleSettingsDesktop from './Settings/ScheduleSettingsDesktop.vue';
    import ScheduleFilterResource from './ScheduleFilterResource.vue';
    import ScheduleFilterLocation from './ScheduleFilterLocation.vue';
    import ScheduleFilterList from './ScheduleFilterList.vue';
    import ScheduleSearchPet from './ScheduleSearchPet.vue';
    import ScheduleViewStaffEvent from './ViewStaffEvent/ScheduleViewStaffEvent.vue';
    import ScheduleDateSelector from './ScheduleDateSelector.vue';
    import ScheduleMobileFilters from './ScheduleMobileFilters.vue';
    import NotificationBell from '../Shared/Notifications/NotificationBell.vue';
    import ResourceImagesSearch from '../Resources/ResourceImagesSearch.vue';
    import SiteSelect from '../Account/SiteSelect.vue';
    import PetImagesSearch from '../Pet/PetImagesSearch.vue';
    import ClientAddressesSearch from '../Clients/Addresses/ClientAddressesSearch.vue';
    import AppointmentOrdersSearch from './AppointmentOrdersSearch.vue';
    import ClientHistoriesSearch from './ClientHistoriesSearch.vue'
    import AppointmentNotesSearch from './AppointmentNotesSearch.vue'
    import { ScheduleResourceHoursMixin } from './ScheduleResourceHoursMixin';
    import SiteResourceEventColourSetting from './SiteResourceEventColourSetting.vue'

    export default {
        name: 'Schedule',
        mixins: [AppointmentsDataFinder, AppointmentSearchMixin, EventSearchMixin, ScheduleViewsMixin, AllResourcesMixin, CalendarRequiredUserSettingsMixin, ScheduleResourceHoursMixin],
        data() {
            return {
                currentView: '',
                takeAppointments: 0,
                staffEvent: null,
                appointment: null,
                showFilters: false,
                currentDate: new Date(),
                date: new Date(),
                options: [
                    {
                        "id": 1,
                        "daysAmount": 0,
                        "text": "Today"
                    },
                    {
                        "id": 2,
                        "daysAmount": 14,
                        "text": "2 weeks"
                    },
                    {
                        "id": 3,
                        "daysAmount": 28,
                        "text": "4 weeks"
                    },
                    {
                        "id": 4,
                        "daysAmount": 42,
                        "text": "6 weeks"
                    },
                    {
                        "id": 5,
                        "daysAmount": 56,
                        "text": "8 weeks"
                    },
                    {
                        "id": 6,
                        "daysAmount": 70,
                        "text": "10 weeks"
                    },
                    {
                        "id": 7,
                        "daysAmount": 84,
                        "text": "12 weeks"
                    }
                ],
                resourceEventColourSetting: {},
                petImagesStatus: {},
                resourceImagesStatus: {},
                clientAddressesStatus: {},
                clientHistoriesStatus: {},
                appointmentOrdersStatus: {},
                calendarApiStatus: {},
                appointmentNotesStatus: {}
            }
        },
        computed: {
            ...mapState('ScheduleFilter', [
                'resourceIds',
                'siteIds'
            ]),
            ...mapGetters('ScheduleFilter', [
                'searchModel'
            ]),
            staffEventId() {
                return !!this.staffEvent ? this.staffEvent.id : '';
            },
            appointmentId() {
                return !!this.appointment ? this.appointment.id : '';
            },
            eventSearchModel() {
                return this.searchModel;
            },
            calendarEvents() {
                const resourceDayOffs = this.currentView == 'resourceTimeGridDay' ? this.resourceDayOffs.map(rdo => CalendarHelper.getResourceHourEvent(rdo)) : [];
                const appointments = this.appointments.map(a => CalendarHelper.getAppointmentEvent(a));
                const events = this.staffEvents.map(se => CalendarHelper.getStaffEvent(se, this.resourceEventColourSetting));
                return appointments.concat(events, resourceDayOffs);
            },
            filteredResources() {
                const resources = this.currentView === 'resourceTimeGridDay' ? this.allActiveResources : this.allResources;
                return this.resourceIds.length > 0 ?
                    resources.filter(r => this.resourceIds.indexOf(r.id) > -1) :
                    resources.filter(r => this.siteIds.indexOf(r.siteId) > -1);
            },
            calendarResources() {
                return this.filteredResources.map(r => ({
                    id: r.id,
                    title: r.name,
                    eventColor: r.colour,
                    priorityOrder: r.priorityOrder
                }));
            },
            statuses() {
                return [
                    this.petImagesStatus,
                    this.resourceImagesStatus,
                    this.clientAddressesStatus,
                    this.clientHistoriesStatus,
                    this.appointmentOrdersStatus,
                    this.appointmentNotesStatus
                ];
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerAddAppointmentWithDuration',
                'triggerViewAppointment'
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setStartUtc',
                'setResource',
                'setStartDate'
            ]),
            ...mapMutations('Events', [
                'appendEvent'
            ]),
            ...mapMutations('ScheduleFilter', [
                'setDates'
            ]),
            movePrev() {
                this.$refs.myCalendar.previous();
            },
            moveNext() {
                this.$refs.myCalendar.next();
            },
            changeView(viewName) {
                this.$refs.myCalendar.changeView(viewName);
            },
            handleViewChanged(viewName) {
                this.currentView = viewName;
            },
            handleSlotClick(info) {
                if (!!info.resource) {
                    this.setResource({ index: 0, resource: info.resource });
                }
                this.setStartUtc(moment(info.date));
                this.triggerAddAppointmentWithDuration();
            },
            handleEventClick(info) {
                if (!!info.event.extendedProps.appointment) {
                    const appointment = info.event.extendedProps.appointment;
                    this.triggerViewAppointment(appointment);
                } else if (!!info.event.extendedProps.staffEvent) {
                    const event = info.event.extendedProps.staffEvent;
                    this.appendEvent(event);
                    this.staffEvent = event;
                    const element = this.$refs.viewEvent.$el;
                    $(element).modal('show');
                }
            },
            handleDatesSet(dates) {
                this.setDates(dates);
            },
            handleDateSet(date) {
                this.setStartDate(date);
                this.currentDate = date;
            },
            handleChangeSelection(optionId) {
                if (optionId !== 1) {
                    let adjustedDate = this.currentDate.setDate(this.currentDate.getDate() + this.options.find(o => o.id === optionId).daysAmount);
                    this.$refs.myCalendar.goto(adjustedDate);
                }
                else {
                    this.$refs.myCalendar.today();
                }
            }
        },
        watch: {
            date(value){
                this.$refs.myCalendar.goto(value);
            }
        },
        components: {
            'calendar': Calendar,
            'schedule-settings': ScheduleSettingsDesktop,
            'filter-resource': ScheduleFilterResource,
            'filter-location': ScheduleFilterLocation,
            'filter-list': ScheduleFilterList,
            'search-pet': ScheduleSearchPet,
            'view-event': ScheduleViewStaffEvent,
            'schedule-date-select': ScheduleDateSelector,
            'mobile-filters': ScheduleMobileFilters,
            'notification-bell': NotificationBell,
            'resource-images': ResourceImagesSearch,
            'pet-images': PetImagesSearch,
            'client-addresses': ClientAddressesSearch,
            'client-histories': ClientHistoriesSearch,
            'appointment-orders': AppointmentOrdersSearch,
            'appointment-notes': AppointmentNotesSearch,
            'site-select': SiteSelect,
            'site-resource-event-colour-setting': SiteResourceEventColourSetting
        }
    }
</script>
