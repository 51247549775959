<template>
    <div v-if="showTags">
        <display-tags :tags="emailTemplateTags"></display-tags>
    </div>
</template>

<script>
    import { EmailTemplateTagsCacheMixin } from "../../Shared/EmailTemplateTagsCacheMixin";
    import TagsDisplay from "../../Shared/Tags/TagsDisplay.vue";
   
    export default {
        name: 'EmailTemplateTags',
        mixins: [EmailTemplateTagsCacheMixin],
        computed: {
            showTags() {
                return this.emailTemplateTags.length > 0;
            }
        },
        components: {
            'display-tags': TagsDisplay
        }
    }
</script>