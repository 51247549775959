import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SortableSearchMixin } from "./Searching/SortableSearchMixin";

export const ClientSearchMixinV2 = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            searchTerm: '',
            skip: 0,
            take: 10,
            returnDefault: true,
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters('ClientSearchesV2', [
            'findClientSearch'
        ]),
        clientSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                ascending: this.ascending,
                returnDefault: this.returnDefault,
                skip: this.skip,
                take: this.take
            }
        },
        clientSearchModelValid() {
            return !!this.searchTerm || this.returnDefault;
        },
        clientSearchKey() {
            return CacheHelper.objectCacheKey(this.clientSearchModel);
        },
        clientSearch() {
            return this.findClientSearch(this.clientSearchKey);
        },
        clients() {
            return !!this.clientSearch ? this.clientSearch.values : [];
        },
        searchingClients() {
            return !!this.clientSearch && this.clientSearch.loading;
        },
        clientResultsReady() {
            return !!this.clientSearch && !this.searchingClients;
        },
        moreClients() {
            return this.clients.length === this.take;
        },
        clientPageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshClientSearch();
            }, 500);
        },
        sortModel() {
            this.refreshClientSearch();
        }
    },
    methods: {
        ...mapActions('ClientSearchesV2', [
            'searchClients'
        ]),
        refreshClientSearch() {
            if (!this.clientSearch || !!this.clientSearch.retry) {
                this.searchAndCacheClients();
            }
        },
        searchAndCacheClients() {
            const self = this;
            self.error = '';
            if (!self.clientSearchModelValid) {
                return;
            }
            self.searchClients({ key: self.clientSearchKey, payload: self.clientSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        next() {
            if (this.moreClients) {
                this.skip += this.take;
                this.refreshClientSearch();
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshClientSearch();
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshClientSearch();
        }
    }
}
