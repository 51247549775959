import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ResourceHelper } from "../../../helpers/ResourceHelper";
import { WorkingHoursCacheMixin } from "./WorkingHoursCacheMixin";
import { mapActions } from 'vuex';
import moment from 'moment';

export const WorkingHoursAddMixin = {
    mixins: [WorkingHoursCacheMixin],
    data() {
        return {
            date: moment(new Date()).format('YYYY-MM-DD') + 'T',
            startLocal: '08:00',
            endLocal: '17:00',
            dayOff: false,
            workingHourValidator: ResourceHelper.getResourceHourValidator()
        }
    },
    props: {
        resource: Object,
        dayId: Number
    },
    computed: {
        fullStartLocal() {
            return this.date + this.startLocal + ':00';
        },
        fullEndLocal() {
            return this.date + this.endLocal + ':00';
        },
        resourceId() {
            return !!this.resource ? this.resource.id : '';
        },
        workingHourAddModel() {
            return {
                resourceId: this.resourceId,
                dayOfWeekId: this.dayId,
                startLocal: this.fullStartLocal,
                endLocal: this.fullEndLocal,
                dayOff: this.dayOff
            }
        },
        workingHourAddModelValid() {
            const model = this.workingHourAddModel;
            return !!model.resourceId && model.dayOfWeekId > -1 && model.dayOfWeekId < 7 && this.workingHourValidator.isValid;
        }
    },
    methods: {
        ...mapActions('WorkingHours', [
            'addWorkingHour'
        ]),
        tryAddWorkingHour() {
            const self = this;
            self.workingHourValidator.validate(self.workingHourAddModel);
            if (!self.workingHourAddModelValid) {
                return;
            }
            self.error = '';
            self.addWorkingHour({ key: self.siteId, payload: self.workingHourAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
