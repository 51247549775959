import { Api } from '../../services/Api';

const state = () => ({
    bookingHistoryCaches: []
})

const getters = {
    findBookingHistoryCache: (state) => key => {
        return state.bookingHistoryCaches.find(bhc => bhc.key === key);
    }
}

const mutations = {
    setLoading(state, key) {
        const existing = state.bookingHistoryCaches.find(bhc => bhc.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.bookingHistoryCaches.push({ key: key, value: null, loading: true });
        }
    },
    setBookingHistory(state, { key, bookingHistory }) {
        const existing = state.bookingHistoryCaches.find(bhc => bhc.key === key);
        if (!!existing) {
            existing.value = bookingHistory;
            existing.loading = false;
        } else {
            state.bookingHistoryCaches.push({ key: key, value: bookingHistory, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.bookingHistoryCaches.find(bhc => bhc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getBookingHistory({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', key);
            Api.post('Booking/History', payload)
                .then(response => {
                    commit('setBookingHistory', { key: key, bookingHistory: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
