import { mapState, mapGetters, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const BookingCountCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingCount', [
            'bookingCountCacheCleared'
        ]),
        ...mapGetters('BookingCount', [
            'findBookingCountCache'
        ]),
        bookingCountCache() {
            return this.findBookingCountCache(this.siteId);
        },
        bookingCount() {
            return !!this.bookingCountCache ? this.bookingCountCache.value : null;
        },
        loadingBookingCount() {
            return !!this.bookingCountCache && this.bookingCountCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshBookingCount();
        },
        bookingCountCacheCleared() {
            this.refreshBookingCount();
        }
    },
    methods: {
        ...mapActions('BookingCount', [
            'fetchBookingCount'
        ]),
        tryFetchBookingCount() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchBookingCount(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBookingCount() {
            if (!this.bookingCountCache || !!this.bookingCountCache.retry) {
                this.tryFetchBookingCount();
            }
        }
    },
    created() {
        this.refreshBookingCount();
    }
}
