<template>
    <div class="main-page">
        <div v-if="error" class="alert alert-danger alert-icon" role="alert">
            <div class="alert-content">
                <p>{{ error }}</p>
            </div>
            <button @click="tryCancelSubscriptionPayment">
                Retry
            </button>
            <button @click="cancel">
                Cancel
            </button>
        </div>
        <page-loader v-if="loadingSubscriptionPayment"></page-loader>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { SubscriptionPaymentCacheMixin } from '../../mixins/SubscriptionPayments/SubscriptionPaymentCacheMixin';
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import PageLoader from '../Shared/Loading/PageLoader.vue';

    export default {
        name: 'PaymentCancel',
        mixins: [SubscriptionPaymentCacheMixin],
        computed: {
            paymentId() {
                return this.$route.params.paymentId;
            }
        },
        methods: {
            ...mapActions('SubscriptionPayments', [
                'cancelSubscriptionPayment'
            ]),
            tryFetchSubscriptionPayment() {
                // do nothing
            },
            tryCancelSubscriptionPayment() {
                const self = this;
                if (!self.paymentId) {
                    return;
                }
                self.cancelSubscriptionPayment(self.paymentId)
                    .then(() => self.complete())
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            complete() {
                this.gotoStore('Cancelled checkout payment');
            },
            cancel() {
                this.gotoStore('Failed to cancel checkout payment');
            },
            gotoStore(warning) {
                this.$router.push({
                    name: 'store',
                    query: { tab: 3, warning: warning }
                });
            }
        },
        created() {
            this.tryCancelSubscriptionPayment();
        },
        components: {
            'page-loader': PageLoader
        }
    }
</script>
