<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Name')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="name" class="form-control" type="text" value="" :placeholder="$t('Name')">
                <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Mobile')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="mobile" class="form-control" type="tel" value="" :placeholder="$t('Mobile')">
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { ContactUploadMixin } from "../../Shared/Contacts/ContactUploadMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'ClientEditAltContactSection',
        mixins: [ContactUploadMixin, StatusEmitterMixin],
        props: {
            clientId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            ownerId() {
                return this.clientId;
            },
            ownerTypeId() {
                return 2;
            },
            contactTypeId() {
                return 2;
            },
            status() {
                return {
                    loading: this.loadingContacts,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.tryUploadContact();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
