<template>
    <stacked-bar :chart-data="chartData"></stacked-bar>
</template>

<script>
    import moment from 'moment';
    import { CalendarHelper } from '../../helpers/CalendarHelper';
    import StackedBarChart from "../Shared/Graphs/StackedBarChart.vue";

    export default {
        name: 'AppointmentAggregateDateChart',
        props: {
            aggregates: []
        },
        computed: {
            dateLabels() {
                const labels = this.aggregates.map(a => moment(a.startLocal).format('MMM'));
                return labels.map(label => this.$t(label)); 
            },
            dateDatasets() {
                const datasets = [];
                for (var i = 0; i < this.aggregates.length; i++) {
                    const statusAggregates = this.aggregates[i].statuses.sort((a, b) => a.status.id - b.status.id);
                    for (var j = 0; j < statusAggregates.length; j++) {
                        const statusAggregate = statusAggregates[j];
                        const dataset = datasets.find(d => d.label === statusAggregate.status.description);
                        if (!!dataset) {
                            dataset.data.push(statusAggregate.count);
                        } else {
                            const colour = CalendarHelper.getAppointmentStatusColour(statusAggregate.status.id);
                            datasets.push({
                                label: statusAggregate.status.description,
                                data: [statusAggregate.count],
                                borderColor: colour,
                                backgroundColor: colour
                            });
                        }
                    }
                }

                const translatedDatasets = datasets.map(dataset => {
                    return {
                        ...dataset,
                        label: this.$t(dataset.label)
                    }
                });

                return translatedDatasets;
            },
            chartData() {
                return {
                    labels: this.dateLabels,
                    datasets: this.dateDatasets
                }
            }
        },
        components: {
            'stacked-bar': StackedBarChart
        }
    }
</script>
