import { Api } from '../../services/Api';

const state = () => ({
    fetching: false,
    fetched: false,
    species: []
})

const getters = {
    findSpecies: (state) => id => state.species.find(s => s.id === id)
}

const mutations = {
    setFetching(state) {
        state.fetched = false;
        state.fetching = true;
    },
    setSpecies(state, species) {
        state.species = species;
        state.fetched = true;
        state.fetching = false;
    },
    setFailed(state) {
        state.species = [];
        state.fetched = false;
        state.fetching = false;
    }
}

const actions = {
    getSpecies({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Breed/Species')
                .then(response => {
                    commit('setSpecies', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
