import { Api } from '../../services/Api';

const state = () => ({
    fetchingImageSettings: false,
    fetchedImageSettings: false,
    imageSettings: null
})

const mutations = {
    setImageSettings(state, imageSettings) {
        state.imageSettings = imageSettings;
        state.fetchedImageSettings = true;
        state.fetchingImageSettings = false;
    },
    setFetching(state) {
        state.fetchingImageSettings = true;
        state.fetchedImageSettings = false;
    },
    setFetched(state, fetched) {
        state.fetchedImageSettings = !!fetched;
        state.fetchingImageSettings = false;
    }
}

const actions = {
    fetchImageSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Image/Settings')
                .then(response => {
                    commit('setImageSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetched', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
