<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-pcn" data-toggle="collapse">
            {{$t('Pet cut notes')}}
            <span v-if="loadingNote" class="inline-loader"></span>
            <div class="ml-auto">
                <i class="far fa-chevron-down ml-auto"></i>
            </div>
        </div>
        <div class="collapse show" id="collapse-pcn">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <textarea v-model="noteText" class="form-control text-area-grow-focus note-section" cols="50" :placeholder="$t('Add notes (optional)')" rows="3"></textarea>
            </div>
        </div>
    </section>
</template>

<script>
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { PetNoteUploadMixin } from '../../mixins/Pets/PetNoteUploadMixin.js';

    export default {
        name: 'PetNotesCutSection',
        mixins: [PetNoteUploadMixin],
        computed: {
            noteTypeId() {
                return 2;
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>

<style>
    .accordion-header .fa-heart {
        color: #FE2051;
    }
</style>
