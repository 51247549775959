import { mapState, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const LogoAddMixin = {
    data() {
        return {
            file: null
        }
    },
    computed: {
        ...mapState('Logos', [
            'addingLogo'
        ]),
        preview() {
            return !!this.file ? {
                url: URL.createObjectURL(this.file)
            } : null;
        }
    },
    watch: {
        preview(_, oldValue) {
            if (!!oldValue) {
                URL.revokeObjectURL(oldValue.url);
            }
        }
    },
    methods: {
        ...mapActions('Logos', [
            'addLogo'
        ]),
        onFilePicked(event) {
            for (var i = 0; i < event.target.files.length; i++) {
                this.file = event.target.files[i];
            }
        },
        clearUpload() {
            this.file = null;
        },
        tryAddLogo() {
            const self = this;
            if (!self.file) {
                return;
            }
            self.error = '';
            self.addLogo(self.file)
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.clearUpload);
        }
    }
}
