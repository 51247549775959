import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { OrderHelper } from "../../../helpers/OrderHelper";
import { OrderCacheMixin } from "./OrderCacheMixin";

export const OrderAddMixin = {
    mixins: [OrderCacheMixin],
    data() {
        return {
            paymentMethodId: 0,
            orderItems: [],
            orderValidator: OrderHelper.getOrderValidator()
        }
    },
    computed: {
        orderAddModel() {
            return {
                appointmentId: this.appointmentId,
                paymentMethodId: this.paymentMethodId,
                orderItems: [...this.orderItems]
            }
        },
        orderAddModelValid() {
            const model = this.orderAddModel;
            return !!model.appointmentId && model.orderItems.length > 0 && this.orderValidator.isValid;
        }
    },
    methods: {
        ...mapActions('Orders', [
            'addOrder'
        ]),
        tryAddOrder() {
            const self = this;
            self.orderValidator.check(self.orderAddModel);
            if (!self.orderAddModelValid) {
                return;
            }
            self.error = '';
            self.addOrder({ key: self.appointmentId, payload: self.orderAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
