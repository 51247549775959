<template>
    <div v-if="displaySite" class="dropdown bootstrap-select form-control">
        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light" :disabled="disabled">
            <span class="text-left text-truncate" style="width: calc(100% - 1rem)">{{siteName}}</span>
        </button>
        <div class="inner show">
            <ul class="dropdown-menu">
                <li v-for="site in sites" 
                    :key="site.id" 
                    class="dropdown-item pointer" 
                    @click="setSelected(site.id)" 
                    v-bind:class="{'active': site.id === siteId}">
                    {{site.name}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";

    export default {
        name: 'SiteSelect',
        mixins: [SiteCacheMixin],
        props: {
            disabled: Boolean
        }
    }
</script>
