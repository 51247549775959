<template>
    <modal-dialog :title="$t('Working Hours')" :sub-title="subTitle">
        <div class="form-group text-center">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-sm btn-outline-secondary" v-bind:class="{ active: hoursType===0 }">
                    <input v-model="hoursType" :value="0" type="radio" ref="regularRadio">
                    {{$t('Regular')}}
                </label>
                <label class="btn btn-sm btn-outline-secondary" v-bind:class="{ active: hoursType===1 }">
                    <input v-model="hoursType" :value="1" type="radio" ref="oneOffRadio">
                    {{$t('One off')}}
                </label>
            </div>
        </div>
        <working-hours v-if="hoursType === 0" :resource="resource" :day-id="dayId" :default-hours="defaultHours"></working-hours>
        <one-off-hours v-if="hoursType === 1" :resource="resource" :date="date" :default-hours="defaultHours"></one-off-hours>
    </modal-dialog>
</template>

<script>
    import moment from 'moment';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { DaysOfWeekMixin } from '../../Shared/DaysOfWeekMixin';
    import UploadOneOffHours from './UploadOneOffHours.vue';
    import UploadWorkingHours from './UploadWorkingHours.vue';

    export default {
        name: 'UploadHoursDialog',
        mixins: [DaysOfWeekMixin],
        data() {
            return {
                hoursType: 0
            }
        },
        props: {
            resource: Object,
            dayId: Number,
            date: Date,
            oneOff: Boolean,
            openingHour: Object
        },
        computed: {
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            subTitle() {
                return `${this.$t('for')} ` + this.resourceName + ` ${this.$t('on')} ` + this.$t(this.daysOfWeek[this.dayId]);
            },
            defaultHours() {
                return {
                    dayOff: this.openingHour ? this.openingHour.closed : false,
                    startLocal: this.openingHour ? moment(this.openingHour.openingLocal).format('HH:mm') : '08:00',
                    endLocal: this.openingHour ? moment(this.openingHour.closingLocal).format('HH:mm') : '17:00'
                }
            }
        },
        watch: {
            oneOff(value) {
                this.hoursType = value ? 1 : 0;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'working-hours': UploadWorkingHours,
            'one-off-hours': UploadOneOffHours
        }
    }
</script>
