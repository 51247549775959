import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    fetchedPaymentApiUser: false,
    fetchingPaymentApiUser: false,
    loadingPaymentApiUser: false,
    paymentApiUser: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingPaymentApiUser = fetching;
        state.fetchedPaymentApiUser = false;
    },
    setLoading(state, loading) {
        state.loadingPaymentApiUser = !!loading;
    },
    setPaymentApiUser(state, paymentApiUser) {
        state.paymentApiUser = paymentApiUser;
        state.fetchedPaymentApiUser = true;
        state.fetchingPaymentApiUser = false;
    },
    clearPaymentApiUser(state) {
        state.paymentApiUser = null;
        state.fetchedPaymentApiUser = false;
        state.fetchingPaymentApiUser = false;
    }
}

const actions = {
    fetchPaymentApiUser({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            PaymentApi.get('User')
                .then(response => {
                    commit('setPaymentApiUser', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setPaymentApiUser', null);
                        resolve(false);
                    } else {
                        commit('setFetching', false);
                        reject(error);
                    }
                });
        });
    },
    updatePaymentApiUserOwner({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            PaymentApi.put('User/Owner', payload)
                .then(response => {
                    commit('setPaymentApiUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
