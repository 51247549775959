<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label for="title">Update</label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <label class="toggle-checkbox">
                    <input v-model="updateTypeId" :value="0" type="radio">
                    Just this appointment
                </label>
                <label class="toggle-checkbox">
                    <input v-model="updateTypeId" :value="1" type="radio">
                    Appointment group
                </label>
                <label class="toggle-checkbox">
                    <input v-model="updateTypeId" :value="2" type="radio">
                    Entire schedule
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppointmentUpdateTypeSelect',
        props: {
            value: Number
        },
        computed: {
            updateTypeId: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
