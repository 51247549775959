import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ServiceProductsGroupMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ServiceProductGroups', [
            'findServiceProductGroup'
        ]),
        productId() {
            console.log('OVERRIDE COMPUTED - productId');
            return '';
        },
        serviceProductGroup() {
            return this.findServiceProductGroup(this.productId);
        },
        serviceProducts() {
            return !!this.serviceProductGroup ? this.serviceProductGroup.values : [];
        },
        groupingServiceProducts() {
            return !!this.serviceProductGroup && this.serviceProductGroup.loading;
        }
    },
    watch: {
        productId() {
            this.refreshServiceProductGroup();
        }
    },
    methods: {
        ...mapActions('ServiceProductGroups', [
            'groupServiceProducts'
        ]),
        tryGroupServiceProducts() {
            const self = this;
            if (!self.productId) {
                return;
            }
            self.error = '';
            self.groupServiceProducts(self.productId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshServiceProductGroup() {
            if (!this.serviceProductGroup || !!this.serviceProductGroup.retry) {
                this.tryGroupServiceProducts();
            }
        }
    },
    created() {
        this.refreshServiceProductGroup();
    }
}
