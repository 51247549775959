import { mapActions, mapGetters, mapState } from "vuex"
import { CacheHelper } from '../../helpers/CacheHelper';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";
import moment from 'moment-timezone';

export const UnavailabilitiesCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    props: {
        appointment: Object
    },
    computed: {
        ...mapState('TimeZones', [
            'selectedTimeZoneId'
        ]),
        ...mapGetters('Unavailabilities', [
            'findUnavailabilityCache'
        ]),
        unavailabilityCache() {
            return this.findUnavailabilityCache(this.unavailabilityCacheKey);
        },
        searchStart() {
            return !!this.appointment ? moment.tz(this.appointment.startUtc, this.selectedTimeZoneId).format().substring(0, 19) : '';
        },
        searchEnd() {
            return !!this.appointment ? moment.tz(this.appointment.endUtc, this.selectedTimeZoneId).format().substring(0, 19) : '';
        },
        unavailabilitiesSearchModel() {
            return {
                startLocal: this.searchStart,
                endLocal: this.searchEnd,
                siteId: this.siteId
            }
        },
        unavailabilitiesSearchModelValid() {
            return !!this.unavailabilitiesSearchModel.siteId &&
                !!this.unavailabilitiesSearchModel.startLocal &&
                !!this.unavailabilitiesSearchModel.endLocal;
        },
        unavailabilityCacheKey() {
            return CacheHelper.fullObjectCacheKey(this.unavailabilitiesSearchModel);
        },
        unavailabilities() {
            return !!this.unavailabilityCache ? this.unavailabilityCache.values : [];
        }
    },
    watch: {
        unavailabilitiesSearchModel() {
            this.refreshUnavailabilities();
        }
    },
    methods: {
        ...mapActions('Unavailabilities', [
            'searchUnavailabilities'
        ]),
        refreshUnavailabilities() {
            if (!this.unavailabilityCache || !!this.unavailabilityCache.retry) {
                this.searchAndCacheUnavailabilities();
            }
        },
        searchAndCacheUnavailabilities() {
            const self = this;
            if (!self.unavailabilitiesSearchModelValid) {
                return;
            }
            self.searchUnavailabilities({ key: self.unavailabilityCacheKey, payload: self.unavailabilitiesSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUnavailabilities();
    }
}
