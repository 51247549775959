<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Reminders')}}</label>
        </div>
        <div class="col-md-8">
            <toggle-input v-model="enabled" :title="toggleMessage"></toggle-input>
            <div v-if="enabled" class="form-group row">
                <div class="col-md-12">
                    <label>{{$t('Sent hours prior')}}</label>
                </div>
                <div class="col-md-12">
                    <input v-model="hoursPrior" class="form-control" type="number" min="1" />
                    <validation-message v-if="reminderSettingValidator.hoursPrior" :message="reminderSettingValidator.hoursPrior"></validation-message>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { ReminderSettingUpdateMixin } from "../../Reminders/ReminderSettingUpdateMixin";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateAppointmentReminder',
        mixins: [ReminderSettingUpdateMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            toggleMessage() {
                return !!this.enabled ? this.$t('Enabled') : this.$t('Disabled');
            },
            status() {
                return {
                    loading: !!this.loadingReminderSettings || !!this.addingReminderSetting || !!this.updatingReminderSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadReminderSetting();
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'toggle-input': SlottedToggle,
            'error-banner': ErrorBanner
        }
    }
</script>
