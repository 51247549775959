<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { TimeFormatSettingAddMixin } from "../../Shared/Time/TimeFormatSettingAddMixin";

    export default {
        name: 'PostRegisterTimeFormat',
        mixins: [TimeFormatSettingAddMixin, StatusEmitterMixin],
        computed: {
            fetchedTimeFormatSetting() {
                return !!this.timeFormatSettingCache && !this.timeFormatSettingCache.retry && !this.loadingTimeFormatSetting;
            },
            timeFormatSettingComplete() {
                return !!this.timeFormatSetting;
            },
            canAddTimeFormatSetting() {
                return !!this.fetchedTimeFormatSetting
                    && !this.timeFormatSettingComplete
                    && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingTimeFormatSetting,
                    completed: this.timeFormatSettingComplete
                }
            }
        },
        watch: {
            canAddTimeFormatSetting(value) {
                if (!!value) {
                    this.tryAddTimeFormatSetting();
                }
            }
        },
        created() {
            if (this.canAddTimeFormatSetting) {
                this.tryAddTimeFormatSetting();
            }
        }
    }
</script>
