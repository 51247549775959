import { mapActions } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper"
import { SettingCacheMixin } from "./SettingCacheMixin"

export const SettingUploadMixin = {
    mixins: [SettingCacheMixin],
    computed: {
        settingEditModel() {
            console.log('!!!OVERRIDE COMPUTED - settingEditModel!!!');
        },
        settingEditModelValid() {
            console.log('!!!OVERRIDE COMPUTED - settingEditModelValid!!!');
        },
        settingUploadObject() {
            return Object.assign({}, this.settingObject, this.settingEditModel);
        },
        settingEditModelModified() {
            return JSON.stringify(this.settingUploadObject) !== JSON.stringify(this.settingObject);
        },
        settingOwnerModel() {
            return {
                ownerId: this.ownerId,
                ownerType: {
                    id: this.ownerTypeId
                }
            }
        },
        settingUploadModel() {
            return Object.assign({}, this.settingOwnerModel, {
                key: this.settingKey,
                value: JSON.stringify(this.settingUploadObject)
            });
        },
        settingUploadModelValid() {
            return !!this.settingEditModelValid && this.ownerQueryModelValid;
        }
    },
    watch: {
        settingObject() {
            this.refreshSettingEditModel();
        }
    },
    methods: {
        ...mapActions('Settings', [
            'addSetting',
            'updateSetting'
        ]),
        refreshSettingEditModel() {
            console.log('!!!OVERRIDE METHOD - refreshSettingEditModel()!!!');
        },
        tryUploadSetting() {
            if (!!this.setting) {
                this.tryUpdateSetting();
            } else {
                this.tryAddSetting();
            }
        },
        tryAddSetting() {
            const self = this;
            if (!self.settingUploadModelValid) {
                return;
            }
            self.error = '';
            self.addSetting({ key: self.settingCacheKey, payload: self.settingUploadModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryUpdateSetting() {
            const self = this;
            if (!self.settingUploadModelValid || !self.settingEditModelModified) {
                return;
            }
            self.error = '';
            self.updateSetting({ key: self.settingCacheKey, payload: self.settingUploadModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSettingEditModel();
    }
}
