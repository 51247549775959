<template>
    <tr @click="onClick">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{productName}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Published Online?')}} {{publishedOnline}}
                    </div>
                    <div class="crd-contact">
                        {{amountString}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { ServiceItemMixin } from "./ServiceItemMixin";

    export default {
        name: 'ServiceItemMobile',
        mixins: [ServiceItemMixin, CountryCacheMixin],
        computed: {
            amountString() {
                return this.currencySymbol + this.amount.toFixed(2);
            }
        }
    }
</script>
