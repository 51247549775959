import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import moment from 'moment';

export const BookingSuggestionAddMixin = {
    props: {
        bookingId: String
    },
    data() {
        return {
            error: '',
            startUtc: moment.utc().format()
        }
    },
    computed: {
        ...mapState('BookingSuggestions', [
            'addingBookingSuggestion'
        ]),
        bookingSuggestionAddModel() {
            return {
                bookingId: this.bookingId,
                startUtc: this.startUtc
            }
        },
        bookingSuggestionAddModelValid() {
            return !!this.bookingSuggestionAddModel.startUtc && !!this.bookingSuggestionAddModel.bookingId
        }
    },
    watch: {
        bookingSuggestionAddedEvent() {
            this.resetBookingSuggestionAddModel();
        }
    },
    methods: {
        ...mapActions('BookingSuggestions', [
            'addBookingSuggestion'
        ]),
        resetBookingSuggestionAddModel() {
            this.bookingId = '';
            this.startUtc = null;
        },
        tryAddBookingSuggestion() {
            const self = this;
            if (!self.bookingSuggestionAddModelValid) {
                return;
            }
            self.error = '';
            self.addBookingSuggestion({ key: self.bookingId, payload: self.bookingSuggestionAddModel })
                .then(response => self.afterAddBookingSuggestion(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddBookingSuggestion() {
            //override if necessary
        }
    }
}
