<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <number-input v-model="rate" :title="`${$t('Tax Rate')} (%)`">
            <validation-message v-if="taxValidator.rate" :message="taxValidator.rate"></validation-message>
        </number-input>
        <div class="form-group row">
            <div class="col-md-4">
                <label>{{$t('Tax Number')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="number" type="text" class="form-control" maxlength="50">
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { TaxUploadMixin } from "../../../mixins/Tax/TaxUploadMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import NumberInput from "../../Shared/Input/NumberInput.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateTaxDetails',
        mixins: [TaxUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ...mapState('Taxes', [
                'addingTax',
                'updatingTax'
            ]),
            status() {
                return {
                    loading: !!this.loadingTaxes || !!this.addingTax || !!this.updatingTax,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadTax();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'number-input': NumberInput
        }
    }
</script>
