<template>
    <div class="pet-photo">
        <div v-if="showImages">
            <img v-if="imageSrc" alt="Pet image" :src="imageSrc" style="float:left" />
            <template v-else>
                <default-image style="float:left"></default-image>
            </template>
        </div>
        <div v-if="showTime">
            {{timeString}}
        </div>
        <div v-for="(line, index) in lines" :key="index">
            <template v-if="showFirstTime">
                <i class="fas fa-star" v-if="index === 1"></i>
            </template>
            {{line}}
        </div>
        <appointment-notes v-if="showAppointmentNotes" :schedule-id="appointment.scheduleId"></appointment-notes>
        <address-lines v-if="showAddressLines" :client-id="clientId"></address-lines>
        <orders v-if="showOrders" :appointment="appointment"></orders>
        <div v-if="showWarning">
            <i class="fas fa-exclamation-circle"></i>
            Handle with care
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { HistoryCacheMixin } from '../Shared/HistoryCacheMixin';
    import { ImageCacheMixin } from '../Shared/Images/ImageCacheMixin';
    import { PetDisplayMixin } from '../Shared/Appointments/PetDisplayMixin';
    import { TimeDisplayMixin } from '../Shared/Time/TimeDisplayMixin';
    import DefaultPetImageSmall from '../Shared/Images/DefaultPetImageSmall.vue';
    import CalendarAppointmentAddressLines from './CalendarAppointmentAddressLines.vue';
    import CalendarAppointmentOrders from './CalendarAppointmentOrders.vue';
    import CalendarAppointmentNotes from './CalendarAppointmentNotes.vue';

    export default {
        name: 'CalendarAppointment',
        mixins: [ImageCacheMixin, PetDisplayMixin, TimeDisplayMixin, HistoryCacheMixin],
        props: {
            appointment: Object
        },
        computed: {
            petId() {
                return this.appointment.petId;
            },
            clientId() {
                return this.appointment.clientId;
            },
            image() {
                return this.images.length > 0 ? this.images[0] : null;
            },
            imageSrc() {
                return !!this.image ? this.image.thumbnails.small : '';
            },
            timeString() {
                return moment(this.appointment.startUtc).format(this.timeMomentJSFormat) + ' - ' +
                    moment(this.appointment.endUtc).format(this.timeMomentJSFormat);
            },
            showTime() {
                return this.userSettingValue.time;
            },
            showWarning() {
                return !!this.pet ? this.pet.behaviour.id === 3 : false;
            },
            showFirstTime() {
                return !!this.history ? (this.history.total === 0 ? true : false) : false;
            }
        },
        methods: {
            tryGetPets() {
                // do nothing
            },
            tryGetClient() {
                // do nothing
            },
            tryFetchBreed() {
                // do nothing
            },
            trySearchImages() {
                // do nothing
            },
            tryGetClientHistory() {
                // do nothing
            }
        },
        components: {
            'default-image': DefaultPetImageSmall,
            'address-lines': CalendarAppointmentAddressLines,
            'orders': CalendarAppointmentOrders,
            'appointment-notes': CalendarAppointmentNotes
        }
    }
</script>
