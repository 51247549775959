<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-pn" data-toggle="collapse">
            {{$t('Pet notes')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-pn">
            <div class="card card-body">
                <textarea v-model="noteText" class="form-control text-area-grow-focus note-section" cols="50" :placeholder="$t('Add notes (optional)')" rows="3"></textarea>
            </div>
        </div>
    </section>
</template>

<script>
    import { PetAddNoteMixin } from "./PetAddNoteMixin";

    export default {
        name: 'PetAddNotes',
        mixins: [PetAddNoteMixin]
    }
</script>