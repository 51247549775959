<template>
    <li class="tag-card pointer" v-bind:style="{ backgroundColor:tagColour }" @click="onClick">
        <i v-if="tag" class="far fa-check text-success"></i>
        {{tagType.name}}
    </li>
</template>

<script>
    export default {
        name: 'TagItem',
        props: {
            tagType: Object,
            tags: Array
        },
        computed: {
            tag() {
                return this.tags.find(t => t.tagTypeId === this.tagType.id);
            },
            tagColour() {
                return this.tagType.colour;
            }
        },
        methods: {
            onClick() {
                this.$emit('toggled', this.tagType);
            }
        }
    }
</script>
