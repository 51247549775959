import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ImageCacheMixin } from "./ImageCacheMixin";

export const ImageUpdateMixin = {
    mixins: [ImageCacheMixin],
    computed: {
        imageId() {
            console.log('!!!OVERRIDE COMPUTED - imageId!!!');
        },
        image() {
            return this.images.find(i => i.id === this.imageId);
        }
    },
    methods: {
        ...mapActions('Images', [
            'deleteImage'
        ]),
        tryDeleteImage() {
            const self = this;
            if (!self.image) {
                return;
            }
            self.error = '';
            self.deleteImage({ key: self.imageCacheKey, id: self.image.id })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
