<template>
    <table-mobile>
        <payment-failure-mobile v-for="failure in paymentFailures" :key="failure.id" :payment="failure.subscriptionPayment"></payment-failure-mobile>
    </table-mobile>
</template>

<script>
    import TableMobile from '../Shared/Layout/TableMobile.vue';
    import PaymentFailureItemMobile from './PaymentFailureItemMobile.vue';

    export default {
        name: 'PaymentFailureMobile',
        props: {
            paymentFailures: Array
        },
        components: {
            'payment-failure-mobile': PaymentFailureItemMobile,
            'table-mobile': TableMobile
        }
    }
</script>
