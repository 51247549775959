import { Api } from '../../services/Api';

const state = () => ({
    serviceProductCountCaches: [],
    serviceProductCountCacheCleared: 0
})

const getters = {
    findServiceProductCountCache: (state) => key => state.serviceProductCountCaches.find(spcc => spcc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.serviceProductCountCaches.find(spcc => spcc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.serviceProductCountCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setServiceProductCount(state, { key, count }) {
        const existing = state.serviceProductCountCaches.find(spcc => spcc.key === key);
        if (!!existing) {
            existing.value = count;
            existing.loading = false;
        } else {
            state.serviceProductCountCaches.push({ key: key, value: count, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.serviceProductCountCaches.find(spcc => spcc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearServiceProductCount(state) {
        state.serviceProductCountCaches = [];
        state.serviceProductCountCacheCleared++;
    }
}

const actions = {
    fetchServiceProductCount({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('ServiceProduct/Count')
                .then(response => {
                    commit('setServiceProductCount', { key: key, count: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
