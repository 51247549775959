<template>
    <div>
        <suggestion-add v-model="suggestionStatus"
                        :booking-id="id" 
                        :submit-event="submitEvent" 
                        :declined="bookingDeclined">
        </suggestion-add>
    </div>
</template>

<script>
/* eslint-disable */
    import { BookingDeclineMixin } from './BookingDeclineMixin';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import BookingSuggestionAdd from './BookingSuggestionAdd.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BookingDecline',
        mixins: [BookingDeclineMixin, StatusEmitterMixin],
        props: {
            id: String,
            submitEvent: Number,
        },
        data() {
            return {
                suggestionStatus: {},
                bookingDeclined: false
            }
        },
        computed: {
            bookingId() {
                return !!this.id ? this.id : '';
            },
            loading() {
                return !!this.declining || !!this.suggestionStatus.loading;
            },
            completed() {
                return !!this.bookingDeclined && !!this.suggestionStatus.completed;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canDeclineBooking() {
                return !this.bookingDeclined;
            }
        },
        watch: {
            submitEvent() {
                this.onValidatedChanged();
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canDeclineBooking) {
                    return;
                }
                this.tryDeclineBooking();
            },
            afterDeclineBooking(){
                this.bookingDeclined = true;
            }
        },
        components: {
            'suggestion-add': BookingSuggestionAdd,
            'error-banner': ErrorBanner
        }
    }
</script>
