import { mapGetters, mapActions } from 'vuex'
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AppointmentGroupCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentGroups', [
            'findAppointmentGroupCache'
        ]),
        groupId() {
            console.log('OVERRIDE COMPUTED - groupId');
            return '';
        },
        appointmentGroupCache() {
            return this.findAppointmentGroupCache(this.groupId);
        },
        appointmentGroup() {
            return !!this.appointmentGroupCache ? this.appointmentGroupCache.value : null;
        },
        appointments() {
            return !!this.appointmentGroup ? this.appointmentGroup.appointments : [];
        },
        loadingAppointmentGroup() {
            return !!this.appointmentGroupCache && this.appointmentGroupCache.loading;
        },
        completedAppointmentGroup() {
            return !!this.appointmentGroupCache && !this.appointmentGroupCache.loading;
        }
    },
    watch: {
        groupId() {
            this.refreshAppointmentGroup();
        }
    },
    methods: {
        ...mapActions('AppointmentGroups', [
            'fetchAppointmentGroup'
        ]),
        tryFetchAppointmentGroup() {
            const self = this;
            if (!self.groupId) {
                return;
            }
            self.error = '';
            self.fetchAppointmentGroup(self.groupId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshAppointmentGroup() {
            if (!this.appointmentGroupCache || !!this.appointmentGroupCache.retry) {
                this.tryFetchAppointmentGroup();
            }
        }
    },
    created() {
        this.refreshAppointmentGroup();
    }
}
