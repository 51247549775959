<template>
    <div class="form-group">
        <div class="dropdown bootstrap-select custom-select form-control">
            <button type="button" data-toggle="dropdown" role="combobox" class="btn dropdown-toggle btn-light">
                <div class="filter-option">
                    <div class="filter-option-inner">
                        <span class="text">{{$t('Click to search')}}... </span>
                    </div>
                </div>
            </button>
            <div class="dropdown-menu" style="">
                <div class="bs-searchbox">
                    <input v-model="searchTerm" type="search" autocomplete="off" role="combobox" class="form-control">
                </div>
                <div role="listbox" id="bs-select-1" tabindex="-1" class="inner show">
                    <ul role="presentation" class="dropdown-menu inner show">
                        <li v-for="option in options" :key="option.id">
                            <div class="dropdown-item">
                                <label class="custom-control custom-checkbox">
                                    <input v-model="option.selected" @click="onClick(option)" class="custom-control-input" type="checkbox">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{option.name}}</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <select-summary :value="value" @remove="removeItem" @clear="clearItems"></select-summary>
    </div>
</template>

<script>
    import { BreedSearchMixin } from "../Shared/BreedSearchMixin";
    import { mapGetters } from 'vuex';
    import BreedMultiSelectSummary from "./BreedMultiSelectSummary.vue";

    export default {
        name: 'BreedMultiSelectDropdown',
        mixins: [BreedSearchMixin],
        props: {
            value: Array
        },
        computed: {
            ...mapGetters('Breeds', [
                'findBreedCache'
            ]),
            options() {
                return this.breeds.map(b => Object.assign({}, b, {
                    selected: this.value.some(id => b.id === id)
                }));
            },
            selectedOptions() {
                return this.value.map(id => this.findBreedCache(id)).filter(b => !!b).map(b => b.value);
            }
        },
        methods: {
            clearItems() {
                this.$emit('input', []);
            },
            removeItem(item) {
                this.$emit('input', this.value.filter(id => id !== item.id));
            },
            onClick(option) {
                if (option.selected) {
                    this.removeItem(option);
                } else {
                    const selected = [...this.value];
                    selected.push(option.id);
                    this.$emit('input', selected);
                }
            }
        },
        components: {
            'select-summary': BreedMultiSelectSummary
        }
    }
</script>

<style scoped>
    .applied-filters {
        display: block;
        padding: initial
    }
</style>
