<template>
    <div class="row">
        <div class="col-md-8 offset-md-4">
            <div v-if="error" class="form-group has-feedback has-error">
                <label class="error">{{error}}</label>
            </div>
            <template v-else>
                <div class="alert alert-info mw-400 d-block" role="alert">
                    <div class="alert-content d-flex" data-toggle="collapse" href="#client-info" role="button" aria-expanded="false">
                        {{$t('Client info')}}:
                        <div v-if="history" class="star-rating ml-3">
                            <div v-if="history.total">
                                <i v-for="star in history.rating" class="fas fa-star"></i>
                            </div>
                            <template v-else>
                                {{$t('Not yet visited')}}
                            </template>
                        </div>
                        <div class="col-auto ml-auto">
                            <span v-if="loadingHistory" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-plus"></i>
                            </template>
                        </div>
                    </div>
                    <div id="client-info" class="client-info collapse mt-3">
                        <div class="row">
                            <div class="col-5">
                                <label>Last visit:</label>
                            </div>
                            <div class="col-6">
                                {{previousAppointment}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label>No shows:</label>
                            </div>
                            <div class="col-6">
                                {{noShows}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label>Total visits</label>
                            </div>
                            <div class="col-6">
                                {{total}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { HistoryCacheMixin } from '../../Shared/HistoryCacheMixin.js';

    export default {
        name: 'AppointmentClientHistory',
        mixins: [HistoryCacheMixin],
        props: {
            pet: Object
        },
        computed: {
            clientId() {
                return this.pet.clientId;
            }
        }
    }
</script>
