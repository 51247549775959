<template>
    <td @click="onClick">
        <span>
            {{$t(daysOfWeek[dayId])}}:
        </span>
        <span class="resource-hours">
            {{summary}}
        </span>
    </td>
</template>

<script>
    import { DaysOfWeekMixin } from '../../Shared/DaysOfWeekMixin';
    import { ResourceHoursDayMixin } from './ResourceHoursDayMixin';

    export default {
        name: 'ResourceHoursDayMobile',
        mixins: [ResourceHoursDayMixin, DaysOfWeekMixin],
        computed: {
            summary() {
                return !!this.hours ? this.workingSummary : this.$t('NOT SET');
            }
        }
    }
</script>

<style scoped>
    .resource-hours {
        float: right
    }
</style>
