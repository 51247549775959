export const ClientHelper = {
    getClientValidator() {
        return {
            isValid: false,
            firstName: '',
            lastName: '',
            check(model) {
                this.firstName = !!model.firstName ? '' : 'Please enter first name';
                this.lastName = !!model.lastName ? '' : 'Please enter last name';
                this.isValid = !this.firstName && !this.lastName;
            }
        }
    },
    getClientMessageAddValidator() {
        return {
            isValid: false,
            subject: '',
            body: '',
            validate(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.body = !!model.body ? '' : 'Please enter a body';
                this.isValid = !this.subject && !this.body;
            }
        }
    }
}
