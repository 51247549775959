import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const ClientVisitAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('ClientVisitAggregateSearches', [
            'findClientVisitAggregatesSearch'
        ]),
        aggregateSearch() {
            return this.findClientVisitAggregatesSearch(this.aggregateSearchKey);
        },
        clientVisitAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingClientVisitAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('ClientVisitAggregateSearches', [
            'searchClientVisitAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchClientVisitAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
