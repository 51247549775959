import { Api } from '../../services/Api';

const state = () => ({
    fetchingLogos: false,
    fetchedLogos: false,
    addingLogo: false,
    deletingLogo: false,
    logos: []
})

const mutations = {
    setFetching(state) {
        state.fetchedLogos = false;
        state.fetchingLogos = true;
    },
    setLogos(state, logos) {
        state.logos = logos;
        state.fetchedLogos = true;
        state.fetchingLogos = false;
    },
    setFailed(state) {
        state.fetchingLogos = false;
        state.fetchedLogos = false;
    },
    setAdding(state, adding) {
        state.addingLogo = !!adding;
    },
    appendLogo(state, logo) {
        state.logos.push(logo);
    },
    setDeleting(state, deleting) {
        state.deletingLogo = !!deleting;
    },
    removeLogo(state, siteId) {
        state.logos = state.logos.filter(l => l.siteId !== siteId);
    }
}

const actions = {
    findLogos({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Logo/Search')
                .then(response => {
                    commit('setLogos', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                })
        });
    },
    addLogo({ commit }, file) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            var form = new FormData();
            form.append('file', file);
            const config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            Api.post('Logo', form, config)
                .then(response => {
                    commit('appendLogo', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    deleteLogo({ commit }, { id, siteId }) {
        return new Promise((resolve, reject) => {
            commit('setDeleting', true);
            Api.delete('Logo/' + id)
                .then(response => {
                    commit('removeLogo', siteId);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setDeleting', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
