export const AddressHelper = {
    getDefaultAddress() {
        return {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postCode: ''
        }
    },
    getAddressValidator() {
        return {
            line1: '',
            postCode: '',
            isValid: false,
            check(address) {
                this.line1 = !!address.line1 ? '' : 'Please enter line 1';
                this.postCode = !!address.postCode ? '' : 'Please enter postcode';
                this.isValid = !this.line1 && !this.postCode;
            }
        }
    },
    getAddressLines(address) {
        return !!address ? [
            address.line1,
            address.line2,
            address.line3,
            address.town,
            address.county,
            address.postCode].filter(o => !!o) : [];
    }
}
