import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const CreditPackSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CreditPackSettings', [
            'findCreditPackSettingsCache'
        ]),
        creditTypeId() {
            console.log('OVERRIDE COMPUTED - creditTypeId');
            return 0;
        },
        creditPackSettingFilterModel() {
            return {
                creditTypeId: this.creditTypeId
            }
        },
        creditPackSettingFilterModelValid() {
            const model = this.creditPackSettingFilterModel;
            return !!model.creditTypeId;
        },
        creditPackSettingsCache() {
            return this.findCreditPackSettingsCache(this.creditTypeId);
        },
        creditPackSettings() {
            return !!this.creditPackSettingsCache ? this.creditPackSettingsCache.values : [];
        },
        loadingCreditPackSettings() {
            return !!this.creditPackSettingsCache && this.creditPackSettingsCache.loading;
        }
    },
    watch: {
        creditTypeId() {
            this.refreshCreditPackSettings();
        }
    },
    methods: {
        ...mapActions('CreditPackSettings', [
            'getCreditPackSettings'
        ]),
        refreshCreditPackSettings() {
            if (!this.creditPackSettingsCache || !!this.creditPackSettingsCache.retry) {
                this.tryGetCreditPackSettings();
            }
        },
        tryGetCreditPackSettings() {
            const self = this;
            if (!self.creditPackSettingFilterModelValid) {
                return;
            }
            self.error = '';
            self.getCreditPackSettings({ key: self.creditTypeId, payload: self.creditPackSettingFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCreditPackSettings();
    }
}
