import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapState, mapActions } from 'vuex';
import { ReminderHelper } from "../../helpers/ReminderHelper";
import { ReminderSettingCacheMixin } from "./ReminderSettingCacheMixin";

export const ReminderSettingAddMixin = {
    mixins: [ReminderSettingCacheMixin],
    data() {
        return {
            error: '',
            enabled: true,
            hoursPrior: 48,
            reminderSettingValidator: ReminderHelper.getReminderSettingValidator()
        }
    },
    computed: {
        ...mapState('ReminderSettings', [
            'addingReminderSetting'
        ]),
        reminderSettingAddModel() {
            return {
                enabled: this.enabled,
                hoursPrior: this.hoursPrior
            };
        },
        reminderSettingAddModelValid() {
            return !!this.siteId && this.reminderSettingValidator.isValid;
        }
    },
    methods: {
        ...mapActions('ReminderSettings', [
            'addReminderSetting'
        ]),
        refreshReminderSettingModel() {
            this.enabled = true;
            this.hoursPrior = 48;
        },
        tryAddReminderSetting() {
            const self = this;
            self.reminderSettingValidator.validate(self.reminderSettingAddModel);
            if (!self.reminderSettingAddModelValid) {
                return;
            }
            self.error = '';
            self.addReminderSetting(self.reminderSettingAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshReminderSettingModel();
    }
}
