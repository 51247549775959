import { StripoCacheMixin } from "./StripoCacheMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { StripoAddMixin } from "./StripoAddMixin";

export const StripoUpdateMixin = {
    mixins: [StripoCacheMixin, StripoAddMixin],
    computed: {
        stripoUpdateModel() {
            return !!this.stripoTemplate ? Object.assign({}, this.stripoAddModel, {
                id: this.stripoTemplate.id
            }) : {}
        },
        stripoUpdateModelValid() {
            return !!this.stripoUpdateModel.id && this.stripoAddModelValid;
        },
        stripoUpdateModelModified() {
            return this.html.replace(/\s/g, '') !== this.stripoTemplate.html.replace(/\s/g, '')
                || this.css.replace(/\s/g, '') !== this.stripoTemplate.css.replace(/\s/g, '');
        }
    },
    watch: {
        stripoTemplate() {
            this.refreshStripoTemplateModel();
        }
    },
    methods: {
        ...mapActions('StripoTemplates', [
            'updateStripoTemplate'
        ]),
        tryUpdateStripoTemplate() {
            const self = this;
            if (!self.stripoUpdateModelValid || !self.stripoUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateStripoTemplate(self.stripoUpdateModel)
                .then(response => self.afterStripoTemplateUpdated(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshStripoTemplateModel() {
            this.html = !!this.stripoTemplate ? this.stripoTemplate.html : !!this.defaultStripoTemplate ? this.defaultStripoTemplate.html : '';
            this.css = !!this.stripoTemplate ? this.stripoTemplate.css : !!this.defaultStripoTemplate ? this.defaultStripoTemplate.css : '';
        },
        afterStripoTemplateUpdated() {
            // override this if necessary
        }
    },
    created() {
        this.refreshStripoTemplateModel();
    }
}
