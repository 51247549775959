import { mapActions, mapMutations } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { WeightAddMixin } from "./WeightAddMixin";

export const WeightUpdateMixin = {
    mixins: [WeightAddMixin],
    computed: {
        weightId() {
            console.log('!!!OVERRIDE COMPUTED - weightId!!!');
        },
        weight() {
            return this.weights.find(w => w.id === this.weightId);
        }
    },
    methods: {
        ...mapMutations('ApplicationEvents', [
            'triggerWeightDeleted'
        ]),
        ...mapActions('Weights', [
            'deleteWeight'
        ]),
        tryDeleteWeight() {
            const self = this;
            if (!self.weight) {
                return;
            }
            self.error = '';
            self.deleteWeight({ key: self.petId, id: self.weightId })
                .then(() => self.triggerWeightDeleted())
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
