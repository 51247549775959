<template>
    <div class="upgrade-display">
        <div class="content">
            <div class="d-md-flex align-items-center">
                <div class="col-12 col-md-5">
                    <div class="h1 upgrade-display_question">{{$t('Want to have access to more features?')}}</div>
                    <div class="my-4">
                        {{$t("Unleash a world of tail-wagging possibilities by upgrading your subscription today! Don't miss out on this golden opportunity to take your business to new heights of success.")}}
                    </div>
                    <div class="mb-3 mb-md-5 h6" style="color: #000;">
                        {{$t("Upgrade now and pamper your clients' furry friends like never before!")}}
                    </div>
                    <button v-if="showUpgrades" class="upgrade-display_button" @click="goToPricingPlan">{{$t('Get Started')}}</button>
                </div>
                <div class="col-12 col-md-7 mt-2">
                    <img src="../../assets/images/subscription-landing.png" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SubscriptionSettingsCacheMixin } from "../../mixins/Subscriptions/SubscriptionSettingsCacheMixin";
    import router from "../../routing";

    export default {
        name: 'SubscriptionUpsellDisplay',
        mixins: [SubscriptionSettingsCacheMixin],
        methods: {
            goToPricingPlan() {
                router.push({ name: 'store', query: { tab: 3 } });
            }
        }
    }
</script>

<style scoped>
    .upgrade-display {
        background-color: #FFE781;
        height: 100%;
        width: 100%;
        padding-top: 2rem;
    }
    .upgrade-display_question {
        color: #0A4F70;
        font-weight: 700;
    }
    .upgrade-display_button {
        border: none;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        font-weight: 700;
        color: #FFF;
        background: #F2205C;
    }
</style>
