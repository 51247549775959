import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { EventHelper } from "../../../helpers/EventHelper";
import { EventCacheMixin } from "./EventCacheMixin";
import { SettingCacheMixin } from "../SettingCacheMixin";
import { SiteResourceEventColourSettingMixin } from "../../../mixins/Settings/Site/SiteResourceEventColourSettingMixin";
import moment from 'moment';

export const EventUpdateMixin = {
    mixins: [EventCacheMixin, SettingCacheMixin, SiteResourceEventColourSettingMixin],
    data() {
        return {
            startUtc: '',
            endUtc: '',
            eventTypeId: 0,
            colour: '#FFFFF',
            eventValidator: EventHelper.getEventUpdateValidator()
        }
    },
    computed: {
        eventType() {
            return !!this.event ? this.event.eventType : null;
        },
        eventTypeName() {
            return !!this.eventType ? this.eventType.name : 'Unknown';
        },
        defaultEventTypeColour() {
            return !!this.settingObject[this.eventTypeName] ? this.settingObject[this.eventTypeName] : this.settingObject['Unknown'];
        },
        eventUpdateModel() {
            return !!this.event ? Object.assign({}, this.event, {
                eventType: Object.assign({}, this.event.eventType, { id: this.eventTypeId }),
                colour: this.colour,
                startUtc: this.startUtc,
                endUtc: this.endUtc
            }) : {};
        },
        eventUpdateModelValid() {
            return this.eventUpdateModel.id && this.eventValidator.isValid;
        },
        eventUpdateModelModified() {
            return JSON.stringify(this.eventUpdateModel) !== JSON.stringify(this.event);
        }
    },
    watch: {
        event() {
            this.refreshEventUploadModel();
        }
    },
    methods: {
        ...mapActions('Events', [
            'updateEvent'
        ]),
        refreshEventUploadModel() {
            this.eventTypeId = !!this.event ? this.event.eventType.id : 0;
            this.colour = !!this.event ? !!this.event.colour ? this.event.colour : this.defaultEventTypeColour : this.defaultEventTypeColour;
            this.startUtc = !!this.event ? moment(this.event.startUtc).utc().format() : '';
            this.endUtc = !!this.event ? moment(this.event.endUtc).utc().format() : '';
        },
        tryUpdateEvent() {
            const self = this;
            self.eventValidator.check(self.eventUpdateModel);
            if (!self.eventUpdateModelValid) {
                return;
            }
            if (!self.eventUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateEvent(self.eventUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEventUploadModel();
    }
}
