<template>
    <table-mobile @next="next">
        <booking-mobile v-for="booking in bookings" :key="booking.id" :booking="booking"></booking-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import BookingItemMobile from "./BookingItemMobile.vue";

    export default {
        name: 'BookingsMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('BookingSearches', [
                'findBookingSearches'
            ]),
            bookingSearches() {
                return this.findBookingSearches(this.searchKey);
            },
            bookings() {
                return [].concat(...this.bookingSearches.map(bs => bs.values));
            },
            loading() {
                return this.bookingSearches.some(bs => !!bs.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.bookings.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'booking-mobile': BookingItemMobile
        }
    }
</script>
