import { Api } from '../../services/Api';

const state = () => ({
    unavailabilityCaches: []
})

const getters = {
    findUnavailabilityCache: (state) => (key) => state.unavailabilityCaches.find(uc => uc.key === key)
}

const mutations = {
    addUnavailabilityCache(state, unavailabilityCache) {
        const local = state.unavailabilityCaches.filter(uc => uc.key !== unavailabilityCache.key);
        local.push(unavailabilityCache);
        state.unavailabilityCaches = local;
    },
    setRetry(state, key) {
        const existing = state.unavailabilityCaches.find(uc => uc.key !== key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchUnavailabilities({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('addUnavailabilityCache', { key: key, values: [], loading: true });
            Api.post('Unavailability', payload)
                .then(response => {
                    commit('addUnavailabilityCache', { key: key, values: response.data, loading: false });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
