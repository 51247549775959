import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { SiteIdMixin } from './Site/SiteIdMixin.js';
import process from 'process';
import { ScheduleAddConflictsMixin } from './Appointments/ScheduleAddConflictsMixin.js';
import { TimeZoneMixin } from './Time/Zone/TimeZoneMixin.js';

export const AppointmentScheduleAddMixin = {
    mixins: [SiteIdMixin, ScheduleAddConflictsMixin, TimeZoneMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'appointmentScheduleAddedEvent'
        ]),
        ...mapGetters('AppointmentScheduleUpload', [
            'scheduleAddModel',
            'scheduleAddModelValidation',
            'indexAppointmentOrder'
        ]),
        appointmentScheduleAddModel() {
            return Object.assign({}, this.scheduleAddModel, {
                timeZoneId: this.selectedTimeZoneId
            });
        },
        pet() {
            return this.indexAppointmentOrder(0).pet;
        },
        appointmentScheduleAddModelValid() {
            return this.scheduleAddModelValidation.isValid;
        }
    },
    watch: {
        appointmentScheduleAddedEvent() {
            process.nextTick(() => {
                this.setDefault();
            })
        }
    },
    methods: {
        ...mapMutations('AppointmentScheduleUpload', [
            'setDefault'
        ]),
        ...mapActions('AppointmentSchedules', [
            'addAppointmentSchedule'
        ]),
        tryAddAppointmentSchedule() {
            const self = this;
            self.error = '';
            if (!self.appointmentScheduleAddModelValid) {
                return;
            }
            self.addAppointmentSchedule({ payload: self.appointmentScheduleAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
