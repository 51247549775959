import { StatusEmitterMixin } from "../../Shared/StatusEmitterMixin";
import { AccountSettingAddMixin } from "./AccountSettingAddMixin";

export const AccountSettingPostRegisterMixin = {
    mixins: [AccountSettingAddMixin, StatusEmitterMixin],
    props: {
        registrationInfo: Object
    },
    computed: {
        accountSettingEditModelValid() {
            return true;
        },
        canUploadSetting() {
            return !this.completed;
        },
        completed() {
            return !this.registrationInfo || !!this.accountSetting;
        },
        loading() {
            return this.loadingAccountSetting;
        },
        status() {
            return {
                error: this.error,
                completed: this.completed,
                loading: this.loading
            }
        }
    },
    watch: {
        canUploadSetting(value) {
            if (!!value) {
                this.tryAddAccountSetting();
            }
        }
    },
    created() {
        if (this.canUploadSetting) {
            this.tryAddAccountSetting();
        }
    }
}
