import { DateDisplayMixin } from "../../components/Shared/Time/DateDisplayMixin";
import { TimeDisplayMixin } from "../../components/Shared/Time/TimeDisplayMixin";
import { mapMutations } from 'vuex';
import moment from 'moment';

export const AppointmentSectionItemMixin = {
    mixins: [DateDisplayMixin, TimeDisplayMixin],
    props: {
        appointment: {
            required: true,
            type: Object
        },
        statusClass: String
    },
    computed: {
        startUtcMoment() {
            return moment(this.appointment.startUtc);
        },
        startDate() {
            return this.startUtcMoment.format(this.momentJSFormat);
        },
        startTime() {
            return this.startUtcMoment.format(this.timeMomentJSFormat);
        },
        createdDateMoment() {
            return moment(this.appointment.createdUtc);
        },
        createdDate() {
            return this.createdDateMoment.format(this.momentJSFormat);
        }
    },
    methods: {
        ...mapMutations('ApplicationEvents', [
            'triggerViewAppointment'
        ]),
        onClick() {
            this.triggerViewAppointment(this.appointment);
        }
    }
}
