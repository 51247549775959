import { Api } from '../../services/Api';

const state = () => ({
    searchingUserClaims: false,
    updatingUserClaims: false,
    fetchingUserClaims: false,
    fetchedUserClaims: false,
    userClaims: []
})

const mutations = {
    setSearching(state, searching) {
        state.searchingUserClaims = !!searching;
    },
    setUpdating(state, updating) {
        state.updatingUserClaims = !!updating;
    },
    setFetching(state, fetching) {
        state.fetchingUserClaims = !!fetching;
    },
    setFetched(state, fetched) {
        state.fetchedUserClaims = !!fetched;
    },
    appendUserClaims(state, claims) {
        const local = [...state.userClaims];
        for (var i = 0; i < claims.length; i++) {
            local.push(claims[i]);
        }
        state.userClaims = local;
    },
    insertUserClaim(state, claim) {
        const existing = state.userClaims.find(uc => uc.userId === claim.userId);
        state.userClaims.splice(state.userClaims.indexOf(existing), 1, claim);
    }
}

const actions = {
    getUserClaims({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('UserClaims')
                .then(response => {
                    commit('appendUserClaims', [response.data]);
                    commit('setFetched', true);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setFetching', false));
        });
    },
    searchUserClaims({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setSearching', true);
            Api.post('UserClaims/Search', payload)
                .then(response => {
                    commit('appendUserClaims', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setSearching', false));
        });
    },
    updateUserClaims({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('UserClaims', payload)
                .then(response => {
                    commit('insertUserClaim', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}