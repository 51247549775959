import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const EventCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        eventId: String
    },
    computed: {
        ...mapGetters('Events', [
            'findEventCache'
        ]),
        eventCache() {
            return this.findEventCache(this.eventId);
        },
        event() {
            return !!this.eventCache ? this.eventCache.value : null;
        },
        eventDescription() {
            return !!this.event ? this.event.eventType.description : '';
        },
        loadingEvent() {
            return !!this.eventCache && this.eventCache.loading;
        }
    },
    watch: {
        eventId() {
            this.refreshEvent();
        }
    },
    methods: {
        ...mapActions('Events', [
            'getEvent',
            'deleteEvent'
        ]),
        refreshEvent() {
            if (!this.eventCache || this.eventCache.retry) {
                this.tryGetEvent();
            }
        },
        tryGetEvent() {
            const self = this;
            if (!self.eventId) {
                return;
            }
            self.error = '';
            self.getEvent(self.eventId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteEvent() {
            const self = this;
            if (!self.eventId) {
                return;
            }
            self.error = '';
            self.deleteEvent(self.eventId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEvent();
    }
}
