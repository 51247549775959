import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { EventHelper } from "../../../helpers/EventHelper"

export const EventsAddMixin = {
    data() {
        return {
            error: '',
            resourceIds: [],
            colour: '#FFFFFF',
            startUtc: '',
            endUtc: '',
            eventTypeId: 0,
            notes: '',
            eventValidator: EventHelper.getEventAddValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'eventsAddedEvent'
        ]),
        eventAddModel() {
            return {
                resourceIds: this.resourceIds,
                colour: this.colour,
                startUtc: this.startUtc,
                endUtc: this.endUtc,
                eventTypeId: this.eventTypeId,
                notes: this.notes
            }
        },
        eventAddModelValid() {
            return this.eventValidator.isValid;
        }
    },
    watch: {
        eventsAddedEvent() {
            this.clearUploadModel();
        }
    },
    methods: {
        ...mapActions('Events', [
            'addEvents'
        ]),
        clearUploadModel() {
            this.resourceIds = [];
            this.colour = '#FFFFFF';
            this.startUtc = '';
            this.endUtc = '';
            this.eventTypeId = 0;
            this.notes = '';
        },
        addAndCacheEvents() {
            const self = this;
            self.eventValidator.check(self.eventAddModel);
            if (!self.eventAddModelValid) {
                return;
            }
            self.error = '';
            self.addEvents(self.eventAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
