<template>

</template>

<script>
    import { AddressSearchMixin } from "../../../mixins/Addresses/AddressSearchMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'ClientAddressesSearch',
        mixins: [AddressSearchMixin, StatusEmitterMixin],
        props: {
            clientIds: Array
        },
        computed: {
            ownerTypeId() {
                return 2;
            },
            ownerIds() {
                return !!this.clientIds ? this.clientIds : [];
            },
            status() {
                return {
                    loading: this.loadingAddressSearch
                }
            }
        }
    }
</script>
