<template>
    <div v-if="displaySite" class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Sites')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <site-dropdown v-model="siteIds"></site-dropdown>
            <validation-message v-if="validationError" :message="validationError"></validation-message>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../SiteCacheMixin";
    import SiteMultiSelectDropdown from "./SiteMultiSelectDropdown.vue";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'SiteMultiSelect',
        mixins: [SiteCacheMixin],
        props: {
            value: Array,
            validationError: String
        },
        computed: {
            siteIds: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'site-dropdown': SiteMultiSelectDropdown,
            'validation-message': ValidationMessage
        }
    }
</script>
