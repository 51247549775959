export const CalendarStaffEventSettingMixin = {
    data() {
        return {
            displayType: 0
        }
    },
    computed: {
        defaultUserSettingValue() {
            return {
                displayType: 0
            }
        },
        userSettingKey() {
            return 'CalendarStaffEvent'
        }
    }
}
