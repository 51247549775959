<template>
    <div class="applied-filters d-none d-md-flex">
        <div v-for="filter in filters" :key="filter.id" @click="filter.delete" class="active-filter">
            <span class="text-truncate">{{filter.name}}</span>
        </div>
        <a v-if="filters.length > 0" @click="clearListFilters" class="clear-filters pointer">Clear filters</a>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { AllResourcesMixin } from '../Shared/Resources/AllResourcesMixin.js';
    import { SiteCacheMixin } from '../Shared/SiteCacheMixin.js';

    export default {
        name: 'ScheduleFilterList',
        mixins: [SiteCacheMixin, AllResourcesMixin],
        computed: {
            ...mapState('ScheduleFilter', [
                'resourceIds',
                'siteIds',
                'selectedSiteId'
            ]),
            filters() {
                return this.siteIds.filter(id => id !== this.selectedSiteId).map(id => {
                    const site = this.sites.find(s => s.id === id);
                    return !!site ? {
                        id: site.id,
                        name: site.name,
                        delete: () => this.deleteSiteId(site.id)
                    } : null;
                }).concat(this.resourceIds.map(id => {
                    const resource = this.allActiveResources.find(r => r.id === id);
                    return !!resource ? {
                        id: resource.id,
                        name: resource.name,
                        delete: () => this.deleteResourceId(resource.id)
                    } : null;
                })).filter(f => !!f);
            }
        },
        methods: {
            ...mapMutations('ScheduleFilter', [
                'clearListFilters',
                'deleteResourceId',
                'deleteSiteId'
            ])
        }
    }
</script>