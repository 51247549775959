<template>
    <div>
        <button ref="displayButton" type="button" data-target="#system-admin-notification-confirmation-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="system-admin-notification-confirmation-modal" title="Confirm Notification?">
            <section class="rounded-card-white mb-3">
                <div class="text-center">
                    <div v-if="isHtml" v-html="message"></div>
                    <template v-else>
                        <p>{{message}}</p>
                    </template>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">Cancel</button>
                <button @click="proceed" type="button" class="btn btn-primary">
                    Proceed
                </button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import ModalDialog from "../Layout/ModalDialog.vue";

    export default {
        name: 'SystemAdminGeneralNotificationsConfirmation',
        props: {
            notification: Object,
            value: Number
        },
        watch: {
            value() {
                this.$refs.displayButton.click();
            }
        },
        computed: {
            message() {
                return !!this.notification ? this.notification.text : '';
            },
            isHtml() {
                return !!this.notification ? this.notification.isHtml : '';
            }
        },
        methods: {
            proceed() {
                this.$refs.cancelButton.click();
                this.$emit('proceed');
            }
        },
        components: {
            'modal-dialog': ModalDialog
        }
    }
</script>