<template>

</template>

<script>
    import { AppointmentOrdersMixin } from "../../mixins/Orders/AppointmentOrdersMixin";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'AppointmentOrdersSearch',
        mixins: [AppointmentOrdersMixin, StatusEmitterMixin],
        props: {
            appointments: []
        },
        computed: {
            appointmentIds() {
                return this.appointments.map(a => a.id);
            },
            status() {
                return {
                    loading: this.fetchingAppointmentOrders
                }
            }
        }
    }
</script>
