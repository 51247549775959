import { CampaignHelper } from "../../helpers/CampaignHelper";
import { SmsCampaignTemplatesCacheMixin } from "./SmsCampaignTemplatesCacheMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SmsCampaignTemplateAddMixin = {
    mixins: [SmsCampaignTemplatesCacheMixin],
    data() {
        return {
            subject: '',
            body: '',
            smsCampaignTemplateValidator: CampaignHelper.getSmsCampaignTemplateValidator()
        }
    },
    computed: {
        smsCampaignTemplateAddModel() {
            return {
                subject: this.subject,
                body: this.body
            }
        },
        smsCampaignTemplateAddModelValid() {
            return this.smsCampaignTemplateValidator.isValid && this.siteId;
        }
    },
    methods: {
        ...mapActions('SmsCampaignTemplates', [
            'addSmsCampaignTemplate'
        ]),
        tryAddSmsCampaignTemplate() {
            const self = this;
            self.smsCampaignTemplateValidator.check(self.smsCampaignTemplateAddModel);
            if (!self.smsCampaignTemplateAddModelValid) {
                return;
            }
            self.error = '';
            self.addSmsCampaignTemplate({ key: self.siteId, payload: self.smsCampaignTemplateAddModel })
                .then(() => self.afterSmsCampaignTemplateAdd())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterSmsCampaignTemplateAdd() {
            // override this
        }
    }
}
