import router from '../../../routing';

export const CampaignSmsTemplateItemMixin = {
    props: {
        template: Object
    },
    computed: {
        subject() {
            return !!this.template ? this.template.subject : '';
        },
        startLetter() {
            return this.subject.length > 0 ? this.subject[0] : '';
        },
        templateId() {
            return !!this.template ? this.template.id : '';
        }
    },
    methods: {
        gotoTemplateEdit() {
            router.push({ name: 'campaignSmsTemplateUpdate', params: { id: this.templateId } });
        }
    }
}
