<template>

</template>

<script>
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { SmsSettingAddMixin } from '../../Communication/Settings/SmsSettingAddMixin';

    export default {
        name: 'PostRegisterSmsSetting',
        mixins: [SmsSettingAddMixin, StatusEmitterMixin],
        computed: {
            canAddSmsSetting() {
                return !!this.loadedSmsSettings && !this.smsSetting && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingSmsSetting,
                    completed: !!this.smsSetting
                }
            }
        },
        watch: {
            canAddSmsSetting() {
                this.tryUploadSmsSetting();
            }
        },
        methods: {
            tryUploadSmsSetting() {
                if (this.canAddSmsSetting) {
                    this.tryAddSmsSetting();
                }
            }
        },
        created() {
            this.tryUploadSmsSetting();
        }
    }
</script>
