<template>
    <div class="pricing-plan_card m-3" v-bind:class="{'pricing-plan_card-current-plan': currentPlan}" v-bind:style="{'border-color': colour}">
        <div class="d-flex flex-column align-items-center">
            <div class="pricing-plan_card-header" v-bind:class="{'pricing-plan_card-header-current-plan': currentPlan}"  v-bind:style="{ 'background-color': colour }">
                <div class="pricing-plan_card-title mt-2">{{ subscriptionTypeDescription }}</div>
                <div class="pricing-plan_card-title-sub" v-bind:style="{ 'color': textColour }">{{ tagline }}</div>
                <div class="pricing-plan_card-money">
                    {{ priceString }}
                    <span v-if="multiSite" class="pricing-plan_card-title-sub">{{$t('per')}} {{$t('Site')}}</span>
                </div>
                <div class="pricing-plan_card-duration" v-bind:style="{ 'color': textColour }">({{ duration }})</div>
            </div>
            <div class="pricing-plan_card-body text-center">
                <img v-if="subscriptionTypeId === 4" src="../../assets/images/subscription-popular.png" class="pricing-plan_card-popular" />
                <feature-list :feature="subscriptionUpgradeFeature"></feature-list>
                <div v-if="multiSite" class="form-group">
                    <label align="center">{{$t('Additional Sites:')}}</label>
                    <div class="def-number-input number-input safari_only">
                        <button class="minus" @click="removeSite" v-bind:disabled="current"></button>
                        <input v-model="additionalSites" class="quantity" min="0" type="number" @blur="setSites">
                        <button class="plus" @click="addSite"></button>
                    </div>
                </div>
                <template>
                    <button v-if="current" class="pricing-plan_card-body-button text-white" v-bind:style="{'background-color': colour}">
                        {{$t('Current plan')}}
                    </button>
                    <button v-else @click="onClick" class="pricing-plan_card-body-button pricing-plan_card-body-button-subscription" v-bind:disabled="loading">
                        {{$t('Choose this plan')}}
                        <span v-if="loading" class="inline-loader"></span>
                        <i v-else class="fa fa-arrow-right text-white"></i>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { SubscriptionCacheMixin } from "../../mixins/Subscriptions/SubscriptionCacheMixin";
    import { SubscriptionUpgradeFeaturesCacheMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeFeaturesCacheMixin";
    import { SubscriptionUpgradeSettingsCacheMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeSettingsCacheMixin";
    import SubscriptionFeatureList from "./SubscriptionFeatureList.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SubscriptionUpgradeType',
        mixins: [SubscriptionCacheMixin, SubscriptionUpgradeSettingsCacheMixin, SubscriptionUpgradeFeaturesCacheMixin],
        props: {
            subscriptionType: {
                type: Object,
                required: true
            },
            selectedUpgrade: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                additionalSites: 0,
                currentPlan: false
            }
        },
        computed: {
            ...mapState('SubscriptionUpgrade', [
                'addingSubscriptionUpgrade'
            ]),
            subscriptionTypeId() {
                return this.subscriptionType.id;
            },
            loading() {
                return this.addingSubscriptionUpgrade && this.selectedUpgrade.subscriptionTypeId === this.subscriptionTypeId;
            },
            subscriptionTypeDescription() {
                return this.subscriptionType.description;
            },
            subscriptionUpgradeFeature() {
                return this.subscriptionUpgradeFeatures.find(f => f.subscriptionType.id === this.subscriptionTypeId);
            },
            subscriptionUpgradeSetting() {
                return this.subscriptionUpgradeSettings.find(s => s.subscriptionType.id === this.subscriptionTypeId);
            },
            current() {
                return !!this.subscription && this.subscription.subscriptionType.id === this.subscriptionTypeId && this.subscription.additionalSites === this.additionalSites;
            },
            tagline() {
                return !!this.subscriptionUpgradeSetting ? this.subscriptionUpgradeSetting.tagline : '';
            },
            yearly() {
                return this.selectedUpgrade.yearly;
            },
            currency() {
                return this.selectedUpgrade.currency;
            },
            currencySymbol() {
                return this.currency === 'GBP' ? '£' : '$';
            },
            price() {
                const price = parseFloat(!!this.subscriptionUpgradeSetting ?
                    (this.yearly ? this.subscriptionUpgradeSetting.yearlyPrices[this.currency] : this.subscriptionUpgradeSetting.monthlyPrices[this.currency]) : 0);
                return isNaN(price) ? 0 : price;
            },
            priceString() {
                return this.currencySymbol + this.price.toFixed(0);
            },
            duration() {
                return `${this.$t('per')} ${this.yearly ? this.$t('year') : this.$t('month')}`;
            },
            colour() {
                return !!this.subscriptionUpgradeSetting ? this.subscriptionUpgradeSetting.colour : '#FFFFFF';
            },
            textColour() {
                return !!this.subscriptionUpgradeSetting ? this.subscriptionUpgradeSetting.textColour : '#FFFFFF';
            },
            multiSite() {
                return !!this.subscriptionUpgradeFeature && this.subscriptionUpgradeFeature.multiSite;
            }
        },
        watch: {
            subscription() {
                this.refreshData();
            },
            subscriptionUpgradeFeature() {
                this.refreshData();
            },
            current(value) {
                if (!!value) {
                    this.currentPlan = value;
                }
            }
        },
        methods: {
            onClick() {
                this.$emit('upgradeSelected', {
                    subscriptionTypeId: this.subscriptionTypeId,
                    additionalSites: this.additionalSites
                });
            },
            addSite() {
                this.additionalSites++;
            },
            removeSite() {
                if (this.additionalSites > 0) {
                    this.additionalSites--;
                }
            },
            setSites() {
                const count = parseInt(this.additionalSites);
                this.additionalSites = isNaN(count) || count < 0 ? 0 : count;
            },
            refreshData() {
                this.additionalSites = !!this.subscription && this.multiSite ? this.subscription.additionalSites : 0;
            }
        },
        components: {
            'feature-list': SubscriptionFeatureList
        },
        mounted() {
            this.currentPlan = this.current;
        },
        created() {
            this.refreshData();
        }
    }
</script>
