import { ErrorHelper } from "../../helpers/ErrorHelper";
import { MigrationCacheMixin } from "./MigrationCacheMixin";
import { mapActions } from 'vuex';

export const MigrationUploadMixin = {
    mixins: [MigrationCacheMixin],
    data() {
        return {
            email: '',
            password: '',
            migrationError: ''
        }
    },
    computed: {
        migrationUploadModel() {
            return {
                email: this.email,
                password: this.password
            }
        },
        migrationUploadModelValid() {
            const model = this.migrationUploadModel;
            return !!this.siteId && !!model.email && !!model.password;
        }
    },
    methods: {
        ...mapActions('Migrations', [
            'runMigration'
        ]),
        migrationStarted() {
            // to override
        },
        tryRunMigration() {
            const self = this;
            if (!self.migrationUploadModelValid) {
                return;
            }
            self.error = '';
            self.migrationError = '';
            self.migrationStarted();
            self.runMigration({ key: self.siteId, payload: self.migrationUploadModel })
                .then(response => {
                    self.afterRunMigration(response.data);
                })
                .catch(error => self.migrationError = ErrorHelper.getError(error));
        },
        afterRunMigration() {
            //override if necessary
        }
    }
}
