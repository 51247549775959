<template>
    <div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="marketing" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Accept marketing')}}</span>
            </label>
        </div>
        <div class="mb-2 d-flex align-items-center">
            <label class="ps-toggle-switch">
                <input v-model="smsAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : smsAppointmentsPartiallyEnabled}"></div>
                <span class="d-inline-block ml-2">{{$t('SMS Appointment')}}</span>
            </label>
            <i ref="sms-appointments-ref" aria-expanded="false" data-target="#sms-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
        </div>
        <div class="collapse" id="sms-appointments">
            <div class="appointment-notifications-body">
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsCancellation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Cancellation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsUpdated" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Updated')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsReminder" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Reminder')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsConfirmation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Confirmation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsThankYou" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Thank You')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsNoShow" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('No Show')}}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="smsMissYou" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Miss You (SMS)')}}</span>
            </label>
        </div>
        <div class="mb-2 d-flex align-items-center">
            <label class="ps-toggle-switch">
                <input v-model="emailAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : emailAppointmentsPartiallyEnabled}"></div>
                <span class="d-inline-block ml-2">{{$t('Email Appointment')}}</span>
            </label>
            <i ref="email-appointments-ref" aria-expanded="false" data-target="#email-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
        </div>
        <div class="collapse" id="email-appointments">
            <div class="appointment-notifications-body">
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailCancellation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Cancellation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailUpdated" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Updated')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailReminder" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Reminder')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailConfirmation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Confirmation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailThankYou" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Thank You')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailNoShow" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('No Show')}}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="emailMissYou" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Miss You (Email)')}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotificationSettingsEdit',
        data() {
            return {
                marketing: false,
                smsCancellation: false,
                smsUpdated: false,
                smsReminder: false,
                smsConfirmation: false,
                smsThankYou: false,
                smsNoShow: false,
                smsMissYou: false,
                emailCancellation: false,
                emailUpdated: false,
                emailReminder: false,
                emailConfirmation: false,
                emailThankYou: false,
                emailNoShow: false,
                emailMissYou: false
            }
        },
        computed: {
            smsAppointmentsEnabled: {
                get() {
                    return !!this.smsCancellation || !!this.smsUpdated || !!this.smsReminder || !!this.smsConfirmation || !!this.smsThankYou || !!this.smsNoShow;
                },
                set(value) {
                    this.smsCancellation = !!value;
                    this.smsUpdated = !!value;
                    this.smsReminder = !!value;
                    this.smsConfirmation = !!value;
                    this.smsThankYou = !!value;
                    this.smsNoShow = !!value;
                }
            },
            emailAppointmentsEnabled: {
                get() {
                    return !!this.emailCancellation || !!this.emailUpdated || !!this.emailReminder || !!this.emailConfirmation || !!this.emailThankYou || !!this.emailNoShow;
                },
                set(value) {
                    this.emailCancellation = !!value;
                    this.emailUpdated = !!value;
                    this.emailReminder = !!value;
                    this.emailConfirmation = !!value;
                    this.emailThankYou = !!value;
                    this.emailNoShow = !!value;
                }
            },
            smsAppointmentsPartiallyEnabled() {
                return (!!this.smsCancellation || !!this.smsUpdated || !!this.smsReminder || !!this.smsConfirmation || !!this.smsThankYou || !!this.smsNoShow) &&
                    !(this.smsCancellation && this.smsUpdated && this.smsReminder && this.smsConfirmation && this.smsThankYou && this.smsNoShow);
            },
            emailAppointmentsPartiallyEnabled() {
                return (!!this.emailCancellation || !!this.emailUpdated || !!this.emailReminder || !!this.emailConfirmation || !!this.emailThankYou || !!this.emailNoShow) &&
                    !(this.emailCancellation && this.emailUpdated && this.emailReminder && this.emailConfirmation && this.emailThankYou && this.emailNoShow);
            },
            settings() {
                return {
                    marketing: this.marketing,
                    smsCancellation: this.smsCancellation,
                    smsUpdated: this.smsUpdated,
                    smsReminder: this.smsReminder,
                    smsConfirmation: this.smsConfirmation,
                    smsThankYou: this.smsThankYou,
                    smsNoShow: this.smsNoShow,
                    smsMissYou: this.smsMissYou,
                    emailCancellation: this.emailCancellation,
                    emailUpdated: this.emailUpdated,
                    emailReminder: this.emailReminder,
                    emailConfirmation: this.emailConfirmation,
                    emailThankYou: this.emailThankYou,
                    emailNoShow: this.emailNoShow,
                    emailMissYou: this.emailMissYou
                }
            }
        },
        watch: {
            settings(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
</script>
