import { Api } from "../../services/Api";

const state = () => ({
    subscriptionPaymentCaches: []
})

const getters = {
    findSubscriptionPaymentCache: (state) => paymentId => state.subscriptionPaymentCaches.find(spc => spc.key === paymentId)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.subscriptionPaymentCaches.find(spc => spc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.subscriptionPaymentCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setSubscriptionPayment(state, payment) {
        const existing = state.subscriptionPaymentCaches.find(spc => spc.key === payment.id);
        if (!!existing) {
            existing.value = payment
            existing.loading = false;
        } else {
            state.subscriptionPaymentCaches.push({ key: payment.id, value: payment, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.subscriptionPaymentCaches.find(spc => spc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setSubscriptionPayments(state, payments) {
        for (var i = 0; i < payments.length; i++) {
            const payment = payments[i];
            const existing = state.subscriptionPaymentCaches.find(spc => spc.key === payment.id);
            if (!!existing) {
                existing.value = payment
                existing.loading = false;
            } else {
                state.subscriptionPaymentCaches.push({ key: payment.id, value: payment, loading: false });
            }
        }
    }
}

const actions = {
    fetchSubscriptionPayment({ commit }, paymentId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: paymentId, loading: true });
            Api.get('SubscriptionPayment/' + paymentId)
                .then(response => {
                    commit('setSubscriptionPayment', response.data);
                    commit('SubscriptionEntityPayments/insertSubscriptionEntityPayment', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', paymentId);
                    reject(error);
                });
        });
    },
    updateSubscriptionPayment({ commit }, { paymentId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: paymentId, loading: true });
            //Api.put('Adyen/Session/SubscriptionPayment/' + paymentId, payload)
            Api.put('Stripe/SubscriptionPayment', payload)
                .then(response => {
                    commit('setSubscriptionPayment', response.data);
                    commit('SubscriptionEntityPayments/insertSubscriptionEntityPayment', response.data, { root: true });
                    commit('SubscriptionPaymentFailures/clearPaymentFailures', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: paymentId, loading: false }));
        });
    },
    cancelSubscriptionPayment({ commit }, paymentId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: paymentId, loading: true });
            Api.put('SubscriptionPayment/' + paymentId + '/Cancel')
                .then(response => {
                    commit('setSubscriptionPayment', response.data);
                    commit('SubscriptionEntityPayments/insertSubscriptionEntityPayment', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: paymentId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
