import { mapActions } from 'vuex';
import { AppointmentCacheMixin } from "./AppointmentCacheMixin";
import { OrderUploadMixin } from '../Orders/OrderUploadMixin';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AppointmentScheduleOrdersUpdateMixin = {
    mixins: [AppointmentCacheMixin, OrderUploadMixin],
    data() {
        return {
            updateTypeId: 0,
            previousUpdateTypeId: null
        }
    },
    computed: {
        scheduleId() {
            return !!this.appointment ? this.appointment.scheduleId : '';
        },
        petId() {
            return !!this.appointment ? this.appointment.petId : '';
        },
        sequenceNumber() {
            return !!this.appointment ? this.appointment.sequenceNumber : 0;
        },
        scheduleOrderUpdateModel() {
            return {
                scheduleId: this.scheduleId,
                sequenceNumber: this.sequenceNumber,
                petId: this.petId,
                paymentMethodId: this.paymentMethodId,
                orderItems: [...this.orderItems]
            }
        },
        scheduleOrderUpdateModelValid() {
            const model = this.scheduleOrderUpdateModel;
            return !!model.scheduleId && !!model.petId && model.orderItems.length > 0 && this.orderValidator.isValid;
        },
        scheduleOrderUpdateModelModified() {
            return this.updateTypeId !== this.previousUpdateTypeId || this.orderUpdateModelModified;
        }
    },
    methods: {
        ...mapActions('Orders', [
            'updateScheduleOrders'
        ]),
        tryUploadOrders() {
            if (this.updateTypeId === 2) {
                this.tryUpdateScheduleOrders();
            }
            else {
                if (!!this.order) {
                    this.tryUpdateOrder();
                } else {
                    this.tryAddOrder();
                }
            }
        },
        tryUpdateScheduleOrders() {
            const self = this;
            self.orderValidator.check(self.scheduleOrderUpdateModel);
            if (!self.scheduleOrderUpdateModelValid || !self.scheduleOrderUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateScheduleOrders({ key: self.appointmentId, payload: self.scheduleOrderUpdateModel })
                .then(() => self.previousUpdateTypeId = self.updateTypeId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
