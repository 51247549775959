import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const ContactCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Contacts', [
            'findContactCache'
        ]),
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        ownerQueryModelValid() {
            const model = this.ownerQueryModel;
            return !!model.ownerId && !!model.ownerTypeId;
        },
        contactCacheKey() {
            return CacheHelper.ownerRequestCacheKey(this.ownerQueryModel);
        },
        contactCache() {
            return this.findContactCache(this.contactCacheKey);
        },
        contacts() {
            return !!this.contactCache ? this.contactCache.contacts : [];
        },
        loadingContacts() {
            return !!this.contactCache && this.contactCache.loading;
        }
    },
    watch: {
        ownerQueryModel() {
            this.refreshContacts();
        }
    },
    methods: {
        ...mapActions('Contacts', [
            'searchContacts'
        ]),
        refreshContacts() {
            if (!this.contactCache || this.contactCache.retry) {
                this.searchAndCacheContacts();
            }
        },
        searchAndCacheContacts() {
            const self = this;
            if (!self.ownerQueryModelValid) {
                return;
            }
            self.error = '';
            self.searchContacts({ key: self.contactCacheKey, payload: self.ownerQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContacts();
    }
}
