import { mapState, mapActions } from 'vuex';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
import { SmsSettingAddMixin } from "./SmsSettingAddMixin";
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const SmsSettingUploadMixin = {
    mixins: [SmsSettingAddMixin, SiteIdMixin],
    computed: {
        ...mapState('SmsSettings', [
            'updatingSmsSettings',
            'addingSmsSettings'
        ]),
        smsSettingUpdateModel() {
            return !!this.smsSetting ? Object.assign({}, this.smsSetting, {
                fromName: this.fromName,
                balanceAlert: this.balanceAlert
            }) : null;
        },
        smsSettingUpdateModelValid() {
            return !!this.smsSettingUpdateModel.fromName;
        },
        smsSettingUpdateModelChanged() {
            return JSON.stringify(this.smsSettingUpdateModel) !== JSON.stringify(this.smsSetting);
        },
        loadingSetting() {
            return this.updatingSmsSettings || this.addingSmsSettings || this.loadingSmsSettings;
        }
    },
    watch: {
        smsSetting() {
            this.refreshSmsSettingModel();
        }
    },
    methods: {
        ...mapActions('SmsSettings', [
            'updateSmsSetting'
        ]),
        refreshSmsSettingModel() {
            this.fromName = !!this.smsSetting ? this.smsSetting.fromName : '';
            this.balanceAlert = !!this.smsSetting ? this.smsSetting.balanceAlert : 20;
        },
        tryUploadSmsSetting() {
            if (!!this.smsSetting) {
                this.tryUpdateSmsSetting();
            } else {
                this.tryAddSmsSetting();
            }
        },
        tryUpdateSmsSetting() {
            const self = this;
            if (!self.smsSettingUpdateModelValid || !self.smsSettingUpdateModelChanged) {
                return;
            }
            self.error = '';
            self.updateSmsSetting(self.smsSettingUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
