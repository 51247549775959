import { Api } from '../../services/Api';

const state = () => ({
    communicationCaches: []
})

const getters = {
    findCommunicationsCache: state => clientId => {
        return state.communicationCaches.find(cc => cc.key === clientId);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.communicationCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.communicationCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCommunications(state, { key, communications }) {
        const existing = state.communicationCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = communications;
            existing.loading = false;
        } else {
            state.communicationCaches.push({ key: key, values: communications, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.communicationCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendCommunication(state, { key, communication }) {
        const existing = state.communicationCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values.push(communication);
        } else {
            state.communicationCaches.push({ key: key, values: [communication], loading: false });
        }
    },
    insertCommunication(state, { key, communication }) {
        const existing = state.communicationCaches.find(cc => cc.key === key);
        if (!!existing) {
            const old = existing.values.find(c => c.id === communication.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, communication);
            } else {
                existing.values.push(communication);
            }
        } else {
            state.communicationCaches.push({ key: key, values: [communication], loading: false });
        }
    }
}

const actions = {
    searchCommunications({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Communication?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setCommunications', { key: key, communications: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    sendCommunication({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Communication', payload)
                .then(response => {
                    commit('appendCommunication', { key: key, communication: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    resendCommunication({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Communication/' + id + '/Resend')
                .then(response => {
                    commit('insertCommunication', { key, communication: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
