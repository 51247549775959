<template>
    <div>
        <div class="form-group row">
            <div class="col-md-4">
                <label>{{$t('Is resource?')}}</label>
            </div>
            <div class="col-md-8">
                <label class="ps-toggle-switch">
                    <input v-model="addResource" class="toggle-checkbox" type="checkbox" checked="">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t(toggleText)}}</span>
                </label>
            </div>
        </div>
        <colour-picker v-if="addResource" v-model="colour" :validator="userResourceValidator"></colour-picker>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import ResourceColourPicker from "../Resources/ResourceColourPicker.vue";
    import { UserResourceAddMixin } from "../Shared/UserResourceAddMixin";

    export default {
        name: 'UserResourceAdd',
        mixins: [UserResourceAddMixin],
        data() {
            return {
                addedUser: null,
                addResource: false
            }
        },
        computed: {
            ...mapState('Employees', [
                'employees'
            ]),
            ...mapState('ApplicationEvents', [
                'userAddedEvent'
            ]),
            userId() {
                return !!this.addedUser ? this.addedUser.id : '';
            },
            user() {
                return this.employees.find(u => u.id === this.userId);
            },
            toggleText() {
                return this.addResource ? 'Yes' : 'No';
            }
        },
        watch: {
            userAddedEvent(user) {
                if (!!this.addResource) {
                    this.addedUser = user;
                    this.tryAddUserResources();
                    this.addedUser = null;
                }
            }
        },
        methods: {
            tryGetUserResources() {
                // do nothing
            }
        },
        components: {
            'colour-picker': ResourceColourPicker
        }
    }
</script>
