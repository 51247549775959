<template>
    <div>
        <div v-if="completed" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>Pet successfully added!</p>
            </div>
            <button @click="completed=false" type="button" class="close">
                <span class="fas fa-plus"></span>
            </button>
        </div>
        <template v-else>
            <div class="title-divider">
                <h2>Pet</h2>
            </div>
            <error-banner v-if="error" :message="error"></error-banner>
            <section class="rounded-card-white mb-3">
                <pet-name v-model="name" :validator="petValidator"></pet-name>
                <pet-breed v-model="breedId" :validator="petValidator"></pet-breed>
            </section>
            <pet-options v-model="petOptionsModel"></pet-options>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import process from 'process';
    import OptionsPet from './OptionsPet.vue';
    import { PetAddMixin } from "../../Shared/PetAddMixin";
    import PetDetailsName from "../../Pet/PetDetailsName.vue";
    import PetDetailsBreed from "../../Pet/PetDetailsBreed.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'NewPetForm',
        mixins: [PetAddMixin],
        data() {
            return {
                completed: false
            }
        },
        props: {
            client: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent',
                'petAddedEvent'
            ]),
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            petOptionsModel: {
                get() {
                    return {};
                },
                set(value) {
                    this.genderId = value.genderId;
                    this.neuteredId = value.neuteredId;
                    this.behaviourId = value.behaviourId;
                    this.dateOfBirth = value.dateOfBirth;
                    this.microChip = value.microChip;
                }
            }
        },
        watch: {
            savePetEvent() {
                this.tryAddPet();
            },
            petAddedEvent() {
                process.nextTick(() => {
                    this.completed = true;
                });
            }
        },
        components: {
            'pet-name': PetDetailsName,
            'pet-breed': PetDetailsBreed,
            'pet-options': OptionsPet,
            'error-banner': ErrorBanner
        }
    }
</script>
