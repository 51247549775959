<template>
    <section class="mb-3">
        <div class="accordion-header collapsed" aria-expanded="false" data-target="#collapse-images" data-toggle="collapse">
            {{$t('Images')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-images">
            <div class="card card-body">
                <div class="image-uploader">
                    <div class="pet-hero-wrap">
                        <figure class="pet-hero">
                            <img v-if="!!heroImage" :src="heroImage.url" width="100" data-toggle="modal" data-target="#image-add-id">
                            <template v-else>
                                <default-image @click="openImageAdd"></default-image>
                            </template>
                        </figure>
                        <div class="custom-file-upload">
                            <button class="custom-upload-btn" data-toggle="modal" data-target="#image-add-id">
                                <i class="fas fa-cloud-upload"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="img-gallery">
                    <ul>
                        <li v-for="upload in otherImages" :key="upload.id">
                            <img :src="upload.url" width="100" data-toggle="modal" data-target="#image-add-id">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <modal-dialog ref="imageAdd" title="Select Pet Image" id="image-add-id">
            <div class="tab-pane fade show active">
                <div @click="showFileSelect" class="file-upload">
                    <label>{{$t('Upload photos')}}</label>
                    <p class="mb-0">{{$t('Select or drop files here, max.')}} ({{maxUploadSize}})</p>
                    <input ref="fileInput" @change="onFilePicked" type="file" style="display: none" accept="image/*" multiple="multiple" />
                </div>
                <div class="image-uploads">
                    <ul>
                        <li v-for="upload in imagePreviewSet" :key="upload.id" class="active">
                            <button @click="removeUpload(upload)" class="btn" type="button">
                                <i class="far fa-times"></i>
                            </button>
                            <figure>
                                <img :src="upload.url" width="120">
                            </figure>
                        </li>
                    </ul>
                </div>
            </div>
        </modal-dialog>
    </section>
</template>

<script>
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import DefaultPetImageMedium from '../../Shared/Images/DefaultPetImageMedium.vue';
    import { PetAddImagesMixin } from './PetAddImagesMixin';

    export default {
        name: 'PetAddImages',
        mixins: [PetAddImagesMixin],
        computed: {
            heroImage() {
                return this.imagePreviewSet.length > 0 ? this.imagePreviewSet[0] : null;
            },
            otherImages() {
                return this.imagePreviewSet.length > 1 ? this.imagePreviewSet.slice(1) : [];
            }
        },
        methods: {
            showFileSelect() {
                this.$refs.fileInput.click();
            },
            openImageAdd() {
                const element = this.$refs.imageAdd.$el;
                $(element).modal('show');
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'default-image': DefaultPetImageMedium
        }
    }
</script>
