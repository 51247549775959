<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <slotted-input v-model="name" :title="$t('Name')">
            <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
        </slotted-input>
        <slotted-input v-model="email" :title="$t('Email')"></slotted-input>
        <slotted-input v-model="mobile" :title="$t('Mobile')"></slotted-input>
        <slotted-text-area v-model="otherInfo" :title="$t('Other info')" :rows="10"></slotted-text-area>
    </div>
</template>

<script>
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { ContactUploadMixin } from "../../Shared/Contacts/ContactUploadMixin";
    import SlottedInput from "../../Shared/Input/SlottedInput.vue";
    import SlottedTextArea from "../../Shared/Input/SlottedTextArea.vue";
    import { mapState } from 'vuex';
    import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateContactDetails',
        mixins: [ContactUploadMixin, SiteIdMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ownerId() {
                return this.siteId;
            },
            ownerTypeId() {
                return 12;
            },
            contactTypeId() {
                return 1;
            },
            status() {
                return {
                    loading: !!this.loadingContacts,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadContact();
            }
        },
        components: {
            'slotted-input': SlottedInput,
            'validation-message': ValidationMessage,
            'slotted-text-area': SlottedTextArea,
            'error-banner': ErrorBanner
        }
    }
</script>
