import { ImageCacheMixin } from "../../../components/Shared/Images/ImageCacheMixin";
import { SettingCacheMixin } from "../../../components/Shared/SettingCacheMixin";
import { BookingPortalImageMixin } from "./BookingPortalImageMixin";

export const BookingPortalImageCacheMixin = {
    mixins: [ImageCacheMixin, SettingCacheMixin, BookingPortalImageMixin],
    computed: {
        image() {
            return this.images.find(i => i.id === this.settingObject.imageId);
        }
    }
}
