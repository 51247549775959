import { Api } from "../../services/Api";

const state = () => ({
    fetchedBookingSettings: false,
    fetchingBookingSettings: false,
    bookingSettings: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingBookingSettings = !!fetching;
        state.fetchedBookingSettings = false;
    },
    setBookingSettings(state, BookingSettings) {
        state.bookingSettings = BookingSettings;
        state.fetchingBookingSettings = false;
        state.fetchedBookingSettings = true;
    }
}

const actions = {
    fetchBookingSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Booking/Setting')
                .then(response => {
                    commit('setBookingSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
