<template>
    <tr @click="onClick">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{breedName}}
                    </div>
                    <div class="crd-contact">
                        {{productString}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { BreedItemMixin } from "./BreedItemMixin";

    export default {
        name: 'BreedItemMobile',
        mixins: [BreedItemMixin, CountryCacheMixin],
        computed: {
            productString() {
                return !!this.product ? this.currencySymbol + this.product.amount.toFixed(2).toString() : this.$t('No price');
            }
        },
        methods: {
            tryGetCountries() {
                // do nothing
            }
        }
    }
</script>
