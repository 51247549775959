import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { Api } from "../../../services/Api";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const AggregateCalculateMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: '',
            loading: false,
            completed: false
        }
    },
    computed: {
        aggregateCalculateModel() {
            return {
                siteId: this.siteId
            }
        },
        aggregateCalculateModelValid() {
            return !!this.aggregateCalculateModel.siteId;
        }
    },
    methods: {
        tryCalculateAgggregates() {
            const self = this;
            if (!self.aggregateCalculateModelValid) {
                return;
            }
            self.error = '';
            self.loading = true;
            Api.put('Aggregate', self.aggregateCalculateModel)
                .then(() => self.completed = true)
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.loading = false);
        }
    }
}
