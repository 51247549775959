import { Api } from "../../services/Api";

const state = () => ({
    appointmentCountCaches: []
})

const getters = {
    findAppointmentCountCache: (state) => key => state.appointmentCountCaches.find(acc => acc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentCountCaches.find(acc => acc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.appointmentCountCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setCount(state, { key, count }) {
        const existing = state.appointmentCountCaches.find(acc => acc.key === key);
        if (!!existing) {
            existing.value = count;
            existing.loading = false;
        } else {
            state.appointmentCountCaches.push({ key: key, value: count, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.appointmentCountCaches.find(acc => acc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    countAppointments({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Appointment/Count', payload)
                .then(response => {
                    commit('setCount', { key: key, count: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
