import { ErrorHelper } from "../../helpers/ErrorHelper";
import { BookingSuggestionCommunicationCacheMixin } from "./BookingSuggestionCommunicationCacheMixin";
import { mapActions } from 'vuex';

export const BookingSuggestionCommunicationResendMixin = {
    mixins: [BookingSuggestionCommunicationCacheMixin],
    computed: {
        bookingSuggestionCommunication() {
            return this.communications.length > 0 ? this.communications[0] : null;
        },
        bookingSuggestionCommunicationId() {
            return !!this.bookingSuggestionCommunication ? this.bookingSuggestionCommunication.id : '';
        },
        failedBookingSuggestionCommunication() {
            return this.bookingSuggestionCommunication ? this.bookingSuggestionCommunication.sendStatus.id !== 1 : false;
        }
    },
    methods: {
        ...mapActions('BookingSuggestionCommunications', [
            'resendCommunication'
        ]),
        tryResendCommunication() {
            const self = this;
            if (!self.bookingSuggestionCommunicationId && !self.failedBookingSuggestionCommunication) {
                return;
            }
            self.error = '';
            self.resendCommunication({ key: self.subjectId, id: self.bookingSuggestionCommunicationId })
                .then(response => self.afterResendingCommunication(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterResendingCommunication() {
            // override if needed
        }
    }
}
