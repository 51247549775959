import { ContractHelper } from '../../helpers/ContractHelper';
import { Api } from '../../services/Api';

const state = () => ({
    uploadingContractTemplate: false,
    contractTemplateCaches: []
})

const getters = {
    findContractTemplateCache: (state) => key => {
        return state.contractTemplateCaches.find(ctc => ctc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.contractTemplateCaches.find(ctc => ctc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.contractTemplateCaches.push({ key: key, value: null, loading: loading });
        }
    },
    setUploading(state, uploading) {
        state.uploadingContractTemplate = !!uploading;
    },
    setContractTemplate(state, { key, contractTemplate }) {
        const existing = state.contractTemplateCaches.find(ctc => ctc.key === key);
        if (!!existing) {
            existing.value = contractTemplate;
            existing.loading = false;
        } else {
            state.contractTemplateCaches.push({ key: key, value: contractTemplate, loading: false });
        }
    },
    removeContractTemplate(state, key) {
        const existing = state.contractTemplateCaches.find(ctc => ctc.key === key);
        if (!!existing) {
            existing.value = null;
        }
    },
    setRetry(state, key) {
        const existing = state.contractTemplateCaches.find(ctc => ctc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getContractTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('ContractTemplate?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setContractTemplate', { key: key, contractTemplate: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    },
    addContractTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUploading', true);
            Api.post('ContractTemplate', payload)
                .then(response => {
                    commit('setContractTemplate', {
                        key: ContractHelper.contractTemplateCacheKey(response.data),
                        contractTemplate: response.data
                    });
                    commit('ContractTemplateSites/clearCache', null, { root: true });
                    commit('ApplicationEvents/triggerContractTemplateAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUploading', false));
        });
    },
    updateContractTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUploading', true);
            Api.put('ContractTemplate', payload)
                .then(response => {
                    commit('setContractTemplate', {
                        key: ContractHelper.contractTemplateCacheKey(response.data),
                        contractTemplate: response.data
                    });
                    commit('ContractTemplateSites/clearCache', null, { root: true });
                    commit('ApplicationEvents/triggerContractTemplateUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUploading', false));
        });
    },
    deleteContractTemplate({ commit }, { key, id } ) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('ContractTemplate/' + id)
                .then(response => {
                    commit('removeContractTemplate', key);
                    commit('ContractTemplateSites/clearCache', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
