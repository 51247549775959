import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AccountSettingAddMixin } from "./AccountSettingAddMixin";
import { mapState, mapActions } from 'vuex';

export const AccountSettingUploadMixin = {
    mixins: [AccountSettingAddMixin],
    data() {
        return {
            accountSettingEdited: false
        }
    },
    computed: {
        ...mapState('AccountSettings', [
            'updatingAccountSetting'
        ]),
        accountSettingUploadModel() {
            return {
                key: this.accountSettingKey,
                value: JSON.stringify(Object.assign({}, this.accountSettingObject, this.accountSettingEditModel))
            };
        },
        accountSettingUploadModelModified() {
            return !!this.accountSettingEdited && this.accountSettingUploadModel.value !== this.accountSettingValue;
        },
        loadingAccountSetting() {
            return this.fetchingAccountSettings || this.addingAccountSetting || this.updatingAccountSetting;
        }
    },
    watch: {
        accountSettingObject() {
            this.refreshAccountSettingEditModel();
            this.accountSettingEdited = false;
        },
        accountSettingEditModel() {
            this.accountSettingEdited = true;
        }
    },
    methods: {
        ...mapActions('AccountSettings', [
            'updateAccountSetting'
        ]),
        refreshAccountSettingEditModel() {
            console.log('OVERRIDE METHOD - refreshAccountSettingEditModel()');
        },
        tryUpdateAccountSetting() {
            const self = this;
            if (!self.accountSettingUploadModelValid) {
                return;
            }
            self.error = '';
            self.updateAccountSetting(self.accountSettingUploadModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryUploadAccountSetting() {
            if (!this.accountSettingUploadModelModified) {
                return;
            }
            if (!!this.accountSetting) {
                this.tryUpdateAccountSetting();
            } else {
                this.tryAddAccountSetting();
            }
        }
    },
    created() {
        this.refreshAccountSettingEditModel();
    }
}
