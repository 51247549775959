<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Deposit')}}</label>
        </div>
        <div class="col-md-8">
            <toggle-input v-model="isRequired" :title="$t('Required?')"></toggle-input>
            <template v-if="isRequired">
                <toggle-input v-model="isPercentage" :title="$t('Percentage?')"></toggle-input>
                <number-input v-if="isPercentage" v-model="percentage" :title="`${$t('Amount')} (%)`"></number-input>
                <money-input v-else v-model="amount" :title="$t('Amount')"></money-input>
                <toggle-input v-model="hasDueDate" :title="$t('Has due date?')"></toggle-input>
                <integer-input v-if="hasDueDate" v-model="daysPrior" :title="$t('Due days prior')"></integer-input>
            </template>
        </div>
    </div>
</template>

<script>
    import { SiteDepositSettingUploadMixin } from "../../../mixins/Settings/Site/SiteDepositSettingUploadMixin";
    import { mapState } from 'vuex';
    import MoneyInput from "../../Shared/Input/MoneyInput.vue";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import NumberInput from "../../Shared/Input/NumberInput.vue";
    import IntegerInput from "../../Shared/Input/IntegerInput.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateAppointmentDeposit',
        mixins: [SiteDepositSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'money-input': MoneyInput,
            'toggle-input': SlottedToggle,
            'number-input': NumberInput,
            'integer-input': IntegerInput,
            'error-banner': ErrorBanner
        }
    }
</script>
