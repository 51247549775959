<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-resource" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Resource')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-resource">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <label>{{$t('Name')}}</label>
                    <input v-model="name" type="text" class="form-control">
                    <validation-message v-if="resourceValidator.name" :message="resourceValidator.name"></validation-message>
                    <label>{{$t('Colour')}}</label>
                    <input v-model="colour" type="color">
                    <validation-message v-if="resourceValidator.colour" :message="resourceValidator.colour"></validation-message>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { ResourceHelper } from "../../helpers/ResourceHelper";
    import { AccountSettingSiteDefaultMixin } from '../../mixins/Settings/Account/AccountSettingSiteDefaultMixin';

    export default {
        name: 'DefaultSiteResource',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                name: '',
                colour: '#FFE781',
                resourceValidator: ResourceHelper.getResourceValidator()
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultResource';
            },
            accountSettingEditModel() {
                return {
                    name: this.name,
                    colour: this.colour
                }
            },
            accountSettingEditModelValid() {
                this.resourceValidator.check(this.accountSettingEditModel);
                return this.resourceValidator.isValid;
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.name = !!this.accountSettingObject ? this.accountSettingObject.name : '';
                this.colour = !!this.accountSettingObject ? this.accountSettingObject.colour : '#FFE781';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
