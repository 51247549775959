<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <label>{{$t('Site Images')}}</label>
        <div class="tab-pane fade show active">
            <div @click="showFileSelect" class="file-upload">
                <label>{{$t('Upload photos')}}</label>
                <p class="mb-0">{{$t('Select or drop files here, max.')}} ({{maxUploadSize}})</p>
                <input ref="fileInput" @change="onFilePicked" type="file" style="display: none" accept="image/*" multiple="multiple" />
            </div>
            <div class="image-uploads">
                <ul>
                    <li v-for="upload in imagePreviewSet" :ref="upload.id" class="active">
                        <button @click="removeUpload(upload)" class="btn" type="button">
                            <i class="far fa-times"></i>
                        </button>
                        <figure>
                            <img :src="upload.url" width="120">
                        </figure>
                    </li>
                </ul>
            </div>
        </div>
        <div class="img-gallery">
            <ul>
                <li v-for="i in images" :ref="i.id">
                    <img @click="image=i" :alt="i.uploadedName" :src="i.thumbnails.small" width="100">
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";
    import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateSiteImages',
        mixins: [ImageAddMixin, SiteIdMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ownerId() {
                return this.siteId;
            },
            ownerTypeId() {
                return 12;
            },
            status() {
                return {
                    loading: this.loadingImages,
                    error: this.error
                }
            }
        },
        watch: {
            ownerId() {
                this.image = null;
            },
            saveSiteEvent() {
                this.tryAddImages();
            }
        },
        methods: {
            showFileSelect() {
                this.$refs.fileInput.click();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
