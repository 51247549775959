import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SmsCalculatorSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SmsCalculatorSettings', [
            'fetchingSmsCalculatorSettings',
            'fetchedSmsCalculatorSettings',
            'smsCalculatorSettings'
        ]),
        enabled() {
            return !!this.smsCalculatorSettings ? this.smsCalculatorSettings.enabled : false; 
        }
    },
    methods: {
        ...mapActions('SmsCalculatorSettings', [
            'getSmsCalculatorSettings'
        ]),
        tryGetSmsCalculatorSettings() {
            const self = this;
            self.error = '';
            self.getSmsCalculatorSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshSmsCalculatorSettings() {
            if (!this.fetchedSmsCalculatorSettings && !this.fetchingSmsCalculatorSettings) {
                this.tryGetSmsCalculatorSettings();
            }
        }
    },
    created() {
        this.refreshSmsCalculatorSettings();
    }
}
