<template>
    <div>
        <button ref="declineButton" type="button" data-target="#decline-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="decline-modal" :title="$t('Decline Booking Request')">
            <error-banner v-if="error" :message="error"></error-banner>
            <booking-decline v-model="declineStatus" :id="id" :submit-event="submitEvent"></booking-decline>
            <div class="d-flex justify-content-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('Cancel')}}</button>
                <button @click="handleSubmit" v-bind:disabled="loading" type="button" class="btn btn-primary">
                    {{$t('Submit')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin'
    import BookingDecline from './BookingDecline.vue'
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BookingDeclineModal',
        mixins: [StatusEmitterMixin],
        props: {
            id: ''
        },
        data() {
            return {
                declineStatus: {},
                submitEvent: 0
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'declineBookingEvent'
            ]),
            loading() {
                return !!this.declineStatus.loading;
            },
            completed() {
                return !!this.declineStatus.completed;
            },
            error() {
                return !!this.declineStatus.error ? this.declineStatus.error : '';
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            }
        },
        watch: {
            declineBookingEvent() {
                this.$refs.declineButton.click();
            },
            completed(value) {
                if (!!value) {
                    this.$refs.cancelButton.click();
                }
            }
        },
        methods: {
            handleSubmit() {
                this.submitEvent++;
            }
        },
        components: {
            'booking-decline': BookingDecline,
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>
