<template>
    <div></div>
</template>

<script>
    import { OnboardingGuideHelper } from "./Helper/OnboardingGuideHelper";
    import { OnboardingGuideMixin } from "./Mixins/OnboardingGuideMixin";
    import router from '../../routing';

    export default {
        name: 'AddCampaignGuide',
        mixins: [OnboardingGuideMixin],
        methods: {
            createTour() {
                this.tour = OnboardingGuideHelper.getTourDefaults("Add Campaign", false, "");

                var addCampaignBtn = OnboardingGuideHelper.getResponsiveElementId('#AddCampaignBtn');
                var addCampaignSettingsSection = '#AddCampaignSettingsSection';
                var addCampaignRecipientFilterSection = '#AddCampaignRecipientFilterSection';
                var addCampaignSaveBtn = '#AddCampaignSaveBtn';

                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    1,
                    'Your Campaign Creation Journey',
                    "<p>Are you ready for an exciting tour on how to create a campaign?</p>",
                    '',
                    "bottom",
                    [
                        {
                            text: `Start <i class="fal fa-flag-checkered"></i>`,
                            action: () => {
                                this.tour.next();
                            }
                        }
                    ],
                    true
                ))
                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    2,
                    'Add Campaign Button',
                    "<p>This button allows users to be redirected to the <strong>Add Campaign</strong> page, where they will fill out a form to generate a campaign.</p>",
                    addCampaignBtn,
                    "bottom",
                    [
                        {
                            text: `Next <i class="fal fa-chevron-right"></i>`,
                            action: ()=> {
                                document.querySelector(addCampaignBtn).click();
                                this.tour.next();
                            }
                        }
                    ]
                ))
                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    3,
                    'Campaign Settings Form',
                    'This form contains the minimum required fields for users to generate a campaign.',
                    addCampaignSettingsSection,
                    "right",
                    [
                        {
                            text: `Next <i class="fal fa-chevron-right"></i>`,
                            action: ()=> {
                                return this.tour.next();
                            } 
                        }
                    ]
                ))
                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    4,
                    'Recipient Filter',
                    `This section enables users to target their campaign audience based on the site, breeds, tags, and those who haven't visited since the selected date.`,
                    addCampaignRecipientFilterSection,
                    "left",
                    [
                        {
                            text: `<i class="fal fa-chevron-left"></i> Back`,
                            action: () => {
                                this.tour.back();
                            }
                        },
                        {
                            text: `Next <i class="fal fa-chevron-right"></i>`,
                            action: () => {
                                return this.tour.next();
                            }
                        }
                    ]
                ))
                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    5,
                    'Save Your Campaign',
                    `After saving the campaign, you've officially started your marketing campaign, and all that's left is to confirm it.`,
                    addCampaignSaveBtn,
                    "top",
                    [
                        {
                            text: `<i class="fal fa-chevron-left"></i> Back`,
                            action: () => {
                                this.tour.back();
                            }
                        },
                        {
                            text: `Next <i class="fal fa-chevron-right"></i>`,
                            action: () => {
                                return this.tour.next();
                            }
                        }
                    ]
                ))
                this.tour.addStep(OnboardingGuideHelper.createTourStep(
                    6,
                    'Great job!',
                    `You will now be redirected to the campaigns page, where you can view the number of clients the campaign will reach and make additional adjustments before confirming it.`,
                    '',
                    "bottom",
                    [
                        {
                            text: `Complete <i class="fal fa-flag-checkered"></i>`,
                            action: () => {
                                this.tour.complete();
                                return router.push({ name: 'campaigns' });
                            }
                        }
                    ],
                    true
                ))
                this.tour.start();
            }
        }
    }
</script>
