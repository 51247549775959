import { mapState } from "vuex";
import router from '../../../routing';

export const CommunicationTemplateUploadMixin = {
    props: {
        id: Number
    },
    computed: {
        ...mapState('CommunicationTypes', [
            'communicationTypes'
        ]),
        communicationType() {
            return this.communicationTypes.find(t => t.id === this.id);
        },
        communicationTypeName() {
            return !!this.communicationType ? this.communicationType.description : '';
        },
        communicationTypeId() {
            return !!this.communicationType ? this.communicationType.id : 0;
        },
        canDisplay() {
            return !!this.communicationType;
        }
    },
    methods: {
        cancel() {
            router.go(-1);
        }
    },
    created() {
        if (!this.id) {
            this.cancel();
        }
    }
}
