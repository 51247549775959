import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin"

export const CampaignsCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Campaigns', [
            'findCampaignsCache'
        ]),
        campaignsCache() {
            return this.findCampaignsCache(this.siteId);
        },
        campaigns() {
            return !!this.campaignsCache ? this.campaignsCache.values : [];
        },
        loadingCampaigns() {
            return !!this.campaignsCache && this.campaignsCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshCampaigns();
        }
    },
    methods: {
        ...mapActions('Campaigns', [
            'findCampaigns'
        ]),
        refreshCampaigns() {
            if (!this.campaignsCache || this.campaignsCache.retry) {
                this.tryFindCampaigns();
            }
        },
        tryFindCampaigns() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.findCampaigns(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCampaigns();
    }
}
