<template>
    <div v-if="hasConflict">
        <div class="title-divider mb-3 mt-0">
            <h2>{{$t('Appointment')}} {{index + 1}}: {{ participantName }} {{$t('with')}} {{resourceName}}</h2>
        </div>
        <appointment-details v-for="appointment in appointments" 
                             :key="appointment.id" 
                             :appointment="appointment" 
                             :resource-id="participant.resourceId">
        </appointment-details>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../Shared/Resources/ResourceCacheMixin";
    import BookingConflictAppointmentDetails from "./BookingConflictAppointmentDetails.vue"

    export default {
        name: 'BookingConflict',
        mixins: [ResourceCacheMixin],
        props:{
            index: 0,
            participant: Object,
            appointments: Array
        },
        computed: {
            resourceId() {
                return this.participant ? this.participant.resourceId : '';
            },
            hasConflict() {
                return !!this.participant ? this.appointments.some(a => a.resourceId === this.resourceId) : false;
            },
            resource() {
                return this.resources.length > 0 && !!this.participant ? this.resources.find(r => r.id === this.resourceId) : null;
            },
            resourceName() {
                return !!this.resource ? this.resource.name : '-';
            },
            participantName() {
                return !!this.participant ? this.participant.name : '-';
            }
        },
        components: {
            'appointment-details': BookingConflictAppointmentDetails
        }
    }
</script>
