<template>
    <tbody>
        <tr :class="classType">
            <td>{{provider}}</td>
            <td>{{date}}</td>
            <td style="text-align: right">{{amount}}</td>
            <td style="text-align: center">{{status}}</td>
            <td>
                <button v-if="canProcess" @click="signalProcess" title="Process payment" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-arrow-right"></i>
                </button>
                <button v-else-if="canRefund" @click="refunding=!refunding" title="Refund payment" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-rotate-left"></i>
                </button>
            </td>
            <td>
                <button v-if="canProcess" @click="cancelling=!cancelling" title="Cancel payment" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-times"></i>
                </button>
                <button v-if="hasRefunds" @click="history=!history" title="Refund history" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-chevron-down"></i>
                </button>
            </td>
        </tr>
        <tr v-if="history" v-for="refund in refunds" :key="refund.id" class="refund-item">
            <td>{{refund.isPartial ? 'Partial ' : 'Full '}} refund</td>
            <td>{{refund.createdUtc | moment(dateFormat)}}</td>
            <td style="text-align: right">-{{refund.amount.toFixed(2)}}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr v-if="refunding" class="refund-item">
            <td colspan="6">
                <slotted-toggle v-model="isPartialRefund" title="Partial refund?"></slotted-toggle>
                <money-input v-if="isPartialRefund" v-model="refundAmount" title="Amount"></money-input>
                <validation-message v-if="isPartialRefund && refundValidator.amount" :message="refundValidator.amount"></validation-message>
                <div class="form-group">
                    <button @click="tryRefundCheckout" v-bind:disabled="loadingCheckouts" type="button" class="btn btn-danger" style="margin-right: 10px;">
                        Refund
                        <span v-if="loadingCheckouts" class="inline-loader"></span>
                    </button>
                    <button @click="refunding=false" type="button" class="btn btn-outline-secondary">
                        {{$t('Cancel')}}
                    </button>
                </div>
            </td>
        </tr>
        <tr v-if="cancelling">
            <td colspan="6">
                <div role="alert" class="alert alert-warning alert-icon">
                    <div class="alert-content">
                        <p> Are you sure you want to cancel this payment?</p>
                    </div>
                    <button @click="tryCancelCheckout" v-bind:disabled="loadingCheckouts" type="button" class="btn btn-danger" style="margin-right: 10px;">
                        Yes
                        <span v-if="loadingCheckouts" class="inline-loader"></span>
                    </button>
                    <button @click="cancelling=false" type="button" class="close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import ValidationMessage from '../ErrorValidation/ValidationMessage.vue';
    import MoneyInput from '../Shared/Input/MoneyInput.vue';
    import SlottedToggle from '../Shared/Input/SlottedToggle.vue';
    import { CheckoutListItemMixin } from './CheckoutListItemMixin';

    export default {
        name: 'CheckoutListItemDesktop',
        mixins: [CheckoutListItemMixin],
        data() {
            return {
                cancelling: false,
                refunding: false,
                history: false
            }
        },
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        },
        methods: {
            afterCancelCheckout() {
                this.cancelling = false;
            },
            afterRefundCheckout() {
                this.refunding = false;
            }
        },
        components: {
            'slotted-toggle': SlottedToggle,
            'money-input': MoneyInput,
            'validation-message': ValidationMessage
        }
    }
</script>
