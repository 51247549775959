import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const PaymentApiUserSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentApiUserSearches', [
            'findPaymentApiUserSearch'
        ]),
        userId() {
            console.log('OVERRIDE COMPUTED - userId');
        },
        paymentApiUserSearchModel() {
            return {
                username: this.userId
            }
        },
        paymentApiUserSearch() {
            return this.findPaymentApiUserSearch(this.userId);
        },
        paymentApiUser() {
            return !!this.paymentApiUserSearch ? this.paymentApiUserSearch.value : null;
        },
        loadingPaymentApiUser() {
            return !!this.paymentApiUserSearch && this.paymentApiUserSearch.loading;
        }
    },
    watch: {
        userId() {
            this.refreshPaymentApiUserSearch();
        }
    },
    methods: {
        ...mapActions('PaymentApiUserSearches', [
            'findPaymentApiUser'
        ]),
        refreshPaymentApiUserSearch() {
            if (!this.paymentApiUserSearch || this.paymentApiUserSearch.retry) {
                this.tryFindPaymentApiUser();
            }
        },
        tryFindPaymentApiUser() {
            const self = this;
            if (!self.userId) {
                return;
            }
            self.error = '';
            self.findPaymentApiUser({ userId: self.userId, payload: self.paymentApiUserSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentApiUserSearch();
    }
}
