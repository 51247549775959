import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SiteCountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SiteCount', [
            'loadingSiteCount',
            'loadedSiteCount',
            'siteCount'
        ])
    },
    methods: {
        ...mapActions('SiteCount', [
            'fetchSiteCount'
        ]),
        refreshSiteCount() {
            if (!this.loadingSiteCount && !this.loadedSiteCount) {
                this.tryFetchSiteCount();
            }
        },
        tryFetchSiteCount() {
            const self = this;

            self.error = '';
            self.fetchSiteCount()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSiteCount();
    }
}
