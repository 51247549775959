import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ClientMessagesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ClientMessages', [
            'findClientMessagesCache'
        ]),
        clientId() {
            console.log('OVERRIDE COMPUTED - clientId');
            return '';
        },
        methodTypeId() {
            console.log('OVERRIDE COMPUTED - methodTypeId');
            return 0;
        },
        clientMessagesFilterModel() {
            return {
                clientId: this.clientId,
                methodTypeId: this.methodTypeId
            }
        },
        clientMessagesFilterModelValid() {
            const model = this.clientMessagesFilterModel;
            return !!model.clientId && !!model.methodTypeId;
        },
        clientMessagesCacheKey() {
            return this.clientId + this.methodTypeId;
        },
        clientMessagesCache() {
            return this.findClientMessagesCache(this.clientMessagesCacheKey);
        },
        clientMessages() {
            return !!this.clientMessagesCache ? this.clientMessagesCache.values : [];
        },
        loadingClientMessages() {
            return !!this.clientMessagesCache && this.clientMessagesCache.loading;
        }
    },
    watch: {
        clientMessagesCacheKey() {
            this.refreshClientMessages();
        }
    },
    methods: {
        ...mapActions('ClientMessages', [
            'findClientMessages'
        ]),
        tryFindClientMessages() {
            const self = this;
            if (!self.clientMessagesFilterModelValid) {
                return;
            }
            self.error = '';
            self.findClientMessages({ key: self.clientMessagesCacheKey, payload: self.clientMessagesFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshClientMessages() {
            if (!this.clientMessagesCache || !!this.clientMessagesCache.retry) {
                this.tryFindClientMessages();
            }
        }
    },
    created() {
        this.refreshClientMessages();
    }
}
