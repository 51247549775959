<template>
    <div class="form-group row">
        <div class="col-md-12">
            <label>{{$t('Requires signature?')}}</label>
            <label class="ps-toggle-switch">
                <input v-model="requiresSignature" class="toggle-checkbox" type="checkbox" checked="">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t(toggleText)}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SignatureRequiredToggle',
        props: {
            value: Boolean
        },
        computed: {
            requiresSignature: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            toggleText() {
                return this.requiresSignature ? 'Yes' : 'No';
            }
        }
    }
</script>
