import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const ContractCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Contracts', [
            'findContractCache'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - ClientId!!!');
        },
        contractCache() {
            return this.findContractCache(this.clientId);
        },
        contracts() {
            return this.contractCache ? this.contractCache.values : [];
        },
        loadingContracts() {
            return this.contractCache && this.contractCache.loading;
        }
    },
    watch: {
        clientId() {
            this.refreshContracts();
        }
    },
    methods: {
        ...mapActions('Contracts', [
            'searchContracts'
        ]),
        refreshContracts() {
            if (!this.contractCache || !!this.contractCache.retry) {
                this.trySearchContracts();
            }
        },
        trySearchContracts() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.searchContracts(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContracts();
    }
}
