<template>
    <modal-dialog :title="$t('Add service')">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <site-select v-model="siteIds" :validation-error="serviceProductValidator.siteIds"></site-select>
            <slotted-input v-model="name" :title="$t('Name')">
                <label v-if="serviceProductValidator.name" class="error">{{serviceProductValidator.name}}</label>
            </slotted-input>
            <amount-input v-model="amount" :validation="serviceProductValidator.amount"></amount-input>
            <toggle-input v-model="publishedOnline" :title="$t('Published Online?')"></toggle-input>
            <description-input v-model="description"></description-input>
        </section>
        <div class="modal-footer">
            <button @click="tryAddServiceProducts" v-bind:disabled="groupingServiceProducts" class="btn btn-primary" type="button">
                {{$t('Add')}}
                <span v-if="groupingServiceProducts" class="inline-loader"></span>
            </button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { ServiceProductsAddMixin } from '../../mixins/Products/ServiceProductsAddMixin';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import SiteMultiSelect from '../Shared/Site/SiteMultiSelect.vue';
    import ProductAmountInput from './ProductAmountInput.vue';
    import ProductDescriptionInput from './ProductDescriptionInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import SlottedInput from '../Shared/Input/SlottedInput.vue';
    import SlottedToggle from '../Shared/Input/SlottedToggle.vue';

    export default {
        name: 'ServiceAdd',
        mixins: [ServiceProductsAddMixin],
        data() {
            return {
                uuid: crypto.randomUUID()
            }
        },
        computed: {
            productId() {
                return this.uuid;
            }
        },
        methods: {
            tryGroupServiceProducts() {
                // do nothing
            },
            afterAddingServiceProducts() {
                this.refreshServiceProductsModel();
                this.uuid = crypto.randomUUID();
                this.$refs.closeModal.click();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'site-select': SiteMultiSelect,
            'slotted-input': SlottedInput,
            'toggle-input': SlottedToggle,
            'amount-input': ProductAmountInput,
            'description-input': ProductDescriptionInput,
            'error-banner': ErrorBanner
        }
    }
</script>
