<template>
    <tr @click="onClick">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{userName}}
                    </div>
                    <div class="crd-contact">
                        {{$t(userRole)}}
                    </div>
                    <div class="crd-pets">
                        {{sitesString}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { UserItemMixin } from "./UserItemMixin";

    export default {
        name: 'UserItemMobile',
        mixins: [UserItemMixin],
        computed: {
            userName() {
                return this.firstName + ' ' + this.lastName;
            }
        }
    }
</script>
