import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const WeightSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Weight';
        },
        defaultSettingObject() {
            return {
                unit: 'kgs'
            }
        }
    }
}
