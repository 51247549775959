export const CommunicationHelper = {
    getContractCommunication(contract) {
        return {
            id: '',
            sendStatus: {
                id: 0,
                name: 'NotSent'
            },
            failureReason: null,
            retries: 0,
            clientId: contract.clientId,
            subjectId: contract.id,
            communicationType: {
                id: 9,
                name: 'DocumentReview'
            },
            methodType: {
                id: 0,
                name: 'Unknown'
            }
        }
    },
    isValid(communication) {
        return communication && communication.clientId && communication.subjectId;
    },
    getSmsTemplateValidator() {
        return {
            isValid: false,
            value: '',
            check(model) {
                this.value = !!model.value ? '' : 'Please enter an SMS template.';
                this.isValid = !this.value;
            }
        }
    },
    getEmailMarketingTemplateValidator() {
        return {
            isValid: false,
            subject: '',
            check(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.isValid = !this.subject;
            }
        }
    }
}
