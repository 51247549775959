import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ServiceProductsAddMixin } from "./ServiceProductsAddMixin";
import { mapActions } from 'vuex';

export const ServiceProductsUploadMixin = {
    mixins: [ServiceProductsAddMixin],
    computed: {
        anyServiceProducts() {
            return this.serviceProducts.length > 0;
        },
        serviceProduct() {
            return this.serviceProducts.find(p => p.siteId === this.siteId);
        },
        serviceProductsUpdateModel() {
            return {
                productId: this.productId,
                amount: this.amount,
                name: this.name,
                publishedOnline: this.publishedOnline,
                description: this.description,
                siteIds: this.siteIds
            }
        },
        serviceProductsUpdateModelValid() {
            const model = this.serviceProductsUpdateModel;
            return !!model.productId && !!this.serviceProductValidator.isValid;
        },
        serviceProductsUpdateModelModified() {
            const model = this.serviceProductsUpdateModel;
            return this.siteIds.some(id => {
                const found = this.serviceProducts.find(p => p.siteId === id);
                return !!found && (model.amount !== found.amount || model.name !== found.name || model.description !== found.description || model.publishedOnline !== found.publishedOnline)
            });
        }
    },
    watch: {
        serviceProduct() {
            this.refreshServiceProductsModel();
        }
    },
    methods: {
        ...mapActions('ServiceProductGroups', [
            'updateServiceProducts',
            'deleteServiceProduct'
        ]),
        tryUploadServiceProducts() {
            this.tryAddServiceProducts();
            this.tryUpdateServiceProducts();
        },
        tryUpdateServiceProducts() {
            const self = this;
            self.serviceProductValidator.validate(self.serviceProductsUpdateModel);
            if (!self.serviceProductsUpdateModelValid || !self.serviceProductsUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateServiceProducts({ productId: self.productId, payload: self.serviceProductsUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteServiceProduct() {
            const self = this;
            if (!self.serviceProduct) {
                return;
            }
            self.error = '';
            self.deleteServiceProduct({ productId: self.productId, id: self.serviceProduct.id })
                .then(() => self.afterDeletingServiceProduct())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshServiceProductsModel() {
            this.amount = !!this.serviceProduct ? this.serviceProduct.amount : (this.anyServiceProducts ? this.serviceProducts[0].amount : null);
            this.name = !!this.serviceProduct ? this.serviceProduct.name : (this.anyServiceProducts ? this.serviceProducts[0].name : '');
            this.publishedOnline = !!this.serviceProduct ? this.serviceProduct.publishedOnline : (this.anyServiceProducts ? this.serviceProducts[0].publishedOnline : false);
            this.description = !!this.serviceProduct ? this.serviceProduct.description : (this.anyServiceProducts ? this.serviceProducts[0].description : '');
            this.refreshSiteIds();
        },
        refreshSiteIds() {
            this.siteIds = this.anyServiceProducts ? this.serviceProducts.map(p => p.siteId) : this.sites.map(s => s.id);
        },
        afterDeletingServiceProduct() {
            // for overriding
        }
    }
}
