<template>
    <div>
        <div v-if="loadingTemplate" class="d-flex align-items-center justify-content-center" style="height: 50vh !important;">
            <span class="inline-loader" style="width: 60px; height: 54px;"></span>
        </div>
        <div v-bind:class="{'d-none': loadingTemplate}" id="stripoTemplator">
            <div v-if="!subscriptionFeatureAllowed" class="alert alert-info alert-icon mt-2 mb-2" role="alert">
                <div class="alert-content">
                    <p>{{$t('Experience the full power of our Email Template editor, which includes features such as uploading your logo, accessing stock images, changing font styles and sizes, adjusting colors, adding buttons, and much more when you upgrade to the Business or Enterprise plan.')}}</p>
                </div>
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div v-else-if="panelHiddenOnMobile" class="alert alert-info alert-icon mt-2 mb-2" role="alert">
                <div class="alert-content">
                    <p>{{$t('Experience the full power of our Email Template editor on desktop.')}}</p>
                </div>
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="d-flex flex-md-row flex-column">
                <div id="stripoSettingsContainer"></div>
                <div id="stripoPreviewContainer"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Api } from '../../../services/Api';
    import { SubscriptionFeatureMixin } from '../../../mixins/Subscriptions/SubscriptionFeatureMixin';

    export default {
        name: 'StripoTemplator',
        mixins: [SubscriptionFeatureMixin],
        props: {
            value: Object,
            accountId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loadingTemplate: false
            }
        },
        computed: {
            computedFeatureKey() {
                return 'editEmailTemplates';
            },
            templateId() {
                return !!this.value ? this.value.templateId : '';
            },
            panelHiddenOnMobile() {
                return window.innerWidth < 768 && this.subscriptionFeatureAllowed;
            }
        },
        methods: {
            refreshStripoSettingsContainer() {
                if (this.subscriptionFeatureFound && !this.loadingTemplate) {
                    if (this.subscriptionFeatureAllowed) {
                        this.showStripoSettingsContainer();
                    }
                    else {
                        this.removeStripoSettingsContainer();
                    }
                }
            },
            showStripoSettingsContainer() {
                const stripoSettingsContainer = document.getElementById("stripoSettingsContainer");
                if (!stripoSettingsContainer) {
                    return;
                }
                stripoSettingsContainer.classList.add('adjust-stripo-settings-container');

                const stripoPreviewContainer = document.getElementById("stripoPreviewContainer");
                stripoPreviewContainer.classList.add('adjust-stripo-preview-container');
            },
            removeStripoSettingsContainer() {
                const stripoSettingsContainer = document.getElementById('stripoSettingsContainer');
                if (stripoSettingsContainer) {
                    stripoSettingsContainer.remove();
                }
            },
            emitTemplate() {
                const self = this;
                window.StripoApi.getTemplate((html, css) => {
                    self.$emit('input', {
                        html: html,
                        css: css
                    });
                });
            },
            initializeStripoPlugin() {
                window.Stripo.init({
                    settingsId: 'stripoSettingsContainer',
                    previewId: 'stripoPreviewContainer',
                    html: this.value.html,
                    css: this.value.css,
                    apiRequestData: {
                        emailId: this.accountId
                    },
                    getAuthToken(callback) {
                        Api.get('Stripo/Token')
                            .then(response => callback(response.data.accessToken))
                            .catch(() => callback(null));
                    },
                    onTemplateLoaded: () => {
                        this.loadingTemplate = false;
                        this.refreshStripoSettingsContainer();
                    }
                });
            }
        },
        mounted() {
            this.loadingTemplate = true; // to bubble up loading of stripo templator
            const script = document.createElement('script');
            script.id = 'stripoScript';
            script.type = 'text/javascript';
            script.src = 'https://plugins.stripo.email/static/rev/1.74.0/stripo.js';
            script.onload = this.initializeStripoPlugin;
            document.head.appendChild(script);
        },
        watch: {
            loadingTemplate(value) {
                this.$emit('loading', value);
            },
            templateId() {
                this.loadingTemplate = true; // to bubble up loading of stripo templator
                this.initializeStripoPlugin();
            },
            subscriptionFeatureFound() {
                this.refreshStripoSettingsContainer();
            }
        },
        beforeDestroy() {
            if (window.StripoApi) {
                window.StripoApi.stop();
            }
        }
    }
</script>

<style lang="scss">
    #externalSystemContainer {
        background-color: darkgrey;
        padding: 5px 0 5px 20px;
    }

    #undoButton, #redoButton {
        display: none;
    }

    #stripoSettingsContainer {
        z-index: 1 !important;
        display: none;
        float: left;
    }

    #stripoPreviewContainer {
        width: 100%;
        float: left;
    }

    @media screen and (max-width: 768px) {
        .esdev-panel-content-wrapper {
            height: 100% !important;
        }
    }

    @media screen and (min-width: 768px) {
        .adjust-stripo-preview-container {
            width: calc(100% - 400px) !important;
        }

        .adjust-stripo-settings-container {
            display: block !important;
            width: 400px !important;
        }
    }

    .notification-zone {
        position: fixed;
        width: 400px;
        z-index: 99999;
        right: 20px;
        bottom: 80px;
    }

    .control-button {
        border-radius: 17px;
        padding: 5px 10px;
        border-color: grey;
    }

    #changeHistoryLink {
        cursor: pointer;
    }

    // Stripo styles colliding with bootstrap
    // Added specific #stripoTemplator 
    #stripoTemplator ul.nav {
        display: -webkit-box;
    }

    #stripoTemplator div.btn-group {
        display: inline-flex !important;
    }

    #stripoTemplator button.btn.dropdown-toggle.btn-default {
        &:after {
            display: none;
        }
    }
</style>
