<template>
    <section class="mb-3">
        <error-banner v-if="error" :message="error"></error-banner>
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header sub" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Pet details')}}
            <span v-if="loadingPets" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <pet-name v-model="name" :validator="petValidator"></pet-name>
                <pet-breed v-model="breedId" :validator="petValidator"></pet-breed>
                <pet-preferred v-model="preferredSettingStatus" :pet-id="petId"></pet-preferred>
                <pet-dob v-model="dateOfBirth"></pet-dob>
                <pet-gender v-model="genderId"></pet-gender>
                <pet-neutered v-model="neuteredId"></pet-neutered>
                <pet-behaviour v-model="behaviourId"></pet-behaviour>
                <pet-microchip v-model="microChip"></pet-microchip>
                <pet-deceased v-model="deceased"></pet-deceased>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { PetUpdateMixinV3 } from "../../../../mixins/Pets/PetUpdateMixinV3";
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../../ErrorValidation/ValidationMessage.vue";
    import PetDetailsName from '../../PetDetailsName.vue';
    import PetDetailsBehaviour from "../../PetDetailsBehaviour.vue";
    import PetDetailsBreed from "../../PetDetailsBreed.vue";
    import PetDetailsDeceased from "../../PetDetailsDeceased.vue";
    import PetDetailsDob from "../../PetDetailsDob.vue";
    import PetDetailsGender from "../../PetDetailsGender.vue";
    import PetDetailsMicrochip from "../../PetDetailsMicrochip.vue";
    import PetDetailsNeutered from "../../PetDetailsNeutered.vue";
    import PetDetailsPreferred from "./PetDetailsPreferred.vue";


    export default {
        name: 'PetDetailsSection',
        mixins: [PetUpdateMixinV3, StatusEmitterMixin],
        props: {
            client: Object,
            petId: String
        },
        data() {
            return {
                preferredSettingStatus: {}
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent'
            ]),
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            pet() {
                return this.pets.find(p => p.id === this.petId);
            },
            status() {
                return {
                    loading: this.loadingPets || this.preferredSettingStatus.loading,
                    error: this.error || this.preferredSettingStatus.error
                }
            }
        },
        watch: {
            savePetEvent() {
                this.tryUpdatePet();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-messsage': ValidationMessage,
            'pet-name': PetDetailsName,
            'pet-breed': PetDetailsBreed,
            'pet-preferred': PetDetailsPreferred,
            'pet-dob': PetDetailsDob,
            'pet-gender': PetDetailsGender,
            'pet-neutered': PetDetailsNeutered,
            'pet-behaviour': PetDetailsBehaviour,
            'pet-microchip': PetDetailsMicrochip,
            'pet-deceased': PetDetailsDeceased
        }
    }
</script>
