import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { CacheHelper } from '../../helpers/CacheHelper.js';
import { SiteIdMixin } from './Site/SiteIdMixin.js';

export const BookingHistoryCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BookingHistories', [
            'findBookingHistoryCache'
        ]),
        bookingEmail() {
            console.log('!!!OVERRIDE COMPUTED - bookingEmail!!!');
        },
        bookingHistoryFilterModel() {
            return {
                email: this.bookingEmail,
                siteId: this.siteId
            }
        },
        bookingHistoryFilterModelValid() {
            return !!this.bookingHistoryFilterModel.email && !!this.bookingHistoryFilterModel.siteId;
        },
        bookingHistoryCacheKey() {
            return CacheHelper.objectCacheKey(this.bookingHistoryFilterModel);
        },
        bookingHistoryCache() {
            return this.findBookingHistoryCache(this.bookingHistoryCacheKey)
        },
        bookingHistory() {
            return this.bookingHistoryCache ? this.bookingHistoryCache.value : null;
        },
        bookingHistoryTotal() {
            return !!this.bookingHistory ? this.bookingHistory.total : 0;
        },
        loadingBookingHistory() {
            return this.bookingHistoryCache && this.bookingHistoryCache.loading;
        }
    },
    watch: {
        bookingEmail() {
            this.refreshBookingHistory();
        }
    },
    methods: {
        ...mapActions('BookingHistories', [
            'getBookingHistory'
        ]),
        refreshBookingHistory() {
            if (!this.bookingHistoryCache || !!this.bookingHistoryCache.retry) {
                this.tryGetBookingHistory();
            }
        },
        tryGetBookingHistory() {
            const self = this;
            if (!self.bookingHistoryFilterModelValid) {
                return;
            }
            self.error = '';
            self.getBookingHistory({key: self.bookingHistoryCacheKey, payload: self.bookingHistoryFilterModel})
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingHistory();
    }
}
