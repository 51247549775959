import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ReportCsvCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        report: Object
    },
    computed: {
        ...mapGetters('ReportCsvs', [
            'findReportCsvCache'
        ]),
        reportId() {
            return !!this.report ? this.report.id : '';
        },
        reportCsvCache() {
            return this.findReportCsvCache(this.reportId);
        },
        reportCsv() {
            return !!this.reportCsvCache ? this.reportCsvCache.value : null;
        },
        loadingReportCsv() {
            return !!this.reportCsvCache && this.this.reportCsvCache.loading;
        }
    },
    methods: {
        ...mapActions('ReportCsvs', [
            'fetchCsv'
        ]),
        refreshReportCsv() {
            if (!this.reportCsvCache || !!this.reportCsvCache.retry) {
                this.tryFetchCsv();
            }
        },
        tryFetchCsv() {
            const self = this;
            if (!self.reportId) {
                return;
            }
            self.error = '';
            self.fetchCsv(self.reportId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
