import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AppointmentScheduleCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentSchedules', [
            'findAppointmentScheduleCache'
        ]),
        appointmentScheduleId() {
            console.log('OVERRIDE COMPUTED - appointmentScheduleId');
            return '';
        },
        appointmentScheduleCache() {
            return this.findAppointmentScheduleCache(this.appointmentScheduleId);
        },
        appointmentSchedule() {
            return !!this.appointmentScheduleCache ? this.appointmentScheduleCache.value : null;
        },
        loadingAppointmentSchedule() {
            return !!this.appointmentScheduleCache && this.appointmentScheduleCache.loading;
        }
    },
    watch: {
        appointmentScheduleId() {
            this.refreshAppointmentSchedule();
        }
    },
    methods: {
        ...mapActions('AppointmentSchedules', [
            'getAppointmentSchedule'
        ]),
        tryGetAppointmentSchedule() {
            const self = this;
            if (!self.appointmentScheduleId) {
                return;
            }
            self.error = '';
            self.getAppointmentSchedule(self.appointmentScheduleId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshAppointmentSchedule() {
            if (!this.appointmentScheduleCache || !!this.appointmentScheduleCache.retry) {
                this.tryGetAppointmentSchedule();
            }
        }
    },
    created() {
        this.refreshAppointmentSchedule();
    }
}
