<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-paw fa-1x"></i>
                    {{$t('Breeds')}}
                    <small>({{breedCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <div class="search-field-static">
                    <div class="search-field">
                        <input v-model="searchTerm" aria-label="Search" autocomplete="off" :placeholder="$t('Search breed name')" spellcheck="false" type="search" class="search-field-input">
                        <button type="submit" class="search-field-btn">
                            <span v-if="loadingBreedSearch" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-search"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="openBreedAdd" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add breed</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button @click="openBreedAdd" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add breed')}}
                </button>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <breed-mobile :search-key="breedSearchKey" @skipTo="skipTo" @breedClicked="onBreedClicked"></breed-mobile>
            <table-desktop :page-number="pageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <sort-header class="col-6" v-model="sortModel" :key-name="''">{{$t('Name')}}</sort-header>
                        <th class="col-3">{{$t('Species')}}</th>
                        <th class="col-3 text-right">{{$t('Price')}} ({{currencySymbol}})</th>
                    </tr>
                </template>
                <template #rows>
                    <breed-desktop v-for="(breed, index) in breeds" :key="breed.id" :breed="breed" :number="index" @breedClicked="onBreedClicked"></breed-desktop>
                </template>
            </table-desktop>
        </div>
        <breed-add ref="breedAdd"></breed-add>
        <breed-update :id="breedId" ref="breedUpdate"></breed-update>
    </div>
</template>

<script>
    import { mapMutations } from "vuex";
    import { BreedSearchMixin } from '../Shared/BreedSearchMixin';
    import { UserCacheMixin } from "../Shared/UserCacheMixin";
    import { SpeciesCacheMixin } from "../Shared/SpeciesCacheMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { HashHelper } from "../../helpers/HashHelper";
    import { BreedProductsSearchMixin } from "../../mixins/Products/BreedProductsSearchMixin";
    import { BreedCountCacheMixin } from "../Shared/BreedCountCacheMixin";
    import TableDesktop from "../Shared/Layout/TableDesktop.vue";
    import TableSortableHeader from "../Shared/Layout/TableSortableHeader.vue";
    import BreedItemDesktop from "./BreedItemDesktop.vue";
    import BreedsMobile from "./BreedsMobile.vue";
    import BreedAdd from "./BreedAdd.vue";
    import BreedUpdate from "./BreedUpdate.vue";
    import SiteSelect from '../Account/SiteSelect.vue';

    export default {
        name: 'Breeds',
        mixins: [UserCacheMixin, BreedSearchMixin, BreedProductsSearchMixin, SpeciesCacheMixin, CountryCacheMixin, BreedCountCacheMixin],
        data() {
            return {
                breed: Object
            }
        },
        computed: {
            siteBreedIds() {
                return this.breeds.map(b => b.id);
            },
            breedId() {
                return !!this.breed ? this.breed.id : '';
            },
            breedSearchModelValid() {
                return true;
            },
            breedSearchKey() {
                return HashHelper.createStringHash(this.searchTerm + this.siteId + this.ascending) + '-';
            },
            breedSearchCacheKey() {
                return this.breedSearchKey + this.skip + this.take;
            },
            breedCountString() {
                return this.breedCount ? this.breedCount.count : '-';
            }
        },
        methods: {
            ...mapMutations('Breeds', [
                'setBreed'
            ]),
            openBreedAdd() {
                const element = this.$refs.breedAdd.$el;
                $(element).modal('show');
            },
            onBreedClicked(breed) {
                this.setBreed(breed);
                this.breed = breed;
                const element = this.$refs.breedUpdate.$el;
                $(element).modal('show');
            }
        },
        components: {
            'breed-add': BreedAdd,
            'breed-update': BreedUpdate,
            'site-select': SiteSelect,
            'table-desktop': TableDesktop,
            'breed-desktop': BreedItemDesktop,
            'breed-mobile': BreedsMobile,
            'sort-header': TableSortableHeader
        }
    }
</script>
