import { PetCacheMixinV2 } from "../../components/Shared/PetCacheMixinV2";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapActions } from 'vuex';

export const PetDeleteMixin = {
    mixins: [PetCacheMixinV2],
    computed: {
        pet() {
            console.log('OVERRIDE COMPUTED - Pet');
            return null;
        },
        petName() {
            return !!this.pet ? this.pet.name : '';
        }
    },
    methods: {
        ...mapActions('PetsV2', [
            'deletePet'
        ]),
        tryDeletePet() {
            const self = this;
            if (!self.pet) {
                return;
            }
            self.error = '';
            self.deletePet(self.pet)
                .then(() => self.afterDeletedPet())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterDeletedPet() {
            // override if necessary
        }
    }
}
