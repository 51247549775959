import { ErrorHelper } from "../../helpers/ErrorHelper";
import { BreedProductAddMixin } from "./BreedProductAddMixin";
import { mapActions } from 'vuex';

export const BreedProductUploadMixin = {
    mixins: [BreedProductAddMixin],
    computed: {
        breedProductUpdateModel() {
            return !!this.breedProduct ? Object.assign({}, this.breedProduct, {
                amount: this.amount
            }) : {};
        },
        breedProductUpdateModelValid() {
            const model = this.breedProductUpdateModel;
            return !isNaN(parseFloat(model.amount));
        },
        breedProductUpdateModelModified() {
            return JSON.stringify(this.breedProduct) !== JSON.stringify(this.breedProductUpdateModel);
        }
    },
    watch: {
        breedProduct() {
            this.refreshBreedProductModel();
        }
    },
    methods: {
        ...mapActions('BreedProducts', [
            'updateBreedProduct'
        ]),
        tryUploadBreedProduct() {
            if (!!this.breedProduct) {
                this.tryUpdateBreedProduct();
            } else {
                this.tryAddBreedProduct();
            }
        },
        tryUpdateBreedProduct() {
            const self = this;
            if (!self.breedProductUpdateModelValid || !self.breedProductUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateBreedProduct({ siteBreedId: self.siteBreedId, payload: self.breedProductUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProductModel() {
            this.amount = this.getDefaultAmount();
        },
        getDefaultAmount() {
            return !!this.breedProduct ? this.breedProduct.amount : null;
        }
    }
}
