<template>
    <div class="form-group row">
        <div class="col-md-4 col-6">
            <label>{{title}}</label>
        </div>
        <div class="col-md-8 col-6">
            <input v-model="content" type="color">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SlottedColorInput',
        props: {
            title: String,
            value: String
        },
        computed: {
            content: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
