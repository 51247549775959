import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const SiteResourceEventColourSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'ResourceEventColour';
        },
        defaultSettingObject() {
            return {
                displayEventColour: false,
                Holiday: '#A9D86E',         // Lighter Olive Green
                Sickness: '#FF7198',        // Pink
                Personal: '#F5FBFE',        // Light Blue
                Complimentary: '#F5E3B3',   // Pastel Gold
                PublicHoliday: '#B9E8A3',   // Pastel Green
                Training: '#E6E6FA',        // Lavender
                Other: '#F6F8F8',           // Light Gray
                Unknown: '#FFFFFF'          // White
            }
        }
    }
}
