import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    purchasedCampaignTemplates: []
})

const getters = {
    findPurchasedCampaignTemplatesCache: (state) => siteId => state.purchasedCampaignTemplates.find(x => x.key === siteId)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.purchasedCampaignTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.purchasedCampaignTemplates.push({ key: key, values: [], loading: !!loading });
        }
    },

    setPurchasedCampaignTemplate(state, { key, values }) {
        const existing = state.purchasedCampaignTemplates.find(x => x.key === key);

        if (!!existing) {
            const index = state.purchasedCampaignTemplates.indexOf(existing);
            state.purchasedCampaignTemplates.splice(index, 1, { key: key, values: values, loading: false});
        } else {
            state.purchasedCampaignTemplates.push({ key: key, values: values, loading: false});
        }
    },

    setRetry(state, key) {
        const existing = state.purchasedCampaignTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },

    appendPurchasedCampaignTemplate(state, { key, values }) {
        const existing = state.purchasedCampaignTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.values.push(values);
        } else {
            state.purchasedCampaignTemplates.push({ key: key, values: values, loading: false });
        }
    },

    appendPurchasedCampaignTemplates(state, { key, values }) {
        const existing = state.purchasedCampaignTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.values.push(...values);
        } else {
            state.purchasedCampaignTemplates.push({ key: key, values: [...values], loading: false });
        }
    },
}

const actions = {
    getPurchasedCampaignTemplates({ commit }, { key }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('PurchasedCampaignTemplate')
                .then(response => {
                    commit('setPurchasedCampaignTemplate', { key: key, values: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setPurchasedCampaignTemplate', { key: key, values: [] });
                        resolve(false);
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                })
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },

    addPurchasedCampaignTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('PurchasedCampaignTemplate', payload)
                .then(response => {
                    commit('appendPurchasedCampaignTemplate', { key: key, values: response.data });
                    commit('EmailTemplateSettings/clearEmailTemplateSettings', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },

    addPurchasedCampaignTemplateBundle({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('PurchasedCampaignTemplate/Bundle', payload)
                .then(response => {
                    commit('appendPurchasedCampaignTemplates', { key: key, values: response.data });
                    commit('EmailTemplateSettings/clearEmailTemplateSettings', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
