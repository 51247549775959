<template>
    <div style="text-align: center">
        <template v-if="siteId">
            <time-zone v-model="timeZoneStatus"></time-zone>
            <breeds-add v-model="breedsStatus"></breeds-add>
            <contact-add v-model="contactStatus" :contact-name="fullName" :contact-email="email"></contact-add>
            <country-add v-model="countryStatus" :country-id="countryId"></country-add>
            <resource-add v-model="resourceStatus"></resource-add>
            <opening-hours-add v-model="openingHoursStatus"></opening-hours-add>
            <sms-templates-add v-model="smsTemplatesStatus"></sms-templates-add>
            <subscription-add v-model="subscriptionStatus" :referral-code="referralCode"></subscription-add>
            <calculate-aggregates v-model="calculateAggregateStatus"></calculate-aggregates>
            <send-welcome-notification v-model="welcomeNotificationStatus"></send-welcome-notification>
            <account-settings v-model="accountSettingsStatus" :registration-info="registrationInfo"></account-settings>
            <reminder-setting v-model="reminderSettingStatus"></reminder-setting>
            <time-format v-model="timeFormatStatus"></time-format>
            <resource-event-colour v-model="resourceEventColourStatus"></resource-event-colour>
        </template>
        <page-loader v-if="loading"></page-loader>
        <button v-if="error" @click="refreshSites" class="btn btn-primary">
            Try again
        </button>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../../Shared/SiteCacheMixin";
    import PageLoader from '../../Shared/Loading/PageLoader.vue'
    import PostRegisterBreeds from "./PostRegisterBreeds.vue";
    import PostRegisterContact from "./PostRegisterContact.vue";
    import PostRegisterCountry from "./PostRegisterCountry.vue";
    import PostRegisterResource from "./PostRegisterResource.vue";
    import PostRegisterOpeningHours from "./PostRegisterOpeningHours.vue";
    import PostRegisterSmsTemplates from "./PostRegisterSmsTemplates.vue";
    import PostRegisterAggregates from "./PostRegisterAggregates.vue";
    import PostRegisterWelcomeNotification from "./PostRegisterWelcomeNotification.vue";
    import PostRegisterSubscription from "./PostRegisterSubscription.vue";
    import PostRegisterAccountSettings from "./PostRegisterAccountSettings.vue";
    import PostRegisterTimeZone from "./PostRegisterTimeZone.vue";
    import PostRegisterReminderSetting from "./PostRegisterReminderSetting.vue";
    import PostRegisterTimeFormat from "./PostRegisterTimeFormat.vue";
    import PostRegisterResourceEventColour from "./PostRegisterResourceEventColour.vue"

    export default {
        name: 'PostRegister',
        mixins: [SiteCacheMixin],
        data() {
            return {
                breedsStatus: {},
                contactStatus: {},
                countryStatus: {},
                resourceStatus: {},
                openingHoursStatus: {},
                smsTemplatesStatus: {},
                subscriptionStatus: {},
                calculateAggregateStatus: {},
                welcomeNotificationStatus: {},
                accountSettingsStatus: {},
                timeZoneStatus: {},
                reminderSettingStatus: {},
                timeFormatStatus: {},
                resourceEventColourStatus: {}
            }
        },
        props: {
            firstName: String,
            lastName: String,
            email: String,
            countryId: String,
            referralCode: String
        },
        computed: {
            registrationInfo() {
                return {
                    fullName: this.fullName,
                    email: this.email,
                    companyName: this.companyName,
                    countryId: this.countryId
                }
            },
            statuses() {
                return [
                    this.breedsStatus,
                    this.contactStatus,
                    this.countryStatus,
                    this.resourceStatus,
                    this.openingHoursStatus,
                    this.smsTemplatesStatus,
                    this.subscriptionStatus,
                    this.calculateAggregateStatus,
                    this.welcomeNotificationStatus,
                    this.accountSettingsStatus,
                    this.timeZoneStatus,
                    this.reminderSettingStatus,
                    this.timeFormatStatus,
                    this.resourceEventColourStatus
                ];
            },
            fullName() {
                return this.firstName + ' ' + this.lastName;
            },
            loading() {
                return this.fetchingSites || this.statuses.some(s => !!s.loading);
            },
            completed() {
                return this.statuses.every(s => !!s.completed || !!s.error);
            }
        },
        watch: {
            completed(newValue) {
                if (!!newValue) {
                    this.$router.push({ name: 'main' });
                }
            }
        },
        components: {
            'page-loader': PageLoader,
            'breeds-add': PostRegisterBreeds,
            'contact-add': PostRegisterContact,
            'country-add': PostRegisterCountry,
            'resource-add': PostRegisterResource,
            'opening-hours-add': PostRegisterOpeningHours,
            'sms-templates-add': PostRegisterSmsTemplates,
            'calculate-aggregates': PostRegisterAggregates,
            'send-welcome-notification': PostRegisterWelcomeNotification,
            'subscription-add': PostRegisterSubscription,
            'account-settings': PostRegisterAccountSettings,
            'time-zone': PostRegisterTimeZone,
            'reminder-setting': PostRegisterReminderSetting,
            'time-format': PostRegisterTimeFormat,
            'resource-event-colour': PostRegisterResourceEventColour
        }
    }
</script>
