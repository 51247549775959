import { Api } from '../../services/Api';

const state = () => ({
    addressCaches: []
})

const getters = {
    findAddressCache: (state) => (key) => {
        return state.addressCaches.find(ac => ac.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.addressCaches.push({ key: key, values: [], loading: !!loading })
        }
    },
    setAddresses(state, { key, addresses }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.values = addresses;
            existing.loading = false;
        } else {
            state.addressCaches.push({ key: key, values: addresses, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendAddress(state, { key, address }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.values.push(address);
        } else {
            state.addressCaches.push({ key: key, values: [address], loading: false });
        }
    },
    insertAddress(state, { key, address }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            const old = existing.values.find(a => a.id === address.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, address);
            } else {
                existing.values.push(address);
            }
        } else {
            state.addressCaches.push({ key: key, values: [address], loading: false });
        }
    }
}

const actions = {
    searchAddresses({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Address?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setAddresses', { key: key, addresses: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addAddress({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Address', payload)
                .then(response => {
                    commit('appendAddress', { key: key, address: response.data });
                    commit('ApplicationEvents/triggerAddressAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateAddress({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Address', payload)
                .then(response => {
                    commit('insertAddress', { key: key, address: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
