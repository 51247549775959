import { CampaignHelper } from "../../helpers/CampaignHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SmsCampaignTemplatesCacheMixin } from "./SmsCampaignTemplatesCacheMixin";
import { mapActions } from 'vuex';

export const SmsCampaignTemplateUpdateMixin = {
    mixins: [SmsCampaignTemplatesCacheMixin],
    data() {
        return {
            subject: '',
            body: '',
            smsCampaignTemplateValidator: CampaignHelper.getSmsCampaignTemplateValidator()
        }
    },
    computed: {
        smsCampaignTemplateId() {
            console.log('!!!OVERRIDE COMPUTED - smsCampaignTemplateId!!!');
        },
        smsCampaignTemplate() {
            return this.smsCampaignTemplates.find(sct => sct.id === this.smsCampaignTemplateId);
        },
        smsCampaignTemplateUpdateModel() {
            return !!this.smsCampaignTemplate ? Object.assign({}, this.smsCampaignTemplate, {
                subject: this.subject,
                body: this.body
            }) : {};
        },
        smsCampaignTemplateUpdateModelValid() {
            return this.smsCampaignTemplateValidator.isValid && !!this.siteId;
        },
        smsCampaignTemplateUpdateModelModified() {
            return JSON.stringify(this.smsCampaignTemplateUpdateModel) !== JSON.stringify(this.smsCampaignTemplate);
        }
    },
    watch: {
        smsCampaignTemplate() {
            this.refreshSmsCampaignTemplateModel();
        }
    },
    methods: {
        ...mapActions('SmsCampaignTemplates', [
            'updateSmsCampaignTemplate'
        ]),
        refreshSmsCampaignTemplateModel() {
            this.subject = !!this.smsCampaignTemplate ? this.smsCampaignTemplate.subject : '';
            this.body = !!this.smsCampaignTemplate ? this.smsCampaignTemplate.body : '';
        },
        tryUpdateSmsCampaignTemplate() {
            const self = this;
            self.smsCampaignTemplateValidator.check(self.smsCampaignTemplateUpdateModel);
            if (!self.smsCampaignTemplateUpdateModelValid || !self.smsCampaignTemplateUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateSmsCampaignTemplate({ key: self.siteId, payload: self.smsCampaignTemplateUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsCampaignTemplateModel();
    }
}
