import { Api } from '../../services/Api';
import { ErrorHelper } from '../../helpers/ErrorHelper.js';

const state = () => ({
    addingBreed: false,
    breedCaches: [],
    searchingBreeds: false
})

const getters = {
    findBreedCache: (state) => (key) => {
        return state.breedCaches.find(bc => bc.key === key);
    }
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.breedCaches.find(bc => bc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.breedCaches.push({ key: key, value: null, loading: !!loading });
            }
        }
    },
    setBreed(state, breed) {
        const existing = state.breedCaches.find(bc => bc.key === breed.id);
        if (!!existing) {
            existing.value = breed;
            existing.loading = false;
        } else {
            state.breedCaches.push({ key: breed.id, value: breed, loading: false });
        }
    },
    setBreeds(state, breeds) {
        for (var i = 0; i < breeds.length; i++) {
            const breed = breeds[i]
            const existing = state.breedCaches.find(bc => bc.key === breed.id);
            if (!!existing) {
                existing.value = breed;
                existing.loading = false;
            } else {
                state.breedCaches.push({ key: breed.id, value: breed, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.breedCaches.find(bc => bc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    },
    setAdding(state, adding) {
        state.addingBreed = !!adding;
    },
    removeBreed(state, key) {
        var existing = state.breedCaches.find(bc => bc.key === key);
        if (!!existing) {
            existing.value = null;
            existing.loading = false;
        }
    },
    setSearching(state, searching) {
        state.searchingBreeds = !!searching;
    }
}

const actions = {
    addSiteBreeds({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Site/Breed')
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    fetchBreed({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [id], loading: true });
            Api.get('Breed/' + id)
                .then(response => {
                    commit('setBreed', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { keys: [id], loading: false });
                    } else {
                        commit('setRetry', [id]);
                    }
                    reject(error);
                });
        });
    },
    searchBreeds({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setSearching', true);
            commit('setLoading', { keys: keys, loading: true });
            Api.post('Breed/Search', payload)
                .then(response => {
                    commit('setBreeds', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => {
                    commit('setLoading', { keys: keys, loading: false });
                    commit('setSearching', false);
                });
        });
    },
    addBreed({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Breed', payload)
                .then(response => {
                    commit('setBreeds', response.data);
                    commit('BreedSearches/clearBreedSearches', null, { root: true });
                    commit('ApplicationEvents/triggerBreedsAdded', response.data, { root: true });
                    commit('BreedCount/clearBreedCount', keys, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateBreed({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [key], loading: true });
            Api.put('Breed', payload)
                .then(response => {
                    commit('setBreed', response.data);
                    commit('BreedSearches/clearBreedSearches', null, { root: true });
                    commit('ApplicationEvents/triggerBreedUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [key], loading: false }));
        });
    },
    deleteBreed({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [id], loading: true });
            Api.delete('Breed/' + id)
                .then(response => {
                    commit('removeBreed', id);
                    commit('BreedSearches/clearBreedSearches', null, { root: true });
                    commit('ApplicationEvents/triggerBreedDeleted', response.data, { root: true });
                    commit('BreedCount/clearBreedCount', [key], { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [id], loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
