<template>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";
    import { SubscriptionFeatureMixin } from "../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import { Api } from "../../services/Api";

    export default {
        name: 'UserNotificationAdd',
        mixins: [StatusEmitterMixin, SubscriptionFeatureMixin],
        props: {
            user: Object
        },
        data() {
            return {
                error: '',
                loading: false,
                sent: false,

            }
        },
        computed: {
            computedFeatureKey() {
                return 'campaigns';
            },
            userId() {
                return !!this.user ? this.user.id : '';
            },
            siteAdmin() {
                return !!this.user ? this.user.isSiteAdmin : false
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                }
            },
            completed() {
                return !this.siteAdmin || !!this.sent; 
            },
            canUploadNotification() {
                return !this.completed && !!this.subscriptionFeatureAllowed && !this.loading;
            },
            notificationAddModel() {
                return {
                    userId: this.userId,
                    notificationTypeId: 3
                }
            }
        },
        watch: {
            canUploadNotification() {
                this.tryAddNotification();
            }
        },
        methods: {
            tryAddNotification() {
                const self = this;
                if (!self.canUploadNotification) {
                    return;
                }
                self.error = '';
                self.loading = true;
                Api.post('Preset/Notification', self.notificationAddModel)
                    .then(() => self.sent = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        created() {
            this.tryAddNotification();
        }
    }
</script>
