import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const SiteNotificationDefaultsSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'NotificationDefaults';
        },
        defaultSettingObject() {
            return {
                isSmsCancellationEnabled: true,
                isSmsUpdatedEnabled: true,
                isSmsReminderEnabled: true,
                isSmsConfirmationEnabled: true,
                isSmsThankYouEnabled: true,
                isSmsNoShowEnabled: true,
                isSmsMissYouEnabled: false,
                isEmailCancellationEnabled: true,
                isEmailUpdatedEnabled: true,
                isEmailReminderEnabled: true,
                isEmailConfirmationEnabled: true,
                isEmailThankYouEnabled: true,
                isEmailNoShowEnabled: true,
                isEmailMissYouEnabled: false
            }
        }
    }
}
