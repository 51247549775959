import { mapActions } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { ResourceHelper } from "../../../helpers/ResourceHelper";
import { ResourceCacheMixin } from "./ResourceCacheMixin";

export const ResourceAddMixin = {
    mixins: [ResourceCacheMixin],
    data() {
        return {
            name: '',
            colour: '#FFE781',
            priorityOrder: 0,
            resourceValidator: ResourceHelper.getResourceValidator()
        }
    },
    computed: {
        resourceAddModel() {
            return {
                name: this.name,
                colour: this.colour
            }
        },
        resourceAddModelValid() {
            return !!this.siteId && this.resourceValidator.isValid;
        }
    },
    methods: {
        ...mapActions('Resources', [
            'addResource'
        ]),
        refreshResourceModel() {
            this.name = '';
            this.colour = '#FFE781';
        },
        tryAddResource() {
            const self = this;
            self.resourceValidator.check(self.resourceAddModel);
            if (!self.resourceAddModelValid) {
                return;
            }
            self.error = '';
            self.addResource({ key: self.siteId, payload: self.resourceAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshResourceModel();
    }
}
