<template>
    <div class="search-field">
        <input v-model="searchTerm" class="search-field-input form-control" :placeholder="$t('Search name, mobile, email, pet')" type="search">
        <button class="search-field-btn" type="submit">
            <span v-if="searchingClients" class="inline-loader"></span>
            <template v-else>
                <i class="fas fa-search"></i>
            </template>
        </button>
        <search-results v-if="clientResultsReady" :clients="clients" @clicked="onClicked"></search-results>
    </div>
</template>

<script>
    import { ClientSearchMixinV2 } from "../../Shared/ClientSearchMixinV2";
    import { ClientPetsSearchMixin } from "../ClientPetsSearchMixin";
    import ClientSearchResults from "./ClientSearchResults.vue";

    export default {
        name: 'ClientSearchField',
        mixins: [ClientPetsSearchMixin, ClientSearchMixinV2],
        data() {
            return {
                returnDefault: false
            }
        },
        computed: {
            clientIds() {
                return this.clients.map(c => c.id);
            }
        },
        methods: {
            onClicked() {
                this.$emit('clicked');
            }
        },
        components: {
            'search-results': ClientSearchResults
        }
    }
</script>
