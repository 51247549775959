import { OneOffHoursSearchMixin } from "../Shared/OneOffHours/OneOffHoursSearchMixin";
import { WorkingHoursCacheMixin } from "../Shared/WorkingHours/WorkingHoursCacheMixin";
import moment from 'moment'

export const ScheduleResourceHoursMixin = {
    mixins: [OneOffHoursSearchMixin, WorkingHoursCacheMixin],
    data() {
        return {
            currentDate: new Date()
        }
    },
    computed: {
        oneOffHoursSearchModel() {
            return {
                fromLocal: moment(this.currentDate).format().substring(0, 19),
                toLocal: moment(this.currentDate).add(1, 'days').format().substring(0, 19),
                siteId: this.siteId
            }
        },
        workingHourDayOffs() {  // this computed return day offs only
            const events = [];
            for (let i = 0; i < this.workingHours.length; i++) {
                const workingHour = this.workingHours[i];
                if (!!workingHour.dayOff) {
                    const dayId = workingHour.dayOfWeek.id;
                    const workingDate = moment(this.currentDate);

                    if (dayId == workingDate.day()) {
                        let event = {
                            id: workingHour.id,
                            startUtc: moment(workingDate).startOf('day').utc().toISOString(),
                            endUtc: moment(workingDate).endOf('day').utc().toISOString(),
                            resourceId: workingHour.resourceId,
                            oneOff: false
                        };
                        events.push(event);
                    }
                }
            }
            return events;
        },
        oneOffHourDayOffs() {   // this computed return day offs only
            const events = [];
            for (let i = 0; i < this.oneOffHours.length; i++) {
                const oneOffHour = this.oneOffHours[i];
                if (!!oneOffHour.dayOff) {
                    let event = {
                        id: oneOffHour.id,
                        startUtc: moment(oneOffHour.startLocal).startOf('day').utc().toISOString(),
                        endUtc: moment(oneOffHour.startLocal).endOf('day').utc().toISOString(),
                        resourceId: oneOffHour.resourceId,
                        oneOff: true
                    }
                    events.push(event);
                }
            }
            return events;
        },
        resourceDayOffs() {
            const resourceMap = new Map();

            [...this.workingHourDayOffs, ...this.oneOffHourDayOffs].forEach(hour => {
                if (!resourceMap.has(hour.resourceId)) {
                    resourceMap.set(hour.resourceId, {
                        resourceId: hour.resourceId,
                        id: hour.id,
                        hours: hour
                    });
                }
            });

            return Array.from(resourceMap.values());
        }
    }
}
