<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Address')}}</label>
        </div>
        <div class="col-md-8">
            <span v-if="loadingAddress" class="inline-loader"></span>
            <template v-else>
                <address-input v-model="addressLines"></address-input>
            </template>
            <validation-message v-if="error" :message="error"></validation-message>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import AddressInputV2 from "../Shared/Address/AddressInputV2.vue";
    import { AddressUploadMixin } from "../Shared/Address/AddressUploadMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'ClientEditAddressInput',
        mixins: [AddressUploadMixin, StatusEmitterMixin],
        props: {
            clientId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            ownerQueryModel() {
                return {
                    ownerId: this.clientId,
                    ownerTypeId: 2
                }
            },
            status() {
                return {
                    loading: this.loadingAddress,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.uploadAddress();
            }
        },
        components: {
            'address-input': AddressInputV2,
            'validation-message': ValidationMessage
        }
    }
</script>
