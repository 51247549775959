import { ErrorHelper } from '../../helpers/ErrorHelper';
import { Api } from '../../services/Api';

const state = () => ({
    smsTemplateCaches: []
})

const getters = {
    getSmsTemplateCache: state => key => {
        return state.smsTemplateCaches.find(stc => stc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.smsTemplateCaches.find(stc => stc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.smsTemplateCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setSmsTemplate(state, { key, smsTemplate }) {
        const existing = state.smsTemplateCaches.find(stc => stc.key === key);
        if (!!existing) {
            existing.value = smsTemplate;
            existing.loading = false;
            existing.retry = false;
        } else {
            state.smsTemplateCaches.push({ key: key, value: smsTemplate, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.smsTemplateCaches.find(stc => stc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findSmsTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('SmsTemplate?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setSmsTemplate', { key: key, smsTemplate: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                })
        });
    },
    addSmsTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('SmsTemplate', payload)
                .then(response => {
                    commit('setSmsTemplate', { key: key, smsTemplate: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateSmsTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('SmsTemplate', payload)
                .then(response => {
                    commit('setSmsTemplate', { key: key, smsTemplate: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
