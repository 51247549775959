import { Api } from '../../services/Api';

const state = () => ({
    fetchedAccountMigrationAllowed: false,
    fetchingAccountMigrationAllowed: false,
    accountMigrationAllowed: false
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingAccountMigrationAllowed = !!fetching;
        state.fetchedAccountMigrationAllowed = false;
    },
    setAccountMigrationAllowed(state, accountMigrationAllowed) {
        state.accountMigrationAllowed = accountMigrationAllowed.allowed;
        state.fetchedAccountMigrationAllowed = true;
        state.fetchingAccountMigrationAllowed = false;
    }
}

const actions = {
    fetchAccountMigrationAllowed({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Migration/Allowed')
                .then(response => {
                    commit('setAccountMigrationAllowed', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
