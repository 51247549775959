import { mapState, mapGetters, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const BreedCountCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BreedCount', [
            'breedCountCacheCleared'
        ]),
        ...mapGetters('BreedCount', [
            'findBreedCountCache'
        ]),
        breedCountCache() {
            return this.findBreedCountCache(this.siteId);
        },
        breedCount() {
            return !!this.breedCountCache ? this.breedCountCache.value : null;
        },
        loadingBreedCount() {
            return !!this.breedCountCache && this.breedCountCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshBreedCount();
        },
        breedCountCacheCleared() {
            this.refreshBreedCount();
        }
    },
    methods: {
        ...mapActions('BreedCount', [
            'fetchBreedCount'
        ]),
        tryFetchBreedCount() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchBreedCount(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedCount() {
            if (!this.breedCountCache || !!this.breedCountCache.retry) {
                this.tryFetchBreedCount();
            }
        }
    },
    created() {
        this.refreshBreedCount();
    }
}
