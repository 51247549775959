<template>
    <div>
        <div v-if="loadingStripoDefaultTemplateSettings" class="d-flex align-items-center justify-content-center" style="height: 50vh !important;">
            <span class="inline-loader" style="width: 60px; height: 54px;"></span>
        </div>
        <div v-else class="d-flex flex-column col-12 px-0">
            <error-banner v-if="errored" :message="errored"></error-banner>
            <email-template-pack></email-template-pack>
            <!--Filter Tabs-->
            <div class="d-flex flex-wrap justify-content-center justify-content-md-start px-2 w-100 mt-3">
                <button v-for="category in stripoDefaultTemplateSettingsCategories" class="btn m-1" v-bind:class="{'btn-secondary' : selectedCategory === category}" :key="category" @click="selectedCategory = category">{{ category }}</button>
            </div>
            <!--Individual Templates-->
            <div class="row m-0">
                <settings-item v-for="setting in filteredStripoDefaultTemplateSettings" :key="setting.id" :setting="setting" v-model="settingItemErrored"></settings-item>
            </div>
            <!--Bundled Templates-->
            <div class="h4 mt-4 px-1">{{$t('Purchase a Bundle')}}</div>
            <div class="row m-0">
                <bundle-item v-for="bundle in bundledStripoDefaultTemplateSettings" :key="bundle.id" :bundle="bundle" v-model="bundleItemErrored"></bundle-item>
            </div>
        </div>
    </div>
</template>

<script>
    import { StripoDefaultTemplateSettingsCacheMixin } from "../../Shared/StripoDefaultTemplateSettings/StripoDefaultTemplateSettingsCacheMixin";
    import { StripoDefaultTemplateBundleSettingsCacheMixin } from "../../Shared/StripoDefaultTemplateSettings/StripoDefaultTemplateBundleSettingsCacheMixin";
    import StripoDefaultTemplateSettingsDisplayItem from "./StripoDefaultTemplateSettingsDisplayItem.vue";
    import StripoDefaultTemplateBundleSettingsDisplayItem from "./StripoDefaultTemplateBundleSettingsDisplayItem.vue";
    import EmailTemplatePack from "../../EmailTemplateCredits/EmailTemplatePack.vue";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'StripoDefaultTemplateSettingsDisplay',
        mixins: [StripoDefaultTemplateSettingsCacheMixin, StripoDefaultTemplateBundleSettingsCacheMixin],
        data() {
            return {
                selectedCategory: "All",
                bundleItemErrored: '',
                settingItemErrored: ''
            }
        },
        computed: {
            stripoDefaultTemplateSettingsCategories() {
                const tabs = ["All"];
                this.stripoDefaultTemplateSettings.forEach(template => {
                    if (!tabs.includes(template.category)) {
                        tabs.push(template.category);
                    }
                });
                return tabs;
            },
            filteredStripoDefaultTemplateSettings() {
                if (this.selectedCategory === "All") {
                    return this.stripoDefaultTemplateSettings;
                } else {
                    return this.stripoDefaultTemplateSettings.filter(template => {
                        return template.category === this.selectedCategory;
                    });
                }
            },
            bundledStripoDefaultTemplateSettings() {
                return this.stripoDefaultTemplateBundleSettings.map(bundle => {

                    const templates = bundle.templateIds.map(templateId => {
                        const template = this.stripoDefaultTemplateSettings.find(t => t.id == templateId);

                        return {
                            id: template.id,
                            name: template.name,
                            thumbnail: template.thumbnail,
                            category: template.category
                        };
                    });

                    return {
                        id: bundle.id,
                        thumbnail: bundle.thumbnail,
                        name: bundle.name,
                        templateIds: bundle.templateIds,
                        templates,
                        colour: bundle.colour
                    };
                });
            },
            errored() {
                return this.error || this.bundleItemErrored || this.settingItemErrored;
            }
        },
        components: {
            'settings-item': StripoDefaultTemplateSettingsDisplayItem,
            'bundle-item': StripoDefaultTemplateBundleSettingsDisplayItem,
            'email-template-pack': EmailTemplatePack,
            'error-banner': ErrorBanner
        }
    }
</script>