import { mapState, mapMutations, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import moment from 'moment';

export const ReportsCacheMixin = {
    data() {
        return {
            error: '',
            timeoutLength: 30,
            destroyed: false
        }
    },
    computed: {
        ...mapState('Reports', [
            'fetchingReports',
            'fetchedReports',
            'reportsLastFetched',
            'reportsTimeoutId',
            'reports'
        ])
    },
    methods: {
        ...mapMutations('Reports', [
            'setTimeoutId'
        ]),
        ...mapActions('Reports', [
            'fetchReports'
        ]),
        refreshReports() {
            if ((!this.fetchedReports || this.getReportsExpired()) && !this.fetchingReports) {
                this.tryFetchReports();
            }
            clearTimeout(this.reportsTimeoutId);
            if (!this.destroyed) {
                this.setTimeoutId(setTimeout(this.refreshReports, this.getSecondsTillNextRefresh() * 1000));
            }
        },
        tryFetchReports() {
            const self = this;
            self.error = '';
            self.fetchReports()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        getSecondsSinceRefreshed() {
            return moment().diff(this.reportsLastFetched, 'seconds');
        },
        getReportsExpired() {
            return !this.reportsLastFetched || this.getSecondsSinceRefreshed() > this.timeoutLength;
        },
        getSecondsTillNextRefresh() {
            return (this.getReportsExpired() ? this.timeoutLength : this.timeoutLength - this.getSecondsSinceRefreshed()) + 1;
        }
    },
    created() {
        this.refreshReports();
    },
    beforeDestroy() {
        this.destroyed = true;
    }
}
