import { mapState } from "vuex";
import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";

export const PetAddImagesMixin = {
    mixins: [ImageAddMixin],
    data() {
        return {
            addedPet: null
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'petAddedEvent'
        ]),
        ownerId() {
            return this.addedPet ? this.addedPet.id : '';
        },
        ownerTypeId() {
            return 3;
        }
    },
    watch: {
        petAddedEvent(pet) {
            this.addedPet = pet;
            this.tryAddImages();
            this.addedPet = null;
        }
    },
    methods: {
        findAndCacheImages() {
            // overriding to do nothing
        }
    }
}
