<template>
    <tr class="link pointer" v-bind:class="classType" @click="onClick">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a class="text-truncate">{{productName}}</a>
            </div>
        </td>
        <td class="text-truncate">{{description}}</td>
        <td class="text-truncate">{{publishedOnline}}</td>
        <td class="text-truncate text-right">{{amountString}}</td>
    </tr>
</template>

<script>
    import { ServiceItemMixin } from "./ServiceItemMixin";

    export default {
        name: 'ServiceItemDesktop',
        mixins: [ServiceItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
