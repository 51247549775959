<template>
    <div class="d-flex flex-wrap mt-1">
        <div v-for="item in orderItems" class="appointment-order-item">
            {{item}}
        </div>
    </div>
</template>

<script>
    import { OrderCacheMixin } from "../Shared/Orders/OrderCacheMixin";

    export default {
        name: 'CalendarAppointmentOrders',
        mixins: [OrderCacheMixin],
        props: {
            appointment: Object
        },
        computed: {
            appointmentId() {
                return !!this.appointment ? this.appointment.id : '';
            },
            orderItems() {
                return !!this.orders ? this.orders.flatMap(order => order.orderItems.map(item => item.name)) : [];
            }
        },
        methods: {
            tryFindOrders() {
                // do nothing
            }
        }
    }
</script>

<style scoped>
    .appointment-order-item {
        border-radius: 5rem;
        padding: 0.05rem 0.3rem;
        border: 1px solid #F4457B;
        width: max-content;
        font-size: 0.7rem;
        color: #F4457B;
        margin: 0.05rem;
        background-color: #fff;
    }
</style>
