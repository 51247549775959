export const ContractHelper = {
    getContractValidator() {
        return {
            isValid: false,
            templateId: '',
            check(model) {
                this.templateId = !!model.templateId ? '' : 'Please select a template';
                this.isValid = !this.templateId;
            }
        }
    },
    getTemplateAddValidator() {
        return {
            isValid: false,
            templateTypeName: '',
            html: '',
            check(model) {
                this.templateTypeName = !!model.templateTypeName ? '' : 'Please enter a template name.';
                this.html = !!model.html ? '' : 'Please enter some valid template content.';
                this.isValid = !this.templateTypeName && !this.html;
            }
        }
    },
    getTemplateUpdateValidator() {
        return {
            isValid: false,
            templateTypeId: '',
            html: '',
            check(model) {
                this.templateTypeId = !!model.templateTypeId ? '' : 'Invalid template type ID.';
                this.html = !!model.html ? '' : 'Please enter some valid template content.';
                this.isValid = !this.templateTypeId && !this.html;
            }
        }
    },
    contractTemplateCacheKey(contractTemplate) {
        return contractTemplate.templateTypeId + contractTemplate.version;
    }
}
