import { Api } from "../../services/Api";

const state = () => ({
    serviceProductGroups: []
})

const getters = {
    findServiceProductGroup: (state) => key => state.serviceProductGroups.find(spg => spg.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.serviceProductGroups.push({ key: key, values: [], loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setServiceProducts(state, { key, products }) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            existing.values = products;
            existing.loading = false;
        } else {
            state.serviceProductGroups.push({ key: key, values: products, loading: false });
        }
    },
    appendServiceProducts(state, { key, products }) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            existing.values = existing.values.concat(products);
        } else {
            state.serviceProductGroups.push({ key: key, values: products, loading: false });
        }
    },
    insertServiceProducts(state, { key, products }) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            for (var i = 0; i < products.length; i++) {
                const product = products[i];
                const previous = existing.values.find(p => p.id === product.id);
                if (!!previous) {
                    const index = existing.values.indexOf(previous);
                    existing.values.splice(index, 1, product);
                } else {
                    existing.values.push(product);
                }
            }
        } else {
            state.serviceProductGroups.push({ key: key, values: products, loading: false });
        }
    },
    removeServiceProduct(state, { key, id }) {
        const existing = state.serviceProductGroups.find(spg => spg.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(p => p.id !== id);
        }
    }
}

const actions = {
    groupServiceProducts({ commit }, productId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: productId, loading: true });
            Api.get('ServiceProduct/Group/' + productId)
                .then(response => {
                    commit('setServiceProducts', { key: productId, products: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', productId);
                    reject(error);
                });
        });
    },
    addServiceProducts({ commit }, { productId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: productId, loading: true });
            Api.post('ServiceProduct', payload)
                .then(response => {
                    commit('appendServiceProducts', { key: productId, products: response.data });
                    commit('ServiceProductSearches/clearServiceProductSearches', null, { root: true });
                    commit('ServiceProductCount/clearServiceProductCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: productId, loading: false }));
        });
    },
    updateServiceProducts({ commit }, { productId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: productId, loading: true });
            Api.put('ServiceProduct', payload)
                .then(response => {
                    commit('insertServiceProducts', { key: productId, products: response.data });
                    commit('ServiceProductSearches/clearServiceProductSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: productId, loading: false }));
        });
    },
    deleteServiceProduct({ commit }, { productId, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: productId, loading: true });
            Api.delete('ServiceProduct/' + id)
                .then(() => {
                    commit('removeServiceProduct', { key: productId, id: id });
                    commit('ServiceProductSearches/clearServiceProductSearches', null, { root: true });
                    commit('ServiceProductCount/clearServiceProductCount', null, { root: true });
                    resolve(true);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: productId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
