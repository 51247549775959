import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PetProductCacheMixinV2 } from "./PetProductCacheMixinV2";
import { BreedProductCacheMixin } from './BreedProductCacheMixin';

export const PetProductAddMixinV2 = {
    mixins: [PetProductCacheMixinV2, BreedProductCacheMixin],
    data() {
        return {
            amount: null
        }
    },
    computed: {
        petProductAddModel() {
            return {
                petId: this.petId,
                amount: this.amount
            }
        },
        petProductAddModelValid() {
            const model = this.petProductAddModel;
            return !!model.petId && !isNaN(parseFloat(model.amount));
        },
        petProductAddModelModified() {
            const model = this.petProductAddModel;
            return !this.breedProduct || model.amount !== this.breedProduct.amount;
        }
    },
    watch: {
        petId() {
            this.refreshPetProductModel();
        },
        breedProduct() {
            this.refreshPetProductModel();
        }
    },
    methods: {
        ...mapActions('PetProductsV2', [
            'addPetProduct'
        ]),
        tryAddPetProduct() {
            const self = this;
            if (!self.petProductAddModelValid || !self.petProductAddModelModified) {
                return;
            }
            self.error = '';
            self.addPetProduct({ petId: self.petId, payload: self.petProductAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshPetProductModel() {
            this.amount = this.getDefaultAmount();
        },
        getDefaultAmount() {
            return !!this.breedProduct ? this.breedProduct.amount : null;
        }
    },
    created() {
        this.refreshPetProductModel();
    }
}
