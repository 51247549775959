<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{$t('Image')}}</label>
        </div>
        <div v-if="preview" class="col-md-8">
            <div class="image-uploads mw-400 m-0">
                <ul>
                    <li class="active">
                        <button @click="clearUploads" class="btn" type="button">
                            <i class="far fa-times"></i>
                        </button>
                        <figure>
                            <img :src="preview.url" width="120">
                        </figure>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else class="col-md-8">
            <div class="file-upload mw-400 m-0">
                <label>
                    <span>{{$t('Browse files')}}</span>
                    <input @change="onFilePicked" type="file" accept="image/*" />
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { ImageAddMixin } from "../Shared/Images/ImageAddMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'ResourceImageAdd',
        mixins: [ImageAddMixin],
        props: {
            resourceId: String
        },
        data() {
            return {
                addedResourceId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'resourceAddedEvent',
                'saveResourceEvent'
            ]),
            ownerId() {
                return !!this.resourceId ? this.resourceId : this.addedResourceId;
            },
            ownerTypeId() {
                return 14;
            },
            preview() {
                return this.imagePreviewSet.length > 0 ? this.imagePreviewSet[0] : null;
            }
        },
        watch: {
            resourceAddedEvent(resource) {
                this.addedResourceId = resource.id;
                this.tryAddImages();
                this.addedResourceId = '';
            },
            saveResourceEvent() {
                this.tryAddImages();
            }
        }
    }
</script>
