<template>
    <div class="pricing-plan_card-list">
        <feature-item :value="clients"></feature-item>
        <feature-item :value="users"></feature-item>
        <feature-item :value="resources"></feature-item>
        <feature-item :value="email"></feature-item>
        <feature-item :value="sms"></feature-item>
        <feature-item :value="contracts"></feature-item>
        <feature-item :value="payments"></feature-item>
        <feature-item :value="support"></feature-item>
        <feature-item :value="campaigns"></feature-item>
        <feature-item :value="editEmailTemplates"></feature-item>
        <feature-item :value="buyEmailTemplates"></feature-item>
        <feature-item :value="campaignCredits"></feature-item>
        <feature-item :value="dashboard"></feature-item>
        <feature-item :value="reporting"></feature-item>
        <feature-item :value="onlineBooking"></feature-item>
        <feature-item :value="smsCredits"></feature-item>
        <feature-item :value="multiSite"></feature-item>
    </div>
</template>

<script>
    import SubscriptionFeatureListItem from "./SubscriptionFeatureListItem.vue";

    export default {
        name: 'SubscriptionFeatureList',
        props: {
            feature: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        computed: {
            clients() {
                const quantity = this.feature.unlimitedClients ? this.$t('Unlimited') : this.feature.clientAllowance ? this.feature.clientAllowance : '';
                return quantity ? quantity + ` ${this.$t('Clients')}` : '';
            },
            users() {
                const quantity = this.feature.unlimitedUsers ? this.$t('Unlimited') : this.feature.userAllowance ? this.feature.userAllowance : '';
                return quantity ? quantity + ` ${this.$t('Users')}` : '';
            },
            resources() {
                const quantity = this.feature.unlimitedResources ? this.$t('Unlimited') : this.feature.resourceAllowance ? this.feature.resourceAllowance : '';
                return quantity ? quantity + ` ${this.$t('Resources')}` : '';
            },
            email() {
                return this.feature.email ? this.$t('Email Reminders') : '';
            },
            sms() {
                return this.feature.sms ? this.$t('SMS Reminders') : '';
            },
            smsCredits() {
                const quantity = this.feature.smsCreditAllowance ? this.feature.smsCreditAllowance : 0;
                return quantity ? quantity + ` ${this.$t('SMS Credits')}` : '';
            },
            contracts() {
                return this.feature.contracts ? this.$t('E-Contracts & Registration Forms') : '';
            },
            payments() {
                return this.feature.payments ? this.$t('Payments & Deposits') : '';
            },
            support() {
                return this.feature.support ? this.$t('Support') : '';
            },
            campaigns() {
                return this.feature.campaigns ? this.$t('Marketing') : '';
            },
            campaignCredits() {
                const quantity = this.feature.campaignCreditAllowance ? this.feature.campaignCreditAllowance : 0;
                return quantity ? quantity + ` ${this.$t('Campaign Credits')}` : '';
            },
            editEmailTemplates() {
                return this.feature.editEmailTemplates ? this.$t('Customise Email Templates')  : '';
            },
            buyEmailTemplates() {
                return this.feature.buyEmailTemplates ? this.$t('Pre-designed Email Templates') : '';
            },
            dashboard() {
                return this.feature.dashboard ? this.$t('Dashboard / Analytics') : '';
            },
            reporting() {
                return this.feature.reporting ? this.$t('Reporting') : '';
            },
            onlineBooking() {
                return this.feature.onlineBooking ? this.$t('Online Booking') : '';
            },
            multiSite() {
                return this.feature.multiSite ? this.$t('Multi Site Management') : `1 ${this.$t('Site')}`;
            }
        },
        components: {
            'feature-item': SubscriptionFeatureListItem
        }
    }
</script>
