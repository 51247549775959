<template>
    <collapsed-static id="booking-suggestions" :title="$t('Alternative Date & Time')">
        <span v-if="loadingBookingSuggestions" class="inline-loader"></span>
        <div v-else-if="bookingSuggestions.length > 0" class="table-responsive">
            <table class="table table-striped ps-table table-hover">
                <thead>
                    <tr>
                        <th>{{$t('Alternative Date')}}</th>
                        <th>{{$t('Time')}}</th>
                        <th>{{$t('Status')}}</th>
                        <th>{{$t('Sent')}}?</th>
                        <th>{{$t('Resend')}}?</th>
                    </tr>
                </thead>
                <tbody>
                    <suggestion-item v-for="bookingSuggestion in bookingSuggestions" :key="bookingSuggestion.id" :booking-suggestion="bookingSuggestion"></suggestion-item>
                </tbody>
            </table>
        </div>
        <div v-else class="alert-wrapper px-0">
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('No alternative date & time for this request.')}}</p>
                </div>
            </div>
        </div>
    </collapsed-static>
</template>

<script>
    import { BookingSuggestionsCacheMixin } from "../Shared/BookingSuggestionsCacheMixin";
    import CollapsedSection from '../Shared/Layout/CollapsedSection.vue';
    import BookingSuggestionsItem from "./BookingSuggestionsItem.vue";

    export default {
        name: 'BookingSuggestions',
        mixins: [BookingSuggestionsCacheMixin],
        props: {
            id: ''
        },
        computed: {
            bookingId() {
                return !!this.id ? this.id : '';
            }
        },
        components: {
            'collapsed-static': CollapsedSection,
            'suggestion-item': BookingSuggestionsItem
        }
    }
</script>
