import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { HashHelper } from "../../../helpers/HashHelper";

export const ScheduleUpdateConflictsMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ConflictSearches', [
            'findConflictsSearchCache'
        ]),
        appointmentScheduleUpdateModel() {
            console.log('!!!OVERRIDE COMPUTED - appointmentScheduleUpdateModel!!!');
        },
        appointmentScheduleUpdateModelValid() {
            console.log('!!!OVERRIDE COMPUTED - appointmentScheduleUpdateModelValid!!!');
        },
        conflictsSearchKey() {
            return HashHelper.createObjectHash(this.appointmentScheduleUpdateModel);
        },
        conflictsSearch() {
            return this.findConflictsSearchCache(this.conflictsSearchKey);
        },
        conflicts() {
            return !!this.conflictsSearch ? this.conflictsSearch.values : [];
        },
        loadingConflicts() {
            return !!this.conflictsSearch && this.conflictsSearch.loading;
        }
    },
    watch: {
        conflictsSearchKey() {
            this.refreshConflictsSearch();
        }
    },
    methods: {
        ...mapActions('ConflictSearches', [
            'searchUpdateConflicts'
        ]),
        refreshConflictsSearch() {
            if (!this.conflictsSearch || !!this.conflictsSearch.retry) {
                this.trySearchConflicts();
            }
        },
        trySearchConflicts() {
            const self = this;
            if (!self.appointmentScheduleUpdateModelValid) {
                return;
            }
            self.error = '';
            self.searchUpdateConflicts({ key: self.conflictsSearchKey, payload: self.appointmentScheduleUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshConflictsSearch();
    }
}
