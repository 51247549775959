<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-cards-blank fa-1x"></i>
                    {{$t('Referrals')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <!--placeholder to occupy header-tools area-->
            </div>
        </header>
        <div class="content">
            <error-banner v-if="error" :message="error"></error-banner>
            <page-loader v-if="fetchingReferrer"></page-loader>
            <div v-else class="d-flex flex-column align-items-center">
                <div class="rewards_card m-3">
                    <div class="pricing-plan_card-header p-4">
                        <i class="fa-duotone fa-7x fa-gift"></i>
                    </div>
                    <div class="pricing-plan_card-body rewards_card-body w-100 px-4 pt-2">
                        <div class="text-center font-weight-bold white">Refer friends, get rewards!</div>
                        <!--With Referrer Code Generated Section-->
                        <template v-if="referrer">
                            <div class="text-center font-weight-bold white">Your Current Score: {{referrerScore}}</div>
                            <div class="d-flex flex-column align-items-center justify-content-center my-4">
                                <div class="font-weight-bold white text-center">Your Referral Link</div>
                                <div class="rewards_card-body-code text-center w-100 py-2 px-3 mt-1">{{referrerUrl.href}}</div>
                                <popper :force-show="showPopper" :visible-arrow="false" :options="{ placement: 'top' }">
                                    <div class="popper">Link Copied!</div>
                                    <div slot="reference"></div>
                                </popper>
                                <button class="rewards_card-body-btn-copy-link mt-2"
                                        v-clipboard:copy="referrerUrl.href"
                                        v-clipboard:success="handleClipboardSuccess">
                                    <span class="mr-2">COPY LINK</span>
                                    <i class="fa-duotone fa-link"></i>
                                </button>
                            </div>
                        </template>
                        <!--Without Referrer Code Section-->
                        <div v-else class="d-flex flex-column align-items-center my-4 col-md-6 col-12">
                            <div class="text-center font-weight-bold white mb-3">
                                Click the button below to generate referral link you can share to earn rewards!
                            </div>
                            <button @click="tryAddReferrer" class="btn btn-primary font-weight-bold" v-bind:disabled="addingReferrer">
                                Generate Link
                                <span v-if="addingReferrer" class="inline-loader"></span>
                            </button>
                        </div>
                        <referral-rewards-instruction></referral-rewards-instruction>
                    </div>
                </div>
                <referral-rewards :score="referrerScore"></referral-rewards>
            </div>
        </div>
    </div>
</template>

<script>
    import { ReferrerAddMixin } from "../../mixins/Referrals/ReferrerAddMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";
    import ReferralsRewards from "./ReferralsRewards.vue";
    import ReferralsRewardsInstruction from "./ReferralsRewardsInstruction.vue";
    import Popper from 'vue-popperjs';

    export default {
        name: 'Referrals',
        mixins: [ReferrerAddMixin],
        data() {
            return {
                showPopper: false
            }
        },
        computed: {
            referrerUrl() {
                return new URL(this.$router.resolve({
                    name: 'register',
                    query: { referral: this.referrerCode }
                }).href, window.location.origin)
            }
        },
        methods: {
            handleClipboardSuccess() {
                this.showPopper = true;
                setTimeout(() => {
                    this.showPopper = false
                }, 1000);
            }
        },
        components: {
            'referral-rewards': ReferralsRewards,
            'referral-rewards-instruction': ReferralsRewardsInstruction,
            'error-banner': ErrorBanner,
            'page-loader': PageLoader,
            'popper': Popper
        }
    }
</script>

<style scoped>
    .popper {
        width: max-content;
        max-width: 200px;
        box-shadow: 0 0.1rem 0.25rem rgba(0,0,0,.35);
        background-color: #fff;
        color: #4bccd1;
        padding: 0.25rem 0.5rem;
        border: none;
    }
</style>
