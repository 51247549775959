<template>
    <div class="pricing-plan_card m-3" v-bind:style="{'background-color': '#EEF0F2'}">
        <div class="d-flex flex-column align-items-center">
            <div class="pricing-plan_card-header p-4">
                <img alt="Default" :src="logoUrl" width="70">
            </div>
            <div class="pricing-plan_card-body w-100 px-3 pt-2">
                <p class="font-weight-bold mb-1" style="color: #000; font-size: 1.25rem;">{{name}}</p>
                <sumup-description v-if="id === 1" :display-connect="displayConnect"></sumup-description>
                <span v-if="loadingPaymentOAuths" class="inline-loader"></span>
                <template v-else>
                    <div class="w-100 px-3">
                        <button v-if="displayConnect" 
                                @click="onClick"
                                class="pricing-plan_card-body-button pricing-plan_card-body-button-primary w-100">
                            <i class="fa-duotone fa-link fa-1x"></i>
                            {{$t('Connect')}}
                        </button>
                        <template v-else>
                            <div class="font-weight-bold text-center w-100" style="color: #F4457B; font-size: 1rem;">
                                <i class="fa-sharp fa-regular fa-link"></i>
                                {{$t('Connected!')}}
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { PaymentOAuthsCacheMixin } from "./PaymentOAuthsCacheMixin";
    import ProviderInfoSumup from "./ProviderInfoSumup.vue";

    export default {
        name: 'ProviderInfo',
        mixins: [PaymentOAuthsCacheMixin],
        props:{
            provider: Object
        },
        computed: {
            id() {
                return this.provider.id;
            },
            displayConnect() {
                return !this.paymentOAuths.some(p => p.providerType.id === this.id);
            },
            name() {
                return this.provider.name;
            },
            description() {
                return this.provider.description;
            },
            logoUrl() {
                return this.provider.logoUrl;
            }
        },
        methods: {
            onClick() {
                this.$emit('connect', this.id);
            }
        },
        components: {
            'sumup-description': ProviderInfoSumup
        }
    }
</script>
