import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AppointmentScheduleDeleteMixin = {
    data() {
        return {
            updateTypeId: 0
        }
    },
    computed: {
        appointmentId() {
            console.log('!!!OVERRIDE COMPUTED - appointmentId!!!');
        },
        appointmentScheduleDeleteModel() {
            return {
                updateTypeId: this.updateTypeId
            }
        }
    },
    methods: {
        ...mapActions('Appointments', [
            'deleteAppointments'
        ]),
        tryDeleteAppointments() {
            const self = this;
            if (!self.appointmentScheduleDeleteModel) {
                return;
            }
            self.error = '';
            self.deleteAppointments({ key: self.appointmentId, payload: self.appointmentScheduleDeleteModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
