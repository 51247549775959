import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const ImageCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Images', [
            'findImageCache'
        ]),
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        imageFilterModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            };
        },
        imageFilterModelIsValid() {
            return !!this.imageFilterModel.ownerId && !!this.imageFilterModel.ownerTypeId;
        },
        imageCacheKey() {
            return CacheHelper.ownerRequestCacheKey(this.imageFilterModel);
        },
        imageCache() {
            return this.findImageCache(this.imageCacheKey);
        },
        images() {
            return !!this.imageCache ? this.imageCache.values : [];
        },
        loadingImages() {
            return !!this.imageCache && this.imageCache.loading;
        }
    },
    watch: {
        imageFilterModel() {
            this.refreshImages();
        }
    },
    methods: {
        ...mapActions('Images', [
            'searchImages'
        ]),
        refreshImages() {
            if (!this.imageCache || !!this.imageCache.retry) {
                this.trySearchImages();
            }
        },
        trySearchImages() {
            const self = this;
            self.error = '';
            if (!self.imageFilterModelIsValid) {
                return;
            }
            self.searchImages({ keys: [self.imageCacheKey], payload: self.imageFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshImages();
    }
}
