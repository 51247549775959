import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AccountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Account', [
            'fetchedAccount',
            'fetchingAccount',
            'account'
        ]),
        accountId() {
            return !!this.account ? this.account.id : '';
        }
    },
    methods: {
        ...mapActions('Account', [
            'fetchAccount'
        ]),
        tryFetchAccount() {
            const self = this;
            self.fetchAccount()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshAccount() {
            if (!this.fetchedAccount && !this.fetchingAccount) {
                this.tryFetchAccount();
            }
        }
    },
    created() {
        this.refreshAccount();
    }
}
