import { Api } from "../../services/Api";

const state = () => ({
    dateFormatOptions: [],
    fetchingDateFormatOptions: false,
    fetchedDateFormatOptions: false
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchedDateFormatOptions = false;
        state.fetchingDateFormatOptions = !!fetching;
    },
    setDateFormatOptions(state, dateFormatOptions) {
        state.dateFormatOptions = dateFormatOptions;
        state.fetchedDateFormatOptions = true;
        state.fetchingDateFormatOptions = false;
    }
}

const actions = {
    getDateFormatOptions({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('DateFormatSetting/Option')
                .then(response => {
                    commit('setDateFormatOptions', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
