<template>
    <div v-if="display" class="pricing-plan_card-body-text">{{ value }}</div>
</template>

<script>
    export default {
        name: 'SubscriptionFeatureListItem',
        props: {
            value: String
        },
        computed: {
            display() {
                return !!this.value;
            }
        }
    }
</script>
