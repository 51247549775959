import { Api } from '../../services/Api';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import router from '../../routing';

export const CampaignAddAllowedMixin = {
    data() {
        return {
            campaignAddAllowed: false,
            fetchingCampaignAddAllowed: false
        }
    },
    methods: {
        openCampaignAdd() {
            const self = this;

            self.fetchingCampaignAddAllowed = true;

            return new Promise((resolve) => {
                Api.get('Campaign/Allowed')
                    .then(response => {
                        self.campaignAddAllowed = response.data.allowed;

                        if (self.campaignAddAllowed) {
                            router.push({ name: 'campaignAdd' });
                        }
                        else if (self.$route.name !== 'upsell') {
                            router.push({ name: 'upsell' });
                        }

                        resolve(response);
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.fetchingCampaignAddAllowed = false);
            })
        }
    }
}