import { ErrorHelper } from "../../helpers/ErrorHelper";
import { TAndCsAcceptMixin } from "../Checkout/TAndCsAcceptMixin";
import { SubscriptionEntityPaymentsCacheMixin } from "./SubscriptionEntityPaymentsCacheMixin";
import { mapActions } from 'vuex';

export const SubscriptionUpgradePaymentAddMixin = {
    mixins: [SubscriptionEntityPaymentsCacheMixin, TAndCsAcceptMixin],
    computed: {
        completedPayment() {
            return this.subscriptionEntityPayments.find(p => p.status.id === 1);
        },
        outstandingPayment() {
            return this.subscriptionEntityPayments.find(p => p.status.id === 5);
        },
        outstandingPaymentId() {
            return !!this.outstandingPayment ? this.outstandingPayment.id : '';
        },
        canAddSubscriptionUpgradePayment() {
            return !!this.subscriptionEntityId && !this.loadingSubscriptionEntityPayments && !this.outstandingPayment && !this.completedPayment;
        }
    },
    methods: {
        ...mapActions('SubscriptionEntityPayments', [
            'addSubscriptionUpgradePayment'
        ]),
        tryAddSubscriptionUpgradePayment() {
            const self = this;
            if (!self.canAddSubscriptionUpgradePayment) {
                return;
            }
            self.error = '';
            self.addSubscriptionUpgradePayment(self.subscriptionEntityId)
                .then(self.managePurchase)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        managePurchase() {
            if (!!this.completedPayment) {
                this.gotoComplete();
            } else if (!this.areTermsAccepted()) {
                return;
            } else if (!!this.outstandingPayment) {
                this.gotoCheckout();
            } else {
                this.tryAddSubscriptionUpgradePayment();
            }
        },
        gotoCheckout() {
            if (!!this.outstandingPaymentId) {
                this.$router.push({
                    name: 'subscriptionCheckout',
                    params: { 'paymentId': this.outstandingPaymentId },
                    query: { 'onComplete': 'subscriptionUpgradeComplete' }
                });
            }
        },
        gotoComplete() {
            this.$router.push({ name: 'subscriptionUpgradeComplete' });
        }
    }
}
