<template>
    <div v-if="showWarning" class="alert alert-warning alert-icon">
        Handle with care
    </div>
</template>

<script>
    export default {
        name: 'AppointmentOverviewPetWarning',
        props: {
            pet: Object
        },
        computed: {
            showWarning() {
                return this.pet.behaviour.id === 3;
            }
        }
    }
</script>
