import { mapMutations } from 'vuex';
import router from '../../routing';

export const SiteItemMixin = {
    props: {
        site: Object
    },
    computed: {
        siteName() {
            return this.site.name;
        },
        startLetter() {
            return this.siteName[0].toUpperCase();
        },
        companyName() {
            return this.site.companyName;
        }
    },
    methods: {
        ...mapMutations('Sites', [
            'setSelected'
        ]),
        gotoSite() {
            if (!!this.site) {
                this.setSelected(!!this.site ? this.site.id : '');
                router.push({ name: 'siteEdit' });
            }
        }
    }
}
