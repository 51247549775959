import { Api } from "../../services/Api";

const state = () => ({
    fetchingReportSettings: false,
    fetchedReportSettings: false,
    reportSettings: []
})

const getters = {
    findReportSetting: (state) => id => state.reportSettings.find(s => s.id === id)
}

const mutations = {
    setFetching(state) {
        state.fetchedReportSettings = false;
        state.fetchingReportSettings = true;
    },
    setReportSettings(state, reportSettings) {
        state.reportSettings = reportSettings;
        state.fetchedReportSettings = true;
        state.fetchingReportSettings = false;
    },
    setError(state) {
        state.fetchedReportSettings = false;
        state.fetchingReportSettings = false;
    }
}

const actions = {
    fetchReportSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Report/Setting')
                .then(response => {
                    commit('setReportSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setError');
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
