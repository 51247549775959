<template>
    <collapsed-static v-if="!addedScheduleId" id="booking-conflicts" :title="conflictTitle">
        <span v-if="loadingAppointments" class="inline-loader"></span>
        <template v-else>
            <template v-if="conflictCount > 0">
                <booking-conflict v-for="(participant, index) in participants"
                                  :key="participant.id"
                                  :participant="participant"
                                  :appointments="sortedAppointments"
                                  :index="index">
                </booking-conflict>
            </template>
            <div v-else class="alert-wrapper px-0">
                <div role="alert" class="alert alert-info alert-icon">
                    <div class="alert-content">
                        <p>{{$t('No appointment conflicts exist for this booking request.')}}</p>
                    </div>
                </div>
            </div>
        </template>
    </collapsed-static>
</template>

<script>
    import moment from 'moment-timezone';
    import { mapState } from 'vuex';
    import { AppointmentSearchMixin } from "../Shared/AppointmentSearchMixin";
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";
    import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';
    import CollapsedStaticSection from '../Shared/Layout/CollapsedStaticSection.vue';
    import BookingConflict from './BookingConflict.vue';

    export default {
        name: 'BookingConflicts',
        mixins: [BookingCacheMixin, AppointmentSearchMixin, SiteIdMixin],
        props: {
            id: ''
        },
        data() {
            return {
                addedScheduleId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'appointmentScheduleAddedEvent'
            ]),
            bookingId() {
                return !!this.id ? this.id : '';
            },
            searchModel() {
                return {
                    startUtc: this.booking ? this.booking.startUtc : null,
                    endUtc: this.booking ? moment(this.booking.startUtc).clone().add(this.booking.duration, 'minutes').utc().format() : null,
                    siteIds: [this.siteId],
                    statusIds: [0, 2]
                }
            },
            participants() {
                return !!this.booking ? this.booking.participants : [];
            },
            sortedAppointments() {
                return this.appointments.length > 0 ? this.appointments.sort((a, b) => new Date(a.startUtc) - new Date(b.startUtc)) : [];
            },
            conflictCount() {
                return this.appointments.length > 0 && this.booking && this.booking.participants ?
                    this.appointments.filter(a => this.booking.participants.some(p => p.resourceId === a.resourceId)).length : 0;
            },
            conflictTitle() {
                return this.$t('Conflicts') + ` (${this.conflictCount})`;
            }
        },
        watch: {
            appointmentScheduleAddedEvent(schedule) {
                this.addedScheduleId = schedule.id
            }
        },
        components: {
            'booking-conflict': BookingConflict,
            'collapsed-static': CollapsedStaticSection
        }
    }
</script>
