import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    fetchedSelfUserResources: false,
    fetchingSelfUserResources: false,
    loadingSelfUserResources: false,
    selfUserResources: []
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSelfUserResources = fetching;
        state.fetchedSelfUserResources = false;
    },
    setLoading(state, loading) {
        state.loadingSelfUserResources = !!loading;
    },
    setSelfUserResources(state, selfUserResources) {
        state.selfUserResources = selfUserResources;
        state.fetchedSelfUserResources = true;
        state.fetchingSelfUserResources = false;
    },
    clearSelfUserResources(state) {
        state.selfUserResources = [];
        state.fetchedSelfUserResources = false;
        state.fetchingSelfUserResources = false;
    }
}

const actions = {
    fetchSelfUserResources({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Self/User/Resource')
                .then(response => {
                    commit('setSelfUserResources', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setSelfUserResources', []);
                        resolve(false);
                    } else {
                        commit('setFetching', false);
                        reject(error);
                    }
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
