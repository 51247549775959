<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{$t('Date of birth')}}</label>
        </div>
        <div class="col-md-8">
            <a v-if="dateStringFormatted" aria-expanded="false" data-target="#edit-dob" data-toggle="collapse" class="collapsed">{{dateStringFormatted}} {{age}}</a>
            <div class="row mw-400" v-bind:class="{ collapse: showSummary }" id="edit-dob">
                <div class="col-4">
                    <input v-model="dayString" class="text-center form-control" maxlength="2" placeholder="DD" type="number">
                </div>
                <div class="col-4">
                    <input v-model="monthString" class="text-center form-control" maxlength="2" placeholder="MM" type="number">
                </div>
                <div class="col-4">
                    <input v-model="yearString" class="text-center form-control" maxlength="4" placeholder="YYYY" type="number">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';

    export default {
        name: 'PetDetailsDob',
        mixins: [DateDisplayMixin],
        data() {
            return {
                currentYear: new Date().getFullYear(),
                dayString: '',
                monthString: '',
                yearString: '',
                showSummary: false
            }
        },
        props: {
            value: String
        },
        computed: {
            year() {
                if (this.yearString.length < 4) return '';
                const year = parseInt(this.yearString, 10);
                return (isNaN(year) || year < 1 || year > this.currentYear) ? '' : ('0000' + year.toString()).slice(-4);
            },
            month() {
                const month = parseInt(this.monthString, 10);
                return (isNaN(month) || month < 1 || month > 12) ? '' : ('00' + month.toString()).slice(-2);
            },
            day() {
                const day = parseInt(this.dayString, 10);
                return (isNaN(day) || day < 1 || day > 31) ? '' : ('00' + day.toString()).slice(-2);
            },
            date() {
                return (!!this.year && !!this.month && !!this.day) ? moment(this.year + this.month + this.day, 'YYYYMMDD') : null;
            },
            dateString() {
                return !!this.date ? this.date.utc().format() : null;
            },
            dateStringFormatted() {
                return !!this.date ? this.date.local().format(this.momentJSFormat) : '';
            },
            age() {
                if (!this.date) return '';

                const today = moment();
                const diffInYears = today.diff(this.date, 'years');
                const diffInMonths = today.diff(this.date, 'months');

                if (diffInYears === 0) {
                    if (diffInMonths === 0) {
                        return '(Less than 1 month old)';
                    } else if (diffInMonths === 1) {
                        return '(1 month old)';
                    } else {
                        return `(${diffInMonths} months old)`;
                    }
                } else if (diffInYears === 1) {
                    return '(1 year old)';
                } else {
                    return `(${diffInYears} years old)`;
                }
            }
        },
        watch: {
            dateString(value) {
                if (!!value) {
                    this.$emit('input', value);
                }
            },
            value() {
                this.refreshValues();
            }
        },
        methods: {
            refreshValues() {
                if (!!this.value) {
                    const date = moment(this.value);
                    this.dayString = date.format('DD');
                    this.monthString = date.format('MM');
                    this.yearString = date.format('YYYY');
                    this.showSummary = true;
                } else {
                    this.dayString = '';
                    this.monthString = '';
                    this.yearString = '';
                    this.showSummary = false;
                }
            }
        },
        created() {
            this.refreshValues();
        }
    }
</script>
