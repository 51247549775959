<template>
    <div v-if="displaySite" class="dropdown bootstrap-select form-control">
        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
            <span class="text-left text-truncate" style="width: calc(100% - 1rem)">{{siteName}}</span>
        </button>
        <div class="inner show">
            <ul class="dropdown-menu">
                <li v-for="site in filteredSites"
                    :key="site.id"
                    class="dropdown-item pointer"
                    @click="setSelected(site.id)"
                    v-bind:class="{'active': site.id === siteId}">
                    {{site.name}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { PaymentApiUserCacheMixin } from "../../mixins/Payments/PaymentApiUserCacheMixin";
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'PaymentReportSiteSelect',
        mixins: [SiteCacheMixin, PaymentApiUserCacheMixin],
        data() {
            return {
                selectedSite: null
            }
        },
        computed: {
            ...mapState('Sites', [
                'selected'
            ]),
            filteredSites() {
                return this.sites.filter(s => this.ownerAccounts.indexOf(s.id) > -1);
            }
        },
        watch: {
            filteredSites() {
                this.refreshSelected();
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            refreshSelected() {
                if (this.filteredSites.length > 0 && !this.filteredSites.find(s => s.id === this.selectedSite)) {
                    this.selectedSite = this.filteredSites[0].id;
                }
            }
        },
        created() {
            this.refreshSelected();
        }
    }
</script>
