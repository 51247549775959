<template>
    <div class="tags-display" v-bind:class="{'tags-display-show': showAvailableTags}">
        <div class="alert alert-info alert-icon mt-2 mb-2" role="alert">
            <div class="alert-content">
                <h3>{{$t('Available tags (click to copy):')}}</h3>
                <a v-for="tag in tags" v-clipboard:copy=tag v-clipboard:error="onError">{{tag}} </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'TagsDisplay',
        props: {
            tags: []
        },
        methods: {
            onError() {
                alert('Copy failed');
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'showAvailableTags'
            ]),
        }
    }
</script>

<style>
    .tags-display {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    .tags-display-show {
        max-height: 100vh;
        transition: max-height 0.3s ease;
    }
</style>
