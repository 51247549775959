<template>
    <section class="rounded-card-white mb-3">
        <error-banner v-if="error" :message="error"></error-banner>
        <div role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{$t('Are you sure?')}}</p>
            </div>
            <button v-if="loading || ready" @click="confirm" v-bind:disabled="loading" type="button" class="btn btn-danger">
                <span v-if="loading" class="inline-loader"></span>
                <template v-else>
                    {{$t('Yes')}}
                </template>
            </button>
            <button @click="cancel" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <p v-if="required">Please first select resources to move oustanding appointments to:</p>
        <resource-update v-for="resource in resources" v-model="statuses" :key="resource.id" :resource="resource"></resource-update>
    </section>
</template>

<script>
    import AppointmentResourceUpdateItem from "../Shared/Resources/AppointmentResourceUpdateItem.vue";
    import { mapMutations } from 'vuex';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'ResourceDeleteFlow',
        data() {
            return {
                statuses: []
            }
        },
        props: {
            resources: Array,
            externalLoading: Boolean
        },
        computed: {
            error() {
                return this.statuses.map(s => s.error).filter(e => !!e).join(", ");
            },
            loading() {
                return this.statuses.some(s => s.loading) || this.externalLoading;
            },
            required() {
                return this.statuses.some(s => s.required);
            },
            ready() {
                return this.statuses.every(s => !s.required || s.ready);
            },
            completed() {
                return this.statuses.every(s => !s.required || s.completed);
            }
        },
        watch: {
            completed(value) {
                if (value) {
                    this.$emit('confirm');
                }
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveAppointmentResources'
            ]),
            cancel() {
                this.$emit('cancel');
            },
            confirm() {
                if (this.required) {
                    this.triggerSaveAppointmentResources();
                } else {
                    this.$emit('confirm');
                }
            }
        },
        components: {
            'resource-update': AppointmentResourceUpdateItem,
            'error-banner': ErrorBanner
        }
    }
</script>
