import Shepherd from 'shepherd.js';

export const OnboardingGuideHelper = {
    getTourDefaults(tourName, confirmCancel, confirmCancelMessage) {
        return new Shepherd.Tour({
            useModalOverlay: true,
            confirmCancel: !!confirmCancel,
            confirmCancelMessage: confirmCancelMessage,
            exitOnEsc: true,
            keyboardNavigation: true,
            tourName: tourName
        });
    },
    createTourStep(id, title, text, elementId, position, buttons, hideProgress=false) {
        return {
            id,
            title,
            text,
            attachTo: {
                element: elementId,
                on: position
            },
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (elementId && elementId.trim() !== '') {
                        // Check if the element you want to attach to is present in the DOM
                        const element = document.querySelector(elementId);
                        if (element) {
                            resolve();
                        } else {
                            // Poll for the element and resolve the promise when it's found
                            const interval = setInterval(() => {
                                const element = document.querySelector(elementId);
                                if (element) {
                                    clearInterval(interval);
                                    resolve();
                                }
                            }, 100);
                        }
                    } else {
                        resolve();
                    }
                });
            },
            buttons,
            when: {
                show() {
                    const currentStep = Shepherd.activeTour?.getCurrentStep();
                    if (!!hideProgress) {
                        return false;
                    }
                    const currentStepElement = currentStep?.getElement();
                    const footer = currentStepElement?.querySelector('.shepherd-footer');
                    const progress = document.createElement('span');
                    progress.className = 'shepherd-progress';
                    progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
                    // Add the progress indicator to the footer
                    footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'));
                }
            },
            canClickTarget: false,
            cancelIcon: {
                enabled: true
            }
        }
    },
    getResponsiveElementId(id) {
        // Determine the screen width to decide if it's a mobile view
        const isMobileView = window.innerWidth <= 768;

        // Append "Mobile" or "Desktop" to the id based on the view
        if (isMobileView) {
            return id + 'Mobile';
        } else {
            return id + 'Desktop';
        }
    }
}
