<template>
    <Bar v-if="chartData" :options="chartOptions" :data="chartData"></Bar>
</template>

<script>
    import { Bar } from 'vue-chartjs';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

    export default {
        name: 'StackedBarChart',
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                }
            }
        },
        props: {
            chartData: Object
        },
        components: {
            Bar
        }
    }
</script>
