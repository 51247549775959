<template>
    <div class="modal image-carousel" role="dialog" aria-hidden="true" v-observe-visibility=isVisible>
        <div class="image-carousel-content">
            <span data-dismiss="modal" aria-label="Close" class="far fa-x pointer image-carousel-close" aria-hidden="true"></span>
            <div class="image-carousel-body" v-if="wasOpen">
                <span v-if="images.length > 1"
                      class="far fa-chevron-circle-left pointer mr-2 image-carousel-btn" 
                      v-bind:class="{'image-carousel-icon-hide': activeIndex === 0}"
                      @click="prevImage"></span>
                <img :src="images[activeIndex]"/>
                <span v-if="images.length > 1"
                      class="far fa-chevron-circle-right pointer ml-2 image-carousel-btn"
                      v-bind:class="{'image-carousel-icon-hide': activeIndex == images.length - 1}"
                      @click="nextImage"></span>
            </div>
            <div class="image-carousel-footer">
                <div>{{activeIndex + 1}} / {{images.length}}</div>
                <div class="image-carousel-title">{{title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImageCarousel',
        props: {
            title: String,
            images: []
        },
        data() {
            return {
                activeIndex: 0,
                wasOpen: false,
            };
        },
        methods: {
            prevImage() {
                if (this.activeIndex > 0) {
                    this.activeIndex--;
                }
            },
            nextImage() {
                if (this.activeIndex < this.images.length - 1) {
                    this.activeIndex++;
                }
            },
            isVisible(value) {
                if (value) {
                    this.wasOpen = true;
                    this.$emit("opened");
                } else if (this.wasOpen) {
                    this.wasOpen = false;
                    this.$emit("closed");
                }
            }
        }
    }
</script>

<style scoped>
    .image-carousel {
        max-height: 100vh !important;
        max-width: 100vw !important;
        padding-right: 0 !important;
    }
    .image-carousel-content {
        display: flex;
        flex-direction: column;
        margin-inline: auto;
        height: 100vh !important;
        justify-content: center;
        align-items: center;
    }
    .image-carousel-close {
        margin: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 10rem;
        background-color: rgba(0,0,0,0.5);
        color: white;
    }
    .image-carousel-close:hover {
        background-color: #4bccd1;
    }
    .image-carousel-btn {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 10rem;
        font-size: 2.5rem;
        font-weight: 300;
        color: #FFF;
        background-color: #4bccd1;
    }
    .image-carousel-btn:hover {
        background-color: #FFF;
        color: #4bccd1;
    }
    .image-carousel-footer {
        margin-top: 1rem;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFF;
    }
    .image-carousel-title {
        font-size: 1.5rem;
    }
    .image-carousel-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto !important;
        max-height: 90% !important;
        width: 30rem !important;
    }
    .image-carousel img {
        height: 100% !important;
        min-width: 30rem !important;
    }
    .image-carousel-icon-hide {
        color: rgba(255,255,255,0.1) !important;
        background-color: rgba(0,0,0,0.1) !important;
    }
    .image-carousel-icon-hide:hover {
        background-color: rgba(0,0,0,0.1) !important;
    }
    @media screen and (max-width: 768px) {
        .image-carousel-btn {
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
        }
        .image-carousel-close {
            margin: 1rem;
        }
        .image-carousel-content {
            width: 100vw !important;
        }
        .image-carousel-body {
            max-height: 90% !important;
            width: 18rem !important;
        }
        .image-carousel img {
            height: auto !important;
            max-height: 100% !important;
            min-width: 18rem !important;
        }
    }
</style>
