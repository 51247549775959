import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const OrderItemAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('OrderItemAggregateSearches', [
            'findOrderItemAggregatesSearch'
        ]),
        aggregateSearch() {
            return this.findOrderItemAggregatesSearch(this.aggregateSearchKey);
        },
        orderItemAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingOrderItemAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('OrderItemAggregateSearches', [
            'searchOrderItemAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchOrderItemAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
