<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{title}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <input v-model="content" class="form-control" :type="inputType" :placeholder="title">
            <validation-message v-if="validation" :message="validation"></validation-message>
        </div>
    </div>
</template>

<script>
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ValidatedInput',
        props: {
            title: String,
            value: String,
            validation: String,
            placeholder: String,
            inputType: {
                type: String,
                default: 'text'
            }
        },
        computed: {
            content: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
