import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const PetProductCacheMixinV2 = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PetProductsV2', [
            'findPetProductCache'
        ]),
        petId() {
            console.log('OVERRIDE COMPUTED - petId');
            return '';
        },
        petProductCache() {
            return this.findPetProductCache(this.petId);
        },
        petProduct() {
            return !!this.petProductCache ? this.petProductCache.value : null;
        },
        loadingPetProduct() {
            return !!this.petProductCache && this.petProductCache.loading;
        }
    },
    watch: {
        petId() {
            this.refreshPetProduct();
        }
    },
    methods: {
        ...mapActions('PetProductsV2', [
            'findPetProduct'
        ]),
        tryFindPetProduct() {
            const self = this;
            if (!self.petId) {
                return;
            }
            self.error = '';
            self.findPetProduct(self.petId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshPetProduct() {
            if (!this.petProductCache || !!this.petProductCache.retry) {
                this.tryFindPetProduct();
            }
        }
    },
    created() {
        this.refreshPetProduct();
    }
}
