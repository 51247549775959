import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionUpgradeFeaturesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SubscriptionUpgradeFeatures', [
            'findSubscriptionUpgradeFeaturesCache'
        ]),
        yearly() {
            return false;
        },
        subscriptionUpgradeFeaturesQueryModel() {
            return {
                yearly: this.yearly
            }
        },
        subscriptionUpgradeFeaturesCacheKey() {
            return this.yearly.toString();
        },
        subscriptionUpgradeFeaturesCache() {
            return this.findSubscriptionUpgradeFeaturesCache(this.subscriptionUpgradeFeaturesCacheKey);
        },
        subscriptionUpgradeFeatures() {
            return !!this.subscriptionUpgradeFeaturesCache ? this.subscriptionUpgradeFeaturesCache.values : [];
        },
        loadingSubscriptionUpgradeFeatures() {
            return !!this.subscriptionUpgradeFeaturesCache && !!this.subscriptionUpgradeFeaturesCache.loading;
        }
    },
    watch: {
        subscriptionUpgradeFeaturesQueryModel() {
            this.refreshSubscriptionUpgradeFeatures();
        }
    },
    methods: {
        ...mapActions('SubscriptionUpgradeFeatures', [
            'getSubscriptionUpgradeFeatures'
        ]),
        refreshSubscriptionUpgradeFeatures() {
            if (!this.subscriptionUpgradeFeaturesCache || !!this.subscriptionUpgradeFeaturesCache.retry) {
                this.tryGetSubscriptionUpgradeFeatures();
            }
        },
        tryGetSubscriptionUpgradeFeatures() {
            const self = this;
            self.error = '';
            self.getSubscriptionUpgradeFeatures({ key: self.subscriptionUpgradeFeaturesCacheKey, payload: self.subscriptionUpgradeFeaturesQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionUpgradeFeatures();
    }
}
