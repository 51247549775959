<template>
    <collapsed-section id="site-booking" :title="$t('Online Booking')">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="!enabled">
            <div class="alert-wrapper">
                <div role="alert" class="alert alert-info alert-icon">
                    <div class="alert-content">
                        <p>{{$t('This feature is currently disabled.')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <template v-else>
            <div v-if="subscriptionFeatureAllowed">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="homeLink">{{$t('Portal Link')}}</label>
                    </div>
                    <div class="col-md-8">
                        <a :href="fullHomeLink" target="_blank">{{fullHomeLink}}</a>
                    </div>
                </div>
                <toggle-input v-model="bookingEnabled" :title="$t('Enabled?')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t('Toggle to activate or deactivate the online booking portal.')}}</span>
                        </tooltip>
                    </template>
                </toggle-input>
                <toggle-input v-model="blockOffEvents" :title="$t('Block off events?')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t("Toggle to block off timeslots that fall within an event's time.")}}</span>
                        </tooltip>
                    </template>
                </toggle-input>
                <toggle-input v-model="singlePetResourceEnabled" :title="$t('1:1 Resource-to-Pet Assignment Enabled?')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t('Enable to assign one resource per pet in a booking with multiple pets. Disable to assign a single resource to all pets.')}}</span>
                        </tooltip>
                    </template>
                </toggle-input>
                <div v-if="unregisteredClientBookingAllowed" class="form-group row">
                    <div class="col-md-4">
                        <label>
                            {{$t('Who can book?')}}
                            <tooltip>
                                <span>{{$t('Select which client type can book online.')}}</span>
                            </tooltip>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="bootstrap-select form-control">
                            <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                                {{selectedClientTypeDescription}}
                            </button>
                            <div class="inner show">
                                <ul class="dropdown-menu">
                                    <li v-for="option in clientTypeOptions"
                                        class="dropdown-item pointer"
                                        :key="option.value"
                                        @click="setClientType(option.value)"
                                        v-bind:class="{'active': option.value === clientType}">
                                        {{$t(option.name)}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>
                            {{$t('Schedule Auto Acceptance:')}}
                            <tooltip>
                                <span>{{$t('Choose client types whose booking requests will be automatically accepted, creating appointments instantly.')}}</span>
                            </tooltip>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="bootstrap-select form-control">
                            <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                                {{selectedReviewTypeDescription}}
                            </button>
                            <div class="inner show">
                                <ul class="dropdown-menu">
                                    <li v-for="option in reviewTypeOptions"
                                        class="dropdown-item pointer"
                                        :key="option.value"
                                        @click="setReviewType(option.value)"
                                        v-bind:class="{'active': option.value === reviewType}">
                                        {{$t(option.name)}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="unregisteredClientBookingAllowed" class="form-group row">
                    <div class="col-md-4">
                        <label>
                            {{$t('Pet Limit for Unregistered Clients:')}}
                            <tooltip>
                                <span>{{$t('Sets the limit of pets allowed in a booking request for an Unregistered Client.')}}</span>
                            </tooltip>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <input v-model="maxPetsInput" class="form-control" placeholder="" type="number" value="1" min="1">
                        <validation-message v-if="!!maxPetsMessage" :message="maxPetsMessage"></validation-message>
                    </div>
                </div>
                <toggle-input v-model="displayPetProfileImage" :title="$t('Display Pet Profile Image?')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t("Toggle to display the profile image of registered clients' pets.")}}</span>
                        </tooltip>
                    </template>
                </toggle-input>
                <toggle-input v-model="serviceSelectEnabled" :title="$t('Allow Service Selection?')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t('Allow clients to submit booking requests without selecting services.')}}</span>
                        </tooltip>
                    </template>
                </toggle-input>
                <template v-if="serviceSelectEnabled">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>
                                {{$t('Service Options:')}}
                                <tooltip>
                                    <span>{{$t('Determine the price displayed for each service. Auto: Pet profile price > Breed price > Published services.')}}</span>
                                </tooltip>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <div class="bootstrap-select form-control">
                                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                                    {{selectedPricingTypeDescription}}
                                </button>
                                <div class="inner show">
                                    <ul class="dropdown-menu">
                                        <li v-for="option in pricingTypeOptions"
                                            class="dropdown-item pointer"
                                            :key="option.value"
                                            @click="setPricingType(option.value)"
                                            v-bind:class="{'active': option.value === pricingType}">
                                            {{$t(option.name)}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <toggle-input v-if="pricingType === 1 || pricingType === 4" v-model="multipleServiceEnabled" :title="$t('Allow multiple service selections?')">
                        <template #tooltip>
                            <tooltip>
                                <span>{{$t('Enable multiple service selections per pet in a booking request, applicable only when service options are set to auto or published services only.')}}</span>
                            </tooltip>
                        </template>
                    </toggle-input>
                    <div v-if="multipleServiceEnabled" class="form-group row">
                        <div class="col-md-4">
                            <label>
                                {{$t('Services Limit:')}}
                                <tooltip>
                                    <span>{{$t('Set the limit for the number of services allowed per pet in a booking request.')}}</span>
                                </tooltip>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input v-model="maxServices" class="form-control" placeholder="" type="number" value="1" min="1">
                            <validation-message v-if="!!maxServicesMessage" :message="maxServicesMessage"></validation-message>
                        </div>
                    </div>
                </template>
                <div class="row form-group">
                    <div class="col-md-4">
                        <label for="title">
                            {{$t('Minimum Request Notice')}}
                            <tooltip>
                                <span>{{$t('Set the earliest date available for booking requests. Previous dates will be disabled.')}}</span>
                            </tooltip>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="d-flex mw-400">
                            <div class="col-6 pl-0">
                                <label for="title">{{$t('Amount')}}</label>
                                <input v-model="minNoticeInput" class="form-control" placeholder="" type="number" value="1" min="1">
                            </div>
                            <div class="col-6 pr-0">
                                <label for="title">{{$t('Unit')}}</label>
                                <div class="bootstrap-select form-control">
                                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                                        {{selectedMinNoticeUnitDescription}}
                                    </button>
                                    <div class="inner show">
                                        <ul class="dropdown-menu">
                                            <li v-for="option in noticeUnitOptions"
                                                class="dropdown-item pointer"
                                                :key="option.value"
                                                @click="setMinNoticeUnit(option.value)"
                                                v-bind:class="{'active': option.value === minNoticeUnit}">
                                                {{$t(option.name)}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">
                        <label for="title">
                            {{$t('Maximum Request Notice')}}
                            <tooltip>
                                <span>{{$t('Set the latest date available for booking requests. Future dates beyond this will be disabled.')}}</span>
                            </tooltip>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="d-flex mw-400">
                            <div class="col-6 pl-0">
                                <label for="title">{{$t('Amount')}}</label>
                                <input v-model="maxNoticeInput" class="form-control" placeholder="" type="number" value="1" min="1">
                            </div>
                            <div class="col-6 pr-0">
                                <label for="title">{{$t('Unit')}}</label>
                                <div class="bootstrap-select form-control">
                                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                                        {{selectedMaxNoticeUnitDescription}}
                                    </button>
                                    <div class="inner show">
                                        <ul class="dropdown-menu">
                                            <li v-for="option in noticeUnitOptions"
                                                class="dropdown-item pointer"
                                                :key="option.value"
                                                @click="setMaxNoticeUnit(option.value)"
                                                v-bind:class="{'active': option.value === maxNoticeUnit}">
                                                {{$t(option.name)}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <validation-message v-if="!!maxNoticeMessage" :message="maxNoticeMessage"></validation-message>
                    </div>
                </div>
                <portal-image v-model="bookingPortalImageStatus"></portal-image>
                <booking-terms v-model="bookingTermsStatus"></booking-terms>
            </div>
            <div v-else>
                <div class="alert-wrapper">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t('Feature not available on your current subscription.')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </collapsed-section>
</template>

<script>
    import { mapState } from 'vuex';
    import { SiteBookingSettingUploadMixin } from "../../../mixins/Settings/Site/SiteBookingSettingUploadMixin";
    import { BookingSettingsCacheMixin } from "../../../mixins/Bookings/BookingSettingsCacheMixin";
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import SiteUpdateBookingPortalImage from './SiteUpdateBookingPortalImage.vue';
    import SiteUpdateBookingTerms from './SiteUpdateBookingTerms.vue';
    import ValidationMessage from '../../ErrorValidation/ValidationMessage.vue';
    import Tooltip from '../../Shared/Layout/Tooltip.vue';

    export default {
        name: 'SiteUpdateBooking',
        mixins: [SiteBookingSettingUploadMixin, BookingSettingsCacheMixin, StatusEmitterMixin],
        data() {
            return {
                bookingPortalImageStatus: {},
                bookingTermsStatus: {},
                maxNoticeMessage: '',
                maxPetsMessage: '',
                maxServicesMessage: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            fullHomeLink() {
                return this.bookingSettings ? this.bookingSettings.homeLink + this.siteId : '';
            },
            selectedClientType() {
                return this.clientTypeOptions.find(cto => cto.value === this.clientType);
            },
            selectedClientTypeDescription() {
                return !!this.selectedClientType ? this.$t(this.selectedClientType.name) : `${this.$t('Please select')}...`;
            },
            selectedReviewType() {
                return this.reviewTypeOptions.find(rto => rto.value === this.reviewType);
            },
            selectedReviewTypeDescription() {
                return !!this.selectedReviewType ? this.$t(this.selectedReviewType.name) : `${this.$t('Please select')}...`;
            },
            selectedPricingType() {
                return this.pricingTypeOptions.find(pto => pto.value === this.pricingType);
            },
            selectedPricingTypeDescription() {
                return !!this.selectedPricingType ? this.$t(this.selectedPricingType.name) : `${this.$t('Please select')}...`;
            },
            selectedMinNoticeUnitDescription() {
                return !!this.selectedMinNoticeUnit ? this.$t(this.selectedMinNoticeUnit.name) : `${this.$t('Please select')}...`;
            },
            selectedMaxNoticeUnitDescription() {
                return !!this.selectedMaxNoticeUnit ? this.$t(this.selectedMaxNoticeUnit.name) : `${this.$t('Please select')}...`;
            },
            status() {
                return {
                    loading: !!this.loadingSetting || !!this.bookingPortalImageStatus.loading || !!this.bookingTermsStatus.loading,
                    error: this.error || this.bookingPortalImageStatus.error || this.bookingTermsStatus.error
                }
            },
            maxPetsInput: {
                get() {
                    return parseInt(this.maxPets);
                },
                set(newValue) {
                    const parsedValue = parseInt(newValue);
                    if (!isNaN(parsedValue)) {
                        this.maxPets = parsedValue;
                    } else {
                        this.maxPets = 1;
                    }
                }
            },
            minNoticeInput: {
                get() {
                    return parseInt(this.minNotice);
                },
                set(newValue) {
                    const parsedValue = parseInt(newValue);
                    if (!isNaN(parsedValue)) {
                        this.minNotice = parsedValue;
                    } else {
                        this.minNotice = 0;
                    }
                }
            },
            maxNoticeInput: {
                get() {
                    return parseInt(this.maxNotice);
                },
                set(newValue) {
                    const parsedValue = parseInt(newValue);
                    if (!isNaN(parsedValue)) {
                        this.maxNotice = parsedValue;
                    } else {
                        this.maxNotice = 30;
                    }
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.check();
                if (!!this.settingEditModelValid) {
                    this.tryUploadSetting();
                }
            }
        },
        methods: {
            check() {
                this.maxNoticeMessage = !!this.settingEditModelValid ? '' : this.calculatedMaxNotice > this.calculatedMinNotice ? '' : 'Maximum notice should be greater than minimum notice.';
                this.maxPetsMessage = !!this.settingEditModelValid ? '' : this.maxPets > 0 ? this.maxPets < 11 ? '' : "Maximum pets should be no greater than 10." : 'Maximum pets should be greater than 0.';
                this.maxServicesMessage = !!this.settingEditModelValid ? '' : this.maxServices > 0 ? this.maxServices < 11 ? '' : "Maximum services should be no greater than 10." : 'Maximum services should be greater than 0.';
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'toggle-input': SlottedToggle,
            'error-banner': ErrorBanner,
            'portal-image': SiteUpdateBookingPortalImage,
            'booking-terms': SiteUpdateBookingTerms,
            'validation-message': ValidationMessage,
            'tooltip': Tooltip
        }
    }
</script>
