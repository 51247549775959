export const ContactHelper = {
    getContactValidator() {
        return {
            name: '',
            isValid: false,
            check(model) {
                this.name = !!model.name ? '' : 'Please enter contact name.';
                this.isValid = !this.name;
            }
        }
    }
}
