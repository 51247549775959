<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Breed')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div v-if="selected">
                <div class="mb-1">
                    {{selected.name}}
                </div>
                <a @click="selected=null" class="link pointer">Clear</a>
            </div>
            <template v-else>
                <breed-search v-model="selected"></breed-search>
            </template>
            <validation-message v-if="validator.breedId" :message="validator.breedId"></validation-message>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { BreedCacheMixin } from "../Shared/BreedCacheMixin";
    import BreedSearchField from "./Search/BreedSearchField.vue";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'PetDetailsBreed',
        mixins: [BreedCacheMixin],
        props: {
            value: String,
            validator: Object
        },
        computed: {
            breedId() {
                return this.value;
            },
            selected: {
                get() {
                    return this.breed;
                },
                set(value) {
                    if (!!value) {
                        this.setBreed(value);
                    }
                    this.$emit('input', !!value ? value.id : '');
                }
            }
        },
        methods: {
            ...mapMutations('Breeds', [
                'setBreed'
            ])
        },
        components: {
            'breed-search': BreedSearchField,
            'validation-message': ValidationMessage
        }
    }
</script>
