import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { PetPreferredSettingMixin } from "./PetPreferredSettingMixin";

export const PetPreferredSettingUploadMixin = {
    mixins: [SettingUploadMixin, PetPreferredSettingMixin],
    data() {
        return {
            resource: null
        }
    },
    computed: {
        settingEditModel() {
            return {
                id: this.resource.id,
                name: this.resource.name
            };
        },
        settingEditModelValid() {
            return !!this.settingEditModel.id;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.resource = {
                id: this.settingObject.id,
                name: this.settingObject.name
            };
        }
    }
}
