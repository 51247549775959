import { Api } from '../../services/Api';

const state = () => ({
    settingsCaches: []
})

const getters = {
    findSettingsCache: (state) => (key) => {
        return state.settingsCaches.find(sc => sc.key === key);
    }
}

const mutations = {
    setSettings(state, { key, settings }) {
        var existing = state.settingsCaches.find(sc => sc.key === key);
        if (!!existing) {
            const update = Object.assign({}, existing, {
                values : settings,
                loading : false,
                attempted : true
            })
            const index = state.settingsCaches.indexOf(existing);
            state.settingsCaches.splice(index, 1, update);
        } else {
            state.settingsCaches.push({ key: key, values: settings, loading: false, attempted: true });
        }
    },
    setRetry(state, key) {
        var existing = state.settingsCaches.find(sc => sc.key === key);
        if (existing) {
            existing.retry = true;
            existing.loading = false;
            existing.attempted = true;
        }
    },
    appendSetting(state, { key, setting }) {
        var existing = state.settingsCaches.find(sc => sc.key === key);
        if (!!existing) {
            existing.values.push(setting);
        } else {
            state.settingsCaches.push({ key: key, values: [setting], loading: false });
        }
    },
    insertSetting(state, { key, setting }) {
        var existing = state.settingsCaches.find(sc => sc.key === key);
        if (!!existing) {
            const old = existing.values.find(s => s.key === setting.key);
            const index = existing.values.indexOf(old);
            existing.values.splice(index, 1, setting);
        } else {
            state.settingsCaches.push({ key: key, values: [setting], loading: false });
        }
    },
    setLoading(state, { key, loading }) {
        var existing = state.settingsCaches.find(sc => sc.key === key);
        if (!!existing) {
            existing.loading = loading;
            existing.retry = false;
        } else {
            state.settingsCaches.push({ key: key, values: [], loading: loading });
        }
    }
}

const actions = {
    getSetting({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('AppSetting?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setSettings', { key: key, settings: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addSetting({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('AppSetting', payload)
                .then(response => {
                    commit('appendSetting', { key: key, setting: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateSetting({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('AppSetting', payload)
                .then(response => {
                    commit('insertSetting', { key: key, setting: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
