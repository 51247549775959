import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const EmployeeCountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('EmployeeCount', [
            'loadingEmployeeCount',
            'loadedEmployeeCount',
            'employeeCount',
            'employeeCountCacheCleared'
        ])
    },
    watch: {
        employeeCountCacheCleared() {
            this.refreshEmployeeCount();
        }
    },
    methods: {
        ...mapActions('EmployeeCount', [
            'fetchEmployeeCount'
        ]),
        refreshEmployeeCount() {
            if (!this.loadingEmployeeCount && !this.loadedEmployeeCount) {
                this.tryFetchEmployeeCount();
            }
        },
        tryFetchEmployeeCount() {
            const self = this;

            self.error = '';
            self.fetchEmployeeCount()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmployeeCount();
    }
}
