import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ClientCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Clients', [
            'findClientCache'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        clientCache() {
            return this.findClientCache(this.clientId);
        },
        client() {
            return !!this.clientCache ? this.clientCache.value : null;
        },
        loadingClient() {
            return !!this.clientCache && this.clientCache.loading;
        },
        clientFullname() {
            return !!this.client ? this.client.firstName + ' ' + this.client.lastName : '-';
        }
    },
    watch: {
        clientId() {
            this.refreshClient();
        }
    },
    methods: {
        ...mapActions('Clients', [
            'getClient'
        ]),
        refreshClient() {
            if (!this.clientCache || !!this.clientCache.retry) {
                this.tryGetClient();
            }
        },
        tryGetClient() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.getClient(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClient();
    }
}
