<template>
    <vue-timepicker v-model="time" :format="format" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
</template>

<script>
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

    export default {
        name: 'MyTimePicker',
        props: {
            value: String,
            format: String
        },
        computed: {
            time: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            VueTimepicker
        }
    }
</script>

<style>
    .vue__time-picker input.display-time {
        border: 1px solid #dce3e5;
        height: 44px !important;
        font-size: 14px;
    }

    .vue__time-picker .dropdown {
        margin-top: 0.5rem;
    }

    /* Force white background to avoid flickering in hours list when selecting in minutes list*/
    .vue__time-picker .dropdown ul li {
        background-color: #FFFFFF !important;
    }

    .vue__time-picker .dropdown ul li:not([disabled]):hover {
        background-color: #F4457B !important;
        color: #FFF !important;
    }

    .vue__time-picker .dropdown ul li:not([disabled]).active,
    .vue__time-picker .dropdown ul li:not([disabled]):active,
    .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
        background-color: #FFC701 !important;
        color: #FFF !important;
    }

    .vue__time-picker .controls .char {
        padding-right: 0.1rem;
        font-size: 2rem !important;
        margin-block: auto;
    }
</style>
