import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Shared/Site/SiteIdMixin";
import { mapState, mapGetters, mapActions } from 'vuex';

export const ReminderSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ReminderSettings', [
            'loadingReminderSettings',
            'loadedReminderSettings'
        ]),
        ...mapGetters('ReminderSettings', [
            'findReminderSetting'
        ]),
        reminderSetting() {
            return this.findReminderSetting(this.siteId);
        }
    },
    watch: {
        siteId() {
            this.refreshReminderSettings();
        }
    },
    methods: {
        ...mapActions('ReminderSettings', [
            'fetchReminderSettings'
        ]),
        refreshReminderSettings() {
            if (!this.loadedReminderSettings && !this.loadingReminderSettings) {
                this.tryFetchReminderSettings();
            }
        },
        tryFetchReminderSettings() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchReminderSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshReminderSettings();
    }
}
