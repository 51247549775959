<template>
    <div class="ps-datatable d-none d-md-block">
        <div id="clients-table_wrapper" class="dataTables_wrapper no-footer">
            <div class="top">
            </div>
            <table class="display dataTable no-footer table-fixed" id="clients-table" role="grid">
                <thead>
                    <slot name="header"></slot>
                </thead>
                <tbody>
                    <slot name="rows"></slot>
                </tbody>
            </table>
            <div v-if="pageNumber" class="fixed-action-btns">
                <div class="def-number-input number-input safari_only">
                    <button @click="previous" class="minus"></button>
                    <input disabled :value="pageNumber" type="number">
                    <button @click="next" class="plus"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TableDesktop',
        props: {
            pageNumber: Number
        },
        methods: {
            previous() {
                this.$emit('previous');
            },
            next() {
                this.$emit('next');
            }
        }
    }
</script>
