<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{$t('Add Campaign')}}
                </h1>
            </div>
        </header>
        <div class="alert-wrapper">
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('After saving a new campaign, you will then be able to preview and confirm it.')}}</p>
                </div>
                <button aria-label="Close" data-dismiss="alert" type="button" class="close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <error-banner v-if="error" :message="error"></error-banner>
        </div>
        <div class="content fixed-action-btns-visible">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-md-8">
                            <section class="mb-3" id="AddCampaignSettingsSection">
                                <div aria-controls="campaign-settings" aria-expanded="true" data-target="#campaign-settings" data-toggle="collapse" class="accordion-header">
                                    {{$t('Campaign Settings')}}
                                    <i class="far fa-chevron-down ml-auto"></i>
                                </div>
                                <div id="campaign-settings" class="collapse show">
                                    <div class="card card-body">
                                        <site-select></site-select>
                                        <method-select v-model="methodTypeId"></method-select>
                                        <email-template-select v-if="methodTypeId===1" v-model="templateId">
                                            <validation-message v-if="campaignValidator.templateId" :message="campaignValidator.templateId"></validation-message>
                                        </email-template-select>
                                        <sms-template-select v-if="methodTypeId===2" v-model="templateId">
                                            <validation-message v-if="campaignValidator.templateId" :message="campaignValidator.templateId"></validation-message>
                                        </sms-template-select>
                                        <slotted-input v-model="subject" :title="$t('Subject')">
                                            <validation-message v-if="campaignValidator.subject" :message="campaignValidator.subject"></validation-message>
                                        </slotted-input>
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <label>{{$t('Send Date')}}</label>
                                            </div>
                                            <div class="col-md-8">
                                                <date-picker v-model="sendDate"></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-4">
                            <section class="mb-3" id="AddCampaignRecipientFilterSection">
                                <div aria-controls="campaign-filters" aria-expanded="true" data-target="#campaign-filters" data-toggle="collapse" class="accordion-header">
                                    {{$t('Recipient Filter')}}
                                    <i class="far fa-chevron-down ml-auto"></i>
                                </div>
                                <div id="campaign-filters" class="collapse show">
                                    <div class="card card-body">
                                        <div class="form-group">
                                            <label>{{$t('Sites')}}</label>
                                            <sites-select v-model="siteIds"></sites-select>
                                            <validation-message v-if="campaignValidator.siteIds" :message="campaignValidator.siteIds"></validation-message>
                                        </div>
                                        <div class="form-group">
                                            <label>{{$t('Tags')}}</label>
                                            <tags-select v-model="tagTypeIds" :siteIds="siteIds"></tags-select>
                                        </div>
                                        <div class="form-group">
                                            <label>{{$t('Breeds')}}</label>
                                            <breeds-select v-model="breedIds"></breeds-select>
                                        </div>
                                        <div class="form-group">
                                            <label>{{$t('Not Visited Since')}}</label>
                                            <date-picker v-model="notVisitedSinceDate"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <button id="AddCampaignSaveBtn" @click="save" type="button" class="btn btn-primary" v-bind:disabled="loadingCampaigns">
                    {{$t('Save')}}
                    <span v-if="loadingCampaigns" class="inline-loader"></span>
                </button>
                <button id="AddCampaignBtnCancel" @click="cancel" type="button" class="btn btn-outline-secondary">
                    {{$t('Cancel')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import SiteSelectColumn from "../../../Shared/SiteSelectColumn.vue";
    import { CampaignAddMixin } from "../CampaignAddMixin";
    import CampaignMethodSelect from "./CampaignMethodSelect.vue";
    import CampaignEmailTemplateSelect from "./CampaignEmailTemplateSelect.vue";
    import CampaignSmsTemplateSelect from "./CampaignSmsTemplateSelect.vue";
    import SlottedInput from "../../../Shared/Input/SlottedInput.vue";
    import MyDatePicker from "../../../Shared/Time/MyDatePicker.vue";
    import moment from 'moment';
    import SiteMultiSelectDropdown from "../../../Shared/Site/SiteMultiSelectDropdown.vue";
    import TagTypeMultiSelectDropdown from "../../../Shared/Tags/TagTypeMultiSelectDropdown.vue";
    import BreedMultiSelectDropdown from "../../../Breeds/BreedMultiSelectDropdown.vue";
    import router from '../../../../routing';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'CampaignAdd',
        mixins: [CampaignAddMixin],
        computed: {
            sendDate: {
                get() {
                    return moment(this.sendDateUtc).toDate();
                },
                set(value) {
                    this.sendDateUtc = moment(value).utc().format();
                }
            },
            notVisitedSinceDate: {
                get() {
                    const m = moment(this.notVisitedSinceUtc);
                    return m.isValid() ? m.toDate() : null;
                },
                set(value) {
                    this.notVisitedSinceUtc = moment(value).utc().format();
                }
            }
        },
        methods: {
            save() {
                this.tryAddCampaign();
            },
            cancel() {
                router.push({ name: 'campaigns' });
            },
            afterCampaignAdd() {
                router.go(-1);
            }
        },
        components: {
            'site-select': SiteSelectColumn,
            'method-select': CampaignMethodSelect,
            'email-template-select': CampaignEmailTemplateSelect,
            'sms-template-select': CampaignSmsTemplateSelect,
            'slotted-input': SlottedInput,
            'date-picker': MyDatePicker,
            'sites-select': SiteMultiSelectDropdown,
            'tags-select': TagTypeMultiSelectDropdown,
            'breeds-select': BreedMultiSelectDropdown,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        },
        created() {
            this.siteIds.push(this.siteId);
        }
    }
</script>
