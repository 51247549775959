import { mapActions } from 'vuex';
import { ClientHelper } from '../../helpers/ClientHelper.js';
import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { ClientCacheMixin } from './ClientCacheMixin.js';
import { MobileValidatorMixin } from './MobileValidatorMixin.js';

export const ClientUpdateMixin = {
    mixins: [ClientCacheMixin, MobileValidatorMixin],
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            telephoneHome: '',
            telephoneWork: '',
            telephoneMobile: '',
            clientValidator: ClientHelper.getClientValidator()
        }
    },
    computed: {
        clientUpdateModel() {
            return !!this.client ? Object.assign({}, this.client, {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                telephoneHome: this.telephoneHome,
                telephoneWork: this.telephoneWork,
                telephoneMobile: this.telephoneMobile
            }) : null;
        },
        clientUpdateModelValid() {
            return !!this.clientUpdateModel && this.clientValidator.isValid && !this.mobileValidation;
        },
        clientUpdateModelModified() {
            return JSON.stringify(this.client) !== JSON.stringify(this.clientUpdateModel);
        }
    },
    watch: {
        client() {
            this.refreshClientUpload();
        }
    },
    methods: {
        ...mapActions('Clients', [
            'updateClient',
            'deleteClient'
        ]),
        refreshClientUpload() {
            this.firstName = !!this.client ? this.client.firstName : '';
            this.lastName = !!this.client ? this.client.lastName : '';
            this.email = !!this.client ? this.client.email : '';
            this.telephoneHome = !!this.client ? this.client.telephoneHome : '';
            this.telephoneWork = !!this.client ? this.client.telephoneWork : '';
            this.telephoneMobile = !!this.client ? this.client.telephoneMobile : '';
        },
        tryUpdateClient() {
            const self = this;
            if (!self.client) {
                return;
            }
            self.clientValidator.check(self.clientUpdateModel);
            self.validateMobile(self.telephoneMobile);
            if (!self.clientUpdateModelValid) {
                return;
            }
            if (!self.clientUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateClient({ key: self.clientId, payload: self.clientUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteClient() {
            const self = this;
            if (!self.client) {
                return;
            }
            self.error = '';
            self.deleteClient(self.client.id)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientUpload();
    }
}
