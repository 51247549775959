import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const OrderAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('OrderAggregateSearches', [
            'findOrderAggregatesSearch'
        ]),
        aggregateSearch() {
            return this.findOrderAggregatesSearch(this.aggregateSearchKey);
        },
        orderAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingOrderAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('OrderAggregateSearches', [
            'searchOrderAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchOrderAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
