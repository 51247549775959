<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>
                    <i class="fa-duotone fa-cart-shopping fa-1x"></i>
                    Outstanding Payments
                </h1>
            </div>
        </header>
        <div class="content">
            <page-loader v-if="fetchingPaymentFailures"></page-loader>
            <template v-else>
                <!-- Table shows for mobile, desktop has own view -->
                <payment-mobile v-if="displayTable" :payment-failures="paymentFailures"></payment-mobile>
                <!-- Table shows for desktop, mobile has own view -->
                <table-desktop v-if="displayTable">
                    <template #header>
                        <tr role="row">
                            <th class="col-3">{{$t('Description')}}</th>
                            <th class="col-2 text-center">{{$t('Date')}}</th>
                            <th class="col-3 text-center">{{$t('Status')}}</th>
                            <th class="col-2 text-center">{{$t('Amount')}}</th>
                            <th class="col-2 text-right"></th>
                        </tr>
                    </template>
                    <template #rows>
                        <payment-desktop v-for="(failure, index) in paymentFailures" :key="failure.id" :payment="failure.subscriptionPayment" :number="index"></payment-desktop>
                    </template>
                </table-desktop>
                <template v-else>
                    <div class="alert alert-success alert-icon" role="alert">
                        <div class="alert-content">
                            <p>No outstanding payments</p>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import { SubscriptionPaymentFailuresCacheMixin } from "../../mixins/SubscriptionPaymentFailures/SubscriptionPaymentFailuresCacheMixin";
    import TableDesktopVue from "../Shared/Layout/TableDesktop.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";
    import PaymentFailureItemDesktop from "./PaymentFailureItemDesktop.vue";
    import PaymentFailureMobile from "./PaymentFailureMobile.vue";

    export default {
        name: 'OutstandingPayments',
        mixins: [SubscriptionPaymentFailuresCacheMixin],
        computed: {
            displayTable() {
                return this.paymentFailures.length > 0;
            }
        },
        components: {
            'table-desktop': TableDesktopVue,
            'payment-desktop': PaymentFailureItemDesktop,
            'payment-mobile': PaymentFailureMobile,
            'page-loader': PageLoader
        }
    }
</script>
