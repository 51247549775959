<template>
    <a class="btn btn-sm btn-outline" @click="trySendCommunication">
        <span v-if="loadingCommunications" class="inline-loader"></span>
        <template v-else>
            <i class="fal fa-envelope"></i>
            {{$t('Payment Request')}}
        </template>
    </a>
</template>

<script>
    import { CommunicationSendMixin } from "../../Shared/CommunicationSendMixin";

    export default {
        name: 'AppointmentPaymentRequestButton',
        mixins: [CommunicationSendMixin],
        props: {
            appointment: Object
        },
        computed: {
            subjectId() {
                return !!this.appointment ? this.appointment.scheduleId + this.appointment.sequenceNumber : '';
            },
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            communicationTypeId() {
                return 10;
            },
            methodTypeId() {
                return 1;
            }
        }
    }
</script>
