<template>
    <tr @click="gotoTemplateEdit">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details d-flex align-items-center">
                    <div class="crd-name text-truncate">
                        {{subject}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CampaignSmsTemplateItemMixin } from './CampaignSmsTemplateItemMixin';

    export default {
        name: 'CampaignSmsTemplateItemMobile',
        mixins: [CampaignSmsTemplateItemMixin]
    }
</script>
