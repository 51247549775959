import { Api } from "../../services/Api";

const state = () => ({
    clientLogsCaches: []
})

const getters = {
    findClientLogsCache: (state) => key => state.clientLogsCaches.find(clc => clc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.clientLogsCaches.find(clc => clc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientLogsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setClientLogs(state, { key, logs }) {
        const existing = state.clientLogsCaches.find(clc => clc.key === key);
        if (!!existing) {
            existing.values = logs;
            existing.loading = false;
        } else {
            state.clientLogsCaches.push({ key: key, values: logs, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.clientLogsCaches.find(clc => clc.key === key);
        if (!!existing) {
            existing.retru = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findClientLogs({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: clientId, loading: true });
            Api.get('Client/' + clientId + '/Log')
                .then(response => {
                    commit('setClientLogs', { key: clientId, logs: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', clientId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
