import { Api } from '../../services/Api';

const state = () => ({
    contractTemplateHtmlCaches: []
})

const getters = {
    findContractTemplateHtmlCache: (state) => key => {
        return state.contractTemplateHtmlCaches.find(cthc => cthc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.contractTemplateHtmlCaches.find(cthc => cthc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.contractTemplateHtmlCaches.push({ key: key, value: null, loading: loading });
        }
    },
    setContractTemplateHtml(state, { key, contractTemplateHtml }) {
        const existing = state.contractTemplateHtmlCaches.find(cthc => cthc.key === key);
        if (!!existing) {
            existing.value = contractTemplateHtml;
            existing.loading = false;
        } else {
            state.contractTemplateHtmlCaches.push({ key: key, value: contractTemplateHtml, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.contractTemplateHtmlCaches.find(cthc => cthc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getContractTemplateHtml({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('ContractTemplate/Html?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setContractTemplateHtml', { key: key, contractTemplateHtml: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
