<template>
    <div class="mw-400">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group booking-banner">
            <div class="image-uploads booking-banner-image-uploads">
                <ul>
                    <li>
                        <button @click="tryDeleteImage" class="btn" type="button">
                            <i class="far fa-times"></i>
                        </button>
                        <figure>
                            <img alt="Booking Portal Banner" :src="image.thumbnails.large" width="160">
                        </figure>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { BookingPortalImageMixin } from "../../../mixins/Settings/Site/BookingPortalImageMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { ImageUpdateMixin } from "../../Shared/Images/ImageUpdateMixin";
    import { SettingCacheMixin } from "../../Shared/SettingCacheMixin";

    export default {
        name: 'SiteUpdateBookingPortalImageDelete',
        mixins: [ImageUpdateMixin, SettingCacheMixin, BookingPortalImageMixin, StatusEmitterMixin],
        computed: {
            imageId() {
                return this.settingObject.imageId;
            },
            status() {
                return {
                    loading: this.loadingImages || this.loadingSetting,
                    error: this.error
                }
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
