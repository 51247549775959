<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ClientOnboardingCommunicationMixin } from '../../../mixins/ClientOnboardingInvites/ClientOnboardingCommunicationMixin';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'NewClientOnboardingInviteSend',
        mixins: [ClientOnboardingCommunicationMixin],
        props: {
            savingType: Number,
            inviteAddStatus: {},
            addedClientId: ''
        },
        data() {
            return {
                addedCommunicationId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent',
                'clientOnboardingInviteAddedEvent'
            ]),
            clientId() {
                return this.addedClientId;
            },
            methodTypeId() {
                return this.savingType;
            },
            addedInvite() {
                return !!this.inviteAddStatus ? this.inviteAddStatus.completed : false;
            },
            completed() {
                return !!this.addedCommunicationId || (!!this.clientId & !this.savingType);
            },
            status() {
                return {
                    error: !!this.error,
                    completed: !!this.completed,
                    loading: !!this.loading 
                }
            },
            canUploadCommunication() {
                return !this.completed && this.addedInvite;
            }
        },
        watch: {
            // retry adding of client onboarding invite
            addClientEvent() {
                this.onValidatedChanged();
            },
            canUploadCommunication() {
                this.onValidatedChanged();
            },
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canUploadCommunication) {
                    return;
                }
                this.tryUploadCommunication();
            },
            refreshClientOnboardingInvite() {
                // do nothing
            },
            afterClientOnboardingInviteAdd() {
                // do nothing
            },
            afterSendingCommunication(communication) {
                this.addedCommunicationId = communication.id
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
