import { Api } from "../../services/Api";

const state = () => ({
    orderItemAggregatesSearches: []
})

const getters = {
    findOrderItemAggregatesSearch: (state) => key => state.orderItemAggregatesSearches.find(oias => oias.key === key)
}

const mutations = {
    setLoading(state, key) {
        const existing = state.orderItemAggregatesSearches.find(oias => oias.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.orderItemAggregatesSearches.push({ key: key, values: [], loading: true });
        }
    },
    setOrderItemAggregates(state, { key, aggregates }) {
        const existing = state.orderItemAggregatesSearches.find(oias => oias.key === key);
        if (!!existing) {
            existing.values = aggregates;
            existing.loading = false;
        } else {
            state.orderItemAggregatesSearches.push({ key: key, values: aggregates, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.orderItemAggregatesSearches.find(oias => oias.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchOrderItemAggregates({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('OrderItem/Aggregate/Search', payload)
                .then(response => {
                    commit('setOrderItemAggregates', { key: key, aggregates: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
