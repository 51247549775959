import { TimeFormatOptionsCacheMixin } from "./TimeFormatOptionsCacheMixin";
import { TimeFormatSettingCacheMixin } from "./TimeFormatSettingCacheMixin";

export const TimeDisplayMixin = {
    mixins: [TimeFormatOptionsCacheMixin, TimeFormatSettingCacheMixin],
    computed: {
        timeFormatId() {
            return !!this.timeFormatSetting ? this.timeFormatSetting.timeFormatId : 0;
        },
        timeFormatOption() {
            return this.timeFormatOptions.find(dfo => dfo.id === this.timeFormatId);
        },
        timeMomentJSFormat() {
            return !!this.timeFormatOption ? this.timeFormatOption.momentJS : 'HH:mm';
        },
        timeCSharpFormat() {
            return !!this.timeFormatOption ? this.timeFormatOption.cSharp : 'HH:mm';
        }
    }
}
