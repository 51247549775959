<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="image-uploader">
            <div class="pet-hero-wrap">
                <figure class="pet-hero">
                    <img v-if="heroImage" @click="openImageView(heroImage, 'view')" :alt="heroImage.uploadedName" :src="heroImage.thumbnails.medium" width="100">
                    <template v-else>
                        <default-image @click="openImageAdd"></default-image>
                    </template>
                </figure>
                <div class="custom-file-upload">
                    <button class="custom-upload-btn" data-toggle="modal" data-target="#image-add-id">
                        <i class="fas fa-cloud-upload"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="image-uploads">
            <ul>
                <li v-for="image in otherImages" :ref="image.id">
                    <button class="btn" type="button" @click="openImageView(image, 'delete')">
                        <i class="far fa-times"></i>
                    </button>
                    <figure @click="openImageView(image, 'view')">
                        <img :alt="image.uploadedName" :src="image.thumbnails.small" width="100">
                    </figure>
                </li>
            </ul>
        </div>
        <image-add ref="imageAdd" id="image-add-id" :pet="pet"></image-add>
        <image-view ref="imageView" id="image-view-id" :image="viewImage" :mode="viewMode" :loading="loadingImages" :error="error" @delete="tryDeleteImage"></image-view>
    </div>
</template>

<script>
    import PetImageAdd from './PetImageAdd.vue';
    import PetImageViewer from './PetImageViewer.vue';
    import { ImageUpdateMixin } from '../Shared/Images/ImageUpdateMixin';
    import DefaultPetImageMedium from '../Shared/Images/DefaultPetImageMedium.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'PetImagesSection',
        mixins: [ImageUpdateMixin, StatusEmitterMixin],
        data() {
            return {
                viewImage: null,
                viewMode: 'view'
            }
        },
        props: {
            pet: Object
        },
        computed: {
            imageId() {
                return !!this.viewImage ? this.viewImage.id : '';
            },
            ownerId() {
                return !!this.pet ? this.pet.id : '';
            },
            ownerTypeId() {
                return 3;
            },
            heroImage() {
                return this.images.length > 0 ? this.images[0] : null;
            },
            otherImages() {
                return this.images.length > 1 ? this.images.slice(1) : [];
            },
            status() {
                return {
                    loading: this.loadingImages,
                    error: this.error
                }
            }
        },
        methods: {
            openImageView(image, mode) {
                this.viewImage = image;
                this.viewMode = mode;
                const element = this.$refs.imageView.$el;
                $(element).modal('show');
            },
            openImageAdd() {
                const element = this.$refs.imageAdd.$el;
                $(element).modal('show');
            }
        },
        components: {
            'image-add': PetImageAdd,
            'image-view': PetImageViewer,
            'default-image': DefaultPetImageMedium,
            'error-banner': ErrorBanner
        }
    }
</script>
