import { CacheHelper } from "../../../helpers/CacheHelper";

export const AggregateSearchMixin = {
    data() {
        return {
            error: '',
            startLocal: '',
            endLocal: '',
            frequencyTypeId: 0
        }
    },
    props: {
        siteIds: Array,
        timePeriod: Object
    },
    computed: {
        aggregateSearchModel() {
            return {
                startLocal: !!this.timePeriod ? this.timePeriod.startLocal : '',
                endLocal: !!this.timePeriod ? this.timePeriod.endLocal : '',
                frequencyTypeId: !!this.timePeriod ? this.timePeriod.frequencyTypeId : 0,
                siteIds: this.siteIds
            }
        },
        aggregateSearchModelValid() {
            const model = this.aggregateSearchModel;
            return !!model.startLocal && !!model.endLocal && !!model.frequencyTypeId && model.siteIds.length > 0;
        },
        aggregateSearchKey() {
            return CacheHelper.objectCacheKey(this.aggregateSearchModel);
        },
        aggregateSearch() {
            console.log('!!!OVERRIDE COMPUTED - aggregateSearch!!!');
        }
    },
    watch: {
        aggregateSearchKey() {
            this.refreshAggregateSearch();
        }
    },
    methods: {
        refreshAggregateSearch() {
            if (!this.aggregateSearch || !!this.aggregateSearch.retry) {
                this.trySearchAggregates();
            }
        },
        trySearchAggregates() {
            console.log('!!!OVERRIDE METHOD - trySearchAggregates()!!!');
        }
    },
    created() {
        this.refreshAggregateSearch();
    }
}
