<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Address')}}</label>
        </div>
        <div class="col-md-8">
            <span v-if="loadingAddress" class="inline-loader"></span>
            <template v-else>
                <address-input v-model="addressLines"></address-input>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import { AddressAddMixin } from "../Shared/Address/AddressAddMixin"
    import AddressInputV2 from "../Shared/Address/AddressInputV2.vue"

    export default {
        name: 'ClientAddAddressInput',
        mixins: [AddressAddMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            ownerQueryModel() {
                return {
                    ownerId: this.addedClientId,
                    ownerTypeId: 2
                }
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
                this.tryAddAddress();
            }
        },
        methods: {
            trySearchAddresses() {
                // do nothing
            }
        },
        components: {
            'address-input': AddressInputV2
        }
    }
</script>
