import { Api } from '../../services/Api';

const state = () => ({
    employeeSearches: []
})

const getters = {
    findEmployeeSearch: (state) => key => state.employeeSearches.find(es => es.key === key),
    findEmployeeSearches: (state) => key => state.employeeSearches.filter(es => es.key.includes(key))
}

const mutations = {
    setSearching(state, key) {
        const existing = state.employeeSearches.find(es => es.key === key);
        if (!!existing) {
            existing.searching = true;
            existing.retry = false;
        } else {
            state.employeeSearches.push({ key: key, values: [], searching: true });
        }
    },
    setSearch(state, { key, results }) {
        const existing = state.employeeSearches.find(es => es.key === key);
        if (!!existing) {
            existing.values = results;
            existing.searching = false;
        } else {
            state.employeeSearches.push({ key: key, values: results, searching: false });
        }
    },
    setRetry(state, key) {
        const existing = state.employeeSearches.find(es => es.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.searching = false;
        }
    },
    clearSearches(state) {
        state.employeeSearches = [];
    }
}

const actions = {
    searchEmployees({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setSearching', key);
            Api.post('Employee/User/Search', payload)
                .then(response => {
                    commit('setSearch', { key: key, results: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
