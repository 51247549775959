<template>
    <div>
        <section class="rounded-card-white mb-3 pb-0">
            <client-overview :appointment="appointment"></client-overview>
            <div class="divider"></div>
            <client-details :id="clientId"></client-details>
            <div class="divider"></div>
            <client-notes v-model="loadingNote" :id="clientId"></client-notes>
            <client-preferences v-model="loadingPreferences" :id="clientId"></client-preferences>
        </section>
        <collapsed-section id="client-history" :title="$t('Appointment History')">
            <client-history :id="clientId"></client-history>
        </collapsed-section>
    </div>
</template>

<script>
    import AppointmentOverviewClient from './AppointmentOverviewClient.vue';
    import AppointmentClientInfoDetails from './AppointmentClientInfoDetails.vue';
    import AppointmentClientInfoNotes from './AppointmentClientInfoNotes.vue';
    import AppointmentClientInfoPreferences from './AppointmentClientInfoPreferences.vue';
    import AppointmentClientInfoHistory from './AppointmentClientInfoHistory.vue';
    import CollapsedSection from '../../Shared/Layout/CollapsedSection.vue';

    export default {
        name: 'AppointmentClientInfo',
        data() {
            return {
                loadingPreferences: false,
                loadingNote: false
            }
        },
        props: {
            appointment: Object
        },
        computed: {
            clientId() {
                return this.appointment.clientId
            },
            loading() {
                return this.loadingPreferences || this.loadingNote;
            }
        },
        watch: {
            loading(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'client-overview': AppointmentOverviewClient,
            'client-details': AppointmentClientInfoDetails,
            'client-notes': AppointmentClientInfoNotes,
            'client-preferences': AppointmentClientInfoPreferences,
            'client-history': AppointmentClientInfoHistory,
            'collapsed-section': CollapsedSection
        }
    }
</script>
