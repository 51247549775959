<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header sub" data-target="#site-details" data-toggle="collapse">
            {{$t('Site Details')}}
            <span v-if="false" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="site-details">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="card card-body">
                <slotted-input v-model="companyName" :title="$t('Company Name')">
                    <validation-message v-if="siteValidator.companyName" :message="siteValidator.companyName"></validation-message>
                </slotted-input>
                <slotted-input v-model="name" :title="$t('Site Name')">
                    <validation-message v-if="siteValidator.name" :message="siteValidator.name"></validation-message>
                </slotted-input>
                <slotted-input v-model="email" :title="$t('Email')">
                    <validation-message v-if="siteValidator.email" :message="siteValidator.email"></validation-message>
                </slotted-input>
                <slotted-input v-model="tagLine" :title="$t('Tag Line')"></slotted-input>
                <slotted-input v-model="contactNumber" :title="$t('Contact Number')"></slotted-input>
                <slotted-input v-model="website" :title="$t('Website')"></slotted-input>
            </div>
        </div>
    </section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import SlottedInput from "../../Shared/Input/SlottedInput.vue";
    import { SiteUpdateMixin } from "../../Shared/SiteUpdateMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateDetails',
        mixins: [SiteUpdateMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: this.updatingSite,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUpdateSite();
            }
        },
        components: {
            'slotted-input': SlottedInput,
            'validation-message': ValidationMessage,
            'error-banner': ErrorBanner
        }
    }
</script>
