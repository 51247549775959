import { OpeningHoursCacheMixin } from "./OpeningHoursCacheMixin";
import { ResourceHelper } from "../../../helpers/ResourceHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { mapActions } from 'vuex';
import moment from 'moment';

export const OpeningHoursAddMixin = {
    mixins: [OpeningHoursCacheMixin],
    data() {
        return {
            date: moment(new Date()).format('YYYY-MM-DD') + 'T',
            openingLocal: '08:00',
            closingLocal: '17:00',
            closed: false,
            validator: ResourceHelper.getOpeningHourValidator()
        }
    },
    props: {
        dayId: Number
    },
    computed: {
        openingLocalFull() {
            return this.date + this.openingLocal + ':00';
        },
        closingLocalFull() {
            return this.date + this.closingLocal + ':00';
        },
        openingHourAddModel() {
            return {
                closed: this.closed,
                openingLocal: this.openingLocalFull,
                closingLocal: this.closingLocalFull,
                dayOfWeekId: this.dayId
            }
        },
        openingHourModelValid() {
            return this.validator.isValid;
        }
    },
    methods: {
        ...mapActions('OpeningHours', [
            'addOpeningHours'
        ]),
        tryAddOpeningHours() {
            const self = this;
            self.validator.validate(self.openingHourAddModel);
            if (!self.openingHourModelValid) {
                return;
            }
            self.error = '';
            self.addOpeningHours({ key: self.openingHoursCacheKey, payload: self.openingHourAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
