<template>
    <tr>
        <td>
            <div class="client-row w-100">
                <div class="client-row-details w-100">
                    <div class="crd-name text-truncate">
                        {{subscriptionPayment.description}}
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div>
                            <div class="crd-contact">
                                {{$t('Site')}}: {{siteName}}
                            </div>
                            <div class="crd-contact">
                                {{$t('Amount')}}:
                                <span v-if="subscriptionPayment.currency === 'GBP'">&pound;</span>
                                <span v-else>&dollar;</span>{{amount}}
                            </div>
                            <div class="crd-contact">
                                {{$t('Date')}}: {{date}}
                            </div>
                        </div>
                        <button type="button" class="btn btn-sm btn-outline-secondary h-100" @click="downloadReceipt" v-bind:disabled="loading">
                            <span v-if="loading" class="inline-loader"></span>
                            <i v-else class="fal fa-download"></i>
                        </button>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { PurchaseHistoryItemMixin } from "./PurchaseHistoryItemMixin";

    export default {
        name: 'PurchaseHistoryItemMobile',
        mixins: [PurchaseHistoryItemMixin],
    }
</script>
