import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const LogosCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Logos', [
            'fetchingLogos',
            'fetchedLogos',
            'logos'
        ]),
        logo() {
            return this.logos.find(l => l.siteId == this.siteId);
        }
    },
    methods: {
        ...mapActions('Logos', [
            'findLogos'
        ]),
        refreshLogos() {
            if (!this.fetchedLogos && !this.fetchingLogos) {
                this.tryFindLogos();
            }
        },
        tryFindLogos() {
            const self = this;
            self.error = '';
            self.findLogos()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshLogos();
    }
}
