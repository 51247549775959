<template>
    <li v-if="allowed" :class="{ active: isActive }">
        <a @click="navigate" class="pointer">
            <i :class="iconClass"></i>
            <div class="nav-link-text d-md-flex ml-md-1 align-items-center">
                <span v-html="$t(this.menuName)"></span>
                <span v-if="displayUpgradeBadge" class="upgrade-badge d-none d-md-block">{{$t('Upgrade')}}</span>
            </div>
        </a>
    </li>
</template>

<script>
    import { NavLinkMixin } from './NavLinkMixin';

    export default {
        name: 'NavPanelMainLink',
        mixins: [NavLinkMixin],
        props: {
            menuName: String,
            iconClass: String
        }
    }
</script>
