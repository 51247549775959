import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { OpeningHoursAddMixin } from "./OpeningHoursAddMixin";
import moment from 'moment';

export const OpeningHoursUploadMixin = {
    mixins: [OpeningHoursAddMixin],
    computed: {
        openingHour() {
            return this.openingHours.find(oh => oh.dayOfWeek.id === this.dayId);
        },
        openingHourUpdateModel() {
            return Object.assign({}, this.openingHour, {
                closed: this.closed,
                openingLocal: this.openingLocalFull,
                closingLocal: this.closingLocalFull
            });
        }
    },
    watch: {
        openingHour() {
            this.refreshUploadModel();
        }
    },
    methods: {
        ...mapActions('OpeningHours', [
            'updateOpeningHours'
        ]),
        refreshUploadModel() {
            this.closed = !!this.openingHour ? this.openingHour.closed : false;
            this.openingLocal = !!this.openingHour ? moment(this.openingHour.openingLocal).format('HH:mm') : '08:00';
            this.closingLocal = !!this.openingHour ? moment(this.openingHour.closingLocal).format('HH:mm') : '17:00';
        },
        tryUploadOpeningHours() {
            if (!!this.openingHour) {
                this.tryUpdateOpeningHours();
            } else {
                this.tryAddOpeningHours();
            }
        },
        tryUpdateOpeningHours() {
            const self = this;
            if (JSON.stringify(self.openingHour) === JSON.stringify(self.openingHourUpdateModel)) {
                return;
            }
            self.validator.validate(self.openingHourUpdateModel);
            if (!self.openingHourModelValid) {
                return;
            }
            self.error = '';
            self.updateOpeningHours({ key: this.openingHoursCacheKey, payload: self.openingHourUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUploadModel();
    }
}
