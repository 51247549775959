import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const TimeFormatOptionsCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('TimeFormatOptions', [
            'timeFormatOptions',
            'fetchingTimeFormatOptions',
            'fetchedTimeFormatOptions'
        ])
    },
    watch: {
        siteId() {
            this.refreshTimeFormatOptions();
        }
    },
    methods: {
        ...mapActions('TimeFormatOptions', [
            'getTimeFormatOptions'
        ]),
        refreshTimeFormatOptions() {
            if (!this.fetchedTimeFormatOptions && !this.fetchingTimeFormatOptions) {
                this.tryGetTimeFormatOptions();
            }
        },
        tryGetTimeFormatOptions() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getTimeFormatOptions()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTimeFormatOptions();
    }
}
