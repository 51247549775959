import { mapActions, mapGetters } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ContractTemplateCacheMixin } from "./ContractTemplateCacheMixin";

export const ContractTemplateHtmlCacheMixin = {
    mixins: [ContractTemplateCacheMixin],
    computed: {
        ...mapGetters('ContractTemplateHtmls', [
            'findContractTemplateHtmlCache'
        ]),
        contractTemplateHtmlCache() {
            return this.findContractTemplateHtmlCache(this.contractTemplateCacheKey);
        },
        loadingContractTemplateHtml() {
            return !!this.contractTemplateHtmlCache && this.contractTemplateHtmlCache.loading;
        },
        contractTemplateHtmlObject() {
            return !!this.contractTemplateHtmlCache ? this.contractTemplateHtmlCache.value : null;
        },
        contractTemplateHtmlString() {
            return !!this.contractTemplateHtmlObject ? this.contractTemplateHtmlObject.html : '';
        }
    },
    watch: {
        contractTemplateFilterModel() {
            this.refreshContractTemplateHtml();
        }
    },
    methods: {
        ...mapActions('ContractTemplateHtmls', [
            'getContractTemplateHtml'
        ]),
        refreshContractTemplateHtml() {
            if (!this.contractTemplateHtmlCache || !!this.contractTemplateHtmlCache.retry) {
                this.tryGetContractTemplateHtml();
            }
        },
        tryGetContractTemplateHtml() {
            const self = this;
            if (!self.contractTemplateFilterModelValid) {
                return;
            }
            self.error = '';
            self.getContractTemplateHtml({ key: self.contractTemplateCacheKey, payload: self.contractTemplateFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContractTemplateHtml();
    }
}
