<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header collapsed" data-target="#collapse-mpo-1" data-toggle="collapse">
            More pet options
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-1">
            <div class="card card-body">
                <pet-gender v-model="genderId"></pet-gender>
                <pet-neutered v-model="neuteredId"></pet-neutered>
                <pet-behaviour v-model="behaviourId"></pet-behaviour>
                <pet-dob v-model="dateOfBirth"></pet-dob>
                <pet-microchip v-model="microChip"></pet-microchip>
                <pet-images></pet-images>
                <pet-notes></pet-notes>
            </div>
        </div>
    </section>
</template>

<script>
    import OptionsPetImages from './OptionsPetImages.vue';
    import OptionsPetNotes from './OptionsPetNotes.vue';
    import PetDetailsGender from '../../Pet/PetDetailsGender.vue';
    import PetDetailsNeutered from '../../Pet/PetDetailsNeutered.vue';
    import PetDetailsBehaviour from '../../Pet/PetDetailsBehaviour.vue';
    import PetDetailsDob from '../../Pet/PetDetailsDob.vue';
    import PetDetailsMicrochip from '../../Pet/PetDetailsMicrochip.vue';

    export default {
        name: 'OptionsPet',
        data() {
            return {
                genderId: 1,
                neuteredId: 0,
                behaviourId: 0,
                dateOfBirth: null,
                microChip: ''
            }
        },
        computed: {
            petOptionsModel() {
                return {
                    genderId: this.genderId,
                    neuteredId: this.neuteredId,
                    behaviourId: this.behaviourId,
                    dateOfBirth: this.dateOfBirth,
                    microChip: this.microChip
                };
            }
        },
        watch: {
            petOptionsModel(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'pet-gender': PetDetailsGender,
            'pet-neutered': PetDetailsNeutered,
            'pet-behaviour': PetDetailsBehaviour,
            'pet-dob': PetDetailsDob,
            'pet-microchip': PetDetailsMicrochip,
            'pet-notes': OptionsPetNotes,
            'pet-images': OptionsPetImages,
        }
    }
</script>
