<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Description')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="description" class="form-control" rows="8" :placeholder="$t('Description')"></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductDescriptionInput',
        data() {
            return {
                description: ''
            }
        },
        props: {
            value: String
        },
        watch: {
            description(newValue) {
                this.$emit('input', newValue);
            },
            value() {
                this.refreshDescription();
            }
        },
        methods: {
            refreshDescription() {
                this.description = this.value;
            }
        },
        created() {
            this.refreshDescription();
        }
    }
</script>
