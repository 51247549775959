import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const UserResourceCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('UserResources', [
            'findUserResourceCache'
        ]),
        userId() {
            console.log('!!!OVERRIDE COMPUTED - userId!!!');
        },
        userResourceCache() {
            return this.findUserResourceCache(this.userId);
        },
        userResources() {
            return !!this.userResourceCache ? this.userResourceCache.values : [];
        },
        loadingUserResources() {
            return !!this.userResourceCache && this.userResourceCache.loading;
        }
    },
    watch: {
        userId() {
            this.refreshUserResources();
        }
    },
    methods: {
        ...mapActions('UserResources', [
            'getUserResources'
        ]),
        refreshUserResources() {
            if (!this.userResourceCache || !!this.userResourceCache.retry) {
                this.tryGetUserResources();
            }
        },
        tryGetUserResources() {
            const self = this;
            if (!self.userId) {
                return;
            }
            self.error = '';
            self.getUserResources(self.userId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUserResources();
    }
}
