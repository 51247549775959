import moment from 'moment';

const state = () => ({
    startUtc: '',
    endUtc: '',
    resourceIds: [],
    siteIds: [],
    selectedSiteId: '',
    statusIds: [0, 2, 3]
})

const getters = {
    searchModel: (state) => {
        return Object.assign({}, state);
    }
}

const mutations = {
    setDates(state, { start, end }) {
        state.startUtc = moment(start).utc().format();
        state.endUtc = moment(end).utc().format();
    },
    setResources(state, resources) {
        state.resourceIds = resources.map(r => r.id);
    },
    setSites(state, sites) {
        state.siteIds = sites.map(s => s.id);
    },
    setSelectedSite(state, site) {
        state.selectedSiteId = site.id;
    },
    clearListFilters(state) {
        state.resourceIds = [];
        if (!!state.selectedSiteId) {
            state.siteIds = [state.selectedSiteId];
        } else {
            state.siteIds = [];
        }
    },
    deleteResourceId(state, id) {
        state.resourceIds = state.resourceIds.filter(rid => rid !== id);
    },
    deleteSiteId(state, id) {
        if (id !== state.selectedSiteId) {
            state.siteIds = state.siteIds.filter(sid => sid !== id);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
