<template>
    <div>
        <div v-if="completed && addedPetId" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>Pet note successfully added.</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="number">{{$t('Notes')}}</label>
                </div>
                <div class="col-md-8">
                    <textarea v-model="noteText" class="form-control" rows="3" cols="50" :placeholder="$t('Add notes (optional)')"></textarea>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { NoteAddMixin } from "../../Shared/Notes/NoteAddMixin";
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AddPetNote',
        mixins: [NoteAddMixin],
        props: {
            addedPetId: ''
        },
        data() {
            return {
                addedPetNote: null
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent'
            ]),
            ownerId() {
                return this.addedPetId;
            },
            ownerTypeId() {
                return 3;
            },
            noteTypeId() {
                return 1;
            },
            completed() {
                return !!this.addedPetNote || !this.noteText;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    validated: true,
                    loading: this.loadingNote
                }
            },
            canAddNote() {
                return !!this.addedPetId && !this.completed;
            }
        },
        watch: {
            // retry saving of pet note
            addClientEvent() {
                this.onPetIdChanged();
            },
            // initial save
            addedPetId() {
                this.onPetIdChanged();
            },
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            async onPetIdChanged() {
                if (!this.canAddNote) {
                    return;
                }
                try {
                    const response = await this.tryAddNote();
                    this.addedPetNote = response.data;
                } catch {
                    this.addedPetNote = null;
                }
            },
            refreshNote() {
                // overriding clear uploads to keep imageFiles during changed of ownerId from empty to a true addedPetId value
            },
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>




