import { Api } from "../../services/Api";

const state = () => ({
    addingEmailTemplateSettings: false,
    updatingEmailTemplateSettings: false,
    loadingEmailTemplateSettings: false,
    loadedEmailTemplateSettings: false,
    emailTemplateSettings: [],
    emailTemplateSettingsClearedEvent: 0
})

const mutations = {
    setEmailTemplateSettings(state, setEmailTemplateSettings) {
        state.emailTemplateSettings = setEmailTemplateSettings;
        state.loadedEmailTemplateSettings = true;
        state.loadingEmailTemplateSettings = false;
    },
    setLoading(state, value) {
        state.loadingEmailTemplateSettings = !!value;
        state.loadedEmailTemplateSettings = false;
    },
    setAdding(state, value) {
        state.addingEmailTemplateSettings = !!value;
    },
    setUpdating(state, value) {
        state.updatingEmailTemplateSettings = !!value;
    },
    clearEmailTemplateSettings(state) {
        state.emailTemplateSettings = [];
        state.loadedEmailTemplateSettings = false;
        state.emailTemplateSettingsClearedEvent++;
    },
    appendEmailTemplateSetting(state, emailTemplateSetting) {
        state.emailTemplateSettings.push(emailTemplateSetting);
    },
    insertEmailTemplateSetting(state, emailTemplateSetting) {
        const existing = state.emailTemplateSettings.find(s => s.id === emailTemplateSetting.id);
        if (!!existing) {
            const index = state.emailTemplateSettings.indexOf(existing);
            state.emailTemplateSettings.splice(index, 1, emailTemplateSetting);
        } else {
            state.emailTemplateSettings.push(emailTemplateSetting);
        }
    }
}

const actions = {
    getEmailTemplateSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('EmailTemplateSetting')
                .then(response => {
                    commit('setEmailTemplateSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
        });
    },
    addEmailTemplateSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('EmailTemplateSetting', payload)
                .then(response => {
                    commit('appendEmailTemplateSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateEmailTemplateSettingCredits({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('CreditPack/EmailTemplateSetting')
                .then(response => {
                    commit('insertEmailTemplateSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    },
    upgradeEmailTemplateSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Subscription/EmailTemplateSetting')
                .then(response => {
                    commit('setEmailTemplateSettings', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}