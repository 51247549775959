<template>
    <div v-if="!!details" class="text-wrap text-center">
        <template v-if="oneOff">
            <div v-if="dayOff" v-bind:style="{ 'background-color': colour }" style="color: white">{{$t('Day Off')}}</div>
            <div v-else v-bind:style="{ 'background-color': colour }" style="color: white">{{startTime}} - {{endTime}}</div>
        </template>
        <div v-else>
            <div v-if="dayOff" v-bind:style="{ 'background-color': colour }" style="color: white">{{$t('Day Off')}}</div>
            <div v-else v-bind:style="{ 'background-color': colour }" style="color: white">{{startTime}} - {{endTime}}</div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment-timezone'

    export default {
        name: 'UserResourceCalendarItem',
        props: {
            event: Object
        },
        computed: {
            details() {
                return this.event.extendedProps.details;
            },
            oneOff() {
                return !!this.details ? this.details.oneOff : false;
            },
            dayOff() {
                return !!this.details ? this.details.dayOff : false;
            },
            start() {
                return !!this.details ? this.details.start : '';
            },
            end() {
                return !!this.details ? this.details.end : '';
            },
            colour() {
                return !!this.details ? this.details.backgroundColor : '';
            },
            startTime() {
                return moment(this.start).format('HH:mm'); 
            },
            endTime() {
                return moment(this.end).format('HH:mm');
            }
        }
    }
</script>
