import { mapGetters } from 'vuex';
import { AccountSettingsCacheMixinV2 } from './AccountSettingsCacheMixinV2';

export const AccountSettingCacheMixin = {
    mixins: [AccountSettingsCacheMixinV2],
    computed: {
        ...mapGetters('AccountSettings', [
            'findAccountSetting'
        ]),
        accountSettingKey() {
            console.log('OVERRIDE COMPUTED - accountSettingKey');
            return '';
        },
        accountSetting() {
            return this.findAccountSetting(this.accountSettingKey);
        },
        accountSettingValue() {
            return !!this.accountSetting ? this.accountSetting.value : '';
        },
        accountSettingObject() {
            return !!this.accountSettingValue ? JSON.parse(this.accountSettingValue) : null;
        }
    },
    watch: {
        accountSettingKey() {
            this.refreshAccountSetting();
        }
    }
}
