import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    fetchingPaymentStatuses: false,
    fetchedPaymentStatuses: false,
    paymentStatuses: []
});

const mutations = {
    setFetching(state, fetching) {
        state.fetchingPaymentStatuses = !!fetching;
        state.fetchedPaymentStatuses = false;
    },
    setStatuses(state, statuses) {
        state.paymentStatuses = statuses;
        state.fetchedPaymentStatuses = true;
        state.fetchingPaymentStatuses = false;
    }
}

const actions = {
    fetchPaymentStatuses({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            PaymentApi.get('Checkout/StatusType')
                .then(response => {
                    commit('setStatuses', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
