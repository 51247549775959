import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const CommunicationTypesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('CommunicationTypes', [
            'fetchingCommunicationTypes',
            'fetchedCommunicationTypes',
            'communicationTypes'
        ])
    },
    methods: {
        ...mapActions('CommunicationTypes', [
            'fetchCommunicationTypes'
        ]),
        refreshCommunicationTypes() {
            if (!this.fetchedCommunicationTypes && !this.fetchingCommunicationTypes) {
                this.fetchAndCacheCommunicationTypes();
            }
        },
        fetchAndCacheCommunicationTypes() {
            const self = this;
            self.error = '';
            self.fetchCommunicationTypes()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCommunicationTypes();
    }
}
