import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { BookingPortalImageMixin } from "./BookingPortalImageMixin";

export const BookingPortalImageUploadMixin = {
    mixins: [SettingUploadMixin, BookingPortalImageMixin],
    data() {
        return {
            bookingPortalImageId: ''
        }
    },
    computed: {
        settingEditModel() {
            return {
                imageId: this.bookingPortalImageId
            }
        },
        settingEditModelValid() {
            return !!this.settingEditModel.imageId;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.bookingPortalImageId = this.settingObject.imageId;
        }
    }
}
