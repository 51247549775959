import { ClientCacheMixin } from '../Shared/ClientCacheMixin.js';

export const ClientCommButtonMixin = {
    mixins: [ClientCacheMixin],
    computed: {
        smsHref() {
            return this.client && this.client.telephoneMobile ? 'sms:' + this.client.telephoneMobile : '';
        },
        phoneHref() {
            return this.client && this.client.telephoneMobile ? 'tel:' + this.client.telephoneMobile : '';
        },
        emailHref() {
            return this.client && this.client.email ? 'mailto:' + this.client.email : '';
        }
    }
}