<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Species')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionName}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="specie in species"
                            class="dropdown-item pointer"
                            :key="specie.id"
                            @click="setSpeciesId(specie.id)"
                            v-bind:class="{'active': specie.id === speciesId}">
                            {{$t(specie.name)}}
                        </li>
                    </ul>
                </div>
            </div>
            <validation-message v-if="validator.speciesId" :message="validator.speciesId"></validation-message>
        </div>
    </div>
</template>

<script>
    import { SpeciesCacheMixin } from "../Shared/SpeciesCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'BreedSpeciesSelect',
        mixins: [SpeciesCacheMixin],
        props: {
            value: String,
            validator: Object
        },
        data() {
            return {
                speciesId: String
            }
        },
        computed: {
            selectedOption() {
                return this.species.find(s => s.id === this.speciesId);
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.$t(this.selectedOption.name) : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            setSpeciesId(value) {
                this.speciesId = value
                this.$emit('input', value);
            }
        },
        created() {
            this.setSpeciesId(this.value);
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
