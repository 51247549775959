<template>
    <div>
        <div v-for="(order, index) in additionalAppointmentOrders" :key="index">
            <div class="row px-1">
                <div class="title-divider d-flex w-100">
                    <i @click="showPetForm = !showPetForm"
                       class="p-2 fal fa-chevron-circle-up pet-form-toggle-icon"
                       v-bind:class="{'pet-form-toggle-icon-rotate':showPetForm}">
                    </i>
                    <h2 class="p-2 mx-auto">{{ order.pet ? order.pet.name : 'Pet ' + (index + 2)}}</h2>
                    <i @click="removeAppointmentOrder(order)"
                       class="p-2 fal fa-trash-alt">
                    </i>
                </div>
            </div>
            <div class="pet-form" v-bind:class="{'pet-form-show': showPetForm}">
                <additional-pet-select :index="index + 1" :pets="pets"></additional-pet-select>
                <order-item-select :index="index + 1"></order-item-select>
                <resource-select :index="index + 1"></resource-select>
            </div>
        </div>
        <a v-if="showAdd" @click="addAdditionalOrder" class="w-100 d-flex justify-content-center mb-3 mt-4" style="cursor: pointer !important">Add additional pet to booking?</a>
    </div>
</template>

<script>
    import AppointmentAdditionalPetSelect from './AppointmentAdditionalPetSelect.vue';
    import AppointmentOrderItemSelect from './AppointmentOrderItemSelect.vue';
    import AppointmentResourceSelect from './AppointmentResourceSelect.vue';
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { PetCacheMixinV2 } from '../../Shared/PetCacheMixinV2';

    export default {
        name: 'AppointmentAdditionalPets',
        mixins: [PetCacheMixinV2],
        data() {
            return {
                showPetForm: true
            }
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'clientId',
                'appointmentOrders'
            ]),
            ...mapGetters('AppointmentScheduleUpload', [
                'additionalAppointmentOrders'
            ]),
            showAdd() {
                return this.appointmentOrders.length < this.pets.length;
            },
            petSearchModel() {
                return {
                    clientIds: [this.clientId]
                }
            },
            petSearchModelValid() {
                const model = this.petSearchModel;
                return model.clientIds.length > 0 && !!model.clientIds[0];
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'addAdditionalOrder',
                'removeAppointmentOrder'
            ])
        },
        components: {
            'additional-pet-select': AppointmentAdditionalPetSelect,
            'order-item-select': AppointmentOrderItemSelect,
            'resource-select': AppointmentResourceSelect
        }
    }
</script>

<style scoped>
    .pet-form {
        max-height: 0;
        display: none;
        transition: all 0.3s ease;
    }

    .pet-form-show {
        max-height: 100%;
        display: block;
        transition: all 0.3s ease;
    }

    .pet-form-toggle-icon {
        transform: rotate(0);
        transition: transform 0.3s ease-in-out;
        border-radius: 5rem;
    }

    .pet-form-toggle-icon-rotate {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
    }
</style>
