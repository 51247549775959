<template>
    <div class="content fixed-action-btns-visible">
        <div class="row">
            <div class="col-12 col-md-auto">
                <div class="client-details-left sidenav">
                    <client-history :id="clientId"></client-history>
                    <tags :owner-id="clientId" :owner-type-id="2"></tags>
                </div>
            </div>
            <client-edit v-model="editStatus" :client="client"></client-edit>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <save-button @clicked="triggerUpdateClient" :status="editStatus"></save-button>
                <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
            </div>
            <delete-button @clicked="remove"></delete-button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import router from '../../routing';
    import ClientHistorySection from './ClientHistorySection.vue';
    import TagSection from '../Shared/Tags/TagSection.vue';
    import ClientEdit from './ClientEdit.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import SaveButton from '../Shared/Buttons/SaveButton.vue';

    export default {
        name: 'ClientOverview',
        data() {
            return {
                editStatus: {}
            }
        },
        props: {
            client: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientDeletedEvent'
            ]),
            clientId() {
                return !!this.client ? this.client.id : '';
            }
        },
        watch: {
            clientDeletedEvent() {
                router.go(-1);
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerUpdateClient',
                'triggerDeleteClient'
            ]),
            cancel() {
                this.$router.push({ name: 'clients' });
            },
            remove() {
                this.triggerDeleteClient();
            }
        },
        components: {
            'client-history': ClientHistorySection,
            'tags': TagSection,
            'client-edit': ClientEdit,
            'delete-button': DeleteButton,
            'save-button': SaveButton
        }
    }
</script>
