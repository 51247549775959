<template>
    <section class="mb-1">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-time-zone" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Time Zone')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-time-zone">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <label>{{$t('Time Zone')}}</label>
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                            {{selectedOptionDescription}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="option in timeZoneOptions"
                                    class="dropdown-item pointer"
                                    :key="option.id"
                                    @click="setTimeZoneId(option.id)"
                                    v-bind:class="{'active': option.id === timeZoneId}">
                                    {{option.description}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <validation-message v-if="timeZoneIdError" :message="timeZoneIdError"></validation-message>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { AccountSettingSiteDefaultMixin } from "../../mixins/Settings/Account/AccountSettingSiteDefaultMixin";

    export default {
        name: 'DefaultSiteTimeZone',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                timeZoneId: '',
                timeZoneIdError: ''
            }
        },
        computed: {
            ...mapState('TimeZones', [
                'timeZoneOptions'
            ]),
            accountSettingKey() {
                return 'DefaultTimeZone';
            },
            accountSettingEditModel() {
                return {
                    timeZoneId: this.timeZoneId
                }
            },
            accountSettingEditModelValid() {
                const model = this.accountSettingEditModel;
                this.timeZoneIdError = !!model.timeZoneId ? '' : 'Please select a time zone.';
                return !this.timeZoneIdError;
            },
            selectedOption() {
                return this.timeZoneOptions.find(tzo => tzo.id == this.timeZoneId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.selectedOption.description : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.timeZoneId = !!this.accountSettingObject ? this.accountSettingObject.timeZoneId : '';
            },
            setTimeZoneId(value) {
                this.timeZoneId = value;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
