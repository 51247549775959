<template>
    <collapsed-section id="site-personalization" :title="$t('Personalization')">
        <pet-image v-model="petImageStatus"></pet-image>
        <site-images v-model="siteImagesStatus"></site-images>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdatePetImage from "./SiteUpdatePetImage.vue";
    import SiteUpdateSiteImages from "./SiteUpdateSiteImages.vue";

    export default {
        name: 'SiteUpdatePersonalization',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                petImageStatus: {},
                siteImagesStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.petImageStatus,
                    this.siteImagesStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'pet-image': SiteUpdatePetImage,
            'site-images': SiteUpdateSiteImages
        }
    }
</script>
