import { mapState } from 'vuex';
import { UserSettingsHelper } from '../../../helpers/UserSettingsHelper';
import { UserSettingUploadMixin } from "../../Shared/UserSettingUploadMixin";

export const CalendarViewSettingsMixin = {
    mixins: [UserSettingUploadMixin],
    data() {
        return {
            scheduleView: '',
            resourceOrder: UserSettingsHelper.getDefaultCalendarResourceOrder(),
            options: [
                { id: 1, value: UserSettingsHelper.getDefaultCalendarResourceOrder(), name: 'Name' },
                { id: 2, value: 'priorityOrder,title', name: 'Priority Order' }
            ]
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'saveScheduleSettingsEvent'
        ]),
        userSettingKey() {
            return 'Calendar';
        },
        defaultUserSettingValue() {
            return UserSettingsHelper.getDefaultCalendarSettings();
        },
        userSettingUploadEdit() {
            return {
                scheduleView: this.scheduleView,
                resourceOrder: this.resourceOrder
            }
        },
        selectedOption() {
            return this.options.find(o => o.value === this.resourceOrder);
        },
        selectedOptionName() {
            return this.selectedOption ? `${this.$t(this.selectedOption.name)}` : `${this.$t('Please select')}...`;
        },
        selectedOptionId() {
            return this.selectedOption ? this.selectedOption.id : 0;
        }
    },
    watch: {
        saveScheduleSettingsEvent() {
            this.uploadUserSetting();
        },
        loadingUserSettings(value) {
            this.$emit('input', value);
        }
    },
    methods: {
        getUserSettingValid() {
            return !!this.scheduleView;
        },
        refreshEditModel(userSettingValue) {
            this.scheduleView = userSettingValue.scheduleView;
            this.resourceOrder = userSettingValue.resourceOrder;
        },
        setResourceOrder(value) {
            this.resourceOrder = value;
        }
    }
}
