<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Start day')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{$t(daysOfWeek[startDay])}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="id in dayIds"
                            class="dropdown-item pointer"
                            :key="id"
                            @click="setStartDay(id)"
                            v-bind:class="{'active': id === startDay}">
                            {{$t(daysOfWeek[id])}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DaysOfWeekMixin } from "../../Shared/DaysOfWeekMixin";
    import { DaysOrderMixin } from "../../Shared/DaysOrderMixin";

    export default {
        name: 'ScheduleStartDay',
        mixins: [DaysOrderMixin, DaysOfWeekMixin],
        props: {
            value: Number
        },
        data() {
            return {
                startDay: 0
            }
        },
        methods: {
            setStartDay(value) {
                const day = parseInt(value);
                this.startDay = day;
                this.$emit('input', isNaN(day) ? 0 : day);
            }
        },
        created() {
            this.setStartDay(this.value);
        }
    }
</script>
