import { render, staticRenderFns } from "./PetNotesCutSection.vue?vue&type=template&id=3f5ab1fa"
import script from "./PetNotesCutSection.vue?vue&type=script&lang=js"
export * from "./PetNotesCutSection.vue?vue&type=script&lang=js"
import style0 from "./PetNotesCutSection.vue?vue&type=style&index=0&id=3f5ab1fa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports