<template>
    <collapsed-section id="site-appointment" :title="$t('Appointments')">
        <duration-setting v-model="durationStatus"></duration-setting>
        <custom-durations v-model="customDurationStatus"></custom-durations>
        <client-setting v-model="clientStatus"></client-setting>
        <reminder-setting v-model="reminderStatus"></reminder-setting>
        <deposit-setting v-model="depositStatus"></deposit-setting>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateAppointmentClient from "./SiteUpdateAppointmentClient.vue";
    import SiteUpdateAppointmentDeposit from "./SiteUpdateAppointmentDeposit.vue";
    import SiteUpdateAppointmentDuration from "./SiteUpdateAppointmentDuration.vue";
    import SiteUpdateAppointmentReminder from "./SiteUpdateAppointmentReminder.vue";
    import SiteUpdateCustomDuration from "./SiteUpdateCustomDuration.vue"

    export default {
        name: 'SiteUpdateAppointment',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                durationStatus: {},
                clientStatus: {},
                reminderStatus: {},
                depositStatus: {},
                customDurationStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.durationStatus,
                    this.clientStatus,
                    this.reminderStatus,
                    this.depositStatus,
                    this.customDurationStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'duration-setting': SiteUpdateAppointmentDuration,
            'client-setting': SiteUpdateAppointmentClient,
            'reminder-setting': SiteUpdateAppointmentReminder,
            'deposit-setting': SiteUpdateAppointmentDeposit,
            'custom-durations': SiteUpdateCustomDuration
        }
    }
</script>
