import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { PurchasedCampaignTemplatesCacheMixin } from '../../Shared/PurchasedCampaignTemplatesCacheMixin';

export const StripoDefaultTemplateSettingsDisplayItemMixin = {
    mixins: [PurchasedCampaignTemplatesCacheMixin],
    data() {
        return {
            purchasingCurrentTemplate: false
        }
    },
    props: {
        setting: {
            type: Object,
            required: true
        }
    },
    computed: {
        purchasedCampaignTemplateAddModel() {
            return {
                stripoDefaultTemplateId: this.setting.id
            }
        },
        purchasedCampaignTemplateAddModelValid() {
            return !!this.purchasedCampaignTemplateAddModel && this.purchasedCampaignTemplateAddModel.stripoDefaultTemplateId;
        },
        purchasedCampaignTemplate() {
            return this.purchasedCampaignTemplates.find(t => t.stripoDefaultTemplateId == this.setting.id);
        },
    },
    methods: {
        ...mapActions('PurchasedCampaignTemplates', [
            'addPurchasedCampaignTemplate'
        ]),

        ...mapActions('EmailCampaignTemplates', [
            'addEmailCampaignTemplatesFromPurchased'
        ]),

        tryPurchasedCampaignTemplate() {
            if (!!this.purchasedCampaignTemplate) {
                return;
            } else {
                this.tryAddPurchasedCampaignTemplate();
            }
        },

        tryAddPurchasedCampaignTemplate() {
            const self = this;

            if (!self.purchasedCampaignTemplateAddModelValid) {
                return;
            }

            self.purchasingCurrentTemplate = true;

            self.error = '';
            self.addPurchasedCampaignTemplate({ key: self.siteId, payload: self.purchasedCampaignTemplateAddModel })
                .then(() => {
                    self.afterAddPurchasedCampaignTemplate();
                })
                .catch(error => {
                    self.error = ErrorHelper.getError(error)
                    self.purchasingCurrentTemplate = false;
                });
        },

        afterAddPurchasedCampaignTemplate() {
            const self = this;

            self.error = '';
            self.addEmailCampaignTemplatesFromPurchased(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.purchasingCurrentTemplate = false);
        }
    }
}
