<template>
    <section class="mb-1">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-details" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-details">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <label>{{$t('Company Name')}}</label>
                    <input v-model="companyName" type="text" class="form-control">
                    <validation-message v-if="companyNameError" :message="companyNameError"></validation-message>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { AccountSettingSiteDefaultMixin } from '../../mixins/Settings/Account/AccountSettingSiteDefaultMixin';

    export default {
        name: 'DefaultSiteDetails',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                companyName: '',
                companyNameError: ''
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultDetails';
            },
            accountSettingEditModel() {
                return {
                    companyName: this.companyName
                }
            },
            accountSettingEditModelValid() {
                const model = this.accountSettingEditModel;
                this.companyNameError = !!model.companyName ? '' : 'Please enter company name';
                return !this.companyNameError;
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.companyName = !!this.accountSettingObject ? this.accountSettingObject.companyName : '';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
