<template>
    <div class="d-flex flex-column align-items-center">
        <div class="pt-3 w-100">
            <error-banner v-if="error" :message="error"></error-banner>
        </div>
        <div class="checkout-card">
            <div class="checkout-card_header">
                {{$t('Checkout')}}
            </div>
            <template v-if="subscriptionUpgrade">
                <div class="checkout-card_body">
                    <div class="d-flex w-100 py-3 align-items-center justify-content-center">
                        <div class="mr-2" style="color: #4BCCD1">
                            <i class="fa-duotone fa-3x fa-badge-check"></i>
                        </div>
                        <div class="d-flex flex-column w-100">
                            <div class="checkout-card_description">{{ description }} Subscription</div>
                            <div class="d-flex justify-content-between" v-if="subscriptionTypeId === 5">
                                <div class="checkout-card_quantity-label">{{$t('Additional Sites:')}}</div>
                                <div class="checkout-card_quantity-amount">{{ additionalSites }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="checkout-card_divider py-2 text-left">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div>{{$t('Subscription Features:')}}</div>
                            <div class="checkout-card_list-toggle" @click="showFeatureList = !showFeatureList">[{{showFeatureList ? $t('Hide List') : $t('Show List')}}]</div>
                        </div>
                        <feature-list :feature="feature" v-if="showFeatureList"></feature-list>
                    </div>
                    <div class="checkout-card_divider py-3" v-if="subscriptionTypeId !== 2">
                        <template v-if="displayDiscountInput">
                            <div class="d-flex">
                                <input v-model="discountCode" @blur="setDiscountCodes" class="checkout-card_discount-input" :placeholder="$t('ENTER DISCOUNT CODE')">
                                <button @click="tryUpdateSubscriptionUpgrade" class="checkout-card_discount-button" v-bind:disabled="!discountCode">{{$t('APPLY')}}</button>
                            </div>
                        </template>
                    </div>
                    <template v-if="displayBreakdown">
                        <div class="d-flex w-100 justify-content-between">
                            <div>{{$t('Gross Price:')}}</div>
                            <div>{{ grossPriceString }}</div>
                        </div>
                        <template v-if="deductedCredit">
                            <div class="d-flex w-100 justify-content-between">
                                <div>{{$t('Credits')}} ({{ remainingCreditString }} {{$t('remaining')}}):</div>
                                <div class="checkout-card_discount-item">-{{ deductedCreditString }}</div>
                            </div>
                        </template>
                        <template v-if="discounts.length > 0">
                            <div class="d-flex flex-column w-100">
                                <div>{{$t('Discounts:')}}</div>
                                <discount-item v-for="(discount, index) in discounts" :key="index" :discount="discount" @deleted="deleteDiscount"></discount-item>
                            </div>
                        </template>
                        <template v-if="rewards.length > 0">
                            <div class="d-flex flex-column w-100">
                                <div>{{$t('Rewards:')}}</div>
                                <reward-item v-for="(reward, index) in rewards" :key="index" :reward="reward"></reward-item>
                            </div>
                        </template>
                    </template>
                    <div class="checkout-card_divider">
                        <div class="checkout-card_total-price d-flex w-100 justify-content-between mt-2">
                            <div>{{$t('Total Price:')}}</div>
                            <div>{{ netPriceString }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="checkout-card_footer">
                <div v-if="!completedPayment" class="d-flex flex-column justify-content-center align-items-center my-4">
                    <div>
                        <label class="custom-control custom-checkbox d-flex align-items-center justify-content-center">
                            <input v-model="acceptedTCs" type="checkbox" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{$t('I agree with the')}} <a @click="gotoTerms" class="pointer">{{$t('Terms & Conditions')}}.</a></span>
                        </label>
                    </div>
                    <div>
                        <label v-if="acceptedTCsError" class="error">{{acceptedTCsError}}</label>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                    <button v-if="subscriptionUpgrade && !completedPayment" class="checkout-card_body-button mr-2" @click="tryDeleteSubscriptionUpgrade" v-bind:disabled="loading">
                        {{$t('Cancel')}}
                    </button>
                    <button @click="managePurchase" class="checkout-card_body-button checkout-card_body-button-success" v-bind:disabled="loading">
                        {{ buttonText }}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SubscriptionUpgradeUpdateMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeUpdateMixin";
    import SubscriptionFeatureList from "./SubscriptionFeatureList.vue";
    import SubscriptionDiscountItem from "./SubscriptionDiscountItem.vue";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import { SubscriptionUpgradePaymentAddMixin } from '../../mixins/Subscriptions/SubscriptionUpgradePaymentAddMixin';
    import { SubscriptionUpgradeDeleteMixin } from '../../mixins/Subscriptions/SubscriptionUpgradeDeleteMixin';
    import SubscriptionRewardItem from "./SubscriptionRewardItem.vue";

    export default {
        name: 'SubscriptionUpgradeModifier',
        mixins: [SubscriptionUpgradeUpdateMixin, SubscriptionUpgradePaymentAddMixin, SubscriptionUpgradeDeleteMixin],
        data() {
            return {
                error: '',
                discountCode: '',
                showFeatureList: false
            }
        },
        computed: {
            loading() {
                return this.loadingSubscriptionEntityPayments || this.addingSubscriptionUpgrade;
            },
            subscriptionEntityId() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.id : '';
            },
            subscriptionTypeId() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.subscriptionType.id : '';
            },
            feature() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.feature : null;
            },
            description() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.subscriptionType.description : '';
            },
            currencySymbol() {
                return this.currency === 'GBP' ? '£' : '$';
            },
            grossPrice() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.grossPrice : 0;
            },
            grossPriceString() {
                return this.currencySymbol + this.grossPrice.toFixed(2);
            },
            netPrice() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.netPrice : 0;
            },
            netPriceString() {
                return this.currencySymbol + this.netPrice.toFixed(2);
            },
            displayBreakdown() {
                return this.netPrice < this.grossPrice;
            },
            deductedCredit() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.creditDeduction : 0;
            },
            deductedCreditString() {
                return this.deductedCredit.toFixed(2);
            },
            remainingCredit() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.remainingCredit : 0;
            },
            remainingCreditString() {
                return this.remainingCredit.toFixed(2);
            },
            discounts() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.discounts : [];
            },
            rewards() {
                return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.rewards : [];
            },
            displayDiscountInput() {
                return this.subscriptionTypeId !== 2 && !this.completedPayment;
            },
            buttonText() {
                return !!this.completedPayment ? this.$t('Complete') : this.$t('Proceed');
            }
        },
        watch: {
            subscriptionUpgrade() {
                this.discountCode = '';
            }
        },
        methods: {
            setDiscountCodes() {
                this.discountCodes = [this.discountCode].filter(s => !!s);
            },
            deleteDiscount() {
                this.discountCodes = [];
                this.tryUpdateSubscriptionUpgrade();
            }
        },
        components: {
            'feature-list': SubscriptionFeatureList,
            'discount-item': SubscriptionDiscountItem,
            'reward-item': SubscriptionRewardItem,
            'error-banner': ErrorBanner
        }
    }
</script>
