<template>

</template>

<script>
    import { CountryOptionCacheMixin } from "../../../mixins/CountryOptions/CountryOptionCacheMixin";
    import { AccountSettingPostRegisterMixin } from "../../../mixins/Settings/Account/AccountSettingPostRegisterMixin";

    export default {
        name: 'PostRegisterAccountSettingDefaultCountry',
        mixins: [AccountSettingPostRegisterMixin, CountryOptionCacheMixin],
        computed: {
            countryId() {
                return !!this.registrationInfo ? this.registrationInfo.countryId : '';
            },
            accountSettingKey() {
                return 'DefaultCountry';
            },
            accountSettingEditModel() {
                return {
                    id: this.countryId,
                    name: this.countryOption ? this.countryOption.name : '',
                    currency: this.countryOption ? this.countryOption.currency : '',
                    currencySymbol: this.countryOption ? this.countryOption.currencySymbol : ''
                };
            },
            canUploadSetting() {
                return !this.completed && !!this.countryOption;
            },
            completed() {
                return !this.countryId || !!this.accountSetting;
            },
            loading() {
                return this.loadingAccountSetting || this.loadingCountryOption;
            }
        }
    }
</script>
