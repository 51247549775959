<template>
    <div class="d-flex flex-column align-items-center">
        <div class="pt-3 w-100">
            <error-banner v-if="error" :message="error"></error-banner>
        </div>
        <div class="checkout-card">
            <div class="checkout-card_header">
                {{$t('Checkout')}}
            </div>
            <template v-if="creditPack">
                <div class="checkout-card_body">
                    <div class="d-flex w-100 py-3 align-items-center justify-content-center">
                        <div class="mr-2" style="color: #4BCCD1">
                            <i v-if="creditTypeId === 1" class="fa-duotone fa-3x fa-message-sms"></i>
                            <i v-if="creditTypeId === 2" class="fa-duotone fa-3x fa-bullhorn"></i>
                            <i v-if="creditTypeId === 3" class="fa-duotone fa-3x fa-envelopes-bulk"></i>
                        </div>
                        <div class="d-flex flex-column w-100">
                            <div class="checkout-card_description">{{$t(creditTypeDescription)}} {{$t('Credits')}}</div>
                            <div class="d-flex justify-content-between">
                                <div class="checkout-card_quantity-label">{{$t('Quantity:')}}</div>
                                <div class="checkout-card_quantity-amount">{{ amount }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="checkout-card_divider py-3">
                        <template v-if="!completedPayment">
                            <div class="d-flex">
                                <input v-model="discountCode" class="checkout-card_discount-input" :placeholder="$t('ENTER DISCOUNT CODE')">
                                <button @click="tryUpdateCreditPack" class="checkout-card_discount-button" v-bind:disabled="!discountCode || loading">{{$t('APPLY')}}</button>
                            </div>
                        </template>
                    </div>
                    <template v-if="displayBreakdown">
                        <div class="d-flex w-100 justify-content-between">
                            <div>{{$t('Gross Price:')}}</div>
                            <div>{{ grossPriceString }}</div>
                        </div>
                        <template v-if="discounts.length > 0">
                            <div class="d-flex flex-column w-100">
                                <div>{{$t('Discounts:')}}</div>
                                <discount-item v-for="(discount, index) in discounts" :key="index" :discount="discount"></discount-item>
                            </div>
                        </template>
                    </template>
                    <div class="checkout-card_divider">
                        <div class="checkout-card_total-price d-flex w-100 justify-content-between mt-2">
                            <div>{{$t('Total Price:')}}</div>
                            <div>{{ netPriceString }}</div>
                        </div>
                    </div>
                </div>
                <div class="checkout-card_footer">
                    <div v-if="!completedPayment" class="d-flex flex-column justify-content-center align-items-center my-4">
                        <div>
                            <label class="custom-control custom-checkbox d-flex align-items-center justify-content-center">
                                <input v-model="acceptedTCs" type="checkbox" class="custom-control-input">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">{{$t('I agree with the')}} <a @click="gotoTerms" class="pointer">{{$t('Terms & Conditions')}}.</a></span>
                            </label>
                        </div>
                        <div>
                            <label v-if="acceptedTCsError" class="error">{{acceptedTCsError}}</label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <button v-if="!completedPayment" class="checkout-card_body-button mr-2" @click="tryDeleteCreditPack" v-bind:disabled="loading">
                            {{$t('Cancel')}}
                        </button>
                        <button @click="managePurchase" class="checkout-card_body-button checkout-card_body-button-success" v-bind:disabled="loading">
                            {{ buttonText }}
                            <span v-if="loading" class="inline-loader"></span>
                        </button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { CreditPackPaymentAddMixin } from "../../mixins/CreditPacks/CreditPackPaymentAddMixin";
    import { CreditPackUpdateMixin } from "../../mixins/CreditPacks/CreditPackUpdateMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import CreditPackDiscountItem from "./CreditPackDiscountItem.vue";

    export default {
        name: 'CreditPackModify',
        mixins: [CreditPackUpdateMixin, CreditPackPaymentAddMixin],
        props: {
            config: {
                type: Object,
                required: true
            }
        },
        computed: {
            creditTypeId() {
                return this.config.creditTypeId;
            },
            creditTypeDescription() {
                return this.config.creditTypeDescription;
            },
            paymentCompletedRouteName() {
                return this.config.paymentCompletedRouteName;
            },
            loading() {
                return this.loadingSubscriptionEntityPayments || this.loadingCreditPacks;
            },
            creditPack() {
                return this.creditPacks[0];
            },
            subscriptionEntityId() {
                return !!this.creditPack ? this.creditPack.id : '';
            },
            currency() {
                return !!this.creditPack ? this.creditPack.currency : '';
            },
            currencySymbol() {
                return this.currency === 'GBP' ? '£' : '$';
            },
            grossPrice() {
                return !!this.creditPack ? this.creditPack.grossPrice : 0;
            },
            netPrice() {
                return !!this.creditPack ? this.creditPack.netPrice : 0;
            },
            netPriceString() {
                return this.currencySymbol + this.netPrice.toFixed(2);
            },
            grossPriceString() {
                return this.currencySymbol + this.grossPrice.toFixed(2);
            },
            amount() {
                return !!this.creditPack ? this.creditPack.amount : 0;
            },
            displayBreakdown() {
                return this.netPrice < this.grossPrice;
            },
            discounts() {
                return !!this.creditPack ? this.creditPack.discounts : [];
            },
            buttonText() {
                return !!this.completedPayment ? this.$t('Complete') : this.$t('Proceed');
            }
        },
        components: {
            'discount-item': CreditPackDiscountItem,
            'error-banner': ErrorBanner
        }
    }
</script>
