<template>
    <div v-if="unavailability" class="alert alert-warning alert-icon" role="alert">
        <div class="alert-content">
            <p>{{unavailability.message}}</p>
        </div>
    </div>
</template>

<script>
    import { UnavailabilitiesCacheMixin } from "../../Shared/UnavailabilitiesCacheMixin";

    export default {
        name: 'AppointmentResourceAvailability',
        mixins: [UnavailabilitiesCacheMixin],
        computed: {
            resourceId() {
                return !!this.appointment ? this.appointment.resourceId : '';
            },
            unavailability() {
                return this.unavailabilities.find(u => u.resourceId === this.resourceId);
            }
        }
    }
</script>
