<template>
    <modal-dialog :title="$t('Add Appointment')" @opened="toggleValue=0">
        <div class="modal-body">
            <div class="form-group text-center">
                <div data-toggle="buttons" class="btn-group btn-group-toggle">
                    <label class="btn btn-sm btn-outline-secondary active">
                        <input v-model="toggleValue" :value="0" type="radio">
                        {{$t('Appointment')}}
                    </label>
                    <label class="btn btn-sm btn-outline-secondary">
                        <input v-model="toggleValue" :value="1" type="radio">
                        {{$t('Staff event')}}
                    </label>
                </div>
            </div>
            <add-appointment v-if="toggleValue===0"></add-appointment>
            <add-event v-if="toggleValue===1"></add-event>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import AddAppointment from './AddAppointment.vue';
    import AddStaffEvent from './AddStaffEvent.vue';

    export default {
        name: 'ScheduleAddItem',
        data() {
            return {
                toggleValue: 0
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'appointmentScheduleAddedEvent',
                'eventsAddedEvent'
            ]),
        },
        watch: {
            appointmentScheduleAddedEvent() {
                if (this.$refs.closeModal) {
                    this.$refs.closeModal.click();
                }
            },
            eventsAddedEvent() {
                if (this.$refs.closeModal) {
                    this.$refs.closeModal.click();
                }
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'add-appointment': AddAppointment,
            'add-event': AddStaffEvent
        }
    }
</script>
