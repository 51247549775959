import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const TagsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        ownerId: String,
        ownerTypeId: Number
    },
    computed: {
        ...mapGetters('Tags', [
            'findTagsCache'
        ]),
        tagFilterModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        tagFilterModelValid() {
            return !!this.ownerId && !!this.ownerTypeId;
        },
        tagsCacheKey() {
            return CacheHelper.objectCacheKey(this.tagFilterModel);
        },
        tagsCache() {
            return this.findTagsCache(this.tagsCacheKey);
        },
        tags() {
            return !!this.tagsCache ? this.tagsCache.values : [];
        },
        loadingTags() {
            return !!this.tagsCache && this.tagsCache.loading;
        }
    },
    watch: {
        tagFilterModel() {
            this.refreshTags();
        }
    },
    methods: {
        ...mapActions('Tags', [
            'findTags'
        ]),
        refreshTags() {
            if (!this.tagsCache || !!this.tagsCache.retry) {
                this.findAndCacheTags();
            }
        },
        findAndCacheTags() {
            const self = this;
            self.error = '';
            if (!self.tagFilterModelValid) {
                return;
            }
            self.findTags({ key: self.tagsCacheKey, payload: self.tagFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTags();
    }
}
