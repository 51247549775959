import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const StripoAddMixin = {
    data() {
        return {
            error: '',
            html: '',
            css: ''
        }
    },
    computed: {
        ...mapState('StripoTemplates', [
            'addingStripoTemplate'
        ]),
        defaultStripoTemplate() {
            console.log('OVERRIDE COMPUTED - defaultStripoTemplate');
        },
        htmlFile() {
            return new File([this.html], 'Scaffold.html', { type: 'text/html' });
        },
        cssFile() {
            return new File([this.css], 'Scaffold.css', { type: 'text/plain' });
        },
        stripoAddModel() {
            return {
                html: this.htmlFile,
                css: this.cssFile
            };
        },
        stripoAddModelValid() {
            return !!this.html && !!this.css;
        }
    },
    watch: {
        defaultStripoTemplate() {
            this.refreshStripoTemplateModel();
        }
    },
    methods: {
        ...mapActions('StripoTemplates', [
            'addStripoTemplate'
        ]),
        tryAddStripoTemplate() {
            const self = this;
            if (!self.stripoAddModelValid) {
                return;
            }
            self.error = '';
            self.addStripoTemplate(self.stripoAddModel)
                .then(response => self.afterStripoTemplateAdded(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshStripoTemplateModel() {
            this.html = !!this.defaultStripoTemplate ? this.defaultStripoTemplate.html : '';
            this.css = !!this.defaultStripoTemplate ? this.defaultStripoTemplate.css : '';
        },
        afterStripoTemplateAdded() {
            // override if necessary
        }
    },
    created() {
        this.refreshStripoTemplateModel();
    }
}
