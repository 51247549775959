import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ReferralRewardSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ReferralRewardSettings', [
            'loadingReferralRewardSettings',
            'loadedReferralRewardSettings',
            'referralRewardSettings'
        ])
    },
    methods: {
        ...mapActions('ReferralRewardSettings', [
            'getReferralRewardSettings'
        ]),
        refreshReferralRewardSettings() {
            if (!this.loadingReferralRewardSettings && !this.loadedReferralRewardSettings) {
                this.tryGetReferralRewardSettings();
            }
        },
        tryGetReferralRewardSettings() {
            const self = this;
            self.getReferralRewardSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshReferralRewardSettings();
    }
}
