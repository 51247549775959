<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{$t('Campaigns')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button id="AddCampaignBtnMobile" @click="openCampaignAdd" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add Campaign</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button id="AddCampaignBtnDesktop" @click="openCampaignAdd" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add Campaign')}}
                </button>
            </div>
        </header>
        <div class="content">
            <div v-if="showNotification" class="alert alert-info  alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{$t('Campaigns must be "Confirmed" in order to send them')}}</p>
                </div>
            </div>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <table-mobile>
                <campaign-mobile v-for="(campaign, index) in campaigns" :key="campaign.id" :campaign="campaign" :number="index"></campaign-mobile>
            </table-mobile>
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th class="col-4">{{$t('Subject')}}</th>
                        <th class="col-2 text-center">{{$t('Method')}}</th>
                        <th class="col-2 text-center">{{$t('Reach')}}</th>
                        <th class="col-2 text-center">{{$t('Confirmed')}}</th>
                        <th class="col-2 text-center">{{$t('Sent')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <campaign-desktop v-for="(campaign, index) in campaigns" :key="campaign.id" :campaign="campaign" :number="index"></campaign-desktop>
                </template>
            </table-desktop>
        </div>
        <add-campaign-guide v-if="showAddCampaignGuide"></add-campaign-guide>
    </div>
</template>

<script>
    import { CampaignsCacheMixin } from "../CampaignsCacheMixin";
    import { CampaignAddAllowedMixin } from "../../../Shared/CampaignAddAllowedMixin";
    import SiteSelect from "../../../Account/SiteSelect.vue";
    import TableMobile from "../../../Shared/Layout/TableMobile.vue";
    import TableDesktop from "../../../Shared/Layout/TableDesktop.vue";
    import CampaignItemDesktop from "./CampaignItemDesktop.vue";
    import CampaignItemMobile from "./CampaignItemMobile.vue";
    import AddCampaignGuide from "../../../OnboardingGuides/AddCampaignGuide.vue";

    export default {
        name: 'Campaigns',
        mixins: [CampaignsCacheMixin, CampaignAddAllowedMixin],
        computed: {
            showNotification() {
                return this.campaigns.some(c => !c.confirmed);
            },
            showAddCampaignGuide() {
                return this.$route.query.gettingstarted === 'true';
            }
        },
        components: {
            'site-select': SiteSelect,
            'table-mobile': TableMobile,
            'table-desktop': TableDesktop,
            'campaign-desktop': CampaignItemDesktop,
            'campaign-mobile': CampaignItemMobile,
            'add-campaign-guide': AddCampaignGuide
        }
    }
</script>
