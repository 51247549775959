import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteScheduleSettingMixin } from "./SiteScheduleSettingMixin";

export const SiteScheduleSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteScheduleSettingMixin],
    data() {
        return {
            startHour: '',
            endHour: '',
            startDay: 0,
            hiddenDays: [],
            nonBusinessHoursAmount: 2
        }
    },
    computed: {
        settingEditModel() {
            return {
                startHour: this.startHour,
                endHour: this.endHour,
                startDay: this.startDay,
                hiddenDays: this.hiddenDays,
                nonBusinessHoursAmount: this.nonBusinessHoursAmount
            }
        },
        settingEditModelValid() {
            return true;
        },
        settingEditModelModified() {
            const original = this.settingObject;
            const modified = this.settingEditModel;
            return modified.startHour !== original.startHour ||
                modified.endHour !== original.endHour ||
                modified.startDay !== original.startDay ||
                JSON.stringify(modified.hiddenDays) !== JSON.stringify(original.hiddenDays) ||
                modified.nonBusinessHoursAmount !== original.nonBusinessHoursAmount;
        }
    },
    methods: {
        refreshSettingEditModel() {
            const model = this.settingObject;
            this.startHour = model.startHour;
            this.endHour = model.endHour;
            this.startDay = model.startDay;
            this.hiddenDays = [...model.hiddenDays];
            this.nonBusinessHoursAmount = model.nonBusinessHoursAmount;
        }
    }
}
