import router from '../../../routing';

export const ContractItemMixin = {
    props: {
        contractTemplate: Object
    },
    computed: {
        templateName() {
            return this.contractTemplate.templateTypeName;
        },
        startLetter() {
            return this.templateName[0].toUpperCase();
        },
        signatureRequired() {
            return !!this.contractTemplate.requiresSignature;
        },
        signature() {
            return this.signatureRequired ? 'Yes' : 'No';
        },
        version() {
            return this.contractTemplate.version.toString();
        }
    },
    methods: {
        onClick() {
            router.push({ name: 'contractUpdate', params: { typeId: this.contractTemplate.templateTypeId } });
        }
    }
}
