<template>
    <div class="dropdown w-100">
        <button aria-expanded="false" 
                aria-haspopup="true" 
                class="filter-dd text-left w-100 mw-400" 
                data-toggle="dropdown" 
                type="button">
            <multi-select-display :values="summary"></multi-select-display>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100 mw-400">
            <div v-for="site in options" :key="site.id" class="dropdown-item px-2">
                <label class="custom-control custom-checkbox">
                    <input v-model="site.selected" @change="onChange" class="custom-control-input" type="checkbox">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{site.name}}</span>
                </label>
            </div>
            <div @click="onSelectAll" class="dropdown-item px-2 w-100 text-center" style="">
                <label>{{$t('Select all')}}</label>
            </div>
            <div v-if="manySelected" @click="onClear" class="dropdown-item px-2 w-100 text-center">
                <label>{{$t('Clear')}}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../SiteCacheMixin";
    import MultiSelectDropdownDisplay from "../Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'SiteMultiSelectDropdown',
        mixins: [SiteCacheMixin],
        props: {
            value: Array
        },
        computed: {
            options() {
                return this.sites.map(s => Object.assign({}, s, { selected: this.value.indexOf(s.id) > -1 }));
            },
            selectedOptions() {
                return this.options.filter(o => o.selected);
            },
            summary() {
                return this.selectedOptions.length > 0 ? this.selectedOptions.map(o => o.name) : ['Locations'];
            },
            manySelected() {
                return this.selectedOptions.length > 1;
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.filter(o => o.selected).map(o => o.id));
            },
            onSelectAll() {
                this.$emit('input', this.options.map(o => o.id));
            },
            onClear() {
                this.$emit('input', [this.siteId]);
            }
        },
        components: {
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
