<template>
    <div>
        <div class="title-divider mb-3 mt-0">
            <h2>{{$t('Pet')}} {{index + 1}}</h2>
        </div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="name">{{$t('Name')}}:</label>
            </div>
            <div class="col-md-8">
                {{petName}}
            </div>
        </div>
        <breed-item :id="petBreedId"></breed-item>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { PetAddMixin } from "../Shared/PetAddMixin";
    import BreedItem from './BreedItem.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'BookingAddPet',
        mixins: [PetAddMixin],
        props: {
            participant: Object,
            addedClientId: '',
            index: 0,
        },
        data() {
            return {
                addedPetId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'approveBookingEvent'
            ]),
            clientId() {
                return this.addedClientId;
            },
            petName() {
                return !!this.participant ? this.participant.name : '';
            },
            petBreedId() {
                return !!this.participant ? this.participant.breedId : ''
            },
            petAddModel() {
                return {
                    clientId: this.clientId,
                    name: this.petName,
                    breedId: this.petBreedId
                }
            },
            completed() {
                return !!this.addedPetId;
            },
            loading() {
                return !!this.addingPet;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canAddPet() {
                return !!this.addedClientId && !this.addedPetId;
            }
        },
        watch: {
            approveBookingEvent() {
                this.onValidatedChanged();
            },
            addedClientId() {
                this.onValidatedChanged();
            },
            status() {
                this.$emit('petAdded', {
                    index: this.index,
                    participant: {
                        ...this.participant,
                        status: this.status,
                        petId: this.addedPetId
                    }
                })
            }
        },
        methods: {
            async onValidatedChanged() {
                if (!this.canAddPet) {
                    return;
                }
                try {
                    const response = await this.tryAddPet();
                    this.addedPetId = response.data.id;
                }
                catch {
                    this.addedPetId = '';
                }
            }
        },
        components: {
            'breed-item': BreedItem,
            'error-banner': ErrorBanner
        }
    }
</script>
