export const OrderHelper = {
    getOrderValidator() {
        return {
            isValid: false,
            orderItems: [],
            check(model) {
                this.orderItems = [];
                for (var i = 0; i < model.orderItems.length; i++) {
                    const item = model.orderItems[i];
                    const amount = parseFloat(item.amount);
                    const quantity = parseInt(item.quantity);
                    this.orderItems.push({
                        productId: item.productId,
                        amount: isNaN(amount) || amount < 0 ? 'Please enter a valid amount.' : '',
                        quantity: isNaN(quantity) || quantity < 1 ? 'Please enter a valid quantity.' : ''
                    })
                }
                this.isValid = !this.orderItems.some(oi => !!oi.amount || !!oi.quantity);
            }
        }
    }
}
