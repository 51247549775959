import { Api } from '../../services/Api';

const state = () => ({
    contractTemplateSiteCaches: []
})

const getters = {
    findContractTemplateSiteCache: (state) => key => {
        return state.contractTemplateSiteCaches.find(ctsc => ctsc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.contractTemplateSiteCaches.find(ctsc => ctsc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.contractTemplateSiteCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setContractTemplates(state, { key, contractTemplates }) {
        const existing = state.contractTemplateSiteCaches.find(ctsc => ctsc.key === key);
        if (!!existing) {
            existing.values = contractTemplates;
            existing.loading = false;
        } else {
            state.contractTemplateSiteCaches.push({ key: key, values: contractTemplates, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.contractTemplateSiteCaches.find(ctsc => ctsc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearCache(state, key) {
        state.contractTemplateSiteCaches = state.contractTemplateSiteCaches.filter(ctsc => ctsc.key === key);
    }
}

const actions = {
    getContractSiteTemplates({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('ContractTemplate/Site/' + id)
                .then(response => {
                    commit('setContractTemplates', { key: id, contractTemplates: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
