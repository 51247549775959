<template>
    <tr @click="gotoSite">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{siteName}}
                    </div>
                    <div class="crd-contact text-truncate">
                        {{companyName}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { SiteItemMixin } from "./SiteItemMixin";

    export default {
        name: 'SiteItemMobile',
        mixins: [SiteItemMixin]
    }
</script>
