<template>
    <collapsed-section id="site-contact" :title="$t('Contact Details')">
        <contact-details v-model="detailsStatus"></contact-details>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateContactDetails from "./SiteUpdateContactDetails.vue";

    export default {
        name: 'SiteUpdateContact',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                detailsStatus: {}
            }
        },
        computed: {
            status() {
                return {
                    loading: !!this.detailsStatus.loading,
                    error: this.detailsStatus.error
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'contact-details': SiteUpdateContactDetails,
        }
    }
</script>
