import { mapState, mapActions } from "vuex";
import { ErrorHelper } from "../../../../helpers/ErrorHelper";
import { TimeZoneCacheMixin } from "./TimeZoneCacheMixin";
import moment from 'moment';

export const TimeZoneAddMixin = {
    mixins: [TimeZoneCacheMixin],
    data() {
        return {
            timeZoneId: moment.tz.guess()
        }
    },
    computed: {
        ...mapState('TimeZones', [
            'timeZoneOptions'
        ]),
        timeZoneAddModel() {
            return {
                timeZoneId: this.timeZoneId
            }
        },
        timeZoneAddModelValid() {
            return !!this.timeZoneAddModel.timeZoneId;
        }
    },
    methods: {
        ...mapActions('TimeZones', [
            'addTimeZone'
        ]),
        tryAddTimeZone() {
            const self = this;
            if (!self.timeZoneAddModelValid) {
                return;
            }
            self.error = '';
            self.addTimeZone({ key: self.timeZoneCacheKey, payload: self.timeZoneAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
