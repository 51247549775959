import { Api } from "../../services/Api";

const state = () => ({
    tagsCaches: []
})

const getters = {
    findTagsCache: (state) => key => {
        return state.tagsCaches.find(tc => tc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.tagsCaches.find(tc => tc.key === key);
        if (!!existing) {
            existing.retry = false;
            existing.loading = !!loading;
        } else {
            state.tagsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setTags(state, { key, tags }) {
        const existing = state.tagsCaches.find(tc => tc.key === key);
        if (!!existing) {
            existing.retry = false;
            existing.values = tags;
            existing.loading = false;
        } else {
            state.tagsCaches.push({ key: key, values: tags, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.tagsCaches.find(tc => tc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendTag(state, { key, tag }) {
        const existing = state.tagsCaches.find(tc => tc.key === key);
        if (!!existing) {
            existing.values.push(tag);
        } else {
            state.tagTypesCaches.push({ key: key, values: [tag], loading: false });
        }
    },
    removeTag(state, { key, id }) {
        const existing = state.tagsCaches.find(tc => tc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(t => t.id !== id);
        }
    }
}

const actions = {
    findTags({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Tag?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setTags', { key: key, tags: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addTag({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Tag', payload)
                .then(response => {
                    commit('appendTag', { key: key, tag: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteTag({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('Tag/' + id)
                .then(response => {
                    commit('removeTag', { key: key, id: id });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
