<template>
    <label class="error" ref="validationRef">
        {{ message }}
    </label>
</template>
<script>
    export default {
        name: 'ValidationMessage',
        props: ['message'],
        mounted() {
            this.$nextTick(() => {
                this.$refs.validationRef.scrollIntoView({ behavior: 'smooth' });
            });
        }
    }
</script>