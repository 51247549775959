<template>
    <div v-if="!!address">
        {{summary}}
    </div>
</template>

<script>
    import { AddressCacheMixin } from "../Shared/AddressCacheMixin";

    export default {
        name: 'CalendarAppointmentAddressLines',
        mixins: [AddressCacheMixin],
        props: {
            clientId: String
        },
        computed: {
            ownerQueryModel() {
                return {
                    ownerId: this.clientId,
                    ownerTypeId: 2
                };
            },
            summary() {
                let addressString = "";
                if (!!this.address.line1) {
                    addressString += this.address.line1 + ", ";
                }
                if (!!this.address.town) {
                    addressString += this.address.town + ", ";
                }
                if (!!this.address.postCode) {
                    addressString += this.address.postCode;
                }
                return addressString.replace(/[, ]+$/, "");;
            },
        },
        methods: {
            trySearchAddresses() {
                // do nothing
            }
        }
    }
</script>
