<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-contact" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Contact')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-contact">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <label>{{$t('Name')}}</label>
                    <input v-model="name" type="text" class="form-control">
                    <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
                    <label>{{$t('Email')}}</label>
                    <input v-model="email" type="text" class="form-control">
                    <label>{{$t('Mobile')}}</label>
                    <input v-model="mobile" type="text" class="form-control">
                    <label>{{$t('Other info')}}</label>
                    <textarea v-model="otherInfo" class="form-control" :placeholder="$t('Website, etc')" cols="50" rows="10"></textarea>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { ContactHelper } from "../../helpers/ContactHelper";
    import { AccountSettingSiteDefaultMixin } from '../../mixins/Settings/Account/AccountSettingSiteDefaultMixin';

    export default {
        name: 'DefaultSiteContact',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                name: '',
                mobile: '',
                email: '',
                otherInfo: '',
                contactValidator: ContactHelper.getContactValidator()
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultContact';
            },
            accountSettingEditModel() {
                return {
                    name: this.name,
                    mobile: this.mobile,
                    email: this.email,
                    otherInfo: this.otherInfo
                }
            },
            accountSettingEditModelValid() {
                this.contactValidator.check(this.accountSettingEditModel);
                return this.contactValidator.isValid;
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.name = !!this.accountSettingObject ? this.accountSettingObject.name : '';
                this.mobile = !!this.accountSettingObject ? this.accountSettingObject.mobile : '';
                this.email = !!this.accountSettingObject ? this.accountSettingObject.email : '';
                this.otherInfo = !!this.accountSettingObject ? this.accountSettingObject.otherInfo : '';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
