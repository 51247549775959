import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin"
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SmsCampaignTemplatesCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SmsCampaignTemplates', [
            'findSmsCampaignTemplatesCache'
        ]),
        smsCampaignTemplatesCache() {
            return this.findSmsCampaignTemplatesCache(this.siteId);
        },
        smsCampaignTemplates() {
            return !!this.smsCampaignTemplatesCache ? this.smsCampaignTemplatesCache.values : [];
        },
        loadingSmsCampaignTemplates() {
            return !!this.smsCampaignTemplatesCache && this.smsCampaignTemplatesCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshSmsCampaignTemplates();
        }
    },
    methods: {
        ...mapActions('SmsCampaignTemplates', [
            'findSmsCampaignTemplates'
        ]),
        refreshSmsCampaignTemplates() {
            if (!this.smsCampaignTemplatesCache || this.smsCampaignTemplatesCache.retry) {
                this.tryFindSmsCampaignTemplates();
            }
        },
        tryFindSmsCampaignTemplates() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.findSmsCampaignTemplates(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsCampaignTemplates();
    }
}
