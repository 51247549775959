<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Weight')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionDescription}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setUnit(option.value)"
                            v-bind:class="{'active': option.value === unit}">
                            {{option.name}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { WeightSettingUploadMixin } from "../../../mixins/Settings/Site/WeightSettingUploadMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLocalizationWeight',
        mixins: [WeightSettingUploadMixin, StatusEmitterMixin],
        data() {
            return {
                options: [
                    { value: 'kgs', name: 'Kgs' },
                    { value: 'lbs', name: 'Lbs' },
                ]
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingSetting,
                    error: this.error
                }
            },
            selectedOption() {
                return this.options.find(o => o.value === this.unit);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.selectedOption.name : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        methods: {
            setUnit(value) {
                this.unit = value;
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
