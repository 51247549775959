import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const BookingSuggestionsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BookingSuggestions', [
            'findBookingSuggestionCache'
        ]),
        bookingId() {
            console.log('OVERRIDE COMPUTED - bookingId');
        },
        bookingSuggestionCache() {
            return this.findBookingSuggestionCache(this.bookingId);
        },
        bookingSuggestions() {
            return !!this.bookingSuggestionCache ? this.bookingSuggestionCache.values : [];
        },
        loadingBookingSuggestions() {
            return !!this.bookingSuggestionCache && this.bookingSuggestionCache.loading;
        }
    },
    watch: {
        bookingId() {
            this.refreshBookingSuggestions();
        }
    },
    methods: {
        ...mapActions('BookingSuggestions', [
            'getBookingSuggestions'
        ]),
        refreshBookingSuggestions() {
            if (!this.bookingSuggestionCache || !!this.bookingSuggestionCache.retry) {
                this.tryGetBookingSuggestions();
            }
        },
        tryGetBookingSuggestions() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.getBookingSuggestions(self.bookingId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingSuggestions();
    }
}
