<template>
    <div class="d-flex flex-column align-items-center mb-5">
        <div class="h2">Pricing Plan</div>
        <p class="text-center mb-0" style="color: #000; font-size: medium;"><strong>Our Subscriptions</strong>, simple, flexible and designed for your business needs.</p>
        <p class="text-center" style="color: #000; font-size: medium;">{{$t('Save')}} <strong>10%</strong>, by choosing our yearly subscription.</p>
        <div class="form-group">
            <label class="ps-toggle-switch">
                <span class="d-inline-block mr-2">Monthly</span>
                <input v-model="yearly" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">Yearly</span>
            </label>
        </div>
        <div class="upgrade-plan-v2">
            <div class="upgrade-plan-v2_card">
                <div class="upgrade-plan-v2_header">
                    <div class="upgrade-plan-v2_header-desription" style="margin-bottom: 1.1rem;">Feature</div>
                </div>
                <div class="upgrade-plan-v2_pricing">Pricing</div>
                <div class="upgrade-plan-v2_list">
                    <div>No. of Clients</div>
                    <div>No. of Users</div>
                    <div>E-Contracts</div>
                    <div>Registration Forms</div>
                    <div>Email Reminders</div>
                    <div>SMS Reminders</div>
                    <div>Payments & Deposits</div>
                    <div>Support</div>
                    <div>Marketing</div>
                    <div>Customise Email Templates</div>
                    <div>Pre-designed Email Templates</div>
                    <div>Campaign Credits</div>
                    <div>Dashboard / Analytics</div>
                    <div>Reporting</div>
                    <div>Multi Site Management</div>
                    <div>Online Booking</div>
                    <div>SMS Credits</div>
                    <div>Sites</div>
                </div>
            </div>
            <upgrade-type v-for="type in subscriptionUpgradeTypes" 
                :key="type.id" 
                :subscription-type="type" 
                :selected-upgrade="subscriptionUpgradeAddModel" 
                @onSelected="onTryAddSubscriptionUpgrade">
            </upgrade-type>
        </div>
    </div>
</template>

<script>
    import { SubscriptionCacheMixin } from "../../../mixins/Subscriptions/SubscriptionCacheMixin";
    import { SubscriptionUpgradeAddMixin } from "../../../mixins/Subscriptions/SubscriptionUpgradeAddMixin";
    import { SubscriptionUpgradeTypesCacheMixin } from "../../../mixins/Subscriptions/SubscriptionUpgradeTypesCacheMixin";
    import SubscriptionUpgradeTypeV2 from "./SubscriptionUpgradeTypeV2.vue";

    export default {
        name: 'SubscriptionUpgradeSelectV2',
        mixins: [SubscriptionCacheMixin, SubscriptionUpgradeAddMixin, SubscriptionUpgradeTypesCacheMixin],
        watch: {
            subscription() {
                this.refreshYearly();
            }
        },
        methods: {
            refreshYearly() {
                this.yearly = !!this.subscription ? this.subscription.yearly : false;
            },
            onTryAddSubscriptionUpgrade(value) {
                this.subscriptionTypeId = value.subscriptionTypeId;
                this.additionalSites = value.additionalSites;
                this.tryAddSubscriptionUpgrade();
            }
        },
        components: {
            'upgrade-type': SubscriptionUpgradeTypeV2
        },
        created() {
            this.refreshYearly();
        }
    }
</script>
<style>
    .upgrade-plan-v2 {
        display: flex;
        flex-direction: row;
        color: #716387;
        font-size: 0.8rem;
    }
    .upgrade-plan-v2 .upgrade-plan-v2_card .upgrade-plan-v2_header,
    .upgrade-plan-v2 .upgrade-plan-v2_card .upgrade-plan-v2_pricing {
        border-top: 0.05rem solid #E9ECF4;
    }
    .upgrade-plan-v2 .upgrade-plan-v2_card .upgrade-plan-v2_list > div {
        border-top: 0.05rem solid #E9ECF4;
    }
    .upgrade-plan-v2 div:nth-child(4) .upgrade-plan-v2_card div.upgrade-plan-v2_pricing,
    .upgrade-plan-v2 div:nth-child(4) .upgrade-plan-v2_card .upgrade-plan-v2_list > div {
        border-top: 0.05rem solid #E5B100;
    }
    .upgrade-plan-v2 .upgrade-plan-v2_card .upgrade-plan-v2_list > div:last-child {
        border-bottom: 0.05rem solid #E9ECF4;
    }
    .upgrade-plan-v2 div .upgrade-plan-v2_card > div > div {
        padding-left: 1.5rem;
    }
    .upgrade-plan-v2 div:nth-child(2) .upgrade-plan-v2_card,
    .upgrade-plan-v2 div:nth-child(3) .upgrade-plan-v2_card {
        border-left: 0.05rem solid #E9ECF4;
    }
    .upgrade-plan-v2 div:nth-child(4) .upgrade-plan-v2_card {
        background-color: #FFC500;
        border-radius: 1rem;
        color: #000000;
    }
    .upgrade-plan-v2_card {
        width: 12rem;
    }
    .upgrade-plan-v2_header {
        width: 100%;
        padding-bottom: 0.625rem;
        height: 5.75rem;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
    .upgrade-plan-v2_header-desription {
        font-size: 1.625rem;
        font-weight: 400;
        color: #000000;
    }
    .upgrade-plan-v2_pricing {
        width: 100%;
        height: 3.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .upgrade-plan-v2_pricing-money {
        font-size: 2.25rem;
        line-height: 2rem;
        font-weight: 700;
        color: #000000;
        display: flex;
    }
    .upgrade-plan-v2_pricing-money span {
        font-size: 0.8rem;
        margin-left: 0.25rem;
    }
    .upgrade-plan-v2_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .upgrade-plan-v2_list > div {
        width: 100%;
        height: 2rem;
        display: flex;
        align-items: center;
    }
    .upgrade-plan-v2_input[type="number"] {
        max-width: max-content;
        width: 2.25rem;
        text-align: center;
        height: 1.25rem;
        border: 0.05rem solid #716387;
        border-radius: 0.25rem;
        color: #716387;
    }
    .upgrade-plan-v2_input[type="number"]::-webkit-inner-spin-button,
    .upgrade-plan-v2_input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .upgrade-plan-v2_popular {
        padding-block: 0.5rem;
        background-color: #FE7698;
        text-align: center;
        margin-top: -1rem;
        color: #FFFFFF;
        position: absolute;
        width: 8rem;
        margin-inline: 2rem;
        border-radius: 2rem;
    }
    .upgrade-plan-v2_choose {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0.25rem;
    }
    .upgrade-plan-v2_choose-current {
        color: #716387;
        padding: 0.5rem 1rem;
    }
    .upgrade-plan-v2_choose-button {
        width: max-content;
        color: #FFFFFF;
        background-color: #F2205C;
        border: none;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }
</style>