import { DateDisplayMixin } from "../Shared/Time/DateDisplayMixin";
import moment from 'moment';

export const PaymentFailureItemMixin = {
    mixins: [DateDisplayMixin],
    props: {
        payment: {
            type: Object,
            required: true
        }
    },
    computed: {
        paymentId() {
            return this.payment.id;
        },
        createdLocalString() {
            return moment(this.payment.createdUtc).format(this.momentJSFormat);
        },
        description() {
            return this.payment.description;
        },
        statusDescription() {
            return this.payment.status.description;
        },
        amountFixed() {
            return this.payment.amount.toFixed(2);
        },
        amount() {
            return this.amountFixed;
        }
    },
    methods: {
        onClick() {
            this.$router.push({
                name: 'subscriptionCheckout',
                params: { 'paymentId': this.paymentId },
                query: { 'onComplete': 'payment' }
            });
        }
    }
}
