import { ContractCacheMixin } from "./ContractCacheMixin";
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const ContractDeleteMixin = {
    mixins: [ContractCacheMixin],
    computed: {
        ...mapState('ApplicationEvents', [
            'contractDeletedEvent'
        ]),
        contract() {
            console.log('!!!OVERRIDE COMPUTED - contract!!!');
        }
    },
    methods: {
        ...mapActions('Contracts', [
            'deleteContract'
        ]),
        tryDeleteContract() {
            const self = this;
            if (!self.contract) {
                return;
            }
            self.deleteContract({ key: self.clientId, id: self.contract.id })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
