<template>
    <div>
        <div v-for="(pet, index) in addedPets" :key="pet.id">
            <add-pet :id="pet.id" :index="index" @deletePet="deletePet" v-model="pet.status" :added-client-id="addedClientId" />
        </div>
        <a @click="addPet" class="w-100 d-flex justify-content-center mb-3 mt-4" style="cursor: pointer !important">{{$t('Add additional pet?')}}</a>
    </div>
</template>

<script>
    import AddPet from './AddPet.vue';

    export default {
        name: 'NewPets',
        props: {
            addedClientId: ''
        },
        data() {
            return {
                addedPets: [{
                    id: Math.random().toString(36).substring(7),
                    status: {
                        validated: false,
                        loading: false,
                        error: '',
                        completed: false
                    }
                }]
            }
        },
        computed: {
            addedPetsStatuses() {
                return this.addedPets.map(pet => pet.status);
            },
            completed() {
                return !!this.addedPetsStatuses.every(s => !!s.completed);
            },
            validated() {
                return !!this.addedPetsStatuses.every(s => !!s.validated);
            },
            errored() {
                return !!this.addedPetsStatuses.map(s => s.error).filter(s => !!s);
            },
            loading() {
                return !!this.addedPetsStatuses.some(s => s.loading);
            },
            status() {
                return {
                    error: !!this.errored, // rename error to error for all statuses
                    completed: this.completed,
                    validated: this.validated,
                    loading: this.loading
                }
            }
        },
        watch: {
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            addPet() {
                let newId;
                do {
                    newId = Math.random().toString(36).substring(7);
                } while (this.addedPets.some(pet => pet.id === newId));
                this.addedPets.push({
                    id: newId,
                    status: {
                        validated: false,
                        loading: false,
                        error: '',
                        completed: false
                    }
                });
            },
            deletePet(id) {
                this.addedPets = this.addedPets.filter(p => p.id !== id);
            }
        },
        components: {
            'add-pet': AddPet
        }
    }
</script>
