<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Add Template')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav header-nav-left">
                <a @click="cancel" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <error-banner v-if="error" :message="error"></error-banner>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <name-input v-model="templateTypeName" :validator="contractTemplateAddValidator"></name-input>
            <signature-toggle v-model="requiresSignature"></signature-toggle>
            <html-editor v-model="html" :validator="contractTemplateAddValidator"></html-editor>
        </div>
        <div class="fixed-action-btns action-btns d-flex flex-column align-items-start pt-0">
            <tag-info></tag-info>
            <div class="d-flex justify-content-between align-items-center col-12 mt-2 px-0">
                <div>
                    <button @click="tryAddContractTemplate" v-bind:disabled="uploadingContractTemplate" class="btn btn-primary" type="button">
                        {{$t('Add')}}
                        <span v-if="uploadingContractTemplate" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
                <toggle-tags></toggle-tags>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import process from 'process';
    import { ContractTemplateAddMixin } from '../../Shared/ContractTemplateAddMixin';
    import SiteSelect from '../../Account/SiteSelect.vue';
    import SignatureRequiredToggle from './SignatureRequiredToggle.vue';
    import TemplateHtmlWysiwyg from './TemplateHtmlWysiwyg.vue';
    import TemplateNameInput from './TemplateNameInput.vue';
    import TemplateTagInfo from './TemplateTagInfo.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import TagsDisplayToggleButton from '../../Shared/Tags/TagsDisplayToggleButton.vue';

    export default {
        name: 'ContractAdd',
        mixins: [ContractTemplateAddMixin],
        watch: {
            contractTemplateAddedEvent() {
                process.nextTick(() => {
                    this.cancel();
                });
            }
        },
        methods: {
            cancel() {
                router.go(-1);
            }
        },
        components: {
            'site-select': SiteSelect,
            'signature-toggle': SignatureRequiredToggle,
            'name-input': TemplateNameInput,
            'html-editor': TemplateHtmlWysiwyg,
            'tag-info': TemplateTagInfo,
            'error-banner': ErrorBanner,
            'toggle-tags': TagsDisplayToggleButton
        }
    }
</script>
