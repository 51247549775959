import { Api } from '../../services/Api';

const state = () => ({
    clientSearches: []
})

const getters = {
    findClientSearch: (state) => key => state.clientSearches.find(cs => cs.key === key),
    findClientSearches: (state) => (key) => state.clientSearches.filter(cs => cs.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.clientSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.clientSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setClientSearch(state, { key, clients }) {
        const existing = state.clientSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.values = clients;
            existing.loading = false;
        } else {
            state.clientSearches.push({ key: key, values: clients, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.clientSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearClientSearches(state) {
        state.clientSearches = [];
    }
}

const actions = {
    searchClients({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Client/Search', payload)
                .then(response => {
                    commit('setClientSearch', { key: key, clients: response.data });
                    commit('Clients/setClients', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        })
    },
    searchClientsNoCache({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Client/Search', payload)
                .then(response => {
                    commit('setClientSearch', { key: key, clients: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
