<template>
    <tr>
        <td @click="smsClick">
            <div class="client-row">
                <div class="d-flex align-items-center">
                    <div class="name-avatar">
                        {{startLetter}}
                    </div>
                    <div class="crd-name">
                        {{typeName}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CommunicationTypeItemMixin } from "./CommunicationTypeItemMixin";

    export default {
        name: 'CommunicationTypeSmsItemMobile',
        mixins: [CommunicationTypeItemMixin]
    }
</script>
