<template>

</template>

<script>
    import { AccountSettingPostRegisterMixin } from "../../../mixins/Settings/Account/AccountSettingPostRegisterMixin";

    export default {
        name: 'PostRegisterAccountSettingDefaultResource',
        mixins: [AccountSettingPostRegisterMixin],
        computed: {
            fullName() {
                return !!this.registrationInfo ? this.registrationInfo.fullName : '';
            },
            accountSettingKey() {
                return 'DefaultResource';
            },
            accountSettingEditModel() {
                return {
                    name: this.fullName,
                    colour: '#FFE781'
                };
            }
        }
    }
</script>
