<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="image-uploads">
            <ul>
                <li>
                    <button @click="tryDeleteImage" class="btn" type="button">
                        <i class="far fa-times"></i>
                    </button>
                    <figure>
                        <img alt="Default pet image" :src="image.thumbnails.medium" width="160">
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { DefaultPetImageMixin } from "../../../mixins/Settings/Site/DefaultPetImageMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { ImageUpdateMixin } from "../../Shared/Images/ImageUpdateMixin";
    import { SettingCacheMixin } from "../../Shared/SettingCacheMixin";

    export default {
        name: 'SiteUpdatePetImageDelete',
        mixins: [ImageUpdateMixin, SettingCacheMixin, DefaultPetImageMixin, StatusEmitterMixin],
        computed: {
            imageId() {
                return this.settingObject.imageId;
            },
            status() {
                return {
                    loading: this.loadingImages || this.loadingSetting,
                    error: this.error
                }
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
