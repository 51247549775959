<template>
    <collapsed-section id="site-localization" :title="$t('Localization')">
        <language-setting v-model="languageStatus"></language-setting>
        <date-format v-model="dateFormatStatus"></date-format>
        <time-format v-model="timeFormatStatus"></time-format>
        <timezone-update v-model="timeZoneStatus"></timezone-update>
        <weight-settings v-model="weightStatus"></weight-settings>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateLocalizationDateFormat from "./SiteUpdateLocalizationDateFormat.vue";
    import SiteUpdateLocalizationTimeFormat from "./SiteUpdateLocalizationTimeFormat.vue";
    import SiteUpdateLocalizationTimeZone from "./SiteUpdateLocalizationTimeZone.vue";
    import SiteUpdateLocalizationWeight from "./SiteUpdateLocalizationWeight.vue";
    import SiteUpdateLocalizationLanguage from "./SiteUpdateLocalizationLanguage.vue";

    export default {
        name: 'SiteUpdateLocalization',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                dateFormatStatus: {},
                timeFormatStatus: {},
                timeZoneStatus: {},
                weightStatus: {},
                languageStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.dateFormatStatus,
                    this.timeFormatStatus,
                    this.timeZoneStatus,
                    this.weightStatus,
                    this.languageStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'date-format': SiteUpdateLocalizationDateFormat,
            'time-format': SiteUpdateLocalizationTimeFormat,
            'timezone-update': SiteUpdateLocalizationTimeZone,
            'weight-settings': SiteUpdateLocalizationWeight,
            'language-setting': SiteUpdateLocalizationLanguage
        }
    }
</script>
