import { mapActions } from 'vuex';
import { CountryCacheMixin } from '../../components/Shared/Country/CountryCacheMixin';
import { SiteCacheMixin } from '../../components/Shared/SiteCacheMixin';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionUpgradeAddMixin = {
    mixins: [CountryCacheMixin, SiteCacheMixin],
    data() {
        return {
            error: '',
            subscriptionTypeId: 0,
            additionalSites: 0,
            yearly: false,
            discountCodes: []
        }
    },
    computed: {
        siteId() {
            return this.sites.length > 0 ? this.sites[0].id : '';
        },
        subscriptionUpgradeAddModel() {
            return {
                subscriptionTypeId: this.subscriptionTypeId,
                additionalSites: this.additionalSites,
                yearly: this.yearly,
                currency: this.currency === 'GBP' ? 'GBP' : 'USD',
                discountCodes: this.discountCodes
            }
        },
        subscriptionUpgradeAddModelValid() {
            const model = this.subscriptionUpgradeAddModel;
            return !!model.subscriptionTypeId && model.additionalSites > -1;
        }
    },
    methods: {
        ...mapActions('SubscriptionUpgrade', [
            'addSubscriptionUpgrade'
        ]),
        tryAddSubscriptionUpgrade() {
            const self = this;
            if (!self.subscriptionUpgradeAddModelValid) {
                return;
            }
            self.error = '';
            self.addSubscriptionUpgrade(self.subscriptionUpgradeAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
