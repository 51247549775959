import { NoteAddMixin } from "./NoteAddMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const NoteUploadMixin = {
    mixins: [NoteAddMixin],
    computed: {
        note() {
            return this.notes.find(n => n.noteType.id === this.noteTypeId);
        },
        noteUpdateModel() {
            return !!this.note ? Object.assign({}, this.note, {
                text: this.noteText
            }) : {};
        },
        noteUpdateModelValid() {
            const model = this.noteUpdateModel;
            return !!model.id && !!model.ownerId && !!model.ownerType.id;
        },
        noteUpdateModelModified() {
            return JSON.stringify(this.noteUpdateModel) !== JSON.stringify(this.note);
        }
    },
    watch: {
        note() {
            this.refreshNoteUpdateModel();
        }
    },
    methods: {
        ...mapActions('Notes', [
            'updateNote'
        ]),
        refreshNoteUpdateModel() {
            this.noteText = !!this.note ? this.note.text : '';
        },
        tryUploadNote() {
            if (!!this.note) {
                this.tryUpdateNote();
            } else {
                this.tryAddNote();
            }
        },
        tryUpdateNote() {
            const self = this;
            if (!self.noteUpdateModelValid) {
                return;
            }
            if (!self.noteUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateNote({ key: self.noteCacheKey, payload: self.noteUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshNoteUpdateModel();
    }
}
