import { Api } from '../../services/Api';

const state = () => ({
    subscriptionPaymentSearches: []
})

const getters = {
    findSubscriptionPaymentSearch: (state) => key => state.subscriptionPaymentSearches.find(sp => sp.key === key),
    findSubscriptionPaymentSearches: (state) => (key) => state.subscriptionPaymentSearches.filter(sp => sp.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.subscriptionPaymentSearches.find(sp => sp.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.subscriptionPaymentSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setSubscriptionPaymentSearch(state, { key, subscriptionPayments }) {
        const existing = state.subscriptionPaymentSearches.find(sp => sp.key === key);
        if (!!existing) {
            existing.values = subscriptionPayments;
            existing.loading = false;
        } else {
            state.subscriptionPaymentSearches.push({ key: key, values: subscriptionPayments, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.subscriptionPaymentSearches.find(sp => sp.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearSubscriptionPaymentSearches(state) {
        state.subscriptionPaymentSearches = [];
    }
}

const actions = {
    searchSubscriptionPayments({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('SubscriptionPayment/Search', payload)
                .then(response => {
                    commit('setSubscriptionPaymentSearch', { key: key, subscriptionPayments: response.data });
                    commit('SubscriptionPayments/setSubscriptionPayments', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
