import { mapState } from 'vuex';
import { NoteAddMixin } from "./Notes/NoteAddMixin";

export const AppointmentScheduleNoteAddMixin = {
    mixins: [NoteAddMixin],
    computed: {
        ...mapState('AppointmentScheduleUpload', [
            'notes'
        ]),
        ownerTypeId() {
            return 8;
        },
        noteTypeId() {
            return 1;
        }
    },
    methods: {
        trySearchNotes() {
            // do nothing
        }
    }
}
