import { mapMutations, mapActions } from 'vuex';
import { Api } from '../../services/Api';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const StripeCheckoutMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        sessionUrl() {
            console.log('OVERRIDE COMPUTED - sessionUrl');
            return '';
        },
        paymentId() {
            return this.$route.params.paymentId;
        },
        sessionId() {
            return this.$route.query.sessionId;
        },
        onComplete() {
            return this.$route.query.onComplete;
        },
        siteId() {
            return this.$route.query.siteId;
        },
        sessionAddModel() {
            return {
                paymentId: this.paymentId,
                successUrl: window.location.href,
                cancelUrl: new URL(this.$router.resolve({
                    name: 'paymentCancel',
                    params: { paymentId: this.paymentId }
                }).href, window.location.origin)
            }
        },
        sessionAddModelValid() {
            const model = this.sessionAddModel;
            return !!this.sessionUrl && !!model.paymentId;
        },
        sessionUpdateModel() {
            return {
                paymentId: this.paymentId,
                sessionId: this.sessionId
            }
        },
        sessionUpdateModelValid() {
            const model = this.sessionUpdateModel;
            return !!model.paymentId && !!model.sessionId;
        }
    },
    methods: {
        ...mapMutations('Sites', [
            'setSelected'
        ]),
        ...mapActions('SubscriptionPayments', [
            'updateSubscriptionPayment'
        ]),
        beginSessionFlow() {
            if (!!this.sessionId) {
                this.tryCompleteSession();
            } else {
                this.tryCreateSession();
            }
        },
        tryCreateSession() {
            const self = this;
            if (!self.sessionAddModelValid) {
                self.error = 'Invalid Payment ID';
                return;
            }
            self.error = '';
            Api.post(self.sessionUrl, self.sessionAddModel)
                .then(response => window.open(response.data.url, '_self'))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryCompleteSession() {
            const self = this;
            if (!self.sessionUpdateModelValid) {
                self.error = 'Invalid Session';
                return;
            }
            self.error = '';
            self.updateSubscriptionPayment({ paymentId: self.paymentId, payload: self.sessionUpdateModel })
                .then(response => {
                    if (response.data.sessionCompleted) {
                        self.gotoComplete();
                    } else {
                        self.gotoRetry();
                    }
                })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        gotoComplete() {
            if (!!this.siteId) {
                this.setSelected(this.siteId);
            }
            this.$router.push({
                name: this.onComplete || 'store',
                query: { success: 'Payment completed!' }
            });
        },
        gotoRetry() {
            this.$router.push({
                name: 'store',
                query: { warning: 'Payment did not succeed. Please try again.' }
            });
        }
    },
    mounted() {
        this.beginSessionFlow();
    }
}
