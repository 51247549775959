import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    addingSubscriptionUpgrade: false,
    fetchedSubscriptionUpgrade: false,
    fetchingSubscriptionUpgrade: false,
    subscriptionUpgrade: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSubscriptionUpgrade = !!fetching;
        state.fetchedSubscriptionUpgrade = false;
    },
    setSubscriptionUpgrade(state, upgrade) {
        state.subscriptionUpgrade = upgrade;
        state.fetchingSubscriptionUpgrade = false;
        state.fetchedSubscriptionUpgrade = true;
    },
    setAdding(state, adding) {
        state.addingSubscriptionUpgrade = !!adding;
    },
    clearSubscriptionUpgrade(state) {
        state.subscriptionUpgrade = null;
    }
}

const actions = {
    fetchSubscriptionUpgrade({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Subscription/Upgrade')
                .then(response => {
                    commit('setSubscriptionUpgrade', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setSubscriptionUpgrade', null);
                        resolve(false);
                    } else {
                        commit('setFetching', false);
                        reject(error);
                    }
                });
        });
    },
    addSubscriptionUpgrade({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Subscription/Upgrade', payload)
                .then(response => {
                    commit('setSubscriptionUpgrade', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateSubscriptionUpgrade({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.put('Subscription/Upgrade', payload)
                .then(response => {
                    commit('setSubscriptionUpgrade', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    deleteSubscriptionUpgrade({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.delete('Subscription/Upgrade')
                .then(response => {
                    commit('clearSubscriptionUpgrade');
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
