<template>
    <div class="primary-nav" v-bind:class="{ 'shrink-nav': shrunk }">
        <div class="primary-nav-sticky">
            <a class="logo">
                <img alt="Shake Your Tail" src="../../assets/images/logo-colour-stacked.svg" width="130">
            </a>
            <nav-panel-mobile></nav-panel-mobile>
            <nav-panel-desktop></nav-panel-desktop>
            <div @click="shrunk=!shrunk" class="shrink-nav-toggle">
                <span>HIDE NAV</span>
            </div>
        </div>
    </div>
</template>

<script>
    import NavPanelDesktop from "./NavPanelDesktop.vue";
    import NavPanelMobile from "./NavPanelMobile.vue";
    import vueCookies from 'vue-cookies';

    export default {
        name: 'NavPanel',
        data() {
            return {
                shrunk: false
            }
        },
        watch: {
            shrunk(value) {
                vueCookies.set('nav-shrunk', value, -1, null, null, true, 'Strict');
            }
        },
        components: {
            'nav-panel-mobile': NavPanelMobile,
            'nav-panel-desktop': NavPanelDesktop
        },
        created() {
            this.shrunk = vueCookies.get('nav-shrunk') === 'true';
        }
    }
</script>
