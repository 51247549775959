<template>
    <div v-if="displaySite" class="form-group row">
        <div class="col-md-4">
            <label>{{$t('Site')}}</label>
        </div>
        <div class="col-md-8">
            <site-select :disabled="disabled"></site-select>
        </div>
    </div>
</template>

<script>
    import SiteSelect from '../Account/SiteSelect.vue';
    import { SiteCacheMixin } from './SiteCacheMixin';

    export default {
        name: 'SiteSelectColumn',
        mixins: [SiteCacheMixin],
        props: {
            disabled: Boolean
        },
        components: {
            'site-select': SiteSelect
        }
    }
</script>
