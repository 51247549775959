import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const AppointmentDepositCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentDeposits', [
            'findAppointmentDepositCache'
        ]),
        scheduleId() {
            console.log('OVERRIDE COMPUTED - scheduleId');
        },
        appointmentDepositFilterRequest() {
            return {
                scheduleId: this.scheduleId
            };
        },
        appointmentDepositCache() {
            return this.findAppointmentDepositCache(this.scheduleId);
        },
        appointmentDeposit() {
            return !!this.appointmentDepositCache ? this.appointmentDepositCache.value : null;
        },
        loadingAppointmentDeposit() {
            return !!this.appointmentDepositCache && this.appointmentDepositCache.loading;
        }
    },
    watch: {
        scheduleId() {
            this.refreshAppointmentDeposit();
        }
    },
    methods: {
        ...mapActions('AppointmentDeposits', [
            'fetchAppointmentDeposit'
        ]),
        refreshAppointmentDeposit() {
            if (!this.appointmentDepositCache || !!this.appointmentDepositCache.retry) {
                this.tryFetchAppointmentDeposit();
            }
        },
        tryFetchAppointmentDeposit() {
            const self = this;
            if (!self.scheduleId) {
                return;
            }
            self.error = '';
            self.fetchAppointmentDeposit({ key: self.scheduleId, payload: self.appointmentDepositFilterRequest })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentDeposit();
    }
}
