<template>
</template>

<script>
    import { SettingCacheMixin } from "../../components/Shared/SettingCacheMixin";
    import { SiteResourceEventColourSettingMixin } from "../../mixins/Settings/Site/SiteResourceEventColourSettingMixin"

    export default {
        name: 'SiteResourceEventColourSetting',
        mixins: [SettingCacheMixin, SiteResourceEventColourSettingMixin],
        watch: {
            settingObject() {
                this.emitSetting();
            }
        },
        methods: {
            emitSetting() {
                this.$emit('input', this.settingObject);
            }
        },
        created() {
            this.emitSetting();
        }
    }
</script>
