import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteBookingSettingMixin } from "./SiteBookingSettingMixin";

export const SiteBookingSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteBookingSettingMixin],
    data() {
        return {
            bookingEnabled: false,
            clientType: 1,
            reviewType: 1,
            serviceSelectEnabled: false,
            pricingType: 1,
            multipleServiceEnabled: true,
            minNotice: 1,
            minNoticeUnit: 'days',
            maxNotice: 30,
            maxNoticeUnit: 'days',
            singlePetResourceEnabled: true,
            maxPets: 6,
            maxServices: 4,
            displayPetProfileImage: false,
            blockOffEvents: true
        }
    },
    computed: {
        settingEditModel() {
            return {
                bookingEnabled: this.bookingEnabled,
                clientType: this.clientType,
                reviewType: this.reviewType,
                serviceSelectEnabled: this.serviceSelectEnabled,
                pricingType: this.pricingType,
                multipleServiceEnabled: this.multipleServiceEnabled,
                minNotice: this.minNotice,
                minNoticeUnit: this.minNoticeUnit,
                maxNotice: this.maxNotice,
                maxNoticeUnit: this.maxNoticeUnit,
                singlePetResourceEnabled: this.singlePetResourceEnabled,
                maxPets: this.maxPets,
                maxServices: this.maxServices,
                displayPetProfileImage: this.displayPetProfileImage,
                blockOffEvents: this.blockOffEvents
            }
        },
        selectedMinNoticeUnit() {
            return this.noticeUnitOptions.find(nuo => nuo.value === this.minNoticeUnit);
        },
        selectedMaxNoticeUnit() {
            return this.noticeUnitOptions.find(nuo => nuo.value === this.maxNoticeUnit);
        },
        calculatedMinNotice() {
            return (!!this.selectedMinNoticeUnit ? this.selectedMinNoticeUnit.multiplier : 0) * this.minNotice;
        },
        calculatedMaxNotice() {
            return (!!this.selectedMaxNoticeUnit ? this.selectedMaxNoticeUnit.multiplier : 0) * this.maxNotice;
        },
        settingEditModelValid() {
            return this.calculatedMaxNotice > this.calculatedMinNotice && this.maxPets > 0 && this.maxPets < 11 && this.maxServices > 0 && this.maxServices < 11;
        }
    },
    methods: {
        setClientType(value) {
            this.clientType = value;
        },
        setReviewType(value) {
            this.reviewType = value;
        },
        setPricingType(value) {
            this.pricingType = value;
        },
        setMinNoticeUnit(value) {
            this.minNoticeUnit = value
        },
        setMaxNoticeUnit(value) {
            this.maxNoticeUnit = value;
        },
        refreshSettingEditModel() {
            this.bookingEnabled = this.settingObject.bookingEnabled;
            this.clientType = this.settingObject.clientType;
            this.reviewType = this.settingObject.reviewType;
            this.serviceSelectEnabled = this.settingObject.serviceSelectEnabled;
            this.pricingType = this.settingObject.pricingType;
            this.multipleServiceEnabled = this.settingObject.multipleServiceEnabled;
            this.minNotice = this.settingObject.minNotice;
            this.minNoticeUnit = this.settingObject.minNoticeUnit;
            this.maxNotice = this.settingObject.maxNotice;
            this.maxNoticeUnit = this.settingObject.maxNoticeUnit;
            this.singlePetResourceEnabled = this.settingObject.singlePetResourceEnabled;
            this.maxPets = this.settingObject.maxPets;
            this.maxServices = this.settingObject.maxServices;
            this.displayPetProfileImage = this.settingObject.displayPetProfileImage;
            this.blockOffEvents = this.settingObject.blockOffEvents;
        }
    }
}
