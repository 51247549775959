<template>
    <collapsed-static id="pets-details" :title="$t('Pet(s) Details')">
        <pet-details v-for="(participant, index) in bookingParticipants"
                     :participant="participant"
                     :index="index"
                     :client-pets="clientPets"
                     :key="participant.id">
        </pet-details>
    </collapsed-static>
</template>

<script>
    import { BookingCacheMixin } from "../Shared/BookingCacheMixin";
    import CollapsedStaticSection from '../Shared/Layout/CollapsedStaticSection.vue';
    import BookingPetDetails from "./BookingPetDetails.vue";

    export default {
        name: 'BookingPets',
        mixins: [BookingCacheMixin],
        props: {
            id: '',
            clientPets: []
        },
        computed: {
            bookingId() {
                return !!this.id ? this.id : '';
            }
        },
        components: {
            'collapsed-static': CollapsedStaticSection,
            'pet-details': BookingPetDetails
        }
    }
</script>
