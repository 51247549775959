<template>
    <td>
        <span v-if="opening" class="inline-loader"></span>
        <template v-else>
            <a @click="opening=true" class="link pointer">{{contract.templateName}}</a>
        </template>
    </td>
</template>

<script>
    import { DocumentCacheMixin } from '../../Shared/DocumentCacheMixin.js';
    import process from 'process';

    export default {
        name: 'ClientDocumentOpen',
        mixins: [DocumentCacheMixin],
        data() {
            return {
                opening: false
            }
        },
        props: {
            contract: Object
        },
        computed: {
            ownerId() {
                return !!this.contract && this.opening ? this.contract.id : '';
            },
            ownerTypeId() {
                return 11;
            },
            document() {
                return this.documents.length > 0 ? this.documents[0] : null;
            }
        },
        watch: {
            document(value) {
                if (!!value && this.opening) {
                    this.opening = false;
                    process.nextTick(() => window.open(value.href, '_blank'));
                }
            },
            error(value) {
                if (!!value) {
                    this.opening = false;
                }
            }
        }
    }
</script>
