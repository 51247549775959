import router from '../../routing';
import { mapGetters, mapMutations } from 'vuex';
import { SiteCacheMixin } from '../Shared/SiteCacheMixin';
import { NoteCacheMixin } from '../Shared/Notes/NoteCacheMixin';

export const ClientItemMixin = {
    mixins: [SiteCacheMixin, NoteCacheMixin],
    props: {
        client: Object
    },
    computed: {
        ...mapGetters('PetsV2', [
            'findPetCache'
        ]),
        clientId() {
            return !!this.client ? this.client.id : '';
        },
        fullName() {
            return !!this.client ? this.client.fullName : '';
        },
        firstName() {
            return !!this.client ? this.client.firstName : '';
        },
        lastName() {
            return !!this.client ? this.client.lastName : '';
        },
        startLetter() {
            return this.lastName.toUpperCase()[0];
        },
        mobile() {
            return !!this.client ? this.client.telephoneMobile : '';
        },
        mobileCell() {
            return this.mobile ? this.mobile : 'N/A';
        },
        email() {
            return !!this.client ? this.client.email : '';
        },
        emailCell() {
            return this.email ? this.email : 'N/A';
        },
        petCache() {
            return this.findPetCache(this.clientId);
        },
        pets() {
            return !!this.petCache ? this.petCache.values : [];
        },
        petNames() {
            return this.pets.map(p => p.name);
        },
        petString() {
            return this.petNames.join(', ');
        },
        siteId() {
            return !!this.client ? this.client.siteId : '';
        },
        showAlert() {
            return !!this.note && !!this.note.text;
        },
        ownerId() {
            return this.clientId;
        },
        ownerTypeId() {
            return 2;
        },
        noteTypeId() {
            return 4;
        },
        note() {
            return this.notes.find(n => n.noteType.id === 4);
        },
        noteText() {
            return this.note ? this.note.text : '';
        }
    },
    methods: {
        ...mapMutations('Clients', [
            'setClient'
        ]),
        gotoClient: function () {
            if (!!this.client) {
                this.setClient(this.client);
                router.push({ name: 'clientEdit', params: { id: this.client.id } });
            }
        }
    }
}
