<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{bookingFullname}}</h1>
                <p class="d-none d-md-block mb-0"><i class="fas fa-envelope"></i> {{bookingEmail}}</p>
                <p class="d-block d-md-none text-white mb-0"><i class="fas fa-envelope"></i> {{bookingEmail}}</p>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <!--placeholder to occupy header-tools area-->
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <div v-if="!enabled">
                <div class="alert-wrapper px-0">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t('This feature is currently disabled.')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="bookingStatusId === 2 || bookingStatusId === 3" class="alert-wrapper px-0">
                <div role="alert" class="alert alert-icon" v-bind:class="bookingStatusClass">
                    <div class="alert-content">
                        <p>{{$t('Booking request')}} {{$t(bookingStatusDescription)}}.</p>
                    </div>
                </div>
            </div>
            <div v-if="bookingHistoryTotal === 1">
                <div class="alert-wrapper px-0">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t('This is a new booking request from a new client.')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <error-banner v-if="errors" :message="errors"></error-banner>
            <div class="row">
                <div class="col-12 col-md-auto">
                    <div class="client-details-left sidenav">
                        <add-schedule :id="bookingId" :participants="addedParticipants" v-model="scheduleStatus"></add-schedule>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-md-8">
                            <add-client :id="bookingId" v-model="clientStatus"></add-client>
                            <add-pets :id="bookingId" v-model="petsStatus" @participantsAdded="addParticipants"></add-pets>
                            <add-orders :id="bookingId" :participants="addedParticipants" v-model="ordersStatus"></add-orders>
                            <booking-approve :id="bookingId" :orders-status="ordersStatus" v-model="approveStatus"></booking-approve>
                            <booking-conflicts v-if="bookingStatusId === 1" :id="bookingId"></booking-conflicts>
                            <booking-suggestions v-if="bookingStatusId === 3" :id="bookingId"></booking-suggestions>
                        </div>
                        <div class="col-md-4">
                            <add-client-notification-settings v-model="notificationSettingsStatus"></add-client-notification-settings>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-action-btns action-btns fixed-action-btns--nonav d-flex flex-md-row flex-wrap justify-content-md-between">
                <div class="fixed-action-btns-left">
                    <button @click="goBack" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
                <template v-if="enabled">
                    <template v-if="bookingStatusId === 1">
                        <div class="fixed-action-btns-center">
                            <button @click="triggerApproveBooking" class="btn btn-primary" type="button" v-bind:disabled="loading">
                                {{$t('Approve')}}
                                <span v-if="loading && !declineStatus.loading" class="inline-loader"></span>
                            </button>
                            <button @click="triggerDeclineBooking" class="btn btn-secondary" type="button" v-bind:disabled="loading || !!declineStatus.loading || completed">
                                {{$t('Decline')}}
                                <span v-if="!!declineStatus.loading" class="inline-loader"></span>
                            </button>
                        </div>
                    </template>
                </template>
                <div class="fixed-action-btns-right">
                    <delete-button @clicked="remove"></delete-button>
                </div>
            </div>
        </div>
        <booking-decline-modal :id="bookingId" v-model="declineStatus"></booking-decline-modal>
    </div>
</template>

<script>
    import router from '../../routing';
    import { mapMutations } from 'vuex';
    import { BookingCacheMixin } from '../Shared/BookingCacheMixin';
    import { BookingSettingsCacheMixin } from '../../mixins/Bookings/BookingSettingsCacheMixin';
    import { BookingHistoryCacheMixin } from '../Shared/BookingHistoryCacheMixin';
    import BookingAddClient from './BookingAddClient.vue';
    import BookingAddClientNotificationSettings from './BookingAddClientNotificationSettings.vue';
    import BookingAddPets from './BookingAddPets.vue';
    import BookingAddAppointmentSchedule from './BookingAddAppointmentSchedule.vue';
    import BookingAddAppointmentScheduleOrders from './BookingAddAppointmentScheduleOrders.vue';
    import BookingConflicts from './BookingConflicts.vue';
    import BookingSuggestions from './BookingSuggestions.vue';
    import BookingApprove from './BookingApprove.vue';
    import BookingDeclineModal from './BookingDeclineModal.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';

    export default {
        name: 'BookingAnonymous',
        mixins: [BookingCacheMixin, BookingSettingsCacheMixin, BookingHistoryCacheMixin],
        data() {
            return {
                addedParticipants: [],
                clientStatus: {},
                petsStatus: {},
                ordersStatus: {},
                scheduleStatus: {},
                approveStatus: {},
                declineStatus: {},
                notificationSettingsStatus: {}
            }
        },
        computed: {
            bookingId() {
                return this.$route.params.id;
            },
            bookingStatusId() {
                return this.booking ? this.booking.status.id : 0;
            },
            bookingStatusDescription() {
                return this.booking ? this.booking.status.description.toUpperCase() : "-";
            },
            bookingStatusClass() {
                return this.bookingStatusId === 2 ? 'alert-success' : this.bookingStatusId === 3 ? 'alert-danger' : '';
            },
            bookingEmail() {
                return this.booking ? this.booking.email : '';
            },
            statuses() {
                return [
                    this.clientStatus,
                    this.petsStatus,
                    this.ordersStatus,
                    this.scheduleStatus,
                    this.approveStatus,
                    this.notificationSettingsStatus
                ]
            },
            completed() {
                return this.statuses.every(s => !!s.completed);
            },
            loading() {
                return this.statuses.some(s => !!s.loading) || !!this.loadingBooking || !!this.loadingBookingHistory || !!this.declineStatus.loading
            },
            errors() {
                const statusErrors = this.statuses.map(s => s.error).filter(error => !!error);
                if (!!this.error) {
                    statusErrors.push(this.error);
                }
                return statusErrors.join(' ');
            }
        },
        watch: {
            booking() {
                this.setSite();
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            ...mapMutations('ApplicationEvents', [
                'triggerApproveBooking',
                'triggerDeleteBooking',
                'triggerDeclineBooking'
            ]),
            goBack() {
                router.push({ name: 'bookings' });
            },
            setSite() {
                if (!!this.booking) {
                    this.setSelected(this.booking.siteId);
                }
            },
            remove() {
                this.triggerDeleteBooking();
            },
            addParticipants(value) {
                this.addedParticipants = value;
            }
        },
        components: {
            'add-client': BookingAddClient,
            'add-client-notification-settings': BookingAddClientNotificationSettings,
            'add-pets': BookingAddPets,
            'add-schedule': BookingAddAppointmentSchedule,
            'add-orders': BookingAddAppointmentScheduleOrders,
            'booking-conflicts': BookingConflicts,
            'booking-suggestions': BookingSuggestions,
            'booking-approve': BookingApprove,
            'booking-decline-modal': BookingDeclineModal,
            'error-banner': ErrorBanner,
            'delete-button': DeleteButton
        },
        created() {
            if (!this.bookingId) {
                router.push({ name: 'bookings' });
            } else {
                this.setSite();
            }
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 768px) {
        .fixed-action-btns-left {
            width: 50%;
            order: 2;
            text-align: left;
        }
        .fixed-action-btns-center {
            width: 100%;
            order: 1;
            text-align: center;
            margin-bottom: 0.5rem;
        }
        .fixed-action-btns-right {
            width: 50%;
            order: 3;
            text-align: right;
        }
    }
</style>