<template>
    <tr class="link pointer" v-bind:class="classType" @click="onClick">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a class="text-truncate">{{firstName}}</a>
            </div>
        </td>
        <td class="text-truncate">{{lastName}}</td>
        <td class="text-truncate">{{userEmail}}</td>
        <td>{{$t(userRole)}}</td>
        <td class="text-truncate">{{sitesString}}</td>
    </tr>
</template>

<script>
    import { UserItemMixin } from "./UserItemMixin";

    export default {
        name: 'UserItemDesktop',
        mixins: [UserItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
