import { Api } from '../../services/Api';

const state = () => ({
    communicationSearches: []
})

const getters = {
    findCommunicationSearch: (state) => key => state.communicationSearches.find(cs => cs.key === key),
    findCommunicationSearches: (state) => (key) => state.communicationSearches.filter(cs => cs.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.communicationSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.communicationSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCommunicationSearch(state, { key, communications }) {
        const existing = state.communicationSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.values = communications;
            existing.loading = false;
        } else {
            state.communicationSearches.push({ key: key, values: communications, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.communicationSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearCommunicationSearches(state) {
        state.communicationSearches = [];
    }
}

const actions = {
    searchCommunications({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Communication/Search', payload)
                .then(response => {
                    commit('setCommunicationSearch', { key: key, communications: response.data });
                    commit('Communications/setCommunications', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
