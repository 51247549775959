import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper.js';
import { CountryCacheMixin } from './Country/CountryCacheMixin.js';
import { SmsCalculatorSettingsCacheMixin } from './SmsCalculatorSettingsCacheMixin.js';

export const SmsUnitCostCacheMixin = {
    mixins: [CountryCacheMixin, SmsCalculatorSettingsCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SmsUnitCost', [
            'findSmsUnitCostCache'
        ]),
        siteCountry() {
            return {
                siteId: this.siteId,
                alpha2Code: this.alpha2Code
            }
        },
        siteCountryValid() {
            const model = this.siteCountry;
            return !!model.siteId && !!model.alpha2Code;
        },
        smsUnitCostCacheKey() {
            return CacheHelper.objectCacheKey(this.siteCountry);
        },
        smsUnitCostsCache() {
            return this.findSmsUnitCostCache(this.smsUnitCostCacheKey);
        },
        smsUnitCost() {
            return !!this.smsUnitCostsCache ? this.smsUnitCostsCache.value : null;
        },
        unitCost() {
            return !!this.smsUnitCost ? this.smsUnitCost.unitCost : 0;
        },
        loadingSmsUnitCost() {
            return !!this.smsUnitCostsCache && this.smsUnitCostsCache.loading;
        }
    },
    watch: {
        siteCountry() {
            this.refreshSmsUnitCost();
        },
        fetchedSmsCalculatorSettings() {
            this.refreshSmsUnitCost();
        }
    },
    methods: {
        ...mapActions('SmsUnitCost', [
            'fetchSmsUnitCost'
        ]),
        refreshSmsUnitCost() {
            if (!this.smsUnitCostsCache || !!this.smsUnitCostsCache.retry) {
                this.tryFetchSmsUnitCost();
            }
        },
        tryFetchSmsUnitCost() {
            const self = this;
            if (!self.siteCountryValid || !self.enabled) {
                return;
            }
            self.error = '';
            self.fetchSmsUnitCost({ key: self.smsUnitCostCacheKey })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsUnitCost();
    }
}
