<template>
    <collapsed-section v-if="display" id="collapseCheckout" title="Payments">
        <error-banner v-if="error" :message="error"></error-banner>
        <fieldset :disabled="disabled">
            <checkout-list :reference="reference" :isRefunder="isPaymentRefunder"></checkout-list>
        </fieldset>
    </collapsed-section>
</template>

<script>
    import CheckoutList from "../../Payments/CheckoutList.vue";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { PaymentApiUserCacheMixin } from "../../../mixins/Payments/PaymentApiUserCacheMixin";

    export default {
        name: 'AppointmentOverviewCheckout',
        mixins: [PaymentApiUserCacheMixin],
        props: {
            appointment: Object,
            disabled: Boolean
        },
        computed: {
            display() {
                return this.isPaymentAdmin && !!this.appointment;
            },
            reference() {
                return !!this.appointment ? this.appointment.scheduleId + this.appointment.sequenceNumber : '';
            }
        },
        components: {
            'checkout-list': CheckoutList,
            'collapsed-section': CollapsedSection,
            'error-banner': ErrorBanner
        }
    }
</script>
