import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const SubscriptionPaymentCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SubscriptionPayments', [
            'findSubscriptionPaymentCache'
        ]),
        paymentId() {
            console.log('OVERRIDE COMPUTED - paymentId');
            return '';
        },
        subscriptionPaymentCache() {
            return this.findSubscriptionPaymentCache(this.paymentId);
        },
        subscriptionPayment() {
            return !!this.subscriptionPaymentCache ? this.subscriptionPaymentCache.value : null;
        },
        loadingSubscriptionPayment() {
            return !!this.subscriptionPaymentCache && this.subscriptionPaymentCache.loading;
        }
    },
    watch: {
        paymentId() {
            this.refreshSubscriptionPayment();
        }
    },
    methods: {
        ...mapActions('SubscriptionPayments', [
            'fetchSubscriptionPayment'
        ]),
        tryFetchSubscriptionPayment() {
            const self = this;
            if (!self.paymentId) {
                return;
            }
            self.error = '';
            self.fetchSubscriptionPayment(self.paymentId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshSubscriptionPayment() {
            if (!this.subscriptionPaymentCache || !!this.subscriptionPaymentCache.retry) {
                this.tryFetchSubscriptionPayment();
            }
        }
    },
    created() {
        this.refreshSubscriptionPayment();
    }
}
