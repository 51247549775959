import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapState, mapActions } from 'vuex';

export const SubscriptionUpgradeCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionUpgrade', [
            'fetchedSubscriptionUpgrade',
            'fetchingSubscriptionUpgrade',
            'subscriptionUpgrade'
        ])
    },
    methods: {
        ...mapActions('SubscriptionUpgrade', [
            'fetchSubscriptionUpgrade'
        ]),
        refreshSubscriptionUpgrade() {
            if (!this.fetchedSubscriptionUpgrade && !this.fetchingSubscriptionUpgrade) {
                this.tryFetchSubscriptionUpgrade();
            }
        },
        tryFetchSubscriptionUpgrade() {
            const self = this;
            self.error = '';
            self.fetchSubscriptionUpgrade()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionUpgrade();
    }
}
