import { ArrayHelper } from "../../helpers/ArrayHelper";
import { Api } from "../../services/Api";

const state = () => ({
    orderCaches: []
})

const getters = {
    findOrderCache: state => key => {
        return state.orderCaches.find(oc => oc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.orderCaches.find(oc => oc.key === key);
        if (existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.orderCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setAllLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.orderCaches.find(oc => oc.key === key);
            if (existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.orderCaches.push({ key: key, values: [], loading: !!loading });
            }
        }
    },
    setOrders(state, { key, orders }) {
        const existing = state.orderCaches.find(oc => oc.key === key);
        if (!!existing) {
            const index = state.orderCaches.indexOf(existing);
            state.orderCaches.splice(index, 1, Object.assign({}, existing, { values: orders, loading: false }));
        } else {
            state.orderCaches.push({ key: key, values: orders, loading: false });
        }
    },
    setAllOrders(state, orders) {
        const orderGroups = ArrayHelper.groupBy(orders, 'appointmentId');
        for (var i = 0; i < orderGroups.length; i++) {
            const group = orderGroups[i];
            const existing = state.orderCaches.find(oc => oc.key === group.key);
            if (!!existing) {
                const index = state.orderCaches.indexOf(existing);
                state.orderCaches.splice(index, 1, Object.assign({}, existing, { values: group.values, loading: false }));
            }
        }
    },
    setRetry(state, key) {
        const existing = state.orderCaches.find(oc => oc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setAllRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.orderCaches.find(oc => oc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    },
    appendOrder(state, { key, order }) {
        const existing = state.orderCaches.find(oc => oc.key === key);
        if (!!existing) {
            existing.values.push(order);
        } else {
            state.orderCaches.push({ key: key, values: [order], loading: false });
        }
    },
    insertOrder(state, { key, order }) {
        const existing = state.orderCaches.find(oc => oc.key === key);
        if (!!existing) {
            const value = existing.values.find(o => o.id === order.id);
            if (!!value) {
                const index = existing.values.indexOf(value);
                existing.values.splice(index, 1, order);
            } else {
                existing.values.push(order);
            }
        } else {
            state.orderCaches.push({ key: key, values: [order], loading: false });
        }
    }
}

const actions = {
    findOrders({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Order/Search', payload)
                .then(response => {
                    commit('setOrders', { key: key, orders: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    findAllOrders({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAllLoading', { keys: keys, loading: true });
            Api.post('Order/Search', payload)
                .then(response => {
                    commit('setAllOrders', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setAllRetry', keys);
                    reject(error);
                })
                .finally(() => commit('setAllLoading', { keys: keys, loading: false }));
        });
    },
    addOrder({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Order', payload)
                .then(response => {
                    commit('appendOrder', { key: key, order: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateOrder({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Order', payload)
                .then(response => {
                    commit('insertOrder', { key: key, order: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateScheduleOrders({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Schedule/Order', payload)
                .then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        const order = response.data[i];
                        commit('insertOrder', { key: order.appointmentId, order: order });
                    }
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
