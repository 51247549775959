export const CampaignHelper = {
    getCampaignAddValidator() {
        return {
            isValid: false,
            subject: '',
            templateId: '',
            siteIds: '',
            check(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.templateId = !!model.templateId ? '' : 'Please select a template';
                this.siteIds = model.siteIds.length > 0 ? '' : 'Please select at least one site';
                this.isValid = !this.subject && !this.templateId && !this.siteIds;
            }
        }
    },
    getCampaignUpdateValidator() {
        return {
            isValid: false,
            subject: '',
            siteIds: '',
            check(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.siteIds = model.siteIds.length > 0 ? '' : 'Please select at least one site';
                this.isValid = !this.subject && !this.siteIds;
            }
        }
    },
    getEmailCampaignTemplateValidator() {
        return {
            isValid: false,
            subject: '',
            check(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.isValid = !this.subject;
            }
        }
    },
    getSmsCampaignTemplateValidator() {
        return {
            isValid: false,
            subject: '',
            body: '',
            check(model) {
                this.subject = !!model.subject ? '' : 'Please enter a subject';
                this.body = !!model.body ? '' : 'Please enter a body';
                this.isValid = !this.subject && !this.body;
            }
        }
    }
}
