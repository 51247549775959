<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" v-observe-visibility=isVisible>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content lg-bg">
                <div class="modal-header">
                    <h5 class="modal-title text-break">{{title}}</h5>
                    <h4 v-if="subTitle" class="modal-subtitle">{{subTitle}}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="wasOpen" class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                wasOpen: false
            }
        },
        props: {
            title: String,
            subTitle: String
        },
        methods: {
            isVisible(value) {
                if (value) {
                    this.wasOpen = true;
                    this.$emit("opened");
                } else if (this.wasOpen) {
                    this.wasOpen = false;
                    this.$emit("closed");
                }
            }
        }
    }
</script>
