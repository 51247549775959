<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-6" data-toggle="collapse">
            <i class="fas fa-message-sms mr-2"></i>
            SMS Credits
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-6">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>{{addedAmount}} SMS Credits Added!</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span>x</span>
                    </button>
                </div>
                <div class="form-group">
                    <label>Account ID</label>
                    <input v-model="accountId" class="form-control" type="text">
                    <validation-message v-if="!!accountIdMessage" :message="accountIdMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Site ID</label>
                    <input v-model="siteId" class="form-control" type="text">
                    <validation-message v-if="!!siteIdMessage" :message="siteIdMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Number of Credits</label>
                    <input v-model="amount" class="form-control text-right" type="number">
                    <validation-message v-if="!!amountMessage" :message="amountMessage"></validation-message>
                </div>
                <div class="form-group">
                    <button @click="submit" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Add
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";

    export default {
        name: 'SystemAdminSmsCredits',
        data() {
            return {
                accountId: '',
                siteId: '',
                amount: 1,
                addedAmount: 1,
                loading: false,
                completed: false,
                error: '',
                accountIdMessage: '',
                siteIdMessage: '',
                amountMessage: ''
            }
        },
        computed: {
            smsCreditsAddModel() {
                return {
                    accountId: this.accountId,
                    siteId: this.siteId,
                    amount: parseInt(this.amount)
                }
            },
            smsCreditsAddModelValid() {
                const model = this.smsCreditsAddModel;
                return !!model.accountId && !!model.siteId && model.amount >= 1;
            }
        },
        methods: {
            submit() {
                const self = this;
                self.completed = false;
                self.error = '';
                self.validate();
                if (!self.smsCreditsAddModelValid) {
                    return;
                }
                self.loading = true;
                Api.post('SmsCredit', self.smsCreditsAddModel)
                    .then(() => {
                        self.completed = true;
                        self.clearSmsCreditsAddModel();
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            clearSmsCreditsAddModel() {
                this.accountId = '';
                this.siteId = '';
                this.addedAmount = parseInt(this.amount);
                this.amount = 1;
            },
            validate() {
                const self = this;
                const model = self.smsCreditsAddModel
                self.accountIdMessage = !!model.accountId ? '' : 'Please enter Account ID.';
                self.siteIdMessage = !!model.siteId ? '' : 'Please enter Site ID.';
                self.amountMessage = model.amount >= 1 ? '' : 'Minimum amount required is 1.';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
