<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-for="provider in paymentProviders" class="d-flex flex-column flex-md-row justify-content-start align-items-center">
            <provider-info :provider="provider" @connect="tryConnect"></provider-info>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { PaymentApi } from "../../services/PaymentApi";
    import { PaymentProvidersCacheMixin } from "./PaymentProvidersCacheMixin";
    import ProviderInfo from "./ProviderInfo.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'ProviderInfoList',
        mixins: [PaymentProvidersCacheMixin],
        props: {
            value: Boolean
        },
        watch: {
            fetchingPaymentProviders(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            tryConnect(id) {
                const self = this;
                PaymentApi.post('OAuth/Connect', { providerTypeId: id })
                    .then(response => window.open(response.data.url, '_self'))
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        components: {
            'provider-info': ProviderInfo,
            'error-banner': ErrorBanner
        }
    }
</script>
