import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AppointmentUpdateMixin } from "./AppointmentUpdateMixin";
import { ScheduleUpdateConflictsMixin } from './ScheduleUpdateConflictsMixin';

export const AppointmentScheduleUpdateMixin = {
    mixins: [AppointmentUpdateMixin, ScheduleUpdateConflictsMixin],
    data() {
        return {
            updateTypeId: 0,
            overrides: [],
            previousScheduleUpdate: null
        }
    },
    computed: {
        appointmentScheduleUpdateModel() {
            return {
                updateTypeId: this.updateTypeId,
                overrides: this.overrides,
                appointment: this.appointmentUpdateModel,
            };
        },
        appointmentScheduleUpdateModelValid() {
            return !!this.appointmentScheduleUpdateModel.appointment && this.appointmentUpdateModelValid;
        },
        appointmentScheduleUpdateModelModified() {
            return JSON.stringify(this.previousScheduleUpdate) !== JSON.stringify(this.scheduleUpdate) || this.appointmentUpdateModelModified;
        },
        scheduleUpdate() {
            return {
                updateTypeId: this.updateTypeId,
                overrides: this.overrides
            }
        }
    },
    watch: {
        appointmentUpdateModel() {
            this.overrides = [];
        },
        appointment() {
            this.refreshSchedule();
        }
    },
    methods: {
        ...mapActions('Appointments', [
            'updateAppointments'
        ]),
        refreshSchedule() {
            this.overrides = [];
            this.previousScheduleUpdate = Object.assign({}, this.scheduleUpdate);
        },
        tryUpdateAppointments() {
            const self = this;
            if (!self.appointmentScheduleUpdateModelValid) {
                return;
            }
            if (!self.appointmentScheduleUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateAppointments({ key: self.appointmentId, payload: self.appointmentScheduleUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSchedule();
    }
}
