import { mapState } from 'vuex';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
import { EmailTemplateSettingAddMixin } from "./EmailTemplateSettingAddMixin";

export const EmailTemplateSettingUploadMixin = {
    mixins: [EmailTemplateSettingAddMixin, SiteIdMixin],
    computed: {
        ...mapState('EmailTemplateSettings', [
            'updatingEmailTemplateSettings'
        ]),
        loadingSetting() {
            return this.updatingEmailTemplateSettings || this.addingEmailTemplateSettings || this.loadingEmailTemplateSettings;
        }
    },
    methods: {
        tryUploadEmailTemplateSetting() {
            if (!!this.emailTemplateSetting) {
                return;
            } else {
                this.tryAddEmailTemplateSetting();
            }
        }
    }
}
