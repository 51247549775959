import { Api } from "../../services/Api";
import moment from 'moment';

const state = () => ({
    fetchingReports: false,
    fetchedReports: false,
    reportsLastFetched: null,
    reportsTimeoutId: null,
    reports: [],
    addingReport: false
})

const mutations = {
    setFetching(state) {
        state.fetchedReports = false;
        state.fetchingReports = true;
    },
    setReports(state, reportTypes) {
        state.reports = reportTypes;
        state.fetchedReports = true;
        state.fetchingReports = false;
        state.reportsLastFetched = moment();
    },
    setError(state) {
        state.fetchedReports = false;
        state.fetchingReports = false;
    },
    setTimeoutId(state, id) {
        state.reportsTimeoutId = id;
    },
    setAdding(state, adding) {
        state.addingReport = !!adding;
    },
    appendReport(state, report) {
        state.reports.push(report);
    }
}

const actions = {
    fetchReports({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Report')
                .then(response => {
                    commit('setReports', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setError');
                    reject(error);
                });
        });
    },
    addReport({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Report', payload)
                .then(response => {
                    commit('appendReport', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
