<template>
    <table-mobile @next="next">
        <service-mobile v-for="product in products" :key="product.id" :product="product" @productClicked="onProductClicked"></service-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import ServiceItemMobile from "./ServiceItemMobile.vue";

    export default {
        name: 'ServicesMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('ServiceProductSearches', [
                'findServiceProductSearches'
            ]),
            productSearches() {
                return this.findServiceProductSearches(this.searchKey);
            },
            products() {
                return [].concat(...this.productSearches.map(ps => ps.values))
            },
            loading() {
                return this.productSearches.some(ps => !!ps.loading);
            }
        },
        methods: {
            onProductClicked(product) {
                this.$emit('productClicked', product);
            },
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.products.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'service-mobile': ServiceItemMobile
        }
    }
</script>
