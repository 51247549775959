import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SubscriptionUpgradeAddMixin } from './SubscriptionUpgradeAddMixin';
import { Api } from '../../services/Api';

export const SubscriptionUpgradeCheckAllowanceMixin = {
    mixins: [SubscriptionUpgradeAddMixin],
    data() {
        return {
            checkingAllowance: false,
            upgradeAllowed: true,
            message: ''
        }
    },
    computed: {
        showCloseAccountBanner() {
            return !!this.message && this.subscriptionTypeId === 2
        }
    },
    methods: {
        tryCheckAllowance() {
            const self = this;
            if (!self.subscriptionUpgradeAddModelValid || !!self.checkingAllowance) {
                return;
            }
            self.checkingAllowance = true;
            self.message = '';
            self.error = '';
            self.upgradeAllowed = false;
            Api.post('Subscription/Upgrade/Check', self.subscriptionUpgradeAddModel)
                .then(response => {
                    var responseData = response.data
                    self.upgradeAllowed = !!responseData.allowed;
                    self.message = responseData.message;
                    self.afterCheckingAllowance();
                }).catch(error => {
                    self.upgradeAllowed = true;
                    self.error = ErrorHelper.getError(error);
                })
                .finally(() => { self.checkingAllowance = false; })
        },
        afterCheckingAllowance() {
            //override if necessary
        }
    }
}
