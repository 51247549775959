import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const SmsTemplateTagsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        communicationTypeId: Number
    },
    computed: {
        ...mapGetters('SmsTemplateTags', [
            'getSmsTemplateTagCache'
        ]),
        smsTemplateTagsQueryModel() {
            return {
                communicationTypeId: this.communicationTypeId
            }
        },
        smsTemplateTagsQueryModelValid() {
            return !!this.smsTemplateTagsQueryModel.communicationTypeId;
        },
        smsTemplateTagsCacheKey() {
            return this.communicationTypeId;
        },
        smsTemplateTagsCache() {
            return this.getSmsTemplateTagCache(this.smsTemplateTagsCacheKey);
        },
        smsTemplateTags() {
            return !!this.smsTemplateTagsCache ? this.smsTemplateTagsCache.values : [];
        },
        loadingSmsTemplateTags() {
            return !!this.smsTemplateTagsCache && this.smsTemplateTagsCache.loading;
        }
    },
    watch: {
        smsTemplateTagsQueryModel() {
            this.refreshSmsTemplateTags();
        }
    },
    methods: {
        ...mapActions('SmsTemplateTags', [
            'findSmsTemplateTags'
        ]),
        refreshSmsTemplateTags() {
            if (!this.smsTemplateTagsCache || !!this.smsTemplateTagsCache.retry) {
                this.findAndCacheSmsTemplateTags();
            }
        },
        findAndCacheSmsTemplateTags() {
            const self = this;
            if (!self.smsTemplateTagsQueryModelValid) {
                return;
            }
            self.error = '';
            self.findSmsTemplateTags({ key: self.smsTemplateTagsCacheKey, payload: self.smsTemplateTagsQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsTemplateTags();
    }
}