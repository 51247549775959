<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label for="title">{{$t('When')}}</label>
        </div>
        <div class="col-md-8">
            <picker v-model="startUtc"></picker>
        </div>
    </div>
</template>

<script>
    import EditDuration from './EditDuration.vue';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin';
    import DateTimePicker from '../../Shared/Time/DateTimePicker.vue';

    export default {
        name: 'EditDateSelect',
        mixins: [DateDisplayMixin],
        props: {
            value: String
        },
        computed: {
            startUtc: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'picker': DateTimePicker,
            'edit-duration': EditDuration
        }
    }
</script>
