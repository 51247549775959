import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { Api } from "../../../services/Api";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const SelfNotificationAddMixin = {
    mixins: [SiteIdMixin],
    computed: {
        notificationTypeId() {
            console.log('!!!OVERRIDE COMPUTED - notificationTypeId!!!');
        },
        notificationAddModel() {
            return {
                notificationTypeId: this.notificationTypeId
            }
        }
    },
    watch: {
        siteId() {
            this.tryAddNotification();
        }
    },
    methods: {
        tryAddNotification() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.loading = true;
            Api.post('Self/Notification', self.notificationAddModel)
                .then(() => self.completed = true)
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.loading = false);
        }
    },
    created() {
        this.tryAddNotification();
    }
}
