import { Api } from "../../services/Api";

const state = () => ({
    fetchedPaymentFailures: false,
    fetchingPaymentFailures: false,
    paymentFailures: []
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingPaymentFailures = !!fetching;
        state.fetchedPaymentFailures = false;
    },
    setPaymentFailures(state, payments) {
        state.paymentFailures = payments;
        state.fetchedPaymentFailures = true;
        state.fetchingPaymentFailures = false;
    },
    clearPaymentFailures(state) {
        state.paymentFailures = [];
        state.fetchedPaymentFailures = false;
        state.fetchingPaymentFailures = false;
    }
}

const actions = {
    fetchPaymentFailures({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('SubscriptionPaymentFailure')
                .then(response => {
                    commit('setPaymentFailures', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
