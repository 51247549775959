import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ReferrerCacheMixin } from "./ReferrerCacheMixin";
import { mapState, mapActions } from 'vuex';

export const ReferrerAddMixin = {
    mixins: [ReferrerCacheMixin],
    computed: {
        ...mapState('Referrers', [
            'addingReferrer'
        ])
    },
    methods: {
        ...mapActions('Referrers', [
            'addReferrer'
        ]),
        tryAddReferrer() {
            const self = this;
            if (!self.fetchedReferrer) {
                return;
            }
            self.error = '';
            self.addReferrer()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
