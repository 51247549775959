<template>
    <div class="image-uploads d-flex flex-column justify-content-center align-items-center">
        <error-banner v-if="error" :message="error"></error-banner>
        <ul>
            <li>
                <button class="btn" type="button" @click="tryDeleteLogo">
                    <i class="far fa-times"></i>
                </button>
                <figure>
                    <img :alt="logo.uploadedName" :src="logo.url" width="160">
                </figure>
            </li>
        </ul>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { LogoDeleteMixin } from "../../Shared/LogoDeleteMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLogoDelete',
        mixins: [LogoDeleteMixin, StatusEmitterMixin],
        computed: {
            ...mapState('Logos', [
                'deletingLogo'
            ]),
            status() {
                return {
                    loading: this.deletingLogo,
                    error: this.error
                }
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
