<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Date Format')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionDescription}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in dateFormatOptions"
                            class="dropdown-item pointer"
                            :key="option.id"
                            @click="setDateFormatId(option.id)"
                            v-bind:class="{'active': option.id === dateFormatId}">
                            {{now | moment(option.momentJS)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { DateFormatOptionsCacheMixin } from "../../Shared/Time/DateFormatOptionsCacheMixin";
    import { DateFormatSettingUploadMixin } from "../../Shared/Time/DateFormatSettingUploadMixin";
    import { mapState } from 'vuex';
    import moment from 'moment';
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateLocalizationDateFormat',
        mixins: [DateFormatOptionsCacheMixin, DateFormatSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            now() {
                return moment();
            },
            status() {
                return {
                    loading: !!this.fetchingDateFormatOptions || !!this.loadingDateFormatSetting,
                    error: this.error
                }
            },
            selectedOption() {
                return this.dateFormatOptions.find(dfo => dfo.id == this.dateFormatId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.now.format(this.selectedOption.momentJS) : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            saveSiteEvent() {
                this.uploadDateFormatSetting();
            }
        },
        methods: {
            setDateFormatId(value) {
                this.dateFormatId = value;
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
