<template>
    <modal-dialog :title="title" :sub-title="subTitle" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="deleting" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p> Are you sure you want to delete this event?</p>
            </div>
            <button @click="confirmDelete" v-bind:disabled="loading" type="button" class="btn btn-danger" style="margin-right: 10px;">Yes</button>
            <button @click="deleting=false" type="button" class="close">
                <span>×</span>
            </button>
        </div>
        <section class="rounded-card-white mb-3">
            <type-select v-model="eventTypeId" :validator="eventValidator"></type-select>
            <colour-input v-model="colour" :title="$t('Colour')">
                <validation-message v-if="!!eventValidator.colour" :message="eventValidator.colour"></validation-message>
            </colour-input>
            <start-input v-model="startUtc" :validator="eventValidator"></start-input>
            <end-input v-model="endUtc" :validator="eventValidator"></end-input>
            <notes-upload :event="event" @loading="onLoadingNote"></notes-upload>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <delete-button @clicked="deleting=true"></delete-button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { EventUpdateMixin } from '../../Shared/Events/EventUpdateMixin';
    import EventTypeIdSelect from '../../Shared/Events/EventTypeIdSelect.vue';
    import EventEndDateInput from './EventEndDateInput.vue';
    import EventNotesUpload from './EventNotesUpload.vue';
    import EventStartDateInput from './EventStartDateInput.vue';
    import DeleteButton from '../../Shared/DeleteButton.vue';
    import { AllResourcesMixin } from '../../Shared/Resources/AllResourcesMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import SlottedColorInput from '../../Shared/Input/SlottedColorInput.vue';
    import ValidationMessage from '../../ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ScheduleViewStaffEvent',
        mixins: [EventUpdateMixin, AllResourcesMixin],
        data() {
            return {
                deleting: false,
                loadingNote: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'eventDeletedEvent'
            ]),
            siteId() {
                return !!this.event ? this.event.siteId : '';
            },
            resourceId() {
                return !!this.event ? this.event.resourceId : '';
            },
            resource() {
                return this.allResources.find(r => r.id === this.resourceId);
            },
            resourceName() {
                return !!this.resource ? this.resource.name : '';
            },
            title() {
                return this.eventDescription;
            },
            subTitle() {
                return this.resourceName;
            },
            loading() {
                return !!this.loadingEvent || !!this.loadingNote;
            }
        },
        watch: {
            eventDeletedEvent() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveEvent'
            ]),
            save() {
                this.tryUpdateEvent();
                this.triggerSaveEvent();

            },
            confirmDelete() {
                this.tryDeleteEvent();
            },
            onOpened() {
                this.deleting = false;
            },
            onLoadingNote(value) {
                this.loadingNote = !!value;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'type-select': EventTypeIdSelect,
            'start-input': EventStartDateInput,
            'end-input': EventEndDateInput,
            'notes-upload': EventNotesUpload,
            'delete-button': DeleteButton,
            'error-banner': ErrorBanner,
            'colour-input': SlottedColorInput,
            'validation-message': ValidationMessage
        }
    }
</script>
