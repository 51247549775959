import { mapActions } from 'vuex';
import { ClientOnboardingInviteCacheMixin } from './ClientOnboardingInviteCacheMixin.js';
import { ErrorHelper } from '../../helpers/ErrorHelper.js';

export const ClientOnboardingInviteAddMixin = {
    mixins: [ClientOnboardingInviteCacheMixin],
    computed: {
        clientOnboardingInviteAddModel() {
            return {
                clientId: this.clientId
            }
        },
        clientOnboardingInviteAddModelValid() {
            const model = this.clientOnboardingInviteAddModel;
            return !!model.clientId;
        }
    },
    methods: {
        ...mapActions('ClientOnboardingInvites', [
            'addClientOnboardingInvite'
        ]),
        tryAddClientOnboardingInvite() {
            const self = this;
            if (!self.clientOnboardingInviteAddModelValid) {
                return;
            }
            self.error = '';
            self.addClientOnboardingInvite({ key: self.clientId, payload: self.clientOnboardingInviteAddModel })
                .then(response => self.afterClientOnboardingInviteAdd(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterClientOnboardingInviteAdd() {
            // override if necessary
        },
    }
}
