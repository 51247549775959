import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { PetDurationAddMixin } from "./PetDurationAddMixin";
import { mapActions } from 'vuex';

export const PetDurationUploadMixin = {
    mixins: [PetDurationAddMixin],
    computed: {
        petDurationUpdateModel() {
            return !!this.petDuration ? Object.assign({}, this.petDuration, {
                hours: parseInt(this.hours),
                minutes: parseInt(this.minutes)
            }) : {};
        },
        petDurationUpdateModelValid() {
            const model = this.petDurationUpdateModel;
            return model.id && (!isNaN(model.hours) || !isNaN(model.minutes));
        },
        petDurationUpdateModelModified() {
            return JSON.stringify(this.petDurationUpdateModel) !== JSON.stringify(this.petDuration);
        }
    },
    watch: {
        petDuration() {
            this.refreshPetDurationModel();
        }
    },
    methods: {
        ...mapActions('PetDurations', [
            'updatePetDuration',
            'deletePetDuration'
        ]),
        refreshPetDurationModel() {
            this.hoursInput = !!this.petDuration ? this.petDuration.hours.toString() : '';
            this.minutesInput = !!this.petDuration ? this.petDuration.minutes.toString() : '';
            this.processHours();
            this.processMinutes();
        },
        tryUploadPetDuration() {
            if (!!this.petDuration) {
                this.tryUpdatePetDuration();
            } else {
                this.tryAddPetDuration();
            }
        },
        tryUpdatePetDuration() {
            const self = this;
            if (!self.petDurationUpdateModelValid) {
                return;
            }
            self.error = '';
            if (!self.petDurationUpdateModelModified) {
                return;
            }
            self.updatePetDuration({ petId: self.petId, payload: self.petDurationUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeletePetDuration() {
            const self = this;
            if (!self.petId) {
                return;
            }
            self.error = '';
            self.deletePetDuration(self.petId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetDurationModel();
    }
}
