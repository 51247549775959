<template>
    <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
        <ul class="dropdown-menu inner show" role="presentation">
            <li v-for="client in clients" :key="client.id">
                <a @click="onClick(client)" role="option" class="dropdown-item pointer" v-bind:class="{ 'active selected': client.id===currentId }">
                    <client-item :client="client"></client-item>
                </a>
            </li>
            <li v-if="clients.length === 0">
                <a role="option" class="dropdown-item">
                    {{$t('No results found')}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import router from '../../../routing';
    import ClientDisplayItem from "../../Scheduler/AddClient/ClientDisplayItem.vue";

    export default {
        name: 'ClientSearchResults',
        props: {
            clients: Array
        },
        computed: {
            currentId() {
                return this.$route.params.id;
            }
        },
        methods: {
            onClick(client) {
                if (this.currentId !== client.id) {
                    router.replace({ params: { id: client.id } });
                    this.$emit('clicked');
                }
            }
        },
        components: {
            'client-item': ClientDisplayItem
        }
    }
</script>
