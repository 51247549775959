<template>
    <div class="alert alert-danger alert-icon" role="alert" ref="errorRef">
        <div class="alert-content">
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ErrorBanner',
        props: ['message'],
        mounted() {
            this.$nextTick(() => {
                this.$refs.errorRef.scrollIntoView({ behavior: 'smooth' });
            });
        }
    }
</script>