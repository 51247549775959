export const TagHelper = {
    getTagTypeAddValidator() {
        return {
            isValid: false,
            name: '',
            colour: '',
            check(model) {
                this.name = !!model.name ? '' : 'Please enter a tag name';
                this.colour = !!model.colour ? '' : 'Please select a colour';
                this.isValid = !this.name && !this.colour;
            }
        }
    }
}