import { OneOffHoursSearchMixin } from "./OneOffHoursSearchMixin";
import moment from 'moment';
import { ResourceHelper } from '../../../helpers/ResourceHelper.js';
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const OneOffHoursAddMixin = {
    mixins: [OneOffHoursSearchMixin],
    data() {
        return {
            startLocal: '08:00',
            endLocal: '17:00',
            dayOff: false,
            oneOffHourValidator: ResourceHelper.getResourceHourValidator()
        }
    },
    props: {
        resource: Object,
        date: Date
    },
    computed: {
        now() {
            return this.date;
        },
        dateString() {
            return moment(this.date).format('YYYY-MM-DD') + 'T';
        },
        fullStartLocal() {
            return this.dateString + this.startLocal + ':00';
        },
        fullEndLocal() {
            return this.dateString + this.endLocal + ':00';
        },
        fullDateLocal() {
            return this.dateString + '00:00:00';
        },
        resourceId() {
            return !!this.resource ? this.resource.id : '';
        },
        oneOffHourAddModel() {
            return {
                resourceId: this.resourceId,
                dateLocal: this.fullDateLocal,
                startLocal: this.fullStartLocal,
                endLocal: this.fullEndLocal,
                dayOff: this.dayOff
            };
        },
        oneOffHourAddModelValid() {
            return this.oneOffHoursSearchModelValid && this.oneOffHourValidator.isValid;
        }
    },
    methods: {
        ...mapActions('OneOffHours', [
            'addOneOffHour'
        ]),
        tryAddOneOffHour() {
            const self = this;
            self.oneOffHourValidator.validate(self.oneOffHourAddModel);
            if (!self.oneOffHourAddModelValid) {
                return;
            }
            self.error = '';
            self.addOneOffHour({ key: self.oneOffHoursSearchKey, payload: self.oneOffHourAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
