<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header sub" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Client Details')}}
            <span v-if="loadingClient" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <client-name v-model="clientName" :validator="clientValidator"></client-name>
                <client-mobile v-model="telephoneMobile" :client-id="id" :validation="mobileValidation"></client-mobile>
                <client-home-tel v-model="telephoneHome"></client-home-tel>
                <client-work-tel v-model="telephoneWork"></client-work-tel>
                <client-email v-model="email" :client-id="id"></client-email>
                <site-select :disabled="true"></site-select>
                <client-address v-model="addressStatus" :client-id="id"></client-address>
            </div>
        </div>
        <button ref="deleteButton" type="button" data-target="#delete-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="delete-modal" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning  alert-icon">
                <div class="alert-content">
                    <p>{{$t('Are you sure you want to delete')}} {{clientFullname}}?</p>
                </div>
                <button @click="tryDeleteClient" v-bind:disabled="loadingClient" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="loadingClient" class="inline-loader"></span>
                    <template v-else>
                        {{$t('Yes')}}
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </section>
</template>

<script>
    import ClientNameFields from './ClientNameFields.vue';
    import ClientMobileFieldV2 from './ClientMobileFieldV2.vue';
    import ClientHomeTelField from './ClientHomeTelField.vue';
    import ClientWorkTelField from './ClientWorkTelField.vue';
    import ClientEmailFieldV2 from './ClientEmailFieldV2.vue';
    import ModalDialogue from '../Layout/ModalDialog.vue';
    import { ClientUpdateMixin } from '../Shared/ClientUpdateMixin.js';
    import { mapState } from 'vuex';
    import ClientEditAddressInput from './ClientEditAddressInput.vue';
    import SiteSelectColumn from '../Shared/SiteSelectColumn.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'ClientDetailsSection',
        mixins: [ClientUpdateMixin, StatusEmitterMixin],
        props: {
            id: String
        },
        data() {
            return {
                addressStatus: {}
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent',
                'deleteClientEvent',
                'clientDeletedEvent'
            ]),
            clientId() {
                return this.id;
            },
            clientName: {
                get() {
                    return {
                        firstName: this.firstName,
                        lastName: this.lastName
                    }
                },
                set(value) {
                    this.firstName = value.firstName;
                    this.lastName = value.lastName;
                }
            },
            status() {
                return {
                    loading: this.loadingClient || this.addressStatus.loading,
                    error: this.error || this.addressStatus.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.tryUpdateClient();
            },
            deleteClientEvent() {
                if (!!this.client) {
                    this.$refs.deleteButton.click();
                }
            },
            clientDeletedEvent() {
                this.$refs.cancelButton.click();
            }
        },
        components: {
            'client-name': ClientNameFields,
            'client-mobile': ClientMobileFieldV2,
            'client-email': ClientEmailFieldV2,
            'client-home-tel': ClientHomeTelField,
            'client-work-tel': ClientWorkTelField,
            'client-address': ClientEditAddressInput,
            'modal-dialog': ModalDialogue,
            'site-select': SiteSelectColumn,
            'error-banner': ErrorBanner
        }
    }
</script>
