import { ErrorHelper } from "../../helpers/ErrorHelper";
import { CommunicationSendMixin } from "./CommunicationSendMixin";
import { mapActions } from 'vuex';
import moment from 'moment';

export const CommunicationResendMixin = {
    mixins: [CommunicationSendMixin],
    computed: {
        attemptedCommunications() {
            return this.communications.filter(c => c.subjectId === this.subjectId && c.methodType.id === this.methodTypeId)
                .sort((a, b) => moment(b.lastUpdatedUtc) - moment(a.lastUpdatedUtc));
        },
        failedCommunication() {
            return this.attemptedCommunications.find(c => [0, 2].includes(c.sendStatus.id));
        },
        communicationSuccessful() {
            return this.attemptedCommunications.length > 0 && this.attemptedCommunications.every(c => c.sendStatus.id === 1);
        }
    },
    methods: {
        ...mapActions('Communications', [
            'resendCommunication'
        ]),
        tryUploadCommunication() {
            if (this.failedCommunication) {
                this.tryResendCommunication();
            } else {
                this.trySendCommunication();
            }
        },
        tryResendCommunication() {
            const self = this;
            if (!self.failedCommunication || !self.communicationSendModelValid) {
                return;
            }
            self.error = '';
            self.resendCommunication({ key: self.communicationCacheKey, id: self.failedCommunication.id })
                .then(response => self.afterSendingCommunication(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
