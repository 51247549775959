<template>
    <table-mobile @next="next">
        <purchase-mobile v-for="subscriptionPayment in subscriptionPayments" :key="subscriptionPayment.id" :subscription-payment="subscriptionPayment"></purchase-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from '../Shared/Layout/TableMobile.vue';
    import PurchaseHistoryItemMobile from './PurchaseHistoryItemMobile.vue';

    export default {
        name: 'PurchaseHistoryMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('SubscriptionPaymentSearches', [
                'findSubscriptionPaymentSearches'
            ]),
            subscriptionPaymentSearches() {
                return this.findSubscriptionPaymentSearches(this.searchKey);
            },
            subscriptionPayments() {
                return [].concat(...this.subscriptionPaymentSearches.map(sp => sp.values));
            },
            loading() {
                return this.subscriptionPaymentSearches.some(sp => !!sp.loading);
            }
        },
        methods: {
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.subscriptionPayments.length);
                }
            }
        },
        components: {
            'purchase-mobile': PurchaseHistoryItemMobile,
            'table-mobile': TableMobile
        }
    }
</script>
