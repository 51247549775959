<template>
    <modal-dialog @opened="onOpened" @closed="onClosed">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-html="html"></div>
    </modal-dialog>
</template>

<script>
    import { ErrorHelper } from "../../../../helpers/ErrorHelper";
    import { Api } from "../../../../services/Api";
    import ModalDialog from "../../../Layout/ModalDialog.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'CampaignPreview',
        data() {
            return {
                error: '',
                html: '',
                open: false
            }
        },
        props: {
            campaignId: String
        },
        computed: {
            ready() {
                return !!this.campaignId && this.open;
            }
        },
        watch: {
            ready() {
                this.fetchTemplateHtml();
            }
        },
        methods: {
            fetchTemplateHtml() {
                const self = this;
                if (!self.ready) {
                    return;
                }
                self.error = '';
                Api.get('Campaign/' + this.campaignId + '/Template')
                    .then(response => self.html = response.data.html)
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            onOpened() {
                this.open = true;
            },
            onClosed() {
                this.open = false;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        },
        created() {
            this.fetchTemplateHtml();
        }
    }
</script>
