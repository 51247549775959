<template>
    <div class="col-6 d-flex justify-content-end">
        <label class="custom-control custom-radio custom-radio-inline">
            <input v-model="paymentMethodId" :value="1" class="custom-control-input" name="radio-example" type="radio">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{$t('Cash')}}</span>
        </label>
        <label class="custom-control custom-radio custom-radio-inline">
            <input v-model="paymentMethodId" :value="2" class="custom-control-input" name="radio-example" type="radio">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{$t('Card')}}</span>
        </label>
        <label class="custom-control custom-radio custom-radio-inline">
            <input v-model="paymentMethodId" :value="3" class="custom-control-input" name="radio-example" type="radio">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{$t('Invoice')}}</span>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'AppointmentOverviewPaymentMethod',
        props: {
            value: Number
        },
        computed: {
            paymentMethodId: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
