import { DaysOrderMixin } from "../../Shared/DaysOrderMixin";

export const ResourceHoursMixin = {
    mixins: [DaysOrderMixin],
    props: {
        resource: Object,
        workingHours: Array,
        oneOffHours: Array
    },
    computed: {
        resourceId() {
            return !!this.resource ? this.resource.id : '';
        },
        resourceName() {
            return !!this.resource ? this.resource.name : 'Unknown';
        },
        resourceColour() {
            return !!this.resource ? this.resource.colour : '#ffffff';
        },
        resourceWorkingHours() {
            return this.workingHours.filter(wh => wh.resourceId === this.resourceId);
        },
        resourceOneOffHours() {
            return this.oneOffHours.filter(ooh => ooh.resourceId === this.resourceId);
        }
    },
    methods: {
        onCellClicked(info) {
            this.$emit('cell-clicked', Object.assign({}, info, { resource: this.resource }));
        },
        onRowClicked() {
            this.$emit('row-clicked', this.resource);
        }
    }
}
