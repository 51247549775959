import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const BookingPortalImageMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'BookingPortalImage';
        },
        defaultSettingObject() {
            return {
                imageId: ''
            }
        }
    }
}
