import { Api } from "../../services/Api";

const state = () => ({
    addingReminderSetting: false,
    updatingReminderSetting: false,
    loadingReminderSettings: false,
    loadedReminderSettings: false,
    reminderSettings: []
})

const getters = {
    findReminderSetting: (state) => siteId => state.reminderSettings.find(s => s.siteId === siteId)
}

const mutations = {
    setLoading(state, loading) {
        state.loadingReminderSettings = !!loading;
    },
    setReminderSettings(state, reminderSettings) {
        state.reminderSettings = reminderSettings;
        state.loadedReminderSettings = true;
    },
    setAdding(state, adding) {
        state.addingReminderSetting = !!adding;
    },
    appendReminderSetting(state, reminderSetting) {
        state.reminderSettings.push(reminderSetting);
    },
    setUpdating(state, updating) {
        state.updatingReminderSetting = !!updating;
    },
    insertReminderSetting(state, reminderSetting) {
        const existing = state.reminderSettings.find(s => s.siteId === reminderSetting.siteId);
        if (!!existing) {
            state.reminderSettings.splice(state.reminderSettings.indexOf(existing), 1, reminderSetting);
        } else {
            state.reminderSettings.push(reminderSetting);
        }
    }
}

const actions = {
    fetchReminderSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('ReminderSetting')
                .then(response => {
                    commit('setReminderSettings', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    addReminderSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('ReminderSetting', payload)
                .then(response => {
                    commit('appendReminderSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateReminderSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('ReminderSetting', payload)
                .then(response => {
                    commit('insertReminderSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
