<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{$t('Campaign Email Templates')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <nav class="links">
                    <ul>
                        <li @click="toggleStripoDefaultTemplatesGallery" class="pointer" v-bind:class="{ active: !showStripoDefaultTemplatesGallery }">
                            <a>{{$t('Templates')}}</a>
                        </li>
                        <li @click="toggleStripoDefaultTemplatesGallery" class="pointer" v-bind:class="{ active: showStripoDefaultTemplatesGallery }">
                            <a>{{$t('Gallery')}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="gotoAddTemplate" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">{{$t('Add Template')}}</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <button @click="gotoAddTemplate" class="btn-add btn btn-secondary d-none d-md-block">
                    <i class="fas fa-plus"></i>
                    {{$t('Add Template')}}
                </button>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <templates-table v-if="!showStripoDefaultTemplatesGallery"></templates-table>
            <templates-gallery v-else></templates-gallery>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import SiteSelect from '../../Account/SiteSelect.vue';
    import CampaignEmailTemplatesTable from './CampaignEmailTemplatesTable.vue';
    import StripoDefaultTemplateSettingsDisplay from './StripoDefaultTemplateSettingsDisplay.vue';

    export default {
        name: 'CampaignEmailTemplates',
        data() {
            return {
                showStripoDefaultTemplatesGallery: false
            }
        },
        methods: {
            gotoAddTemplate() {
                router.push({ name: 'campaignEmailTemplateAdd' });
            },
            toggleStripoDefaultTemplatesGallery() {
                this.showStripoDefaultTemplatesGallery = !this.showStripoDefaultTemplatesGallery;
            }
        },
        components: {
            'site-select': SiteSelect,
            'templates-table': CampaignEmailTemplatesTable,
            'templates-gallery': StripoDefaultTemplateSettingsDisplay
        }
    }
</script>
