<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-up fa-1x"></i>
                    Subscriptions
                </h1>
            </div>
        </header>
        <div class="content">
            <upgrade-modifier v-if="subscriptionUpgrade"></upgrade-modifier>
            <upgrade-select v-else></upgrade-select>
            <page-loader v-if="loading"></page-loader>
        </div>
    </div>
</template>

<script>
    import { SubscriptionUpgradeCacheMixin } from "../../../mixins/Subscriptions/SubscriptionUpgradeCacheMixin";
    import PageLoader from "../../Shared/Loading/PageLoader.vue";
    import SubscriptionUpgradeSelectV2 from "./SubscriptionUpgradeSelectV2.vue";
    import SubscriptionUpgradeModifier from "../SubscriptionUpgradeModifier.vue";

    export default {
        name: 'SubscriptionUpgradeV2',
        mixins: [SubscriptionUpgradeCacheMixin],
        computed: {
            loading() {
                return !this.subscriptionUpgrade && this.fetchingSubscriptionUpgrade;
            }
        },
        components: {
            'page-loader': PageLoader,
            'upgrade-select': SubscriptionUpgradeSelectV2,
            'upgrade-modifier': SubscriptionUpgradeModifier
        }
    }
</script>
