import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AddressAddMixin } from "./AddressAddMixin";

export const AddressUploadMixin = {
    mixins: [AddressAddMixin],
    computed: {
        addressUpdateModel() {
            return Object.assign({}, this.address, this.addressLines);
        },
        addressUpdateModelChanged() {
            return JSON.stringify(this.addressUpdateModel) !== JSON.stringify(this.address);
        }
    },
    watch: {
        address(newValue) {
            this.setAddressLines(newValue);
        }
    },
    methods: {
        ...mapActions('Addresses', [
            'updateAddress'
        ]),
        uploadAddress() {
            if (!this.loadingAddress) {
                if (!!this.address) {
                    this.tryUpdateAddress();
                } else {
                    this.tryAddAddress();
                }
            }
        },
        tryUpdateAddress() {
            const self = this;
            if (!self.addressUpdateModelChanged) {
                return;
            }
            self.error = '';
            self.updateAddress({ key: self.addressCacheKey, payload: self.addressUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.setAddressLines(this.address);
    }
}
