<template>
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex align-items-center">
                <a @click="onClick" class="pointer header-client" data-dismiss="modal">
                    <h4 class="mb-0">{{clientFullname}}</h4>
                </a>
                <div v-if="history">
                    <first-time-tooltip v-if="history.total === 0"></first-time-tooltip>
                </div>
            </div>
            <div v-if="history && history.total" class="star-rating mb-3">
                <i v-for="star in history.rating" :title="history.description" class="far fa-star"></i>
            </div>
            <template v-else>
                {{$t('No rating')}}
                <div>
                    <br />
                </div>
            </template>
        </div>
        <div class="col-md-6 contact-options d-flex flex-column align-items-start align-items-md-end">
            <div class="d-flex justify-content-end mb-1">
                <a v-if="phoneHref" class="btn btn-sm btn-outline mr-1" :href="phoneHref" target="_blank">
                    <i class="fal fa-phone-alt"></i> {{$t('Call')}}
                </a>
                <a v-if="smsHref" @click="toggleSms" class="btn btn-sm btn-outline mr-1">
                    <i class="fal fa-comment-alt-dots"></i> {{$t('Text')}}
                </a>
                <a v-if="emailHref" @click="toggleEmail" class="btn btn-sm btn-outline mr-1">
                    <i class="fal fa-envelope"></i> {{$t('Email')}}
                </a>
            </div>
            <div class="d-flex justify-content-end mb-1">
                <payment-button v-if="emailHref" :appointment="appointment" class="mr-1"></payment-button>
                <rebook-button :appointment="appointment"></rebook-button>
            </div>
        </div>
        <div v-if="!!client" class="col-md-12">
            <email-send v-if="email" :client="client"></email-send>
            <sms-send v-if="sms" :client="client"></sms-send>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import ClientEmailSend from '../../Clients/Communications/ClientEmailSend.vue';
    import ClientSmsSend from '../../Clients/Communications/ClientSmsSend.vue';
    import { ClientCacheMixin } from '../../Shared/ClientCacheMixin';
    import { ClientCommButtonMixin } from '../../Shared/ClientCommButtonMixin.js';
    import { HistoryCacheMixin } from '../../Shared/HistoryCacheMixin.js';
    import AppointmentPaymentRequestButton from './AppointmentPaymentRequestButton.vue';
    import AppointmentRebookButton from './RebookAppointment/AppointmentRebookButton.vue';
    import AppointmentOverviewFirstTimeTooltip from './AppointmentOverviewFirstTimeTooltip.vue';

    export default {
        name: 'AppointmentOverviewClient',
        mixins: [ClientCommButtonMixin, HistoryCacheMixin, ClientCacheMixin],
        props: {
            appointment: Object
        },
        data() {
            return {
                email: false,
                sms: false
            }
        },
        computed: {
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            }
        },
        methods: {
            onClick() {
                if (!!this.clientId) {
                    router.push({ name: 'clientEdit', params: { id: this.clientId } });
                }
            },
            toggleEmail() {
                this.email = !this.email;
                this.sms = false;
            },
            toggleSms() {
                this.sms = !this.sms;
                this.email = false;
            }
        },
        components: {
            'payment-button': AppointmentPaymentRequestButton,
            'rebook-button': AppointmentRebookButton,
            'email-send': ClientEmailSend,
            'sms-send': ClientSmsSend,
            'first-time-tooltip': AppointmentOverviewFirstTimeTooltip
        }
    }
</script>
