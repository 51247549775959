import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { SiteIdMixin } from '../../../components/Shared/Site/SiteIdMixin';

export const AccountSettingsCacheMixinV2 = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('AccountSettings', [
            'fetchingAccountSettings',
            'fetchedAccountSettings',
            'accountSettings'
        ])
    },
    watch: {
        siteId() {
            this.refreshAccountSettings();
        }
    },
    methods: {
        ...mapActions('AccountSettings', [
            'getAccountSettings'
        ]),
        tryGetAccountSettings() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getAccountSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshAccountSettings() {
            if (!this.fetchedAccountSettings && !this.fetchingAccountSettings) {
                this.tryGetAccountSettings();
            }
        }
    },
    created() {
        this.refreshAccountSettings();
    }
}
