<template>
    <collapsed-section id="pet-appointments" :title="title" :loading="status.loading">
        <appointments-table v-model="appointmentStatus" :petId="petId"></appointments-table>
    </collapsed-section>
</template>

<script>
    import { HistoryCacheMixin } from "../../Shared/HistoryCacheMixin";
    import moment from 'moment'
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import PetAppointmentsTable from "./PetAppointmentsTable.vue";

    export default {
        name: 'PetAppointmentsSectionV2',
        mixins: [HistoryCacheMixin, StatusEmitterMixin],
        props: {
            pet: Object
        },
        data() {
            return {
                appointmentStatus: {}
            }
        },
        computed: {
            clientId() {
                return !!this.pet ? this.pet.clientId : '';
            },
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            petlastVisited() {
                return this.history ? this.history.lastVisited[this.petId] : null;
            },
            petlastVisitedString() {
                return this.petlastVisited ? this.$t('last visited: ') + moment(this.petlastVisited).format('DD MMM YYYY') : this.$t('Not yet visited');
            },
            title() {
                return this.$t('Appointment History') + ' - ' + this.petlastVisitedString;
            },
            status() {
                return {
                    loading: this.loadingHistory || this.appointmentStatus.loading,
                    error: this.error || this.appointmentStatus.error
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'appointments-table': PetAppointmentsTable
        }
    }
</script>
