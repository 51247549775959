import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ReminderSettingAddMixin } from "./ReminderSettingAddMixin";
import { mapState, mapActions } from 'vuex';

export const ReminderSettingUpdateMixin = {
    mixins: [ReminderSettingAddMixin],
    computed: {
        ...mapState('ReminderSettings', [
            'updatingReminderSetting'
        ]),
        reminderSettingUpdateModel() {
            return !!this.reminderSetting ? Object.assign({}, this.reminderSetting, {
                enabled: this.enabled,
                hoursPrior: this.hoursPrior
            }) : {};
        },
        reminderSettingUpdateModelValid() {
            const model = this.reminderSettingUpdateModel;
            return !!model.siteId && this.reminderSettingValidator.isValid;
        },
        reminderSettingUpdateModelModified() {
            return JSON.stringify(this.reminderSetting) !== JSON.stringify(this.reminderSettingUpdateModel);
        }
    },
    watch: {
        reminderSetting() {
            this.refreshReminderSettingModel();
        }
    },
    methods: {
        ...mapActions('ReminderSettings', [
            'updateReminderSetting'
        ]),
        refreshReminderSettingModel() {
            this.enabled = !!this.reminderSetting ? this.reminderSetting.enabled : true;
            this.hoursPrior = !!this.reminderSetting ? this.reminderSetting.hoursPrior : 48;
        },
        tryUpdateReminderSetting() {
            const self = this;
            self.reminderSettingValidator.validate(self.reminderSettingUpdateModel);
            if (!self.reminderSettingUpdateModelValid || !self.reminderSettingUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateReminderSetting(self.reminderSettingUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryUploadReminderSetting() {
            if (!!this.reminderSetting) {
                this.tryUpdateReminderSetting();
            } else {
                this.tryAddReminderSetting();
            }
        }
    }
}
