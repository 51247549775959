<template>
    <section class="mb-3">
        <error-banner v-if="error" :message="error"></error-banner>
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-1" data-toggle="collapse">
            {{$t('Pet details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <pet-name v-model="name" :validator="petValidator"></pet-name>
                <pet-breed v-model="breedId" :validator="petValidator"></pet-breed>
                <pet-preferred></pet-preferred>
                <pet-dob v-model="dateOfBirth"></pet-dob>
                <pet-gender v-model="genderId"></pet-gender>
                <pet-neutered v-model="neuteredId"></pet-neutered>
                <pet-behaviour v-model="behaviourId"></pet-behaviour>
                <pet-microchip v-model="microChip"></pet-microchip>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { PetAddMixin } from "../../Shared/PetAddMixin";
    import PetDetailsBehaviour from "../PetDetailsBehaviour.vue";
    import PetDetailsBreed from "../PetDetailsBreed.vue";
    import PetDetailsDeceased from "../PetDetailsDeceased.vue";
    import PetDetailsDob from "../PetDetailsDob.vue";
    import PetDetailsGender from "../PetDetailsGender.vue";
    import PetDetailsMicrochip from "../PetDetailsMicrochip.vue";
    import PetDetailsName from "../PetDetailsName.vue";
    import PetDetailsNeutered from "../PetDetailsNeutered.vue";
    import PetAddDetailsPreferred from "./PetAddDetailsPreferred.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'PetAddDetails',
        mixins: [PetAddMixin],
        props: {
            client: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent'
            ]),
            clientId() {
                return !!this.client ? this.client.id : '';
            }
        },
        watch: {
            savePetEvent() {
                this.tryAddPet();
            }
        },
        components: {
            'pet-name': PetDetailsName,
            'pet-breed': PetDetailsBreed,
            'pet-preferred': PetAddDetailsPreferred,
            'pet-dob': PetDetailsDob,
            'pet-gender': PetDetailsGender,
            'pet-neutered': PetDetailsNeutered,
            'pet-behaviour': PetDetailsBehaviour,
            'pet-microchip': PetDetailsMicrochip,
            'pet-deceased': PetDetailsDeceased,
            'error-banner': ErrorBanner
        }
    }
</script>
