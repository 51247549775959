<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { TimeZoneAddMixin } from "../../Shared/Time/Zone/TimeZoneAddMixin";

    export default {
        name: 'PostRegisterTimeZone',
        mixins: [TimeZoneAddMixin, StatusEmitterMixin],
        computed: {
            fetchedTimeZone() {
                return !!this.timeZoneCache && !this.timeZoneCache.retry && !this.loadingTimeZone;
            },
            timeZoneComplete() {
                return !!this.timeZone;
            },
            canAddTimeZone() {
                return this.fetchedTimeZone
                    && !this.timeZoneComplete
                    && !!this.siteId;
            },
            invalidTimeZoneIdError() {
                return this.timeZoneAddModelValid ? '' : 'Invalid time zone ID';
            },
            status() {
                return {
                    error: this.error || this.invalidTimeZoneIdError,
                    loading: this.loadingTimeZone,
                    completed: this.timeZoneComplete
                }
            }
        },
        watch: {
            canAddTimeZone(value) {
                if (!!value) {
                    this.tryAddTimeZone();
                }
            }
        },
        created() {
            if (this.canAddTimeZone) {
                this.tryAddTimeZone();
            }
        }
    }
</script>
