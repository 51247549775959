<template>
    <div class="rewards_card-body-instruction">
        <div class="rewards_card-body-instruction-title">How does it work?</div>
        <div class="rewards_card-body-instruction-title-sub text-center">Share our service with your friends to earn rewards when they sign up. Your friends also receive money off their first subscription by doing so!</div>
        <div class="font-weight-bold">1.) Copy and share the link with Your Friends:</div>
        <p>
            First things first, you'll need to share your referral link with your friends.
        </p>
        <div class="font-weight-bold">2.) Ask Your Friends to Register an Account:</div>
        <p>
            Your friends can paste the referral link into their browser, which will take them directly to the registration page.
        </p>
        <div class="font-weight-bold">3.) Creating an Account and Subscribing:</div>
        <p>
            After your friend creates an account, encourage them to sign up for a subscription plan (Core, Business, or Enterprise).<strong> You earn a point for each successful paid subscription, and they will also receive money off their first payment equal to a bronze reward.</strong>
        </p>
        <div class="font-weight-bold">4.) Redeem Your Rewards:</div>
        <p>
            Explore the section below for various rewards. After collecting enough points for a reward, you'll automatically receive a one-off discount on your next subscription payment.
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ReferralsRewardsInstruction'
    }
</script>