<template>
    <div>
        <template v-if="error">
            <error-banner :message="error"></error-banner>
            <button @click="beginSessionFlow" class="btn btn-primary">Retry</button>
        </template>
        <page-loader v-else></page-loader>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";

    export default {
        name: 'CardAdd',
        data() {
            return {
                error: ''
            }
        },
        computed: {
            sessionId() {
                return this.$route.query.sessionId;
            },
            sessionAddModel() {
                return {
                    successUrl: window.location.href,
                    cancelUrl: new URL(this.$router.resolve({
                        name: 'paymentUpdate',
                        query: { warning: 'Adding card cancelled' }
                    }).href, window.location.origin)
                }
            },
            sessionFilterRequest() {
                return {
                    sessionId: this.sessionId
                }
            },
            sessionFilterRequestValid() {
                return !!this.sessionFilterRequest.sessionId;
            }
        },
        methods: {
            beginSessionFlow() {
                if (!!this.sessionId) {
                    this.tryCompleteSession();
                } else {
                    this.tryCreateSession();
                }
            },
            tryCreateSession() {
                const self = this;
                self.error = '';
                Api.post('Stripe/Card/Session', self.sessionAddModel)
                    .then(response => window.open(response.data.url, '_self'))
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            tryCompleteSession() {
                const self = this;
                if (!self.sessionFilterRequestValid) {
                    return;
                }
                self.error = '';
                Api.get('Stripe/Session?' + new URLSearchParams(self.sessionFilterRequest))
                    .then(response => {
                        if (response.data.success) {
                            this.$router.push({
                                name: 'paymentUpdate',
                                query: { success: 'Successfully added card' }
                            });
                        } else {
                            this.$router.push({
                                name: 'paymentUpdate',
                                query: { error: 'Failed to add card. Please try again.' }
                            });
                        }
                    })
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'page-loader': PageLoader
        },
        mounted() {
            this.beginSessionFlow();
        }
    }
</script>
