import { mapActions, mapGetters} from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SiteIdMixin } from './Site/SiteIdMixin';

export const PurchasedCampaignTemplatesCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PurchasedCampaignTemplates', [
            'findPurchasedCampaignTemplatesCache'
        ]),
        purchasedCampaignTemplatesCache() {
            return this.findPurchasedCampaignTemplatesCache(this.siteId);
        },
        purchasedCampaignTemplates() {
            return !!this.purchasedCampaignTemplatesCache ? this.purchasedCampaignTemplatesCache.values : null;
        },
        loadingPurchasedCampaignTemplates() {
            return !!this.purchasedCampaignTemplatesCache && this.purchasedCampaignTemplatesCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshPurchasedCampaignTemplates();
        }
    },
    methods: {
        ...mapActions('PurchasedCampaignTemplates', [
            'getPurchasedCampaignTemplates'
        ]),
        refreshPurchasedCampaignTemplates() {
            if (!this.purchasedCampaignTemplatesCache || this.purchasedCampaignTemplatesCache.retry) {
                this.tryGetPurchasedCampaignTemplates();
            }
        },
        tryGetPurchasedCampaignTemplates() {
            const self = this;

            if (!self.siteId) {
                return;
            }

            self.getPurchasedCampaignTemplates({ key: self.siteId })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPurchasedCampaignTemplates();
    }
}
