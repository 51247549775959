<template>
    <page-loader></page-loader>
</template>

<script>
    import { mapActions } from 'vuex';
    import PageLoader from '../Shared/Loading/PageLoader.vue';

    export default {
        name: 'Interstitial',
        computed: {
            returnPath() {
                return this.$route.query.rPath;
            },
            returnQuery() {
                const query = this.$route.query.rQuery;
                return !!query ? '?' + query : '';
            }
        },
        methods: {
            ...mapActions('Account', [
                'fetchAccount'
            ]),
            ...mapActions('Sites', [
                'getSites'
            ]),
            ...mapActions('Subscription', [
                'createTrial'
            ]),
            ...mapActions('Authentication', [
                'logout'
            ]),
            tryRefresh() {
                const self = this;
                self.fetchAccount()
                    .then(response => {
                        if (response.data.isRestricted) {
                            self.$router.push({ name: 'restricted' });
                        } else {
                            self.tryGetSites();
                        }
                    })
                    .catch(() => self.logout());
            },
            tryGetSites() {
                const self = this;
                self.getSites()
                    .then(self.tryCreateTrial)
                    .catch(() => self.logout());
            },
            tryCreateTrial() {
                const self = this;
                self.createTrial()
                    .then(() => {
                        if (!!self.returnPath && self.$router.resolve(self.returnPath).route.name !== self.$route.name) {
                            self.$router.push({ path: self.returnPath + self.returnQuery });
                        } else {
                            self.$router.push({ name: 'schedule' });
                        }
                    })
                    .catch(() => self.logout());
            }
        },
        components: {
            'page-loader': PageLoader
        },
        created() {
            this.tryRefresh();
        }
    }
</script>
