<template>
    <tr @click="gotoCampaignEdit" class="link pointer" v-bind:class="classType">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="w-100">
                    <div class="crd-name">
                        {{subject}}
                    </div>
                    <div class="d-flex">
                        <div class="col-6 px-0">
                            Method: {{method}}
                        </div>
                        <div class="col-6 px-0">
                            Confirmed: {{confirmed}}
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="col-6 px-0">
                            Reach: {{reach}}
                        </div>
                        <div class="col-6 px-0">
                            Sent: {{completed}}
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import router from '../../../../routing';

    export default {
        name: 'CampaignItemMobile',
        props: {
            campaign: Object,
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            },
            subject() {
                return !!this.campaign ? this.campaign.subject : '';
            },
            startLetter() {
                return this.subject.length > 0 ? this.subject[0] : '';
            },
            campaignId() {
                return !!this.campaign ? this.campaign.id : '';
            },
            reach() {
                return !!this.campaign ? this.campaign.reach : 0;
            },
            confirmed() {
                return !!this.campaign && this.campaign.confirmed ? 'Yes' : 'No';
            },
            completed() {
                return !!this.campaign && this.campaign.completed ? 'Yes' : 'No';
            },
            method() {
                return !!this.campaign ? this.campaign.methodType.description : '';
            }
        },
        methods: {
            gotoCampaignEdit() {
                router.push({ name: 'campaignUpdate', params: { id: this.campaignId } });
            }
        }
    }
</script>
