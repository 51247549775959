<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label for="title">{{$t('Client name')}}<span class="required-field"></span> </label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <div class="row">
                    <div class="col-6">
                        <label class="d-md-none" for="text">First name</label>
                        <input ref="firstNameField" v-model="firstName" class="form-control" name="name" type="text" value="" :placeholder="$t('First name')">
                        <validation-message v-if="validator.firstName" :message="validator.firstName" ></validation-message>
                    </div>
                    <div class="col-6">
                        <label class="d-md-none" for="text">Last name</label>
                        <input v-model="lastName" class="form-control" type="text" name="name" value="" :placeholder="$t('Last name')">
                        <validation-message v-if="validator.lastName" :message="validator.lastName"></validation-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import process from 'process';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ClientNameFields',
        props: {
            value: Object,
            validator: Object,
            setFocus: Boolean
        },
        computed: {
            firstName: {
                get() {
                    return this.value.firstName;
                },
                set(value) {
                    this.$emit('input', {
                        firstName: value,
                        lastName: this.value.lastName
                    });
                }
            },
            lastName: {
                get() {
                    return this.value.lastName;
                },
                set(value) {
                    this.$emit('input', {
                        firstName: this.value.firstName,
                        lastName: value
                    });
                }
            }
        },
        mounted() {
            if (this.setFocus) {
                process.nextTick(() => this.$refs.firstNameField.focus());
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
