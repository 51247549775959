import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';

export const ContractTemplateSiteCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ContractTemplateSites', [
            'findContractTemplateSiteCache'
        ]),
        contractTemplateSiteCache() {
            return this.findContractTemplateSiteCache(this.siteId);
        },
        contractTemplatesForSite() {
            return !!this.contractTemplateSiteCache ? this.contractTemplateSiteCache.values : [];
        },
        loadingContractTemplatesForSite() {
            return !!this.contractTemplateSiteCache && this.contractTemplateSiteCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshContractTemplatesForSite();
        }
    },
    methods: {
        ...mapActions('ContractTemplateSites', [
            'getContractSiteTemplates'
        ]),
        refreshContractTemplatesForSite() {
            if (!this.contractTemplateSiteCache || !!this.contractTemplateSiteCache.retry) {
                this.tryGetContractTemplatesForSite();
            }
        },
        tryGetContractTemplatesForSite() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getContractSiteTemplates(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContractTemplatesForSite();
    }
}
