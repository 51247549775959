<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area col-md-6">
                <h1>
                    <i class="fa-duotone fa-gauge-max fa-1x"></i>
                    {{$t('Dashboard')}}
                </h1>
            </div>
            <div class="col-md-6 px-0 d-md-block d-none">
                <div class="d-flex">
                    <div class="col-3 px-0 mr-1"></div>
                    <div class="col-3 px-0 mr-1"></div>
                    <div class="col-3 px-0 mr-1">
                        <site-select v-model="siteIds"></site-select>
                    </div>
                    <div class="col-3 px-0">
                        <time-period v-model="timePeriod" :has-future-option="false"></time-period>
                    </div>
                </div>
            </div>
            <div class="d-flex w-100 d-block d-md-none mb-2">
                <div class="col-6 pr-1">
                    <site-select v-model="siteIds"></site-select>
                </div>
                <div class="col-6 pl-1">
                    <time-period v-model="timePeriod" :has-future-option="false"></time-period>
                </div>
            </div>
        </header>
        <div class="content">
            <div class="row">
                <div class="col-md-3">
                    <order-totals :site-ids="siteIds" :time-period="timePeriod"></order-totals>
                </div>
                <div class="col-md-3">
                    <client-count :site-ids="siteIds" :time-period="timePeriod"></client-count>
                </div>
                <div class="col-md-3">
                    <client-visit-rate :site-ids="siteIds" :time-period="timePeriod"></client-visit-rate>
                </div>
                <div class="col-md-3">
                    <appointment-totals :site-ids="siteIds" :time-period="timePeriod"></appointment-totals>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <appointment-charts :site-ids="siteIds" :time-period="timePeriod"></appointment-charts>
                </div>
                <div class="col-md-3">
                    <order-item-totals :site-ids="siteIds" :time-period="timePeriod"></order-item-totals>
                    <breed-counts :site-ids="siteIds" :time-period="timePeriod"></breed-counts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteIdMixin } from "../Shared/Site/SiteIdMixin";
    import SiteMultiSelectDropdown from "../Shared/Site/SiteMultiSelectDropdown.vue";
    import TimePeriodSelector from "../Shared/Time/TimePeriodSelector.vue";
    import AppointmentAggregateCharts from "./AppointmentAggregateCharts.vue";
    import AppointmentAggregateTotals from "./AppointmentAggregateTotals.vue";
    import BreedAggregateCount from "./BreedAggregateCount.vue";
    import ClientAggregateCount from "./ClientAggregateCount.vue";
    import ClientVisitAggregateRates from "./ClientVisitAggregateRates.vue";
    import OrderAggregateTotals from "./OrderAggregateTotals.vue";
    import OrderItemAggregateTotals from "./OrderItemAggregateTotals.vue";

    export default {
        name: 'Dashboard',
        mixins: [SiteIdMixin],
        data() {
            return {
                siteIds: [],
                timePeriod: null
            }
        },
        created() {
            this.siteIds = [this.siteId]
        },
        components: {
            'site-select': SiteMultiSelectDropdown,
            'time-period': TimePeriodSelector,
            'appointment-charts': AppointmentAggregateCharts,
            'appointment-totals': AppointmentAggregateTotals,
            'order-totals': OrderAggregateTotals,
            'client-count': ClientAggregateCount,
            'breed-counts': BreedAggregateCount,
            'order-item-totals': OrderItemAggregateTotals,
            'client-visit-rate': ClientVisitAggregateRates
        }
    }
</script>

<style lang="scss">
    .dashboard-card {
        .card-title {
            text-align: center
        }
        .card-icon {
            float: left;
            display: flex;
            margin-right: 20px;
            margin-top: 7px;
        }
        .card-content {
            float: left;
            font-size: 18px;
            font-weight: 200;
        }
    }
</style>
