import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const BreedProductsGroupMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BreedProductGroups', [
            'findBreedProductGroup'
        ]),
        breedId() {
            console.log('OVERRIDE COMPUTED - breedId');
            return '';
        },
        breedProductGroup() {
            return this.findBreedProductGroup(this.breedId);
        },
        breedProducts() {
            return !!this.breedProductGroup ? this.breedProductGroup.values : [];
        },
        groupingBreedProducts() {
            return !!this.breedProductGroup && this.breedProductGroup.loading;
        }
    },
    watch: {
        breedId() {
            this.refreshBreedProductGroup();
        }
    },
    methods: {
        ...mapActions('BreedProductGroups', [
            'groupBreedProducts'
        ]),
        tryGroupBreedProducts() {
            const self = this;
            if (!self.breedId) {
                return;
            }
            self.error = '';
            self.groupBreedProducts(self.breedId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProductGroup() {
            if (!this.breedProductGroup || !!this.breedProductGroup.retry) {
                this.tryGroupBreedProducts();
            }
        }
    },
    created() {
        this.refreshBreedProductGroup();
    }
}
