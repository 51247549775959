import { Api } from '../../services/Api';

const state = () => ({
    adding: false,
    updating: false,
    fetching: false,
    fetched: false,
    attempted: false,
    userSettings: []
})

const mutations = {
    setFetching(state) {
        state.fetching = true;
        state.fetched = false;
    },
    setSettings(state, settings) {
        state.userSettings = settings;
        state.fetched = true;
        state.fetching = false;
    },
    setFetched(state, fetched) {
        state.fetched = !!fetched;
        state.fetching = false;
    },
    setAdding(state, adding) {
        state.adding = !!adding;
    },
    setUpdating(state, updating) {
        state.updating = !!updating;
    },
    addSetting(state, setting) {
        var local = state.userSettings.filter(us => us.key !== setting.key);
        local.push(setting);
        state.userSettings = local;
    },
    setAttempted(state) {
        state.attempted = true;
    }
}

const actions = {
    getUserSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('UserSetting')
                .then(response => {
                    commit('setSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetched', false);
                    reject(error);
                })
                .finally(() => commit('setAttempted'));
        });
    },
    addUserSetting({ commit }, setting) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('UserSetting', setting)
                .then(response => {
                    commit('addSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateUserSetting({ commit }, setting) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('UserSetting', setting)
                .then(response => {
                    commit('addSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}