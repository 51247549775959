import { mapActions, mapState } from "vuex";
import { ContractHelper } from "../../helpers/ContractHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ContractTemplateHtmlCacheMixin } from "./ContractTemplateHtmlCacheMixin";

export const ContractTemplateUpdateMixin = {
    mixins: [ContractTemplateHtmlCacheMixin],
    data() {
        return {
            html: '',
            requiresSignature: false,
            contractTemplateUpdateValidator: ContractHelper.getTemplateUpdateValidator()
        }
    },
    computed: {
        ...mapState('ContractTemplates', [
            'uploadingContractTemplate'
        ]),
        ...mapState('ApplicationEvents', [
            'contractTemplateUpdatedEvent'
        ]),
        contractTemplateUpdateModel() {
            return {
                templateTypeId: this.templateTypeId,
                html: this.html,
                requiresSignature: this.requiresSignature
            }
        },
        contractTemplateUpdateModelValid() {
            return !!this.contractTemplate && this.contractTemplateUpdateValidator.isValid;
        },
        contractTemplateUpdateModelModified() {
            const model = this.contractTemplateUpdateModel;
            return model.html !== this.contractTemplateHtmlString || model.requiresSignature !== this.contractTemplate.requiresSignature;
        }
    },
    watch: {
        contractTemplate() {
            this.refreshContractTemplateModel();
        },
        contractTemplateHtmlObject() {
            this.refreshContractTemplateModel();
        }
    },
    methods: {
        ...mapActions('ContractTemplates', [
            'updateContractTemplate',
            'deleteContractTemplate'
        ]),
        refreshContractTemplateModel() {
            this.html = this.contractTemplateHtmlString
            this.requiresSignature = !!this.contractTemplate && this.contractTemplate.requiresSignature;
        },
        tryUpdateContractTemplate() {
            const self = this;
            self.error = '';
            self.contractTemplateUpdateValidator.check(self.contractTemplateUpdateModel);
            if (!self.contractTemplateUpdateModelValid) {
                return;
            }
            if (!self.contractTemplateUpdateModelModified) {
                return;
            }
            self.updateContractTemplate(self.contractTemplateUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteContractTemplate() {
            const self = this;
            if (!self.contractTemplate) {
                return;
            }
            self.error = '';
            self.deleteContractTemplate({ key: self.contractTemplateCacheKey, id: self.templateTypeId })
                .then(() => self.afterContractTemplateDelete())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterContractTemplateDelete() {
            // override if necessary
        }
    },
    created() {
        this.refreshContractTemplateModel();
    }
}
