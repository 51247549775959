<template>
    <div>
        <div class="form-group">
            <label for="email">{{$t('Line')}} 1<span class="required-field"></span></label>
            <input v-model="line1" @blur="submitAddress" type="text" class="form-control" :placeholder="`${$t('Line')} 1`">
            <validation-message v-if="validator.line1" :message="validator.line1"></validation-message>
        </div>
        <div class="form-group">
            <label>{{$t('Line')}} 2 <span class="optional-field">({{$t('optional')}})</span></label>
            <input v-model="line2" @blur="submitAddress" type="text" class="form-control" :placeholder="`${$t('Line')} 2`">
        </div>
        <div class="form-group">
            <label>{{$t('Line')}} 3 <span class="optional-field">({{$t('optional')}})</span></label>
            <input v-model="line3" @blur="submitAddress" type="text" class="form-control" :placeholder="`${$t('Line')} 3`">
        </div>
        <div class="form-group">
            <label>{{$t('Town')}} <span class="optional-field">({{$t('optional')}})</span></label>
            <input v-model="town" @blur="submitAddress" type="text" class="form-control" :placeholder="$t('Town')">
        </div>
        <div class="form-group">
            <label>{{$t('County')}} <span class="optional-field">({{$t('optional')}})</span></label>
            <input v-model="county" @blur="submitAddress" type="text" class="form-control" :placeholder="$t('County')">
        </div>
        <div class="form-group">
            <label for="email">{{$t('Post Code')}}<span class="required-field"></span></label>
            <input v-model="postCode" @blur="submitAddress" type="text" class="form-control" :placeholder="$t('Post Code')">
            <validation-message v-if="validator.postCode" :message="validator.postCode"></validation-message>
        </div>
    </div>
</template>

<script>
    import { AddressHelper } from "../../../helpers/AddressHelper";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'AddressManualInput',
        data() {
            return {
                line1: '',
                line2: '',
                line3: '',
                town: '',
                county: '',
                postCode: '',
                validator: AddressHelper.getAddressValidator()
            }
        },
        props: {
            value: Object
        },
        computed: {
            address() {
                return {
                    line1: this.line1,
                    line2: this.line2,
                    line3: this.line3,
                    town: this.town,
                    county: this.county,
                    postCode: this.postCode
                }
            }
        },
        watch: {
            value(address) {
                this.setAddress(address);
            }
        },
        methods: {
            submitAddress() {
                this.validator.check(this.address);
                if (this.validator.isValid) {
                    this.$emit('input', this.address);
                }
            },
            setAddress(address) {
                this.line1 = !!address ? address.line1 : '';
                this.line2 = !!address ? address.line2 : '';
                this.line3 = !!address ? address.line3 : '';
                this.town = !!address ? address.town : '';
                this.county = !!address ? address.county : '';
                this.postCode = !!address ? address.postCode : '';
            }
        },
        created() {
            this.setAddress(this.value);
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
