import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteDepositSettingMixin } from "./SiteDepositSettingMixin";

export const SiteDepositSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteDepositSettingMixin],
    data() {
        return {
            isRequired: false,
            isPercentage: false,
            percentage: 0,
            amount: 0,
            hasDueDate: false,
            daysPrior: 0
        }
    },
    computed: {
        settingEditModel() {
            return {
                isRequired: this.isRequired,
                isPercentage: this.isPercentage,
                percentage: this.percentage,
                fixedAmount: this.amount,
                hasDueDate: this.hasDueDate,
                daysPrior: this.daysPrior
            }
        },
        settingEditModelValid() {
            const model = this.settingEditModel;
            const fixedAmount = !isNaN(model.fixedAmount) && !(model.fixedAmount < 0);
            const percentage = !isNaN(model.percentage) && !(model.percentage < 0);
            const daysPrior = !isNaN(model.daysPrior) && !(model.daysPrior < 0);
            return fixedAmount && percentage && daysPrior;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.isRequired = this.settingObject.isRequired;
            this.isPercentage = this.settingObject.isPercentage;
            this.percentage = this.settingObject.percentage;
            this.amount = this.settingObject.fixedAmount;
            this.hasDueDate = this.settingObject.hasDueDate;
            this.daysPrior = this.settingObject.daysPrior;
        }
    }
}
