<template>
    <tr class="link pointer" v-bind:class="classType">
        <td class="text-truncate">{{lastUpdatedDateTime}}</td>
        <td class="text-truncate">{{fullName}}</td>
        <td class="text-truncate">{{methodTypeDescription}}</td>
        <td class="text-truncate">{{communicationTypeDescription}}</td>
    </tr>
</template>

<script>
    import { CommunicationItemMixin } from "./CommunicationItemMixin";

    export default {
        name: 'CommunicationItemDesktop',
        mixins: [CommunicationItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
