<template>
    <div>
        <div class="form-group">
            <label>{{$t('Subject')}}</label>
            <input v-model="subject" class="form-control" maxlength="100">
            <validation-message v-if="emailMarketingTemplateValidator.subject" :message="emailMarketingTemplateValidator.subject"></validation-message>
        </div>
        <stripo-templator ref="stripoTemplator" v-if="displayTemplator" v-model="stripoModel" :accountId="accountId" @loading="onLoading"></stripo-templator>
    </div>
</template>

<script>
    import { AccountCacheMixin } from "../../../mixins/Account/AccountCacheMixin";
    import { EmailMarketingTemplatesAddMixin } from "../../Shared/EmailMarketingTemplatesAddMixin";
    import StripoTemplator from "../../Shared/Stripo/StripoTemplator.vue";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { mapState } from "vuex";

    export default {
        name: 'EmailMarketingTemplateAdd',
        mixins: [EmailMarketingTemplatesAddMixin, AccountCacheMixin],
        props: {
            id: Number
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveEmailMarketingTemplateEvent'
            ]),
            communicationTypeId() {
                return this.id;
            },
            stripoModel: {
                get() {
                    return {
                        html: this.html,
                        css: this.css
                    };
                },
                set(value) {
                    this.html = value.html;
                    this.css = value.css;
                    this.tryUploadEmailMarketingTemplate();
                }
            },
            displayTemplator() {
                return !!this.html && !!this.css && !!this.account;
            }
        },
        methods: {
            onSave() {
                this.$refs.stripoTemplator.emitTemplate();
            },
            onLoading(value) {
                this.$emit('loading', value);
            }
        },
        watch: {
            saveEmailMarketingTemplateEvent() {
                this.onSave();
            }
        },
        components: {
            'stripo-templator': StripoTemplator,
            'validation-message': ValidationMessage
        }
    }
</script>
