import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { TimeFormatSettingAddMixin } from "./TimeFormatSettingAddMixin";

export const TimeFormatSettingUploadMixin = {
    mixins: [TimeFormatSettingAddMixin],
    computed: {
        timeFormatSettingUpdateModel() {
            return Object.assign({}, this.timeFormatSetting, { timeFormatId: this.timeFormatId });
        },
        timeFormatSettingUpdateModelValid() {
            const model = this.timeFormatSettingUpdateModel;
            return !!model.id && model.timeFormatId > -1;
        },
        timeFormatSettingUpdateModelChanged() {
            return this.timeFormatSettingUpdateModel.timeFormatId !== this.timeFormatSetting.timeFormatId;
        }
    },
    watch: {
        timeFormatSetting() {
            this.refreshTimeFormatUpload();
        }
    },
    methods: {
        ...mapActions('TimeFormatSettings', [
            'updateTimeFormatSetting'
        ]),
        refreshTimeFormatUpload() {
            this.timeFormatId = !!this.timeFormatSetting ? this.timeFormatSetting.timeFormatId : 0;
        },
        uploadTimeFormatSetting() {
            if (!!this.timeFormatSetting) {
                this.tryUpdateTimeFormatSetting();
            } else {
                this.tryAddTimeFormatSetting();
            }
        },
        tryUpdateTimeFormatSetting() {
            const self = this;
            if (!self.timeFormatSettingUpdateModelValid) {
                return;
            }
            if (!self.timeFormatSettingUpdateModelChanged) {
                return;
            }
            self.error = '';
            self.updateTimeFormatSetting({ key: self.siteId, payload: self.timeFormatSettingUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTimeFormatUpload();
    }
}
