<template>
    <div v-if="show">
        <div class="title-divider">
            <h2>{{petName}}</h2>
        </div>
        <order-item v-for="(item, itemIndex) in orderItems" :index="index" :itemIndex="itemIndex" :key="itemIndex"></order-item>
    </div>
</template>

<script>
    import AppointmentTotalOrderItem from './AppointmentTotalOrderItem.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'AppointmentTotalOrderSummary',
        props: {
            index: Number
        },
        computed: {
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            appointmentOrder() {
                return this.indexAppointmentOrder(this.index);
            },
            pet() {
                return this.appointmentOrder.pet;
            },
            petName() {
                return !!this.pet ? this.pet.name : 'Pet ' + (this.index + 1);
            },
            orderItems() {
                return this.appointmentOrder.orderItems;
            },
            show() {
                return !!this.orderItems && this.orderItems.length > 0;
            }
        },
        components: {
            'order-item': AppointmentTotalOrderItem
        }
    }
</script>
