<template>
    <div class="form-group row">
        <div v-if="!!country" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Country')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="col-md-4">
                <label>{{$t('Country')}}</label>
            </div>
            <div class="col-md-8">
                <div v-if="countryOption">
                    <div class="mb-1">
                        {{countryOption.name}}
                    </div>
                    <a @click="countryOption=null" class="link pointer">{{$t('Clear')}}</a>
                </div>
                <template v-else>
                    <country-search v-model="countryOption"></country-search>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { CountryAddMixin } from "../../Shared/Country/CountryAddMixin";
    import CountryOptionSearch from "../../Shared/Country/CountryOptionSearch.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AccountSettingCacheMixin } from "../../../mixins/Settings/Account/AccountSettingCacheMixin";

    export default {
        name: 'SiteAddCountry',
        mixins: [CountryAddMixin, AccountSettingCacheMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            accountSettingKey() {
                return 'DefaultCountry';
            },
            canAddCountry() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !this.countryOption || !!this.country
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.addingCountry
                }
            }
        },
        watch: {
            accountSetting() {
                this.setDefaultCountry();
            },
            canAddCountry(value) {
                if (value) {
                    this.tryAddCountry();
                }
            },
            saveSiteEvent() {
                if (this.canAddCountry) {
                    this.tryAddCountry();
                }
            }
        },
        methods: {
            setDefaultCountry() {
                this.countryOption = !!this.accountSettingObject ? this.accountSettingObject : null;
            },
            tryGetCountries() {
                // do nothing
            }
        },
        components: {
            'country-search': CountryOptionSearch,
            'error-banner': ErrorBanner
        },
        created() {
            this.setDefaultCountry();
        },
    }
</script>
