import { mapState, mapActions } from 'vuex';
import { EmailTemplateSettingCacheMixin } from "./EmailTemplateSettingCacheMixin";
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const EmailTemplateSettingAddMixin = {
    mixins: [EmailTemplateSettingCacheMixin],
    computed: {
        ...mapState('EmailTemplateSettings', [
            'addingEmailTemplateSettings'
        ])
    },
    methods: {
        ...mapActions('EmailTemplateSettings', [
            'addEmailTemplateSetting'
        ]),
        tryAddEmailTemplateSetting() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.addEmailTemplateSetting()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
