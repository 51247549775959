import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper.js';

export const WeightCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Weights', [
            'findWeightCache'
        ]),
        petId() {
            console.log('!!!OVERRIDE COMPUTED - petId!!!');
        },
        weightCache() {
            return this.findWeightCache(this.petId);
        },
        loadingWeights() {
            return this.weightCache && this.weightCache.loading;
        },
        weights() {
            return this.weightCache ? this.weightCache.values : [];
        }
    },
    watch: {
        petId() {
            this.refreshWeights();
        }
    },
    methods: {
        ...mapActions('Weights', [
            'getWeights'
        ]),
        tryGetWeights() {
            const self = this;
            if (!self.petId) {
                return;
            }
            self.error = '';
            self.getWeights(self.petId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshWeights() {
            if (!this.weightCache || !!this.weightCache.retry) {
                this.tryGetWeights();
            }
        }
    },
    created() {
        this.refreshWeights();
    }
}
