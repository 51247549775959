import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const PaymentProvidersCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PaymentProviders', [
            'fetchingPaymentProviders',
            'fetchedPaymentProviders',
            'paymentProviders'
        ])
    },
    methods: {
        ...mapActions('PaymentProviders', [
            'fetchPaymentProviders'
        ]),
        refreshPaymentProviders() {
            if (!this.fetchedPaymentProviders && !this.fetchingPaymentProviders) {
                this.tryFetchPaymentProviders();
            }
        },
        tryFetchPaymentProviders() {
            const self = this;
            self.error = '';
            self.fetchPaymentProviders()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentProviders();
    }
}
