<template>
    <datepicker v-model="date"
                :format="customFormatter" 
                :input-class="localInputClass"
                :calendar-button="showCalendarButton" 
                calendar-button-icon="far fa-calendar-alt">
    </datepicker>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import { DatePickerFormatterMixin } from './DatePickerFormatterMixin';

    export default {
        name: 'MyDatePicker',
        mixins: [DatePickerFormatterMixin],
        props: {
            value: Date,
            inputClass: String,
            showCalendarButton: false
        },
        computed: {
            date: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            localInputClass() {
                return ['form-control caleran pointer', this.inputClass].join(' ');
            }
        },
        components: {
            Datepicker
        }
    }
</script>
