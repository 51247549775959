<template>
    <div class="row form-group">
        <div class="col-md-4 col-6">
            <label>{{$t('All day?')}}</label>
        </div>
        <div class="col-md-8 col-6">
            <label class="ps-toggle-switch">
                <input v-model="allDay" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{value ? $t('Yes') : $t('No')}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EventAllDayToggle',
        props: {
            value: Boolean
        },
        computed: {
            allDay: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
