<template>
    <tr @click="signalProcess">
        <td>
            <div class="client-row">
                <div class="client-row-details">
                    <div class="crd-name">
                        {{currencySymbol}}{{amount}}
                    </div>
                    <div class="crd-contact">
                        {{date}}
                    </div>
                    <div class="crd-contact">
                        {{status}}
                    </div>
                    <div class="crd-pets">
                        {{provider}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { CheckoutListItemMixin } from "./CheckoutListItemMixin";

    export default {
        name: 'CheckoutListItemMobile',
        mixins: [CheckoutListItemMixin, CountryCacheMixin],
        methods: {
            tryGetCountries() {
                // do nothing
            }
        }
    }
</script>
