<template>
    <div class="dropdown w-100">
        <button aria-expanded="false"
                aria-haspopup="true"
                class="filter-dd text-left w-100 mw-400"
                data-toggle="dropdown"
                type="button">
            <multi-select-display :values="summary"></multi-select-display>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100 mw-400">
            <dropdown-item v-for="site in selectedSites" v-model="selectedTags" :site="site" :key="site.id"></dropdown-item>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { SiteCacheMixin } from "../SiteCacheMixin";
    import TagTypeMultiSelectDropdownItem from './TagTypeMultiSelectDropdownItem.vue';
    import MultiSelectDropdownDisplay from "../Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'TagTypeMultiSelectDropdown',
        mixins: [SiteCacheMixin],
        props: {
            value: Array,
            siteIds: Array
        },
        computed: {
            ...mapState('TagTypes', [
                'tagTypesCaches'
            ]),
            allTagTypes() {
                return [].concat(...this.tagTypesCaches.map(ttc => ttc.values))
            },
            selectedTags: {
                get() {
                    return this.value.map(id => this.allTagTypes.find(tt => tt.id === id)).filter(tt => !!tt);
                },
                set(value) {
                    this.$emit('input', value.map(v => v.id));
                }
            },
            selectedSites() {
                return this.siteIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s);
            },
            summary() {
                return this.selectedTags.length > 0 ? this.selectedTags.map(tt => tt.name) : ['Tags'];
            }
        },
        components: {
            'dropdown-item': TagTypeMultiSelectDropdownItem,
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
