import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { CountryAddMixin } from "./CountryAddMixin";

export const CountryUploadMixin = {
    mixins: [CountryAddMixin],
    computed: {
        ...mapState('Countries', [
            'updatingCountry'
        ]),
        countryUpdateModel() {
            return Object.assign({}, this.country, {
                countryId: !!this.countryOption ? this.countryOption.id : '',
                name: !!this.countryOption ? this.countryOption.name : '',
                currency: !!this.countryOption ? this.countryOption.currency : '',
                currencySymbol: !!this.countryOption ? this.countryOption.currencySymbol : ''
            });
        },
        countryUpdateModelValid() {
            const model = this.countryUpdateModel;
            return !!model.id && !!model.countryId;
        }
    },
    watch: {
        country() {
            this.refreshCountryOption();
        }
    },
    methods: {
        ...mapActions('Countries', [
            'updateCountry'
        ]),
        tryUploadCountry() {
            if (!this.addingCountry && !this.updatingCountry) {
                if (!!this.country) {
                    this.tryUpdateCountry();
                } else {
                    this.tryAddCountry();
                }
            }
        },
        refreshCountryOption() {
            this.countryOption = !!this.country ? {
                id: this.country.countryId,
                name: this.country.name,
                currency: this.country.currency,
                currencySymbol: this.country.currencySymbol
            } : null;
        },
        tryUpdateCountry() {
            const self = this;
            if (self.countryUpdateModel.countryId === self.country.countryId) {
                return;
            }
            if (!self.countryUpdateModelValid) {
                return;
            }
            self.error = '';
            self.updateCountry(self.countryUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCountryOption();
    }
}
