<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Status')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select custom-select form-control">
                <button type="button" 
                        class="btn dropdown-toggle btn-light btn-appointment-status" 
                        data-toggle="dropdown" 
                        role="combobox" 
                        aria-owns="bs-select-10" 
                        aria-haspopup="listbox" 
                        aria-expanded="false" 
                        title="Booked"
                        v-bind:style="{'border-color': appointmentStatusColor }">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{$t(status.name)}}</div>
                        </div>
                    </div>
                </button>
                <div class="dropdown-menu" style="max-height: 435px; overflow: hidden; min-height: 126px;">
                    <div class="inner show" role="listbox" id="bs-select-10" tabindex="-1" aria-activedescendant="bs-select-10-0" style="max-height: 401px; overflow-y: auto; min-height: 92px;">
                        <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                            <li v-for="s in statuses">
                                <a @click="status=s" role="option" class="dropdown-item" v-bind:class="{ 'active selected': status.id === s.id }">
                                    <span class=" bs-ok-default check-mark"></span>
                                    <span class="text">
                                        {{$t(s.name)}}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CalendarHelper } from '../../../helpers/CalendarHelper';

    export default {
        name: 'AppointmentOverviewStatus',
        props: {
            value: Object
        },
        computed: {
            statuses() {
                return [
                    { id: 0, name: 'Booked' },
                    { id: 1, name: 'Cancelled' },
                    { id: 2, name: 'Completed' },
                    { id: 3, name: 'No show' }
                ]
            },
            status: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            appointmentStatusColor() {
                return !!this.status ?
                    CalendarHelper.getAppointmentStatusColour(this.status.id) :
                    CalendarHelper.getAppointmentStatusColour(0);
            }
        }
    }
</script>

<style scoped>
    .btn-appointment-status {
        border-left: solid 0.5rem #00BFFF;
    }
</style>
