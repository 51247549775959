import { mapActions, mapState } from "vuex"

export const LogoSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('LogoSettings', [
            'fetchingLogoSettings',
            'fetchedLogoSettings',
            'logoSettings'
        ]),
        maxUploadSize() {
            return !!this.logoSettings ? this.logoSettings.maxMbUploadSize + 'MB' : '';
        }
    },
    methods: {
        ...mapActions('LogoSettings', [
            'fetchLogoSettings'
        ]),
        refreshLogoSettings() {
            if (!this.fetchedLogoSettings && !this.fetchingLogoSettings) {
                this.fetchLogoSettings();
            }
        }
    },
    created() {
        this.refreshLogoSettings();
    }
}