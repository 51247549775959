import { Api } from "../../services/Api";

const state = () => ({
    reportCsvCaches: []
})

const getters = {
    findReportCsvCache: (state) => key => state.reportCsvCaches.find(rcc => rcc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.reportCsvCaches.find(rcc => rcc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.reportCsvCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setCsv(state, { key, csv }) {
        const existing = state.reportCsvCaches.find(rcc => rcc.key === key);
        if (!!existing) {
            existing.value = csv;
            existing.loading = false;
        } else {
            state.reportCsvCaches.push({ key: key, value: csv, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.reportCsvCaches.find(rcc => rcc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchCsv({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Report/' + id + '/Csv')
                .then(response => {
                    commit('setCsv', { key: id, csv: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
