<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-folder-open fa-1x"></i>
                    {{title}}
                </h1>
            </div>
        </header>
        <div class="alert-wrapper">
            <error-banner v-if="error" :message="error"></error-banner>
            <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                <div class="alert-content">
                    <p>This campaign has been sent.</p>
                </div>
            </div>
        </div>
        <div class="content fixed-action-btns-visible">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-md-8">
                            <section class="mb-3">
                                <div aria-controls="campaign-settings" aria-expanded="true" data-target="#campaign-settings" data-toggle="collapse" class="accordion-header">
                                    {{$t('Campaign Settings')}}
                                    <i class="far fa-chevron-down ml-auto"></i>
                                </div>
                                <div id="campaign-settings" class="collapse show">
                                    <div class="card card-body">
                                        <fieldset v-bind:disabled="completed">
                                            <slotted-input v-model="subject" :title="$t('Subject')">
                                                <validation-message v-if="campaignValidator.subject" :message="campaignValidator.subject"></validation-message>
                                            </slotted-input>
                                            <method-select :value="methodTypeId" :disabled="true"></method-select>
                                            <div class="form-group row">
                                                <div class="col-md-4">
                                                    <label>{{$t('Send Date')}}</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <date-picker v-model="sendDate"></date-picker>
                                                </div>
                                            </div>
                                            <slotted-toggle v-if="!completed" v-model="confirmed" :title="$t('Status')">
                                                <span class="d-inline-block ml-2">{{$t(confirmedMessage)}}</span>
                                            </slotted-toggle>
                                            <div v-if="hasErrors" class="form-group row">
                                                <div class="col-md-4">
                                                    <label>Errors</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <div v-for="(e, index) in errors" :key="index">
                                                        - {{e}}
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-4">
                            <section class="mb-3">
                                <div aria-controls="campaign-filters" aria-expanded="true" data-target="#campaign-filters" data-toggle="collapse" class="accordion-header">
                                    Recipient Filter
                                    <i class="far fa-chevron-down ml-auto"></i>
                                </div>
                                <div id="campaign-filters" class="collapse show">
                                    <div class="card card-body">
                                        <fieldset v-bind:disabled="completed">
                                            <div class="form-group">
                                                <label>{{$t('Sites')}}</label>
                                                <sites-select v-model="siteIds"></sites-select>
                                                <validation-message v-if="campaignValidator.siteIds" :message="campaignValidator.siteIds"></validation-message>
                                            </div>
                                            <div class="form-group">
                                                <label>{{$t('Tags')}}</label>
                                                <tags-select v-model="tagTypeIds" :siteIds="siteIds"></tags-select>
                                            </div>
                                            <div class="form-group">
                                                <label>{{$t('Breeds')}}</label>
                                                <breeds-select v-model="breedIds"></breeds-select>
                                            </div>
                                            <div class="form-group">
                                                <label>{{$t('Not Visited Since')}}</label>
                                                <date-picker v-model="notVisitedSinceDate"></date-picker>
                                            </div>
                                            <div class="form-group">
                                                <label>{{$t('Reach')}}</label>
                                                {{reach}} {{$t('client(s)')}}
                                            </div>
                                            <div class="form-group">
                                                <button @click="refresh" class="btn btn-sm btn-outline" v-bind:disabled="loadingCampaigns">
                                                    <i class="fal fa-refresh"></i>
                                                    {{$t('Refresh')}}
                                                </button>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav d-flex flex-md-row flex-wrap justify-content-md-between campaign-update">
            <div class="fixed-action-btns-left">
                <div class="fixed-action-btns-left-left">
                    <button @click="save" type="button" class="btn btn-primary" v-bind:disabled="completed || loadingCampaigns">
                        {{$t('Save')}}
                        <span v-if="loadingCampaigns" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" type="button" class="btn btn-outline-secondary">
                        {{$t('Cancel')}}
                    </button>
                </div>
                <div class="fixed-action-btns-left-right">
                    <button @click="preview" type="button" class="btn btn-secondary">
                        <i class="far fa-eye"></i>
                    </button>
                    <button v-if="methodTypeId == 1" @click="test" type="button" class="btn btn-outline-secondary">
                        {{$t('Test')}}
                    </button>
                </div>
            </div>
            <div class="fixed-action-btns-right">
                <delete-button @clicked="remove"></delete-button>
            </div>
        </div>
        <campaign-preview ref="campaignPreview" :campaign-id="campaignId"></campaign-preview>
        <modal-dialog ref="deleteDialog" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning  alert-icon">
                <div class="alert-content">
                    <p>{{$t('Are you sure you want to delete')}} {{campaignName}}?</p>
                </div>
                <button @click="tryDeleteCampaign" v-bind:disabled="loadingCampaigns" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="loadingCampaigns" class="inline-loader"></span>
                    <template v-else>
                        {{$t('Yes')}}
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="deleteCancel" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
        <modal-dialog v-if="methodTypeId == 1" ref="confirmTestDialog" :title="$t('Test Campaign')">
            <test-send :campaign="campaign"></test-send>
        </modal-dialog>
    </div>
</template>

<script>
    import router from '../../../../routing';
    import BreedMultiSelectDropdown from '../../../Breeds/BreedMultiSelectDropdown.vue';
    import SlottedInput from '../../../Shared/Input/SlottedInput.vue';
    import SiteMultiSelectDropdown from '../../../Shared/Site/SiteMultiSelectDropdown.vue';
    import TagTypeMultiSelectDropdown from '../../../Shared/Tags/TagTypeMultiSelectDropdown.vue';
    import MyDatePicker from '../../../Shared/Time/MyDatePicker.vue';
    import { CampaignUpdateMixin } from "../CampaignUpdateMixin";
    import moment from 'moment';
    import CampaignMethodSelect from './CampaignMethodSelect.vue';
    import SlottedToggle from '../../../Shared/Input/SlottedToggle.vue';
    import CampaignPreview from './CampaignPreview.vue';
    import DeleteButton from '../../../Shared/DeleteButton.vue';
    import ModalDialog from '../../../Layout/ModalDialog.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import CampaignTestSend from './CampaignTestSend.vue';

    export default {
        name: 'CampaignUpdate',
        mixins: [CampaignUpdateMixin],
        computed: {
            campaignId() {
                return this.$route.params.id;
            },
            campaignName() {
                return !!this.campaign ? this.campaign.subject : 'Campaign';
            },
            title() {
                return `${this.$t('Edit')} ` + this.campaignName;
            },
            methodTypeId() {
                return !!this.campaign ? this.campaign.methodType.id : 0;
            },
            reach() {
                return !!this.campaign ? this.campaign.reach : 0;
            },
            errors() {
                return !!this.campaign ? this.campaign.errors.split('. ').filter(e => !!e) : [];
            },
            hasErrors() {
                return this.errors.length > 0;
            },
            confirmedMessage() {
                return !!this.confirmed ? 'Confirmed' : 'NOT Confirmed';
            },
            completed() {
                return !!this.campaign && this.campaign.completed;
            },
            sendDate: {
                get() {
                    const m = moment(this.sendDateUtc);
                    return m.isValid() ? m.toDate() : null;
                },
                set(value) {
                    this.sendDateUtc = moment(value).utc().format();
                }
            },
            notVisitedSinceDate: {
                get() {
                    const m = moment(this.notVisitedSinceUtc);
                    return m.isValid() ? m.toDate() : null;
                },
                set(value) {
                    this.notVisitedSinceUtc = moment(value).utc().format();
                }
            }
        },
        methods: {
            save() {
                this.tryUpdateCampaign();
            },
            refresh() {
                this.tryForceUpdateCampaign();
            },
            cancel() {
                router.go(-1);
            },
            remove() {
                const element = this.$refs.deleteDialog.$el;
                $(element).modal('show');
            },
            preview() {
                const element = this.$refs.campaignPreview.$el;
                $(element).modal('show');
            },
            afterCampaignDelete() {
                this.$refs.deleteCancel.click();
                router.go(-1);
            },
            test() {
                const element = this.$refs.confirmTestDialog.$el;
                $(element).modal('show');
            }
        },
        components: {
            'slotted-input': SlottedInput,
            'date-picker': MyDatePicker,
            'sites-select': SiteMultiSelectDropdown,
            'tags-select': TagTypeMultiSelectDropdown,
            'breeds-select': BreedMultiSelectDropdown,
            'method-select': CampaignMethodSelect,
            'slotted-toggle': SlottedToggle,
            'campaign-preview': CampaignPreview,
            'delete-button': DeleteButton,
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'test-send': CampaignTestSend
        }
    }
</script>

<style scoped>
    .campaign-update .fixed-action-btns-left {
        display: flex;
    }

    @media only screen and (max-width: 768px) {
        .campaign-update .fixed-action-btns-left {
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .campaign-update .fixed-action-btns-left .btn {
            margin-right: 0;
        }

        .campaign-update .fixed-action-btns-left-left .btn:first-child {
            margin-right: 0.5rem;
        }

        .campaign-update .fixed-action-btns-left-right .btn:first-child {
            margin-right: 0.5rem;
        }

        .campaign-update .fixed-action-btns-right {
            width: 100%;
            margin-top: 1rem;
            text-align: right;
        }
    }
</style>
