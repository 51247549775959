import { Api } from '../../services/Api';
import { ArrayHelper } from '../../helpers/ArrayHelper.js';

const state = () => ({
    addingPet: false,
    petCaches: [],
    searchingPets: false
})

const getters = {
    findPetCache: (state) => key => state.petCaches.find(pc => pc.key === key),
    findPets: (state) => key => {
        const cache = state.petCaches.find(pc => pc.key === key);
        return !!cache ? cache.values : [];
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.petCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.petCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setPets(state, { keys, pets }) {
        const groups = ArrayHelper.groupBy(pets, 'clientId');
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const group = groups.find(g => g.key === key);
            const values = !!group ? group.values : [];
            const existing = state.petCaches.find(pc => pc.key === key);
            if (!!existing) {
                const index = state.petCaches.indexOf(existing);
                state.petCaches.splice(index, 1, Object.assign({}, existing, { values: values, loading: false }));
            } else {
                state.petCaches.push({ key: key, values: values, loading: false });
            }
        }
    },
    setRetry(state, key) {
        const existing = state.petCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setAdding(state, adding) {
        state.addingPet = !!adding;
    },
    appendPet(state, { key, pet }) {
        const existing = state.petCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.values.push(pet);
        } else {
            state.petCaches.push({ key: key, values: [pet], loading: false });
        }
    },
    insertPet(state, { key, pet }) {
        const existing = state.petCaches.find(pc => pc.key === key);
        if (!!existing) {
            const old = existing.values.find(p => p.id === pet.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, pet);
            } else {
                existing.values.push(pet);
            }
        } else {
            state.petCaches.push({ key: key, values: [pet], loading: false });
        }
    },
    removePet(state, { key, pet }) {
        const existing = state.petCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(p => p.id !== pet.id);
        }
    },
    setSearching(state, searching) {
        state.searchingPets = !!searching;
    }
}

const actions = {
    getPets({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: clientId, loading: true });
            Api.get('Client/' + clientId + '/Pet')
                .then(response => {
                    commit('setPets', { keys: [clientId], pets: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', clientId);
                    reject(error);
                })
        });
    },
    searchPets({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setSearching', true);
            Api.post('Pet/Search', payload)
                .then(response => {
                    commit('setPets', { keys: keys, pets: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => {
                    commit('setSearching', false);
                });
        });
    },
    addPet({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            commit('setLoading', { key: key, loading: true });
            Api.post('Pet', payload)
                .then(response => {
                    commit('appendPet', { key: key, pet: response.data });
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('PetSearchesV2/clearPetSearches', null, { root: true });
                    commit('ApplicationEvents/triggerPetAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => {
                    commit('setAdding', false);
                    commit('setLoading', { key: key, loading: false });
                });
        });
    },
    updatePet({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Pet', payload)
                .then(response => {
                    commit('insertPet', { key: key, pet: response.data });
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('PetSearchesV2/clearPetSearches', null, { root: true });
                    commit('ApplicationEvents/triggerPetUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deletePet({ commit }, pet) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: pet.clientId, loading: true });
            Api.delete('Pet/' + pet.id)
                .then(response => {
                    commit('removePet', { key: pet.clientId, pet: pet });
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('PetSearchesV2/clearPetSearches', null, { root: true });
                    commit('ApplicationEvents/triggerPetDeleted', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: pet.clientId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
