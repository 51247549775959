<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>
                Send password link
            </label>
        </div>
        <div class="col-md-8">
            <button @click="submit" class="btn btn-sm btn-outline" :disabled="disabled">
                <span v-if="loading" class="inline-loader"></span>
                <template v-else>
                    <i class="fal fa-paper-plane"></i>
                </template>
            </button>
            <label v-if="error">
                {{error}}
            </label>
            <label v-if="success">
                Password email sent!
            </label>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";

    export default {
        name: 'UserPasswordSendButton',
        data() {
            return {
                error: '',
                loading: false,
                success: false
            }
        },
        props: {
            user: Object,
            email: String
        },
        computed: {
            disabled() {
                return this.loading || !this.user.email || this.user.email !== this.email;
            }
        },
        watch: {
            user() {
                this.error = '';
                this.success = false;
            }
        },
        methods: {
            submit() {
                const self = this;
                self.error = '';
                self.loading = true;
                self.success = false;
                Api.post('Credentials/Set/Send', { userId: self.user.id })
                    .then(() => self.success = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        }
    }
</script>
