<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapseOverviewDeposit" data-toggle="collapse">
            {{$t('Deposit')}}
            <div class="total-figure ml-auto"></div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapseOverviewDeposit">
            <div class="card card-body">
                <fieldset :disabled="disabled">
                    <section class="group">
                        <toggle-input v-model="isRequired" :title="$t('Deposit Required?')"></toggle-input>
                        <template v-if="isRequired">
                            <provider-select v-if="isPaymentAdmin" v-model="providerTypeId"></provider-select>
                            <money-input v-model="amount" :title="$t('Amount')"></money-input>
                            <integer-input v-model="dueDaysPrior" :title="$t('Due days prior')"></integer-input>
                        </template>
                    </section>
                </fieldset>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { PaymentApiUserCacheMixin } from "../../../mixins/Payments/PaymentApiUserCacheMixin";
    import { PaymentCheckoutsAddMixin } from "../../Payments/PaymentCheckoutsAddMixin";
    import { AppointmentDepositUpdateMixin } from "../../../mixins/AppointmentDeposits/AppointmentDepositUpdateMixin";
    import moment from 'moment';
    import CheckoutProviderSelect from "../../Payments/CheckoutProviderSelect.vue";
    import MoneyInput from "../../Shared/Input/MoneyInput.vue";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import IntegerInput from "../../Shared/Input/IntegerInput.vue";

    export default {
        name: 'AppointmentOverviewDeposit',
        mixins: [PaymentApiUserCacheMixin, AppointmentDepositUpdateMixin, PaymentCheckoutsAddMixin],
        props: {
            appointment: Object,
            disabled: Boolean
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ]),
            scheduleId() {
                return !!this.appointment ? this.appointment.scheduleId : '';
            },
            checkoutsReference() {
                return !!this.appointment ? this.appointment.groupId : '';
            }
        },
        watch: {
            saveAppointmentEvent() {
                this.tryUploadAppointmentDeposit();
                if (this.isRequired) {
                    const daysPrior = parseInt(this.dueDaysPrior);
                    this.dueUtc = isNaN(daysPrior) ? null : moment(this.startUtc).subtract(daysPrior, 'd').utc().format();
                    this.tryAddCheckout();
                }
            },
            loadingAppointmentDeposit(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'money-input': MoneyInput,
            'provider-select': CheckoutProviderSelect,
            'toggle-input': SlottedToggle,
            'integer-input': IntegerInput
        }
    }
</script>
