import { mapState, mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SiteIdMixin } from '../../components/Shared/Site/SiteIdMixin';
import { HashHelper } from '../../helpers/HashHelper';
import { SortableSearchMixin } from '../../components/Shared/Searching/SortableSearchMixin';
import { SearchTermDelayMixin } from '../Shared/SearchTermDelayMixin';

export const ServiceProductsSearchMixin = {
    mixins: [SiteIdMixin, SortableSearchMixin, SearchTermDelayMixin],
    data() {
        return {
            error: '',
            take: 10
        }
    },
    computed: {
        ...mapState('ServiceProductSearches', [
            'serviceProductSearchesClearedEvent'
        ]),
        ...mapGetters('ServiceProductSearches', [
            'findServiceProductSearch'
        ]),
        serviceProductSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                take: this.take,
                skip: this.skip,
                ascending: this.ascending
            }
        },
        serviceProductSearchKey() {
            const model = this.serviceProductSearchModel;
            return HashHelper.createStringHash(this.siteId + model.searchTerm + model.sortKey + model.take + model.skip + model.ascending);
        },
        serviceProductSearch() {
            return this.findServiceProductSearch(this.serviceProductSearchKey);
        },
        serviceProducts() {
            return !!this.serviceProductSearch ? this.serviceProductSearch.values : [];
        },
        searchingServiceProducts() {
            return !!this.serviceProductSearch && this.serviceProductSearch.loading;
        },
        finished() {
            return this.serviceProducts.length < this.take;
        },
        pageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        serviceProductSearchKey() {
            this.refreshServiceProductSearch();
        },
        serviceProductSearchesClearedEvent() {
            this.refreshServiceProductSearch();
        }
    },
    methods: {
        ...mapActions('ServiceProductSearches', [
            'searchServiceProducts'
        ]),
        trySearchServiceProducts() {
            const self = this;
            self.error = '';
            self.searchServiceProducts({ key: self.serviceProductSearchKey, payload: self.serviceProductSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshServiceProductSearch() {
            if (!this.serviceProductSearch || !!this.serviceProductSearch.retry) {
                this.trySearchServiceProducts();
            }
        },
        next() {
            if (!this.finished) {
                this.skip += this.take;
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
        },
        skipTo(skip) {
            this.skip = skip;
        }
    },
    created() {
        this.refreshServiceProductSearch();
    }
}
