<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Amount')}} ({{currencySymbol}})</label>
        </div>
        <div class="col-md-8">
            <input v-model="amountInput" @blur="processInput" class="form-control" type="number">
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { ProductInputMixin } from "../Products/ProductInputMixin";
    import { BreedProductUploadMixin } from "../../mixins/Products/BreedProductUploadMixin";

    export default {
        name: 'BreedUpdateProduct',
        mixins: [BreedProductUploadMixin, ProductInputMixin, CountryCacheMixin],
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveBreedEvent'
            ]),
            siteBreedId() {
                return this.id;
            }
        },
        watch: {
            saveBreedEvent() {
                this.tryUploadBreedProduct();
            },
            loadingBreedProduct(newValue) {
                this.$emit('input', newValue);
            }
        },
        methods: {
            refreshBreedProductModel() {
                this.setInitialAmount(this.getDefaultAmount());
            }
        }
    }
</script>
