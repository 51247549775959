<template>
    <div>
        <button ref="displayModalButton" type="button" data-target="#disable-account-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="disable-account-modal" title="Verify Account Closure">
            <section class="rounded-card-white">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="alert alert-warning alert-icon mb-2" role="alert">
                    <div class="alert-content">
                        <div class="alert-content d-flex align-items-center">
                            <p><strong>Warning:</strong> Closing this account will permanently prevent access to it and any associated data.</p>
                        </div>
                    </div>
                </div>
                <div class="w-100 mb-3 text-center">Are you sure you want to <b>close</b> this account?</div>
            </section>
            <div class="d-flex justify-content-center">
                <button @click="tryCloseAccount" type="button" class="btn btn-secondary mr-2" v-bind:disabled="loading">
                    {{$t('Yes')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{$t('No')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import process from 'process';
    import { Api } from '../../services/Api';
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import { mapActions } from 'vuex'
    import ModalDialog from "../Layout/ModalDialog.vue";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'DisableAccountConfirmation',
        data() {
            return {
                error: '',
                loading: false
            }
        },
        methods: {
            ...mapActions('Authentication', [
                'logout'
            ]),
            tryCloseAccount() {
                const self = this;
                if (!!self.loading) {
                    return;
                }
                self.loading = true;
                self.error = '';
                Api.put('Account/Disable')
                    .then(() => {
                        self.afterClosingAccount();
                    })
                    .catch(error => { self.error = ErrorHelper.getError(error) })
                    .finally(() => { self.loading = false })
            },
            afterClosingAccount() {
                process.nextTick(() => {
                    this.$refs.cancelButton.click();
                    this.logout();
                });
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>
