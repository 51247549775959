<template>
    <div>
        <img v-if="image" @click="onClick" alt="Default" :src="image.thumbnails.medium" width="100">
        <template v-else>
            <img @click="onClick" alt="Default" src="../../../assets/images/DogProfile_Blue_300.svg" width="100">
        </template>
    </div>
</template>

<script>
    import { DefaultPetImageCacheMixin } from "../../../mixins/Settings/Site/DefaultPetImageCacheMixin";

    export default {
        name: 'DefaultPetImageMedium',
        mixins: [DefaultPetImageCacheMixin],
        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>
