import { Api } from '../../services/Api';

const state = () => ({
    addingWeight: false,
    weightCaches: []
})

const getters = {
    findWeightCache: (state) => (key) => {
        return state.weightCaches.find(nc => nc.key === key)
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.weightCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.weightCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setWeights(state, { key, weights }) {
        var existing = state.weightCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = weights;
            existing.loading = false;
        } else {
            state.weightCaches.push({ key: key, values: weights, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.weightCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendWeight(state, { key, weight }) {
        var existing = state.weightCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values.push(weight);
        } else {
            state.weightCaches.push({ key: key, values: [weight], loading: false });
        }
    },
    removeWeight(state, { key, id }) {
        var existing = state.weightCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(w => w.id !== id);
        }
    }
}

const actions = {
    addWeight({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Weight', payload)
                .then(response => {
                    commit('appendWeight', { key: key, weight: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    getWeights({ commit }, petId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            Api.get('Pet/' + petId + '/Weight')
                .then(response => {
                    commit('setWeights', { key: petId, weights: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', petId)
                    reject(error);
                });
        });
    },
    deleteWeight({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('Weight/' + id)
                .then(response => {
                    commit('removeWeight', { key: key, id: id });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }))
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
