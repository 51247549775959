<template>
    <div>
        <div class="tab-pane fade show active">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="card card-body">
                <div class="form-group">
                    <label>Name</label>
                    {{clientFullname}}
                </div>
                <div class="form-group">
                    <label>Template</label>
                    {{contractName}}
                </div>
                <div class="form-group wide">
                    <document-send v-model="emailStatus" :contract="contract" :send="sendTrigger" :method-type="1"></document-send>
                    <document-send v-model="smsStatus" :contract="contract" :send="sendTrigger" :method-type="2"></document-send>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button @click="save" class="btn btn-primary" type="button" v-bind:disabled="loading">
                Send
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal">Close</button>
        </div>
    </div>
</template>

<script>
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { ClientCacheMixin } from '../../Shared/ClientCacheMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ClientDocumentSend from './ClientDocumentSend.vue';

    export default {
        name: 'ClientDocumentResend',
        mixins: [ClientCacheMixin],
        props: {
            contract: Object
        },
        data() {
            return {
                emailStatus: {},
                smsStatus: {},
                sendTrigger: 0
            }
        },
        computed: {
            clientId() {
                return !!this.contract ? this.contract.clientId : '';
            },
            contractName() {
                return this.contract ? this.contract.templateName : '';
            },
            statuses() {
                return [this.emailStatus, this.smsStatus];
            },
            loading() {
                return this.statuses.some(s => s.loading);
            }
        },
        methods: {
            save() {
                this.sendTrigger++
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner,
            'document-send': ClientDocumentSend
        }
    }
</script>
