<template>
    <table-mobile @next="next">
        <user-mobile v-for="user in employees" :key="user.id" :user="user" @userClicked="onUserClicked"></user-mobile>
    </table-mobile>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TableMobile from '../Shared/Layout/TableMobile.vue';
    import UserItemMobile from './UserItemMobile.vue';

    export default {
        name: 'UsersMobile',
        props: {
            searchKey: String
        },
        computed: {
            ...mapGetters('EmployeeSearches', [
                'findEmployeeSearches'
            ]),
            employeeSearches() {
                return this.findEmployeeSearches(this.searchKey);
            },
            employees() {
                return [].concat(...this.employeeSearches.map(es => es.values));
            },
            loading() {
                return this.employeeSearches.some(es => !!es.searching);
            }
        },
        methods: {
            onUserClicked(user) {
                this.$emit('userClicked', user);
            },
            next() {
                if (!this.loading) {
                    this.$emit('skipTo', this.employees.length);
                }
            }
        },
        components: {
            'table-mobile': TableMobile,
            'user-mobile': UserItemMobile
        }
    }
</script>
