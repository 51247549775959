<template>
    <validated-input v-model="breedName" :title="$t('Name')" :validation="validator.name" :inputType="'text'"></validated-input>
</template>

<script>
    import ValidatedInput from '../Shared/Input/ValidatedInput.vue';

    export default {
        name: 'BreedNameInput',
        props: {
            value: String,
            validator: Object
        },
        computed: {
            breedName: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'validated-input': ValidatedInput
        }
    }
</script>
