import { NoteCacheMixin } from './Notes/NoteCacheMixin';

export const ClientAlertMixin = {
    mixins: [NoteCacheMixin],
    props: {
        clientId: String
    },
    computed: {
        showAlert() {
            return !!this.note && !!this.note.text;
        },
        ownerId() {
            return this.clientId;
        },
        ownerTypeId() {
            return 2;
        },
        noteTypeId() {
            return 4;
        },
        note() {
            return this.notes.find(n => n.noteType.id === 4);
        }
    }
}
