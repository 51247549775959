<template>
    <div class="form-group">
        <label>Note</label>
        <textarea v-model="note" class="form-control" rows="4" cols="50"></textarea>
    </div>
</template>

<script>
    export default {
        name: 'DocumentNoteInput',
        props: {
            value: String
        },
        computed: {
            note: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
