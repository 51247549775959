import { ErrorHelper } from "../../helpers/ErrorHelper";
import { BreedProductCacheMixin } from "./BreedProductCacheMixin";
import { mapActions } from 'vuex';

export const BreedProductAddMixin = {
    mixins: [BreedProductCacheMixin],
    data() {
        return {
            amount: null
        }
    },
    computed: {
        breedProductAddModel() {
            return {
                amount: this.amount
            }
        },
        breedProductAddModelValid() {
            const model = this.breedProductAddModel;
            return !!this.siteBreedId && !isNaN(parseFloat(model.amount));
        }
    },
    watch: {
        siteBreedId() {
            this.refreshBreedProductModel();
        }
    },
    methods: {
        ...mapActions('BreedProducts', [
            'addBreedProduct'
        ]),
        tryAddBreedProduct() {
            const self = this;
            if (!self.breedProductAddModelValid) {
                return;
            }
            self.error = '';
            self.addBreedProduct({ siteBreedId: self.siteBreedId, payload: self.breedProductAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProductModel() {
            this.amount = null;
        }
    },
    created() {
        this.refreshBreedProductModel();
    }
}
