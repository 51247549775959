<template>
    <tr @click="gotoBooking">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{clientName}}
                    </div>
                    <div class="crd-contact">
                        {{contactSummary}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Type')}}: {{clientTypeDescription}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Status')}}: {{statusDescription}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Created')}}: {{createdDateTime}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Appointment')}}: {{appointmentDateTime}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { BookingItemMixin } from "./BookingItemMixin";

    export default {
        name: 'BookingItemMobile',
        mixins: [BookingItemMixin],
        computed: {
            clientName() {
                return this.firstName + ' ' + this.lastName;
            }
        }
    }
</script>
