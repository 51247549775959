<template>
    <section class="mb-1">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-tax" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Tax Details')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-tax">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <number-input v-model="rate" :title="$t('Tax Rate')">
                        <validation-message v-if="taxValidator.rate" :message="taxValidator.rate"></validation-message>
                    </number-input>
                    <label>{{$t('Tax Number')}}</label>
                    <input v-model="number" type="text" class="form-control">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { TaxHelper } from '../../helpers/TaxHelper';
    import NumberInput from '../Shared/Input/NumberInput.vue';
    import { AccountSettingSiteDefaultMixin } from '../../mixins/Settings/Account/AccountSettingSiteDefaultMixin';

    export default {
        name: 'DefaultSiteTaxDetails',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                rate: null,
                number: '',
                taxValidator: TaxHelper.getTaxValidatorV2()
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultTaxDetails';
            },
            accountSettingEditModel() {
                return {
                    rate: this.rate,
                    number: this.number
                }
            },
            accountSettingEditModelValid() {
                this.taxValidator.validate(this.accountSettingEditModel);
                return this.taxValidator.isValid;
            }
        },
        methods: {
            refreshAccountSettingEditModel() {
                this.rate = !!this.accountSettingObject ? this.accountSettingObject.rate : null;
                this.number = !!this.accountSettingObject ? this.accountSettingObject.number : '';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'number-input': NumberInput,
            'validation-message': ValidationMessage
        }
    }
</script>
