import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BreedProductCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BreedProducts', [
            'findBreedProductCache'
        ]),
        siteBreedId() {
            console.log('OVERRIDE COMPUTED - siteBreedId');
            return '';
        },
        breedProductCache() {
            return this.findBreedProductCache(this.siteBreedId);
        },
        breedProduct() {
            return !!this.breedProductCache ? this.breedProductCache.value : null;
        },
        loadingBreedProduct() {
            return !!this.breedProductCache && this.breedProductCache.loading;
        }
    },
    watch: {
        siteBreedId() {
            this.refreshBreedProduct();
        }
    },
    methods: {
        ...mapActions('BreedProducts', [
            'findBreedProduct'
        ]),
        tryFindBreedProduct() {
            const self = this;
            if (!self.siteBreedId) {
                return;
            }
            self.error = '';
            self.findBreedProduct(self.siteBreedId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProduct() {
            if (!this.breedProductCache || !!this.breedProductCache.retry) {
                this.tryFindBreedProduct();
            }
        }
    },
    created() {
        this.refreshBreedProduct();
    }
}
