<template>
    <section class="mb-3">
        <div class="card card-body">
            <h5>{{$t('Appointments')}}</h5>
            <date-chart v-if="displayDateChart" :aggregates="appointmentAggregates"></date-chart>
            <template v-else>
                <status-chart :aggregates="appointmentAggregates"></status-chart>
            </template>
        </div>
    </section>
</template>

<script>
    import { AppointmentAggregateSearchMixin } from "../Shared/Aggregates/AppointmentAggregateSearchMixin";
    import AppointmentAggregateDateChart from "./AppointmentAggregateDateChart.vue";
    import AppointmentAggregateStatusChart from "./AppointmentAggregateStatusChart.vue";

    export default {
        name: 'AppointmentAggregateCharts',
        mixins: [AppointmentAggregateSearchMixin],
        computed: {
            displayDateChart() {
                return this.appointmentAggregates.length > 1;
            }
        },
        components: {
            'status-chart': AppointmentAggregateStatusChart,
            'date-chart': AppointmentAggregateDateChart
        }
    }
</script>
