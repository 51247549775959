<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-1" data-toggle="collapse">
            <i class="fas fa-user-alt mr-2"></i>
            {{$t('Profile')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <div class="form-group">
                    <button @click="onClick" v-bind:disabled="loading" type="button" class="btn btn-outline-secondary">
                        {{$t('Logout')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions, mapState } from "vuex";

    export default {
        name: 'UserOptions',
        computed: {
            ...mapState('Authentication', [
                'loading'
            ])
        },
        methods: {
            ...mapActions('Authentication', [
                'logout'
            ]),
            onClick() {
                this.logout();
            }
        }
    }
</script>
