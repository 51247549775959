import { Api } from "../../services/Api";

const state = () => ({
    stripoDefaultTemplateCaches: []
})

const getters = {
    findStripoDefaultTemplateCache: (state) => key => {
        return state.stripoDefaultTemplateCaches.find(sdtc => sdtc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.stripoDefaultTemplateCaches.find(sdtc => sdtc.key === key);

        if (!!existing) {
            existing.retry = false;
            existing.loading = !!loading;
        } else {
            state.stripoDefaultTemplateCaches.push({ key: key, value: null, loading: !!loading });
        }
    },

    setTemplate(state, { key, template }) {
        const existing = state.stripoDefaultTemplateCaches.find(sdtc => sdtc.key === key);

        if (!!existing) {
            existing.retry = false;
            existing.value = template;
            existing.loading = false;
        } else {
            state.stripoDefaultTemplateCaches.push({ key: key, value: template, loading: false });
        }
    },

    setRetry(state, key) {
        const existing = state.stripoDefaultTemplateCaches.find(sdtc => sdtc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getStripoDefaultTemplate({ commit }, stripoDefaultTemplateId ) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: stripoDefaultTemplateId, loading: true });
            Api.get('StripoDefaultTemplate/' + stripoDefaultTemplateId )
                .then(response => {
                    commit('setTemplate', { key: stripoDefaultTemplateId, template: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', stripoDefaultTemplateId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
