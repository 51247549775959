<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Add Site')}}</h1>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="row">
                <template>
                    <div class="col">
                        <div class="row">
                            <div class="col-12">
                                <collapsed-static id="site-add" :title="$t('Site Details')" :isCollapsed="false">
                                    <details-add :added-site-id="addedSiteId" v-model="siteDetailsStatus"></details-add>
                                </collapsed-static>
                                <collapsed-static id="location-add" :title="$t('Location Details')">
                                    <address-add :added-site-id="addedSiteId" v-model="siteAddressStatus"></address-add>
                                    <country-add :added-site-id="addedSiteId" v-model="siteCountryStatus"></country-add>
                                </collapsed-static>
                                <collapsed-static id="localization-add" :title="$t('Localization')">
                                    <date-format :added-site-id="addedSiteId" v-model="siteDateFormatStatus"></date-format>
                                    <time-format :added-site-id="addedSiteId" v-model="siteTimeFormatStatus"></time-format>
                                    <timezone-add :added-site-id="addedSiteId" v-model="siteTimeZoneStatus"></timezone-add>
                                </collapsed-static>
                                <collapsed-static id="reminder-add" :title="$t('Reminder Settings')">
                                    <reminder-add :added-site-id="addedSiteId" v-model="siteReminderStatus"></reminder-add>
                                </collapsed-static>
                                <collapsed-static id="deposit-add" :title="$t('Deposit Settings')">
                                    <deposit-add :added-site-id="addedSiteId" v-model="siteDepositStatus"></deposit-add>
                                </collapsed-static>
                                <collapsed-static id="contact-add" :title="$t('Contact Details')">
                                    <contact-add :added-site-id="addedSiteId" v-model="siteContactStatus" :site-country-status="siteCountryStatus"></contact-add>
                                </collapsed-static>
                                <collapsed-static id="tax-add" :title="$t('Tax Details')">
                                    <tax-rate-add :added-site-id="addedSiteId" v-model="siteTaxRateStatus"></tax-rate-add>
                                </collapsed-static>
                                <collapsed-static id="resource-add" :title="$t('Resource')">
                                    <resource-add :added-site-id="addedSiteId" v-model="siteResourceStatus"></resource-add>
                                </collapsed-static>
                                <resource-event-colour :added-site-id="addedSiteId" v-model="siteResourceEventColourStatus"></resource-event-colour>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
                <button @click="triggerSaveSite" v-bind:disabled="loading" class="btn btn-primary">
                    {{$t('Add')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
                <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex';
    import { SmsSettingAddMixin } from '../../Communication/Settings/SmsSettingAddMixin';
    import { CampaignSettingAddMixin } from '../../Communication/Settings/CampaignSettingAddMixin';
    import { EmailTemplateSettingAddMixin } from '../../Communication/Settings/EmailTemplateSettingAddMixin';
    import process from 'process';
    import SiteAddDetails from './SiteAddDetails.vue';
    import SiteAddAddress from './SiteAddAddress.vue';
    import SiteAddContact from './SiteAddContact.vue';
    import SiteAddCountry from './SiteAddCountry.vue';
    import SiteAddTaxRate from './SiteAddTaxRate.vue';
    import SiteAddTimeZone from './SiteAddTimeZone.vue';
    import SiteAddDateFormat from "./SiteAddDateFormat.vue";
    import SiteAddTimeFormat from "./SiteAddTimeFormat.vue";
    import SiteAddDeposit from './SiteAddDeposit.vue';
    import SiteAddResource from './SiteAddResource.vue';
    import CollapsedStaticSection from '../../Shared/Layout/CollapsedStaticSection.vue';
    import ValidatedInput from '../../Shared/Input/ValidatedInput.vue';
    import SlottedInput from '../../Shared/Input/SlottedInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import SiteAddReminderSetting from './SiteAddReminderSetting.vue';
    import SiteAddResourceEventColour from './SiteAddResourceEventColour.vue'

    export default {
        name: 'SiteAdd',
        mixins: [SmsSettingAddMixin, CampaignSettingAddMixin, EmailTemplateSettingAddMixin],
        data() {
            return {
                addedSiteId: '',
                siteDetailsStatus: {},
                siteAddressStatus: {},
                siteDateFormatStatus: {},
                siteTimeFormatStatus: {},
                siteTimeZoneStatus: {},
                siteDepositStatus: {},
                siteTaxRateStatus: {},
                siteCountryStatus: {},
                siteContactStatus: {},
                siteResourceStatus: {},
                siteReminderStatus: {},
                siteResourceEventColourStatus: {}
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'siteAddedEvent'
            ]),
            statuses() {
                return [
                    this.siteDetailsStatus,
                    this.siteAddressStatus,
                    this.siteDateFormatStatus,
                    this.siteTimeFormatStatus,
                    this.siteTimeZoneStatus,
                    this.siteDepositStatus,
                    this.siteTaxRateStatus,
                    this.siteCountryStatus,
                    this.siteContactStatus,
                    this.siteResourceStatus,
                    this.siteReminderStatus,
                    this.siteResourceEventColourStatus
                ];
            },
            loading() {
                return this.statuses.some(s => !!s.loading);
            },
            completed() {
                return this.statuses.every(s => !!s.completed);
            }
        },
        watch: {
            siteAddedEvent(site) {
                process.nextTick(() => {
                    this.setSelected(site.id);
                    this.addedSiteId = site.id;
                    this.addSiteBreeds();
                    this.addDefaultOpeningHours(site.id);
                    this.tryAddSmsSetting();
                    this.tryAddCampaignSetting();
                    this.tryAddEmailTemplateSetting();
                });
            },
            completed() {
                this.$router.push({ name: 'siteEdit' });
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            ...mapActions('Breeds', [
                'addSiteBreeds'
            ]),
            ...mapActions('OpeningHours', [
                'addDefaultOpeningHours'
            ]),
            ...mapMutations('ApplicationEvents', [
                'triggerSaveSite'
            ]),
            cancel() {
                this.$router.push({ name: 'sites' });
            }
        },
        components: {
            'details-add': SiteAddDetails,
            'country-add': SiteAddCountry,
            'tax-rate-add': SiteAddTaxRate,
            'address-add': SiteAddAddress,
            'contact-add': SiteAddContact,
            'timezone-add': SiteAddTimeZone,
            'date-format': SiteAddDateFormat,
            'time-format': SiteAddTimeFormat,
            'reminder-add': SiteAddReminderSetting,
            'resource-add': SiteAddResource,
            'collapsed-static': CollapsedStaticSection,
            'validated-input': ValidatedInput,
            'slotted-input': SlottedInput,
            'deposit-add': SiteAddDeposit,
            'error-banner': ErrorBanner,
            'resource-event-colour': SiteAddResourceEventColour
        }
    }
</script>
