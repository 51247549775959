<template>
    <div class="date-selector schedule-date-selector">
        <date-picker v-model="date" input-class="schedule-date" :show-calendar-button="true"></date-picker>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import moment from 'moment';
    import MyDatePicker from "../Shared/Time/MyDatePicker.vue";

    export default {
        name: 'ScheduleDateSelector',
        props: {
            value: Date
        },
        computed: {
            ...mapGetters('ScheduleFilter', [
                'searchModel'
            ]),
            date: {
                get() {
                    const current = moment(this.value);
                    const start = moment(this.searchModel.startUtc);
                    const end = moment(this.searchModel.endUtc);
                    return !start.isValid() || !end.isValid() || (current.isAfter(start) && current.isBefore(end)) ? current.toDate() : start.toDate();
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'date-picker': MyDatePicker
        }
    }
</script>
