import { mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const NotificationsCacheMixin = {
    data() {
        return {
            error: '',
            timeoutLength: 30
        }
    },
    computed: {
        ...mapState('Notifications', [
            'fetchingNotifications',
            'fetchedNotifications',
            'notificationsLastFetched',
            'notificationsTimeoutId',
            'notifications'
        ]),
        notificationFilterModel() {
            return {
                ascending: false,
                skip: this.notifications.length
            }
        }
    },
    methods: {
        ...mapMutations('Notifications', [
            'setTimeoutId'
        ]),
        ...mapActions('Notifications', [
            'findNotifications'
        ]),
        refreshNotifications() {
            if ((!this.fetchedNotifications || this.getNotificationsExpired()) && !this.fetchingNotifications) {
                this.tryFindNotifications();
            }
            clearTimeout(this.notificationsTimeoutId);
            if (!this.destroyed) {
                this.setTimeoutId(setTimeout(this.refreshNotifications, this.getSecondsTillNextRefresh() * 1000));
            }
        },
        tryFindNotifications() {
            const self = this;
            self.error = '';
            self.findNotifications(self.notificationFilterModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        getSecondsSinceRefreshed() {
            return moment().diff(this.notificationsLastFetched, 'seconds');
        },
        getNotificationsExpired() {
            return !this.notificationsLastFetched || this.getSecondsSinceRefreshed() > this.timeoutLength;
        },
        getSecondsTillNextRefresh() {
            return (this.getNotificationsExpired() ? this.timeoutLength : this.timeoutLength - this.getSecondsSinceRefreshed()) + 1;
        }
    },
    created() {
        this.refreshNotifications();
    }
}
