<template>
    <li class="m-0">
        <a @click="onClick" class="dropdown-item pointer" v-bind:class="{ 'active selected': selected }">
            <span class="text">
                {{displayString}}
                <i v-if="preferred" class="fa fa-heart"></i>
            </span>
        </a>
    </li>
</template>

<script>
    export default {
        name: 'EditResourceListItem',
        props: {
            resource: Object,
            unavailabilities: Array,
            selected: Boolean,
            preferred: Boolean
        },
        computed: {
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            resourceId() {
                return !!this.resource ? this.resource.id : '';
            },
            unavailability() {
                return this.unavailabilities.find(u => u.resourceId === this.resourceId);
            },
            unavailabilityMessage() {
                return !!this.unavailability ? ' - ' + this.unavailability.message : '';
            },
            displayString() {
                return this.resourceName + this.unavailabilityMessage;
            }
        },
        methods: {
            onClick() {
                if (!!this.resource) {
                    this.$emit('resourceClicked', this.resource);
                }
            }
        }
    }
</script>

<style scoped>
    .dropdown-item i {
        color: #F4457B;
    }
    .dropdown-item.active i,
    .dropdown-item:hover i {
        color: #FFF;
    }
</style>
