import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { EmailMarketingDefaultSubjectsCacheMixin } from './EmailMarketingDefaultSubjectsCacheMixin';

export const EmailMarketingDefaultSubjectsAddMixin = {
    mixins: [EmailMarketingDefaultSubjectsCacheMixin],
    data() {
        return {
            subject: ''
        }
    },
    computed: {
        ...mapGetters('EmailMarketingDefaultSubjects', [
            'findEmailMarketingDefaultSubjectCache'
        ]),
        communicationTypeId() {
            console.log('OVERRRIDE COMPUTED --- Communication Type ID')
        },
        emailMarketingDefaultSubjectCache() {
            return this.findEmailMarketingDefaultSubjectCache(this.communicationTypeId)
        },
        emailMarketingDefaultSubject() {
            return !!this.emailMarketingDefaultSubjectCache ? this.emailMarketingDefaultSubjectCache.subject : '';
        }
    },
    watch: {
        emailMarketingDefaultSubject() {
            this.refreshEmailMarketinSubject();
        }
    },
    methods: {
        refreshEmailMarketinSubject() {
            this.subject = this.emailMarketingDefaultSubject;
        }
    },
    created() {
        this.refreshEmailMarketinSubject();
    }
}
