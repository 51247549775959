import { mapGetters, mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper.js";
import { SiteIdMixin } from '../Site/SiteIdMixin.js';

export const WorkingHoursCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('WorkingHours', [
            'findWorkingHourCache'
        ]),
        workingHoursCache() {
            return this.findWorkingHourCache(this.siteId);
        },
        workingHoursFilter() {
            return {
                siteId: this.siteId
            }
        },
        workingHoursFilterValid() {
            return !!this.workingHoursFilter.siteId;
        },
        workingHours() {
            return !!this.workingHoursCache ? this.workingHoursCache.values : [];
        },
        loadingWorkingHour() {
            return !!this.workingHoursCache && this.workingHoursCache.loading;
        }
    },
    watch: {
        workingHoursFilter() {
            this.refreshWorkingHours();
        }
    },
    methods: {
        ...mapActions('WorkingHours', [
            'findWorkingHours'
        ]),
        refreshWorkingHours() {
            if (!this.workingHoursCache || !!this.workingHoursCache.retry) {
                this.tryFindWorkingHours();
            }
        },
        tryFindWorkingHours() {
            const self = this;
            if (!self.workingHoursFilterValid) {
                return;
            }
            self.error = '';
            self.findWorkingHours({ key: self.siteId, payload: self.workingHoursFilter })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshWorkingHours();
    }
}
