import router from '../../routing';

export const TAndCsAcceptMixin = {
    data() {
        return {
            acceptedTCs: false,
            acceptedTCsError: ''
        }
    },
    methods: {
        areTermsAccepted() {
            this.acceptedTCsError = !!this.acceptedTCs ? '' : 'Please accept terms & conditions to proceed';
            return this.acceptedTCs;
        },
        gotoTerms() {
            const routeData = router.resolve({ name: 'terms' });
            window.open(routeData.href, '_blank');
        }
    }
}
