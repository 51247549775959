<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Neutered')}}</label>
        </div>
        <div class="col-md-8">
            <label class="custom-control custom-radio custom-radio-inline">
                <input v-model="neuteredId" :value="1" class="custom-control-input" type="radio">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t('Unknown')}}</span>
            </label>
            <label class="custom-control custom-radio custom-radio-inline">
                <input v-model="neuteredId" :value="2" class="custom-control-input" type="radio">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t('Yes')}}</span>
            </label>
            <label class="custom-control custom-radio custom-radio-inline">
                <input v-model="neuteredId" :value="3" class="custom-control-input" type="radio">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t('No')}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PetDetailsNeutered',
        props: {
            value: Number
        },
        computed: {
            neuteredId: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
