<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{$t('Template')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedSubject}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="item in emailCampaignTemplates"
                            :key="item.id"
                            class="dropdown-item pointer"
                            @click="setSelected(item.id)"
                            v-bind:class="{'active': item.id === templateId}">
                            {{item.subject}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EmailCampaignTemplatesCacheMixin } from "../../../../mixins/Campaigns/EmailCampaignTemplatesCacheMixin";

    export default {
        name: 'CampaignEmailTemplateSelect',
        mixins: [EmailCampaignTemplatesCacheMixin],
        props: {
            value: String
        },
        data() {
            return {
                templateId: ''
            }
        },
        computed: {
            selectedOption() {
                return this.emailCampaignTemplates.find(ect => ect.id === this.templateId);
            },
            selectedSubject() {
                return !!this.selectedOption ? this.selectedOption.subject : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            setSelected(value) {
                this.templateId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setSelected(this.value);
        }
    }
</script>
