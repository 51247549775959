import { NotificationSettingsAddMixin } from "./NotificationSettingsAddMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const NotificationSettingsUploadMixin = {
    mixins: [NotificationSettingsAddMixin],
    computed: {
        notificationSettingsUpdateModel() {
            return !!this.notificationSettings ? Object.assign({}, this.notificationSettings, {
                marketing: this.marketing,
                smsCancellation: this.smsCancellation,
                smsUpdated: this.smsUpdated,
                smsReminder: this.smsReminder,
                smsConfirmation: this.smsConfirmation,
                smsThankYou: this.smsThankYou,
                smsNoShow: this.smsNoShow,
                smsMissYou: this.smsMissYou,
                emailCancellation: this.emailCancellation,
                emailUpdated: this.emailUpdated,
                emailReminder: this.emailReminder,
                emailConfirmation: this.emailConfirmation,
                emailThankYou: this.emailThankYou,
                emailNoShow: this.emailNoShow,
                emailMissYou: this.emailMissYou
            }) : {};
        },
        notificationSettingsUpdateModelValid() {
            const model = this.notificationSettingsUpdateModel;
            return !!model.id && !!model.clientId;
        },
        notificationSettingsUpdateModelModified() {
            return JSON.stringify(this.notificationSettingsUpdateModel) !== JSON.stringify(this.notificationSettings);
        }
    },
    watch: {
        notificationSettings() {
            this.refreshNotificationSettingsModel();
        }
    },
    methods: {
        ...mapActions('NotificationSettings', [
            'updateNotificationSettings',
        ]),
        refreshNotificationSettingsModel() {
            this.marketing = !!this.notificationSettings ? this.notificationSettings.marketing : false;
            this.smsCancellation = !!this.notificationSettings ? this.notificationSettings.smsCancellation : this.settingObject.isSmsCancellationEnabled;
            this.smsUpdated = !!this.notificationSettings ? this.notificationSettings.smsUpdated : this.settingObject.isSmsUpdatedEnabled;
            this.smsReminder = !!this.notificationSettings ? this.notificationSettings.smsReminder : this.settingObject.isSmsReminderEnabled;
            this.smsConfirmation = !!this.notificationSettings ? this.notificationSettings.smsConfirmation : this.settingObject.isSmsConfirmationEnabled;
            this.smsThankYou = !!this.notificationSettings ? this.notificationSettings.smsThankYou : this.settingObject.isSmsThankYouEnabled;
            this.smsNoShow = !!this.notificationSettings ? this.notificationSettings.smsNoShow : this.settingObject.isSmsNoShowEnabled;
            this.smsMissYou = !!this.notificationSettings ? this.notificationSettings.smsMissYou : this.settingObject.isSmsMissYouEnabled;
            this.emailCancellation = !!this.notificationSettings ? this.notificationSettings.emailCancellation : this.settingObject.isEmailCancellationEnabled;
            this.emailUpdated = !!this.notificationSettings ? this.notificationSettings.emailUpdated : this.settingObject.isEmailUpdatedEnabled;
            this.emailReminder = !!this.notificationSettings ? this.notificationSettings.emailReminder : this.settingObject.isEmailReminderEnabled;
            this.emailConfirmation = !!this.notificationSettings ? this.notificationSettings.emailConfirmation : this.settingObject.isEmailConfirmationEnabled;
            this.emailThankYou = !!this.notificationSettings ? this.notificationSettings.emailThankYou : this.settingObject.isEmailThankYouEnabled;
            this.emailNoShow = !!this.notificationSettings ? this.notificationSettings.emailNoShow : this.settingObject.isEmailNoShowEnabled;
            this.emailMissYou = !!this.notificationSettings ? this.notificationSettings.emailMissYou : this.settingObject.isEmailMissYouEnabled;
        },
        tryUploadNotificationSettings() {
            if (!!this.notificationSettings) {
                this.tryUpdateNotificationSettings();
            } else {
                this.tryAddNotificationSettings();
            }
        },
        tryUpdateNotificationSettings() {
            const self = this;
            if (!self.notificationSettingsUpdateModelValid || !self.notificationSettingsUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateNotificationSettings({ clientId: self.clientId, payload: self.notificationSettingsUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
