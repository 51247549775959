<template>
    <div class="mb-3">
        <div class="row px-1">
            <div class="title-divider d-flex w-100">
                <i @click="showPetForm = !showPetForm"
                   class="p-2 fal fa-chevron-circle-up pet-form-toggle-icon"
                   v-bind:class="{'pet-form-toggle-icon-rotate':showPetForm}">
                </i>
                <h2 class="p-2 mx-auto">{{$t('Pet')}} {{index + 1}}</h2>
                <i v-if="index !== 0" @click="deletePet" class="p-2 fal fa-trash-alt"></i>
                <i v-else class="p-2 fas fa-circle text-white"></i>
            </div>
        </div>
        <div class="pet-form" v-bind:class="{'pet-form-show': showPetForm}">
            <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                <div class="alert-content">
                    <p>Pet successfully added.</p>
                </div>
            </div>
            <template v-else>
                <error-banner v-if="error" :message="error"></error-banner>
                <pet-name v-model="name" :validator="petValidator"></pet-name>
                <pet-breed v-model="breedId" :validator="petValidator"></pet-breed>
                <pet-gender v-model="genderId"></pet-gender>
                <pet-neutered v-model="neuteredId"></pet-neutered>
                <pet-behaviour v-model="behaviourId"></pet-behaviour>
                <pet-dob v-model="dateOfBirth"></pet-dob>
                <pet-microchip v-model="microChip"></pet-microchip>
                <pet-images v-model="petImageStatus" :added-pet-id="addedPetId"></pet-images>
                <pet-note v-model="petNoteStatus" :added-pet-id="addedPetId"></pet-note>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { PetAddMixin } from '../../Shared/PetAddMixin';
    import PetDetailsBreed from '../../Pet/PetDetailsBreed.vue';
    import PetDetailsName from '../../Pet/PetDetailsName.vue';
    import PetDetailsGender from '../../Pet/PetDetailsGender.vue';
    import PetDetailsNeutered from '../../Pet/PetDetailsNeutered.vue';
    import PetDetailsBehaviour from '../../Pet/PetDetailsBehaviour.vue';
    import PetDetailsDob from '../../Pet/PetDetailsDob.vue';
    import PetDetailsMicrochip from '../../Pet/PetDetailsMicrochip.vue';
    import AddPetImages from './AddPetImages.vue';
    import AddPetNote from './AddPetNote.vue';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AddPet',
        mixins: [PetAddMixin],
        props: {
            index: 0,
            id: {
                type: String,
                required: true
            },
            addedClientId: ''
        },
        data() {
            return {
                addedPetId: '',
                petNoteStatus: {},
                petImageStatus: {},
                showPetForm: true,
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent',
            ]),
            clientId() {
                return this.addedClientId
            },
            completed() {
                return !!this.addedPetId && this.petImageStatus.completed && this.petNoteStatus.completed;
            },
            validated() {
                return !!this.petValidator.isValid && !!this.petNoteStatus.validated && !!this.petImageStatus.validated;
            },
            errored() {
                return !!this.error && !!this.petImageStatus.error && !!this.petNoteStatus.error;
            },
            loading() {
                return !!this.addingPet || !!this.petImageStatus.loading || !!this.petNoteStatus.loading;
            },
            status() {
                return {
                    error: !!this.errored, // rename error to error for all statuses
                    completed: this.completed,
                    validated: this.validated,
                    loading: this.loading
                }
            },
            canAddPet() {
                return !!this.addedClientId && this.petValidator.isValid && !this.addedPetId;
            }
        },
        watch: {
            // retry saving of pets
            addClientEvent() {
                this.onClientIdChanged();
            },
            // initial save
            addedClientId() {
                this.onClientIdChanged();
            },
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            async onClientIdChanged() {
                this.petValidator.check(this.petAddModel)
                if (!this.canAddPet) {
                    return;
                }
                try {
                    const response = await this.tryAddPet();
                    this.addedPetId = response.data.id;
                }
                catch {
                    this.addedPetId = '';
                }
            },
            deletePet() {
                if (this.index !== 0 && !this.addedPetId) {
                    this.$emit('deletePet', this.id);
                }
            },
            resetPetAddModel() {
                // do nothing
            }
        },
        components: {
            'pet-name': PetDetailsName,
            'pet-breed': PetDetailsBreed,
            'pet-gender': PetDetailsGender,
            'pet-neutered': PetDetailsNeutered,
            'pet-behaviour': PetDetailsBehaviour,
            'pet-dob': PetDetailsDob,
            'pet-microchip': PetDetailsMicrochip,
            'pet-images': AddPetImages,
            'pet-note': AddPetNote,
            'error-banner': ErrorBanner
        }
    }
</script>

<style scoped>
    .pet-form {
        display: none;
        max-height: 0;
        transition: all 0.3s ease;
    }

    .pet-form-show {
        display: block;
        max-height: 100%;
        transition: all 0.3s ease;
    }

    .pet-form-toggle-icon {
        transform: rotate(0);
        transition: transform 0.3s ease-in-out;
        border-radius: 5rem;
    }

    .pet-form-toggle-icon-rotate {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
    }
</style>
