<template>
    <div v-if="hasConflict" class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label>{{$t('Conflicts with:')}}</label>
        </div>
        <div class="col-md-8">
            {{summaryString}}
        </div>
    </div>
</template>

<script>
    import moment from 'moment-timezone'
    import { PetDisplayMixin } from '../../components/Shared/Appointments/PetDisplayMixin'

    export default {
        name: 'BookingConflictAppointmentDetails',
        mixins: [PetDisplayMixin],
        props: {
            resourceId: String,
            appointment: Object
        },
        computed: {
            hasConflict() {
                return this.appointment ? this.appointment.resourceId === this.resourceId : false;
            },
            clientId() {
                return !!this.hasConflict ? this.appointment.clientId : '';
            },
            petId() {
                return !!this.hasConflict ? this.appointment.petId : '';
            },
            startUtc() {
                return !!this.hasConflict ? this.appointment.startUtc : '';
            },
            endUtc() {
                return !!this.hasConflict ? this.appointment.endUtc : '';
            },
            timeString() {
                return moment(this.startUtc).format('HH:mm') + ' - ' + moment(this.endUtc).format('HH:mm');
            },
            title() {
                return this.lines.length > 0 ? this.lines[0] : 'Unknown';
            },
            summaryString() {
                return this.title + ' at ' + this.timeString;
            }
        }
    }
</script>
