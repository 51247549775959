<template>
    <validated-input v-model="email" :title="$t('Email')" :validation="validator.email" :inputType="'text'"></validated-input>
</template>

<script>
    import ValidatedInput from '../Shared/Input/ValidatedInput.vue';

    export default {
        name: 'UserEmailInput',
        props: {
            value: String,
            validator: Object
        },
        computed: {
            email: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'validated-input': ValidatedInput
        }
    }
</script>
