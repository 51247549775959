<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label>{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteText" class="form-control" cols="50" :placeholder="$t('Add notes (optional)')" rows="3"></textarea>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { NoteUploadMixin } from "../../Shared/Notes/NoteUploadMixin";

    export default {
        name: 'EventNotesUpload',
        mixins: [NoteUploadMixin],
        props: {
            event: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveEventEvent'
            ]),
            ownerId() {
                return !!this.event ? this.event.id : '';
            },
            ownerTypeId() {
                return 13;
            },
            noteTypeId() {
                return 1;
            }
        },
        watch: {
            saveEventEvent() {
                this.tryUploadNote();
            },
            loadingNote(newValue) {
                this.$emit('loading', newValue);
            }
        }
    }
</script>
