<template>
    <li class="pointer">
        <a @click="onClick" role="option" class="dropdown-item text-wrap">
            {{addressString}}
        </a>
    </li>
</template>

<script>
    import { AddressHelper } from "../../../helpers/AddressHelper"

    export default {
        name: 'PostcodeSearchResult',
        props: {
            address: Object
        },
        computed: {
            addressString() {
                return AddressHelper.getAddressLines(this.address).join(', ');
            }
        },
        methods: {
            onClick() {
                this.$emit('addressClicked', this.address);
            }
        }
    }
</script>
