<template>
    <div class="form-group">
        <div v-if="!editing" class="d-flex justify-content-between">
            <div class="d-flex align-items-center px-1">
                <img :src="getFlagImage(selectedOption.value)" height="15" />
                <span class="ml-2" v-html="selectedOptionName"></span>
            </div>
            <a @click="editing=true" class="link pointer">{{$t('Change')}}</a>
        </div>
        <div v-else class="d-flex flex-wrap justify-content-between">
            <div class="dropdown bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    <div class="d-flex align-items-center px-1">
                        <img :src="getFlagImage(selectedOption.value)" height="15" />
                        <span class="ml-2" v-html="selectedOptionName"></span>
                    </div>
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            :key="option.value"
                            class="dropdown-item pointer px-3"
                            @click="setLanguage(option.value)"
                            v-bind:class="{'active': option.value === selectedOption.value}">
                            <div class="d-flex align-items-center">
                                <img :src="getFlagImage(option.value)" height="15" />
                                <span class="ml-2" v-html="option.name"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <a @click="editing=false" class="link pointer mt-2">{{$t('Back')}}</a>
        </div>
    </div>
</template>

<script>
    import { LanguageSettingOptionsMixin } from "../Shared/LanguageSettingOptionsMixin";

    export default {
        name: 'LanguageSelect',
        mixins: [LanguageSettingOptionsMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            selectedOption() {
                return this.options.find(o => o.value === this.code);
            },
            code() {
                return localStorage.getItem('lang') || 'en';
            }
        },
        methods: {
            setLanguage(value) {
                if (localStorage.getItem('lang') != value) {
                    localStorage.setItem('lang', value);
                    window.location.reload();
                }
            }
        },
        created() {
            localStorage.setItem('lang', this.code);
        }
    }
</script>
