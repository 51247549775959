<template>
    <section class="mb-3">
        <div class="card card-body">
            <div class="dashboard-card">
                <span class="fa-stack fa-2x float-left">
                    <i class="fa-thin fa-square fa-stack-2x color-medium-pink"></i>
                    <i class="fa-solid fa-coins color-medium-turquoise fa-stack-1x fa-inverse"></i>
                </span>
               
                <div class="card-content">
                    <div v-for="total in totals" :key="total.key">
                        <h2 class="text-secondary">{{total.symbol}}{{toMoneyFormat(total.amount)}}</h2>
                    </div>
                    <h5>{{$t('Total Sales')}}</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { NumberHelper } from '../../helpers/NumberHelper';
    import { OrderAggregateSearchMixin } from "../Shared/Aggregates/OrderAggregateSearchMixin";
    import { CountryCacheMixin } from '../Shared/Country/CountryCacheMixin';

    export default {
        name: 'OrderAggregateTotals',
        mixins: [OrderAggregateSearchMixin, CountryCacheMixin],
        computed: {
            ...mapState('Countries', [
                'countries'
            ]),
            totals() {
                const result = [];
                for (var i = 0; i < this.orderAggregates.length; i++) {
                    const aggregate = this.orderAggregates[i];
                    const country = this.countries.find(c => c.siteId === aggregate.siteId);
                    const key = !!country ? country.currency : '???';
                    const symbol = !!country ? country.currencySymbol : '?';
                    const existing = result.find(r => r.key === key);
                    if (!!existing) {
                        existing.amount += aggregate.total;
                    } else {
                        result.push({
                            key: key,
                            symbol: symbol,
                            amount: aggregate.total
                        });
                    }
                }
                return result.sort((a, b) => b.amount - a.amount);
            }
        },
        methods: {
            toMoneyFormat(number) {
                return NumberHelper.toMoneyFormat(number);
            }
        }
    }
</script>
