import { Api } from '../../services/Api';
import { ArrayHelper } from '../../helpers/ArrayHelper.js';

const state = () => ({
    addingBookingSuggestion: false,
    bookingSuggestionCaches: []
})

const getters = {
    findBookingSuggestionCache: (state) => key => state.bookingSuggestionCaches.find(bs => bs.key === key),
    findBookingSuggestions: (state) => key => {
        const cache = state.bookingSuggestionCaches.find(bs => bs.key === key);
        return !!cache ? cache.values : [];
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.bookingSuggestionCaches.find(bs => bs.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.bookingSuggestionCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setBookingSuggestions(state, { keys, bookingSuggestions }) {
        const groups = ArrayHelper.groupBy(bookingSuggestions, 'bookingId');
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const group = groups.find(g => g.key === key);
            const values = !!group ? group.values : [];
            const existing = state.bookingSuggestionCaches.find(bs => bs.key === key);
            if (!!existing) {
                const index = state.bookingSuggestionCaches.indexOf(existing);
                state.bookingSuggestionCaches.splice(index, 1, Object.assign({}, existing, { values: values, loading: false }));
            } else {
                state.bookingSuggestionCaches.push({ key: key, values: values, loading: false });
            }
        }
    },
    setRetry(state, key) {
        const existing = state.bookingSuggestionCaches.find(bs => bs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setAdding(state, adding) {
        state.addingBookingSuggestion = !!adding;
    },
    appendBookingSuggestion(state, { key, bookingSuggestion }) {
        const existing = state.bookingSuggestionCaches.find(bs => bs.key === key);
        if (!!existing) {
            existing.values.push(bookingSuggestion);
        } else {
            state.bookingSuggestionCaches.push({ key: key, values: [bookingSuggestion], loading: false });
        }
    }
}

const actions = {
    getBookingSuggestions({ commit }, bookingId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: bookingId, loading: true });
            Api.get('Booking/' + bookingId + '/BookingSuggestion')
                .then(response => {
                    commit('setBookingSuggestions', { keys: [bookingId], bookingSuggestions: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', bookingId);
                    reject(error);
                })
        });
    },
    addBookingSuggestion({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            commit('setLoading', { key: key, loading: true });
            Api.post('BookingSuggestion', payload)
                .then(response => {
                    commit('appendBookingSuggestion', { key: key, bookingSuggestion: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => {
                    commit('setAdding', false);
                    commit('setLoading', { key: key, loading: false });
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
