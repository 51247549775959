<template>
    <section class="mb-3">
        <div class="card card-body">
            <div align="center">
                <span class="fa-stack fa-2x">
                    <i class="fa-thin fa-square fa-stack-2x color-medium-pink"></i>
                    <i class="fa-solid fa-file-spreadsheet color-medium-turquoise fa-stack-1x fa-inverse"></i>
                </span>
            </div>
            <h3 class="text-center">
                {{$t(description)}}
            </h3>
            <div class="text-center">
                {{$t(summary)}}
            </div>
            <br />
            <div class="text-center">
                <h6 v-if="hasFilters">{{$t('Filters available:')}}</h6>
                <div class="filters-available">
                    <span v-for="(filter, index) in filters" :key="index" class="active-filter">
                        {{$t(filter)}} <br />
                    </span>
                </div>
            </div>
            <div align="center">
                <button @click="onClick" class="btn-add btn btn-secondary w-100">
                    {{$t('Generate')}}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ReportType',
        props: {
            reportType: Object
        },
        computed: {
            ...mapGetters('ReportSettings', [
                'findReportSetting'
            ]),
            description() {
                return this.reportType.description;
            },
            setting() {
                return this.findReportSetting(this.reportType.id);
            },
            summary() {
                return !!this.setting ? this.setting.summary : '';
            },
            filters() {
                return !!this.setting ? this.setting.filters : [];
            },
            hasFilters() {
                return this.filters.length > 0;
            }
        },
        methods: {
            onClick() {
                this.$emit('reportClicked', this.reportType);
            }
        }
    }
</script>
