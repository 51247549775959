import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const PaymentCheckoutsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentCheckouts', [
            'findPaymentCheckoutsCache'
        ]),
        checkoutsReference() {
            console.log('OVERRIDE COMPUTED - checkoutsReference');
        },
        checkoutsFilterModel() {
            return {
                reference: this.checkoutsReference
            }
        },
        checkoutsFilterModelValid() {
            return !!this.checkoutsFilterModel.reference;
        },
        checkoutsCacheKey() {
            return CacheHelper.objectCacheKey(this.checkoutsFilterModel);
        },
        checkoutsCache() {
            return this.findPaymentCheckoutsCache(this.checkoutsCacheKey);
        },
        checkouts() {
            return !!this.checkoutsCache ? this.checkoutsCache.values : [];
        },
        loadingCheckouts() {
            return !!this.checkoutsCache && this.checkoutsCache.loading;
        }
    },
    watch: {
        checkoutsCacheKey() {
            this.refreshCheckouts();
        }
    },
    methods: {
        ...mapActions('PaymentCheckouts', [
            'findCheckouts'
        ]),
        refreshCheckouts() {
            if (!this.checkoutsCache || !!this.checkoutsCache.retry) {
                this.tryFindCheckouts();
            }
        },
        tryFindCheckouts() {
            const self = this;
            if (!self.checkoutsFilterModelValid) {
                return;
            }
            self.error = '';
            self.findCheckouts({ key: self.checkoutsCacheKey, payload: self.checkoutsFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCheckouts();
    }
}
