<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="deleting" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{$t('Are you sure you want to delete')}} {{contractName}}?</p>
            </div>
            <button @click="tryDeleteContract" v-bind:disabled="loadingContracts" type="button" class="btn btn-danger" style="margin-right: 10px;">{{$t('Yes')}}</button>
            <button @click="deleting=false" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <button style="width: 120px;" type="button" class="btn btn-sm btn-outline-secondary" title="Document Request" data-toggle="modal" data-target="#document-add-id" id="btnDoc">
            <i class="fal fa-paper-plane"></i> {{$t('Send Doc')}}
        </button>
        <div class="table-responsive">
            <table class="table table-striped ps-table table-hover">
                <thead>
                    <tr>
                        <th>{{$t('Name')}}</th>
                        <th class="text-center">{{$t('Version')}}</th>
                        <th>{{$t('Sent')}}</th>
                        <th class="text-center">{{$t('Signature?')}}</th>
                        <th>{{$t('Status')}}</th>
                        <th>{{$t('Signed?')}}</th>
                        <th v-if="canDelete" class="text-center">{{$t('Delete')}}</th>
                        <th class="text-center">{{$t('Resend')}}?</th>
                    </tr>
                </thead>
                <tbody>
                    <document-item v-for="contract in contracts" :key="contract.id" :contract="contract" @delete="handleDelete" @resend="handleResend" @error="handleError"></document-item>
                </tbody>
            </table>
        </div>
        <modal-dialog id="document-add-id" title="Send Document">
            <document-add :client="client"></document-add>
        </modal-dialog>
        <modal-dialog ref="documentResend" title="Send Document">
            <document-resend :contract="contract"></document-resend>
        </modal-dialog>
    </div>
</template>

<script>
    import ClientDocumentAdd from './ClientDocumentAdd.vue';
    import ClientDocumentItem from './ClientDocumentItem.vue';
    import ClientDocumentResend from './ClientDocumentResend.vue';
    import { ContractDeleteMixin } from '../../Shared/ContractDeleteMixin.js';
    import { ClientCacheMixin } from '../../Shared/ClientCacheMixin.js';
    import { UserCacheMixin } from '../../Shared/UserCacheMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ModalDialog from '../../Layout/ModalDialog.vue';

    export default {
        name: 'ClientDocumentSection',
        mixins: [ContractDeleteMixin, ClientCacheMixin, UserCacheMixin],
        data() {
            return {
                error: '',
                deleting: false,
                selectedId: ''
            }
        },
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id;
            },
            contract() {
                return this.contracts.find(c => c.id === this.selectedId);
            },
            contractName() {
                return !!this.contract ? this.contract.templateName : '';
            }
        },
        watch: {
            contractDeletedEvent() {
                this.deleting = false;
            },
            loadingContracts(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            handleDelete(contract) {
                this.selectedId = contract.id;
                this.deleting = true;
            },
            handleResend(contract) {
                this.selectedId = contract.id;
                const element = this.$refs.documentResend.$el;
                $(element).modal('show');
            },
            handleError(error) {
                this.error = error;
            }
        },
        components: {
            'document-add': ClientDocumentAdd,
            'document-item': ClientDocumentItem,
            'document-resend': ClientDocumentResend,
            'error-banner': ErrorBanner,
            'modal-dialog': ModalDialog
        }
    }
</script>
