import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    timeFormatSettingCaches: []
})

const getters = {
    findTimeFormatSettingCache: (state) => key => state.timeFormatSettingCaches.find(dtfsc => dtfsc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.timeFormatSettingCaches.find(dtfsc => dtfsc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.timeFormatSettingCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setTimeFormatSetting(state, { key, timeFormatSetting }) {
        const existing = state.timeFormatSettingCaches.find(dtfsc => dtfsc.key === key);
        if (!!existing) {
            existing.value = timeFormatSetting;
            existing.loading = false;
        } else {
            state.timeFormatSettingCaches.push({ key: key, value: timeFormatSetting, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.timeFormatSettingCaches.find(dtfsc => dtfsc.key === key);
        if (!!existing) {
            existing.retry = true;
        }
    }
}

const actions = {
    getTimeFormatSetting({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('TimeFormatSetting')
                .then(response => {
                    commit('setTimeFormatSetting', { key: key, timeFormatSetting: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (!!ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                });
        });
    },
    addTimeFormatSetting({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('TimeFormatSetting', payload)
                .then(response => {
                    commit('setTimeFormatSetting', { key: key, timeFormatSetting: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateTimeFormatSetting({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('TimeFormatSetting', payload)
                .then(response => {
                    commit('setTimeFormatSetting', { key: key, timeFormatSetting: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
