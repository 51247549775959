import moment from 'moment';
import { PaymentCheckoutUpdateMixin } from '../../mixins/Payments/PaymentCheckoutUpdateMixin';

export const CheckoutListItemMixin = {
    mixins: [PaymentCheckoutUpdateMixin],
    props: {
        payment: Object,
        dateFormat: String,
        isRefunder: Boolean
    },
    computed: {
        checkoutsReference() {
            return this.payment.reference;
        },
        checkoutId() {
            return this.payment.id;
        },
        canProcess() {
            return this.payment.canProcess;
        },
        canRefund() {
            return this.isRefunder && this.payment.canRefund;
        },
        provider() {
            return this.payment.providerType.description;
        },
        status() {
            return this.payment.status.description;
        },
        date() {
            return moment(this.payment.dateUtc).format(this.dateFormat);
        },
        amount() {
            return this.payment.amount.toFixed(2);
        },
        refunds() {
            return this.payment.refunds;
        },
        hasRefunds() {
            return this.refunds.length > 0;
        }
    },
    watch: {
        error(value) {
            this.$emit('error', value);
        }
    },
    methods: {
        tryFindCheckouts() {
            // do nothing
        },
        signalProcess() {
            if (this.canProcess) {
                this.$emit('process', this.payment);
            }
        }
    }
}
