import { Api } from '../../services/Api';

const state = () => ({
    loadingStripoDefaultTemplateSettings: false,
    loadedStripoDefaultTemplateSettings: false,
    stripoDefaultTemplateSettings: []
});

const mutations = {
    setSettings(state, settings) {
        state.loadedStripoDefaultTemplateSettings = true;
        state.stripoDefaultTemplateSettings = settings;
    },
    setLoading(state, value) {
        state.loadingStripoDefaultTemplateSettings = value;
    },
}

const actions = {
    getStripoDefaultTemplateSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('StripoDefaultTemplate/Setting')
                .then(response => {
                    commit('setSettings', response.data);
                    resolve(response);
                })
                .catch(error => { reject(error) })
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
