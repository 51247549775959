<template>
    <th class="text-center col-1" aria-controls="clients-table" style="font-size: 0.75rem;">
        <div>
            {{$t(daysOfWeek[dayId])}}
        </div>
        <div>
            {{openingString}}
        </div>
    </th>
</template>

<script>
    import { OpeningHoursMixin } from "../Resources/ResourceHours/OpeningHoursMixin";

    export default {
        name: 'UserResourceOpeningHours',
        mixins: [OpeningHoursMixin]
    }
</script>
