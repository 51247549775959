import { ErrorHelper } from "../../helpers/ErrorHelper";
import { CreditPacksCacheMixin } from "./CreditPacksCacheMixin";
import { mapActions } from 'vuex';

export const CreditPackUpdateMixin = {
    mixins: [CreditPacksCacheMixin],
    data() {
        return {
            discountCode: ''
        }
    },
    computed: {
        creditPack() {
            return this.creditPacks[0];
        },
        creditPackId() {
            return !!this.creditPack ? this.creditPack.id : '';
        },
        creditPackUpdateModel() {
            return {
                id: this.creditPackId,
                discountCode: this.discountCode
            }
        },
        creditPackUpdateModelValid() {
            const model = this.creditPackUpdateModel;
            return !!this.siteId && !!model.id && !!model.discountCode;
        }
    },
    methods: {
        ...mapActions('CreditPacks', [
            'updateCreditPack',
            'deleteCreditPack'
        ]),
        tryUpdateCreditPack() {
            const self = this;
            if (!self.creditPackUpdateModelValid) {
                return;
            }
            self.error = '';
            self.updateCreditPack({ key: self.creditPacksCacheKey, payload: self.creditPackUpdateModel })
                .then(self.refreshUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshUpdateModel() {
            this.discountCode = '';
        },
        tryDeleteCreditPack() {
            const self = this;
            if (!self.creditPackId) {
                return;
            }
            self.error = '';
            self.deleteCreditPack({ key: self.creditPacksCacheKey, id: self.creditPackId })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
