import { ErrorHelper } from "../../helpers/ErrorHelper";
import { AppointmentDepositAddMixin } from "./AppointmentDepositAddMixin";
import { mapActions } from 'vuex';

export const AppointmentDepositUpdateMixin = {
    mixins: [AppointmentDepositAddMixin],
    computed: {
        appointmentDepositUpdateModel() {
            const dueDaysPrior = parseInt(this.dueDaysPrior);
            return !!this.appointmentDeposit ? Object.assign({}, this.appointmentDeposit, {
                providerType: Object.assign({}, this.appointmentDeposit.providerType, {
                    id: this.providerTypeId
                }),
                isRequired: this.isRequired,
                amount: this.amount,
                dueDaysPrior: isNaN(dueDaysPrior) ? null : dueDaysPrior
            }) : {};
        },
        appointmentDepositUpdateModelValid() {
            const model = this.appointmentDepositUpdateModel;
            return !!model.id && !!model.scheduleId && this.appointmentDepositValidator.isValid;
        },
        appointmentDepositUpdateModelModified() {
            return JSON.stringify(this.appointmentDepositUpdateModel) !== JSON.stringify(this.appointmentDeposit);
        }
    },
    watch: {
        appointmentDeposit() {
            this.resetAppointmentDepositModel();
        }
    },
    methods: {
        ...mapActions('AppointmentDeposits', [
            'updateAppointmentDeposit'
        ]),
        tryUploadAppointmentDeposit() {
            if (!!this.appointmentDeposit) {
                this.tryUpdateAppointmentDeposit();
            } else {
                this.tryAddAppointmentDeposit();
            }
        },
        tryUpdateAppointmentDeposit() {
            const self = this;
            self.appointmentDepositValidator.check(self.appointmentDepositUpdateModel);
            if (!self.appointmentDepositUpdateModelValid || !self.appointmentDepositUpdateModelModified) {
                return
            }
            self.error = '';
            self.updateAppointmentDeposit({ key: self.scheduleId, payload: self.appointmentDepositUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        resetAppointmentDepositModel() {
            this.providerTypeId = !!this.appointmentDeposit ? this.appointmentDeposit.providerType.id : 0;
            this.isRequired = !!this.appointmentDeposit ? this.appointmentDeposit.isRequired : false;
            this.amount = !!this.appointmentDeposit ? this.appointmentDeposit.amount : 0;
            this.dueDaysPrior = !!this.appointmentDeposit ? this.appointmentDeposit.dueDaysPrior : null;
        }
    },
    created() {
        this.resetAppointmentDepositModel();
    }
}
