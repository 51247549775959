<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{title}}<slot name="tooltip"></slot></label>
        </div>
        <div class="col-md-8">
            <label class="ps-toggle-switch">
                <input v-model="toggle" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch">
                </div>
                <slot></slot>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SlottedToggle',
        props: {
            title: String,
            value: Boolean
        },
        computed: {
            toggle: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
