import { Api } from "../../services/Api";

const state = () => ({
    appointmentCaches: []
})

const getters = {
    findAppointmentCache: (state) => (key) => {
        return state.appointmentCaches.find(ac => ac.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.appointmentCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setAppointments(state, appointments) {
        for (var i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            const existing = state.appointmentCaches.find(ac => ac.key === appointment.id);
            if (!!existing) {
                existing.value = appointment;
                existing.loading = false;
            } else {
                state.appointmentCaches.push({ key: appointment.id, value: appointment, loading: false });
            }
        }
    },
    setAppointment(state, appointment) {
        const existing = state.appointmentCaches.find(ac => ac.key === appointment.id);
        if (!!existing) {
            existing.value = appointment;
            existing.loading = false;
        } else {
            state.appointmentCaches.push({ key: appointment.id, value: appointment, loading: false });
        }
    },
    clearAppointments(state) {
        state.appointmentCaches = [];
    }
}

const actions = {
    updateAppointments({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Appointment', payload)
                .then(response => {
                    commit('setAppointments', response.data);
                    commit('AppointmentSearches/clearAppointmentSearches', null, { root: true });
                    commit('ConflictSearches/clearConflictSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', { key: key, loading: false });
                    reject(error);
                });
        });
    },
    deleteAppointments({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete(`Appointment/${key}?` + new URLSearchParams(payload))
                .then(response => {
                    commit('clearAppointments');
                    commit('ApplicationEvents/triggerAppointmentDeleted', null, { root: true });
                    commit('AppointmentSearches/clearAppointmentSearches', null, { root: true });
                    commit('ConflictSearches/clearConflictSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }))
        });
    },
    updateAppointmentResources({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Appointment/Resource', payload)
                .then(response => {
                    commit('setAppointments', response.data);
                    commit('AppointmentSearches/clearAppointmentSearches', null, { root: true });
                    commit('ConflictSearches/clearConflictSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', { key: key, loading: false });
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
