import { Api } from '../../services/Api';

const state = () => ({
    loadingClientCount: false,
    loadedClientCount: false,
    clientCount: null
})

const mutations = {
    setloadingClientCount(state, value) {
        state.loadingClientCount = !!value;
    },
    setClientCount(state, count) {
        state.clientCount = count;
        state.loadedClientCount = true;
    }
}

const actions = {
    fetchClientCount({ commit }) {
        commit('setloadingClientCount', true);
        return new Promise((resolve, reject) => {
            Api.get('Client/Count')
                .then(response => {
                    commit('setClientCount', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setloadingClientCount', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
