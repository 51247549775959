<template>
    <tr @click="gotoCampaignEdit" class="link pointer" v-bind:class="classType">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a class="text-truncate">{{subject}}</a>
            </div>
        </td>
        <td class="text-center">
            {{$t(method)}}
        </td>
        <td class="text-center">
            {{reach}}
        </td>
        <td class="text-center">
            {{$t(confirmed)}}
        </td>
        <td class="text-center">
            {{$t(completed)}}
        </td>
    </tr>
</template>

<script>
    import router from '../../../../routing';

    export default {
        name: 'CampaignItemDesktop',
        props: {
            campaign: Object,
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            },
            subject() {
                return !!this.campaign ? this.campaign.subject : '';
            },
            startLetter() {
                return this.subject.length > 0 ? this.subject[0] : '';
            },
            campaignId() {
                return !!this.campaign ? this.campaign.id : '';
            },
            reach() {
                return !!this.campaign ? this.campaign.reach : 0;
            },
            confirmed() {
                return !!this.campaign && this.campaign.confirmed ? 'Yes' : 'No';
            },
            completed() {
                return !!this.campaign && this.campaign.completed ? 'Yes' : 'No';
            },
            method() {
                return !!this.campaign ? this.campaign.methodType.description : '';
            }
        },
        methods: {
            gotoCampaignEdit() {
                router.push({ name: 'campaignUpdate', params: { id: this.campaignId } });
            }
        }
    }
</script>
