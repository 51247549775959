import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ReportTypesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ReportTypes', [
            'fetchingReportTypes',
            'fetchedReportTypes',
            'reportTypes'
        ])
    },
    methods: {
        ...mapActions('ReportTypes', [
            'fetchReportTypes'
        ]),
        refreshReportTypes() {
            if (!this.fetchedReportTypes && !this.fetchingReportTypes) {
                this.tryFetchReportTypes();
            }
        },
        tryFetchReportTypes() {
            const self = this;
            self.error = '';
            self.fetchReportTypes()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshReportTypes();
    }
}
