import { mapActions, mapMutations, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const SiteCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Sites', [
            'sites',
            'fetchedSites',
            'fetchingSites'
        ]),
        displaySite() {
            return this.sites.length > 1;
        },
        site() {
            return this.sites.find(s => s.id === this.siteId);
        },
        siteName() {
            return !!this.site ? this.site.name : '-';
        },
        companyName() {
            return !!this.site ? this.site.companyName : '';
        }
    },
    methods: {
        ...mapMutations('Sites', [
            'setSelected'
        ]),
        ...mapActions('Sites', [
            'getSites'
        ]),
        refreshSites() {
            if (!this.fetchedSites && !this.fetchingSites) {
                this.tryGetSites();
            }
        },
        tryGetSites() {
            const self = this;
            self.error = '';
            self.getSites()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSites();
    }
}
