import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const EventSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'eventUpdatedEvent',
            'eventDeletedEvent',
            'eventsAddedEvent'
        ]),
        ...mapGetters('EventSearches', [
            'findEventSearch'
        ]),
        eventSearchModel() {
            console.log('!!!OVERRIDE COMPUTED - eventSearchModel!!!');
        },
        eventSearchValid() {
            const model = this.eventSearchModel;
            return !!model && model.siteIds.length > 0 && !!model.startUtc && !!model.endUtc;
        },
        eventSearchCacheKey() {
            return CacheHelper.objectCacheKey(this.eventSearchModel);
        },
        eventSearchCache() {
            return this.findEventSearch(this.eventSearchCacheKey);
        },
        staffEvents() {
            return !!this.eventSearchCache ? this.eventSearchCache.values : [];
        },
        loadingStaffEvents() {
            return !!this.eventSearchCache ? this.eventSearchCache.loading : false;
        }
    },
    watch: {
        eventSearchModel() {
            this.refreshEventSearch();
        },
        eventUpdatedEvent() {
            this.fullRefreshEventSearch();
        },
        eventDeletedEvent() {
            this.fullRefreshEventSearch();
        },
        eventsAddedEvent() {
            this.fullRefreshEventSearch();
        }
    },
    methods: {
        ...mapMutations('EventSearches', [
            'clearSearches'
        ]),
        ...mapActions('EventSearches', [
            'searchEvents'
        ]),
        fullRefreshEventSearch() {
            this.clearSearches();
            this.refreshEventSearch();
        },
        refreshEventSearch() {
            if (!this.eventSearchCache || this.eventSearchCache.retry) {
                this.searchAndCacheEvents();
            }
        },
        searchAndCacheEvents() {
            const self = this;
            if (!self.eventSearchValid) {
                return;
            }
            self.error = '';
            self.searchEvents({ key: self.eventSearchCacheKey, payload: self.eventSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEventSearch();
    }
}
