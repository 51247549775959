import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionUpgradeDeleteMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionUpgrade', [
            'addingSubscriptionUpgrade'
        ])
    },
    methods: {
        ...mapActions('SubscriptionUpgrade', [
            'deleteSubscriptionUpgrade'
        ]),
        tryDeleteSubscriptionUpgrade() {
            const self = this;
            self.error = '';
            self.deleteSubscriptionUpgrade()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
