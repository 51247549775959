import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';

export const SmsSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SmsSettings', [
            'loadingSmsSettings',
            'loadedSmsSettings',
            'smsSettings'
        ]),
        smsSetting() {
            return this.smsSettings.find(s => s.siteId == this.siteId);
        }
    },
    watch: {
        siteId() {
            this.refreshSmsSettings();
        }
    },
    methods: {
        ...mapActions('SmsSettings', [
            'getSmsSettings'
        ]),
        refreshSmsSettings() {
            if (!this.loadedSmsSettings && !this.loadingSmsSettings) {
                this.tryGetSmsSettings();
            }
        },
        tryGetSmsSettings() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getSmsSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSmsSettings();
    }
}
