<template>
    <tr class="link pointer" v-bind:class="classType">
        <td class="text-left">{{description}}</td>
        <td class="text-center">{{createdLocalString}}</td>
        <td class="text-center">{{statusDescription}}</td>
        <td class="text-center">
            <span v-if="payment.currency === 'GBP'">&pound;</span>
            <span v-else>&dollar;</span>{{amount}}
        </td>
        <td class="text-center">
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="onClick" title="Checkout">
                <i class="fal fa-arrow-right"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import { PaymentFailureItemMixin } from "./PaymentFailureItemMixin";

    export default {
        name: 'PaymentFailureItemDesktop',
        mixins: [PaymentFailureItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
