<template>
    <div>
        <button ref="displayButton" type="button" data-target="#system-admin-account-confirmation-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="system-admin-account-confirmation-modal" title="Are you sure?">
            <section class="rounded-card-white mb-3">
                <div role="alert" class="alert alert-warning alert-icon">
                    <div class="alert-content">
                        <p>Do you want to <b>{{ isDisabled ? 'disable' : 'enable'}}</b> account: {{id}}?</p>
                    </div>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <button @click="proceed" type="button" class="btn btn-primary mr-2">Yes</button>
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">No</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import ModalDialog from "../Layout/ModalDialog.vue";

    export default {
        name: 'SystemAdminGeneralNotificationsConfirmation',
        props: {
            updateModel: Object
        },
        computed: {
            isDisabled() {
                return !!this.updateModel ? this.updateModel.isDisabled : false;
            },
            id() {
                return !!this.updateModel ? this.updateModel.id : '';
            }
        },
        methods: {
            proceed() {
                this.$refs.cancelButton.click();
                this.$emit('proceed');
            }
        },
        components: {
            'modal-dialog': ModalDialog
        }
    }
</script>