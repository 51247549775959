import { Api } from "../../services/Api";

const state = () => ({
    addingCampaignSettings: false,
    updatingCampaignSettings: false,
    loadingCampaignSettings: false,
    loadedCampaignSettings: false,
    campaignSettings: []
})

const mutations = {
    setCampaignSettings(state, setCampaignSettings) {
        state.campaignSettings = setCampaignSettings;
        state.loadedCampaignSettings = true;
        state.loadingCampaignSettings = false;
    },
    setLoading(state, value) {
        state.loadingCampaignSettings = !!value;
        state.loadedCampaignSettings = false;
    },
    setAdding(state, value) {
        state.addingCampaignSettings = !!value;
    },
    setUpdating(state, value) {
        state.updatingCampaignSettings = !!value;
    },
    appendCampaignSetting(state, campaignSetting) {
        state.campaignSettings.push(campaignSetting);
    },
    insertCampaignSetting(state, campaignSetting) {
        const existing = state.campaignSettings.find(s => s.id === campaignSetting.id);
        if (!!existing) {
            const index = state.campaignSettings.indexOf(existing);
            state.campaignSettings.splice(index, 1, campaignSetting);
        } else {
            state.campaignSettings.push(campaignSetting);
        }
    }
}

const actions = {
    getCampaignSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('CampaignSetting')
                .then(response => {
                    commit('setCampaignSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
        });
    },
    addCampaignSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('CampaignSetting', payload)
                .then(response => {
                    commit('appendCampaignSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateCampaignSettingCredits({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('CreditPack/CampaignSetting')
                .then(response => {
                    commit('insertCampaignSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    },
    upgradeCampaignSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('Subscription/CampaignSetting')
                .then(response => {
                    commit('setCampaignSettings', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}