import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SortableSearchMixin } from "./Searching/SortableSearchMixin";

export const SubscriptionPaymentSearchMixin = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            searchTerm: '',
            skip: 0,
            take: 10,
            ascending: false,
            returnDefault: true,
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters('SubscriptionPaymentSearches', [
            'findSubscriptionPaymentSearch'
        ]),
        subscriptionPaymentSearchModel() {
            return {
                searchTerm: this.searchTerm,
                sortKey: this.sortKey,
                ascending: this.ascending,
                returnDefault: this.returnDefault,
                skip: this.skip,
                take: this.take
            }
        },
        subscriptionPaymentSearchModelValid() {
            return !!this.searchTerm || this.returnDefault;
        },
        subscriptionPaymentSearchKey() {
            return CacheHelper.objectCacheKey(this.subscriptionPaymentSearchModel);
        },
        subscriptionPaymentSearch() {
            return this.findSubscriptionPaymentSearch(this.subscriptionPaymentSearchKey);
        },
        subscriptionPayments() {
            return !!this.subscriptionPaymentSearch ? this.subscriptionPaymentSearch.values : [];
        },
        searchingSubscriptionPayments() {
            return !!this.subscriptionPaymentSearch && this.subscriptionPaymentSearch.loading;
        },
        subscriptionPaymentResultsReady() {
            return !!this.subscriptionPaymentSearch && !this.searchingSubscriptionPayments;
        },
        moreSubscriptionPayments() {
            return this.subscriptionPayments.length === this.take;
        },
        subscriptionPaymentPageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshSubscriptionPaymentSearch();
            }, 500);
        },
        sortModel() {
            this.refreshSubscriptionPaymentSearch();
        }
    },
    methods: {
        ...mapActions('SubscriptionPaymentSearches', [
            'searchSubscriptionPayments'
        ]),
        refreshSubscriptionPaymentSearch() {
            if (!this.subscriptionPaymentSearch || !!this.subscriptionPaymentSearch.retry) {
                this.searchAndCacheSubscriptionPayments();
            }
        },
        searchAndCacheSubscriptionPayments() {
            const self = this;
            self.error = '';
            if (!self.subscriptionPaymentSearchModelValid) {
                return;
            }
            self.searchSubscriptionPayments({ key: self.subscriptionPaymentSearchKey, payload: self.subscriptionPaymentSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        next() {
            if (this.moreSubscriptionPayments) {
                this.skip += this.take;
                this.refreshSubscriptionPaymentSearch();
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshSubscriptionPaymentSearch();
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshSubscriptionPaymentSearch();
        }
    }
}
