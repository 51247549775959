<template>

</template>

<script>
    import moment from 'moment';
    import { AccountSettingPostRegisterMixin } from '../../../mixins/Settings/Account/AccountSettingPostRegisterMixin';

    export default {
        name: 'PostRegisterAccountSettingDefaultTimeZone',
        mixins: [AccountSettingPostRegisterMixin],
        computed: {
            timeZoneId() {
                return moment.tz.guess();
            },
            accountSettingKey() {
                return 'DefaultTimeZone';
            },
            accountSettingEditModel() {
                return {
                    timeZoneId: this.timeZoneId
                };
            }
        }
    }
</script>
