import { mapGetters, mapActions } from 'vuex';
import { HashHelper } from '../../helpers/HashHelper';
import { CacheHelper } from '../../helpers/CacheHelper';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AddressSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AddressSearches', [
            'findAddressSearch'
        ]),
        ...mapGetters('Addresses', [
            'findAddressCache'
        ]),
        ownerTypeId() {
            console.log('OVERRIDE COMPUTED - ownerTypeId');
            return 0;
        },
        ownerIds() {
            console.log('OVERRIDE COMPUTED - ownerIds');
            return [];
        },
        missingOwnerIds() {
            return this.ownerIds.filter(id =>
                !this.findAddressCache(CacheHelper.ownerRequestCacheKey({ ownerTypeId: this.ownerTypeId, ownerId: id })));
        },
        addressSearchModel() {
            return {
                ownerTypeId: this.ownerTypeId,
                ownerIds: this.missingOwnerIds,
                ascending: false
            }
        },
        addressSearchModelValid() {
            const model = this.addressSearchModel;
            return !!model.ownerTypeId && model.ownerIds.length > 0;
        },
        addressSearchKey() {
            return HashHelper.createStringHash(this.ownerIds.join() + this.ownerTypeId);
        },
        addressSearch() {
            return this.findAddressSearch(this.addressSearchKey);
        },
        addresses() {
            return !!this.addressSearch ? this.addressSearch.values : [];
        },
        loadingAddressSearch() {
            return !!this.addressSearch && this.addressSearch.loading;
        }
    },
    watch: {
        addressSearchKey() {
            this.refreshAddressSearch();
        }
    },
    methods: {
        ...mapActions('AddressSearches', [
            'searchAddresses'
        ]),
        trySearchAddresses() {
            const self = this;
            if (!self.addressSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchAddresses({ key: self.addressSearchKey, payload: self.addressSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshAddressSearch() {
            if (!this.addressSearch || !!this.addressSearch.retry) {
                this.trySearchAddresses();
            }
        }
    },
    created() {
        this.refreshAddressSearch();
    }
}
