import { Api } from '../../services/Api';

const state = () => ({
    breedSearchCaches: [],
    breedSearchesCleared: 0
})

const getters = {
    findBreedSearch: (state) => (key) => state.breedSearchCaches.find(search => search.key === key),
    findBreedSearches: (state) => (key) => state.breedSearchCaches.filter(search => search.key.includes(key))
}

const mutations = {
    setLoading(state, key) {
        const existing = state.breedSearchCaches.find(bsc => bsc.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.breedSearchCaches.push({ key: key, values: [], loading: true });
        }
    },
    setSearch(state, { key, results }) {
        const existing = state.breedSearchCaches.find(bsc => bsc.key === key);
        if (!!existing) {
            existing.values = results;
            existing.loading = false;
        } else {
            state.breedSearchCaches.push({ key: key, values: results, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.breedSearchCaches.find(search => search.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearBreedSearches(state) {
        state.breedSearchCaches = [];
        state.breedSearchesCleared++;
    }
}

const actions = {
    searchBreeds({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', key);
            Api.post('Breed/Search', payload)
                .then(response => {
                    commit('setSearch', { key: key, results: response.data });
                    commit('Breeds/setBreeds', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
