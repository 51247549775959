<template>
    <display-tags :tags="tagList"></display-tags>
</template>

<script>
    import { ContractTemplateTagCacheMixin } from "../../Shared/ContractTemplateTagCacheMixin";
    import TagsDisplay from "../../Shared/Tags/TagsDisplay.vue";

    export default {
        name: 'TemplateTagInfo',
        mixins: [ContractTemplateTagCacheMixin],
        components: {
            'display-tags': TagsDisplay
        }
    }
</script>