<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{clientFullname}}</h1>
                <p class="d-none d-md-block mb-0"><i class="fas fa-mobile-android-alt"></i> {{mobileNumber}}</p>
                <p class="d-block d-md-none text-white mb-0"><i class="fas fa-mobile-android-alt"></i> {{mobileNumber}}</p>
            </div>
            <div class="secondary-nav">
                <nav class="links">
                    <ul>
                        <li @click="showClientOverview=true" class="pointer" v-bind:class="{ active: showClientOverview }">
                            <a>
                                {{$t('Overview')}}
                                <client-alert-badge :clientId="clientId" class="ml-1"></client-alert-badge>
                            </a>
                        </li>
                        <client-pets-tab @click="showClientOverview=false" :id="clientId" :active="!showClientOverview"></client-pets-tab>
                    </ul>
                </nav>
            </div>
            <div class="header-nav header-nav-left">
                <a @click="goBack" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="gotoAdd" class="mobile-header-btn" v-bind:disabled="fetchingClientAddAllowed">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <client-search></client-search>
                <button @click="gotoAddClient" class="btn-add btn btn-secondary d-none d-md-block" v-bind:disabled="fetchingClientAddAllowed">
                    <i class="fas fa-plus"></i>{{$t('Add client')}}
                </button>
                <notification-bell></notification-bell>
            </div>
        </header>
        <client-alert-note :clientId="clientId"></client-alert-note>
        <client-overview v-if="showClientOverview" :client="client"></client-overview>
        <template v-else>
            <pet-overview :client="client" :add-pressed="addPressed"></pet-overview>
        </template>
    </div>
</template>

<script>
    import router from '../../routing';
    import { mapMutations } from 'vuex';
    import { ClientCacheMixin } from '../Shared/ClientCacheMixin';
    import { ClientAddAllowedMixin } from '../Shared/ClientAddAllowedMixin';
    import ClientOverview from './ClientOverview.vue';
    import ClientPetsTab from './ClientPetsTab.vue';
    import ClientAlertNotification from './ClientAlertNotification.vue';
    import ClientAlertBadge from './ClientAlertBadge.vue';
    import PetOverview from '../Pet/PetOverview.vue';
    import ClientSearchClient from './Search/ClientSearchClient.vue';
    import NotificationBell from '../Shared/Notifications/NotificationBell.vue';


    export default {
        name: 'ClientDisplay',
        mixins: [ClientCacheMixin, ClientAddAllowedMixin],
        data() {
            return {
                showClientOverview: true,
                addPressed: 0
            }
        },
        computed: {
            clientId() {
                return this.$route.params.id;
            },
            mobileNumber() {
                return !!this.client && !!this.client.telephoneMobile ? this.client.telephoneMobile : 'N/A';
            }
        },
        watch: {
            client() {
                this.setSite();
            }
        },
        methods: {
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            gotoAdd() {
                if (this.showClientOverview) {
                    this.gotoAddClient();
                } else {
                    this.addPressed++;
                }
            },
            goBack() {
                router.push({ name: 'clients' });
            },
            setSite() {
                if (!!this.client) {
                    this.setSelected(this.client.siteId);
                }
            }
        },
        components: {
            'client-overview': ClientOverview,
            'pet-overview': PetOverview,
            'client-pets-tab': ClientPetsTab,
            'client-alert-note': ClientAlertNotification,
            'client-alert-badge': ClientAlertBadge,
            'client-search': ClientSearchClient,
            'notification-bell': NotificationBell
        },
        created() {
            if (!this.clientId) {
                router.push({ name: 'clients' });
            } else {
                this.showClientOverview = !!this.$route.query.pet ? false : true;
                this.setSite();
            }
        }
    }
</script>
