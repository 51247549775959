export const WeightHelper = {
    getValidator() {
        return {
            isValid: false,
            value: '',
            check(model) {
                this.value = '';
                if (isNaN(model.value) || !model.value > 0) {
                    this.value = 'Weight must be greater than 0';
                }
                this.isValid = !this.value;
            }
        }
    }
}
