<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Amount')}} ({{currencySymbol}})</label>
        </div>
        <div class="col-md-8">
            <input v-model="amountInput" @blur="processInput" class="form-control text-right" type="text">
            <validation-message v-if="validation" :message="validation"></validation-message>
        </div>
    </div>
</template>

<script>
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";
    import { ProductInputMixin } from "./ProductInputMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ProductAmountInput',
        mixins: [ProductInputMixin, CountryCacheMixin],
        props: {
            value: Number,
            validation: String
        },
        watch: {
            amount(newValue) {
                this.$emit('input', newValue);
            }
        },
        methods: {
            refreshAmount() {
                this.setInitialAmount(this.value);
            }
        },
        created() {
            this.refreshAmount();
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
