import { ErrorHelper } from "../../helpers/ErrorHelper";
import { TaxHelper } from "../../helpers/TaxHelper";
import { AccountSettingCacheMixin } from "../Settings/Account/AccountSettingCacheMixin";
import { TaxCacheMixinV2 } from "./TaxCacheMixinV2";
import { mapActions } from 'vuex';

export const TaxAddMixin = {
    mixins: [TaxCacheMixinV2, AccountSettingCacheMixin],
    data() {
        return {
            rate: null,
            number: '',
            taxValidator: TaxHelper.getTaxValidatorV2()
        }
    },
    computed: {
        accountSettingKey() {
            return 'DefaultTaxDetails';
        },
        taxAddModel() {
            return {
                rate: this.rate,
                number: this.number
            }
        },
        taxAddModelValid() {
            return this.taxValidator.isValid;
        },
        taxAddModelModified() {
            const model = this.taxAddModel;
            return !!model.rate || !!model.number;
        }
    },
    watch: {
        accountSetting() {
            this.refreshTaxModel();
        }
    },
    methods: {
        ...mapActions('Taxes', [
            'addTax'
        ]),
        tryAddTax() {
            const self = this;
            if (!self.taxAddModelModified) {
                return;
            }
            self.taxValidator.validate(self.taxAddModel);
            if (!self.taxAddModelValid) {
                return;
            }
            self.error = '';
            self.addTax(self.taxAddModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshTaxModel() {
            this.rate = !!this.accountSettingObject ? this.accountSettingObject.rate : null;
            this.number = !!this.accountSettingObject ? this.accountSettingObject.number : '';
        }
    },
    created() {
        this.refreshTaxModel();
    }
}
