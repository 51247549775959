<template>
    <tr>
        <td>
            <day-cell v-for="dayId in dayIds" :key="dayId" :day-id="dayId" :working-hours="resourceWorkingHours" :oneOffHours="resourceOneOffHours" @cell-clicked="onCellClicked"></day-cell>
        </td>
    </tr>
</template>

<script>
    import UserResourceHoursDayMobile from "./UserResourceHoursDayMobile.vue";
    import { ResourceHoursMixin } from "../Resources/ResourceHours/ResourceHoursMixin";

    export default {
        name: 'UserResourceHoursMobile',
        mixins: [ResourceHoursMixin],
        computed: {
            id() {
                return 'collapse-resource-' + this.resourceId;
            }
        },
        components: {
            'day-cell': UserResourceHoursDayMobile
        }
    }
</script>
