<template>
    <tr @click="onClick">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{templateName}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Version')}}: {{version}} {{signature}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { ContractItemMixin } from "./ContractItemMixin";

    export default {
        name: 'ContractItemMobile',
        mixins: [ContractItemMixin],
        computed: {
            signature() {
                return !!this.signatureRequired ? `(${this.$t('signature')})` : '';
            }
        }
    }
</script>
