<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="table-responsive">
            <table class="table table-striped ps-table table-hover">
                <thead>
                    <tr>
                        <th>{{$t('Pet')}}</th>
                        <th>{{$t('Date')}}</th>
                        <th>{{$t('Time')}}</th>
                        <th class="text-center">{{$t('Status')}}</th>
                        <th>{{$t('Created')}}</th>
                        <th class="text-center">{{$t('View')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <appointment-item v-for="appointment in appointments" :key="appointment.id" :appointment="appointment" :status-class="getStatusClass(appointment)"></appointment-item>
                </tbody>
            </table>
            <button v-if="more" @click="searchAndCacheAppointments" type="button" class="btn btn-outline-secondary">{{$t('Load more')}}</button>
        </div>
    </div>
</template>

<script>
    import { AppointmentSearchMixin } from '../Shared/AppointmentSearchMixin.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ClientAppointmentSectionItem from './ClientAppointmentSectionItem.vue';

    export default {
        name: 'ClientAppointmentSection',
        mixins: [AppointmentSearchMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id;
            },
            searchModel() {
                return { clientId: this.id };
            },
            searchModelValid() {
                return !!this.searchModel && !!this.searchModel.clientId;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'appointment-item': ClientAppointmentSectionItem
        }
    }
</script>
