<template>
    <div class="page-loader">
        <img class="loading-icon" src="../../../assets/images/loading.svg" width="100">
    </div>
</template>

<script>
    export default {
        name: 'PageLoader'
    }
</script>
