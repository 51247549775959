import { TaxCacheMixinV2 } from "../../mixins/Tax/TaxCacheMixinV2";
import { CountryCacheMixin } from "./Country/CountryCacheMixin";

export const AppointmentTotalMixin = {
    mixins: [TaxCacheMixinV2, CountryCacheMixin],
    computed: {
        orderItems() {
            console.log('!!!OVERRIDE COMPUTED - orderItems!!!');
        },
        taxableAmount() {
            return this.orderItems.map(s => s.amount * s.quantity).reduce((a, b) => a + b, 0);
        },
        payableTax() {
            return !!this.tax ? this.taxableAmount * 0.01 * this.tax.rate : 0;
        },
        payableTaxString() {
            return this.currencySymbol + this.payableTax.toFixed(2).toString();
        },
        totalAmount() {
            return this.taxableAmount + this.payableTax;
        },
        totalString() {
            return this.currencySymbol + this.totalAmount.toFixed(2).toString();
        }
    }
}
