import { Api } from "../../services/Api";

const state = () => ({
    campaignsCaches: []
})

const getters = {
    findCampaignsCache: (state) => key => state.campaignsCaches.find(cc => cc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.campaignsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCampaigns(state, { key, campaigns }) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = campaigns;
            existing.loading = false;
        } else {
            state.campaignsCaches.push({ key: key, values: campaigns, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendCampaign(state, { key, campaign }) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values.push(campaign);
        } else {
            state.campaignsCaches.push({ key: key, values: [campaign], loading: false });
        }
    },
    insertCampaign(state, { key, campaign }) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            const old = existing.values.find(c => c.id === campaign.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, campaign);
            } else {
                existing.values.push(campaign);
            }
        } else {
            state.campaignsCaches.push({ key: key, values: [campaign], loading: false });
        }
    },
    removeCampaign(state, { key, id }) {
        const existing = state.campaignsCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(c => c.id !== id);
        }
    }
}

const actions = {
    findCampaigns({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Campaign')
                .then(response => {
                    commit('setCampaigns', { key: key, campaigns: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    },
    addCampaign({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Campaign', payload)
                .then(response => {
                    commit('appendCampaign', { key: key, campaign: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateCampaign({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Campaign', payload)
                .then(response => {
                    commit('insertCampaign', { key: key, campaign: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteCampaign({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.delete('Campaign/' + id)
                .then(response => {
                    commit('removeCampaign', { key: key, id: id });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
