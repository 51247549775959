import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const BookingStatusesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingStatuses', [
            'fetchingBookingStatuses',
            'fetchedBookingStatuses',
            'bookingStatuses'
        ])
    },
    methods: {
        ...mapActions('BookingStatuses', [
            'fetchBookingStatuses'
        ]),
        refreshBookingStatuses() {
            if (!this.fetchedBookingStatuses && !this.fetchingBookingStatuses) {
                this.tryFetchBookingStatuses();
            }
        },
        tryFetchBookingStatuses() {
            const self = this;
            self.error = '';
            self.fetchBookingStatuses()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingStatuses();
    }
}
