import Vue from 'vue';
import VueRouter from 'vue-router'
import Interstitial from '../components/Authentication/Interstitial.vue'
import Login from '../components/Authentication/Login.vue'
import Auth from '../components/Authentication/Auth.vue'
import Register from '../components/Authentication/Registration/Register.vue'
import ForgotPassword from '../components/Authentication/ForgotPassword.vue'
import MainLayout from '../components/Layout/MainLayout.vue'
import Scheduler from '../components/Scheduler/Schedule.vue'
import Clients from '../components/Clients/Clients.vue'
import ClientAdd from '../components/Clients/ClientAdd.vue'
import ClientDisplay from '../components/Clients/ClientDisplay.vue'
import Breeds from '../components/Breeds/Breeds.vue'
import MyAccount from '../components/Account/MyAccount.vue';
import Resources from '../components/Resources/Resources.vue';
import Services from '../components/Products/Services.vue';
import Sites from '../components/Organisation/Sites.vue';
import SiteDisplay from '../components/Organisation/SiteDisplay.vue';
import SiteAdd from '../components/Organisation/SiteAdd/SiteAdd.vue';
import Users from '../components/Users/Users.vue';
import PasswordReset from '../components/Authentication/PasswordReset.vue';
import PasswordSet from '../components/Authentication/PasswordSet.vue';
import Contracts from '../components/Contractual/Template/Contracts.vue';
import ContractAdd from '../components/Contractual/Template/ContractAdd.vue';
import ContractUpdate from '../components/Contractual/Template/ContractUpdate.vue';
import EmailMarketingTemplates from '../components/Communication/Template/EmailMarketingTemplates.vue';
import SmsMarketingTemplates from '../components/Communication/Template/SmsMarketingTemplates.vue';
import SmsTemplateUpload from '../components/Communication/Template/SmsTemplateUpload.vue';
import EmailMarketingTemplateUpload from '../components/Communication/Template/EmailMarketingTemplateUpload.vue';
import PostRegister from '../components/Authentication/Registration/PostRegister.vue';
import Reports from '../components/Reporting/Reports.vue';
import TermsAndConditions from '../components/Authentication/Registration/TermsAndConditions.vue';
import PrivacyPolicy from '../components/Authentication/Registration/PrivacyPolicy.vue';
import Dashboard from '../components/Dashboard/Dashboard.vue';
import PaymentProviders from '../components/Payments/PaymentProviders.vue';
import CampaignEmailTemplates from '../components/Communication/Campaign/CampaignEmailTemplates.vue';
import CampaignEmailTemplateAdd from '../components/Communication/Campaign/CampaignEmailTemplateAdd.vue';
import CampaignEmailTemplateUpdate from '../components/Communication/Campaign/CampaignEmailTemplateUpdate.vue';
import Campaigns from '../components/Communication/Campaign/CampaignsV2/Campaigns.vue';
import CampaignAdd from '../components/Communication/Campaign/CampaignsV2/CampaignAdd.vue';
import CampaignUpdate from '../components/Communication/Campaign/CampaignsV2/CampaignUpdate.vue';
import CampaignSmsTemplates from '../components/Communication/Campaign/CampaignSmsTemplates.vue';
import CampaignSmsTemplateAdd from '../components/Communication/Campaign/CampaignSmsTemplateAdd.vue';
import CampaignSmsTemplateUpdate from '../components/Communication/Campaign/CampaignSmsTemplateUpdate.vue';
import PaymentReports from '../components/Payments/PaymentReports.vue';
import SubscriptionUpgradeV2 from '../components/Subscriptions/SubscriptionsV2/SubscriptionUpgradeV2.vue';
import SubscriptionUpsellDisplay from '../components/Subscriptions/SubscriptionUpsellDisplay.vue';
import RestrictedArea from '../components/Account/RestrictedArea.vue';
import RestrictedCheckout from '../components/Account/RestrictedCheckout.vue';
import OutstandingPayments from '../components/Account/OutstandingPayments.vue';
import PaymentRetry from '../components/Account/PaymentRetry.vue';
import Store from '../components/Store/Store.vue';
import SubscriptionUpgradeComplete from '../components/Subscriptions/SubscriptionUpgradeComplete.vue';
import StoreCheckout from '../components/Store/StoreCheckout.vue';
import CampaignPackComplete from '../components/CampaignCredits/CampaignPackComplete.vue';
import SmsPackComplete from '../components/SmsCredits/SmsPackComplete.vue';
import EmailTemplatePackComplete from '../components/EmailTemplateCredits/EmailTemplatePackComplete.vue';
import PaymentCheckout from '../components/Store/PaymentCheckout.vue';
import SubscriptionCheckout from '../components/Store/SubscriptionCheckout.vue';
import PurchaseHistory from '../components/Account/PurchaseHistory.vue';
import Referrals from '../components/Account/Referrals.vue';
import SubscriptionReferredClaim from '../components/Subscriptions/SubscriptionReferredClaim.vue';
import PaymentCancel from '../components/Account/PaymentCancel.vue';
import CardAdd from '../components/Account/CardAdd.vue';
import PaymentUpdate from '../components/Account/PaymentUpdate.vue';
import Migrations from '../components/Account/Migrations/Migrations.vue';
import Bookings from '../components/Bookings/Bookings.vue';
import BookingDisplay from '../components/Bookings/BookingDisplay.vue'
import Communications from  '../components/Communication/Communications.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        name: 'login',
        redirect: '/interstitial',
        component: Login,
        children: [
            { path: 'interstitial', name: 'interstitial', component: Interstitial },
            { path: 'login', name: 'auth', component: Auth },
            { path: 'register', name: 'register', component: Register },
            { path: 'setup', name: 'setup', component: PostRegister, props: true },
            { path: 'forgot-password', name: 'forgot', component: ForgotPassword },
            { path: 'password-reset', name: 'passwordReset', component: PasswordReset },
            { path: 'password-set', name: 'passwordSet', component: PasswordSet }
        ]
    },
    { path: '/terms', name: 'terms', component: TermsAndConditions },
    { path: '/policy', name: 'policy', component: PrivacyPolicy },
    { path: '/restricted', name: 'restricted', component: RestrictedArea },
    { path: '/restricted/checkout/:paymentId', name: 'restrictedCheckout', component: RestrictedCheckout },
    {
        path: '',
        name: 'main',
        redirect: '/schedule',
        component: MainLayout,
        children: [
            { path: 'dashboard', name: 'dashboard', component: Dashboard },
            { path: 'schedule', name: 'schedule', component: Scheduler },
            { path: 'clients', name: 'clients', component: Clients },
            { path: 'breeds', name: 'breeds', component: Breeds },
            { path: 'products', name: 'products', component: Services },
            { path: 'sites', name: 'sites', component: Sites },
            { path: 'site-add', name: 'siteAdd', component: SiteAdd },
            { path: 'site-edit', name: 'siteEdit', component: SiteDisplay },
            { path: 'users', name: 'users', component: Users },
            { path: 'client-add', name: 'clientAdd', component: ClientAdd },
            { path: 'client/:id', name: 'clientEdit', component: ClientDisplay },
            { path: 'my-account', name: 'myAccount', component: MyAccount },
            { path: 'migration', name: 'migration', component: Migrations },
            { path: 'purchase-history', name: 'purchaseHistory', component: PurchaseHistory },
            { path: 'referrals', name: 'referrals', component: Referrals },
            { path: 'resources', name: 'resources', component: Resources },
            { path: 'contracts', name: 'contracts', component: Contracts },
            { path: 'contracts/add', name: 'contractAdd', component: ContractAdd },
            { path: 'contracts/update', name: 'contractUpdate', component: ContractUpdate, props: true },
            { path: 'sent-notifications', name: 'sentNotifications', component: Communications },
            { path: 'notifications/templates/email', name: 'emailMarketingTemplates', component: EmailMarketingTemplates },
            { path: 'notifications/templates/sms', name: 'smsMarketingTemplates', component: SmsMarketingTemplates },
            { path: 'notifications/templates/sms/upload', name: 'communicationTemplateSms', component: SmsTemplateUpload, props: true },
            { path: 'notifications/templates/email/upload', name: 'communicationTemplateEmail', component: EmailMarketingTemplateUpload, props: true },
            { path: 'marketing/campaigns', name: 'campaigns', component: Campaigns },
            { path: 'marketing/campaigns/add', name: 'campaignAdd', component: CampaignAdd },
            { path: 'marketing/campaigns/:id', name: 'campaignUpdate', component: CampaignUpdate },
            { path: 'marketing/campaigns/templates/email', name: 'campaignEmailTemplates', component: CampaignEmailTemplates },
            { path: 'marketing/campaigns/templates/email/add', name: 'campaignEmailTemplateAdd', component: CampaignEmailTemplateAdd },
            { path: 'marketing/campaigns/templates/email/:id', name: 'campaignEmailTemplateUpdate', component: CampaignEmailTemplateUpdate },
            { path: 'marketing/campaigns/templates/sms', name: 'campaignSmsTemplates', component: CampaignSmsTemplates },
            { path: 'marketing/campaigns/templates/sms/add', name: 'campaignSmsTemplateAdd', component: CampaignSmsTemplateAdd },
            { path: 'marketing/campaigns/templates/sms/:id', name: 'campaignSmsTemplateUpdate', component: CampaignSmsTemplateUpdate },
            { path: 'reports', name: 'reports', component: Reports },
            { path: 'payments/providers', name: 'paymentProviders', component: PaymentProviders },
            { path: 'payments/reports', name: 'paymentReports', component: PaymentReports },
            { path: 'payment', name: 'payment', component: OutstandingPayments },
            { path: 'payment/retry/:paymentId', name: 'paymentRetry', component: PaymentRetry },
            { path: 'payment/cancel/:paymentId', name: 'paymentCancel', component: PaymentCancel },
            { path: 'payment/update', name: 'paymentUpdate', component: PaymentUpdate },
            { path: 'subscription-V2', name: 'subscriptionV2', component: SubscriptionUpgradeV2 },
            { path: 'subscription/upgrade/complete', name: 'subscriptionUpgradeComplete', component: SubscriptionUpgradeComplete },
            { path: 'subscription/referred/claim', name: 'subscriptionReferredClaim', component: SubscriptionReferredClaim },
            { path: 'campaign/pack/complete', name: 'campaignPackComplete', component: CampaignPackComplete },
            { path: 'sms/pack/complete', name: 'smsPackComplete', component: SmsPackComplete },
            { path: 'email-template/pack/complete', name: 'emailTemplatePackComplete', component: EmailTemplatePackComplete },
            { path: 'store/checkout/:paymentId', name: 'storeCheckout', component: StoreCheckout },
            { path: 'upsell', name: 'upsell', component: SubscriptionUpsellDisplay},
            { path: 'store', name: 'store', component: Store },
            { path: 'payment/checkout/:paymentId', name: 'paymentCheckout', component: PaymentCheckout },
            { path: 'subscription/checkout/:paymentId', name: 'subscriptionCheckout', component: SubscriptionCheckout },
            { path: 'card/add', name: 'cardAdd', component: CardAdd },
            { path: 'bookings', name: 'bookings', component: Bookings },
            { path: 'booking/:id', name: 'bookingEdit', component: BookingDisplay },
        ]
    },
    {
        path: '*',
        redirect: ''
    }
]

export default new VueRouter({
    routes
})
