import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin";
import { TAndCsAcceptMixin } from "../Checkout/TAndCsAcceptMixin";
import { SubscriptionEntityPaymentsCacheMixin } from "../Subscriptions/SubscriptionEntityPaymentsCacheMixin";
import { mapActions } from 'vuex'
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const CreditPackPaymentAddMixin = {
    mixins: [SubscriptionEntityPaymentsCacheMixin, SiteIdMixin, TAndCsAcceptMixin],
    computed: {
        paymentCompletedRouteName() {
            console.log('OVERRIDE COMPUTED - paymentCompletedRouteName');
            return '';
        },
        completedPayment() {
            return this.subscriptionEntityPayments.find(p => p.status.id === 1);
        },
        outstandingPayment() {
            return this.subscriptionEntityPayments.find(p => p.status.id === 5);
        },
        outstandingPaymentId() {
            return !!this.outstandingPayment ? this.outstandingPayment.id : '';
        },
        canAddCreditPackPayment() {
            return !!this.subscriptionEntityId && !!this.paymentCompletedRouteName && !this.loadingSubscriptionEntityPayments && !this.outstandingPayment && !this.completedPayment;
        }
    },
    methods: {
        ...mapActions('SubscriptionEntityPayments', [
            'addCreditPackPayment'
        ]),
        tryAddCreditPackPayment() {
            const self = this;
            if (!self.canAddCreditPackPayment) {
                return;
            }
            self.error = '';
            self.addCreditPackPayment(self.subscriptionEntityId)
                .then(self.managePurchase)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        managePurchase() {
            if (!!this.completedPayment) {
                this.gotoComplete();
            } else if (!this.areTermsAccepted()) {
                return;
            } else if (!!this.outstandingPayment) {
                this.gotoCheckout();
            } else {
                this.tryAddCreditPackPayment();
            }
        },
        gotoCheckout() {
            if (!!this.outstandingPaymentId) {
                this.$router.push({
                    name: 'paymentCheckout',
                    params: { 'paymentId': this.outstandingPaymentId },
                    query: {
                        'onComplete': this.paymentCompletedRouteName,
                        'siteId': this.siteId
                    }
                });
            }
        },
        gotoComplete() {
            if (!!this.paymentCompletedRouteName) {
                this.$router.push({ name: this.paymentCompletedRouteName });
            }
        }
    }
}
