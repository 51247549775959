<template>

</template>

<script>
    import { AppointmentOrdersMixin } from "../../../../mixins/Orders/AppointmentOrdersMixin";
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'AppointmentRebookOrders',
        mixins: [AppointmentOrdersMixin, StatusEmitterMixin],
        props: {
            appointments: Array
        },
        computed: {
            appointmentIds() {
                return !!this.appointments ? this.appointments.map(a => a.id) : [];
            },
            orderCaches() {
                return this.appointmentIds.map(id => this.findOrderCache(id)).filter(oc => !!oc);
            },
            orders() {
                var result = [];
                for (var i = 0; i < this.orderCaches.length; i++) {
                    const cache = this.orderCaches[i];
                    result = result.concat(cache.values);
                }
                return result;
            },
            status() {
                return {
                    completed: this.missingAppointmentIds.length === 0 && this.orderCaches.every(oc => !oc.loading),
                    loading: this.orderCaches.some(oc => oc.loading),
                    error: this.error,
                    orders: this.orders
                }
            }
        }
    }
</script>
