<template>
    <div class="search-wrapper d-none d-md-flex" v-bind:class="{ 'show-search': searching }">
        <button class="btn-search">
            <i @click="searching = !searching" :class="searching ? 'far fa-times' : 'fas fa-search'"></i>
        </button>
        <div class="search-field-wrap">
            <search-field v-if="searching" @clicked="searching=false"></search-field>
        </div>
    </div>
</template>

<script>
    import ClientSearchField from "./ClientSearchField.vue"

    export default {
        name: 'ClientSearchClient',
        data() {
            return {
                searching: false
            }
        },
        components: {
            'search-field': ClientSearchField
        }
    }
</script>
