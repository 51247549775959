import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import { ContractHelper } from '../../helpers/ContractHelper.js';

export const ContractTemplateCacheMixin = {
    data() {
        return {
            error: '',
            version: 0
        }
    },
    computed: {
        ...mapGetters('ContractTemplates', [
            'findContractTemplateCache'
        ]),
        templateTypeId() {
            console.log('!!!OVERRIDE COMPUTED - templateTypeId!!!');
        },
        contractTemplateFilterModel() {
            return {
                templateTypeId: this.templateTypeId,
                version: this.version
            }
        },
        contractTemplateFilterModelValid() {
            return !!this.contractTemplateFilterModel.templateTypeId && !!this.contractTemplateFilterModel.version;
        },
        contractTemplateCacheKey() {
            return ContractHelper.contractTemplateCacheKey(this.contractTemplateFilterModel);
        },
        contractTemplateCache() {
            return this.findContractTemplateCache(this.contractTemplateCacheKey);
        },
        contractTemplate() {
            return !!this.contractTemplateCache ? this.contractTemplateCache.value : null;
        },
        loadingContractTemplate() {
            return !!this.contractTemplateCache && this.contractTemplateCache.loading;
        },
        templateTypeName() {
            return !!this.contractTemplate ? this.contractTemplate.templateTypeName : '';
        }
    },
    watch: {
        contractTemplateFilterModel() {
            this.refreshContractTemplate();
        }
    },
    methods: {
        ...mapActions('ContractTemplates', [
            'getContractTemplate'
        ]),
        refreshContractTemplate() {
            if (!this.contractTemplateCache || !!this.contractTemplateCache.retry) {
                this.tryGetContractTemplate();
            }
        },
        tryGetContractTemplate() {
            const self = this;
            if (!self.contractTemplateFilterModelValid) {
                return
            }
            self.error = '';
            self.getContractTemplate({ key: self.contractTemplateCacheKey, payload: self.contractTemplateFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshContractTemplate();
    }
}
