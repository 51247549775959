<template>
    <div v-if="pet" class="pet-cards">
        <div class="row">
            <div class="col-md-12">
                <fieldset :disabled="disabled">
                    <div class="pet-card">
                        <pet-photo :pet="pet"></pet-photo>
                        <div class="appointment-overview-pet">
                            <div class="appointment-overview-pet-name">
                                <pet-name v-if="pet" :pet="pet"></pet-name>
                            </div>
                            <div class="appointment-overview-pet-tab">
                                <a class="btn btn-sm btn-outline" @click="setPetOverviewTab(0)" v-bind:class="{'active': petOverviewTab === 0}">
                                    <i class="fal fa-info"></i>
                                </a>
                                <a class="btn btn-sm btn-outline" @click="setPetOverviewTab(1)" v-bind:class="{'active': petOverviewTab === 1}">
                                    <i class="fal fa-paw"></i>
                                </a>
                                <a class="btn btn-sm btn-outline" @click="setPetOverviewTab(2)" v-bind:class="{'active': petOverviewTab === 2}">
                                    <i class="fal fa-scissors"></i>
                                </a>
                                <a class="btn btn-sm btn-outline" @click="setPetOverviewTab(3)" v-bind:class="{'active': petOverviewTab === 3}">
                                    <i class="fal fa-stethoscope"></i>
                                </a>
                            </div>
                            <div class="appointment-overview-pet-details">
                                <template v-if="petOverviewTab === 0">
                                    <pet-activity v-model="resourceId" :appointment="appointment" :order-items="orderItems"></pet-activity>
                                    <pet-warning v-if="pet" :pet="pet"></pet-warning>
                                </template>
                                <template v-else>
                                    <pet-note :id="petId" :type-id="petNoteTypeId"></pet-note>
                                </template>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
    import AppointmentOverviewPetPhoto from './AppointmentOverviewPetPhoto.vue';
    import AppointmentOverviewPetName from './AppointmentOverviewPetName.vue';
    import AppointmentOverviewPetActivity from './AppointmentOverviewPetActivity.vue';
    import AppointmentOverviewPetWarning from './AppointmentOverviewPetWarning.vue';
    import AppointmentOverviewPetNote from './AppointmentOverviewPetNote.vue';
    import { PetCacheMixinV2 } from '../../Shared/PetCacheMixinV2';

    export default {
        name: 'AppointmentOverviewPet',
        mixins: [PetCacheMixinV2],
        props: {
            value: String,
            appointment: Object,
            orderItems: Array,
            disabled: Boolean
        },
        data() {
            return {
                petOverviewTab: 0,
                petNoteTypeId: Number
            }
        },
        computed: {
            resourceId: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            petId() {
                return !!this.appointment ? this.appointment.petId : '';
            },
            pet() {
                return this.pets.find(p => p.id === this.petId);
            }
        },
        methods: {
            setPetOverviewTab(value) {
                this.petOverviewTab = value;
                this.petNoteTypeId = value;
            }
        },
        components: {
            'pet-photo': AppointmentOverviewPetPhoto,
            'pet-name': AppointmentOverviewPetName,
            'pet-activity': AppointmentOverviewPetActivity,
            'pet-warning': AppointmentOverviewPetWarning,
            'pet-note': AppointmentOverviewPetNote
        }
    }
</script>
