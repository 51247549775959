<template>

</template>

<script>
    import { HistoriesCacheMixin } from "../../components/Shared/HistoriesCacheMixin"
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'ClientHistoriesSearch',
        mixins: [HistoriesCacheMixin, StatusEmitterMixin],
        props: {
            clienIds: Array
        },
        computed: {
            status() {
                return {
                    loading: this.fetchingHistories
                }
            }
        }
    }
</script>
