import { Api } from "../../services/Api";

const state = () => ({
    postcodeSearches: []
})

const getters = {
    findPostcodeSearch: (state) => key => state.postcodeSearches.find(ps => ps.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.postcodeSearches.find(ps => ps.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.postcodeSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setResults(state, { key, results }) {
        const existing = state.postcodeSearches.find(ps => ps.key === key);
        if (!!existing) {
            existing.values = results;
            existing.loading = false;
        } else {
            state.postcodeSearches.push({ key: key, values: results, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.postcodeSearches.find(ps => ps.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchPostcode({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('AddressLookup', payload)
                .then(response => {
                    commit('setResults', { key: key, results: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
