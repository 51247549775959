<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-5" data-toggle="collapse">
            <i class="fas fa-file-csv mr-2"></i>
            CSV Import
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-5">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Import successful</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span>x</span>
                    </button>
                </div>
                <div class="form-group">
                    <label>Account ID</label>
                    <input v-model="accountId" class="form-control" type="text">
                </div>
                <div class="form-group">
                    <label>Site ID</label>
                    <input v-model="siteId" class="form-control" type="text">
                </div>
                <div class="form-group">
                    <label>Notification Settings</label>
                    <settings-edit v-model="notificationSettings"></settings-edit>
                </div>
                <div class="form-group">
                    <div v-if="csvFile">
                        <label>{{fileName}}</label>
                        <button @click="removeFile">Remove</button>
                    </div>
                    <div v-else class="file-upload mw-400 m-0">
                        <label>
                            <span>Select CSV</span>
                            <input @change="onFilePicked" type="file" accept=".csv" />
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <button @click="submit" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Import
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import NotificationSettingsEdit from "./NotificationSettingsEdit.vue";

    export default {
        name: 'SystemAdminImport',
        data() {
            return {
                accountId: '',
                siteId: '',
                csvFile: null,
                notificationSettings: {},
                loading: false,
                completed: false,
                error: ''
            }
        },
        computed: {
            importUploadModel() {
                return Object.assign({}, {
                    accountId: this.accountId,
                    siteId: this.siteId,
                    csv: this.csvFile
                }, this.notificationSettings);
            },
            importUploadModelValid() {
                const model = this.importUploadModel;
                return !!model.accountId && !!model.siteId && !!model.csv;
            },
            fileName() {
                return !!this.csvFile ? this.csvFile.name : '';
            },
            importUploadForm() {
                var form = new FormData();
                const model = this.importUploadModel;
                for (const property in model) {
                    if (model.hasOwnProperty(property)) {
                        form.append(property, model[property]);
                    }
                }
                return form;
            }
        },
        methods: {
            onFilePicked(event) {
                for (var i = 0; i < event.target.files.length; i++) {
                    this.csvFile = event.target.files[i];
                }
            },
            removeFile() {
                this.csvFile = null;
            },
            submit() {
                const self = this;
                if (!self.importUploadModelValid) {
                    return;
                }
                self.error = '';
                self.completed = false;
                self.loading = true;
                const config = {
                    header: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                Api.post('Import', self.importUploadForm, config)
                    .then(() => {
                        self.completed = true;
                        self.clearUpload();
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            clearUpload() {
                this.accountId = '';
                this.siteId = '';
                this.csvFile = null;
            }
        },
        components: {
            'settings-edit': NotificationSettingsEdit
        }
    }
</script>
