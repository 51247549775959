<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{title}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <div class="d-flex flex-column flex-md-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{$t('Subject')}}<span class="required-field"></span></label>
                        <input v-model="subject" class="form-control" :placeholder="$t('Subject')" />
                        <validation-message v-if="smsCampaignTemplateValidator.subject" :message="smsCampaignTemplateValidator.subject"></validation-message>
                    </div>
                    <div class="form-group">
                        <label>{{$t('Template')}}<span class="required-field"></span></label>
                        <div class="form-group">
                            <textarea v-model="body" class="form-control w-100" rows="10"></textarea>
                            <div class="mw-400 d-flex justify-content-between">
                                <validation-message class="w-100" v-if="smsCampaignTemplateValidator.body" :message="smsCampaignTemplateValidator.body"></validation-message>
                                <char-count-display class="w-100" :message="body"></char-count-display>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <cost-calculator :message="body"></cost-calculator>
                </div>
            </div>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <template-tags :method-type-id="2"></template-tags>
                <div>
                    <button @click="save" v-bind:disabled="loadingSmsCampaignTemplates" class="btn btn-primary" type="button">
                        {{$t('Save')}}
                        <span v-if="loadingSmsCampaignTemplates" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SmsCampaignTemplateUpdateMixin } from "../../../mixins/Campaigns/SmsCampaignTemplateUpdateMixin";
    import SiteSelect from "../../Account/SiteSelect.vue";
    import router from '../../../routing';
    import CampaignTemplateTags from "./CampaignTemplateTags.vue";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import SmsCreditCostCalculator from "../SmsCreditCostCalculator.vue";
    import SmsCharacterCountDisplay from '../SmsCharacterCountDisplay.vue';

    export default {
        name: 'CampaignSmsTemplateUpdate',
        mixins: [SmsCampaignTemplateUpdateMixin],
        computed: {
            smsCampaignTemplateId() {
                return this.$route.params.id;
            },
            title() {
                return ` ${this.$t('Edit')} ` + (!!this.smsCampaignTemplate ? this.smsCampaignTemplate.subject : 'SMS') + ` ${this.$t('Campaign Template')}`;
            }
        },
        methods: {
            save() {
                this.tryUpdateSmsCampaignTemplate();
            },
            cancel() {
                router.go(-1);
            }
        },
        components: {
            'site-select': SiteSelect,
            'template-tags': CampaignTemplateTags,
            'validation-message': ValidationMessage,
            'char-count-display': SmsCharacterCountDisplay,
            'cost-calculator': SmsCreditCostCalculator,
            'error-banner': ErrorBanner
        }
    }
</script>
