import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapActions } from 'vuex';
import { CommunicationCacheMixin } from './CommunicationCacheMixin.js';

export const CommunicationSendMixin = {
    mixins: [CommunicationCacheMixin],
    computed: {
        subjectId() {
            console.log('OVERRIDE COMPUTED - subjectId');
            return '';
        },
        methodTypeId() {
            console.log('OVERRIDE COMPUTED - methodTypeId');
            return 0;
        },
        communicationSendModel() {
            return {
                clientId: this.clientId,
                communicationTypeId: this.communicationTypeId,
                subjectId: this.subjectId,
                methodTypeId: this.methodTypeId
            }
        },
        communicationSendModelValid() {
            const model = this.communicationSendModel;
            return !!model.clientId && !!model.communicationTypeId && !!model.subjectId && !!model.methodTypeId;
        }
    },
    methods: {
        ...mapActions('Communications', [
            'sendCommunication'
        ]),
        trySendCommunication() {
            const self = this;
            if (!this.communicationSendModelValid) {
                return;
            }
            this.error = '';
            self.sendCommunication({ key: self.communicationCacheKey, payload: self.communicationSendModel })
                .then(response => self.afterSendingCommunication(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterSendingCommunication() {
            // override if needed
        }
    }
}
