<template>
    <tr @click="gotoTemplateEdit" class="link pointer" v-bind:class="classType">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a>{{subject}}</a>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CampaignEmailTemplateItemMixin } from "./CampaignEmailTemplateItemMixin";

    export default {
        name: 'CampaignEmailTemplateItemDesktop',
        mixins: [CampaignEmailTemplateItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
