<template>
    <div class="client-details-left sidenav">
        <section class="client-contact-section mb-3">
            <div class="row">
                <div class="form-group col-6 col-md-12 px-md-3">
                    <label for="title">{{$t('Type')}}:</label>
                    {{clientTypeDescription}}
                </div>
                <div class="form-group col-6 col-md-12 px-0 px-md-3">
                    <label for="title">{{$t('Duration')}}:</label> {{appointmentDuration}} {{$t('minutes')}}
                </div>
                <div class="form-group col-6 col-md-12 px-md-3">
                    <label for="title">{{$t('Created Date Time')}}:</label> {{createdDate}} {{createdTime}}
                </div>
                <div class="form-group col-6 col-md-12 px-0 px-md-3">
                    <label for="title">{{$t('Appointment Date Time')}}:</label> {{appointmentDate}} {{appointmentTime}}
                </div>
                <div class="form-group col-6 col-md-12 px-md-3">
                    <label for="title">{{$t('No. of Pet(s)')}}:</label>
                    {{participantsCount}}
                </div>
                <div class="form-group col-6 col-md-12 px-0 px-md-3">
                    <label for="title">{{$t('Total')}}:</label>
                    {{totalString}}
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { AppointmentScheduleAddMixin } from '../Shared/AppointmentScheduleAddMixin';
    import { BookingCacheMixin } from '../Shared/BookingCacheMixin';
    import { CountryCacheMixin } from '../Shared/Country/CountryCacheMixin';
    import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';
    import { TimeDisplayMixin } from '../Shared/Time/TimeDisplayMixin';

    export default {
        name: 'BookingAddAppointmentSchedule',
        mixins: [AppointmentScheduleAddMixin, BookingCacheMixin, CountryCacheMixin, DateDisplayMixin, TimeDisplayMixin, StatusEmitterMixin],
        props: {
            id: '',
            participants: []
        },
        data() {
            return {
                addedScheduleId: '',
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'approveBookingEvent',
                'appointmentScheduleAddedEvent',
                'clientAddedEvent'
            ]),
            ...mapState('AppointmentSchedules', [
                'addingSchedule'
            ]),
            bookingId() {
                return !!this.id ? this.id : '';
            },
            createdDate() {
                return !!this.booking ? moment(this.booking.createdDate).format(this.momentJSFormat) : "-";
            },
            createdTime() {
                return !!this.booking ? moment(this.booking.createdDate).format(this.timeMomentJSFormat) : "-";
            },
            appointmentDate() {
                return !!this.booking ? moment(this.booking.startUtc).format(this.momentJSFormat) : "-";
            },
            appointmentTime() {
                return !!this.booking ? moment(this.booking.startUtc).format(this.timeMomentJSFormat) : "-";
            },
            appointmentDuration() {
                return !!this.booking ? this.booking.duration : '-';
            },
            participantsCount() {
                return this.bookingParticipants.length > 0 ? this.bookingParticipants.length : 0;
            },
            clientType() {
                return !!this.booking ? this.booking.clientType : null;
            },
            clientTypeDescription() {
                const description = !!this.clientType ? this.clientType.description : 'Unknown';
                return `${description} Client`;
            },
            totalString() {
                let total = 0;
                this.bookingParticipants.forEach(participant => {
                    total += participant.serviceItems.length > 0 ? participant.serviceItems.reduce((sum, item) => {
                        return sum + item.amount;
                    }, 0) : 0;
                });
                return `${this.currencySymbol} ${total.toFixed(2).toString()}`;
            },
            appointmentScheduleAddModel() {
                return {
                    clientId: this.addedClientId,
                    appointmentFrequencyId: 0,
                    duration: !!this.booking ? this.booking.duration : 120,
                    frequencyMultiple: 1,
                    occurrences: 1,
                    overrides: [],
                    participants: this.participants,
                    startUtc: !!this.booking ? this.booking.startUtc : '',
                    timeZoneId: !!this.booking ? this.booking.timeZoneId : ''
                }
            },
            appointmentScheduleAddModelValid() {
                return true;
            },
            completed() {
                return !!this.addedScheduleId;
            },
            loading() {
                return !!this.addingSchedule;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canAddSchedule() {
                return !this.addedScheduleId && !!this.addedClientId;
            }
        },
        watch: {
            appointmentScheduleAddedEvent(schedule) {
                this.addedScheduleId = schedule.id
            },
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            },
            addedClientId() {
                this.onValidatedChanged();
            },
            approveBookingEvent() {
                this.onValidatedChanged();
            },
            participants(value) {
                if (value.length > 0) {
                    this.onValidatedChanged();
                }
            }
        },
        methods: {
            onValidatedChanged() {
                if (!this.canAddSchedule || !this.validateModel(this.appointmentScheduleAddModel) || !!this.loading) {
                    return;
                }
                this.tryAddAppointmentSchedule();
            },
            validateModel(value) {
                const bookingParticipants = value.participants;

                if (bookingParticipants.length < 1) {
                    return false;
                }
                // Check if participant has petId and resourceId
                for (let i = 0; i < bookingParticipants.length; i++) {
                    const participant = bookingParticipants[i];
                    
                    if (!participant.petId || !participant.resourceId) {
                        return false;
                    }
                }
                // Check for timeZoneId, startUtc, and duration
                return !!value.timeZoneId && !!value.startUtc && !!value.duration;
            },
            refreshConflictsSearch() {
                // do nothing!
            }
        }
    }
</script>
