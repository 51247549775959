<template>
    <tr class="link" v-bind:class="classType">
        <td class="text-left text-truncate">{{subscriptionPayment.description}}</td>
        <td class="text-center text-truncate">{{date}}</td>
        <td class="text-center text-truncate">{{siteName}}</td>
        <td class="text-right">
            <span v-if="subscriptionPayment.currency === 'GBP'">&pound;</span>
            <span v-else>&dollar;</span>{{amount}}
        </td>
        <td class="text-right">
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="downloadReceipt" v-bind:disabled="loading">
                <span v-if="loading" class="inline-loader"></span>
                <i v-else class="fal fa-download"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import { PurchaseHistoryItemMixin } from './PurchaseHistoryItemMixin';

    export default {
        name: 'PurchaseHistoryItemDesktop',
        mixins: [PurchaseHistoryItemMixin],
        props: {
            number: Number,
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
