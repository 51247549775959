import { AccountSettingCacheMixin } from "./AccountSettingCacheMixin";
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const AccountSettingAddMixin = {
    mixins: [AccountSettingCacheMixin],
    computed: {
        ...mapState('AccountSettings', [
            'addingAccountSetting'
        ]),
        accountSettingEditModel() {
            console.log('OVERRIDE COMPUTED - accountSettingEditModel');
            return {};
        },
        accountSettingEditModelValid() {
            console.log('OVERRIDE COMPUTED - accountSettingEditModelValid');
            return false;
        },
        accountSettingUploadModel() {
            return {
                key: this.accountSettingKey,
                value: JSON.stringify(this.accountSettingEditModel)
            };
        },
        accountSettingUploadModelValid() {
            return !!this.accountSettingUploadModel.key && !!this.accountSettingEditModelValid;
        },
        loadingAccountSetting() {
            return this.fetchingAccountSettings || this.addingAccountSetting;
        }
    },
    methods: {
        ...mapActions('AccountSettings', [
            'addAccountSetting'
        ]),
        tryAddAccountSetting() {
            const self = this;
            if (!self.accountSettingUploadModelValid) {
                return;
            }
            self.error = '';
            self.addAccountSetting(self.accountSettingUploadModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
