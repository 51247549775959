import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const StripoCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('StripoTemplates', [
            'findStripoTemplateCache'
        ]),
        stripoTemplateId() {
            console.log('!!!OVERRIDE COMPUTED - stripoTemplateId!!!');
            return '';
        },
        stripoTemplateCache() {
            return this.findStripoTemplateCache(this.stripoTemplateId);
        },
        stripoTemplate() {
            return !!this.stripoTemplateCache ? this.stripoTemplateCache.value : null;
        },
        loadingStripoTemplate() {
            return !!this.stripoTemplateCache && this.stripoTemplateCache.loading;
        }
    },
    watch: {
        stripoTemplateId() {
            this.refreshStripoTemplateCache();
        }
    },
    methods: {
        ...mapActions('StripoTemplates', [
            'getStripoTemplate'
        ]),
        refreshStripoTemplateCache() {
            if (!this.stripoTemplateCache || this.stripoTemplateCache.retry) {
                this.tryGetStripoTemplate();
            }
        },
        tryGetStripoTemplate() {
            const self = this;
            if (!self.stripoTemplateId) {
                return;
            }
            self.error = '';
            self.getStripoTemplate(self.stripoTemplateId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshStripoTemplateCache();
    }
}
