<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-8" data-toggle="collapse">
            <i class="fas fa-lock mr-2"></i>
            Account Access
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-8">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Account {{ id }} is successfully <b>{{isDisabled ? 'disabled' : 'enabled'}}</b>!</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span>x</span>
                    </button>
                </div>
                <div class="form-group">
                    <label>Account ID</label>
                    <input v-model="id" class="form-control" type="text" v-bind:disabled="loading">
                    <validation-message v-if="!!message" :message="message"></validation-message>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-auto px-0 mr-2">Disable?</label>
                    </div>
                    <div class="col-md-8">
                        <label class="col ps-toggle-switch">
                            <input v-model="isDisabled" class="toggle-checkbox" type="checkbox" v-bind:disabled="loading">
                            <div class="toggle-switch"></div>
                            <span class="d-inline-block ml-2">{{isDisabled ? 'Yes' : 'No'}}</span>
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <button @click="save" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Save
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
        <confirmation-modal ref="confirmationModal" :update-model="updateModel" @proceed="proceed"></confirmation-modal>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";
    import SystemAdminAccountAccessConfirmation from "./SystemAdminAccountAccessConfirmation.vue";

    export default {
        name: 'SystemAdminAccountAccess',
        data() {
            return {
                id: '',
                isDisabled: false,
                loading: false,
                completed: false,
                error: '',
                message: ''
            }
        },
        computed: {
            updateModel() {
                return {
                    id: this.id,
                    isDisabled: this.isDisabled
                }
            },
            updateModelValid() {
                const model = this.updateModel;
                return !!model.id;
            }
        },
        watch: {
            updateModel() {
                this.completed = false;
            }
        },
        methods: {
            save() {
                const self = this;
                self.validate();
                if (self.updateModelValid) {
                    this.$refs.confirmationModal.$refs.displayButton.click();
                }
            },
            proceed() {
                const self = this;
                self.completed = false;
                self.error = '';
                self.validate();
                if (!self.updateModelValid) {
                    return;
                }
                self.loading = true;
                Api.put('Account', self.updateModel)
                    .then(() => {
                        self.completed = true;
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            validate() {
                const self = this;
                const model = self.updateModel
                self.message = !!model.id ? '' : 'Please enter Account Id.';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'confirmation-modal': SystemAdminAccountAccessConfirmation
        }
    }
</script>
