import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { PaymentApiUserCacheMixin } from './PaymentApiUserCacheMixin';

export const PaymentStatusesCacheMixin = {
    mixins: [PaymentApiUserCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PaymentStatuses', [
            'fetchingPaymentStatuses',
            'fetchedPaymentStatuses',
            'paymentStatuses'
        ])
    },
    methods: {
        ...mapActions('PaymentStatuses', [
            'fetchPaymentStatuses'
        ]),
        refreshPaymentStatuses() {
            if (!this.fetchedPaymentStatuses && !this.fetchingPaymentStatuses) {
                this.tryFetchPaymentStatuses();
            }
        },
        tryFetchPaymentStatuses() {
            const self = this;
            if (!self.isPaymentAdmin) {
                return;
            }
            self.error = '';
            self.fetchPaymentStatuses()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentStatuses();
    }
}
