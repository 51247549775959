<template>
    <delete-prep :resources="userResources" :external-loading="loading" @cancel="cancelDelete" @confirm="proceedDelete"></delete-prep>
</template>

<script>
    import ResourceDeleteFlow from "../Resources/ResourceDeleteFlow.vue";
    import { UserResourceDeleteMixin } from "../Shared/UserResourceDeleteMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'UserResourceDeleteFlow',
        mixins: [UserResourceDeleteMixin],
        data() {
            return {
                statuses: []
            }
        },
        props: {
            id: String
        },
        computed: {
            ...mapState('Employees', [
                'updatingEmployee'
            ]),
            userId() {
                return this.id;
            },
            hasUserResources() {
                return !this.loadingUserResources && this.userResources.length > 0;
            },
            loading() {
                return this.loadingUserResources || this.updatingEmployee;
            }
        },
        methods: {
            cancelDelete() {
                this.$emit('cancel');
            },
            proceedDelete() {
                if (this.hasUserResources) {
                    this.tryDeleteUserResources();
                } else {
                    this.$emit('confirm');
                }
            },
            afterDeleteUserResources() {
                this.$emit('confirm');
            }
        },
        components: {
            'delete-prep': ResourceDeleteFlow
        }
    }
</script>
