<template>
    <div class="content fixed-action-btns-visible">
        <div class="alert-wrapper px-0">
            <div class="alert alert-info alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{$t('These settings will autopopulate fields when creating a new site.')}}</p>
                </div>
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <default-details v-model="detailsStatus"></default-details>
                <default-tax v-model="taxStatus"></default-tax>
                <default-resource v-model="resourceStatus"></default-resource>
            </div>
            <div class="col-md-4">
                <default-contact v-model="contactStatus"></default-contact>
            </div>
            <div class="col-md-4">
                <default-address v-model="addressStatus"></default-address>
                <default-country v-model="countryStatus"></default-country>
                <default-time-zone v-model="timeZoneStatus"></default-time-zone>
            </div>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <button @click="triggerSaveSiteDefaults" class="btn btn-primary" type="button" v-bind:disabled="loading">
                    {{$t('Save')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultSiteAddress from './DefaultSiteAddress.vue';
    import DefaultSiteContact from './DefaultSiteContact.vue';
    import DefaultSiteCountry from './DefaultSiteCountry.vue';
    import DefaultSiteDetails from './DefaultSiteDetails.vue';
    import DefaultSiteTaxDetails from './DefaultSiteTaxDetails.vue';
    import DefaultSiteTimeZone from './DefaultSiteTimeZone.vue';
    import DefaultSiteResource from './DefaultSiteResource.vue';
    import { mapMutations } from 'vuex';

    export default {
        name: 'AppSettings',
        data() {
            return {
                detailsStatus: {},
                taxStatus: {},
                contactStatus: {},
                resourceStatus: {},
                addressStatus: {},
                countryStatus: {},
                timeZoneStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.detailsStatus,
                    this.taxStatus,
                    this.contactStatus,
                    this.resourceStatus,
                    this.addressStatus,
                    this.countryStatus,
                    this.timeZoneStatus
                ];
            },
            loading() {
                return this.statuses.some(s => !!s.loading);
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveSiteDefaults'
            ])
        },
        components: {
            'default-contact': DefaultSiteContact,
            'default-address': DefaultSiteAddress,
            'default-country': DefaultSiteCountry,
            'default-details': DefaultSiteDetails,
            'default-tax': DefaultSiteTaxDetails,
            'default-time-zone': DefaultSiteTimeZone,
            'default-resource': DefaultSiteResource
        }
    }
</script>
