<template>
    <div class="col-md-2 col-12 p-md-2 p-1">
        <div class="card card-body p-0">
            <div class="d-flex justify-content-center pt-2" style="font-weight: 600" v-bind:style="{ 'background-color': bundle.colour}">
                <div class="card-title">{{ bundle.name }}</div>
            </div>
            <img class="card-img-top" :src="bundle.thumbnail" :alt="bundle.name" style="object-fit: cover; width: 100%; height: 10rem;">
            <div class="d-flex justify-content-center flex-column align-items-center px-1 py-2">
                <ul class="list-unstyled d-flex flex-column align-items-center justify-content-center">
                    <li v-for="template in bundle.templates"
                        :key="template.id" 
                        v-bind:class="{'text-decoration-line-through':isTemplatePurchased(template)}"
                        style="font-size: 0.7rem">
                    {{template.name}}
                    </li>
                </ul>
                <div class="d-flex">
                    <button v-if="allStripoDefaultTemplatesPurchased && !purchasingCurrentBundle" class="btn-add btn btn-secondary" disabled>
                        <i class="fas fa-exclamation-circle"></i>{{$t('Purchased')}}
                    </button>
                    <button v-else class="btn-add btn btn-secondary" v-bind:disabled="loadingPurchasedCampaignTemplates || purchasingCurrentBundle" @click="purchaseBundle">
                        <i class="fas fa-cart-shopping"></i>{{campaignTemplateBundleCost}} {{$t('Credits')}}
                        <span v-if="purchasingCurrentBundle" class="inline-loader"></span>
                    </button>
                    <button @click="preview" type="button" class="btn-add btn btn-primary ml-1 px-2">
                        <i class="far fa-eye m-0"></i>
                    </button>
                </div>
            </div>
        </div>
        <image-carousel ref="bundlePreview" :images="bundleThumbnails" :title="bundle.name"></image-carousel>
    </div>
</template>

<script>
    import { StripoDefaultTemplateBundleSettingsDisplayItemMixin } from "./StripoDefaultTemplateBundleSettingsDisplayItemMixin";
    import ImageCarousel from "../../Layout/ImageCarousel.vue";
    import { SubscriptionFeatureMixin } from "../../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import { mapMutations } from 'vuex';

    export default {
        name: 'StripoDefaultTemplateBundleSettingsDisplayItem',
        mixins: [StripoDefaultTemplateBundleSettingsDisplayItemMixin, SubscriptionFeatureMixin],
        computed: {
            computedFeatureKey() {
                return 'buyEmailTemplates';
            }
        },
        watch: {
            error(value) {
                this.$emit('input', value)
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerStoreTabChange'
            ]),
            purchaseBundle() {
                if (this.subscriptionFeatureAllowed) {
                    this.tryPurchasedCampaignTemplateBundle();
                } else {
                    this.triggerStoreTabChange(3);
                }
            },
            isTemplatePurchased(template) {
                return this.purchasedCampaignTemplates.find(t => t.stripoDefaultTemplateId == template.id)
            },
            preview() {
                const element = this.$refs.bundlePreview.$el;
                $(element).modal('show');
            },
        },
        components: {
            'image-carousel': ImageCarousel
        }
    }
</script>
