<template>
    <div class="form-group">
        <div class="search-field">
            <input v-model="postcode" class="search-field-input form-control" :placeholder="$t('Enter postcode')" type="search">
            <button class="search-field-btn" type="submit">
                <span v-if="searchingPostcode" class="inline-loader"></span>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </button>
            <div v-if="showPostcodeResults" class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                <ul class="dropdown-menu inner show" role="presentation">
                    <search-result v-for="(address, index) in addresses" :key="index" :address="address" @addressClicked="onAddressClicked"></search-result>
                    <li v-if="addresses.length === 0">
                        <a role="option" class="dropdown-item">
                            {{$t('No results found')}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { PostcodeSearchMixin } from "./PostcodeSearchMixin";
    import PostcodeSearchResult from "./PostcodeSearchResult.vue";

    export default {
        name: 'PostcodeLookup',
        mixins: [PostcodeSearchMixin],
        methods: {
            onAddressClicked(address) {
                this.$emit('input', address);
            }
        },
        components: {
            'search-result': PostcodeSearchResult
        }
    }
</script>
