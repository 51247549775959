import { mapState } from "vuex";
import { NoteUploadMixin } from "../../Shared/Notes/NoteUploadMixin";

export const PetAddNoteMixin = {
    mixins: [NoteUploadMixin],
    data() {
        return {
            addedPetId: ''
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'petAddedEvent'
        ]),
        ownerId() {
            return this.addedPetId;
        },
        ownerTypeId() {
            return 3;
        },
        noteTypeId() {
            return 1;
        }
    },
    watch: {
        petAddedEvent(pet) {
            this.addedPetId = pet.id;
            this.tryAddNote();
            this.addedPetId = '';

        }
    }
}
