import { Api } from '../../services/Api';

const state = () => ({
    signatureCaches: []
})

const getters = {
    findSignatureCache: state => key => {
        return state.signatureCaches.find(sc => sc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.signatureCaches.find(sc => sc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.signatureCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setSignatures(state, { key, signatures }) {
        const existing = state.signatureCaches.find(sc => sc.key === key);
        if (!!existing) {
            existing.values = signatures;
            existing.loading = false;
        } else {
            state.signatureCaches.push({ key: key, values: signatures, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.signatureCaches.find(sc => sc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchSignatures({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: clientId, loading: true });
            Api.get('Signature?clientId=' + clientId)
                .then(response => {
                    commit('setSignatures', { key: clientId, signatures: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', clientId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
