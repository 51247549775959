<template>
    <div class="login-wrapper">
        <div class="row flex-grow-1">
            <div class="col-md-12 d-md-flex align-items-center">
                <div class="login-form-wrap terms-wrap">
                    <img class="logo" alt="Shake Your Tail" src="../../../assets/images/logo-colour-stacked.svg" width="168">
                    <div class="terms-content d-flex flex-column align-items-center text-center">
                        <h1>Privacy Policy</h1>
                        <p>Please Read These Terms Carefully Before Using this Service</p>
                        <h6 class="m-0 mt-4">Petstar is committed to maintaining the confidentiality, integrity and security of information about our users and their organizations. Petstar Privacy Policy describes the information we collect, how the information is used and for what purposes, to whom we disclose the information, and how we safeguard your information.</h6>
                        <h6 class="m-0 mt-4">Collection of Your Personal and Business Information</h6>
                        <p>When you signup to ShakeYourTail.com we need to collect information about you and your business, this includes data such as Name, Business Name, Email and address.</p>
                        <p>We also collect data relating to your computer, IP address and web browser information.</p>
                        <p>We will also collect and store any information relating to your business, such as tax rate, prices, website, contact numbers and email addresses.</p>
                        <h6 class="m-0 mt-4">How Your Personal Information Is Used</h6>
                        <p>Petstar collects your information in order to provide services to you, comply with our legal obligations, and to improve our products and services. We do not sell, rent or share your personally identifiable information to or with third parties in any way other than as disclosed in this Privacy Policy. Petstar may use this information to:</p>
                        <ul class="d-flex flex-column align-items-center">
                            <li>Process Orders</li>
                            <li>Send You Subscription Renewal Reminders and Confirmations</li>
                            <li>Administer Your Account</li>
                            <li>Customise Your Experience and Account</li>
                            <li>Used for Internal Statistical, Metric and Reporting Purposes</li>
                            <li>Send You service updates and information</li>
                            <li>Secure your Account</li>
                        </ul>
                        <h6 class="m-0 mt-4">Information Shared with Third Parties</h6>
                        <p>Petstar will provide a number of selected partners minimal information you collect and store within our service in order for petstar to deliver its services on your behalf.</p>
                        <p>To the best of our knowledge, these companies use the information provided by us to solely to carry out the activity that they have been selected for and do not share this information for any other purpose.</p>
                        <p>We do share your information with certain companies in order to take payment (Stripe/Sumup), store data (Microsoft and OVH) and send you information and security emails (SendGrid).</p>
                        <p>We do not sell or otherwise provide Personal Information to other companies for the marketing of their own products or services.</p>
                        <h6 class="m-0 mt-4">Protection of Your Personal Information</h6>
                        <p>The Personal Information that you provide in connection with the use of the service is protected in several ways.</p>
                        <ul class="d-flex flex-column align-items-center">
                            <li>Your account profile is available through a password and unique customer ID selected by you. This password is encrypted. You should strive to use a strong, alpha-numeric and case sensitive password which you should not divulge to anyone.</li>
                            <li>Your Personal Information resides on servers that only selected Petstar personnel and contractors have access to via passwords and/or MFA.</li>
                            <li>Your Information is encrypted whenever it is transmitted to and from shakeyourtail.com</li>
                        </ul>
                        <h6>We strive to protect the Personal Information submitted to us, both during transmission and once we receive it. However, no method of transmission over the Internet, or method of electronic storage is 100% secure. While we take into account the sensitivity of the Personal Information we collect, process and store, and the current state of technology to use these measures protect your Personal Information, we cannot guarantee its absolute security.</h6>
                        <h6 class="m-0 mt-4">Data Retention</h6>
                        <p>We will retain your information for as long as your account is active, should you cancel your paid subscription or not access your account within 6 months Petstar reserve the right to delete the account, If you wish to delete your account or request that we no longer use your information to provide you services contact us at <a href="mailto:support-team@petstar.co.uk">support-team@petstar.co.uk</a> We will respond to your request within 30 days.</p>
                        <p>We will retain and use your information as necessary to comply with any legal obligations, disputes, and enforce our agreements.</p>
                        <h6 class="m-0 mt-4">Cookies</h6>
                        <p>Petstar uses cookies to help provide the best customer experience for our customers and make our website easier for you to use.</p>
                        <h6 class="m-0 mt-4">What are cookies?</h6>
                        <p>In this case they aren't the super yummy chocolate chip baked treat we all love; no they are super tiny files that contain information that is needed to help communicate via your web browser (Like Chrome or IE) to our web server, these files are vital and to help us present your account information. Cookies are used to save time and make visiting websites more efficient and personalized. Currently in order to use is site you must accept cookies. For more information please visit (please note we are not responsible for the content on this site) <a href="https://www.allaboutcookies.org" target="_blank">https://www.allaboutcookies.org</a></p>
                        <h6 class="m-0 mt-4">How does Petstar use cookies?</h6>
                        <p>Currently we only use cookies to store account and user information that enables us to log you in and present the correct information to you.</p>
                        <h6 class="m-0 mt-4">Information You Upload to Petstar</h6>
                        <p>Petstar offers a scheduling, marketing, CRM and other business tools. All client and business data uploaded into our system are stored in a secure location. Only restricted users at Petstar have access to your Data</p>
                        <h6 class="m-0 mt-4">Links</h6>
                        <p>Petstar may present links in a format that enables us to keep track of whether these links have been followed. We use this information to improve the quality of our services, customized content and advertising.</p>
                        <h6 class="m-0 mt-4">Other Sites</h6>
                        <p>Petstar website may contain links to other sites. Please be aware that Petstar is not responsible for the privacy policy of third party sites. We encourage our users to read the privacy policies of each and every website that collects personal, identifiable information.</p>
                        <h6 class="m-0 mt-4">User Communication</h6>
                        <p>We may retain email or other communication you send to Petstar in order to process your inquiries, respond to your requests and improve our services.</p>
                        <h6 class="m-0 mt-4">Changes to this Privacy Policy</h6>
                        <p>This Privacy Policy may change over time, it is you responsibility to visit out website to check for updates, however petstar may email you of changes. You reserve the right at any time to cancel your account by emailing us at <a href="mailto:support-team@petstar.co.uk">support-team@petstar.co.uk</a>.</p>
                        <h6 class="m-0 mt-4">Security</h6>
                        <p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, as well as physical security measures to guard against unauthorized access to systems where we store personal data. We restrict access to personal information to Petstar employees, contractors and agents who need to know that information in order to operate, develop or improve our services. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations.</p>
                        <p class="my-4">Version: 1.7</p>
                        <a href="http://www.shakeyourtail.com" target="_blank">www.shakeyourtail.com</a>
                    </div>
                    <br />
                    <div class="text-center copy">
                        &copy; 2023
                        <a href="#/login">Petstar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicy',
    }
</script>

<style scoped>
    .terms-content {
        padding-inline: 3rem;
        max-height: 500px;
        overflow: auto;
    }
    @media screen and (max-width: 768px) {
        .terms-content {
            max-height: calc(100vh - 225px);
            padding-inline: 0;
        }
    }
    .terms-content h1, 
    .terms-content h6 {
        color: #fff;
    }
    .terms-content p {
        margin: 0;
        color: #fff;
    }
    .terms-content ul li,
    .terms-content ol li {
        margin: 0;
        color: #fff;
    }
    .terms-wrap {
        max-width: none;
    }
</style>
