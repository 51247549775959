<template>
    <tr>
        <td>
            <div class="client-row w-100">
                <div class="client-row-details w-100">
                    <div class="crd-name text-truncate">
                        {{description}}
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div>
                            <div class="crd-contact">
                                {{$t('Date')}}: {{createdLocalString}}
                            </div>
                            <div class="crd-contact">
                                {{$t('Status')}}: {{statusDescription}}
                            </div>
                            <div class="crd-contact">
                                {{$t('Amount')}}:
                                <span v-if="payment.currency === 'GBP'">&pound;</span>
                                <span v-else>&dollar;</span>{{amount}}
                            </div>
                        </div>
                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="onClick" title="Checkout">
                            <i class="fal fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { PaymentFailureItemMixin } from "./PaymentFailureItemMixin";

    export default {
        name: 'PaymentFailureItemMobile',
        mixins: [PaymentFailureItemMixin]
    }
</script>
