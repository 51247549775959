<template>
    <nav class="nav-mobile">
        <ul>
            <main-link menu-name="Dashboard" icon-class="fas fa-gauge-max" router-name="dashboard"></main-link>
            <main-link menu-name="Schedule" icon-class="fas fa-calendar-alt" router-name="schedule"></main-link>
            <li id="-mobile-nav">
                <a @click="triggerAddAppointmentWithDuration" class="nav-link-text">
                    <i class="fas fa-plus"></i>
                    <span>{{$t('Add')}}</span>
                </a>
            </li>
            <main-link menu-name="Clients" icon-class="fas fa-user-alt" router-name="clients"></main-link>
            <li>
                <a @click="toggleShowMobileNav" class="nav-link-text">
                    <button class="btn btn-blank btn-burger d-md-none" type="button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <span>{{$t('More')}}</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { mapMutations } from 'vuex';
    import NavPanelMainLink from "./NavPanelMainLink.vue";

    export default {
        name: 'NavPanelMobile',
        methods: {
            ...mapMutations('ApplicationEvents', [
                'toggleShowMobileNav',
                'triggerAddAppointmentWithDuration'
            ])
        },
        components: {
            'main-link': NavPanelMainLink
        }
    }
</script>
