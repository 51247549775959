import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from '../../../helpers/CacheHelper.js';
import { ErrorHelper } from "../../../helpers/ErrorHelper.js";

export const CountryOptionSearchMixin = {
    data() {
        return {
            error: '',
            searchTerm: '',
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters('CountryOptionSearches', [
            'findCountryOptionSearch'
        ]),
        countryOptionSearchModel() {
            return {
                searchTerm: this.searchTerm
            }
        },
        countryOptionSearchModelValid() {
            return this.countryOptionSearchModel.searchTerm.length > 1;
        },
        countryOptionSearchKey() {
            return CacheHelper.objectCacheKey(this.countryOptionSearchModel);
        },
        countryOptionSearch() {
            return this.findCountryOptionSearch(this.countryOptionSearchKey);
        },
        countryOptions() {
            return !!this.countryOptionSearch ? this.countryOptionSearch.values : [];
        },
        searchingCountryOptions() {
            return !!this.countryOptionSearch && this.countryOptionSearch.loading;
        },
        showCountryResults() {
            return !!this.countryOptionSearch && !this.searchingCountryOptions;
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(this.refreshCountryOptionSearch, 500);
        }
    },
    methods: {
        ...mapActions('CountryOptionSearches', [
            'searchCountryOptions'
        ]),
        refreshCountryOptionSearch() {
            if (!this.countryOptionSearch || !!this.countryOptionSearch.retry) {
                this.trySearchCountryOptions();
            }
        },
        trySearchCountryOptions() {
            const self = this;
            if (!self.countryOptionSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchCountryOptions({ key: self.countryOptionSearchKey, payload: self.countryOptionSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCountryOptionSearch();
    }
}
