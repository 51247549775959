import { mapActions, mapState } from "vuex"
import { BreedHelper } from "../../helpers/BreedHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteCacheMixin } from "./SiteCacheMixin";

export const BreedAddMixin = {
    mixins: [SiteCacheMixin],
    data() {
        return {
            error: '',
            name: '',
            speciesId: '',
            siteIds: [],
            breedValidator: BreedHelper.getBreedAddValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'breedsAddedEvent'
        ]),
        breedAddModel() {
            return {
                name: this.name,
                speciesId: this.speciesId,
                siteIds: this.siteIds
            };
        },
        breedAddModelValid() {
            return this.breedValidator.isValid;
        }
    },
    watch: {
        breedsAddedEvent() {
            this.name = '';
            this.speciesId = '';
            this.refreshSiteIds();
        },
        sites() {
            this.refreshSiteIds();
        }
    },
    methods: {
        ...mapActions('Breeds', [
            'addBreed'
        ]),
        refreshSiteIds() {
            this.siteIds = this.sites.map(s => s.id);
        },
        tryAddBreed() {
            const self = this;
            self.breedValidator.check(self.breedAddModel);
            if (!self.breedAddModelValid) {
                return;
            }
            self.error = '';
            self.addBreed({ keys: self.siteIds, payload: self.breedAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSiteIds();
    }
}
