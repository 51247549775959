<template>
    <div class="rewards_card mt-3" v-bind:style="{ 'background-color': colour }">
        <div class="pricing-plan_card-header p-4">
            <i class="fa-duotone fa-4x fa-trophy" v-bind:style="{ 'color': colour }"></i>
        </div>
        <div class="pricing-plan_card-body rewards_card-body w-100 px-4 pt-2">
            <div class="pricing-plan_card-title pricing-plan_card-title-lg white">{{reward.name}}</div>
            <template v-if="levelPassed">
                <div class="d-flex flex-column align-items-center my-2">
                    <div>
                        <i v-for="point in pointsRemaining" class="fas fa-star fa-1x"></i>
                    </div>
                    <div class="pricing-plan_card-title-sub white mt-1">
                        Level {{reward.level}} Completed - Great Job!
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="d-flex flex-column align-items-center my-2">
                    <div class="d-flex">
                        <i v-for="point in score" class="fas fa-star fa-1x"></i>
                        <i v-for="point in pointsRemaining" class="fal fa-star fa-1x"></i>
                    </div>
                    <div class="pricing-plan_card-title-sub white mt-1">
                        {{pointsRemaining}} more {{pointsRemaining == 1 ? 'point' : 'points'}} to get this reward!
                    </div>
                </div>
            </template>
            <div class="w-100 d-flex flex-column flex-md-row">
                <div class="col-md-6 col-12 text-center mt-2 p-0">
                    <div class="font-weight-bold">Monthly Subscription (One-off):</div>
                    <div v-for="(value, key) in monthly" :key="key">
                        {{ key }} - &#163;{{ value }}
                    </div>
                </div>
                <div class="col-md-6 col-12 text-center mt-2 p-0">
                    <div class="font-weight-bold">Yearly Subscription (One-off):</div>
                    <div v-for="(value, key) in yearly" :key="key">
                        {{ key }} - &#163;{{ value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReferralsRewardsItem',
        props: {
            reward: Object,
            score: Number
        },
        computed: {
            levelPassed() {
                return this.score >= this.reward.requirement;
            },
            pointsRemaining() {
                return !!this.levelPassed ? this.reward.requirement : Math.abs(this.reward.requirement - this.score);
            },
            colour() {
                return this.reward.colour
            },
            monthly() {
                const monthlyArray = Object.entries(this.reward.amount.monthly);
                monthlyArray.sort((a, b) => a[1] - b[1]);
                return  Object.fromEntries(monthlyArray);
            },
            yearly() {
                const yearlyArray = Object.entries(this.reward.amount.yearly);
                yearlyArray.sort((a, b) => a[1] - b[1]);
                return Object.fromEntries(yearlyArray);
            }
        }
    }
</script>