<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{title}}</label>
        </div>
        <div class="col-md-8">
            <input v-model="content" class="form-control" :placeholder="title" :disabled="disabled">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SlottedInput',
        props: {
            title: String,
            value: String,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            content: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
