import { Api } from "../../services/Api";

const state = () => ({
    appointmentScheduleOrderCaches: []
})

const getters = {
    findAppointmentScheduleOrderCache: (state) => (key) => {
        return state.appointmentScheduleOrderCaches.find(asoc => asoc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentScheduleOrderCaches.find(asoc => asoc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.appointmentScheduleOrderCaches.push({ key: key, loading: !!loading });
        }
    }
}

const actions = {
    addAppointmentScheduleOrders({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Schedule/Order', payload)
                .then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        const order = response.data[i];
                        commit('Orders/appendOrder', { key: order.appointmentId, order: order }, { root: true });
                    }
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
