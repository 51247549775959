import { mapState, mapGetters, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const ResourceCountCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ResourceCount', [
            'resourceCountCacheCleared'
        ]),
        ...mapGetters('ResourceCount', [
            'findResourceCountCache'
        ]),
        resourceCountCache() {
            return this.findResourceCountCache(this.siteId);
        },
        resourceCount() {
            return !!this.resourceCountCache ? this.resourceCountCache.value : null;
        },
        loadingResourceCount() {
            return !!this.resourceCountCache && this.resourceCountCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshResourceCount();
        },
        resourceCountCacheCleared() {
            this.refreshResourceCount();
        }
    },
    methods: {
        ...mapActions('ResourceCount', [
            'fetchResourceCount'
        ]),
        tryFetchResourceCount() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchResourceCount(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshResourceCount() {
            if (!this.resourceCountCache || !!this.resourceCountCache.retry) {
                this.tryFetchResourceCount();
            }
        }
    },
    created() {
        this.refreshResourceCount();
    }
}
