<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{title}}</label>
        </div>
        <div class="col-md-8">
            <input v-model="amount" class="form-control text-right" type="number">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IntegerInput',
        props: {
            value: Number,
            title: String
        },
        computed: {
            amount: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', parseInt(value));
                }
            }
        }
    }
</script>
