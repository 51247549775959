<template>
    <div class="dropdown w-100">
        <button aria-expanded="false"
                aria-haspopup="true"
                class="filter-dd text-left w-100 mw-400"
                data-toggle="dropdown"
                type="button">
            <multi-select-display :values="summary"></multi-select-display>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100 mw-400">
            <dropdown-item v-for="site in selectedSites" v-model="selectedResources" :site="site" :key="site.id"></dropdown-item>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../SiteCacheMixin";
    import { AllResourcesMixin } from "./AllResourcesMixin";
    import ScheduleFilterResourceItem from "../../Scheduler/ScheduleFilterResourceItem.vue";
    import MultiSelectDropdownDisplay from "../Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'ResourceMultiSelectDropdown',
        mixins: [SiteCacheMixin, AllResourcesMixin],
        props: {
            value: Array,
            siteIds: Array
        },
        computed: {
            selectedResources: {
                get() {
                    return this.value.map(id => this.allResources.find(r => r.id === id)).filter(r => !!r);
                },
                set(value) {
                    this.$emit('input', value.map(v => v.id));
                }
            },
            selectedSites() {
                return this.siteIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s);
            },
            summary() {
                return this.selectedResources.length > 0 ? this.selectedResources.map(r => r.name) : ['Staff'];
            },
        },
        watch: {
            siteIds() {
                this.$emit('input', []);
            }
        },
        components: {
            'dropdown-item': ScheduleFilterResourceItem,
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
