<template>
    <div>
        <div v-if="editing">
            <manual-input v-model="address"></manual-input>
            <a @click="editing=false" class="link pointer">{{$t('Back')}}</a>
        </div>
        <template v-else>
            <div v-if="!!addressString">
                <div class="mb-1">
                    {{addressString}}
                </div>
                <a @click="editing=true" class="link pointer">{{$t('Edit')}}</a>
            </div>
            <template v-else>
                <postcode-lookup v-if="canUseLookup" v-model="address"></postcode-lookup>
                <template v-else>
                    <manual-input v-model="address"></manual-input>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
    import { AddressHelper } from "../../../helpers/AddressHelper";
    import { CountryFormatCacheMixin } from "../Country/CountryFormatCacheMixin";
    import AddressManualInput from "./AddressManualInput.vue";
    import PostcodeLookup from "./PostcodeLookup.vue";

    export default {
        name: 'AddressInputV2',
        mixins: [CountryFormatCacheMixin],
        data() {
            return {
                editing: false
            }
        },
        props: {
            value: Object
        },
        computed: {
            address: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            addressString() {
                return AddressHelper.getAddressLines(this.address).join(', ');
            },
            canUseLookup() {
                return !!this.countryFormat && this.countryFormat.addressLookup;
            }
        },
        components: {
            'manual-input': AddressManualInput,
            'postcode-lookup': PostcodeLookup
        }
    }
</script>
