import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper.js';
import { SiteIdMixin } from '../../../components/Shared/Site/SiteIdMixin.js';

export const CustomDurationCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CustomDurations', [
            'findCustomDurationCache'
        ]),
        customDurationCache() {
            return this.findCustomDurationCache(this.siteId);
        },
        loadingCustomDurations() {
            return this.customDurationCache && this.customDurationCache.loading;
        },
        customDurations() {
            return this.customDurationCache ? this.customDurationCache.values : [];
        }
    },
    watch: {
        siteId() {
            this.refreshCustomDurations();
        }
    },
    methods: {
        ...mapActions('CustomDurations', [
            'getCustomDurations'
        ]),
        tryGetCustomDurations() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getCustomDurations(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshCustomDurations() {
            if (!this.customDurationCache || !!this.customDurationCache.retry) {
                this.tryGetCustomDurations();
            }
        }
    },
    created() {
        this.refreshCustomDurations();
    }
}
