<template>
    <section class="mb-3">
        <div ref="accordionHeaderAlert" aria-expanded="false" class="accordion-header collapsed" data-target="#collapse-alert" data-toggle="collapse">
            {{$t('Alert')}}
            <span v-if="loadingNote" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-alert">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <input v-model="noteText" class="form-control" type="text" maxlength="200" style="width: 100%; max-width: 100%">
            </div>
        </div>
    </section>
</template>

<script>
    import { NoteUploadMixin } from "../Shared/Notes/NoteUploadMixin";
    import { mapState } from 'vuex';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'ClientAlertSection',
        mixins: [NoteUploadMixin, StatusEmitterMixin],
        props: {
            clientId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            ownerId() {
                return this.clientId;
            },
            ownerTypeId() {
                return 2;
            },
            noteTypeId() {
                return 4;
            },
            status() {
                return {
                    loading: this.loadingNote,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.tryUploadNote();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
