import moment from 'moment';

export const ResourceHoursDayMixin = {
    props: {
        dayId: Number,
        workingHours: Array,
        oneOffHours: Array
    },
    computed: {
        workingHour() {
            return this.workingHours.find(wh => wh.dayOfWeek.id === this.dayId);
        },
        oneOffHour() {
            return this.oneOffHours.find(ooh => moment(ooh.dateLocal).day() === this.dayId);
        },
        hours() {
            return !!this.oneOffHour ? this.oneOffHour : this.workingHour;
        },
        isOneOff() {
            return !!this.oneOffHour;
        },
        timeSummary() {
            return moment(this.hours.startLocal).format('HH:mm') + ' - ' + moment(this.hours.endLocal).format('HH:mm');
        },
        workingSummary() {
            return !!this.hours.dayOff ? 'Day off' : this.timeSummary;
        }
    },
    methods: {
        onClick() {
            this.$emit('cell-clicked', { dayId: this.dayId, oneOff: this.isOneOff });
        }
    }
}
