<template>
    <div class="wrapper" v-bind:class="additives">
        <nav-panel></nav-panel>
        <nav-panel-expand></nav-panel-expand>
        <main class="main">
            <router-view></router-view>
            <notification-panel v-model="notificationsPinned"></notification-panel>
            <add-item ref="addItem"></add-item>
            <view-appointment :id="appointmentId" ref="viewAppointment"></view-appointment>
        </main>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import ScheduleAddItem from '../Scheduler/AddAppointment/ScheduleAddItem.vue';
    import ScheduleViewAppointment from '../Scheduler/ViewAppointment/ScheduleViewAppointment.vue';
    import { TimeZoneMixin } from '../Shared/Time/Zone/TimeZoneMixin';
    import NavPanel from './NavPanel.vue'
    import NavPanelExpand from './NavPanelExpand.vue'
    import NotificationPanel from './NotificationPanel.vue'
    import { SettingCacheMixin } from '../Shared/SettingCacheMixin';
    import { AppointmentDefaultsSettingMixin } from '../../mixins/Settings/Site/AppointmentDefaultsSettingMixin';

    export default {
        name: 'MainLayout',
        mixins: [TimeZoneMixin, SettingCacheMixin, AppointmentDefaultsSettingMixin],
        data() {
            return {
                notificationsPinned: false,
                appointmentId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'showMobileNav',
                'addAppointment',
                'addAppointmentWithDuration',
                'viewAppointment'
            ]),
            additives() {
                return {
                    'notifications-pinned': this.notificationsPinned,
                    'show-nav': this.showMobileNav
                }
            }
        },
        watch: {
            addAppointmentWithDuration() {
                this.setDuration(this.settingObject.petDurationMinutes);
                this.openAddAppointment();
            },
            addAppointment() {
                this.openAddAppointment();
            },
            viewAppointment(appointment) {
                this.openViewAppointment(appointment);
            }
        },
        methods: {
            ...mapMutations('Appointments', [
                'setAppointment'
            ]),
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setDuration'
            ]),
            openAddAppointment() {
                if (!this.showMobileNav) {
                    const element = this.$refs.addItem.$el;
                    $(element).modal('show');
                }
            },
            openViewAppointment(appointment) {
                if (!!appointment) {
                    this.setSelected(appointment.siteId);
                    this.setAppointment(appointment);
                    this.appointmentId = appointment.id;
                    const element = this.$refs.viewAppointment.$el;
                    $(element).modal('show');
                }
            }
        },
        components: {
            'nav-panel': NavPanel,
            'nav-panel-expand': NavPanelExpand,
            'notification-panel': NotificationPanel,
            'add-item': ScheduleAddItem,
            'view-appointment': ScheduleViewAppointment
        }
    }
</script>

<style>
    .pointer {
        cursor: pointer;
    }
</style>
