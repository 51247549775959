<template>
    <td class="text-center">
        <button v-if="hours" @click="onClick" type="button" class="btn btn-outline-secondary px-3">
            {{workingSummary}}
        </button>
        <template v-else>
            <button @click="onClick" type="button" class="btn btn-secondary">
                <i class="fas fa-plus"></i>
            </button>
        </template>
    </td>
</template>

<script>
    import { ResourceHoursDayMixin } from './ResourceHoursDayMixin';

    export default {
        name: 'ResourceHoursDayDesktop',
        mixins: [ResourceHoursDayMixin]
    }
</script>
