<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Resources')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button" class="filter-dd">
                    {{staffString}}
                    <i class="far fa-chevron-down"></i>
                </button>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                    <div>
                        <div v-for="resource in options" :key="resource.id" class="dropdown-item">
                            <label class="custom-control custom-checkbox">
                                <input v-model="resource.selected" @change="onChange" type="checkbox" class="custom-control-input">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">{{resource.name}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group has-error has-feedback">
                <validation-message v-if="validator.resourceIds" :message="validator.resourceIds"></validation-message>
            </div>
        </div>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'EventResourceSelect',
        mixins: [ResourceCacheMixin],
        props: {
            value: Array,
            validator: Object
        },
        computed: {
            options() {
                return this.resources.map(r => Object.assign({}, r, {
                    selected: this.value.indexOf(r.id) > -1
                }));
            },
            selectedOptions() {
                return this.options.filter(o => o.selected);
            },
            staffString() {
                return this.selectedOptions.length > 0 ? this.selectedOptions.map(o => o.name).join(', ') : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.filter(o => o.selected).map(o => o.id));
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
