<template>
    <div class="col">
        <div class="row">
            <div class="col-md-8">
                <pet-details :client="client"></pet-details>
                <pet-weight></pet-weight>
            </div>
            <div class="col-md-4">
                <pet-notes></pet-notes>
                <pet-images></pet-images>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import PetAddWeight from "../Weight/PetAddWeight.vue";
    import PetAddDetails from "./PetAddDetails.vue";
    import PetAddImages from "./PetAddImages.vue";
    import PetAddNotes from "./PetAddNotes.vue";

    export default {
        name: 'PetAddV2',
        props: {
            client: Object
        },
        computed: {
            ...mapState('PetsV2', [
                'addingPet'
            ])
        },
        watch: {
            addingPet(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'pet-details': PetAddDetails,
            'pet-weight': PetAddWeight,
            'pet-notes': PetAddNotes,
            'pet-images': PetAddImages
        }
    }
</script>
