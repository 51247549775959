<template>
    <div>
        <span v-if="loadingClientLogs" class="inline-loader"></span>
        <template v-else>
            <div v-if="clientLogs.length > 0" @click="openClientLogsTable" class="pointer client-logs-section">
                <table-desktop>
                    <template #header>
                        <tr role="row">
                            <th class="col-2">Date</th>
                            <th class="col-2">Time</th>
                            <th class="col-8">Message</th>
                        </tr>
                    </template>
                    <template #rows>
                        <preview-item v-for="(log, index) in topClientLogs" :key="index" :log="log" :number="index"></preview-item>
                    </template>
                </table-desktop>
                <a v-if="showMore">Show more</a>
            </div>
            <div v-else>
                {{$t('No event logs to display')}}
            </div>
        </template>
        <modal-dialog ref="clientLogsTable" :title="title">
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th class="col-3">Timestamp</th>
                        <th class="col-2 text-center">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    Type
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-item" v-bind:class="{ 'active': filter==='' }">
                                        <a class="pointer" @click="filter=''">All</a>
                                    </div>
                                    <div v-for="logFilter in clientLogFilters" class="dropdown-item" v-bind:class="{ 'active': filter===logFilter }">
                                        <a class="pointer" @click="filter=logFilter">{{logFilter}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th class="col-7">Message</th>
                    </tr>
                </template>
                <template #rows>
                    <log-desktop v-for="(log, index) in filteredClientLogs" :key="index" :log="log" :number="index"></log-desktop>
                </template>
            </table-desktop>
        </modal-dialog>
    </div>
</template>

<script>
    import { ClientLogsCacheMixin } from "../../../mixins/ClientLogs/ClientLogsCacheMixin";
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import TableDesktop from "../../Shared/Layout/TableDesktop.vue";
    import LogItemDesktop from "./LogItemDesktop.vue";
    import ClientLogPreviewItem from "./ClientLogPreviewItem.vue";

    export default {
        name: 'ClientLogsSection',
        mixins: [ClientLogsCacheMixin],
        props: {
            client: Object
        },
        computed: {
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            topClientLogs() {
                return this.clientLogs.slice(0, 5);
            },
            showMore() {
                return this.topClientLogs.length < this.clientLogs.length;
            },
            clientFullName() {
                return !!this.client ? this.client.fullName : '';
            },
            title() {
                return this.clientFullName + ' Event Logs';
            }
        },
        methods: {
            openClientLogsTable() {
                const element = this.$refs.clientLogsTable.$el;
                $(element).modal('show');
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'table-desktop': TableDesktop,
            'log-desktop': LogItemDesktop,
            'preview-item': ClientLogPreviewItem
        }
    }
</script>
