import { SiteCacheMixin } from "../../components/Shared/SiteCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ProductHelper } from "../../helpers/ProductHelper";
import { ServiceProductsGroupMixin } from "./ServiceProductsGroupMixin";
import { mapActions } from 'vuex';

export const ServiceProductsAddMixin = {
    mixins: [ServiceProductsGroupMixin, SiteCacheMixin],
    data() {
        return {
            amount: null,
            name: '',
            description: '',
            publishedOnline: false,
            siteIds: [],
            serviceProductValidator: ProductHelper.getServiceProductValidator()
        }
    },
    computed: {
        missingSiteIds() {
            return this.siteIds.filter(id => !this.serviceProducts.find(p => p.siteId === id));
        },
        serviceProductsAddModel() {
            return {
                productId: this.productId,
                amount: this.amount,
                name: this.name,
                description: this.description,
                publishedOnline: this.publishedOnline,
                siteIds: this.missingSiteIds
            }
        },
        serviceProductsAddModelValid() {
            const model = this.serviceProductsAddModel;
            return !!model.productId && !!this.serviceProductValidator.isValid;
        }
    },
    watch: {
        productId() {
            this.refreshServiceProductsModel();
        },
        sites() {
            this.refreshSiteIds();
        }
    },
    methods: {
        ...mapActions('ServiceProductGroups', [
            'addServiceProducts'
        ]),
        tryAddServiceProducts() {
            const self = this;
            self.serviceProductValidator.validate(self.serviceProductsAddModel);
            if (!self.serviceProductsAddModelValid) {
                return;
            }
            self.error = '';
            self.addServiceProducts({ productId: self.productId, payload: self.serviceProductsAddModel })
                .then(response => self.afterAddingServiceProducts(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddingServiceProducts() {
            // for overriding
        },
        refreshServiceProductsModel() {
            this.amount = null;
            this.name = '';
            this.description = '';
            this.publishedOnline = false,
            this.refreshSiteIds();
        },
        refreshSiteIds() {
            this.siteIds = this.sites.map(s => s.id);
        }
    },
    created() {
        this.refreshServiceProductsModel();
    }
}
