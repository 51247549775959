import { mapGetters, mapActions } from "vuex"
import { CacheHelper } from "../../../helpers/CacheHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const NoteCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Notes', [
            'findNoteCache'
        ]),
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId,
                ascending: false
            }
        },
        ownerQueryModelValid() {
            const model = this.ownerQueryModel;
            return !!model.ownerId && !!model.ownerTypeId;
        },
        noteCacheKey() {
            return CacheHelper.ownerRequestCacheKey(this.ownerQueryModel);
        },
        noteCache() {
            return this.findNoteCache(this.noteCacheKey);
        },
        notes() {
            return !!this.noteCache ? this.noteCache.values : [];
        },
        loadingNote() {
            return !!this.noteCache && this.noteCache.loading;
        }
    },
    watch: {
        ownerQueryModel() {
            this.refreshNote();
        }
    },
    methods: {
        ...mapActions('Notes', [
            'searchNotes'
        ]),
        refreshNote() {
            if (!this.noteCache || this.noteCache.retry) {
                this.trySearchNotes();
            }
        },
        trySearchNotes() {
            const self = this;
            if (!self.ownerQueryModelValid) {
                return;
            }
            self.error = '';
            self.searchNotes({ key: self.noteCacheKey, payload: self.ownerQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshNote();
    }
}
