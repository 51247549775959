<template>
    <div v-if="showAlert" class="alert alert-warning alert-icon" role="alert">
        <div class="alert-content">
            <p>{{note.text}}</p>
        </div>
    </div>
</template>

<script>
    import { ClientAlertMixin } from "../Shared/ClientAlertMixin.js";

    export default {
        name: 'AppointmentAlert',
        mixins: [ClientAlertMixin]
    }
</script>
