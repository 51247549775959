import { mapState, mapActions } from 'vuex';
import { SiteIdMixin } from '../../components/Shared/Site/SiteIdMixin';
import { UserCacheMixin } from '../../components/Shared/UserCacheMixin';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { PaymentHelper } from '../../helpers/PaymentHelper';

export const PaymentApiUserCacheMixin = {
    mixins: [SiteIdMixin, UserCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PaymentApiUser', [
            'fetchedPaymentApiUser',
            'fetchingPaymentApiUser',
            'paymentApiUser'
        ]),
        paymentApiUserInvalid() {
            return !!this.user && !!this.paymentApiUser && this.user.id !== this.paymentApiUser.name;
        },
        ownerAccounts() {
            return !!this.paymentApiUser ? this.paymentApiUser.owner : [];
        },
        adminAccounts() {
            return !!this.paymentApiUser ? this.paymentApiUser.admin : [];
        },
        refundAccounts() {
            return !!this.paymentApiUser ? this.paymentApiUser.refunder : [];
        },
        isPaymentOwner() {
            return this.ownerAccounts.indexOf(this.siteId) > -1;
        },
        isPaymentAdmin() {
            return this.isPaymentOwner || this.adminAccounts.indexOf(this.siteId) > -1;
        },
        isPaymentRefunder() {
            return this.isPaymentOwner || this.refundAccounts.indexOf(this.siteId) > -1;
        }
    },
    watch: {
        paymentApiUserInvalid() {
            this.refreshPaymentApiUser();
        }
    },
    methods: {
        ...mapActions('PaymentApiUser', [
            'fetchPaymentApiUser'
        ]),
        refreshPaymentApiUser() {
            if (this.paymentApiUserInvalid) {
                PaymentHelper.clearToken();
            }
            if (this.paymentApiUserInvalid || (!this.fetchedPaymentApiUser && !this.fetchingPaymentApiUser)) {
                this.tryFetchPaymentApiUser();
            }
        },
        tryFetchPaymentApiUser() {
            const self = this;
            self.error = '';
            self.fetchPaymentApiUser()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentApiUser();
    }
}
