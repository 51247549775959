import { PetBreedsSearchMixin } from "../../Breeds/PetBreedsSearchMixin";
import { ClientPetsSearchMixin } from "../../Clients/ClientPetsSearchMixin";
import { PetClientsSearchMixin } from "../../Pet/Search/PetClientsSearchMixin";
import { ResourceCacheMixin } from "../Resources/ResourceCacheMixin";

export const AppointmentsDataFinder = {
    mixins: [ResourceCacheMixin, PetClientsSearchMixin, ClientPetsSearchMixin, PetBreedsSearchMixin],
    computed: {
        appointments() {
            console.log('!!!OVERRIDE COMPUTED - appointments!!!');
            return [];
        },
        clientIds() {
            return [...new Set(this.appointments.map(a => a.clientId))];
        },
        petIds() {
            return [...new Set(this.appointments.map(a => a.petId))];
        },
        breedIds() {
            const result = [];
            for (var i = 0; i < this.appointments.length; i++) {
                const appointment = this.appointments[i];
                const pets = this.findPets(appointment.clientId);
                result.push(pets.find(p => p.id === appointment.petId));
            }
            return result.filter(p => !!p).map(p => p.breedId);
        }
    }
}
