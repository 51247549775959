import { Api } from "../../services/Api";

const state = () => ({
    creditPackCaches: []
})

const getters = {
    findCreditPackCache: (state) => key => state.creditPackCaches.find(cp => cp.key === key)
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.creditPackCaches.find(cp => cp.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.creditPackCaches.push({ key: key, value: null, loading: !!loading });
            }
        }
    },
    setCreditPacks(state, { keys, creditPacks }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const creditPack = creditPacks.find(cp => cp.id === key);
            const existing = state.creditPackCaches.find(cp => cp.key === key);
            if (!!existing) {
                existing.value = creditPack;
                existing.loading = false;
            } else {
                state.creditPackCaches.push({ key: key, value: creditPack, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.creditPackCaches.find(cp => cp.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    },
}

const actions = {
    searchCreditPacks({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            Api.post('CreditPack/Search', payload)
                .then(response => {
                    commit('setCreditPacks', { keys: keys, creditPacks: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', keys);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
