import { StatusEmitterMixin } from "../../Shared/StatusEmitterMixin";
import { AccountSettingUploadMixin } from "./AccountSettingUploadMixin";
import { mapState } from 'vuex';

export const AccountSettingSiteDefaultMixin = {
    mixins: [AccountSettingUploadMixin, StatusEmitterMixin],
    computed: {
        ...mapState('ApplicationEvents', [
            'saveSiteDefaultsEvent'
        ]),
        status() {
            return {
                loading: this.loadingAccountSetting
            }
        }
    },
    watch: {
        saveSiteDefaultsEvent() {
            this.tryUploadAccountSetting();
        }
    }
}
