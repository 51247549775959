import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PetHelper } from "../../helpers/PetHelper"

export const PetAddMixin = {
    data() {
        return {
            error: '',
            name: '',
            dateOfBirth: null,
            microChip: '',
            breedId: '',
            genderId: 1,
            neuteredId: 0,
            behaviourId: 0,
            deceased: false,
            petValidator: PetHelper.getPetValidator()
        }
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'petAddedEvent'
        ]),
        ...mapState('PetsV2', [
            'addingPet'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        petAddModel() {
            return {
                clientId: this.clientId,
                name: this.name,
                dateOfBirth: this.dateOfBirth,
                microChip: this.microChip,
                breedId: this.breedId,
                genderId: this.genderId,
                neuteredId: this.neuteredId,
                behaviourId: this.behaviourId,
                deceased: this.deceased
            }
        },
        petAddModelValid() {
            return this.petValidator.isValid;
        }
    },
    watch: {
        petAddedEvent() {
            this.resetPetAddModel();
        }
    },
    methods: {
        ...mapActions('PetsV2', [
            'addPet'
        ]),
        resetPetAddModel() {
            this.name = '';
            this.dateOfBirth = null;
            this.microChip = '';
            this.breedId = '';
            this.genderId = 1;
            this.neuteredId = 0;
            this.behaviourId = 0;
            this.deceased = false;
        },
        tryAddPet() {
            const self = this;
            self.petValidator.check(self.petAddModel);
            if (!self.clientId || !self.petAddModelValid) {
                return;
            }
            self.error = '';
            return self.addPet({ key: self.clientId, payload: self.petAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
