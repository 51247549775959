import { mapState, mapActions } from 'vuex';
import { CampaignSettingCacheMixin } from "./CampaignSettingCacheMixin";
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const CampaignSettingAddMixin = {
    mixins: [CampaignSettingCacheMixin],
    computed: {
        ...mapState('CampaignSettings', [
            'addingCampaignSettings'
        ])
    },
    methods: {
        ...mapActions('CampaignSettings', [
            'addCampaignSetting'
        ]),
        tryAddCampaignSetting() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.addCampaignSetting()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
