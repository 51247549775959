<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Campaigns')}}</label>
        </div>
        <div class="col-md-8">
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Credits')}}</label>
                </div>
                <div class="col-md-8">
                    <p>{{remainingCredits}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { CampaignSettingUploadMixin } from "../../Communication/Settings/CampaignSettingUploadMixin";
    import { mapState } from 'vuex';
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateCreditsCampaign',
        mixins: [CampaignSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            remainingCredits() {
                return !!this.campaignSetting ? this.campaignSetting.remainingCredits : 'N/A';
            },
            status() {
                return {
                    loading: this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadCampaignSetting();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
