import { mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper.js';
import { CustomDurationCacheMixin } from './CustomDurationCacheMixin.js';

export const CustomDurationAddMixin = {
    mixins: [CustomDurationCacheMixin],
    data() {
        return {
            error: '',
            value: 0,
            valueMessage: ''
        }
    },
    computed: {
        customDurationAddModel() {
            return {
                value: this.amount
            }
        },
        customDurationAddModelValid() {
            const model = this.customDurationAddModel;
            return model.value > 0;
        },
        customDurationAddModelModified() {
            const model = this.customDurationAddModel;
            return model.value !== 0;
        },
        amount: {
            get() {
                return parseInt(this.value, 10)
            },
            set(value) {
                this.value = value;
            }
        }
    },
    methods: {
        ...mapActions('CustomDurations', [
            'addCustomDuration'
        ]),
        tryAddCustomDuration() {
            const self = this;
            self.validateAddModel();
            if (!self.customDurationAddModelValid) {
                return;
            }
            self.error = '';
            self.addCustomDuration({ key: self.siteId, payload: self.customDurationAddModel })
                .then(response => self.afterAddCustomDuration(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        validateAddModel() {
            if (!!this.customDurationAddModelModified) {
                const model = this.customDurationAddModel
                this.valueMessage = model.value > 0 ? '' : 'Must be greater than 0.';
            }
        },
        resetCustomDurationModel() {
            this.value = 0;
        },
        afterAddCustomDuration() {
            this.resetCustomDurationModel();
        }
    }
}
