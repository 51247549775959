<template>
    <div v-if="failure" class="alert alert-danger alert-icon" role="alert">
        <div class="alert-content">
            <p>Payment Failed - Please contact the system administrator.</p>
        </div>
    </div>
</template>

<script>
    import { StripeCheckoutMixin } from "../../mixins/Stripe/StripeCheckoutMixin";
    import { Api } from "../../services/Api";

    export default {
        name: 'RestrictedCheckout',
        mixins: [StripeCheckoutMixin],
        data() {
            return {
                failure: false
            }
        },
        computed: {
            sessionUrl() {
                return 'Stripe/Subscription/Session';
            }
        },
        methods: {
            gotoComplete() {
                const self = this;
                Api.put('AccountRestriction/Account')
                    .finally(() => self.$router.push({ name: 'interstitial' }));
            },
            gotoRetry() {
                this.failure = true;
            }
        }
    }
</script>
