import { mapGetters } from "vuex";
import { BreedProductCacheMixin } from "../../mixins/Products/BreedProductCacheMixin";

export const BreedItemMixin = {
    mixins: [BreedProductCacheMixin],
    props: {
        breed: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('Species', [
            'findSpecies'
        ]),
        siteBreedId() {
            return this.breed.id;
        },
        breedName() {
            return this.breed.name;
        },
        startLetter() {
            return this.breedName[0].toUpperCase();
        },
        species() {
            return this.findSpecies(this.breed.speciesId);
        },
        speciesName() {
            return !!this.species ? this.species.name : 'Unknown';
        },
        productString() {
            return !!this.breedProduct ? this.breedProduct.amount.toFixed(2) : '';
        }
    },
    methods: {
        tryFindBreedProduct() {
            // do nothing
        },
        onClick() {
            this.$emit('breedClicked', this.breed);
        }
    }
}
