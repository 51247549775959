import { mapActions, mapGetters } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const EmailTemplateTagsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        communicationTypeId: Number
    },
    computed: {
        ...mapGetters('EmailTemplateTags', [
            'getEmailTemplateTagCache'
        ]),
        emailTemplateTagsQueryModel() {
            return {
                communicationTypeId: this.communicationTypeId
            }
        },
        emailTemplateTagsQueryModelValid() {
            return !!this.emailTemplateTagsQueryModel.communicationTypeId;
        },
        emailTemplateTagsCacheKey() {
            return this.communicationTypeId;
        },
        emailTemplateTagsCache() {
            return this.getEmailTemplateTagCache(this.emailTemplateTagsCacheKey);
        },
        emailTemplateTags() {
            return !!this.emailTemplateTagsCache ? this.emailTemplateTagsCache.values : [];
        },
        loadingEmailTemplateTags() {
            return !!this.emailTemplateTagsCache && this.emailTemplateTagsCache.loading;
        }
    },
    watch: {
        emailTemplateTagsQueryModel() {
            this.refreshEmailTemplateTags();
        }
    },
    methods: {
        ...mapActions('EmailTemplateTags', [
            'findEmailTemplateTags'
        ]),
        refreshEmailTemplateTags() {
            if (!this.emailTemplateTagsCache || !!this.emailTemplateTagsCache.retry) {
                this.findAndCacheEmailTemplateTags();
            }
        },
        findAndCacheEmailTemplateTags() {
            const self = this;
            if (!self.emailTemplateTagsQueryModelValid) {
                return;
            }
            self.error = '';
            self.findEmailTemplateTags({ key: self.emailTemplateTagsCacheKey, payload: self.emailTemplateTagsQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailTemplateTags();
    }
}