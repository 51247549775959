<template>
    <div>
        <booking-authenticated v-if="clientTypeId === 1"></booking-authenticated>
        <booking-anonymous v-else></booking-anonymous>
        <button ref="deleteButton" type="button" data-target="#delete-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="delete-modal" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning alert-icon">
                <div class="alert-content">
                    <p>{{$t('Are you sure you want to delete booking?')}}</p>
                </div>
                <button @click="tryDeleteBooking" v-bind:disabled="deleting" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="deleting" class="inline-loader"></span>
                    <template v-else>
                        {{$t('Yes')}}
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { BookingCacheMixin } from '../Shared/BookingCacheMixin';
    import { BookingDeleteMixin } from './BookingDeleteMixin';
    import BookingAnonymous from './BookingAnonymous.vue';
    import BookingAuthenticated from './BookingAuthenticated.vue';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';
    
    export default {
        name: 'BookingDisplay',
        mixins: [BookingDeleteMixin, BookingCacheMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'bookingDeletedEvent',
                'deleteBookingEvent',
            ]),
            bookingId() {
                return this.$route.params.id;
            },
            clientType() {
                return !!this.booking ? this.booking.clientType : null;
            },
            clientTypeId() {
                return !!this.clientType ? this.clientType.id : 0;
            },
        },
        watch: {
            bookingDeletedEvent() {
                this.$refs.cancelButton.click();
                this.$router.push({ name: 'bookings' });
            },
            deleteBookingEvent() {
                if (!!this.booking) {
                    this.$refs.deleteButton.click();
                }
            }
        },
        components: {
            'booking-anonymous': BookingAnonymous,
            'booking-authenticated': BookingAuthenticated,
            'error-banner': ErrorBanner,
            'modal-dialog': ModalDialog,
        }
    }
</script>
