import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const StripoDefaultTemplateBundleSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('StripoDefaultTemplateBundleSettings', [
            'loadingStripoDefaultTemplateBundleSettings',
            'loadedStripoDefaultTemplateBundleSettings',
            'stripoDefaultTemplateBundleSettings'
        ])
    },
    methods: {
        ...mapActions('StripoDefaultTemplateBundleSettings', [
            'getStripoDefaultTemplateBundleSettings'
        ]),
        refreshStripoDefaultTemplateBundleSettings() {
            if (!this.loadingStripoDefaultTemplateBundleSettings && !this.loadedStripoDefaultTemplateBundleSettings) {
                this.tryGetStripoDefaultTemplateBundleSettings();
            }
        },
        tryGetStripoDefaultTemplateBundleSettings() {
            const self = this;
            self.getStripoDefaultTemplateBundleSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshStripoDefaultTemplateBundleSettings();
    }
}
