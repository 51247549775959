<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Time Format')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionDescription}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in timeFormatOptions"
                            class="dropdown-item pointer"
                            :key="option.id"
                            @click="setTimeFormatId(option.id)"
                            v-bind:class="{'active': option.id === timeFormatId}">
                            {{now | moment(option.momentJS)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { TimeFormatOptionsCacheMixin } from "../../Shared/Time/TimeFormatOptionsCacheMixin";
    import { TimeFormatSettingUploadMixin } from "../../Shared/Time/TimeFormatSettingUploadMixin";
    import moment from 'moment';
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLocalizationTimeFormat',
        mixins: [TimeFormatOptionsCacheMixin, TimeFormatSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            now() {
                return moment();
            },
            status() {
                return {
                    loading: !!this.fetchingTimeFormatOptions || !!this.loadingTimeFormatSetting,
                    error: this.error
                }
            },
            selectedOption() {
                return this.timeFormatOptions.find(tfo => tfo.id == this.timeFormatId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.now.format(this.selectedOption.momentJS) : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            saveSiteEvent() {
                this.uploadTimeFormatSetting();
            }
        },
        methods: {
            setTimeFormatId(value) {
                this.timeFormatId = value;
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
