<template>
    <edit-overrides v-model="overrides" :conflicts="conflicts" :loading="loading"></edit-overrides>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import EditOverrides from "../EditAppointment/EditOverrides.vue";

    export default {
        name: 'AppointmentOverrides',
        props: {
            conflicts: Array,
            loading: Boolean
        },
        computed: {
            ...mapGetters('AppointmentScheduleUpload', [
                'getOverrides'
            ]),
            overrides: {
                get() {
                    return this.getOverrides;
                },
                set(value) {
                    this.setOverrides(value);
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setOverrides'
            ])
        },
        components: {
            'edit-overrides': EditOverrides
        }
    }
</script>
