<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse3a" data-toggle="collapse">
            {{$t('Images')}}
            <div class="total-figure ml-auto"></div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse3a">
            <div class="card card-body">
                <fieldset :disabled="disabled">
                    <div class="tab-pane fade show active">
                        <div @click="showFileSelect" class="file-upload">
                            <label>{{$t('Upload photos')}}</label>
                            <p class="mb-0">{{$t('Select or drop files here, max.')}} ({{maxUploadSize}})</p>
                            <input ref="fileInput" @change="onFilePicked" type="file" style="display: none" accept="image/*" multiple="multiple" />
                        </div>
                        <div class="image-uploads">
                            <ul>
                                <li v-for="upload in imagePreviewSet" :ref="upload.id" class="active">
                                    <button @click="removeUpload(upload)" class="btn" type="button">
                                        <i class="far fa-times"></i>
                                    </button>
                                    <figure>
                                        <img :src="upload.url" width="120">
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="img-gallery">
                        <ul>
                            <li v-for="i in images" :ref="i.id">
                                <img @click="image=i" :alt="i.uploadedName" :src="i.thumbnails.small" width="100">
                            </li>
                        </ul>
                    </div>
                    <error-banner v-if="error" :message="error"></error-banner>
                    <img v-if="image" class="img-responsive" :alt="image.uploadedName" :src="image.thumbnails.large">
                </fieldset>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AppointmentOverviewImageAdd',
        mixins: [ImageAddMixin],
        data() {
            return {
                image: null
            }
        },
        props: {
            appointment: Object,
            disabled: Boolean
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ]),
            ownerId() {
                return !!this.appointment ? this.appointment.id : '';
            },
            ownerTypeId() {
                return 5;
            }
        },
        watch: {
            saveAppointmentEvent() {
                this.tryAddImages();
            },
            appointment() {
                this.image = null;
            },
            loadingImages(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            showFileSelect() {
                this.$refs.fileInput.click();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
