<template>
    <div>
        <span v-if="loadingClientUser" class="inline-loader"></span>
        <div v-else-if="clientUser" class="mb-2">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group row">
                <div class="col-6">
                    <label>
                        {{$t('Disabled?')}}
                        <tooltip>
                            <span>{{$t('Toggle to restrict client portal access. Disable viewing appointments, contract access, invite registrations, campaign unsubscribing, and booking portal login.')}}</span>
                        </tooltip>
                    </label>
                </div>
                <div class="col-6">
                    <label class="ps-toggle-switch">
                        <input v-model="isDisabled" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2"></span>
                    </label>
                </div>
            </div>
        </div>
        <div v-else-if="!clientUser && !loadingClientUser">
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('This client has not accessed the portal.')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ClientUserUpdateMixin } from "../../Shared/ClientUserUpdateMixin";
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';
    import Tooltip from '../../Shared/Layout/Tooltip.vue';

    export default {
        name: 'ClientEditUserAccountSection',
        mixins: [ClientUserUpdateMixin, StatusEmitterMixin],
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            clientId() {
                return this.id;
            },
            status() {
                return {
                    loading: this.loadingClientUser,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                if (!!this.clientUser) {
                    this.tryUpdateClientUser();
                }
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'tooltip': Tooltip
        }
    }
</script>
