<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Client Portal Colours')}}</label>
        </div>
        <div class="col-md-8">
            <color-input v-model="mainTextColor" :title="$t('Main Text Colour')"></color-input>
            <color-input v-model="primaryButtonColor" :title="$t('Primary Button Colour')"></color-input>
            <color-input v-model="secondaryButtonColor" :title="$t('Secondary Button Colour')"></color-input>
            <color-input v-model="tertiaryButtonColor" :title="$t('Tertiary Button Colour')"></color-input>
            <color-input v-model="headerBackgroundColor" :title="$t('Header Background Colour')"></color-input>
            <color-input v-model="headerTextColor" :title="$t('Header Text Colour')"></color-input>
            <color-input v-model="footerBackgroundColor" :title="$t('Footer Background Colour')"></color-input>
            <color-input v-model="footerTextColor" :title="$t('Footer Text Colour')"></color-input>
            <button @click="resetColours" class="btn btn-primary" type="button" v-bind:disabled="loadingSetting">
                {{$t('Reset Colours')}}
            </button>
        </div>
    </div>
</template>

<script>
    import { SiteBrandingSettingUploadMixin } from "../../../mixins/Settings/Site/SiteBrandingSettingUploadMixin";
    import { mapState } from 'vuex';
    import SlottedColorInput from "../../Shared/Input/SlottedColorInput.vue";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateBrandingColours',
        mixins: [SiteBrandingSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'color-input': SlottedColorInput,
            'error-banner': ErrorBanner
        }
    }
</script>
