<template>
    <div>
        <button ref="subscriptionCheckingButton" type="button" data-target="#subscription-upgrade-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="subscription-upgrade-modal" title="Upgrade Subscription" @closed="closed">
            <section class="rounded-card-white mb-3">
                <div class="form-group row px-2">
                    <error-banner v-if="error" :message="error"></error-banner>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">Cancel</button>
                <button @click="goToPricingPlan" type="button" class="btn btn-primary">Go to Store</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../../helpers/ErrorHelper";
    import { Api } from "../../../services/Api";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import router from "../../../routing";
    import process from 'process';

    export default {
        name: 'MigrationCheckSubscription',
        mixins: [StatusEmitterMixin],
        props: {
            migrationUploadModel: Object,
            checkSubscriptionEvent: 0
        },
        data() {
            return {
                error: '',
                subscriptionAllowed: false,
                fetchingSubscriptionAllowed: false,
                fetchedSubscriptionAllowed: false
            }
        },
        computed: {
            showUpgradeModal() {
                return !!this.fetchedSubscriptionAllowed && !this.subscriptionAllowed;
            },
            status() {
                return {
                    loading: this.fetchingSubscriptionAllowed,
                    allowed: this.subscriptionAllowed,
                    error: this.error
                }
            }
        },
        watch: {
            checkSubscriptionEvent() {
                this.tryfetchSubscriptionAllowed();
            },
            showUpgradeModal(value) {
                if (!!value) {
                    this.$refs.subscriptionCheckingButton.click();
                }
            }
        },
        methods: {
            tryfetchSubscriptionAllowed() {
                const self = this;
                if (!!self.fetchingSubscriptionAllowed) {
                    return;
                }
                self.resetData();
                self.fetchingSubscriptionAllowed = true;
                Api.post('Migration/Subscription/Allowed', self.migrationUploadModel)
                    .then(response => {
                        var responseData = response.data
                        self.subscriptionAllowed = !!responseData.allowed;
                        self.error = responseData.message;
                    })
                    .catch(error => {
                        self.subscriptionAllowed = true;
                        self.error = ErrorHelper.getError(error);
                    })
                    .finally(() => {
                        self.fetchedSubscriptionAllowed = true;
                        self.fetchingSubscriptionAllowed = false;
                    })
            },
            goToPricingPlan() {
                process.nextTick(() => {
                    this.$refs.cancelButton.click();
                    router.push({ name: 'store', query: { tab: 3 } });
                });
            },
            closed() {
                this.resetData()
            },
            resetData() {
                this.fetchingSubscriptionAllowed = false;
                this.fetchedSubscriptionAllowed = false;
                this.subscriptionAllowed = false;
                this.error = false;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'modal-dialog': ModalDialog
        }
    }
</script>
