import { Api } from '../../services/Api';

const state = () => ({
    fetchingHistories: false,
    historyCaches: []
})

const getters = {
    findHistoryCache: (state) => clientId => {
        return state.historyCaches.find(hc => hc.key === clientId);
    }
}

const mutations = {
    setLoading(state, key) {
        const existing = state.historyCaches.find(hc => hc.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.historyCaches.push({ key: key, value: null, loading: true });
        }
    },
    setHistory(state, { key, history }) {
        const existing = state.historyCaches.find(hc => hc.key === key);
        if (!!existing) {
            existing.value = history;
            existing.loading = false;
        } else {
            state.historyCaches.push({ key: key, value: history, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.historyCaches.find(hc => hc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setFetching(state, fetching) {
        state.fetchingHistories = !!fetching;
    }
}

const actions = {
    getHistory({ commit }, clientId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', clientId);
            Api.get('Client/' + clientId + '/History')
                .then(response => {
                    commit('setHistory', { key: clientId, history: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', clientId);
                    reject(error);
                });
        });
    },
    searchHistories({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            keys.forEach(clientId => { commit('setLoading', clientId) });
            Api.post('Client/History/Search', payload)
                .then(response => {
                    keys.forEach(clientId => {
                        const history = response.data.find(r => r.clientId === clientId);
                        commit('setHistory', { key: clientId, history: history ? history : null });
                    });
                    resolve(response);
                })
                .catch(error => {
                    keys.forEach(clientId => { commit('setRetry', clientId) });
                    reject(error);
                })
                .finally(() => {
                    commit('setFetching', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
