<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-user-group fa-1x"></i>
                    {{$t('Users')}}
                    <small>({{employeeCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <div class="search-field-static">
                    <div class="search-field">
                        <input v-model="searchTerm" aria-label="Search" autocomplete="off" :placeholder="$t('Search user name')" spellcheck="false" type="search" class="search-field-input">
                        <button type="submit" class="search-field-btn">
                            <span v-if="searchingEmployees" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-search"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="openUserAdd" class="mobile-header-btn" v-bind:disabled="fetchingUserAddAllowed">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add user</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <button @click="openUserAdd" class="btn-add btn btn-secondary d-none d-md-block" v-bind:disabled="fetchingUserAddAllowed">
                    <i class="fas fa-plus"></i>
                    {{$t('Add user')}}
                </button>
            </div>
        </header>
        <div class="content">
            <users-mobile :search-key="searchKey" @skipTo="skipTo" @userClicked="onUserClicked"></users-mobile>
            <table-desktop :page-number="pageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <sort-header class="col-2" v-model="sortModel" key-name="firstName">{{$t('First Name')}}</sort-header>
                        <sort-header class="col-2" v-model="sortModel" :key-name="''">{{$t('Last Name')}}</sort-header>
                        <th class="col-2">{{$t('Email')}}</th>
                        <th class="col-2">{{$t('Role')}}</th>
                        <th class="col-4">{{$t('Sites')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <user-desktop v-for="(user, index) in employees" :user="user" :number="index" :key="user.id" @userClicked="onUserClicked"></user-desktop>
                </template>
            </table-desktop>
        </div>
        <user-add ref="userAdd"></user-add>
        <user-update ref="userUpdate" :id="employeeId"></user-update>
    </div>
</template>

<script>
    import router from "../../routing";
    import { Api } from "../../services/Api";
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { mapMutations } from 'vuex';
    import { HashHelper } from "../../helpers/HashHelper";
    import { EmployeeSearchMixin } from "../Shared/EmployeeSearchMixin";
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import TableDesktop from "../Shared/Layout/TableDesktop.vue";
    import TableSortableHeader from "../Shared/Layout/TableSortableHeader.vue";
    import UserAdd from "./UserAdd.vue";
    import UserItemDesktop from "./UserItemDesktop.vue";
    import UsersMobile from "./UsersMobile.vue";
    import UserUpdate from "./UserUpdate.vue";
    import { EmployeeCountCacheMixin } from "../Shared/EmployeeCountCacheMixin";

    export default {
        name: 'Users',
        mixins: [EmployeeSearchMixin, SiteCacheMixin, EmployeeCountCacheMixin],
        data() {
            return {
                employee: Object,
                userAddAllowed: false,
                fetchingUserAddAllowed: false
            }
        },
        computed: {
            employeeId() {
                return !!this.employee ? this.employee.id : '';
            },
            searchKey() {
                return HashHelper.createStringHash(this.searchTerm + this.sortKey + this.ascending) + '-';
            },
            employeeSearchKey() {
                return this.searchKey + this.skip + this.take;
            },
            employeeCountString() {
                return this.employeeCount ? this.employeeCount.count : '-';
            }
        },
        methods: {
            ...mapMutations('Employees', [
                'insertEmployee'
            ]),
            openUserAdd() {
                const self = this;

                self.fetchingUserAddAllowed = true;

                return new Promise((resolve) => {
                    Api.get('User/Allowed')
                        .then(response => {
                            self.userAddAllowed = response.data.allowed;

                            if (self.userAddAllowed) {
                                const element = this.$refs.userAdd.$el;
                                $(element).modal('show');
                            }
                            else if (self.$route.name !== 'upsell') {
                                router.push({ name: 'upsell' });
                            }

                            resolve(response);
                        })
                        .catch(error => self.error = ErrorHelper.getError(error))
                        .finally(() => self.fetchingUserAddAllowed = false);
                })
            },
            onUserClicked(user) {
                this.insertEmployee(user);
                this.employee = user;
                const element = this.$refs.userUpdate.$el;
                $(element).modal('show');
            }
        },
        components: {
            'user-add': UserAdd,
            'user-update': UserUpdate,
            'table-desktop': TableDesktop,
            'user-desktop': UserItemDesktop,
            'users-mobile': UsersMobile,
            'sort-header': TableSortableHeader
        }
    }
</script>
