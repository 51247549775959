<template>
    <div>
        <section class="rounded-card-white mb-3">
            <error-banner v-if="error" :message="error"></error-banner>
            <div v-if="sites.length > 1" class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Site')}}</label>
                </div>
                <div class="col-md-8">
                    <site-select></site-select>
                </div>
            </div>
            <resource-select v-model="resourceIds" :validator="eventValidator"></resource-select>
            <type-select v-model="eventTypeId" :validator="eventValidator"></type-select>
            <event-colour v-if="!!eventTypeId" :event-type-id="eventTypeId" v-model="colour"></event-colour>
            <all-day v-model="allDay"></all-day>
            <start-input v-model="startUtc" :all-day="allDay" :validator="eventValidator"></start-input>
            <end-input v-model="endUtc" :all-day="allDay" :validator="eventValidator"></end-input>
            <notes-input v-model="notes"></notes-input>
        </section>
        <div class="modal-footer d-flex w-100">
            <button @click="addAndCacheEvents" v-bind:disabled="addingEvent" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="addingEvent" class="inline-loader"></span>
            </button>
            <button @click="setDefault" class="btn btn-outline-secondary" data-dismiss="modal" type="button">{{$t('Cancel')}}</button>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { EventsAddMixin } from '../../Shared/Events/EventsAddMixin';
    import SiteSelect from '../../Account/SiteSelect.vue';
    import EventEndDateInput from '../ViewStaffEvent/EventEndDateInput.vue';
    import EventStartDateInput from '../ViewStaffEvent/EventStartDateInput.vue';
    import EventNotesInput from './EventNotesInput.vue';
    import EventResourceSelect from './EventResourceSelect.vue';
    import EventTypeIdSelect from '../../Shared/Events/EventTypeIdSelect.vue';
    import EventAllDayToggle from '../../Shared/Events/EventAllDayToggle.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import SlottedColorInput from '../../Shared/Input/SlottedColorInput.vue';
    import ValidationMessage from '../../ErrorValidation/ValidationMessage.vue';
    import AddStaffEventColour from './AddStaffEventColour.vue';

    export default {
        name: 'AddStaffEvent',
        mixins: [EventsAddMixin],
        data() {
            return {
                allDay: false
            }
        },
        computed: {
            ...mapState('Sites', [
                'selected',
                'sites'
            ]),
            ...mapState('Events', [
                'addingEvent'
            ])
        },
        watch: {
            selected() {
                this.clearUploadModel();
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault'
            ])
        },
        components: {
            'site-select': SiteSelect,
            'resource-select': EventResourceSelect,
            'start-input': EventStartDateInput,
            'end-input': EventEndDateInput,
            'type-select': EventTypeIdSelect,
            'event-colour': AddStaffEventColour,
            'notes-input': EventNotesInput,
            'all-day': EventAllDayToggle,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
