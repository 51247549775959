export const ReminderHelper = {
    getReminderSettingValidator() {
        return {
            isValid: false,
            hoursPrior: '',
            validate(model) {
                const hoursPrior = parseInt(model.hoursPrior);
                this.hoursPrior = isNaN(hoursPrior) || !(hoursPrior > 0) ? 'Please select a value greater than 0' : '';
                this.isValid = !this.hoursPrior;
            }
        }
    }
}
