<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Event Type')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <span v-if="fetchingEventTypes" class="inline-loader"></span>
            <div v-else class="dropdown bootstrap-select custom-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox" aria-owns="bs-select-type" aria-haspopup="listbox" aria-expanded="false">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <span class="text">
                                {{selectionString}}
                            </span>
                        </div>
                    </div>
                </button>
                <div class="dropdown-menu" style="overflow: hidden !important;">
                    <div class="inner show" role="listbox" id="bs-select-type" tabindex="-1">
                        <div class="dropdown-menu inner show" role="presentation">
                            <div v-for="eventType in eventTypes" :key="eventType.id">
                                <a @click="selection=eventType" role="option" class="dropdown-item pointer" v-bind:class=" { 'active selected': value===eventType.id }">
                                    <div>{{$t(eventType.description)}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group has-error has-feedback">
                <validation-message v-if="errorString" :message="errorString"></validation-message>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventTypesCacheMixin } from './EventTypesCacheMixin';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'EventTypeIdSelect',
        mixins: [EventTypesCacheMixin],
        props: {
            value: Number,
            validator: Object
        },
        computed: {
            selection: {
                get() {
                    return this.eventTypes.find(et => et.id === this.value);
                },
                set(value) {
                    this.$emit('input', value.id);
                }
            },
            selectionString() {
                return !!this.selection ? `${this.$t(this.selection.description)}` : `${this.$t('Please select')}...`;
            },
            errorString() {
                return !!this.validator.eventTypeId ? this.validator.eventTypeId : !!this.error ? this.error : '';
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
