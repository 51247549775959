<template>
    <li v-if="allowed">
        <a @click="navAndClose" class="pointer d-flex align-items-center">
            <i :class="iconClass"></i>
            <div class="nav-link-text d-flex align-items-center ml-1 w-100">
                {{$t(menuName)}}<span v-if="displayUpgradeBadge" class="upgrade-badge ml-2">{{$t('Upgrade')}}</span>
            </div>
        </a>
    </li>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { NavLinkMixin } from "./NavLinkMixin";

    export default {
        name: 'NavPanelExpandLink',
        mixins: [NavLinkMixin],
        props: {
            menuName: String,
            iconClass: String
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'toggleShowMobileNav'
            ]),
            navAndClose() {
                this.navigate();
                this.toggleShowMobileNav();
            }
        }
    }
</script>
