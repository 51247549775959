import { NoteUploadMixin } from "../../components/Shared/Notes/NoteUploadMixin";
import { StatusEmitterMixin } from "../Shared/StatusEmitterMixin";
import { mapState } from 'vuex';

export const PetNoteUploadMixin = {
    mixins: [NoteUploadMixin, StatusEmitterMixin],
    props: {
        petId: String
    },
    computed: {
        ...mapState('ApplicationEvents', [
            'savePetEvent'
        ]),
        ownerId() {
            return this.petId;
        },
        ownerTypeId() {
            return 3;
        },
        status() {
            return {
                loading: this.loadingNote,
                error: this.error
            }
        }
    },
    watch: {
        savePetEvent() {
            this.tryUploadNote();
        }
    }
}
