<template>
    <modal-dialog :title="$t('Opening Hours')" :sub-title="$t(subTitle)" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Hours')}}</label>
                </div>
                <div class="col-md-8">
                    <label class="ps-toggle-switch">
                        <input v-model="isOpen" class="toggle-checkbox" type="checkbox" checked="">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t(toggleText)}}</span>
                    </label>
                    <br />
                    <div v-if="isOpen" class="mw-400">
                        <div class="row">
                            <div class="col-6">
                                <label for="title">{{$t('Opening')}}</label>
                                <vue-timepicker v-model="openingLocal" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                            </div>
                            <div class="col-6">
                                <label for="title">{{$t('Closing')}}</label>
                                <vue-timepicker v-model="closingLocal" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                            </div>
                        </div>
                        <validation-message v-if="validator.openingLocal" :message="validator.openingLocal"></validation-message>
                    </div>
                </div>
            </div>
        </section>
        <button @click="save" v-bind:disabled="loadingOpeningHours" class="btn btn-primary" type="button">
            {{$t('Save')}}
            <span v-if="loadingOpeningHours" class="inline-loader"></span>
        </button>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { DaysOfWeekMixin } from '../../Shared/DaysOfWeekMixin';
    import { OpeningHoursUploadMixin } from '../../Shared/OpeningHours/OpeningHoursUploadMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

    export default {
        name: 'UploadOpeningHours',
        mixins: [OpeningHoursUploadMixin, DaysOfWeekMixin],
        computed: {
            subTitle() {
                return this.daysOfWeek[this.dayId];
            },
            toggleText() {
                return !!this.closed ? 'Closed' : 'Open';
            },
            isOpen: {
                get() {
                    return !this.closed;
                },
                set(value) {
                    this.closed = !value;
                }
            }
        },
        methods: {
            save() {
                this.tryUploadOpeningHours();
            },
            onOpened() {
                this.error = '';
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            VueTimepicker
        }
    }
</script>
