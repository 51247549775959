<template>
    <tr>
        <document-open :contract="contract"></document-open>
        <td class="text-center">{{contract.version}}</td>
        <td>
            <span v-if="loadingCommunications" class="inline-loader"></span>
            {{communicationStatus}}
        </td>
        <td class="text-center">{{$t(signatureRequired)}}</td>
        <td>{{$t(contract.status.name)}}</td>
        <td>
            <span v-if="loadingSignatures" class="inline-loader"></span>
            {{$t(contractSigned)}}
        </td>
        <td v-if="canDelete" @click="onDeleteClick" class="text-center"><i class="fal fa-trash-alt text-danger"></i></td>
        <td class="text-center">
            <button v-if="!contractCompleted" @click="onResendClick" class="btn btn-sm btn-outline" style="background-color: #fff;">
                <i class="fal fa-paper-plane"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import { CommunicationCacheMixin } from '../../Shared/CommunicationCacheMixin.js';
    import { SignatureCacheMixin } from '../../Shared/SignatureCacheMixin.js';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin.js';
    import { UserCacheMixin } from '../../Shared/UserCacheMixin.js';
    import ClientDocumentOpen from './ClientDocumentOpen.vue';
    import moment from 'moment';

    export default {
        name: 'ClientDocumentItem',
        mixins: [CommunicationCacheMixin, SignatureCacheMixin, DateDisplayMixin, UserCacheMixin],
        props: {
            contract: Object
        },
        computed: {
            clientId() {
                return !!this.contract ? this.contract.clientId : '';
            },
            communicationTypeId() {
                return 9;
            },
            contractId() {
                return !!this.contract ? this.contract.id : '';
            },
            signatureRequired() {
                return this.contract.signatureRequired ? 'Yes' : 'No';
            },
            filteredCommunications() {
                return this.communications
                    .filter(c => c.subjectId === this.contractId && c.sendStatus.id === 1)
                    .sort((a, b) => moment(b.lastUpdatedUtc) - moment(a.lastUpdatedUtc));
            },
            communicationStatus() {
                return this.filteredCommunications.length > 0 ?
                    moment(this.filteredCommunications[0].lastUpdatedUtc).format(this.momentJSFormat) :
                    'Not Sent';
            },
            contractSigned() {
                return !!this.contract.signatureRequired ?
                    !!this.signature ? moment(this.signature.signedDateUtc).format(this.momentJSFormat) : '' :
                    'N/A';
            },
            contractCompleted() {
                return this.contract.status.id === 5;
            }
        },
        watch: {
            error() {
                if (!!this.error) {
                    this.$emit('error', this.error);
                }
            }
        },
        methods: {
            onResendClick() {
                if (this.contract) {
                    this.$emit("resend", this.contract);
                }
            },
            onDeleteClick() {
                if (this.contract) {
                    this.$emit("delete", this.contract);
                }
            }
        },
        components: {
            'document-open': ClientDocumentOpen
        }
    }
</script>
