import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const CampaignTagsMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        methodTypeId: Number
    },
    computed: {
        ...mapGetters('CampaignTags', [
            'findCampaignTagsCache'
        ]),
        campaignTagsFilterModel() {
            return {
                methodTypeId: this.methodTypeId
            }
        },
        campaignTagsCache() {
            return this.findCampaignTagsCache(this.methodTypeId);
        },
        campaignTags() {
            return !!this.campaignTagsCache ? this.campaignTagsCache.values : [];
        },
        loadingCampaignTags() {
            return !!this.campaignTagsCache && this.campaignTagsCache.loading;
        }
    },
    watch: {
        methodTypeId() {
            this.refreshCampaignTags();
        }
    },
    methods: {
        ...mapActions('CampaignTags', [
            'getCampaignTags'
        ]),
        refreshCampaignTags() {
            if (!this.campaignTagsCache || this.campaignTagsCache.retry) {
                this.tryGetCampaignTags();
            }
        },
        tryGetCampaignTags() {
            const self = this;
            if (!self.methodTypeId) {
                return;
            }
            self.error = '';
            self.getCampaignTags({ key: self.methodTypeId, payload: self.campaignTagsFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCampaignTags();
    }
}
