<template>
    <tr>
        <td class="text-left">{{suggestedDate}}</td>
        <td class="text-left">{{suggestedTime}}</td>
        <td class="text-left">{{suggestedStatusDescription}}</td>
        <td class="text-left">{{bookingSuggestionCommunicationStatus}}</td>
        <td class="text-center">
            <button v-if="!!failedBookingSuggestionCommunication" @click="tryResendCommunication" class="btn btn-sm btn-outline" style="background-color: #fff;">
                <i class="fal fa-paper-plane"></i>
            </button>
        </td>
    </tr>
</template>

<script>
    import moment from 'moment';
    import { BookingSuggestionCommunicationResendMixin } from '../../mixins/BookingSuggestions/BookingSuggestionCommunicationResendMixin';
    import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';
    import { TimeDisplayMixin } from '../Shared/Time/TimeDisplayMixin';

    export default {
        name: 'BookingSuggestionsItem',
        mixins: [BookingSuggestionCommunicationResendMixin, DateDisplayMixin, TimeDisplayMixin],
        props: {
            bookingSuggestion: Object
        },
        computed: {
            subjectId() {
                return !!this.bookingSuggestion ? this.bookingSuggestion.id : '';
            },
            suggestedDate() {
                return !!this.bookingSuggestion ? moment(this.bookingSuggestion.startUtc).format(this.momentJSFormat) : "-";
            },
            suggestedTime() {
                return !!this.bookingSuggestion ? moment(this.bookingSuggestion.startUtc).format(this.timeMomentJSFormat) : "-";
            },
            suggestedStatusDescription() {
                return !!this.bookingSuggestion ? this.bookingSuggestion.status.description.toUpperCase() : "-";
            },
            bookingSuggestionCommunicationStatus() {
                return !!this.bookingSuggestionCommunication ?
                    moment(this.bookingSuggestionCommunication.lastUpdatedUtc).format(this.momentJSFormat) :
                    'Not Sent';
            }
        }
    }
</script>
