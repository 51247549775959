<template>
    <div>
        <div v-if="error" class="alert alert-danger  alert-icon" role="alert">
            <div class="alert-content">
                <p>{{error}}</p>
            </div>
            <button @click="retryCheckout">Retry</button>
        </div>
        <div v-if="display" ref="dropinContainer"></div>
        <button @click="cancelCheckout">
            {{$t('Cancel')}}
        </button>
    </div>
</template>

<script>
    import AdyenCheckout from '@adyen/adyen-web';
    import '@adyen/adyen-web/dist/adyen.css';
    import { ErrorHelper } from '../../../helpers/ErrorHelper';
    import { Api } from '../../../services/Api';
    import { mapActions } from 'vuex';

    export default {
        name: 'AdyenCheckout',
        data() {
            return {
                error: '',
                createdSession: null,
                display: true
            }
        },
        computed: {
            paymentId() {
                return this.$route.params.paymentId;
            },
            sessionId() {
                return this.$route.query.sessionId;
            },
            redirectResult() {
                return this.$route.query.redirectResult;
            },
            sessionPayload() {
                return {
                    returnUrl: window.location.href
                }
            },
            config() {
                const self = this;
                return {
                    session: !!self.createdSession ? self.createdSession : { id: self.sessionId },
                    clientKey: 'test_5Q7RVZB6E5EUZIV6BTUSJYOJM4D3ALAI',
                    environment: 'test',
                    onPaymentCompleted(result) {
                        self.completeCheckout(result);
                    },
                    onError(error) {
                        self.errorCheckout(error);
                    }
                }
            }
        },
        watch: {
            paymentId() {
                this.initialiseCheckout();
            }
        },
        methods: {
            ...mapActions('SubscriptionPayments', [
                'updateSubscriptionPayment',
                'cancelSubscriptionPayment'
            ]),
            initialiseCheckout() {
                if (!!this.paymentId) {
                    if (!!this.sessionId) {
                        this.finalizeCheckout();
                    } else {
                        this.createCheckout();
                    }
                }
            },
            createCheckout() {
                const self = this;
                self.error = '';
                Api.post('Adyen/SubscriptionPayment/' + self.paymentId + '/Session', this.sessionPayload)
                    .then(response => {
                        self.createdSession = response.data;
                        self.display = true;
                        AdyenCheckout(self.config)
                            .then(checkout => checkout.create('dropin').mount(self.$refs.dropinContainer))
                            .catch(error => console.log(error));
                    })
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            finalizeCheckout() {
                const self = this;
                AdyenCheckout(self.config)
                    .then(checkout => checkout.submitDetails({ details: self.redirectResult }))
                    .catch(error => console.log(error));
            },
            retryCheckout() {
                this.display = false;
                this.createCheckout();
            },
            completeCheckout(result) {
                if (result.resultCode === 'Refused') {
                    this.error = 'Payment was refused. Please try again with a different method or card.'
                } else if (result.resultCode === 'Error') {
                    this.error = 'Payment processing failed. Please try again.'
                } else {
                    const self = this;
                    self.error = '';
                    self.updateSubscriptionPayment({
                        paymentId: self.paymentId, payload: {
                            sessionId: !!self.createdSession ? self.createdSession.id : self.sessionId,
                            sessionResult: result.sessionResult
                        }
                    })
                        .then(() => self.$emit('completed'))
                        .catch(error => self.error = ErrorHelper.getError(error));
                }
            },
            errorCheckout(error) {
                if (error.name === 'NETWORK_ERROR') {
                    this.error = 'A network error occurred. Please try again.'
                } else if (error.name === 'CANCEL') {
                    this.cancelCheckout();
                } else {
                    this.error = 'Something went wrong. Please try again, perhaps with a different payment method.'
                }
            },
            cancelCheckout() {
                const self = this;
                self.error = '';
                self.cancelSubscriptionPayment(self.paymentId)
                    .then(() => self.$emit('cancelled'))
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        mounted() {
            this.initialiseCheckout();
        }
    }
</script>
