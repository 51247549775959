import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { OrderAddMixin } from "./OrderAddMixin";

export const OrderUploadMixin = {
    mixins: [OrderAddMixin],
    computed: {
        order() {
            return this.orders.length > 0 ? this.orders[0] : null;
        },
        paymentMethod() {
            return !!this.order ? this.order.paymentMethod : null;
        },
        orderUpdateModel() {
            return Object.assign({}, this.order, {
                orderItems: [...this.orderItems],
                paymentMethod: Object.assign({}, this.paymentMethod, {
                    id: this.paymentMethodId
                })
            });
        },
        orderUpdateModelValid() {
            const model = this.orderUpdateModel;
            return !!model.appointmentId && !!model.paymentMethod && this.orderValidator.isValid;
        },
        orderUpdateModelModified() {
            return JSON.stringify(this.orderUpdateModel) !== JSON.stringify(this.order);
        }
    },
    watch: {
        order() {
            this.refreshOrderModel();
        }
    },
    methods: {
        ...mapActions('Orders', [
            'updateOrder'
        ]),
        refreshOrderModel() {
            this.paymentMethodId = !!this.order ? this.order.paymentMethod.id : 0;
            this.orderItems = [];
            if (!!this.order) {
                for (var i = 0; i < this.order.orderItems.length; i++) {
                    this.orderItems.push(Object.assign({}, this.order.orderItems[i]));
                }
            }
        },
        tryUpdateOrder() {
            const self = this;
            self.orderValidator.check(self.orderUpdateModel);
            if (!self.orderUpdateModelValid || !self.orderUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateOrder({ key: self.appointmentId, payload: self.orderUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOrderModel();
    }
}
