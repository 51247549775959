import { Api } from "../../services/Api";

const state = () => ({
    fetchedSubscriptionUpgradeSettings: false,
    fetchingSubscriptionUpgradeSettings: false,
    subscriptionUpgradeSettings: []
});

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSubscriptionUpgradeSettings = !!fetching;
        state.fetchedSubscriptionUpgradeSettings = false;
    },
    setSubscriptionUpgradeSettings(state, settings) {
        state.subscriptionUpgradeSettings = settings;
        state.fetchedSubscriptionUpgradeSettings = true;
        state.fetchingSubscriptionUpgradeSettings = false;
    }
}

const actions = {
    getSubscriptionUpgradeSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Subscription/Upgrade/Setting')
                .then(response => {
                    commit('setSubscriptionUpgradeSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
