<template>
    <div class="col">
        <div class="d-flex align-items-center justify-content-center">
            <div class="credits-display">
                <i class="fa-duotone fa-bullhorn fa-1x"></i>
                <div class="ml-2">{{$t('Current Credits:')}} <span class="credits-display-value">{{ currentCredits }}</span></div>
            </div>
        </div>
        <pack-modify v-if="creditPack" :config="config"></pack-modify>
        <pack-select v-else :config="config" @idSelected="onIdSelected"></pack-select>
        <page-loader v-if="loadingCreditPacks"></page-loader>
    </div>
</template>

<script>
    import { CampaignCreditPackAddMixin } from "../../mixins/CreditPacks/CampaignCreditPackAddMixin";
    import { CreditPacksCacheMixin } from "../../mixins/CreditPacks/CreditPacksCacheMixin";
    import { CampaignSettingCacheMixin } from "../Communication/Settings/CampaignSettingCacheMixin";
    import CreditPackModify from "../CreditPacks/CreditPackModify.vue";
    import CreditPackSelect from "../CreditPacks/CreditPackSelect.vue";
    import PageLoader from "../Shared/Loading/PageLoader.vue";

    export default {
        name: 'CampaignPack',
        mixins: [CreditPacksCacheMixin, CampaignSettingCacheMixin, CampaignCreditPackAddMixin],
        computed: {
            creditTypeId() {
                return 2;
            },
            config() {
                return {
                    creditTypeId: this.creditTypeId,
                    creditTypeDescription: 'Campaign',
                    paymentCompletedRouteName: 'campaignPackComplete',
                    iconClass: 'fa-bullhorn',
                    computedFeatureKey: 'buyCampaigns'
                }
            },
            creditPack() {
                return this.creditPacks[0];
            },
            campaignSetting() {
                return this.campaignSettings.find(s => s.siteId === this.siteId);
            },
            currentCredits() {
                return !!this.campaignSetting ? this.campaignSetting.remainingCredits : 0;
            }
        },
        methods: {
            onIdSelected(id) {
                this.packId = id;
                this.tryAddCampaignCreditPack();
            }
        },
        components: {
            'pack-select': CreditPackSelect,
            'pack-modify': CreditPackModify,
            'page-loader': PageLoader
        }
    }
</script>
