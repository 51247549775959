<template>
    <span v-if="showAlert" class="badge badge-warning">!</span>
</template>

<script>
    import { ClientAlertMixin } from '../Shared/ClientAlertMixin.js';

    export default {
        name: 'ClientAlertBadge',
        mixins: [ClientAlertMixin]
    }
</script>
