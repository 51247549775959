<template>
    <popper trigger="clickToToggle"
            :visible-arrow="false"
            :options="{ placement: 'top' }">
        <i slot="reference" class="fas fa-star custom-bounce pointer"></i>
        <div class="popper">
            <span>{{$t('Client first appointment')}}</span>
        </div>
    </popper>
</template>

<script>
    import Popper from 'vue-popperjs';

    export default {
        name:'AppointmentOverviewFirstTimeTooltip',
        components: {
            'popper': Popper
        }
    }
</script>

<style scoped>
    .custom-bounce {
        font-size: 1rem;
        color: #00bfff;
        margin-left: 0.25rem;
        display: inline-block;
        animation: bounce 2s ease-in-out 1; /* 5 times */
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }

        20%, 40%, 60%, 80% {
            transform: translateY(-10px);
        }

        30%, 50%, 70%, 90% {
            transform: translateY(0);
        }
    }
</style>