<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Preference')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select custom-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Select staff">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{selectedName}}</div>
                        </div>
                    </div>
                </button>
                <div class="dropdown-menu ">
                    <div class="bs-searchbox">
                        <input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list">
                    </div>
                    <div class="inner show" role="listbox" id="bs-select-3" tabindex="-1">
                        <ul class="dropdown-menu inner show" role="presentation">
                            <li v-for="resource in resources" :key="resource.id">
                                <a @click="selectedResource=resource" role="option" class="dropdown-item" v-bind:class="{ 'active selected': resource.id===selectedId }" id="bs-select-1-0">
                                    {{resource.name}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { PetDetailsPreferrredMixin } from "../PetDetailsPreferrredMixin";

    export default {
        name: 'PetAddDetailsPreferred',
        mixins: [PetDetailsPreferrredMixin],
        data() {
            return {
                addedPetId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'petAddedEvent'
            ]),
            ownerId() {
                return this.addedPetId;
            }
        },
        watch: {
            petAddedEvent(pet) {
                this.addedPetId = pet.id;
                this.tryAddSetting();
                this.setDefault();
            }
        },
        methods: {
            setDefault() {
                this.addedPetId = '';
                this.id = '';
            }
        }
    }
</script>
