import { mapActions, mapState, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const PetBreedsSearchMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Breeds', [
            'searchingBreeds'
        ]),
        ...mapGetters('Breeds', [
            'findBreedCache'
        ]),
        breedIds() {
            console.log('!!!OVERRIDE COMPUTED - breedIds!!!');
            return [];
        },
        missingPetBreedIds() {
            return this.breedIds.filter(id => !this.findBreedCache(id));
        },
        breedSearchModel() {
            return {
                breedIds: this.missingPetBreedIds
            }
        },
        breedSearchModelValid() {
            return this.breedSearchModel.breedIds.length > 0;
        }
    },
    watch: {
        breedSearchModel() {
            this.trySearchPetBreeds();
        }
    },
    methods: {
        ...mapActions('Breeds', [
            'searchBreeds'
        ]),
        trySearchPetBreeds() {
            const self = this;
            if (!self.breedSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchBreeds({ keys: self.missingPetBreedIds, payload: self.breedSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.trySearchPetBreeds();
    }
}
