import moment from 'moment';

export const AppointmentHelper = {
    // we use a duration, so this probably isn't needed
    getAppointmentValidator() {
        return {
            isValid: false,
            startUtc: '',
            check(model) {
                const start = moment(model.startUtc);
                const end = moment(model.endUtc);
                this.startUtc = start < end ? '' : 'Start time must be before end.';
                this.isValid = !this.startUtc;
            }
        }
    },
    getAppointmentDepositValidator() {
        return {
            isValid: false,
            amount: '',
            check(model) {
                this.amount = isNaN(parseFloat(model.amount)) || model.amount < 0 ? 'Amount cannot be less than 0.' : '';
                this.isValid = !this.amount;
            }
        }
    }
}
