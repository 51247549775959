<template>
    <div class="row form-group">
        <div class="col-md-3 px-0 pl-3">
            <label>{{$t(noteDescription)}}</label>
        </div>
        <div class="col-md-9 appointment-overview-pet-note-text">
            <textarea cols="50" :placeholder="$t('Note not available')" rows="3" class="form-control" v-model="noteText" disabled></textarea>
        </div>
    </div>
</template>

<script>
    import { NoteCacheMixin } from "../../Shared/Notes/NoteCacheMixin";

    export default {
        name: 'AppointmentOverviewPetNote',
        mixins: [NoteCacheMixin],
        props: {
            id: String,
            typeId: Number
        },
        computed: {
            ownerId() {
                return this.id;
            },
            ownerTypeId() {
                return 3;
            },
            noteTypeId() {
                return this.typeId;
            },
            note() {
                return this.notes.find(n => n.noteType.id === this.noteTypeId);
            },
            noteText() {
                return !!this.note ? this.note.text : '';
            },
            noteDescription() {
                switch (this.typeId) {
                    case 1:
                        return 'General Notes';
                    case 2:
                        return 'Cut Notes';
                    case 3:
                        return 'Medical Notes';
                    default:
                        return 'Notes';
                }
            }
        }
    }
</script>
