import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { TimeFormatSettingCacheMixin } from "./TimeFormatSettingCacheMixin";

export const TimeFormatSettingAddMixin = {
    mixins: [TimeFormatSettingCacheMixin],
    data() {
        return {
            timeFormatId: 0
        }
    },
    computed: {
        timeFormatSettingAddModel() {
            return {
                timeFormatId: this.timeFormatId
            }
        },
        timeFormatSettingAddModelValid() {
            return this.timeFormatSettingAddModel.timeFormatId > -1;
        }
    },
    methods: {
        ...mapActions('TimeFormatSettings', [
            'addTimeFormatSetting'
        ]),
        tryAddTimeFormatSetting() {
            const self = this;
            if (!self.timeFormatSettingAddModelValid) {
                return;
            }
            self.error = '';
            self.addTimeFormatSetting({ key: self.siteId, payload: self.timeFormatSettingAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
