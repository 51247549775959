import { CommunicationResendMixin } from "../../components/Shared/CommunicationResendMixin";
import { ClientMessageAddMixin } from "./ClientMessageAddMixin";

export const ClientMessageCommunicationMixin = {
    mixins: [ClientMessageAddMixin, CommunicationResendMixin],
    data() {
        return {
            addedClientMessage: null
        }
    },
    computed: {
        communicationTypeId() {
            return 12;
        },
        subjectId() {
            return !!this.addedClientMessage ? this.addedClientMessage.id : '';
        },
        loading() {
            return this.loadingClientMessages || this.loadingCommunications;
        }
    },
    methods: {
        trySendClientMessage() {
            if (this.failedCommunication) {
                this.tryResendCommunication();
            } else {
                this.addedClientMessage = null;
                this.tryAddClientMessage();
            }
        },
        afterAddingClientMessage(clientMessage) {
            this.addedClientMessage = clientMessage;
            this.trySendCommunication();
        },
        afterSendingCommunication(communication) {
            if (!!communication.failureReason) {
                this.error = communication.failureReason;
            } else {
                this.refreshClientMessageModel();
            }
        }
    }
}
