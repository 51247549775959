import { Api } from '../../services/Api';

const state = () => ({
    noteCaches: [],
    searchingNotes: false
})

const getters = {
    findNoteCache: (state) => key => state.noteCaches.find(nc => nc.key === key)
}

const mutations = {
    setNotes(state, { key, notes }) {
        const existing = state.noteCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values = notes;
            existing.loading = false;
        } else {
            state.noteCaches.push({ key: key, values: notes, loading: false });
        }
    },
    setAllNotes(state, notes) {
        for (var i = 0; i < notes.length; i++) {
            const note = notes[i];
            const existing = state.noteCaches.find(nc => nc.key === note.ownerId + note.ownerType.id);
            if (!!existing) {
                const existingNote = existing.values.find(n => n.id === note.id);
                if (existingNote) {
                    existing.values.splice(existing.values.indexOf(existingNote), 1, note);
                }
                else {
                    existing.values.push(note);
                }
                existing.loading = false;
            } else {
                state.noteCaches.push({ key: note.ownerId + note.ownerType.id, values: [note], loading: false });
            }
        }
    },
    appendNote(state, { key, note }) {
        const existing = state.noteCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.values.push(note);
        } else {
            state.noteCaches.push({ key: key, values: [note], loading: false });
        }
    },
    insertNote(state, { key, note }) {
        const existing = state.noteCaches.find(cc => cc.key === key);
        if (!!existing) {
            const old = existing.values.find(n => n.id === note.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, note);
            } else {
                existing.values.push(note);
            }
        } else {
            state.noteCaches.push({ key: key, values: [note], loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.noteCaches.find(cc => cc.key === key);
        if (existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setLoading(state, { key, loading }) {
        var existing = state.noteCaches.find(cc => cc.key === key);
        if (existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.noteCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setAllNotesLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.noteCaches.find(nc => nc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.noteCaches.push({ key: key, values: [], loading: !!loading });
            }
        }
    },
    setSearchingNotes(state, searching) {
        state.searchingNotes = !!searching;
    }
}

const actions = {
    searchNotes({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Note/Search', payload)
                .then(response => {
                    commit('setNotes', { key: key, notes: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    searchAllNotes({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setSearchingNotes', true);
            commit('setAllNotesLoading', { keys: keys, loading: true });
            Api.post('Note/Search', payload)
                .then(response => {
                    commit('setAllNotes', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => {
                    commit('setSearchingNotes', false);
                    commit('setAllNotesLoading', { keys: keys, loading: false })
                });
        });
    },
    addNote({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Note', payload)
                .then(response => {
                    commit('appendNote', { key: key, note: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false}));
        });
    },
    updateNote({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Note', payload)
                .then(response => {
                    commit('insertNote', { key: key, note: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
