<template>
    <p class="w-100 d-flex justify-content-end">{{$t('Message length')}}: {{messageLength}}</p>
</template>

<script>
    export default {
        name: 'SmsCharacterCountDisplay',
        props: {
            message: ''
        },
        computed: {
            messageLength() {
                if (!this.message) return 0;

                // GSM 7-bit character set
                const gsm7bitCharset =
                    `@Δ £_$¥èéùìòÇ\nØø\rÅå
                    ΔΦΓΛΩΠΨΣΘΞ
                    SP!"#¤%&'()*+,-./
                    0123456789:;<=>?
                    ¡¿A BCDEFGHIJKLMNOPQRSTUVWXYZa bcdefghijklmnopqrstuvwxyzäöñüàÄÖÑÜ§
                    \x1b`;

                let length = 0;

                for (let char of this.message) {
                    if (gsm7bitCharset.includes(char)) {
                        length += 1;
                    } else {
                        length += (char.length * 2);
                    }
                }

                return length;
            }
        }
    }
</script>
