<template>
    <div class="fc-event-title fc-sticky pointer">
        <appointment v-if="appointment" :appointment="appointment"></appointment>
        <staff-event v-else-if="staffEvent" :staff-event="staffEvent"></staff-event>
    </div>
</template>

<script>
    import CalendarAppointment from "./CalendarAppointment.vue";
    import CalendarStaffEvent from "./CalendarStaffEvent.vue";

    export default {
        name: 'CalendarItem',
        props: {
            event: Object
        },
        computed: {
            appointment() {
                return this.event.extendedProps.appointment;
            },
            staffEvent() {
                return this.event.extendedProps.staffEvent;
            }
        },
        components: {
            'appointment': CalendarAppointment,
            'staff-event': CalendarStaffEvent
        }
    }
</script>
