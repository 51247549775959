import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper.js';

export const AppointmentSearchMixin = {
    data() {
        return {
            error: '',
            takeAppointments: 10
        }
    },
    computed: {
        ...mapState('AppointmentSearches', [
            'appointmentSearchesCleared'
        ]),
        ...mapGetters('AppointmentSearches', [
            'findAppointmentSearch'
        ]),
        searchModel() {
            console.log('OVERRIDE COMPUTED = searchModel')
            return {};
        },
        searchModelValid() {
            return !!this.searchModel
                && !!this.searchModel.startUtc
                && !!this.searchModel.endUtc
        },
        appointmentSearchCacheKey() {
            return CacheHelper.fullObjectCacheKey(this.searchModel);
        },
        appointmentCache() {
            return this.findAppointmentSearch(this.appointmentSearchCacheKey);
        },
        loadingAppointments() {
            return this.appointmentCache && this.appointmentCache.loading;
        },
        appointments() {
            return this.appointmentCache ? this.appointmentCache.value.appointments : [];
        },
        more() {
            return this.appointmentCache && this.appointmentCache.value.more;
        }
    },
    watch: {
        searchModel() {
            this.refreshAppointments();
        },
        appointmentSearchesCleared() {
            this.refreshAppointments();
        }
    },
    methods: {
        ...mapActions('AppointmentSearches', [
            'searchAppointments'
        ]),
        getStatusClass(appointment) {
            if (appointment.status.id === 0) {
                return 'far fa-book text-info';
            } else if (appointment.status.id === 1) {
                return 'far fa-times';
            } else if (appointment.status.id === 2) {
                return 'far fa-check text-success';
            }
            return 'far fa-exclamation-circle text-danger';
        },
        refreshAppointments() {
            if (!this.appointmentCache || !!this.appointmentCache.retry) {
                this.searchAndCacheAppointments();
            }
        },
        searchAndCacheAppointments() {
            const self = this;
            if (!self.searchModelValid) {
                return;
            }
            this.error = '';
            self.searchAppointments({
                key: self.appointmentSearchCacheKey,
                payload: Object.assign({ skip: self.appointments.length, take: self.takeAppointments }, self.searchModel)
            }).catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointments();
    }
}
