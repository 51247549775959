import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    emailMarketingTemplates: []
})

const getters = {
    findEmailMarketingTemplateCache: (state) => key => state.emailMarketingTemplates.find(x => x.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.emailMarketingTemplates.push({ key: key, value: null, loading: !!loading });
        }
    },
    setEmailMarketingTemplate(state, { key, template }) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            const index = state.emailMarketingTemplates.indexOf(existing);
            state.emailMarketingTemplates.splice(index, 1, { key: key, value: template, loading: false, fetched: true });
        } else {
            state.emailMarketingTemplates.push({ key: key, value: template, loading: false, fetched: true});
        }
    },
    setRetry(state, key) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendEmailMarketingTemplate(state, { key, template }) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.value = template;
        } else {
            state.emailMarketingTemplates.push({ key: key, value: template, loading: false });
        }
    },
    insertEmailMarketingTemplate(state, { key, template }) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.value = template;
        } else {
            state.emailMarketingTemplates.push({ key: key, value: template, loading: false });
        }
    },
    removeEmailMarketingTemplate(state, key) {
        const existing = state.emailMarketingTemplates.find(x => x.key === key);

        if (!!existing) {
            existing.value = null;
        }
    }
}

const actions = {
    findEmailMarketingTemplate({ commit }, { key, communicationTypeId }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });

            Api.get('/CommunicationType/' + communicationTypeId + '/EmailMarketingTemplate')
                .then(response => {
                    commit('setEmailMarketingTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setEmailMarketingTemplate', { key: key, template: null });
                        resolve(false);
                    } else {
                        commit('setRetry', communicationTypeId);
                        reject(error);
                    }
                })
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addEmailMarketingTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });

            Api.post('EmailMarketingTemplate', payload)
                .then(response => {
                    commit('appendEmailMarketingTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateEmailMarketingTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });

            Api.put('EmailMarketingTemplate', payload)
                .then(response => {
                    commit('insertEmailMarketingTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    deleteEmailMarketingTemplate({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });

            Api.delete('EmailMarketingTemplate/' + id)
                .then(response => {
                    commit('removeEmailMarketingTemplate', key);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
