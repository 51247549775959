<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{$t('Method')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light" v-bind:disabled="disabled">
                    {{$t(selectedOptionName)}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setMethodType(option.value)"
                            v-bind:class="{'active': option.value === selectionOptionValue}">
                            {{$t(option.name)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CampaignMethodSelect',
        props: {
            value: Number,
            disabled: Boolean
        },
        data() {
            return {
                options: [
                    { value: 1, name: 'Email' },
                    { value: 2, name: 'SMS' }
                ],
                methodTypeId: Number
            }
        },
        watch: {
            value(value) {
                this.setMethodType(value);
            }
        },
        computed: {
            selectedOption() {
                return this.options.find(o => o.value === this.methodTypeId);
            },
            selectionOptionValue() {
                return !!this.selectedOption ? this.selectedOption.value : 0;
            },
            selectedOptionName() {
                return !!this.selectedOption ? this.selectedOption.name : '';
            }
        },
        methods: {
            setMethodType(value) {
                this.methodTypeId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setMethodType(this.value);
        }
    }
</script>
