<template>
    <section class="mb-3">
        <div class="card card-body dashboard-card">
            <h5 class="card-title">
                {{$t('Most Popular Breeds')}}
            </h5>
            <table class="display dataTable no-footer card-content">
                <tbody>
                    <tr v-for="(aggregate, index) in breedAggregates" :key="aggregate.breedId" v-bind:class="index % 2 === 0 ? 'even' : 'odd'">
                        <td>
                            {{aggregate.breedName}}
                        </td>
                        <td style="text-align: right">
                            {{aggregate.count}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
    import { BreedAggregateSearchMixin } from "../Shared/Aggregates/BreedAggregateSearchMixin";

    export default {
        name: 'BreedAggregateCount',
        mixins: [BreedAggregateSearchMixin]
    }
</script>
