import { Api } from "../../services/Api";

const state = () => ({
    smsDefaults: []
})

const getters = {
    findSmsDefaultCache: (state) => key => state.smsDefaults.find(sdc => sdc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.smsDefaults.find(sdc => sdc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.smsDefaults.push({ key: key, value: null, loading: !!loading });
        }
    },
    setTemplate(state, { key, template }) {
        const existing = state.smsDefaults.find(sdc => sdc.key === key);
        if (!!existing) {
            existing.value = template;
            existing.loading = false;
        } else {
            state.smsDefaults.push({ key: key, value: template, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.smsDefaults.find(sdc => sdc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findSmsDefault({ commit }, communicationTypeId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: communicationTypeId, loading: true });
            Api.get('CommunicationType/' + communicationTypeId + '/SmsDefault')
                .then(response => {
                    commit('setTemplate', { key: communicationTypeId, template: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', communicationTypeId);
                    reject(error);
                })
                .finally(() => commit('setLoading', { key: communicationTypeId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
