import { Api } from "../../services/Api";

const state = () => ({
    paymentUserCaches: []
})

const getters = {
    findPaymentUserCache: (state) => key => state.paymentUserCaches.find(puc => puc.key === key)
}

const mutations = {
    setPromise(state, { key, promise }) {
        const existing = state.paymentUserCaches.find(puc => puc.key === key);
        if (!!existing) {
            existing.promise = promise;
        } else {
            state.paymentUserCaches.push({ key: key, value: null, promise: promise });
        }
    },
    setPaymentUser(state, { key, paymentUser }) {
        const existing = state.paymentUserCaches.find(puc => puc.key === key);
        if (!!existing) {
            existing.value = paymentUser;
            existing.promise = null;
        } else {
            state.paymentUserCaches.push({ key: key, value: paymentUser, promise: null });
        }
    }
}

const actions = {
    addPaymentUser({ state, commit }, { userId, payload }) {
        const existing = state.paymentUserCaches.find(puc => puc.key === userId);
        if (!!existing && existing.promise) {
            return existing.promise;
        }
        const promise = new Promise((resolve, reject) => {
            Api.post('PaymentUser', payload)
                .then(response => {
                    commit('setPaymentUser', { key: userId, paymentUser: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setPaymentUser', { key: userId, paymentUser: null });
                    reject(error);
                });
        });
        commit('setPromise', { key: userId, promise: promise });
        return promise;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
