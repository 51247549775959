import { Api } from '../../services/Api';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import router from '../../routing';

export const ClientAddAllowedMixin = {
    data() {
        return {
            clientAddAllowed: false,
            fetchingClientAddAllowed: false
        }
    },
    methods: {
        gotoAddClient() {
            const self = this;

            self.fetchingClientAddAllowed = true;

            return new Promise((resolve) => {
                Api.get('Client/Allowed')
                    .then(response => {
                        self.clientAddAllowed = response.data.allowed;

                        if (self.clientAddAllowed) {
                            router.push({ name: 'clientAdd' });
                        }
                        else if (self.$route.name !== 'upsell') {
                            router.push({ name: 'upsell' });
                        }

                        resolve(response);
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.fetchingClientAddAllowed = false);
            })
        }
    }
}