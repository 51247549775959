import { CampaignHelper } from "../../../helpers/CampaignHelper";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { CampaignsCacheMixin } from "./CampaignsCacheMixin";
import { mapActions } from 'vuex';

export const CampaignUpdateMixin = {
    mixins: [CampaignsCacheMixin],
    data() {
        return {
            subject: '',
            siteIds: [],
            tagTypeIds: [],
            breedIds: [],
            notVisitedSinceUtc: null,
            sendDateUtc: null,
            confirmed: false,
            campaignValidator: CampaignHelper.getCampaignUpdateValidator()
        }
    },
    computed: {
        campaignId() {
            console.log('!!!OVERRIDE COMPUTED - campaignId!!!');
        },
        campaign() {
            return this.campaigns.find(c => c.id === this.campaignId);
        },
        campaignUpdateModel() {
            return !!this.campaign ? Object.assign({}, this.campaign, {
                subject: this.subject,
                siteIds: this.siteIds,
                tagTypeIds: this.tagTypeIds,
                breedIds: this.breedIds,
                notVisitedSinceUtc: this.notVisitedSinceUtc,
                sendDateUtc: this.sendDateUtc,
                confirmed: this.confirmed
            }) : {};
        },
        campaignUpdateModelValid() {
            return this.campaignValidator.isValid && this.siteId;
        },
        campaignUpdateModelModified() {
            return JSON.stringify(this.campaignUpdateModel) !== JSON.stringify(this.campaign);
        }
    },
    watch: {
        campaign() {
            this.refreshCampaignModel();
        }
    },
    methods: {
        ...mapActions('Campaigns', [
            'updateCampaign',
            'deleteCampaign'
        ]),
        refreshCampaignModel() {
            this.subject = !!this.campaign ? this.campaign.subject : '';
            this.siteIds = !!this.campaign ? this.campaign.siteIds : [];
            this.tagTypeIds = !!this.campaign ? this.campaign.tagTypeIds : [];
            this.breedIds = !!this.campaign ? this.campaign.breedIds : [];
            this.notVisitedSinceUtc = !!this.campaign ? this.campaign.notVisitedSinceUtc : null;
            this.sendDateUtc = !!this.campaign ? this.campaign.sendDateUtc : null;
            this.confirmed = !!this.campaign ? this.campaign.confirmed : false;
        },
        tryUpdateCampaign() {
            const self = this;
            self.campaignValidator.check(self.campaignUpdateModel);
            if (!self.campaignUpdateModelValid || !self.campaignUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateCampaign({ key: self.siteId, payload: self.campaignUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryForceUpdateCampaign() {
            const self = this;
            self.campaignValidator.check(self.campaignUpdateModel);
            if (!self.campaignUpdateModelValid) {
                return;
            }
            self.error = '';
            self.updateCampaign({ key: self.siteId, payload: self.campaignUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        tryDeleteCampaign() {
            const self = this;
            if (!self.campaign) {
                return;
            }
            self.error = '';
            self.deleteCampaign({ key: self.siteId, id: self.campaign.id })
                .then(() => self.afterCampaignDelete())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterCampaignDelete() {
            // for overriding
        }
    },
    created() {
        this.refreshCampaignModel();
    }
}
