<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-shopping-cart fa-1x"></i>
                    {{$t('Purchase History')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <!--placeholder to occupy header-tools area-->
            </div>
        </header>
        <div class="content">
            <!-- Table shows for mobile, desktop has own view -->
            <purchases-mobile :search-key="searchKey" @skipTo="skipTo"></purchases-mobile>
            <!-- Table shows for desktop, mobile has own view -->
            <table-desktop :page-number="subscriptionPaymentPageNumber" @next="next" @previous="previous" ss>
                <template #header>
                    <tr role="row">
                        <th class="col-4">{{$t('Product')}}</th>
                        <th class="col-2 text-center">{{$t('Date')}}</th>
                        <th class="col-2 text-center">{{$t('Site')}}</th>
                        <th class="col-2 text-right">{{$t('Amount')}}</th>
                        <th class="col-2 text-right"></th>
                    </tr>
                </template>
                <template #rows>
                    <purchases-desk v-for="(subscriptionPayment, index) in subscriptionPayments" :key="subscriptionPayment.id" :subscription-payment="subscriptionPayment" :number="index"></purchases-desk>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import { HashHelper } from '../../helpers/HashHelper';
    import { SubscriptionPaymentSearchMixin } from '../Shared/SubscriptionPaymentSearchMixin';
    import { CreditPackSearchMixin } from '../../mixins/CreditPacks/CreditPackSearchMixin';
    import { PaymentReceiptSearchMixin } from '../../mixins/PaymentReceipts/PaymentReceiptSearchMixin';
    import { SiteCacheMixin } from '../Shared/SiteCacheMixin';
    import PurchaseHistoryMobile from './PurchaseHistoryMobile.vue';
    import PurchaseHistoryItemDesktop from './PurchaseHistoryItemDesktop.vue';
    import TableDesktop from '../Shared/Layout/TableDesktop.vue';

    export default {
        name: 'PurchaseHistory',
        mixins: [SubscriptionPaymentSearchMixin, CreditPackSearchMixin, PaymentReceiptSearchMixin, SiteCacheMixin],
        computed: {
            searchKey() {
                return HashHelper.createStringHash(this.searchTerm + this.sortKey + this.ascending) + '-';
            },
            subscriptionPaymentSearchKey() {
                return this.searchKey + this.skip + this.take;
            },
            creditPackIds() {
                return !!this.subscriptionPayments ? this.subscriptionPayments.map(sp => sp.subscriptionEntityId) : [];                
            },
            subscriptionPaymentIds() {
                return !!this.subscriptionPayments ? this.subscriptionPayments.map(sp => sp.id) : [];
            }
        },
        created() {
            this.refreshSubscriptionPaymentSearch();
        },
        components: {
            'purchases-mobile': PurchaseHistoryMobile,
            'purchases-desk': PurchaseHistoryItemDesktop,
            'table-desktop': TableDesktop,
        }
    }
</script>
