import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SiteIdMixin } from "../Shared/Site/SiteIdMixin"

export const ReportAddMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: '',
            reportTypeId: 0,
            siteIds: [],
            resourceIds: [],
            tagTypeIds: [],
            timePeriod: null
        }
    },
    computed: {
        ...mapState('Reports', [
            'addingReport'
        ]),
        reportAddModel() {
            return {
                reportTypeId: this.reportTypeId,
                siteIds: this.siteIds,
                resourceIds: this.resourceIds,
                tagTypeIds: this.tagTypeIds,
                startLocal: !!this.timePeriod ? this.timePeriod.startLocal : '',
                endLocal: !!this.timePeriod ? this.timePeriod.endLocal : ''
            }
        },
        reportAddModelValid() {
            const model = this.reportAddModel;
            return !!model.reportTypeId && model.siteIds.length > 0 && !!model.startLocal && !!model.endLocal;
        }
    },
    methods: {
        ...mapActions('Reports', [
            'addReport'
        ]),
        tryAddReport() {
            const self = this;
            if (!self.reportAddModelValid) {
                return;
            }
            self.error = '';
            self.addReport(self.reportAddModel)
                .then(() => self.afterReportAdded())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterReportAdded() {
            //for overriding
        }
    },
    created() {
        this.siteIds = [this.siteId]
    }
}
