/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';

export const BookingApproveMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'approveBooking'
        ]),
        tryApproveBooking() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.approveBooking(self.bookingId)
                .then(response => self.afterApproveBooking(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterApproveBooking() {
            //override if necessary
        }
    }
}
