<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-cards-blank fa-1x"></i>
                    Migration
                </h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <!--placeholder to occupy header-tools area-->
            </div>
        </header>
        <page-loader v-if="fetchingAccountMigrationAllowed"></page-loader>
        <div class="content" v-else-if="migrationAllowed">
            <div class="form-group">
                <site-select></site-select>
            </div>
            <error-banner v-if="errors" :message="errors"></error-banner>
            <error-banner v-if="migrationError" :message="migrationError"></error-banner>
            <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                <div class="alert-content">
                    <p>Migration completed!</p>
                </div>
            </div>
            <template v-else>
                <div class="alert alert-warning  alert-icon" role="alert">
                    <div class="alert-content">
                        <p class="mb-0"><b>Important Information!</b></p>
                        <p>Please log in using your classic login details below. Any information you have already entered into the new system will be deleted, overwritten, and replaced with data from your classic system. Please read our FAQs for changes by clicking <a href="https://shakeyourtail.com/migration.html" target="_blank" alt="Migration FAQ">here</a>.</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Email</label>
                    <input v-model="email" type="email" class="form-control">
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input v-model="password" type="password" class="form-control">
                </div>
                <div class="form-group">
                    <button @click="migrateClicked" v-bind:disabled="loading || !migrationUploadModelValid" class="btn btn-primary">
                        Migrate
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
                <div class="form-group">
                    {{status}}
                </div>
            </template>
            <migration-instructions @runMigration="tryRunMigration"
                                    :display-instructions-event="displayInstructionsEvent">
            </migration-instructions>
            <check-subscription v-model="checkSubscriptionStatus"
                                  :migration-upload-model="migrationUploadModel" 
                                  :check-subscription-event="checkSubscriptionEvent">
            </check-subscription>
        </div>
        <div class="alert-wrapper" v-else>
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>Migration is not allowed for this account.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { MigrationAllowedMixin } from "../../../mixins/Migrations/MigrationAllowedMixin";
    import { MigrationUploadMixin } from "../../../mixins/Migrations/MigrationUploadMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import PageLoader from "../../Shared/Loading/PageLoader.vue";
    import { SiteCacheMixin } from "../../Shared/SiteCacheMixin";
    import SiteSelect from "../SiteSelect.vue"
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import MigrationInstructions from './MigrationInstructions.vue';
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import MigrationCheckSubscription from "./MigrationCheckSubscription.vue";

    export default {
        name: 'Migrations',
        mixins: [MigrationAllowedMixin, MigrationUploadMixin, SiteCacheMixin],
        data() {
            return {
                timeoutId: null,
                checkSubscriptionEvent: 0,
                checkSubscriptionStatus: {},
                displayInstructionsEvent: 0
            }
        },
        computed: {
            running() {
                return !!this.migration && this.migration.running;
            },
            completed() {
                return !!this.migration && this.migration.completed;
            },
            stage() {
                return !!this.migration ? this.migration.stage : '';
            },
            progress() {
                return !!this.migration ? this.migration.progress : 0;
            },
            status() {
                return this.stage + (!!this.progress ? ' ' + this.progress : '');
            },
            loading() {
                return this.loadingMigration || this.running || !!this.subscriptionChecking;
            },
            subscriptionChecking() {
                return !!this.checkSubscriptionStatus ? this.checkSubscriptionStatus.loading : false;
            },
            subscriptionAllowed() {
                return !!this.checkSubscriptionStatus ? this.checkSubscriptionStatus.allowed : false;
            },
            subscriptionError() {
                return !!this.checkSubscriptionStatus ? this.checkSubscriptionStatus.error : '';
            },
            subscriptionAllowedWithNoError() {
                return !!this.subscriptionAllowed && !this.subscriptionError;
            },
            errors() {
                const errorsArray = [this.error, this.subscriptionError].filter(s => !!s);
                return errorsArray.join(' ');
            }
        },
        watch: {
            migration() {
                this.tryQueueFetchMigration();
            },
            subscriptionAllowedWithNoError(value) {
                if (!!value) {
                    this.displayInstructionsEvent++;
                }
            }
        },
        methods: {
            migrationStarted() {
                this.queueFetchMigration();
            },
            tryQueueFetchMigration() {
                if (!!this.running && !this.timeoutId) {
                    this.queueFetchMigration();
                }
            },
            queueFetchMigration() {
                this.timeoutId = setTimeout(() => {
                    this.timeoutId = null;
                    this.tryFetchMigration();
                }, 3000);
            },
            migrateClicked() {
                this.checkSubscriptionEvent++;
            },
            afterRunMigration() {
                //do nothing;
            }
        },
        components: {
            'site-select': SiteSelect,
            'error-banner': ErrorBanner,
            'page-loader': PageLoader,
            'modal-dialog': ModalDialog,
            'migration-instructions': MigrationInstructions,
            'validation-message': ValidationMessage,
            'check-subscription': MigrationCheckSubscription
        },
        created() {
            this.tryQueueFetchMigration();
        }
    }
</script>
