import { Api } from '../../services/Api';

const state = () => ({
    conflictsSearches: []
})

const getters = {
    findConflictsSearchCache: (state) => key => state.conflictsSearches.find(cs => cs.key === key)
}

const mutations = {
    setLoading(state, key) {
        const existing = state.conflictsSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.conflictsSearches.push({ key: key, values: [], loading: true });
        }
    },
    setConflicts(state, { key, conflicts }) {
        const existing = state.conflictsSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.values = conflicts;
            existing.loading = false;
        } else {
            state.conflictsSearches.push({ key: key, values: conflicts, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.conflictsSearches.find(cs => cs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearConflictSearches(state) {
        state.conflictsSearches = [];
    }
}

const actions = {
    searchAddConflicts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', key);
            Api.post('Schedule/Conflict', payload)
                .then(response => {
                    commit('setConflicts', { key: key, conflicts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    },
    searchUpdateConflicts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', key);
            Api.post('Appointment/Conflict', payload)
                .then(response => {
                    commit('setConflicts', { key: key, conflicts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
