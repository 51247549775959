<template>
    <li v-if="allowed && errorCount > 0" :class="{ active: isActive }">
        <a @click="navAndClose" class="pointer d-md-none d-block">
            <span class="nav-link-text d-flex  align-items-center pl-3 ml-0 w-100">
                {{$t(menuName)}}
                <span class="upgrade-badge ml-2 ml-md-auto">{{errorCount}} {{$t('FAILED')}}</span>
            </span>
        </a>
        <a @click="navigate" class="pointer d-md-block d-none">
            <span class="nav-link-text d-flex  align-items-center pl-3 ml-0 w-100">
                {{$t(menuName)}}
                <span class="upgrade-badge ml-2 ml-md-auto">{{errorCount}} {{$t('FAILED')}}</span>
            </span>
        </a>
    </li>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { NavLinkMixin } from './NavLinkMixin';

    export default {
        name: 'NavPanelSubLinkWithError',
        mixins: [NavLinkMixin],
        props: {
            menuName: String,
            errorCount: 0
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'toggleShowMobileNav'
            ]),
            navAndClose() {
                this.navigate();
                this.toggleShowMobileNav();
            }
        }
    }
</script>
