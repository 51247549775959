import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteBrandingSettingMixin } from "./SiteBrandingSettingMixin";

export const SiteBrandingSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteBrandingSettingMixin],
    data() {
        return {
            mainTextColor: "#434242",
            primaryButtonColor: "#F24C64",
            secondaryButtonColor: "#86BF4E",
            tertiaryButtonColor: "#51C5DE",
            headerBackgroundColor: "#EEEEEE",
            headerTextColor: "#434242",
            footerBackgroundColor: "#0A4F70",
            footerTextColor: "#51C5DE",
        }
    },
    computed: {
        settingEditModel() {
            return {
                mainTextColor: this.mainTextColor,
                primaryButtonColor: this.primaryButtonColor,
                secondaryButtonColor: this.secondaryButtonColor,
                tertiaryButtonColor: this.tertiaryButtonColor,
                headerBackgroundColor: this.headerBackgroundColor,
                headerTextColor: this.headerTextColor,
                footerBackgroundColor: this.footerBackgroundColor,
                footerTextColor: this.footerTextColor,
                formBackgroundColor: this.formBackgroundColor
            }
        },
        settingEditModelValid() {
            return true;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.mainTextColor = this.settingObject.mainTextColor;
            this.primaryButtonColor = this.settingObject.primaryButtonColor;
            this.secondaryButtonColor = this.settingObject.secondaryButtonColor;
            this.tertiaryButtonColor = this.settingObject.tertiaryButtonColor;
            this.headerBackgroundColor = this.settingObject.headerBackgroundColor;
            this.headerTextColor = this.settingObject.headerTextColor;
            this.footerBackgroundColor = this.settingObject.footerBackgroundColor;
            this.footerTextColor = this.settingObject.footerTextColor;
        },
        resetColours() {
            this.mainTextColor = this.defaultSettingObject.mainTextColor;
            this.primaryButtonColor = this.defaultSettingObject.primaryButtonColor;
            this.secondaryButtonColor = this.defaultSettingObject.secondaryButtonColor;
            this.tertiaryButtonColor = this.defaultSettingObject.tertiaryButtonColor;
            this.headerBackgroundColor = this.defaultSettingObject.headerBackgroundColor;
            this.headerTextColor = this.defaultSettingObject.headerTextColor;
            this.footerBackgroundColor = this.defaultSettingObject.footerBackgroundColor;
            this.footerTextColor = this.defaultSettingObject.footerTextColor;
        } 
    }
}
