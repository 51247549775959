<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse2" data-toggle="collapse">
            {{$t('Total')}}
            <div class="total-figure ml-auto">
                {{totalString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse2">
            <div class="card card-body">
                <order-summary v-for="(order, index) in appointmentOrders" :index="index" :key="index"></order-summary>
                <payment-select></payment-select>
            </div>
        </div>
    </section>
</template>

<script>
    import AppointmentTotalOrderSummary from './AppointmentTotalOrderSummary.vue';
    import AppointmentPaymentSelect from './AppointmentPaymentSelect.vue';
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin';
    import { mapState } from 'vuex';

    export default {
        name: 'AppointmentTotal',
        mixins: [AppointmentTotalMixin],
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'appointmentOrders',
            ]),
            orderItems() {
                var orderItems = [];
                for (var i = 0; i < this.appointmentOrders.length; i++) {
                    orderItems = orderItems.concat(this.appointmentOrders[i].orderItems);
                }
                return orderItems;
            }
        },
        watch: {
            totalAmount(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'order-summary': AppointmentTotalOrderSummary,
            'payment-select': AppointmentPaymentSelect
        }
    }
</script>
