<template>
    <div class="form-group">
        <label>
            {{methodTypeName}}:
            <span v-if="isActive && loadingCommunications" class="inline-loader"></span>
            <span v-else>{{statusMessage}}</span>
        </label>
        <label class="ps-toggle-switch">
            <input v-model="isActive" class="toggle-checkbox" type="checkbox">
            <div class="toggle-switch"></div>
        </label>
        <label v-if="error" class="error">{{error}}</label>
    </div>
</template>

<script>
    import { CommunicationResendMixin } from "../../Shared/CommunicationResendMixin";

    export default {
        name: 'ClientDocumentSend',
        mixins: [CommunicationResendMixin],
        props: {
            value: Object,
            contract: Object,
            send: Number,
            methodType: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isActive: true
            }
        },
        computed: {
            methodTypeName() {
                return this.methodType === 1 ? 'Email' : this.methodType === 2 ? 'SMS' : 'Unknown';
            },
            clientId() {
                return !!this.contract ? this.contract.clientId : '';
            },
            communicationTypeId() {
                return 9;
            },
            subjectId() {
                return !!this.contract ? this.contract.id : '';
            },
            methodTypeId() {
                return this.methodType;
            },
            status() {
                return {
                    error: this.error,
                    completed: !this.isActive || this.communicationSuccessful,
                    loading: this.loadingCommunications
                }
            },
            statusMessage() {
                return this.isActive ? (this.attemptedCommunications.length > 0 ?
                    (!!this.communicationSuccessful ? 'Sent' : !!this.failedCommunication ? this.failedCommunication.failureReason : 'Unknown') :
                    'Not sent') : 'Inactive';
            }
        },
        watch: {
            status(value) {
                this.$emit('input', value);
            },
            send() {
                if (this.isActive) {
                    this.tryUploadCommunication();
                }
            }
        }
    }
</script>
