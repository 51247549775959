export const BreedHelper = {
    getBreedAddValidator() {
        return {
            isValid: false,
            siteIds: '',
            name: '',
            speciesId: '',
            check(model) {
                this.siteIds = model.siteIds.length > 0 ? '' : 'Please select one or more sites';
                this.name = !!model.name ? '' : 'Please enter breed name';
                this.speciesId = !!model.speciesId ? '' : 'Please select species';
                this.isValid = !this.siteIds && !this.name && !this.speciesId;
            }
        }
    },
    getBreedUpdateValidator() {
        return {
            isValid: false,
            name: '',
            speciesId: '',
            check(model) {
                this.name = !!model.name ? '' : 'Please enter breed name';
                this.speciesId = !!model.speciesId ? '' : 'Please select species';
                this.isValid = !this.name && !this.speciesId;
            }
        }
    }
}
