import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentOAuthsCaches: []
});

const getters = {
    findPaymentOAuthsCache: (state) => key => state.paymentOAuthsCaches.find(poa => poa.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentOAuthsCaches.find(poa => poa.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentOAuthsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setOAuths(state, { key, oAuths }) {
        const existing = state.paymentOAuthsCaches.find(poa => poa.key === key);
        if (!!existing) {
            existing.values = oAuths;
            existing.loading = false;
        } else {
            state.paymentOAuthsCaches.push({ key: key, values: oAuths, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentOAuthsCaches.find(poa => poa.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findPaymentOAuths({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteId, loading: true });
            PaymentApi.get('OAuth')
                .then(response => {
                    commit('setOAuths', { key: siteId, oAuths: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', siteId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
