import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ClientUserCacheMixin } from "./ClientUserCacheMixin";

export const ClientUserUpdateMixin = {
    mixins: [ClientUserCacheMixin],
    data() {
        return {
            error: '',
            isDisabled: false
        }
    },
    computed: {
        clientUserUpdateModel() {
            return !!this.clientUser ? Object.assign({}, this.clientUser, {
                clientId: this.clientId,
                isDisabled: this.isDisabled
            }) : {};
        },
        clientUserUpdateModelModified() {
            return JSON.stringify(this.clientUserUpdateModel) !== JSON.stringify(this.clientUser);
        }
    },
    watch: {
        clientUser() {
            this.refreshClientUserUpdateModel();
        }
    },
    methods: {
        ...mapActions('ClientUsers', [
            'updateClientUser'
        ]),
        tryUpdateClientUser() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            if (!self.clientUserUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateClientUser({ key: self.clientId, payload: self.clientUserUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshClientUserUpdateModel() {
            this.isDisabled = !!this.clientUser ? this.clientUser.isDisabled : false;
        }
    },
    created() {
        this.refreshClientUserUpdateModel();
    }
}
