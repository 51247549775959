import { DaysOfWeekMixin } from "../../Shared/DaysOfWeekMixin";
import moment from 'moment';

export const OpeningHoursMixin = {
    mixins: [DaysOfWeekMixin],
    props: {
        dayId: Number,
        openingHours: Array
    },
    computed: {
        openingHour() {
            return this.openingHours.find(oh => oh.dayOfWeek.id === this.dayId);
        },
        openingString() {
            return !!this.openingHour ?
                this.openingHour.closed ?
                    this.$t('CLOSED') :
                    moment(this.openingHour.openingLocal).format('HH:mm') + ' - ' + moment(this.openingHour.closingLocal).format('HH:mm') :
                this.$t('NOT SET');
        }
    },
    methods: {
        onClick() {
            this.$emit('header-clicked', this.dayId);
        }
    }
}
