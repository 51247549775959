<template>
    <div class="col-md-2 col-12 p-md-2 p-1">
        <div class="card card-body p-0">
            <img class="card-img-top" :src="setting.thumbnail" :alt="setting.name" style="object-fit: cover; width: 100%; height: 10rem;">
            <div class="d-flex justify-content-center flex-column align-items-center px-1 py-2">
                <div class="card-title" style="font-size: 0.7rem">{{ setting.name }}</div>
                <div class="d-flex">
                    <button v-if="purchasedCampaignTemplate && !purchasingCurrentTemplate" class="btn-add btn btn-secondary" disabled>
                        <i class="fas fa-exclamation-circle"></i>{{$t('Purchased')}}
                    </button>
                    <button v-else class="btn-add btn btn-secondary" @click="purchaseTemplate" v-bind:disabled="loadingPurchasedCampaignTemplates || purchasingCurrentTemplate">
                        <i class="fas fa-cart-shopping"></i>{{$t('Buy')}}
                        <span v-if="purchasingCurrentTemplate" class="inline-loader"></span>
                    </button>
                    <button @click="preview" type="button" class="btn-add btn btn-primary ml-1 px-2">
                        <i class="far fa-eye m-0"></i>
                    </button>
                </div>
            </div>
        </div>
        <image-carousel ref="templatePreview" :images="[setting.thumbnail]" :title="setting.name"></image-carousel>
    </div>
</template>

<script>
    import { StripoDefaultTemplateSettingsDisplayItemMixin } from "./StripoDefaultTemplateSettingsDisplayItemMixin";
    import ImageCarousel from "../../Layout/ImageCarousel.vue";
    import { SubscriptionFeatureMixin } from "../../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import { mapMutations } from 'vuex';

    export default {
        name: 'StripoDefaultTemplateSettingsDisplayItem',
        mixins: [StripoDefaultTemplateSettingsDisplayItemMixin, SubscriptionFeatureMixin],
        computed: {
            computedFeatureKey() {
                return 'buyEmailTemplates';
            }
        },
        watch: {
            error(value) {
                this.$emit('input', value)
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerStoreTabChange'
            ]),
            purchaseTemplate() {
                if (this.subscriptionFeatureAllowed) {
                    this.tryPurchasedCampaignTemplate();
                } else {
                    this.triggerStoreTabChange(3);
                }
            },
            preview() {
                const element = this.$refs.templatePreview.$el;
                $(element).modal('show');
            }
        },
        components: {
            'image-carousel': ImageCarousel
        }
    }
</script>