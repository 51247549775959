import vueCookies from 'vue-cookies';

export const PaymentHelper = {
    getBaseUrl() {
        return process.env.VUE_APP_AXIOS_PAYMENT_BASE_URL || '#{Project.PaymentApi.Endpoint}';
    },
    getToken() {
        return vueCookies.get(PaymentHelper.getTokenName());
    },
    setToken(token) {
        vueCookies.set(PaymentHelper.getTokenName(), token, -1, null, null, true, 'Strict');
    },
    clearToken() {
        vueCookies.remove(PaymentHelper.getTokenName());
    },
    getTokenName() {
        return 'payment_token';
    },
    getCheckoutValidator() {
        return {
            amount: '',
            providerTypeId: '',
            isValid: false,
            check(model) {
                this.amount = model.amount > 0 ? '' : 'Please enter an amount greater than 0';
                this.providerTypeId = !!model.providerTypeId ? '' : 'Please select a payment provider';
                this.isValid = !this.amount && !this.providerTypeId;
            }
        }
    },
    getRefundValidator() {
        return {
            amount: '',
            isValid: false,
            validate(model) {
                this.amount = !model.isPartial || model.amount > 0 ? '' : 'Partial amount must be greater than 0';
                this.isValid = !this.amount;
            }
        }
    }
}
