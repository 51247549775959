import { mapActions, mapGetters } from 'vuex';
import { CacheHelper } from '../../../helpers/CacheHelper';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const PostcodeSearchMixin = {
    data() {
        return {
            error: '',
            postcode: '',
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters('PostcodeSearches', [
            'findPostcodeSearch'
        ]),
        postcodeSearch() {
            return this.findPostcodeSearch(this.postcodeSearchKey);
        },
        postcodeSearchModel() {
            return {
                postcode: this.postcode.toLowerCase().split(' ').join('')
            }
        },
        postcodeSearchModelValid() {
            return this.postcodeSearchModel.postcode.length > 4;
        },
        postcodeSearchKey() {
            return CacheHelper.objectCacheKey(this.postcodeSearchModel);
        },
        addresses() {
            return !!this.postcodeSearch ? this.postcodeSearch.values : [];
        },
        searchingPostcode() {
            return !!this.postcodeSearch && this.postcodeSearch.loading;
        },
        showPostcodeResults() {
            return !!this.postcodeSearch && !this.searchingPostcode;
        }
    },
    watch: {
        postcode() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.refreshPostcodeSearch();
            }, 500);
        }
    },
    methods: {
        ...mapActions('PostcodeSearches', [
            'searchPostcode'
        ]),
        refreshPostcodeSearch() {
            if (!this.postcodeSearch || !!this.postcodeSearch.retry) {
                this.trySearchPostcode();
            }
        },
        trySearchPostcode() {
            const self = this;
            if (!this.postcodeSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchPostcode({ key: self.postcodeSearchKey, payload: self.postcodeSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
