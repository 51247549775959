<template>
    <div>
        <div class="row form-group">
            <div class="col-md-4">
                <label for="title">{{$t("Who's it for?")}}<span class="required-field"></span></label>
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <div v-if="pet">
                        <pet-item :pet="pet"></pet-item>
                        <button @click="clearPet" class="btn btn-secondary btn-sm" type="button"><i class="far fa-times"></i></button>
                    </div>
                    <template v-else>
                        <search-field v-model="pet"></search-field>
                    </template>
                </div>
                <div class="form-group has-error has-feedback">
                    <validation-message v-if="validationError" :message="validationError"></validation-message>
                </div>
            </div>
        </div>
        <client-history v-if="pet" :pet="pet"></client-history>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { AppointmentScheduleValidationMixin } from '../../Shared/AppointmentScheduleValidationMixin';
    import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
    import AppointmentClientHistory from './AppointmentClientHistory.vue';
    import PetSearchField from '../../Pet/Search/PetSearchField.vue';
    import PetDisplayItem from '../../Pet/Search/PetDisplayItem.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'AppointmentPetSelect',
        mixins: [AppointmentScheduleValidationMixin, SiteIdMixin],
        computed: {
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            validationErrorText() {
                return this.scheduleAddModelValidation.appointmentOrders[0].petId;
            },
            appointmentOrder() {
                return this.indexAppointmentOrder(0);
            },
            pet: {
                get() {
                    return this.appointmentOrder.pet;
                },
                set(value) {
                    if (!!value) {
                        if (value.siteId !== this.siteId) {
                            this.setSelected(value.siteId);
                        }
                        this.setMainPet(value);
                    }
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setMainPet'
            ]),
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            clearPet() {
                this.setMainPet(null);
            }
        },
        components: {
            'client-history': AppointmentClientHistory,
            'search-field': PetSearchField,
            'pet-item': PetDisplayItem,
            'validation-message': ValidationMessage
        }
    }
</script>
