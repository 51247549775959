<template>
    <div class="mw-400">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group booking-banner">
            <div v-if="preview" class="image-uploads booking-banner-image-uploads">
                <ul>
                    <li class="active">
                        <button @click="clearUploads" class="btn" type="button">
                            <i class="far fa-times"></i>
                        </button>
                        <figure>
                            <img :src="preview.url" width="120">
                        </figure>
                    </li>
                </ul>
            </div>
            <template v-else>
                <div class="file-upload mw-400">
                    <label>
                        <span>{{$t('Browse files')}}</span>
                        <input @change="onFilePicked" type="file" accept="image/*" />
                    </label>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { BookingPortalImageUploadMixin } from "../../../mixins/Settings/Site/BookingPortalImageUploadMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateBookingPortalImageAdd',
        mixins: [ImageAddMixin, BookingPortalImageUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            preview() {
                return this.imagePreviewSet.length > 0 ? this.imagePreviewSet[0] : null;
            },
            status() {
                return {
                    loading: this.loadingImages || this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryAddImages();
            }
        },
        methods: {
            afterImagesAdded(images) {
                this.bookingPortalImageId = images[0].id;
                this.tryUploadSetting();
                this.bookingPortalImageId = '';
                this.clearUploads();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
