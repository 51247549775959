import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const NotificationSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('NotificationSettings', [
            'findNotificationSettingsCache'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        notificationSettingsCache() {
            return this.findNotificationSettingsCache(this.clientId);
        },
        notificationSettings() {
            return !!this.notificationSettingsCache ? this.notificationSettingsCache.value : null;
        },
        loadingNotificationSettings() {
            return !!this.notificationSettingsCache && this.notificationSettingsCache.loading;
        }
    },
    watch: {
        clientId() {
            this.refreshNotificationSettings();
        }
    },
    methods: {
        ...mapActions('NotificationSettings', [
            'getNotificationSettings'
        ]),
        refreshNotificationSettings() {
            if (!this.notificationSettingsCache || !!this.notificationSettingsCache.retry) {
                this.tryGetNotificationSettings();
            }
        },
        tryGetNotificationSettings() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.getNotificationSettings(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshNotificationSettings();
    }
}
