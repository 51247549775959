<template>
    <collapsed-section id="site-tax" :title="$t('Tax Details')">
        <tax-details v-model="detailsStatus"></tax-details>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateTaxDetails from "./SiteUpdateTaxDetails.vue";

    export default {
        name: 'SiteUpdateTax',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                detailsStatus: {}
            }
        },
        computed: {
            status() {
                return {
                    loading: !!this.detailsStatus.loading,
                    error: this.detailsStatus.error
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'tax-details': SiteUpdateTaxDetails
        }
    }
</script>
