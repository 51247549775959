<template>
    <div>
        <div v-if="selectedWeight" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{$t('Are you sure you want to delete this weight?')}}</p>
            </div>
            <button @click="tryDeleteWeight" v-bind:disabled="loadingWeights" type="button" class="btn btn-danger" style="margin-right: 10px;">
                <span v-if="loadingWeights" class="inline-loader"></span>
                <template v-else>
                    {{$t('Yes')}}
                </template>
            </button>
            <button @click="selectedWeight=null" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="table-responsive">
            <table class="table table-striped ps-table table-hover">
                <thead>
                    <tr>
                        <th>{{$t('Date')}}</th>
                        <th>{{$t('Weight')}} ({{weightUnit}})</th>
                        <th>{{$t('Note')}}</th>
                        <th v-if="canDelete" class="text-center">{{$t('Delete?')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="w in weights" :ref="w.id">
                        <td>{{w.createdUtc | moment(momentJSFormat)}}</td>
                        <td>{{w.value}}</td>
                        <td>{{w.notes}}</td>
                        <td v-if="canDelete" @click="selectedWeight=w" class="text-center pointer">
                            <i class="fal fa-trash-alt text-danger"></i>
                        </td>
                    </tr>
                    <tr>
                        <td>{{date | moment(momentJSFormat)}}</td>
                        <td>
                            <input v-model="value" class="form-control" type="number">
                            <validation-message v-if="weightValidator.value" :message="weightValidator.value"></validation-message>
                        </td>
                        <td>
                            <input v-model="notes" class="form-control" type="text" name="name">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin.js';
    import { WeightSettingCacheMixin } from './WeightSettingCacheMixin.js';
    import { WeightUpdateMixin } from './WeightUpdateMixin.js';
    import { UserCacheMixin } from '../../Shared/UserCacheMixin.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin.js';

    export default {
        name: 'PetWeightSection',
        mixins: [WeightSettingCacheMixin, WeightUpdateMixin, DateDisplayMixin, StatusEmitterMixin, UserCacheMixin],
        data() {
            return {
                selectedWeight: null,
                date: new Date()
            }
        },
        props: {
            pet: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'weightDeletedEvent',
                'savePetEvent'
            ]),
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            weightId() {
                return !!this.selectedWeight ? this.selectedWeight.id : '';
            },
            status() {
                return {
                    loading: this.loadingWeights || this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            petId() {
                this.selectedWeight = null;
            },
            weightDeletedEvent() {
                this.selectedWeight = null;
            },
            savePetEvent() {
                this.tryAddWeight();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
