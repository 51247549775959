<template>
    <div class="main-page">
        <div v-if="error" class="alert alert-danger alert-icon" role="alert">
            <div class="alert-content">
                <p>{{ error }}</p>
            </div>
            <button @click="tryClaimReferred">
                Retry
            </button>
            <button @click="cancel">
                {{$t('Cancel')}}
            </button>
        </div>
        <page-loader v-if="claimingReferred"></page-loader>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import PageLoader from "../Shared/Loading/PageLoader.vue";

    export default {
        name: 'SubscriptionReferredClaim',
        data() {
            return {
                error: '',
                claimingReferred: false
            }
        },
        methods: {
            tryClaimReferred() {
                const self = this;
                self.error = '';
                self.claimingReferred = true;
                Api.put('Referred')
                    .then(() => self.afterClaimReferred())
                    .catch(error => {
                        if (ErrorHelper.isNotFound(error)) {
                            self.afterClaimReferred();
                        } else {
                            self.error = ErrorHelper.getError(error);
                        }
                    })
                    .finally(() => self.claimingReferred = false);
            },
            afterClaimReferred() {
                this.returnToStore({ success: 'Successfully upgraded subscription' });
            },
            cancel() {
                this.returnToStore({ warning: 'Successfully upgraded subscription, but referral attempt failed' });
            },
            returnToStore(messageObj) {
                const queryObj = Object.assign({ tab: 3 }, messageObj);
                this.$router.push({ name: 'store', query: queryObj });
            }
        },
        components: {
            'page-loader': PageLoader
        },
        created() {
            this.tryClaimReferred();
        }
    }
</script>
