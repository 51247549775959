import router from '../../../routing';

export const CommunicationTypeItemMixin = {
    props: {
        communicationType: Object
    },
    computed: {
        typeName() {
            return this.$t(this.communicationType.description);
        },
        startLetter() {
            return this.typeName.length > 0 ? this.typeName[0] : '';
        }
    },
    methods: {
        smsClick() {
            router.push({ name: 'communicationTemplateSms', params: { id: this.communicationType.id } });
        },
        emailClick() {
            router.push({ name: 'communicationTemplateEmail', params: { id: this.communicationType.id } });
        }
    }
}
