<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Hidden days')}}</label>
        </div>
        <div class="col-md-8">
            <label v-for="id in dayIds" :key="id" class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" :id="daysOfWeek[id]" :value="id" v-model="hiddenDays">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{$t(daysOfWeek[id])}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import { DaysOfWeekMixin } from "../../Shared/DaysOfWeekMixin";
    import { DaysOrderMixin } from "../../Shared/DaysOrderMixin";

    export default {
        name: 'ScheduleHiddenDays',
        mixins: [DaysOrderMixin, DaysOfWeekMixin],
        props: {
            value: Array
        },
        computed: {
            hiddenDays: {
                get() {
                    return this.value;
                },
                set(values) {
                    this.$emit('input', [...new Set(values.map(v => this.getNumber(v)))]);
                }
            }
        },
        methods: {
            getNumber(value) {
                const number = parseInt(value);
                return isNaN(number) ? 0 : number;
            }
        }
    }
</script>
