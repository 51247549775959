<template>
    <h5 @click="gotoPetDetails" data-dismiss="modal" class="header-pet pointer">{{petString}}</h5>
</template>

<script>
    import { BreedCacheMixin } from "../../Shared/BreedCacheMixin";
    import router from '../../../routing';

    export default {
        name: 'AppointmentOverviewPetName',
        mixins: [BreedCacheMixin],
        props: {
            pet: Object
        },
        computed: {
            petName() {
                return this.pet.name;
            },
            breedId() {
                return this.pet.breedId;
            },
            petString() {
                return this.petName + ' - ' + this.breedName;
            }
        },
        methods: {
            gotoPetDetails() {
                const queryObj = Object.assign({ pet: this.pet.id });
                router.push({
                    name: 'clientEdit',
                    params: { id: this.pet.clientId },
                    query: queryObj
                });
            }
        }
    }
</script>

<style scoped>
    .header-pet:hover {
        color: #F4457B;
    }
</style>
