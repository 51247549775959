<template>
    <popper trigger="clickToOpen"
            :visible-arrow="false"
            :options="{
                placement: 'top',
            }">
        <i slot="reference" class="far fa-info-circle" style="cursor: pointer; color: #339ca0"></i>
        <div class="popper">
            <slot></slot>
        </div>
    </popper>
</template>

<script>
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';

    export default {
        name:'Tooltip',
        components: {
            'popper': Popper
        }
    }
</script>
