<template>
    <div class="row">
        <div class="col-md-4 d-none d-md-block">
            <label for="title">{{$t('Client Details')}}</label>
        </div>
        <div v-if="hasInfo" class="col-md-8">
            <p v-if="mobile" class="mb-1">Tel: {{mobile}}</p>
            <p v-if="email" class="mb-1">{{email}}</p>
            <p v-if="addressString" class="mb-1">{{addressString}}</p>
        </div>
        <template v-else>
            <div class="col-md-8">
                N/A
            </div>
        </template>
    </div>
</template>

<script>
    import { AddressHelper } from "../../../helpers/AddressHelper";
    import { AddressCacheMixin } from "../../Shared/AddressCacheMixin";
    import { ClientCacheMixin } from "../../Shared/ClientCacheMixin";

    export default {
        name: 'AppointmentClientInfoDetails',
        mixins: [ClientCacheMixin, AddressCacheMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id;
            },
            mobile() {
                return !!this.client ? this.client.telephoneMobile : '';
            },
            email() {
                return !!this.client ? this.client.email : '';
            },
            ownerQueryModel() {
                return {
                    ownerId: this.clientId,
                    ownerTypeId: 2
                };
            },
            addressString() {
                return AddressHelper.getAddressLines(this.address).join(', ');
            },
            hasInfo() {
                return !!this.mobile || !!this.email || !!this.addressString;
            }
        }
    }
</script>
