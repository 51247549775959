export const ServiceItemMixin = {
    props: {
        product: Object
    },
    computed: {
        productName() {
            return this.product.name;
        },
        startLetter() {
            return this.productName[0].toUpperCase();
        },
        amount() {
            return this.product.amount;
        },
        amountString() {
            return this.amount.toFixed(2);
        },
        description() {
            return this.product.description;
        },
        publishedOnline() {
            return !!this.product.publishedOnline ? this.$t('Yes') : this.$t('No');
        }
    },
    methods: {
        onClick() {
            this.$emit('productClicked', this.product);
        }
    }
}