<template>
    <div class="wrapper">
        <div class="main">
            <div class="main-page">
                <div v-if="unknownReason" class="alert alert-danger alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Account Restricted - Please contact the system administrator.</p>
                    </div>
                </div>
                <template v-else>
                    <page-loader></page-loader>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { SubscriptionPaymentFailuresCacheMixin } from "../../mixins/SubscriptionPaymentFailures/SubscriptionPaymentFailuresCacheMixin";
    import PageLoader from "../Shared/Loading/PageLoader.vue";

    export default {
        name: 'RestrictedArea',
        mixins: [SubscriptionPaymentFailuresCacheMixin],
        computed: {
            failure() {
                return this.paymentFailures[0];
            },
            unknownReason() {
                return this.fetchedPaymentFailures && !this.failure;
            },
            payment() {
                return !!this.failure ? this.failure.subscriptionPayment : null
            },
            paymentId() {
                return !!this.payment ? this.payment.id : '';
            }
        },
        watch: {
            paymentId() {
                this.gotoCheckout();
            }
        },
        methods: {
            gotoCheckout() {
                if (!!this.paymentId) {
                    this.$router.push({
                        name: 'restrictedCheckout',
                        params: { 'paymentId': this.paymentId }
                    });
                }
            }
        },
        components: {
            'page-loader': PageLoader
        },
        created() {
            this.gotoCheckout();
        }
    }
</script>
