<template>
    <modal-dialog :title="title" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <delete-prep v-if="deleting" :resources="resourcesToDelete" @cancel="cancelDelete" @confirm="confirmDelete"></delete-prep>
        <section class="rounded-card-white mb-3">
            <name-input v-model="name" :validator="resourceValidator"></name-input>
            <colour-picker v-model="colour" :validator="resourceValidator"></colour-picker>
            <image-update v-model="loadingImage" :resource-id="resourceId"></image-update>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Priority Order')}}</label>
                </div>
                <div class="col-md-8">
                    <p>{{priorityOrder}}</p>
                </div>
            </div>
        </section>
        <div class="modal-footer">
            <button @click="triggerSaveResource" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <delete-button @clicked="deleting=true"></delete-button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import ModalDialog from '../Layout/ModalDialog.vue';
    import ResourceNameInput from './ResourceNameInput.vue';
    import ResourceColourPicker from './ResourceColourPicker.vue';
    import ResourceDeleteFlow from './ResourceDeleteFlow.vue';
    import { mapState, mapMutations } from 'vuex';
    import { ResourceUploadMixin } from '../Shared/Resources/ResourceUploadMixin';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import ResourceImageUpdate from './ResourceImageUpdate.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'ResourceUpdate',
        mixins: [ResourceUploadMixin],
        data() {
            return {
                deleting: false,
                loadingImage: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'resourceDeletedEvent',
                'saveResourceEvent'
            ]),
            resourcesToDelete() {
                return !!this.resource ? [this.resource] : [];
            },
            title() {
                return !!this.resource ? this.resource.name : '';
            },
            loading() {
                return this.loadingResources || this.loadingImage;
            }
        },
        watch: {
            resourceDeletedEvent() {
                this.$refs.closeModal.click();
            },
            saveResourceEvent() {
                this.tryUploadResource();
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveResource'
            ]),
            confirmDelete() {
                this.tryDeleteResource();
            },
            cancelDelete() {
                this.deleting = false;
            },
            onOpened() {
                this.deleting = false;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'name-input': ResourceNameInput,
            'colour-picker': ResourceColourPicker,
            'delete-prep': ResourceDeleteFlow,
            'delete-button': DeleteButton,
            'image-update': ResourceImageUpdate,
            'error-banner': ErrorBanner
        }
    }
</script>
