<template>
</template>

<script>
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { SiteResourceEventColourSettingUploadMixin } from "../../../mixins/Settings/Site/SiteResourceEventColourSettingUploadMixin";

    export default {
        name: 'SiteAddResourceEventColour',
        mixins: [SiteResourceEventColourSettingUploadMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            siteId() {
                return !!this.addedSiteId ? this.addedSiteId : '';
            },
            defaultSettingObject() {
                return {
                    displayEventColour: true,
                    Holiday: '#A9D86E',         // Lighter Olive Green
                    Sickness: '#FF7198',        // Pink
                    Personal: '#F5FBFE',        // Light Blue
                    Complimentary: '#F5E3B3',   // Pastel Gold
                    PublicHoliday: '#B9E8A3',   // Pastel Green
                    Training: '#E6E6FA',        // Lavender
                    Other: '#F6F8F8',           // Light Gray
                    Unknown: '#FFFFFF'          // White
                }
            },
            canAddResourceEventColour() {
                return !this.loadingSetting && !this.setting && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingSetting,
                    completed: !!this.settingString
                }
            }
        },
        watch: {
            canAddResourceEventColour() {
                this.tryUploadResourceEventColour();
            }
        },
        methods: {
            tryUploadResourceEventColour() {
                if (this.canAddResourceEventColour) {
                    this.tryUploadSetting();
                }
            }
        },
        created() {
            this.tryUploadResourceEventColour();
        }
    }
</script>
