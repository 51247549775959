import { Api } from "../../services/Api";

const state = () => ({
    subscriptionEntityPaymentsCaches: []
})

const getters = {
    findSubscriptionEntityPaymentsCache: (state) => key => state.subscriptionEntityPaymentsCaches.find(supc => supc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.subscriptionEntityPaymentsCaches.find(supc => supc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.subscriptionEntityPaymentsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setSubscriptionEntityPayments(state, { key, payments }) {
        const existing = state.subscriptionEntityPaymentsCaches.find(supc => supc.key === key);
        if (!!existing) {
            existing.values = payments
            existing.loading = false;
        } else {
            state.subscriptionEntityPaymentsCaches.push({ key: key, values: payments, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.subscriptionEntityPaymentsCaches.find(supc => supc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendSubscriptionEntityPayment(state, { key, payment }) {
        const existing = state.subscriptionEntityPaymentsCaches.find(supc => supc.key === key);
        if (!!existing) {
            existing.values.push(payment);
        } else {
            state.subscriptionEntityPaymentsCaches.push({ key: key, values: [payment], loading: false });
        }
    },
    insertSubscriptionEntityPayment(state, payment) {
        const existing = state.subscriptionEntityPaymentsCaches.find(supc => supc.key === payment.subscriptionEntityId);
        if (!!existing) {
            const old = existing.values.find(p => p.id === payment.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, payment);
            } else {
                existing.values.push(payment);
            }
        } else {
            state.subscriptionEntityPaymentsCaches.push({ key: payment.subscriptionEntityId, values: [payment], loading: false });
        }
    }
}

const actions = {
    findSubscriptionEntityPayments({ commit }, entityId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: entityId, loading: true });
            Api.get('SubscriptionEntity/' + entityId + '/SubscriptionPayment')
                .then(response => {
                    commit('setSubscriptionEntityPayments', { key: entityId, payments: response.data });
                    commit('SubscriptionPayments/setSubscriptionPayments', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', entityId);
                    reject(error);
                });
        });
    },
    addSubscriptionUpgradePayment({ commit }, upgradeId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: upgradeId, loading: true });
            Api.post('SubscriptionUpgrade/' + upgradeId + '/SubscriptionPayment')
                .then(response => {
                    commit('appendSubscriptionEntityPayment', { key: upgradeId, payment: response.data });
                    commit('SubscriptionPayments/setSubscriptionPayment', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: upgradeId, loading: false }));
        });
    },
    addCreditPackPayment({ commit }, packId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: packId, loading: true });
            Api.post('CreditPack/' + packId + '/SubscriptionPayment')
                .then(response => {
                    commit('appendSubscriptionEntityPayment', { key: packId, payment: response.data });
                    commit('SubscriptionPayments/setSubscriptionPayment', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: packId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
