<template>
    <li @click="onClick" class="pointer" v-bind:class="{ active: active }">
        <a>
            {{$t('Pets')}}
            <span class="badge badge-secondary ml-1">{{pets.length}}</span>
        </a>
    </li>
</template>

<script>
    import { PetCacheMixinV2 } from '../Shared/PetCacheMixinV2';

    export default {
        name: 'ClientPetsTab',
        mixins: [PetCacheMixinV2],
        props: {
            id: String,
            active: Boolean
        },
        computed: {
            clientId() {
                return this.id;
            }
        },
        methods: {
            onClick() {
                this.$emit('click')
            }
        }
    }
</script>
