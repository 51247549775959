import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { DefaultPetImageMixin } from "./DefaultPetImageMixin";

export const DefaultPetImageUploadMixin = {
    mixins: [SettingUploadMixin, DefaultPetImageMixin],
    data() {
        return {
            defaultPetImageId: ''
        }
    },
    computed: {
        settingEditModel() {
            return {
                imageId: this.defaultPetImageId
            }
        },
        settingEditModelValid() {
            return !!this.settingEditModel.imageId;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.defaultPetImageId = this.settingObject.imageId;
        }
    }
}
