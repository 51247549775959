<template>
    <div class="d-flex flex-column align-items-center">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="flex-md-row d-flex flex-column justify-content-center align-items-center align-items-md-start w-100 mt-2 flex-wrap">
            <div v-for="setting in creditPackSettings">
                <pack-item :credit-setting="setting" :config="config" @idSelected="onIdSelected"></pack-item>
            </div>
        </div>
    </div>
</template>

<script>
    import { CreditPackSettingsCacheMixin } from "../../mixins/CreditPacks/CreditPackSettingsCacheMixin"
    import { SubscriptionFeatureMixin } from "../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import CreditPackSelectItem from "./CreditPackSelectItem.vue";
    import { mapMutations } from 'vuex';

    export default {
        name: 'CreditPackSelect',
        mixins: [CreditPackSettingsCacheMixin, SubscriptionFeatureMixin],
        props: {
            config: {
                type: Object,
                required: true
            }
        },
        computed: {
            creditTypeId() {
                return this.config.creditTypeId;
            },
            computedFeatureKey() {
                return this.config.computedFeatureKey;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerStoreTabChange'
            ]),
            onIdSelected(id) {
                if (this.subscriptionFeatureAllowed) {
                    this.$emit('idSelected', id);
                } else {
                    this.triggerStoreTabChange(3);
                }
            }
        },
        components: {
            'pack-item': CreditPackSelectItem,
            'error-banner': ErrorBanner
        }
    }
</script>
