import { CountryCacheMixin } from "../../components/Shared/Country/CountryCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { CreditPacksCacheMixin } from "./CreditPacksCacheMixin";
import { mapActions } from 'vuex';

export const EmailTemplateCreditPackAddMixin = {
    mixins: [CreditPacksCacheMixin, CountryCacheMixin],
    data() {
        return {
            packId: 0
        }
    },
    computed: {
        creditTypeId() {
            return 3;
        },
        emailTemplateCreditPackAddModel() {
            return {
                packId: this.packId,
                currency: this.currency === 'GBP' ? 'GBP' : 'USD',
            }
        },
        emailTemplateCreditPackAddModelValid() {
            const model = this.emailTemplateCreditPackAddModel;
            return !!this.siteId && !!model.packId;
        }
    },
    methods: {
        ...mapActions('CreditPacks', [
            'addEmailTemplateCreditPack'
        ]),
        tryAddEmailTemplateCreditPack() {
            const self = this;
            if (!self.emailTemplateCreditPackAddModelValid) {
                return;
            }
            self.error = '';
            self.addEmailTemplateCreditPack({ key: self.creditPacksCacheKey, payload: self.emailTemplateCreditPackAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
