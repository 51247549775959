import { mapState, mapActions } from 'vuex';
import { SiteIdMixin } from '../../components/Shared/Site/SiteIdMixin';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Subscription', [
            'fetchedSubscription',
            'fetchingSubscription',
            'subscription'
        ]),
        subscriptionFeatures() {
            return !!this.subscription ? this.subscription.feature : null;
        },
        subscriptionAdditionalSites() {
            return !!this.subscription ? this.subscription.additionalSites : 0;
        }
    },
    methods: {
        ...mapActions('Subscription', [
            'fetchSubscription'
        ]),
        refreshSubscription() {
            if (!this.fetchedSubscription && !this.fetchingSubscription) {
                this.tryFetchSubscription();
            }
        },
        tryFetchSubscription() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchSubscription()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscription();
    }
}
