import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { NoteCacheMixin } from "./NoteCacheMixin";

export const NoteAddMixin = {
    mixins: [NoteCacheMixin],
    data() {
        return {
            noteText: ''
        }
    },
    computed: {
        noteTypeId() {
            console.log('!!!OVERRIDE COMPUTED - noteTypeId!!!');
        },
        noteAddModel() {
            return Object.assign({}, this.ownerQueryModel, {
                noteTypeId: this.noteTypeId,
                text: this.noteText
            });
        },
        noteAddModelValid() {
            return this.ownerQueryModelValid && !!this.noteText;
        }
    },
    methods: {
        ...mapActions('Notes', [
            'addNote'
        ]),
        tryAddNote() {
            const self = this;
            if (!self.noteAddModelValid) {
                return;
            }
            self.error = '';
            return self.addNote({ key: self.noteCacheKey, payload: self.noteAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
