export const ProductHelper = {
    isValidAmount(amount) {
        amount = parseFloat(amount);
        return !isNaN(amount) && !(amount < 0);
    },
    getServiceProductValidator() {
        return {
            isValid: false,
            amount: '',
            name: '',
            siteIds: '',
            validate(model) {
                this.amount = ProductHelper.isValidAmount(model.amount) ? '' : 'Please enter valid amount';
                this.name = !!model.name ? '' : 'Please enter a name';
                this.siteIds = model.siteIds.length > 0 ? '' : 'Please select at least one site';
                this.isValid = !this.amount && !this.name && !this.siteIds;
            }
        }
    },
    getProductValidator() {
        return {
            isValid: false,
            amount: '',
            name: '',
            check(model) {
                this.amount = ProductHelper.isValidAmount(model.amount) ? '' : 'Please enter valid amount';
                this.name = !!model.name ? '' : 'Please enter a name';
                this.isValid = !this.amount && !this.name;
            }
        }
    },
    getProductsAddValidator() {
        return {
            isValid: false,
            amount: '',
            name: '',
            ownerIds: '',
            check(model) {
                this.ownerIds = !!model.ownerIds && model.ownerIds.length > 0 ? '' : 'Please select at least 1 site';
                this.amount = ProductHelper.isValidAmount(model.amount) ? '' : 'Please enter valid amount';
                this.name = !!model.name ? '' : 'Please enter a name';
                this.isValid = !this.ownerIds && !this.amount && !this.name;
            }
        }
    }
}
