<template>
    <section v-if="display" class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse3" data-toggle="collapse">
            {{$t('Conflicts')}}
            <span v-if="loading" class="inline-loader"></span>
            <template v-else>
                {{conflictsLabel}}
            </template>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse3">
            <div class="card card-body">
                <override-item v-for="(conflict, index) in conflictsAugmented" :key="index" v-model="overrides" :conflict="conflict" :loading="loading"></override-item>
            </div>
        </div>
    </section>
</template>

<script>
    import { AppointmentsDataFinder } from "../../Shared/Appointments/AppointmentsDataFinder";
    import EditOverrideItem from "./EditOverrideItem.vue";

    export default {
        name: 'EditOverrides',
        mixins: [AppointmentsDataFinder],
        props: {
            value: Array,
            conflicts: Array,
            loading: Boolean
        },
        computed: {
            conflictsAugmented() {
                return this.value.filter(o => !this.conflicts.some(c => c.appointment.sequenceNumber === o.sequenceNumber && c.appointment.petId === o.petId))
                    .map(o => ({
                        appointment: o,
                        conflicts: []
                    }))
                    .concat(this.conflicts)
                    .sort((a, b) => a.appointment.sequenceNumber - b.appointment.sequenceNumber);
            },
            conflictsLabel() {
                return '(' + this.conflicts.length + ')';
            },
            appointments() {
                var result = [];
                for (var i = 0; i < this.conflicts.length; i++) {
                    const c = this.conflicts[i];
                    result = result.concat([c.appointment].concat(c.conflicts));
                }
                return result;
            },
            overrides: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            display() {
                return this.conflictsAugmented.length > 0 || this.loading;
            }
        },
        methods: {
            onChanged(overrides) {
                this.$emit('input', overrides);
            }
        },
        components: {
            'override-item': EditOverrideItem
        }
    }
</script>
