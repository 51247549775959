<template>
    <div>
        <div class="title-divider mb-3 mt-0">
            <h2>{{$t('Pet')}} {{index + 1}}: {{petName}} with {{resourceName}}</h2>
        </div>
        <section v-for="(orderItem, index) in serviceItems" :key="orderItem.id">
            <div class="title-divider">
                <h2>{{orderItem.name}}</h2>
            </div>
            <div class="row form-group">
                <div class="col-6">
                    <label>{{$t('Price')}} ({{currencySymbol}}):</label>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    {{orderItem.amount}}
                </div>
            </div>
            <div class="row form-group">
                <div class="col-6">
                    <label>{{$t('Quantity:')}}</label>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    {{orderItem.quantity}}
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../Shared/Resources/ResourceCacheMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";

    export default {
        name: 'BookingAddAppointmentScheduleOrdersItem',
        mixins: [ResourceCacheMixin, CountryCacheMixin],
        props: {
            participant: Object,
            index: 0
        },
        computed: {
            petName() {
                return !!this.participant && !!this.participant.name ? this.participant.name : '-'
            },
            serviceItems() {
                return !!this.participant && this.participant.serviceItems.length > 0 ? this.participant.serviceItems : [];
            },
            resource() {
                return this.resources.length > 0 ? this.resources.find(r => r.id === this.participant.resourceId) : null;
            },
            resourceName() {
                return !!this.resource ? this.resource.name : '-';
            }
        }
    }
</script>
