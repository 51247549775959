import { Api } from "../../services/Api";

const state = () => ({
    smsCampaignTemplatesCaches: []
})

const getters = {
    findSmsCampaignTemplatesCache: (state) => key => state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.smsCampaignTemplatesCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setSmsCampaignTemplates(state, { key, templates }) {
        const existing = state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key);
        if (!!existing) {
            existing.values = templates;
            existing.loading = false;
        } else {
            state.smsCampaignTemplatesCaches.push({ key: key, values: templates, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendSmsCampaignTemplate(state, { key, template }) {
        const existing = state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key);
        if (!!existing) {
            existing.values.push(template);
        } else {
            state.smsCampaignTemplatesCaches.push({ key: key, values: [template], loading: false });
        }
    },
    insertSmsCampaignTemplate(state, { key, template }) {
        const existing = state.smsCampaignTemplatesCaches.find(sctc => sctc.key === key);
        if (!!existing) {
            const old = existing.values.find(t => t.id === template.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, template);
            } else {
                existing.values.push(template);
            }
        } else {
            state.smsCampaignTemplatesCaches.push({ key: key, values: [template], loading: false });
        }
    }
}

const actions = {
    findSmsCampaignTemplates({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('SmsCampaignTemplate')
                .then(response => {
                    commit('setSmsCampaignTemplates', { key: key, templates: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addSmsCampaignTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('SmsCampaignTemplate', payload)
                .then(response => {
                    commit('appendSmsCampaignTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateSmsCampaignTemplate({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('SmsCampaignTemplate', payload)
                .then(response => {
                    commit('insertSmsCampaignTemplate', { key: key, template: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
