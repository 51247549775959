<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">Add photos</label>
        </div>
        <div class="col-md-8">
            <div class="file-upload mw-400 ml-0">
                <label>
                    <span>{{$t('Browse files')}}</span>
                    <input @change="onFilePicked" type="file" accept="image/*" multiple="multiple" />
                </label>
            </div>
        </div>
        <div class="image-uploads">
            <ul>
                <li v-for="upload in imagePreviewSet" :ref="upload.id" class="active">
                    <button @click="removeUpload(upload)" class="btn" type="button">
                        <i class="far fa-times"></i>
                    </button>
                    <figure>
                        <img :src="upload.url" width="120">
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { PetAddImagesMixin } from "../../Pet/PetAdd/PetAddImagesMixin";

    export default {
        name: 'OptionsPetImages',
        mixins: [PetAddImagesMixin]
    }
</script>