import { Api } from '../../services/Api';

const state = () => ({
    fetchingEventTypes: false,
    fetchedEventTypes: false,
    eventTypes: []
})

const mutations = {
    setFetching(state) {
        state.fetchedEventTypes = false;
        state.fetchingEventTypes = true;
    },
    setEventTypes(state, eventTypes) {
        state.eventTypes = eventTypes;
        state.fetchedEventTypes = true;
        state.fetchingEventTypes = false;
    },
    failFetching(state) {
        state.fetchingEventTypes = false;
        state.fetchedEventTypes = false;
    }
}

const actions = {
    getEventTypes({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Event/Type')
                .then(response => {
                    commit('setEventTypes', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('failFetching');
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
