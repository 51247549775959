import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const OrderCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Orders', [
            'findOrderCache'
        ]),
        appointmentId() {
            console.log('OVERRIDE COMPUTED - appointmentId');
            return '';
        },
        orderFilterModel() {
            return {
                appointmentId: this.appointmentId
            }
        },
        orderFilterModelValid() {
            const model = this.orderFilterModel;
            return !!model.appointmentId;
        },
        orderCache() {
            return this.findOrderCache(this.appointmentId);
        },
        orders() {
            return !!this.orderCache ? this.orderCache.values : [];
        },
        loadingOrders() {
            return !!this.orderCache && !!this.orderCache.loading;
        }
    },
    watch: {
        appointmentId() {
            this.refreshOrders();
        }
    },
    methods: {
        ...mapActions('Orders', [
            'findOrders'
        ]),
        refreshOrders() {
            if (!this.orderCache || !!this.orderCache.retry) {
                this.tryFindOrders();
            }
        },
        tryFindOrders() {
            const self = this;
            if (!self.orderFilterModelValid) {
                return;
            }
            self.error = '';
            self.findOrders({ key: self.appointmentId, payload: self.orderFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOrders();
    }
}
