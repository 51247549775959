<template>
    <div>
        <table-mobile>
            <campaign-email-mobile v-for="(template, index) in emailCampaignTemplates" :key="template.id" :template="template"></campaign-email-mobile>
        </table-mobile>
        <table-desktop>
            <template #header>
                <tr role="row">
                    <th>{{$t('Subject')}}</th>
                </tr>
            </template>
            <template #rows>
                <campaign-email-desktop v-for="(template, index) in emailCampaignTemplates" :key="template.id" :number="index" :template="template"></campaign-email-desktop>
            </template>
        </table-desktop>
    </div>
</template>

<script>
    import { EmailCampaignTemplatesCacheMixin } from '../../../mixins/Campaigns/EmailCampaignTemplatesCacheMixin';
    import TableDesktop from '../../Shared/Layout/TableDesktop.vue';
    import TableMobile from '../../Shared/Layout/TableMobile.vue';
    import CampaignEmailTemplateItemDesktop from './CampaignEmailTemplateItemDesktop.vue';
    import CampaignEmailTemplateItemMobile from './CampaignEmailTemplateItemMobile.vue';

    export default {
        name: 'CampaignEmailTemplates',
        mixins: [EmailCampaignTemplatesCacheMixin],
        methods: {
            gotoAddTemplate() {
                router.push({ name: 'campaignEmailTemplateAdd' });
            },
        },
        components: {
            'table-mobile': TableMobile,
            'table-desktop': TableDesktop,
            'campaign-email-desktop': CampaignEmailTemplateItemDesktop,
            'campaign-email-mobile': CampaignEmailTemplateItemMobile
        }
    }
</script>
