import moment from 'moment';
import { mapActions, mapGetters } from "vuex";
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SortableSearchMixin } from "./Searching/SortableSearchMixin";

export const CommunicationSearchMixin = {
    mixins: [SortableSearchMixin],
    data() {
        return {
            error: '',
            skip: 0,
            take: 10,
            searchTerm: '',
            returnDefault: true,
            sortKey: 'createdDate',
            timeoutId: null,
            ascending: false,
            communicationTypeId: 0,
            methodTypeId: 0,
            createdUtc: moment().subtract(1, 'month').utc()
        }
    },
    computed: {
        ...mapGetters('CommunicationSearches', [
            'findCommunicationSearch'
        ]),
        communicationSearchModel() {
            return {
                skip: this.skip,
                take: this.take,
                searchTerm: this.searchTerm,
                returnDefault: this.returnDefault,
                sortKey: this.sortKey,
                ascending: this.ascending,
                communicationTypeId: this.communicationTypeId,
                methodTypeId: this.methodTypeId,
                createdUtc: this.createdUtc
            }
        },
        communicationSearchModelValid() {
            return !!this.searchTerm || this.returnDefault;
        },
        communicationSearchKey() {
            return CacheHelper.objectCacheKey(this.communicationSearchModel);
        },
        communicationSearch() {
            return this.findCommunicationSearch(this.communicationSearchKey);
        },
        communications() {
            return !!this.communicationSearch ? this.communicationSearch.values : [];
        },
        searchingCommunications() {
            return !!this.communicationSearch && this.communicationSearch.loading;
        },
        communicationResultsReady() {
            return !!this.communicationSearch && !this.searchingCommunications;
        },
        moreCommunications() {
            return this.communications.length === this.take;
        },
        communicationPageNumber() {
            return 1 + (this.skip / this.take);
        }
    },
    watch: {
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshCommunicationSearch();
            }, 500);
        },
        sortModel() {
            this.refreshCommunicationSearch();
        },
        communicationSearchKey() {
            this.refreshCommunicationSearch();
        }
    },
    methods: {
        ...mapActions('CommunicationSearches', [
            'searchCommunications'
        ]),
        refreshCommunicationSearch() {
            if (!this.communicationSearch || !!this.communicationSearch.retry) {
                this.searchAndCacheCommunications();
            }
        },
        searchAndCacheCommunications() {
            const self = this;
            self.error = '';
            if (!self.communicationSearchModelValid) {
                return;
            }
            self.searchCommunications({ key: self.communicationSearchKey, payload: self.communicationSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        next() {
            if (this.moreCommunications) {
                this.skip += this.take;
                this.refreshCommunicationSearch();
            }
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshCommunicationSearch();
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshCommunicationSearch();
        }
    }
}
