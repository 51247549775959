<template>
    <section class="mb-3">
        <div class="accordion-header collapsed" aria-expanded="false" data-target="#collapse3" data-toggle="collapse">
            {{$t('Weight')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse3">
            <div class="card card-body">
                <div class="table-responsive">
                    <table class="table table-striped ps-table table-hover">
                        <thead>
                            <tr>
                                <th>{{$t('Date')}}</th>
                                <th>{{$t('Weight')}} ({{weightUnit}})</th>
                                <th>{{$t('Note')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{date | moment(momentJSFormat)}}</td>
                                <td>
                                    <input v-model="value" class="form-control" type="number">
                                    <validation-message v-if="weightValidator.value" :message="weightValidator.value"></validation-message>
                                </td>
                                <td>
                                    <input v-model="notes" class="form-control" type="text" name="name">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import { DateDisplayMixin } from "../../Shared/Time/DateDisplayMixin";
    import { WeightAddMixin } from "./WeightAddMixin";
    import { WeightSettingCacheMixin } from "./WeightSettingCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'PetAddWeight',
        mixins: [WeightSettingCacheMixin, WeightAddMixin, DateDisplayMixin],
        data() {
            return {
                addedPetId: '',
                date: new Date()
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'petAddedEvent'
            ]),
            petId() {
                return this.addedPetId;
            }
        },
        watch: {
            petAddedEvent(pet) {
                this.addedPetId = pet.id;
                this.tryAddWeight();
            },
            weightAddedEvent() {
                this.addedPetId = '';
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
