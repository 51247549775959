import de from './assets/translations/de.json';
import en from './assets/translations/en.json';
import es from './assets/translations/es.json';
import fr from './assets/translations/fr.json';
import sv from './assets/translations/sv.json';
import vuei18n from 'vue-i18n';
import vue from 'vue';

vue.use(vuei18n);

export default new vuei18n({
    locale: localStorage.getItem('lang') || 'en',
    messages: {
        de: de,
        en: en,
        es: es,
        fr: fr,
        sv: sv
    }
})