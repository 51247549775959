<template>
    <div>
        <div v-if="completed" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Site Details')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <logo-add v-model="logoStatus" :added-site-id="addedSiteId"></logo-add>
            <validated-input v-model="companyName" :title="$t('Company Name')" :validation="siteValidator.companyName"></validated-input>
            <validated-input v-model="name" :title="$t('Site Name')" :validation="siteValidator.name"></validated-input>
            <validated-input v-model="email" :title="$t('Email')" :validation="siteValidator.email"></validated-input>
            <slotted-input v-model="tagLine" :title="$t('Tag Line')"></slotted-input>
            <slotted-input v-model="contactNumber" :title="$t('Contact Number')"></slotted-input>
            <slotted-input v-model="website" :title="$t('Website')"></slotted-input>
        </template>
    </div> 
</template>

<script>
    import { mapState } from 'vuex';
    import { SiteAddMixin } from '../../Shared/SiteAddMixin';
    import SiteAddLogo from './SiteAddLogo.vue';
    import ValidatedInput from '../../Shared/Input/ValidatedInput.vue';
    import SlottedInput from '../../Shared/Input/SlottedInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'SiteAddDetails',
        mixins: [SiteAddMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        data() {
            return {
                logoStatus: {}
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            canAddSite() {
                return !this.completed;
            },
            completed() {
                return !!this.addedSiteId && !!this.logoStatus.completed
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.addingSite || !!this.logoStatus.loading
                }
            }
        },
        watch: {
            saveSiteEvent() {
                if (!!this.canAddSite) {
                    this.tryAddSite();
                }
            }
        },
        components: {
            'logo-add': SiteAddLogo,
            'validated-input': ValidatedInput,
            'slotted-input': SlottedInput,
            'error-banner': ErrorBanner
        }
    }
</script>
