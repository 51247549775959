<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t(communicationTypeName)}} SMS</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <site-select class="ml-auto d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <div class="d-flex flex-column flex-md-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <textarea v-model="value" class="form-control" rows="10"></textarea>
                        <div class="mw-400 d-flex justify-content-between">
                            <validation-message  class="w-100" v-if="smsTemplateValidator.value" :message="smsTemplateValidator.value"></validation-message>
                            <char-count-display class="w-100" :message="value"></char-count-display>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <cost-calculator :message="value"></cost-calculator>
                </div>
            </div>
        </div>
        <div class="fixed-action-btns action-btns d-flex flex-column align-items-start pt-0">
            <template-tags :communication-type-id="communicationTypeId"></template-tags>
            <div class="d-flex justify-content-between align-items-center col-12 mt-2 px-0">
                <div>
                    <button @click="save" v-bind:disabled="loadingSmsTemplate" class="btn btn-primary" type="button">
                        {{$t('Save')}}
                        <span v-if="loadingSmsTemplate" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
                <toggle-tags></toggle-tags>
            </div>
        </div>
    </div>
</template>

<script>
    import SiteSelect from '../../Account/SiteSelect.vue';
    import { SmsTemplateUploadMixin } from "../../Shared/SmsTemplateUploadMixin";
    import { CommunicationTemplateUploadMixin } from "./CommunicationTemplateUploadMixin";
    import SmsTemplateTags from "./SmsTemplateTags.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import TagsDisplayToggleButton from '../../Shared/Tags/TagsDisplayToggleButton.vue';
    import SmsCreditCostCalculator from '../SmsCreditCostCalculator.vue';
    import SmsCharacterCountDisplay from '../SmsCharacterCountDisplay.vue';

    export default {
        name: 'SmsTemplateUpload',
        mixins: [SmsTemplateUploadMixin, CommunicationTemplateUploadMixin],
        methods: {
            save() {
                this.uploadSmsTemplate();
            }
        },
        components: {
            'site-select': SiteSelect,
            'template-tags': SmsTemplateTags,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'toggle-tags': TagsDisplayToggleButton,
            'char-count-display': SmsCharacterCountDisplay,
            'cost-calculator': SmsCreditCostCalculator
        }
    }
</script>