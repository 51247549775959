<template>
    <tr v-bind:class="classType" class="pointer">
        <td @click="emailClick">
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a>{{typeName}}</a>
            </div>
        </td>
    </tr>
</template>

<script>
    import { CommunicationTypeItemMixin } from './CommunicationTypeItemMixin';

    export default {
        name: 'CommunicationTypeEmailItemDesktop',
        mixins: [CommunicationTypeItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
