<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="number">{{$t('Mobile tel')}}</label>
        </div>
        <div class="col-md-8">
            <mobile-input v-model="telephoneMobile" :placeholder="$t('Mobile tel')"></mobile-input>
            <validation-message v-if="validation" :message="validation"></validation-message>
            <template>
                <label v-if="searchingClients"><span class="inline-loader"></span> Searching for a matching mobile tel...</label>
                <ul v-else-if="showResults" class="form-control inner show mb-0" role="presentation" style="max-height: 150px; height: max-content; overflow-y: scroll">
                    <li class="mb-0" style="font-weight: 600; color: #F4457B;">Top {{exactMatchClients.length}} matching client(s) for the mobile tel:</li>
                    <li v-for="s in sitesWithClients" :key="s.id" class="mb-0">
                        <label class="group-header mb-0">{{s.name}}</label>
                        <template v-for="(client, index) in s.clients">
                            <div class="px-2 w-100" role="option" id="bs-select-1-0" tabindex="0" aria-setsize="6" aria-posinset="1">
                                <a class="pointer" @click="gotoClient(client.id)">{{index+1}}.) {{client.fullName}}</a>
                            </div>
                        </template>
                    </li>
                </ul>
                <label v-else-if="attempted && !showResults" style="color: #86BF4E"><i class="far fa-circle-check"></i> No matching results found for the mobile tel.</label>
            </template>
        </div>
    </div>
</template>

<script>
    import router from '../../routing';
    import { ArrayHelper } from "../../helpers/ArrayHelper";
    import { ClientSearchNoCacheMixin } from '../Shared/ClientSearchNoCacheMixin';
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import MobileInput from "../Shared/Input/MobileInput.vue"

    export default {
        name: 'ClientMobileFieldV2',
        mixins: [ClientSearchNoCacheMixin, SiteCacheMixin],
        props: {
            value: String,
            validation: String,
            clientId: String
        },
        data() {
            return {
                returnDefault: false
            }
        },
        computed: {
            telephoneMobile: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.searchTerm = value;
                    this.$emit('input', value);
                }
            },
            clientIds() {
                return this.clients.map(c => c.id);
            },
            exactMatchClients() {
                const filteredClients = this.clients.filter(c => c.id !== this.clientId);
                return filteredClients.filter(c => c.telephoneMobile === this.searchTerm);
            },
            sitesWithClients() {
                const clientsGroupedBySite = ArrayHelper.groupBy(this.exactMatchClients, 'siteId');
                const result = [];
                for (var i = 0; i < clientsGroupedBySite.length; i++) {
                    const clientGroup = clientsGroupedBySite[i];
                    const site = this.sites.find(site => site.id === clientGroup.key);
                    if (site) {
                        const siteWithClients = { ...site, clients: clientGroup.values };
                        result.push(siteWithClients);
                    }
                }
                return result;
            },
            showResults() {
                return this.sitesWithClients.length > 0;
            }
        },
        methods: {
            gotoClient(id) {
                if (!!id) {
                    const route = router.resolve({ name: 'clientEdit', params: { id: id } });
                    window.open(route.href, '_blank');
                }
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'mobile-input': MobileInput
        }
    }
</script>
