<template>
    <div @click="triggerNotificationBell" class="notification-indicator">
        <i class="fa-duotone fa-bell"></i>
        <span v-if="showCount" class="notifs-count">{{count}}</span>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'NotificationBell',
        computed: {
            ...mapState('Notifications', [
                'notifications'
            ]),
            count() {
                return this.notifications.filter(n => !n.viewed).length;
            },
            showCount() {
                return this.count > 0;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerNotificationBell'
            ])
        }
    }
</script>
