<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="name">{{$t('Breed')}}:</label>
        </div>
        <div class="col-md-8">
            {{breedName}}
        </div>
    </div>
</template>

<script>
    import { BreedCacheMixin } from "../Shared/BreedCacheMixin";

    export default {
        name: 'BreedItem',
        mixins: [BreedCacheMixin],
        props: {
            id: ''
        },
        computed: {
            breedId() {
                return !!this.id ? this.id : '';
            }
        }
    }
</script>
