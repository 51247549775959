import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const UserCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Authentication', [
            'user',
            'loading'
        ]),
        isOwner() {
            return !!this.user && this.user.isOwner;
        },
        isSiteAdmin() {
            return !!this.user && this.user.isSiteAdmin;
        },
        isSystemAdmin() {
            return !!this.user && this.user.isSystemAdmin;
        },
        canDelete() {
            return !!this.user && this.user.canDelete;
        }
    },
    methods: {
        ...mapActions('Authentication', [
            'getUser'
        ]),
        refreshUser() {
            if (!this.user && !this.loading) {
                this.tryGetUser();
            }
        },
        tryGetUser() {
            const self = this;
            self.error = '';
            self.getUser()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUser();
    }
}
