import moment from 'moment';
import { mapGetters } from 'vuex';
import { SiteCacheMixin } from '../Shared/SiteCacheMixin';
import { PaymentReceiptCacheMixin } from '../../mixins/PaymentReceipts/PaymentReceiptCacheMixin';
import { DocumentCacheMixin } from '../Shared/DocumentCacheMixin';
import process from 'process';

export const PurchaseHistoryItemMixin = {
    mixins: [SiteCacheMixin, PaymentReceiptCacheMixin, DocumentCacheMixin],
    props: {
        subscriptionPayment: Object
    },
    computed: {
        ...mapGetters('CreditPackSearches', [
            'findCreditPackCache'
        ]),
        creditPackCache() {
            return this.findCreditPackCache(this.subscriptionPayment.subscriptionEntityId);
        },
        creditPack() {
            return !!this.creditPackCache ? this.creditPackCache.value : null;
        },
        siteId() {
            return !!this.creditPack ? this.creditPack.siteId : '';
        },
        amount() {
            const floatNumber = parseFloat(this.subscriptionPayment.amount);
            const formattedFloatNumber = floatNumber.toFixed(2);
            return formattedFloatNumber.toString();
        },
        date() {
            return moment(this.subscriptionPayment.createdUtc).format('DD MMM YYYY');
        },
        subscriptionPaymentId() {
            return this.subscriptionPayment.id;
        },
        ownerId() {
            return !!this.paymentReceipt ? this.paymentReceipt.id : '';
        },
        ownerTypeId() {
            return 17;
        },
        document() {
            return this.documents.length > 0 ? this.documents[0] : null;
        },
        loading() {
            return !!this.loadingPaymentReceipt || !!this.loadingDocuments
        }
    },
    methods: {
        refreshDocuments() {
            // do nothing
        },
        downloadReceipt() {
            if (!this.paymentReceipt) {
                this.refreshPaymentReceipt();
            } else if (!this.document) {
                this.trySearchDocuments();
            } else {
                this.tryOpenDocument();
            }
        },
        tryOpenDocument() {
            if (!this.document) {
                return;
            }
            process.nextTick(() => window.open(this.document.href, '_blank'));
        },
        afterPaymentReceiptAdded() {
            this.trySearchDocuments();
        },
        afterSearchDocuments() {
            this.tryOpenDocument();
        }
    }
}
