import { mapActions } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper"
import { TagsCacheMixin } from "./TagsCacheMixin"

export const TagUploadMixin = {
    mixins: [TagsCacheMixin],
    data() {
        return {
            tagTypeId: ''
        }
    },
    computed: {
        tagAddModel() {
            return {
                tagTypeId: this.tagTypeId,
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        tagAddModelValid() {
            return this.tagFilterModelValid && !!this.tagAddModel.tagTypeId;
        },
        tag() {
            return this.tags.find(t => t.tagTypeId === this.tagTypeId);
        }
    },
    methods: {
        ...mapActions('Tags', [
            'addTag',
            'deleteTag'
        ]),
        toggleTag(tagType) {
            this.tagTypeId = tagType.id;
            if (!!this.tag) {
                this.deleteAndCacheTag();
            } else {
                this.addAndCacheTag();
            }
        },
        addAndCacheTag() {
            const self = this;
            if (!!self.tag || !self.tagAddModelValid) {
                return;
            }
            self.error = '';
            self.addTag({ key: self.tagsCacheKey, payload: self.tagAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        deleteAndCacheTag() {
            const self = this;
            if (!self.tag) {
                return;
            }
            self.error = '';
            self.deleteTag({ key: self.tagsCacheKey, id: self.tag.id })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
