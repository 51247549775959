<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <toggle-input v-model="displayEventColour" :title="$t('Display Event Colours?')">
            <template #tooltip>
                <tooltip>
                    <span>{{$t("Toggle to display event colours on the schedule page.")}}</span>
                </tooltip>
            </template>
        </toggle-input>
        <div class="row form-group" v-if="!!displayEventColour">
            <div class="col-md-4">
                <label>{{$t('Event Colours')}}</label>
            </div>
            <div class="col-md-8">
                <color-input v-model="Holiday" :title="$t('Holiday')"></color-input>
                <color-input v-model="Sickness" :title="$t('Sick Leave')"></color-input>
                <color-input v-model="Personal" :title="$t('Personal Event')"></color-input>
                <color-input v-model="Complimentary" :title="$t('Complimentary Leave')"></color-input>
                <color-input v-model="PublicHoliday" :title="$t('Public Holiday')"></color-input>
                <color-input v-model="Training" :title="$t('Training')"></color-input>
                <color-input v-model="Other" :title="$t('Other')"></color-input>
                <color-input v-model="Unknown" :title="$t('Unknown')"></color-input>
                <button @click="resetColours" class="btn btn-primary" type="button" v-bind:disabled="loadingSetting">
                    {{$t('Reset Colours')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { SiteResourceEventColourSettingUploadMixin } from "../../../mixins/Settings/Site/SiteResourceEventColourSettingUploadMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import SlottedColorInput from "../../Shared/Input/SlottedColorInput.vue";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import Tooltip from '../../Shared/Layout/Tooltip.vue';

    export default {
        name: 'SiteUpdateResourceEventColours',
        mixins: [SiteResourceEventColourSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'color-input': SlottedColorInput,
            'error-banner': ErrorBanner,
            'toggle-input': SlottedToggle,
            'tooltip': Tooltip
        }
    }
</script>
