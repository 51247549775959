import { SiteIdMixin } from "../../components/Shared/Site/SiteIdMixin"
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";

export const TaxCacheMixinV2 = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Taxes', [
            'loadedTaxes',
            'loadingTaxes',
            'taxes'
        ]),
        tax() {
            return this.taxes.find(t => t.siteId === this.siteId);
        }
    },
    watch: {
        siteId() {
            this.refreshTaxes();
        }
    },
    methods: {
        ...mapActions('Taxes', [
            'getTaxes'
        ]),
        tryGetTaxes() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getTaxes()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshTaxes() {
            if (!this.loadedTaxes && !this.loadingTaxes) {
                this.tryGetTaxes();
            }
        }
    },
    created() {
        this.refreshTaxes();
    }
}
