<template>
    <colour-input v-if="!loadingSetting" v-model="colour" :title="$t('Colour')"></colour-input>
</template>

<script>
    import { SettingCacheMixin } from '../../Shared/SettingCacheMixin';
    import { SiteResourceEventColourSettingMixin } from '../../../mixins/Settings/Site/SiteResourceEventColourSettingMixin'
    import { EventTypesCacheMixin } from '../../Shared/Events/EventTypesCacheMixin';
    import SlottedColorInput from '../../Shared/Input/SlottedColorInput.vue';

    export default {
        name: 'AddStaffEventColour',
        mixins: [EventTypesCacheMixin, SettingCacheMixin, SiteResourceEventColourSettingMixin],
        props: {
            eventTypeId: 0
        },
        data() {
            return {
                eventColour: ''
            }
        },
        computed: {
            colour: {
                get() {
                    return this.eventColour;
                },
                set(value) {
                    this.eventColour = value;
                    this.$emit('input', this.eventColour);
                }
            },
            selectedEvent() {
                return !!this.eventTypes ? this.eventTypes.find(et => et.id === this.eventTypeId) : null;
            },
            selectedEventName() {
                return !!this.selectedEvent ? this.selectedEvent.name : 'Unknown';
            },
            defaultColor() {
                return !!this.settingObject[this.selectedEventName] ? this.settingObject[this.selectedEventName] : this.settingObject['Unknown'];
            }
        },
        watch: {
            eventTypeId() {
                this.setDefaultColor();
            },
            defaultColor() {
                this.setDefaultColor();
            }
        },
        methods: {
            setDefaultColor() {
                this.colour = this.defaultColor;
            }
        },
        created() {
            this.setDefaultColor();
        },
        components: {
            'colour-input': SlottedColorInput
        }
    }
</script>
