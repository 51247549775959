<template>
    <div class="row form-group">
        <div class="col-md-4 d-none d-md-block">
            <label>{{$t('Preference')}}</label>
        </div>
        <div class="col-md-8">
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="marketing" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('Accept marketing')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="smsConfirmation" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('SMS confirmation')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="smsReminder" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('SMS reminder')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="smsMissYou" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('Miss You (SMS)')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="emailConfirmation" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('Email confirmation')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="emailReminder" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('Email reminder')}}</span>
                </label>
            </div>
            <div class="mb-2">
                <label class="ps-toggle-switch">
                    <input v-model="emailMissYou" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{$t('Miss You (Email)')}}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { NotificationSettingsAddMixin } from '../../Shared/NotificationSettingsAddMixin'
    import { mapState } from 'vuex';

    export default {
        name: 'OptionsClientPreferences',
        mixins: [NotificationSettingsAddMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            clientId() {
                return this.addedClientId;
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
                this.tryAddNotificationSettings();
                this.addedClientId = '';
            }
        }
    }
</script>
