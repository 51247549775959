import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';
import { SiteIdMixin } from '@/components/Shared/Site/SiteIdMixin';

export const EmailMarketingTemplateCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    mixins: [SiteIdMixin],
    computed: {
        ...mapGetters('EmailMarketingTemplates', [
            'findEmailMarketingTemplateCache'
        ]),
        communicationTypeId() {
            console.log("OVERRIDE COMPUTED --- Communication Type ID!")
        },
        emailMarketingTemplateCackeKey() {
            return `${this.siteId}/${this.communicationTypeId}`;
        },
        emailMarketingTemplateCache() {
            return this.findEmailMarketingTemplateCache(this.emailMarketingTemplateCackeKey);
        },
        emailMarketingTemplate() {
            return !!this.emailMarketingTemplateCache ? this.emailMarketingTemplateCache.value : null;
        },
        loadingEmailMarketingTemplate() {
            return !!this.emailMarketingTemplateCache && this.emailMarketingTemplateCache.loading;
        },
        fetchedEmailMarketingTemplate() {
            return !!this.emailMarketingTemplateCache && this.emailMarketingTemplateCache.fetched;
        }
    },
    watch: {
        emailMarketingTemplateCackeKey() {
            this.refreshEmailMarketingtTemplate();
        }
    },
    methods: {
        ...mapActions('EmailMarketingTemplates', [
            'findEmailMarketingTemplate'
        ]),
        refreshEmailMarketingtTemplate() {
            if (!this.emailMarketingTemplateCache || !!this.emailMarketingTemplateCache.retry) {
                this.tryfindEmailMarketingTemplate();
            }
        },
        tryfindEmailMarketingTemplate() {
            const self = this;
            if (!self.communicationTypeId) {
                return;
            }
            self.error = '';
            self.findEmailMarketingTemplate({ key: self.emailMarketingTemplateCackeKey, communicationTypeId: self.communicationTypeId })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailMarketingtTemplate();
    }
}
