import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const BreedAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('BreedAggregateSearches', [
            'findBreedAggregatesSearch'
        ]),
        aggregateSearch() {
            return this.findBreedAggregatesSearch(this.aggregateSearchKey);
        },
        breedAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingBreedAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('BreedAggregateSearches', [
            'searchBreedAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchBreedAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
