<template>
    <section class="mb-3">
        <!-- Mobile will use this dropdown to change site. Hide if only 1 site -->
        <site-select class="d-md-none d-block"></site-select>
        <!-- Desktop will use sidenav -->
        <nav class="pet-sidenav sidenav d-none d-md-block">
            <ul>
                <site-list-item @site-selected="setSelected(site.id)" v-for="site in sites" :key="site.id" :site="site" :is-selected-site="siteId == site.id"></site-list-item>
            </ul>
        </nav>
    </section>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import { SiteIdMixin } from "../Shared/Site/SiteIdMixin";
    import SiteSelect from "../Account/SiteSelect.vue";
    import SiteListItem from "./SiteListItem.vue";

    export default {
        name: "SiteList",
        mixins: [SiteCacheMixin, SiteIdMixin],
        components: {
            "site-select": SiteSelect,
            "site-list-item": SiteListItem,
        }
    }
</script>
