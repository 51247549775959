import { Api } from "../../services/Api";

const state = () => ({
    clientMessagesCaches: []
})

const getters = {
    findClientMessagesCache: (state) => key => state.clientMessagesCaches.find(cmc => cmc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.clientMessagesCaches.find(cmc => cmc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientMessagesCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setClientMessages(state, { key, messages }) {
        const existing = state.clientMessagesCaches.find(cmc => cmc.key === key);
        if (!!existing) {
            existing.values = messages;
            existing.loading = false;
        } else {
            state.clientMessagesCaches.push({ key: key, values: messages, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.clientMessagesCaches.find(cmc => cmc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendClientMessage(state, { key, message }) {
        const existing = state.clientMessagesCaches.find(cmc => cmc.key === key);
        if (!!existing) {
            existing.values.push(message);
        } else {
            state.clientMessagesCaches.push({ key: key, values: [message], loading: false });
        }
    }
}

const actions = {
    findClientMessages({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('ClientMessage?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setClientMessages', { key: key, messages: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addClientMessage({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('ClientMessage', payload)
                .then(response => {
                    commit('appendClientMessage', { key: key, message: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
