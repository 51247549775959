import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';

export const EmailTemplateSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('EmailTemplateSettings', [
            'loadingEmailTemplateSettings',
            'loadedEmailTemplateSettings',
            'emailTemplateSettings',
            'emailTemplateSettingsClearedEvent'
        ]),
        emailTemplateSetting() {
            return this.emailTemplateSettings.find(s => s.siteId == this.siteId);
        }
    },
    watch: {
        siteId() {
            this.refreshEmailTemplateSettings();
        },
        emailTemplateSettingsClearedEvent() {
            this.refreshEmailTemplateSettings();
        }
    },
    methods: {
        ...mapActions('EmailTemplateSettings', [
            'getEmailTemplateSettings'
        ]),
        refreshEmailTemplateSettings() {
            if (!this.loadedEmailTemplateSettings && !this.loadingEmailTemplateSettings) {
                this.tryGetEmailTemplateSettings();
            }
        },
        tryGetEmailTemplateSettings() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getEmailTemplateSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailTemplateSettings();
    }
}
