import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { AppointmentScheduleOrdersCacheMixin } from './AppointmentScheduleOrdersCacheMixin';

export const AppointmentScheduleOrdersAddMixin = {
    mixins: [AppointmentScheduleOrdersCacheMixin],
    computed: {
        ...mapGetters('AppointmentScheduleUpload', [
            'appointmentScheduleOrders'
        ]),
        appointmentScheduleOrdersAddModel() {
            return Object.assign({ scheduleId: this.scheduleId }, this.appointmentScheduleOrders);
        },
        appointmentScheduleOrdersAddModelValid() {
            return !!this.appointmentScheduleOrdersAddModel.scheduleId;
        }
    },
    methods: {
        ...mapActions('AppointmentScheduleOrders', [
            'addAppointmentScheduleOrders'
        ]),
        tryAddAppointmentScheduleOrders() {
            const self = this;
            if (!self.appointmentScheduleOrdersAddModelValid) {
                return;
            }
            self.error = '';
            return self.addAppointmentScheduleOrders({ key: self.scheduleId, payload: self.appointmentScheduleOrdersAddModel })
                .then(response => self.afterAddAppointmentScheduleOrders(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddAppointmentScheduleOrders() {
            //override if necessary
        }
    }
}
