import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";
import { SiteHelper } from "../../../helpers/SiteHelper";

export const SiteScheduleSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Calendar';
        },
        defaultSettingObject() {
            return SiteHelper.getDefaultCalendarSettings();
        }
    }
}
