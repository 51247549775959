<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Name')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="name" class="form-control" type="text" value="" :placeholder="$t('Name')">
                <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Mobile')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="mobile" class="form-control" type="tel" value="" :placeholder="$t('Mobile')">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Address')}}</label>
            </div>
            <div class="col-md-8">
                <textarea v-model="otherInfo" id="VetAddress" rows="8" class="form-control" name="VetAddress" maxlength="80" :placeholder="$t('Address')"></textarea>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Medical notes')}}</label>
            </div>
            <div class="col-md-8">
                <textarea v-model="noteText" id="MedicalNotes" rows="8" class="form-control" name="MedicalNotes" maxlength="499" :placeholder="$t('Medical notes')"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import { ContactUploadMixin } from '../Shared/Contacts/ContactUploadMixin';
    import { mapState } from 'vuex';
    import { NoteUploadMixin } from '../Shared/Notes/NoteUploadMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ClientVetContactSection',
        mixins: [ContactUploadMixin, NoteUploadMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            ownerId() {
                return this.addedClientId;
            },
            ownerTypeId() {
                return 2;
            },
            noteTypeId() {
                return 3;
            },
            contactTypeId() {
                return 3;
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
                this.tryAddContact();
                this.tryAddNote();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
