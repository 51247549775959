<template>
    <tbody>
        <tr :class="classType">
            <td>{{date}}</td>
            <td class="pointer" @click="onClientClick">{{clientName}}</td>
            <td class="pointer" @click="onAppointmentClick">{{appointmentDate}}</td>
            <td class="pointer" @click="onAppointmentClick">{{appointmentTime}}</td>
            <td class="text-center" v-bind:class="statusColor">
                {{$t(status)}}
                <i v-if="summary" :title="summary" class="far" v-bind:class="statusIcon"></i>
            </td>
            <td class="text-right">{{amount}}</td>
            <td class="text-right">
                <span v-if="refunds.length > 0" @click="showRefunds=!showRefunds" class="pointer">
                    {{refundAmount}}
                    <i class="fa fa-chevron-down"></i>
                </span>
            </td>
        </tr>
        <tr v-if="showRefunds" v-for="refund in refunds" class="refund-item">
            <td>{{refund.createdUtc | moment(dateFormat)}}</td>
            <td></td>
            <td></td>
            <td class="text-center">{{refund.isPartial ? $t('Partial') : $t('Full')}}</td>
            <td class="text-right">-{{refund.amount.toFixed(2)}}</td>
            <td></td>
        </tr>
    </tbody>
</template>

<script>
    import { PaymentReportItemMixin } from "./PaymentReportItemMixin";

    export default {
        name: 'PaymentReportItemDesktop',
        mixins: [PaymentReportItemMixin],
        props: {
            number: Number
        }
    }
</script>
