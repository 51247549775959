<template>
    <div>
        <div v-if="!!timeZone" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Time Zone')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Time Zone')}}</label>
                </div>
                <div class="col-md-8">
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                            {{selectedOptionDescription}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="option in timeZoneOptions"
                                    class="dropdown-item pointer"
                                    :key="option.id"
                                    @click="setTimeZoneId(option.id)"
                                    v-bind:class="{'active': option.id === timeZoneId}">
                                    {{option.description}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { TimeZoneAddMixin } from "../../Shared/Time/Zone/TimeZoneAddMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import moment from 'moment';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AccountSettingCacheMixin } from "../../../mixins/Settings/Account/AccountSettingCacheMixin";

    export default {
        name: 'SiteAddTimeZone',
        mixins: [TimeZoneAddMixin, AccountSettingCacheMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            accountSettingKey() {
                return 'DefaultTimeZone';
            },
            canAddTimeZone() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !this.timeZoneId || !!this.timeZone;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingTimeZone
                }
            },
            selectedOption() {
                return this.timeZoneOptions.find(tzo => tzo.id == this.timeZoneId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.selectedOption.description : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            accountSetting() {
                this.setDefaultTimeZone();
            },
            canAddTimeZone(value) {
                if (value) {
                    this.tryAddTimeZone();
                }
            },
            saveSiteEvent() {
                if (this.canAddTimeZone) {
                    this.tryAddTimeZone();
                }
            }
        },
        methods: {
            setDefaultTimeZone() {
                this.timeZoneId = !!this.accountSettingObject ? this.accountSettingObject.timeZoneId : moment.tz.guess();
            },
            setTimeZoneId(value) {
                this.timeZoneId = value;
            },
            tryFetchTimeZone() {
                //do nothing
            }
        },
        components: {
            'error-banner': ErrorBanner
        },
        created() {
            this.setDefaultTimeZone();
        }
    }
</script>
