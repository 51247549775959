export const CacheHelper = {
    ownerRequestCacheKey(ownerRequestModel) {
        return ownerRequestModel.ownerId + ownerRequestModel.ownerTypeId;
    },
    ownerCacheKey(ownerModel) {
        return ownerModel.ownerId + ownerModel.ownerType.id;
    },
    objectCacheKey(obj) {
        var key = '';
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                key += obj[property];
            }
        }
        return key;
    },
    fullObjectCacheKey(obj) {
        var key = '';
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                key += property;
                key += obj[property];
            }
        }
        return key;
    }
}
