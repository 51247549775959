import { mapActions, mapGetters } from "vuex"
import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";
import { SmsDefaultCacheMixin } from "./SmsDefaultCacheMixin";

export const SmsTemplateCacheMixin = {
    mixins: [SiteIdMixin, SmsDefaultCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('SmsTemplates', [
            'getSmsTemplateCache'
        ]),
        communicationTypeId() {
            console.log('!!!OVERRIDE COMPUTED - communicationTypeId!!!');
        },
        smsTemplateQuery() {
            return {
                siteId: this.siteId,
                communicationTypeId: this.communicationTypeId
            }
        },
        smsTemplateQueryValid() {
            return !!this.smsTemplateQuery.siteId && !!this.smsTemplateQuery.communicationTypeId;
        },
        smsTemplateCacheKey() {
            return CacheHelper.objectCacheKey(this.smsTemplateQuery);
        },
        smsTemplateCache() {
            return this.getSmsTemplateCache(this.smsTemplateCacheKey);
        },
        smsTemplate() {
            return !!this.smsTemplateCache ? this.smsTemplateCache.value : null;
        },
        loadingSmsTemplate() {
            return !!this.smsTemplateCache && this.smsTemplateCache.loading;
        },
        loadedSmsTemplate() {
            return !!this.smsTemplate
        }
    },
    watch: {
        smsTemplateQuery() {
            this.refreshSmsTemplate();
        }
    },
    methods: {
        ...mapActions('SmsTemplates', [
            'findSmsTemplate'
        ]),
        refreshSmsTemplate() {
            if (!this.smsTemplateCache || !!this.smsTemplateCache.retry) {
                this.findAndCacheSmsTemplate();
            }
        },
        findAndCacheSmsTemplate() {
            const self = this;
            if (!self.smsTemplateQueryValid) {
                return;
            }
            self.error = '';
            self.smsTemplateAttempted = false;
            self.findSmsTemplate({ key: self.smsTemplateCacheKey, payload: self.smsTemplateQuery })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.smsTemplateAttempted = true );
        }
    },
    created() {
        this.refreshSmsTemplate();
    }
}