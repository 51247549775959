import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    migrationCaches: []
})

const getters = {
    findMigrationCache: (state) => key => state.migrationCaches.find(x => x.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.migrationCaches.find(x => x.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.migrationCaches.push({ key: key, value: null, loading: false });
        }
    },
    setMigration(state, { key, migration }) {
        const existing = state.migrationCaches.find(x => x.key === key);
        if (!!existing) {
            existing.value = migration;
            existing.loading = false;
        } else {
            state.migrationCaches.push({ key: key, value: migration, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.migrationCaches.find(x => x.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchMigration({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('Migration')
                .then(response => {
                    commit('setMigration', { key: key, migration: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                });
        });
    },
    runMigration({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Migration', payload)
                .then(response => {
                    commit('setMigration', { key: key, migration: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
