import { CountryCacheMixin } from "../../components/Shared/Country/CountryCacheMixin";
import { SiteCacheMixin } from "../../components/Shared/SiteCacheMixin";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapActions } from 'vuex';
import { SubscriptionUpgradeCacheMixin } from "./SubscriptionUpgradeCacheMixin";

export const SubscriptionUpgradeUpdateMixin = {
    mixins: [SubscriptionUpgradeCacheMixin, CountryCacheMixin, SiteCacheMixin],
    data() {
        return {
            additionalSites: 0,
            yearly: false,
            discountCodes: []
        }
    },
    computed: {
        siteId() {
            return this.sites.length > 0 ? this.sites[0].id : '';
        },
        subscriptionUpgradeTypeId() {
            return !!this.subscriptionUpgrade ? this.subscriptionUpgrade.subscriptionType.id : '';
        },
        subscriptionUpgradeUpdateModel() {
            return {
                subscriptionTypeId: this.subscriptionUpgradeTypeId,
                additionalSites: this.additionalSites,
                yearly: this.yearly,
                currency: this.currency === 'GBP' ? 'GBP' : 'USD',
                discountCodes: this.discountCodes
            }
        },
        subscriptionUpgradeUpdateModelValid() {
            const model = this.subscriptionUpgradeUpdateModel;
            return !!model.subscriptionTypeId && model.additionalSites > -1;
        }
    },
    watch: {
        subscriptionUpgrade() {
            this.refreshSubscriptionUpgradeModel();
        }
    },
    methods: {
        ...mapActions('SubscriptionUpgrade', [
            'updateSubscriptionUpgrade'
        ]),
        refreshSubscriptionUpgradeModel() {
            this.additionalSites = !!this.subscriptionUpgrade ? this.subscriptionUpgrade.additionalSites : 0;
            this.yearly = !!this.subscriptionUpgrade ? this.subscriptionUpgrade.yearly : false;
            this.discountCodes = !!this.subscriptionUpgrade ? this.subscriptionUpgrade.discounts.map(d => d.code) : [];
        },
        tryUpdateSubscriptionUpgrade() {
            const self = this;
            if (!self.subscriptionUpgradeUpdateModelValid) {
                return;
            }
            self.error = '';
            self.updateSubscriptionUpgrade(self.subscriptionUpgradeUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionUpgradeModel();
    }
}
