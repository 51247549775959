<template>
    <full-calendar id="UserResourceCalendar" ref="fullCalendar" :options="config">
        <template #eventContent="{ event }">
            <calendar-item :event="event"></calendar-item>
        </template>
    </full-calendar>
</template>

<script>
    import { TimeZoneMixin } from "../Shared/Time/Zone/TimeZoneMixin";
    import { OpeningHoursCacheMixin } from "../Shared/OpeningHours/OpeningHoursCacheMixin";
    import moment from 'moment-timezone'
    import FullCalendar from '@fullcalendar/vue';
    import momentTimezonePlugin from '@fullcalendar/moment-timezone';
    import bootstrapPlugin from '@fullcalendar/bootstrap';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
    import UserResourceCalendarItem from "./UserResourceCalendarItem.vue";

    export default {
        name: 'UserResourceFullCalendar',
        mixins: [OpeningHoursCacheMixin, TimeZoneMixin],
        props: {
            value: Date,
            events: Array,
        },
        data() {
            return {
                calendar: {
                    gotoDate() { }
                }
            }
        },
        computed: {
            config() {
                return {
                    plugins: [momentTimezonePlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin, resourceTimeGridPlugin, dayGridPlugin],
                    schedulerLicenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
                    timeZone: this.selectedTimeZoneId,
                    events: this.events,
                    editable: false,
                    selectable: false,
                    dayMaxEvents: true,
                    weekends: true,
                    height: "auto",
                    nowIndicator: false,
                    initialView: 'dayGridMonth',
                    views: {
                        dayGridMonth: {
                            dayHeaderContent: (arg) => {
                                var dayName = arg.date.toLocaleDateString(undefined, { weekday: 'long' });
                                var dayId = arg.date.getDay();
                                var hours = this.getOpeningHours(dayId);
                                return dayName + ' ' + hours;
                            }
                        }
                    }
                }
            }
        },
        methods: {
            getOpeningHours(dayId) {
                var dayInfo = this.openingHours.find(day => day.dayOfWeek.id === dayId);
                if (!!dayInfo) {
                    if (dayInfo.closed) {
                        return 'CLOSED';
                    } else {
                        const openingTime = moment(dayInfo.openingLocal).format('HH:mm');
                        const closingTime = moment(dayInfo.closingLocal).format('HH:mm');
                        return `${openingTime}-${closingTime}`;
                    }
                }
            },
        },
        mounted() {
            this.calendar = this.$refs.fullCalendar.getApi();
            this.calendar.gotoDate(this.value);
        },
        components: {
            FullCalendar,
            'calendar-item': UserResourceCalendarItem
        }
    }
</script>

<style>
    @media screen and (max-width: 768px) {
        #UserResourceCalendar {
            font-size: 0.4rem;
        }

            #UserResourceCalendar .fc-daygrid-day-number {
                font-size: 0.4rem;
            }

            #UserResourceCalendar .fc-col-header-cell-cushion {
                font-size: 0.4rem;
                white-space: normal !important;
            }
    }

    @media screen and (min-width: 768px) {
        #UserResourceCalendar .fc-col-header-cell-cushion {
            padding-inline: 1rem;
            font-size: 0.75rem;
            white-space: normal !important;
        }
    }
</style>
