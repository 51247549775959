import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ClientUserCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ClientUsers', [
            'findClientUserCache'
        ]),
        clientId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
        clientUserCache() {
            return this.findClientUserCache(this.clientId);
        },
        clientUser() {
            return !!this.clientUserCache ? this.clientUserCache.value : null;
        },
        loadingClientUser() {
            return !!this.clientUserCache && this.clientUserCache.loading;
        }
    },
    watch: {
        clientId() {
            this.refreshClientUser();
        }
    },
    methods: {
        ...mapActions('ClientUsers', [
            'getClientUser'
        ]),
        refreshClientUser() {
            if (!this.clientUserCache || !!this.clientUserCache.retry) {
                this.tryGetClientUser();
            }
        },
        tryGetClientUser() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.getClientUser(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientUser();
    }
}
