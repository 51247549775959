export const SearchTermDelayMixin = {
    data() {
        return {
            inputText: '',
            searchTerm: '',
            skip: 0,
            timeoutId: null
        }
    },
    watch: {
        inputText(value) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.searchTerm = value;
                this.skip = 0;
            }, 500);
        }
    }
}
