<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Staff')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <div v-if="resourceCache" class="dropdown bootstrap-select custom-select form-control">
                    <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Select staff">
                        <div class="filter-option">
                            <div class="filter-option-inner">
                                <div class="filter-option-inner-inner">
                                    {{summaryString}}
                                </div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu">
                        <div class="bs-searchbox">
                            <input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list">
                        </div>
                        <div class="inner show w-100" role="listbox" id="bs-select-3" tabindex="-1" style="overflow-x: auto;">
                            <ul class="dropdown-menu inner show d-flex flex-column" role="presentation" style="width: max-content;">
                                <resource-item v-for="resource in resources"
                                               :key="resource.id"
                                               :resource="resource"
                                               :unavailabilities="unavailabilities"
                                               :selected="resource.id===value"
                                               :preferred="resource.id===preferredId"
                                               @resourceClicked="onResourceClicked"></resource-item>
                            </ul>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <span class="inline-loader"></span>
                </template>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import { SettingCacheMixin } from "../../Shared/SettingCacheMixin";
    import { UnavailabilitiesCacheMixin } from "../../Shared/UnavailabilitiesCacheMixin";
    import EditResourceListItem from "./EditResourceListItem.vue";

    export default {
        name: 'EditResource',
        mixins: [ResourceCacheMixin, UnavailabilitiesCacheMixin, SettingCacheMixin],
        props: {
            value: String
        },
        computed: {
            ownerId() {
                return !!this.appointment ? this.appointment.petId : '';
            },
            ownerTypeId() {
                return 3;
            },
            settingKey() {
                return 'PreferredResource';
            },
            defaultSettingObject() {
                return {
                    id: ''
                };
            },
            preferredId() {
                return this.settingObject.id;
            },
            resource() {
                return this.resources.find(r => r.id === this.value);
            },
            resourceId() {
                return !!this.resource ? this.resource.id : '';
            },
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            unavailability() {
                return this.unavailabilities.find(u => u.resourceId === this.resourceId);
            },
            unavailabilityMessage() {
                return this.unavailability ? ' - ' + this.unavailability.message : '';
            },
            summaryString() {
                return !!this.resource ? this.resourceName + this.unavailabilityMessage : 'Select staff';
            }
        },
        methods: {
            onResourceClicked(resource) {
                this.$emit('input', resource.id);
            }
        },
        components: {
            'resource-item': EditResourceListItem
        }
    }
</script>
