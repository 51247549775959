import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { CacheHelper } from '../../helpers/CacheHelper.js';
import { mapGetters, mapActions } from 'vuex';

export const DocumentCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Documents', [
            'findDocumentCache'
        ]),
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            console.log('!!!OVERRIDE COMPUTED - ownerTypeId!!!');
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        ownerQueryModelValid() {
            const model = this.ownerQueryModel;
            return !!model.ownerId && !!model.ownerTypeId;
        },
        documentCacheKey() {
            return CacheHelper.ownerRequestCacheKey(this.ownerQueryModel);
        },
        documentCache() {
            return this.findDocumentCache(this.documentCacheKey);
        },
        documents() {
            return !!this.documentCache ? this.documentCache.values : [];
        },
        loadingDocuments() {
            return !!this.documentCache && this.documentCache.loading;
        }
    },
    watch: {
        ownerQueryModel() {
            this.refreshDocuments();
        }
    },
    methods: {
        ...mapActions('Documents', [
            'searchDocuments'
        ]),
        refreshDocuments() {
            if (!this.documentCache || !!this.documentCache.retry) {
                this.trySearchDocuments();
            }
        },
        trySearchDocuments() {
            const self = this;
            self.error = '';
            if (!self.ownerQueryModelValid) {
                return;
            }
            self.searchDocuments({ key: self.documentCacheKey, payload: self.ownerQueryModel })
                .then(response => self.afterSearchDocuments(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterSearchDocuments() {
            // override if necessary
        }
    },
    created() {
        this.refreshDocuments();
    }
}
