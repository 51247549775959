import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { UserResourceCacheMixin } from "./UserResourceCacheMixin";

export const UserResourceDeleteMixin = {
    mixins: [UserResourceCacheMixin],
    methods: {
        ...mapActions('UserResources', [
            'deleteUserResources'
        ]),
        afterDeleteUserResources() {
            // to override
        },
        tryDeleteUserResources() {
            const self = this;
            if (!self.userId || self.userResources.length === 0) {
                return;
            }
            self.error = '';
            self.deleteUserResources(self.userId)
                .then(() => self.afterDeleteUserResources())
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
