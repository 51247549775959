<template>
    <section class="mh-100 mb-3">
        <div class="card card-body">
            <div class="dashboard-card">
                <span class="fa-stack fa-2x float-left">
                    <i class="fa-thin fa-square fa-stack-2x color-medium-pink"></i>
                    <i class="fa-solid fa-calendar-days color-medium-turquoise fa-stack-1x fa-inverse"></i>
                </span>
                <div class="card-content">
                    <div v-for="total in totals" :key="total.id">
                        <h6>
                            {{total.count}}
                            {{$t(total.description)}}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { AppointmentAggregateSearchMixin } from "../Shared/Aggregates/AppointmentAggregateSearchMixin";

    export default {
        name: 'AppointmentAggregateTotals',
        mixins: [AppointmentAggregateSearchMixin],
        computed: {
            totals() {
                const totals = [];
                for (var i = 0; i < this.appointmentAggregates.length; i++) {
                    const statuses = this.appointmentAggregates[i].statuses;
                    for (var j = 0; j < statuses.length; j++) {
                        const aggregate = statuses[j];
                        const total = totals.find(t => t.id === aggregate.status.id);
                        if (!!total) {
                            total.count += aggregate.count;
                        } else {
                            totals.push({
                                id: aggregate.status.id,
                                description: aggregate.status.description,
                                count: aggregate.count
                            });
                        }
                    }
                }
                return totals.sort((a, b) => a.id - b.id);
            }
        },
        methods: {
            trySearchAggregates() {
                // do nothing
            }
        }
    }
</script>
