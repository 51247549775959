import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ReportSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ReportSettings', [
            'fetchingReportSettings',
            'fetchedReportSettings',
            'reportSettings'
        ])
    },
    methods: {
        ...mapActions('ReportSettings', [
            'fetchReportSettings'
        ]),
        refreshReportSettings() {
            if (!this.fetchedReportSettings && !this.fetchingReportSettings) {
                this.tryFetchReportSettings();
            }
        },
        tryFetchReportSettings() {
            const self = this;
            self.error = '';
            self.fetchReportSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshReportSettings();
    }
}
