import { DateFormatOptionsCacheMixin } from "./DateFormatOptionsCacheMixin";
import { DateFormatSettingCacheMixin } from "./DateFormatSettingCacheMixin";

export const DateDisplayMixin = {
    mixins: [DateFormatOptionsCacheMixin, DateFormatSettingCacheMixin],
    computed: {
        dateFormatId() {
            return !!this.dateFormatSetting ? this.dateFormatSetting.dateFormatId : 1;
        },
        dateFormatOption() {
            return this.dateFormatOptions.find(dfo => dfo.id === this.dateFormatId);
        },
        momentJSFormat() {
            return !!this.dateFormatOption ? this.dateFormatOption.momentJS : 'DD MMM YYYY';
        },
        cSharpFormat() {
            return !!this.dateFormatOption ? this.dateFormatOption.cSharp : 'dd MMM yyyy';
        }
    }
}
