<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Name')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="name" class="form-control" type="text" value="" :placeholder="$t('Name')">
                <validation-message v-if="contactValidator.name" :message="contactValidator.name"></validation-message>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="number">{{$t('Mobile')}}</label>
            </div>
            <div class="col-md-8">
                <input v-model="mobile" class="form-control" type="tel" value="" :placeholder="$t('Mobile')">
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ContactUploadMixin } from '../Shared/Contacts/ContactUploadMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ClientAltContactSection',
        mixins: [ContactUploadMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent'
            ]),
            ownerId() {
                return this.addedClientId;
            },
            ownerTypeId() {
                return 2;
            },
            contactTypeId() {
                return 2;
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
                this.tryAddContact();
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
