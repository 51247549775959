import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionUpgradeSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionUpgradeSettings', [
            'fetchedSubscriptionUpgradeSettings',
            'fetchingSubscriptionUpgradeSettings',
            'subscriptionUpgradeSettings'
        ])
    },
    methods: {
        ...mapActions('SubscriptionUpgradeSettings', [
            'getSubscriptionUpgradeSettings'
        ]),
        refreshSubscriptionUpgradeSettings() {
            if (!this.fetchedSubscriptionUpgradeSettings && !this.fetchingSubscriptionUpgradeSettings) {
                this.tryGetSubscriptionUpgradeSettings();
            }
        },
        tryGetSubscriptionUpgradeSettings() {
            const self = this;
            self.error = '';
            self.getSubscriptionUpgradeSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionUpgradeSettings();
    }
}
