<template>
    <div class="form-group row">
        <div v-if="!!address" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Address')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="col-md-4">
                <label>{{$t('Address')}}</label>
            </div>
            <div class="col-md-8">
                <address-input v-model="addressLines"></address-input>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { AddressAddMixin } from "../../Shared/Address/AddressAddMixin";
    import AddressInputV2 from "../../Shared/Address/AddressInputV2.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AccountSettingCacheMixin } from "../../../mixins/Settings/Account/AccountSettingCacheMixin";

    export default {
        name: 'SiteAddAddress',
        mixins: [AddressAddMixin, AccountSettingCacheMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            ownerQueryModel() {
                return {
                    ownerId: this.addedSiteId,
                    ownerTypeId: 12
                }
            },
            accountSettingKey() {
                return 'DefaultAddress';
            },
            canAddAddress() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return this.addressAddModelDefault || !!this.address;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingAddress
                }
            }
        },
        watch: {
            accountSetting() {
                this.setAddressLines(this.accountSettingObject);
            },
            canAddAddress(value) {
                if (value) {
                    this.tryAddAddress();
                }
            },
            saveSiteEvent() {
                if (this.canAddAddress) {
                    this.tryAddAddress();
                }
            }
        },
        methods: {
            trySearchAddresses() {
                // do nothing
            }
        },
        components: {
            'address-input': AddressInputV2,
            'error-banner': ErrorBanner
        },
        created() {
            this.setAddressLines(this.accountSettingObject);
        }
    }
</script>
