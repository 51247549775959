<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Sites')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav">
                <div class="d-block">
                    <button @click="openSiteAdd" class="mobile-header-btn">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">Add site</span>
                    </button>
                </div>
            </div>
            <div class="header-tools">
                <button @click="openSiteAdd" class="btn-add btn btn-secondary" v-bind:disabled="fetchingSiteAddAllowed">
                    <i class="fas fa-plus"></i>
                    {{$t('Add site')}}
                </button>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <div class="row">
                <div class="col-12 col-md-auto">
                    <site-list></site-list>
                </div>
                <div class="col">
                    <site-update></site-update>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteUpdateMixin } from '@/components/Shared/SiteUpdateMixin';
    import { SiteAddAllowedMixin } from '../Shared/SiteAddAllowedMixin';
    import router from '../../routing';
    import SiteList from './SiteList.vue';
    import SiteUpdateV2 from './SiteUpdate/SiteUpdateV2.vue';

    export default {
        name: 'SiteDisplay',
        mixins: [SiteUpdateMixin, SiteAddAllowedMixin],
        components: {
            'site-list': SiteList,
            'site-update': SiteUpdateV2
        },
        created() {
            if (!this.site) {
                router.push({ name: 'sites' });
            }
        }
    }
</script>
