import { AppointmentCacheMixin } from "./AppointmentCacheMixin";
import moment from 'moment';
import { TimeZoneMixin } from "../Time/Zone/TimeZoneMixin";

export const AppointmentUpdateMixin = {
    mixins: [AppointmentCacheMixin, TimeZoneMixin],
    data() {
        return {
            resourceId: '',
            startUtc: '',
            duration: 30,
            status: null
        }
    },
    computed: {
        appointmentUpdateModel() {
            return Object.assign({}, this.appointment, {
                resourceId: this.resourceId,
                startUtc: this.startUtc,
                endUtc: moment(this.startUtc).add(this.duration, 'minutes').utc().format(),
                status: this.status,
                timeZoneId: this.selectedTimeZoneId
            });
        },
        appointmentUpdateModelValid() {
            const model = this.appointmentUpdateModel;
            return !!model.resourceId && !!model.startUtc && !!model.endUtc && !!model.status;
        },
        appointmentUpdateModelModified() {
            return JSON.stringify(this.appointmentUpdateModel) !== JSON.stringify(this.appointment);
        }
    },
    watch: {
        appointment() {
            this.refreshAppointmentModel();
        }
    },
    methods: {
        refreshAppointmentModel() {
            this.resourceId = !!this.appointment ? this.appointment.resourceId : '';
            this.startUtc = !!this.appointment ? this.appointment.startUtc : '';
            this.duration = !!this.appointment ? moment.duration(moment(this.appointment.endUtc).diff(moment(this.appointment.startUtc))).asMinutes() : '';
            this.status = !!this.appointment ? this.appointment.status : null;
        }
    },
    created() {
        this.refreshAppointmentModel();
    }
}
