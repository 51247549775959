import { AppointmentHelper } from "../../helpers/AppointmentHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { AppointmentDepositCacheMixin } from "./AppointmentDepositCacheMixin"
import { mapActions } from 'vuex';

export const AppointmentDepositAddMixin = {
    mixins: [AppointmentDepositCacheMixin],
    data() {
        return {
            providerTypeId: 0,
            isRequired: false,
            amount: 0,
            dueDaysPrior: null,
            appointmentDepositValidator: AppointmentHelper.getAppointmentDepositValidator()
        }
    },
    computed: {
        appointmentDepositAddModel() {
            const dueDaysPrior = parseInt(this.dueDaysPrior);
            return {
                scheduleId: this.scheduleId,
                providerTypeId: this.providerTypeId,
                isRequired: this.isRequired,
                amount: this.amount,
                dueDaysPrior: isNaN(dueDaysPrior) ? null : dueDaysPrior
            };
        },
        appointmentDepositAddModelValid() {
            const model = this.appointmentDepositAddModel;
            return !!model.scheduleId && this.appointmentDepositValidator.isValid;
        }
    },
    methods: {
        ...mapActions('AppointmentDeposits', [
            'addAppointmentDeposit'
        ]),
        tryAddAppointmentDeposit() {
            const self = this;
            self.appointmentDepositValidator.check(self.appointmentDepositAddModel);
            if (!self.appointmentDepositAddModelValid) {
                return;
            }
            self.error = '';
            self.addAppointmentDeposit({ key: self.scheduleId, payload: self.appointmentDepositAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
