<template>
    <button @click="save" class="btn btn-primary" type="button" v-bind:disabled="loading || timeoutId">
        {{$t(message)}}
        <span v-if="loading" class="inline-loader"></span>
    </button>
</template>

<script>
    export default {
        name: 'SaveButton',
        props: {
            status: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                wasSaving: false,
                timeoutId: null
            }
        },
        computed: {
            loading() {
                return !!this.status.loading;
            },
            error() {
                return !!this.status.error;
            },
            message() {
                return !!this.timeoutId ? 'Saved' : 'Save';
            }
        },
        watch: {
            status(value) {
                if (!value.loading && !value.error && this.wasSaving) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = setTimeout(() => this.timeoutId = null, 1000);
                    this.wasSaving = false;
                }
            },
            error(value) {
                if (!!value) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            }
        },
        methods: {
            save() {
                this.wasSaving = true;
                this.$emit('clicked');
            }
        }
    }
</script>
