<template>
    <div class="form-group">
        <label>{{$t('Site Logo')}}</label>
        <logo-delete v-if="logo" v-model="logoStatus"></logo-delete>
        <logo-add v-else v-model="logoStatus"></logo-add>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { LogosCacheMixin } from "../../Shared/LogosCacheMixin";
    import SiteUpdateLogoAdd from "./SiteUpdateLogoAdd.vue";
    import SiteUpdateLogoDelete from "./SiteUpdateLogoDelete.vue";

    export default {
        name: 'SiteUpdateLogo',
        mixins: [LogosCacheMixin, StatusEmitterMixin],
        data() {
            return {
                logoStatus: {}
            }
        },
        computed: {
            status() {
                return {
                    loading: !!this.logoStatus.loading,
                    error: this.logoStatus.error
                }
            }
        },
        components: {
            'logo-add': SiteUpdateLogoAdd,
            'logo-delete': SiteUpdateLogoDelete,
            'validation-message': ValidationMessage
        }
    }
</script>
