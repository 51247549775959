<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <tag-selector :tag-types="selectedTagTypes" :owner-id="ownerId" :owner-type-id="ownerTypeId"></tag-selector>
    </div>
</template>

<script>
    import { TagsCacheMixin } from "../TagsCacheMixin";
    import { TagTypesCacheMixin } from "../TagTypesCacheMixin";
    import TagSelector from "./TagSelector.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagList',
        mixins: [TagTypesCacheMixin, TagsCacheMixin],
        computed: {
            selectedTagTypes() {
                return this.tags.map(t => this.tagTypes.find(tt => tt.id === t.tagTypeId)).filter(tt => !!tt);
            }
        },
        components: {
            'tag-selector': TagSelector,
            'error-banner': ErrorBanner
        }
    }
</script>