import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { UserSettingCacheMixin } from "./UserSettingCacheMixin";

export const UserSettingUploadMixin = {
    mixins: [UserSettingCacheMixin],
    computed: {
        userSettingUploadEdit() {
            console.log('!!!OVERRIDE COMPUTED - userSettingUploadEdit!!!');
        },
        userSettingUploadModel() {
            return Object.assign({}, this.userSettingValue, this.userSettingUploadEdit);
        }
    },
    watch: {
        userSettingValue(newValue) {
            this.refreshEditModel(newValue);
        }
    },
    methods: {
        ...mapActions('UserSettings', [
            'addUserSetting',
            'updateUserSetting'
        ]),
        getUserSettingValid() {
            console.log('!!!OVERRIDE METHOD - getUserSettingValid() - boolean!!!');
            return false;
        },
        refreshEditModel() {
            console.log('!!!OVERRIDE METHOD - refreshEditModel(userSettingValue) - void!!!');
        },
        uploadUserSetting() {
            if (!!this.userSetting) {
                this.updateAndCacheUserSetting();
            } else {
                this.addAndCacheUserSetting();
            }
        },
        addAndCacheUserSetting() {
            const self = this;
            if (!self.getUserSettingValid()) {
                return;
            }
            self.error = '';
            const value = JSON.stringify(self.userSettingUploadModel);
            self.addUserSetting({ key: self.userSettingKey, value: value })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        updateAndCacheUserSetting() {
            const self = this;
            if (!self.getUserSettingValid()) {
                return;
            }
            const value = JSON.stringify(self.userSettingUploadModel);
            if (value === JSON.stringify(self.userSettingValue)) {
                return;
            }
            self.error = '';
            self.updateUserSetting({ key: self.userSettingKey, value: value })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEditModel(this.userSettingValue);
    }
}
