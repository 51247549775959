export const UserSettingsHelper = {
    getDefaultCalendarSettings() {
        return {
            scheduleView: 'timeGridWeek',
            resourceOrder: this.getDefaultCalendarResourceOrder()
        }
    },
    getDefaultOnboardingGuideSettings() {
        return {
            gettingStarted: true,
            makeEmailCampaign: true,
            makeSmsCampaign: true
        }
    },
    getDefaultCalendarResourceOrder() {
        return 'title,priorityOrder';
    }
}
