import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const EventTypesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('EventTypes', [
            'fetchingEventTypes',
            'fetchedEventTypes',
            'eventTypes'
        ])
    },
    methods: {
        ...mapActions('EventTypes', [
            'getEventTypes'
        ]),
        refreshEventTypes() {
            if (!this.fetchedEventTypes && !this.fetchingEventTypes) {
                this.getAndCacheEventTypes();
            }
        },
        getAndCacheEventTypes() {
            const self = this;
            self.error = '';
            this.getEventTypes()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEventTypes();
    }
}
