<template>
    <div class="row mb-2">
        <div class="col-12 text-left">
            <label class="d-flex">
                <popper trigger="hover"
                        :visible-arrow="false"
                        :options="{placement: 'top'}">
                    <i slot="reference" :class="statusClass" style="cursor: pointer; color: #339ca0"></i>
                    <div class="popper"  v-bind:style="{ 'background-color': statusColor }">{{appointment.status.description}}</div>
                </popper>
                <div class="ml-2">{{ appointment.startUtc | moment("DD MMM YYYY") }} {{ appointment.startUtc | moment("dddd") }} - {{ petName }} with {{resourceName}}</div>
            </label>
        </div>
    </div>
</template>

<script>
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import { CalendarHelper } from "../../../helpers/CalendarHelper";

    export default {
        name: 'AppointmentClientInfoHistoryItem',
        mixins: [ResourceCacheMixin],
        props: {
            appointment: Object,
            pets: Array,
            statusClass: String
        },
        computed: {
            pet() {
                return this.pets.find(p => p.id === this.appointment.petId);
            },
            petName() {
                return !!this.pet ? this.pet.name : '';
            },
            resourceId() {
                return !!this.appointment ? this.appointment.resourceId : '';
            },
            resource() {
                return this.resources.find(r => r.id === this.resourceId);
            },
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            status() {
                return !!this.appointment ? this.appointment.status : null;
            },
            statusColor() {
                return !!this.status ? CalendarHelper.getAppointmentStatusColour(this.status.id) : CalendarHelper.getAppointmentStatusColour(0);
            }
        },
        components: {
            'popper': Popper
        }
    }
</script>
