import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    appointmentDepositCaches: []
});

const getters = {
    findAppointmentDepositCache: (state) => key => state.appointmentDepositCaches.find(adc => adc.key === key)
};

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentDepositCaches.find(adc => adc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.appointmentDepositCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setDeposit(state, { key, deposit }) {
        const existing = state.appointmentDepositCaches.find(adc => adc.key === key);
        if (!!existing) {
            existing.value = deposit;
            existing.loading = false;
        } else {
            state.appointmentDepositCaches.push({ key: key, value: deposit, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.appointmentDepositCaches.find(adc => adc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchAppointmentDeposit({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('AppointmentDeposit?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setDeposit', { key: key, deposit: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                        resolve(false);
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                })
        });
    },
    addAppointmentDeposit({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('AppointmentDeposit', payload)
                .then(response => {
                    commit('setDeposit', { key: key, deposit: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateAppointmentDeposit({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('AppointmentDeposit', payload)
                .then(response => {
                    commit('setDeposit', { key: key, deposit: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
