import { PetDurationCacheMixin } from "./PetDurationCacheMixin";
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";

export const PetDurationAddMixin = {
    mixins: [PetDurationCacheMixin],
    data() {
        return {
            hoursInput: '',
            minutesInput: '',
            hours: null,
            minutes: null
        }
    },
    computed: {
        petDurationAddModel() {
            return {
                petId: this.petId,
                hours: parseInt(this.hours),
                minutes: parseInt(this.minutes)
            }
        },
        petDurationAddModelValid() {
            const model = this.petDurationAddModel;
            return !!model.petId && (!isNaN(model.hours) || !isNaN(model.minutes));
        }
    },
    methods: {
        ...mapActions('PetDurations', [
            'addPetDuration'
        ]),
        processHours() {
            const hours = parseInt(this.hoursInput);
            if (isNaN(hours)) {
                this.hours = null;
                this.hoursInput = '';
            } else {
                this.hours = Math.max(0, Math.min(8, hours));
                this.hoursInput = this.hours.toString();
            }
        },
        processMinutes() {
            const minutes = parseInt(this.minutesInput);
            if (isNaN(minutes)) {
                this.minutes = null;
                this.minutesInput = '';
            } else {
                this.minutes = Math.max(0, Math.min(59, minutes));
                this.minutesInput = this.minutes.toString();
            }
        },
        tryAddPetDuration() {
            const self = this;
            if (!self.petDurationAddModelValid) {
                return;
            }
            self.error = '';
            self.addPetDuration({ petId: self.petId, payload: self.petDurationAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
