<template>
    <div class="row form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4 d-none d-md-block">
            <label>{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteText" class="form-control" cols="50" :placeholder="$t('Add notes (optional)')" rows="3">{{noteText}}</textarea>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { NoteUploadMixin } from '../../Shared/Notes/NoteUploadMixin.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AppointmentOverviewNotes',
        mixins: [NoteUploadMixin],
        props: {
            appointment: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ]),
            ownerId() {
                return !!this.appointment ? this.appointment.scheduleId : '';
            },
            ownerTypeId() {
                return 8;
            },
            noteTypeId() {
                return 1;
            }
        },
        watch: {
            saveAppointmentEvent() {
                this.tryUploadNote();
            },
            loadingNote(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
