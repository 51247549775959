<template>
    <div v-if="countryFormatCompleted">
        <date-format v-model="dateFormatStatus" :id="dateFormatId"></date-format>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { CountryFormatCacheMixin } from "../../Shared/Country/CountryFormatCacheMixin";
    import PostRegisterDateFormat from "./PostRegisterDateFormat.vue";

    export default {
        name: 'PostRegisterCountryFormat',
        mixins: [CountryFormatCacheMixin, StatusEmitterMixin],
        data() {
            return {
                dateFormatStatus: {}
            }
        },
        computed: {
            countryFormatCompleted() {
                return !!this.countryFormatCache && !this.loadingCountryFormat;
            },
            dateFormatCompleted() {
                return this.dateFormatStatus.completed || !!this.dateFormatStatus.error;
            },
            dateFormatId() {
                return !!this.countryFormat ? this.countryFormat.dateFormatId : 1;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingCountryFormat || this.dateFormatStatus.loading,
                    completed: this.countryFormatCompleted && this.dateFormatCompleted
                }
            }
        },
        components: {
            'date-format': PostRegisterDateFormat
        }
    }
</script>
