<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Language')}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    <div class="d-flex align-items-center px-1">
                        <img :src="getFlagImage(selectedOption.value)" height="15" />
                        <span class="ml-2" v-html="selectedOptionName"></span>
                    </div>
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options" ss
                            :key="option.value"
                            class="dropdown-item pointer px-3"
                            @click="setLanguage(option.value)"
                            v-bind:class="{'active': option.value === selectedOption.value}">
                            <div class="d-flex align-items-center">
                                <img :src="getFlagImage(option.value)" height="15" />
                                <span class="ml-2" v-html="option.name"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { LanguageSettingUploadMixin } from "../../../mixins/Settings/Site/LanguageSettingUploadMixin";
    import { LanguageSettingOptionsMixin } from "../../Shared/LanguageSettingOptionsMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'SiteUpdateLocalizationLanguage',
        mixins: [LanguageSettingUploadMixin, LanguageSettingOptionsMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingSetting,
                    error: this.error
                }
            },
            selectedOption() {
                return this.options.find(o => o.value === this.code);
            }
        },
        methods: {
            setLanguage(value) {
                this.code = value;
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>