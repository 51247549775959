import { Api } from "../../services/Api";

const state = () => ({
    appointmentGroupCaches: []
});

const getters = {
    findAppointmentGroupCache: (state) => key => state.appointmentGroupCaches.find(agc => agc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentGroupCaches.find(agc => agc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.appointmentGroupCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setAppointmentGroup(state, group) {
        const existing = state.appointmentGroupCaches.find(agc => agc.key === group.groupId);
        if (!!existing) {
            const index = state.appointmentGroupCaches.indexOf(existing);
            state.appointmentGroupCaches.splice(index, 1, Object.assign({}, existing, { value: group, loading: false }));
        } else {
            state.appointmentGroupCaches.push({ key: group.groupId, value: group, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.appointmentGroupCaches.find(agc => agc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setAppointmentGroups(state, groups) {
        for (var i = 0; i < groups.length; i++) {
            const group = groups[i];
            const existing = state.appointmentGroupCaches.find(agc => agc.key === group.groupId);
            if (!!existing) {
                const index = state.appointmentGroupCaches.indexOf(existing);
                state.appointmentGroupCaches.splice(index, 1, Object.assign({}, existing, { value: group }));
            } else {
                state.appointmentGroupCaches.push({ key: group.groupId, value: group, loading: false });
            }
        }
    }
}

const actions = {
    fetchAppointmentGroup({ commit }, groupId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: groupId, loading: true });
            Api.get('AppointmentGroup/' + groupId)
                .then(response => {
                    commit('setAppointmentGroup', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', groupId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
