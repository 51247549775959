<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">Pet</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select custom-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <span>{{petName}}</span>
                        </div>
                    </div>
                </button>
                <div class="form-group has-error has-feedback">
                    <validation-message v-if="validationError" :message="validationError"></validation-message>
                </div>
                <div class="dropdown-menu ">
                    <div class="bs-searchbox">
                        <input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-1" aria-autocomplete="list">
                    </div>
                    <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                        <ul class="dropdown-menu inner show" role="presentation">
                            <li v-for="p in selectablePets" :key="p.id">
                                <a @click="setPet(p)" class="dropdown-item" role="option" tabindex="0" aria-posinset="1">
                                    <span class="text">{{p.name}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { AppointmentScheduleValidationMixin } from '../../Shared/AppointmentScheduleValidationMixin';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'AppointmentAdditionalPetSelect',
        mixins: [AppointmentScheduleValidationMixin],
        props: {
            index: Number,
            pets: Array
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'appointmentOrders'
            ]),
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            appointmentOrder() {
                return this.indexAppointmentOrder(this.index);
            },
            petName() {
                return !!this.appointmentOrder.pet ? this.appointmentOrder.pet.name : 'Click to search..';
            },
            selectablePets() {
                return this.pets.filter(p => !this.appointmentOrders.some(ao => !!ao.pet && ao.pet.id === p.id))
            },
            validationErrorText() {
                return this.scheduleAddModelValidation.appointmentOrders[this.index].petId;
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setAdditionalPet'
            ]),
            setPet(pet) {
                this.setAdditionalPet({ index: this.index, pet: pet });
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>
