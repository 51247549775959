import { Api } from '../../services/Api';

const state = () => ({
    fetchingAccountSettings: false,
    fetchedAccountSettings: false,
    addingAccountSetting: false,
    updatingAccountSetting: false,
    accountSettings: []
})

const getters = {
    findAccountSetting: (state) => key => state.accountSettings.find(s => s.key === key),
    canGetAccountSettings: (state) =>
        !state.fetchedAccountSettings && !state.fetchingAccountSettings,
    loadingAccountSettings: (state) =>
        state.fetchingAccountSettings || state.addingAccountSetting || state.updatingAccountSetting
}

const mutations = {
    setFetching(state) {
        state.fetchedAccountSettings = false;
        state.fetchingAccountSettings = true;
    },
    setAccountSettings(state, settings) {
        state.accountSettings = settings;
        state.fetchedAccountSettings = true;
        state.fetchingAccountSettings = false;
    },
    setFailed(state) {
        state.fetchingAccountSettings = false;
        state.fetchedAccountSettings = false;
    },
    setAdding(state, adding) {
        state.addingAccountSetting = !!adding;
    },
    appendSetting(state, setting) {
        state.accountSettings.push(setting);
    },
    setUpdating(state, updating) {
        state.updatingAccountSetting = !!updating;
    },
    insertSetting(state, setting) {
        const existing = state.accountSettings.find(as => as.key === setting.key);
        if (!!existing) {
            const index = state.accountSettings.indexOf(existing);
            state.accountSettings.splice(index, 1, setting);
        } else {
            state.accountSettings.push(setting);
        }
    }
}

const actions = {
    getAccountSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('AccountSetting')
                .then(response => {
                    commit('setAccountSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                })
        });
    },
    addAccountSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('AccountSetting', payload)
                .then(response => {
                    commit('appendSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateAccountSetting({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setUpdating', true);
            Api.put('AccountSetting', payload)
                .then(response => {
                    commit('insertSetting', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setUpdating', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
