<template>
    <modal-dialog :title="$t('Add resource')">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <site-select></site-select>
            <name-input v-model="name" :validator="resourceValidator"></name-input>
            <colour-picker v-model="colour" :validator="resourceValidator"></colour-picker>
            <image-add></image-add>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loadingResources" class="btn btn-primary" type="button">
                {{$t('Add')}}
                <span v-if="loadingResources" class="inline-loader"></span>
            </button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import ModalDialog from '../Layout/ModalDialog.vue';
    import { ResourceAddMixin } from '../Shared/Resources/ResourceAddMixin';
    import ResourceNameInput from './ResourceNameInput.vue';
    import SiteSelectColumn from '../Shared/SiteSelectColumn.vue';
    import ResourceColourPicker from './ResourceColourPicker.vue';
    import ResourceImageAdd from './ResourceImageAdd.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'ResourceAdd',
        mixins: [ResourceAddMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'resourceAddedEvent'
            ])
        },
        watch: {
            resourceAddedEvent() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            save() {
                this.tryAddResource();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'site-select': SiteSelectColumn,
            'name-input': ResourceNameInput,
            'colour-picker': ResourceColourPicker,
            'image-add': ResourceImageAdd,
            'error-banner': ErrorBanner
        }
    }
</script>
