<template>
    <div class="dropdown appointment-overview-pet-activity">
        <button aria-expanded="false" aria-haspopup="true" class="filter-dd text-break" data-toggle="dropdown" type="button">
            {{activityString}}
            <i class="far fa-chevron-down"></i>
        </button>
        <div class="dropdown-menu py-0">
            <ul class="inner show d-flex flex-column my-0 p-0" role="presentation" style="width: max-content; min-width: 100%;">
                <resource-item v-for="(resource, index) in resources"
                                :key="index"
                                :resource="resource"
                                :unavailabilities="unavailabilities"
                                :selected="value===resource.id"
                                @resourceClicked="onResourceClicked"></resource-item>
            </ul>
        </div>
    </div>
</template>

<script>
    import { AllResourcesMixin } from "../../Shared/Resources/AllResourcesMixin";
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import { UnavailabilitiesCacheMixin } from "../../Shared/UnavailabilitiesCacheMixin";
    import EditResourceListItem from "../EditAppointment/EditResourceListItem.vue";

    export default {
        name: 'AppointmentOverviewPetActivity',
        mixins: [ResourceCacheMixin, UnavailabilitiesCacheMixin, AllResourcesMixin],
        props: {
            value: String,
            orderItems: Array
        },
        computed: {
            activityString() {
                return !!this.orderItems && this.orderItems.length > 0 ?
                    this.orderItems.map(oi => oi.name).join(', ') + ' with ' + this.selectedResourceName :
                    this.selectedResourceName;
            },
            selectedResource() {
                return this.allResources.find(r => r.id === this.value)
            },
            selectedResourceName() {
                return !!this.selectedResource ? this.selectedResource.name : 'Unknown';
            }
        },
        methods: {
            onResourceClicked(resource) {
                this.$emit('input', resource.id);
            }
        },
        components: {
            'resource-item': EditResourceListItem
        }
    }
</script>

<style scoped>
    .appointment-overview-pet-activity {
        width: max-content;
        max-width: 100%;
    }
    .appointment-overview-pet-activity .filter-dd {
        width: max-content; 
        max-width: 100%;
    }
    .appointment-overview-pet-activity .dropdown-menu .inner.show {
        overflow-x: auto;
        width: max-content;
        list-style: none;
    }
</style>
