import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const SubscriptionTypesCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('SubscriptionTypes', [
            'fetchedSubscriptionTypes',
            'fetchingSubscriptionTypes',
            'subscriptionTypes'
        ])
    },
    methods: {
        ...mapActions('SubscriptionTypes', [
            'getSubscriptionTypes'
        ]),
        refreshSubscriptionTypes() {
            if (!this.fetchedSubscriptionTypes && !this.fetchingSubscriptionTypes) {
                this.tryGetSubscriptionTypes();
            }
        },
        tryGetSubscriptionTypes() {
            const self = this;
            self.error = '';
            self.getSubscriptionTypes()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscriptionTypes();
    }
}
