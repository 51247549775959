import { Api } from '../../services/Api';

const state = () => ({
    fetchingTemplateTags: false,
    fetchedTemplateTags: false,
    contractTemplateTags: null
})

const mutations = {
    setFetching(state) {
        state.fetchedTemplateTags = false;
        state.fetchingTemplateTags = true;
    },
    setTemplateTags(state, tags) {
        state.contractTemplateTags = tags;
        state.fetchedTemplateTags = true;
        state.fetchingTemplateTags = false;
    },
    setFailed(state) {
        state.contractTemplateTags = null;
        state.fetchedTemplateTags = false;
        state.fetchingTemplateTags = false;
    }
}

const actions = {
    getContractTemplateTags({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('ContractTemplate/Tag')
                .then(response => {
                    commit('setTemplateTags', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
