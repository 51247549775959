<template>
    <div class="dropdown">
        <button aria-expanded="false" aria-haspopup="true" class="filter-dd" data-toggle="dropdown" type="button">
            {{$t('Staff')}}
            <i class="far fa-chevron-down"></i>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1">
            <resource-group v-for="site in selectedSites"
                            v-model="selectedResources"
                            :site="site" :key="site.id"
                            :display-site-name="selectedSites.length > 1">
            </resource-group>
        </div>
    </div>
</template>

<script>
    import ScheduleFilterResourceItem from './ScheduleFilterResourceItem.vue';
    import { ScheduleFilterResourceMixin } from './ScheduleFilterResourceMixin';

    export default {
        name: 'ScheduleFilterResource',
        mixins: [ScheduleFilterResourceMixin],
        components: {
            'resource-group': ScheduleFilterResourceItem
        }
    }
</script>
