<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label>{{title}}<slot name="tooltip"></slot></label>
        </div>
        <div class="col-md-8">
            <input v-model="amountInput" @blur="processInput" class="form-control text-right" type="number">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { ProductInputMixin } from "../../Products/ProductInputMixin";

    export default {
        name: 'NumberInput',
        mixins: [ProductInputMixin],
        props: {
            value: Number,
            title: String
        },
        watch: {
            amount(value) {
                this.$emit('input', value);
            },
            value(value) {
                if (value !== this.amount) {
                    this.setInitialAmount(this.value);
                }
            }
        },
        created() {
            this.setInitialAmount(this.value);
        }
    }
</script>
