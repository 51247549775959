import { mapState, mapGetters, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const ServiceProductCountCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ServiceProductCount', [
            'serviceProductCountCacheCleared'
        ]),
        ...mapGetters('ServiceProductCount', [
            'findServiceProductCountCache'
        ]),
        serviceProductCountCache() {
            return this.findServiceProductCountCache(this.siteId);
        },
        serviceProductCount() {
            return !!this.serviceProductCountCache ? this.serviceProductCountCache.value : null;
        },
        loadingServiceProductCount() {
            return !!this.serviceProductCountCache && this.serviceProductCountCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshServiceProductCount();
        },
        serviceProductCountCacheCleared() {
            this.refreshServiceProductCount();
        }
    },
    methods: {
        ...mapActions('ServiceProductCount', [
            'fetchServiceProductCount'
        ]),
        tryFetchServiceProductCount() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.fetchServiceProductCount(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshServiceProductCount() {
            if (!this.serviceProductCountCache || !!this.serviceProductCountCache.retry) {
                this.tryFetchServiceProductCount();
            }
        }
    },
    created() {
        this.refreshServiceProductCount();
    }
}
