<template>
    <div>
        <div class="upgrade-plan-v2_popular" v-if="subscriptionTypeId === 4">
            <span>Most Popular</span>
        </div>
        <div class="upgrade-plan-v2_card">
            <div class="upgrade-plan-v2_header">
                <div class="upgrade-plan-v2_header-desription">{{ subscriptionTypeDescription }}</div>
                <div class="upgrade-plan-v2_header-tagline">{{ tagline }}</div>
            </div>
            <div class="upgrade-plan-v2_pricing">
                <div class="upgrade-plan-v2_pricing-money">
                    {{ priceString }}
                    <span v-if="multiSite">Per Site</span>
                </div>
                <div>{{ duration }}</div>
            </div>
            <feature-list :feature="subscriptionUpgradeFeature" @additionalSitesChanged="setAdditionalSites"></feature-list>
        </div>
        <div class="upgrade-plan-v2_choose">
            <div v-if="current" class="upgrade-plan-v2_choose-current">
                Current plan
            </div>
            <button v-else @click="selectPlan" class="upgrade-plan-v2_choose-button" v-bind:disabled="loading">
                Choose this plan
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { SubscriptionCacheMixin } from "../../../mixins/Subscriptions/SubscriptionCacheMixin";
    import { SubscriptionUpgradeFeaturesCacheMixin } from "../../../mixins/Subscriptions/SubscriptionUpgradeFeaturesCacheMixin";
    import { SubscriptionUpgradeSettingsCacheMixin } from "../../../mixins/Subscriptions/SubscriptionUpgradeSettingsCacheMixin";
    import SubscriptionFeatureListV2 from "./SubscriptionFeatureListV2.vue";

    export default {
        name: 'SubscriptionUpgradeTypeV2',
        mixins: [SubscriptionCacheMixin, SubscriptionUpgradeFeaturesCacheMixin, SubscriptionUpgradeSettingsCacheMixin],
        props: {
            subscriptionType: {
                type: Object,
                required: true
            },
            selectedUpgrade: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                additionalSites: 0
            }
        },
        computed: {
            ...mapState('SubscriptionUpgrade', [
                'addingSubscriptionUpgrade'
            ]),
            subscriptionTypeId() {
                return this.subscriptionType.id;
            },
            loading() {
                return this.addingSubscriptionUpgrade && this.selectedUpgrade.subscriptionTypeId === this.subscriptionTypeId;
            },
            subscriptionTypeDescription() {
                return this.subscriptionType.description;
            },
            subscriptionUpgradeFeature() {
                return this.subscriptionUpgradeFeatures.find(f => f.subscriptionType.id === this.subscriptionTypeId);
            },
            subscriptionUpgradeSetting() {
                return this.subscriptionUpgradeSettings.find(s => s.subscriptionType.id === this.subscriptionTypeId);
            },
            current() {
                return !!this.subscription && this.subscription.subscriptionType.id === this.subscriptionTypeId;
            },
            tagline() {
                return !!this.subscriptionUpgradeSetting ? this.subscriptionUpgradeSetting.tagline : '';
            },
            yearly() {
                return this.selectedUpgrade.yearly;
            },
            currency() {
                return this.selectedUpgrade.currency;
            },
            currencySymbol() {
                return this.currency === 'GBP' ? '£' : '$';
            },
            price() {
                const price = parseFloat(!!this.subscriptionUpgradeSetting ?
                    (this.yearly ? this.subscriptionUpgradeSetting.yearlyPrices[this.currency] : this.subscriptionUpgradeSetting.monthlyPrices[this.currency]) : 0);
                return isNaN(price) ? 0 : price;
            },
            priceString() {
                return this.currencySymbol + this.price.toFixed(0);
            },
            duration() {
                return '(per ' + (this.yearly ? 'year)' : 'month)');
            },
            multiSite() {
                return !!this.subscriptionUpgradeFeature && this.subscriptionUpgradeFeature.multiSite;
            }
        },
        watch: {
            subscription() {
                this.refreshData();
            },
            subscriptionUpgradeFeature() {
                this.refreshData();
            }
        },
        methods: {
            selectPlan() {
                this.$emit('onSelected', {
                    subscriptionTypeId: this.subscriptionTypeId,
                    additionalSites: this.additionalSites
                });
            },
            refreshData() {
                this.additionalSites = !!this.subscription && this.multiSite ? this.subscription.additionalSites : 0;
            },
            setAdditionalSites(value){
                this.additionalSites = value;
            }
        },
        components: {
            'feature-list': SubscriptionFeatureListV2
        },
        created() {
            this.refreshData();
        }
    }
</script>
