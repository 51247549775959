import { CacheHelper } from "../../helpers/CacheHelper";
import { Api } from "../../services/Api";

const state = () => ({
    productCaches: []
})

const getters = {
    findProductCache: (state) => key => state.productCaches.find(pc => pc.key === key)
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.productCaches.find(pc => pc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.productCaches.push({ key: key, values: [], loading: !!loading });
            }
        }
    },
    setProducts(state, { keys, products }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const ownerProducts = products.filter(p => CacheHelper.ownerCacheKey(p) === key);
            const existing = state.productCaches.find(pc => pc.key === key);
            if (!!existing) {
                existing.values = ownerProducts;
                existing.loading = false;
            } else {
                state.productCaches.push({ key: key, values: ownerProducts, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const existing = state.productCaches.find(pc => pc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    },
    appendProducts(state, products) {
        for (var i = 0; i < products.length; i++) {
            const product = products[i];
            const key = CacheHelper.ownerCacheKey(product);
            const existing = state.productCaches.find(pc => pc.key === key);
            if (!!existing) {
                existing.values.push(product);
            } else {
                state.productCaches.push({ key: key, values: [product], loading: false });
            }
        }
    },
    insertProduct(state, product) {
        const key = CacheHelper.ownerCacheKey(product);
        const existing = state.productCaches.find(pc => pc.key === key);
        if (!!existing) {
            const old = existing.values.find(p => p.id === product.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, product);
            } else {
                existing.values.push(product);
            }
        } else {
            state.productCaches.push({ key: key, values: [product], loading: false });
        }
    },
    removeProduct(state, product) {
        const key = CacheHelper.ownerCacheKey(product);
        const existing = state.productCaches.find(pc => pc.key === key);
        if (!!existing) {
            existing.values = existing.values.filter(p => p.id !== product.id);
        }
    }
}

const actions = {
    searchProducts({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            Api.post('Product/Search', payload)
                .then(response => {
                    commit('setProducts', { keys: keys, products: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', keys);
                    reject(error);
                });
        });
    },
    addProducts({ commit }, { keys, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            Api.post('Product', payload)
                .then(response => {
                    commit('appendProducts', response.data);
                    commit('ProductSearches/clearProductSearches', null, { root: true });
                    commit('ApplicationEvents/triggerProductsAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: keys, loading: false }));
        });
    },
    updateProduct({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [key], loading: true });
            Api.put('Product', payload)
                .then(response => {
                    commit('insertProduct', response.data);
                    commit('ProductSearches/clearProductSearches', null, { root: true });
                    commit('ApplicationEvents/triggerProductUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [key], loading: false }));
        });
    },
    deleteProduct({ commit }, { key, product }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [key], loading: true });
            Api.delete('Product/' + product.id)
                .then(response => {
                    commit('removeProduct', product);
                    commit('ProductSearches/clearProductSearches', null, { root: true });
                    commit('ApplicationEvents/triggerProductDeleted', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { keys: [key], loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
