import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { LanguageSettingMixin } from "./LanguageSettingMixin";

export const LanguageSettingUploadMixin = {
    mixins: [SettingUploadMixin, LanguageSettingMixin],
    data() {
        return {
            code: ''
        }
    },
    computed: {
        settingEditModel() {
            return {
                code: this.code
            }
        },
        settingEditModelValid() {
            return !!this.settingEditModel.code;
        }
    },
    methods: {
        refreshSettingEditModel() {
            this.code = this.settingObject.code;
        }
    }
}
