<template>
    <section class="mb-3">
        <div class="card card-body dashboard-card">
            <h5 class="card-title">
                {{$t('Top 5 Services')}}
            </h5>
            <table class="display dataTable no-footer card-content">
                <tbody>
                    <tr v-for="(total, index) in totals" :key="total.name" v-bind:class="index % 2 === 0 ? 'even' : 'odd'">
                        <td>
                            {{total.name}}
                        </td>
                        <td style="text-align: right">
                            {{total.amount}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { NumberHelper } from '../../helpers/NumberHelper';
    import { OrderItemAggregateSearchMixin } from "../Shared/Aggregates/OrderItemAggregateSearchMixin";
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";

    export default {
        name: 'OrderItemAggregateTotals',
        mixins: [OrderItemAggregateSearchMixin, CountryCacheMixin],
        computed: {
            ...mapState('Countries', [
                'countries'
            ]),
            totals() {
                const result = [];
                for (var i = 0; i < this.orderItemAggregates.length; i++) {
                    const aggregate = this.orderItemAggregates[i];
                    const total = {
                        name: aggregate.name,
                        amounts: [],
                        amount: ''
                    };
                    for (var j = 0; j < aggregate.totals.length; j++) {
                        var siteTotal = aggregate.totals[j];
                        const country = this.countries.find(c => c.siteId === siteTotal.siteId);
                        const key = !!country ? country.currency : '???';
                        const existing = total.amounts.find(a => a.key === key);
                        if (!!existing) {
                            existing.amount += siteTotal.total;
                        } else {
                            total.amounts.push({
                                key: key,
                                symbol: !!country ? country.currencySymbol : '?',
                                amount: siteTotal.total
                            });
                        }
                    }
                    total.amount = total.amounts.sort((a, b) => b.amount - a.amount).map(a => a.symbol + NumberHelper.toMoneyFormat(a.amount)).join(', ');
                    result.push(total);
                }
                return result;
            }
        }
    }
</script>
