import { mapState } from 'vuex';

export const AllResourcesMixin = {
    computed: {
        ...mapState('Resources', [
            'resourceCaches'
        ]),
        allResources() {
            return [].concat(...this.resourceCaches.map(rc => rc.values));
        },
        allActiveResources() {
            return this.allResources.filter(r => !r.hidden);
        }
    }
}
