export const HashHelper = {
    createObjectHash: function (object) {
        var string = JSON.stringify(object);
        return this.createStringHash(string);
    },
    createStringHash: function (string) {
        var hash = 0;
        if (string.length == 0) return hash;
        for (var i = 0; i < string.length; i++) {
            var character = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash.toString();
    },
    randomId: function () {
        var x = (Math.random() * 46656) | 0;
        var y = (Math.random() * 46656) | 0;
        x = ("000" + x.toString(36)).slice(-3);
        y = ("000" + y.toString(36)).slice(-3);
        return x + y;
    }
}
