import { mapMutations, mapState } from 'vuex';
import { SiteCacheMixin } from "../Shared/SiteCacheMixin";

export const ScheduleFilterLocationMixin = {
    mixins: [SiteCacheMixin],
    computed: {
        ...mapState('ScheduleFilter', [
            'siteIds'
        ]),
        selectedIds: {
            get() {
                return this.siteIds;
            },
            set(value) {
                this.setSites(value);
            }
        },
        options() {
            return this.sites.map(s => Object.assign({}, s, {
                selected: this.selectedIds.some(id => id === s.id)
            }));
        }
    },
    watch: {
        selected() {
            this.setSitesFilter();
        }
    },
    methods: {
        ...mapMutations('ScheduleFilter', [
            'setSites',
            'setSelectedSite'
        ]),
        onOptionsChanged() {
            this.selectedIds = this.options.filter(o => o.selected);
        },
        setSitesFilter() {
            if (!!this.selected) {
                this.selectedIds = [this.selected];
                this.setSelectedSite(this.selected);
            }
        }
    },
    created() {
        this.setSitesFilter();
    }
}
