<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-4" data-toggle="collapse">
            <i class="fas fa-gear mr-2"></i>
            {{$t('System Settings')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-4">
            <div class="card card-body">
                <div class="form-group">
                    <label>{{$t('Language')}}</label>
                    <language-select></language-select>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import LanguageSelect from "./LanguageSelect.vue";

    export default {
        name: 'SystemSettingsOptions',
        components: {
            'language-select': LanguageSelect
        }
    }
</script>