<template>
    <tr @click="gotoClient">
        <td>
            <div class="client-row">
                <div class="name-avatar">
                    {{startLetter}}
                </div>
                <div class="client-row-details">
                    <div class="crd-name text-truncate">
                        {{clientName}}
                    </div>
                    <div v-if="showAlert">
                        <span  class="badge badge-warning">!</span>
                        {{ noteText }}
                    </div>
                    <div class="crd-contact">
                        {{contactSummary}}
                    </div>
                    <div class="crd-pets">
                        {{petString}}
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { ClientItemMixin } from './ClientItemMixin';

    export default {
        name: 'ClientItemMobile',
        mixins: [ClientItemMixin],
        methods: {
            refreshNote() {
                // do nothing;
            }
        },
        computed: {
            clientName() {
                return this.displaySite ? this.fullName + ' (' + this.siteName + ')' : this.fullName;
            },
            contactSummary() {
                return this.mobile && this.email ? this.mobile + ' / ' + this.email : this.mobile ? this.mobile : this.email ? this.email : 'No contact info';
            }
        }
    }
</script>
