<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-notes" data-toggle="collapse">
            {{$t('Client notes')}}
            <span v-if="loadingNote" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-notes">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <textarea @focus="popup" v-model="noteText" class="form-control note-section" cols="50" rows="10" :placeholder="$t('Add notes (optional)')"></textarea>
                <button ref="notePopupButton" type="button" style="display: none" data-toggle="modal" data-target="#notes-popup-id"></button>
                <modal-dialog id="notes-popup-id" :title="$t('Client notes')">
                    <textarea v-model="noteText" class="form-control popped-out" cols="50" rows="20" :placeholder="$t('Add notes (optional)')"></textarea>
                    <div class="modal-footer">
                        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal">{{$t('Close')}}</button>
                    </div>
                </modal-dialog>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import { NoteUploadMixin } from '../Shared/Notes/NoteUploadMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'ClientNotesSection',
        mixins: [NoteUploadMixin, StatusEmitterMixin],
        props: {
            clientId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            ownerId() {
                return this.clientId;
            },
            ownerTypeId() {
                return 2;
            },
            noteTypeId() {
                return 1;
            },
            status() {
                return {
                    loading: this.loadingNote,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.tryUploadNote();
            }
        },
        methods: {
            popup() {
                this.$refs.notePopupButton.click();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner
        }
    }
</script>

<style scoped>
    .popped-out {
        max-width: 800px;
    }
</style>
