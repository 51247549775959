<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-mpo-address" data-toggle="collapse">
            <i class="fas fa-key mr-2"></i>
            {{$t('Default Address')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-address">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="form-group">
                    <address-input v-model="addressLines"></address-input>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { AddressHelper } from '../../helpers/AddressHelper.js';
    import { AccountSettingSiteDefaultMixin } from '../../mixins/Settings/Account/AccountSettingSiteDefaultMixin.js';
    import AddressInputV2 from '../Shared/Address/AddressInputV2.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'DefaultSiteAddress',
        mixins: [AccountSettingSiteDefaultMixin],
        data() {
            return {
                line1: '',
                line2: '',
                line3: '',
                town: '',
                county: '',
                postCode: '',
                addressValidator: AddressHelper.getAddressValidator(),
            }
        },
        computed: {
            accountSettingKey() {
                return 'DefaultAddress';
            },
            accountSettingEditModel() {
                return {
                    line1: this.line1,
                    line2: this.line2,
                    line3: this.line3,
                    town: this.town,
                    county: this.county,
                    postCode: this.postCode
                }
            },
            accountSettingEditModelValid() {
                this.addressValidator.check(this.accountSettingEditModel);
                return this.addressValidator.isValid;
            },
            addressLines: {
                get() {
                    return this.accountSettingEditModel;
                },
                set(value) {
                    this.setAddressLines(value);
                }
            }
        },
        methods: {
            setAddressLines(address) {
                this.line1 = !!address ? address.line1 : '';
                this.line2 = !!address ? address.line2 : '';
                this.line3 = !!address ? address.line3 : '';
                this.town = !!address ? address.town : '';
                this.county = !!address ? address.county : '';
                this.postCode = !!address ? address.postCode : '';
            },
            refreshAccountSettingEditModel() {
                this.setAddressLines(this.accountSettingObject);
            }
        },
        components: {
            'address-input': AddressInputV2,
            'error-banner': ErrorBanner
        }
    }
</script>
