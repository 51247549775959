import { Api } from '../../services/Api';

const state = () => ({
    dirty: true,
    loading: false,
    forecast: []
})

const getters = {}

const actions = {
    refresh({ commit }) {
        return new Promise((resolve, reject) => {
            commit('startLoading');
            Api.get('WeatherForecast')
                .then(r => {
                    commit('setForecast', r.data);
                    resolve(r);
                })
                .catch(error => {
                    reject(error);
                })
                .then(() => {
                    commit('stopLoading')
                });
        });
    }
}

const mutations = {
    startLoading(state) {
        state.loading = true;
    },
    stopLoading(state) {
        state.loading = false;
    },
    setForecast(state, forecast) {
        state.dirty = false;
        state.forecast = forecast;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}