import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const ClientAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('ClientAggregateSearches', [
            'findClientAggregatesSearch'
        ]),
        aggregateSearch() {
            return this.findClientAggregatesSearch(this.aggregateSearchKey);
        },
        clientAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingClientAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('ClientAggregateSearches', [
            'searchClientAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchClientAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
