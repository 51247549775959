import { Api } from '../../services/Api';

const state = () => ({
    loadingSiteCount: false,
    loadedSiteCount: false,
    siteCount: null
})

const mutations = {
    setloadingSiteCount(state, value) {
        state.loadingSiteCount = !!value;
    },
    setSiteCount(state, count) {
        state.siteCount = count;
        state.loadedSiteCount = true;
    }
}

const actions = {
    fetchSiteCount({ commit }) {
        commit('setloadingSiteCount', true);
        return new Promise((resolve, reject) => {
            Api.get('Site/Count')
                .then(response => {
                    commit('setSiteCount', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setloadingSiteCount', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
