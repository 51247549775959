<template>
    <div>
        <div class="tab-pane fade show active">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="card card-body">
                <div class="form-group">
                    <label>Name</label>
                    {{clientName}}
                </div>
                <template-select v-model="templateId" :validator="contractValidator"></template-select>
                <note-input v-model="note"></note-input>
                <div class="form-group wide">
                    <document-send v-model="emailStatus" :contract="contract" :send="emailSend" :method-type="1"></document-send>
                    <document-send v-model="smsStatus" :contract="contract" :send="smsSend" :method-type="2"></document-send>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                Send
                <span v-if="loading" class="inline-loader"></span>
            </button>
            <button ref="closeModal" class="btn btn-outline-secondary" type="button" data-dismiss="modal">Close</button>
        </div>
    </div>
</template>

<script>
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import { ContractAddMixin } from '../../Shared/ContractAddMixin.js';
    import DocumentTemplateSelect from './DocumentTemplateSelect.vue';
    import DocumentNoteInput from './DocumentNoteInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ClientDocumentSend from './ClientDocumentSend.vue';

    export default {
        name: 'ClientDocumentAdd',
        mixins: [ContractAddMixin],
        data() {
            return {
                contract: null,
                emailStatus: {},
                smsStatus: {},
                emailSend: 0,
                smsSend: 0
            }
        },
        props: {
            client: Object
        },
        computed: {
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            clientName() {
                return !!this.client ? this.client.firstName + ' ' + this.client.lastName : 'Unknown';
            },
            statuses() {
                return [this.emailStatus, this.smsStatus];
            },
            completed() {
                return !!this.contract && this.statuses.every(s => s.completed);
            },
            loading() {
                return this.loadingContracts || this.statuses.some(s => s.loading);
            }
        },
        watch: {
            completed() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            afterContractAdd(contract) {
                this.contract = contract;
                this.emailSend++;
                this.smsSend++;
            },
            save() {
                if (!!this.contract) {
                    if (!this.emailStatus.completed) {
                        this.emailSend++;
                    }
                    if (!this.smsStatus.completed) {
                        this.smsSend++;
                    }
                } else {
                    this.tryAddContract();
                }
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'template-select': DocumentTemplateSelect,
            'note-input': DocumentNoteInput,
            'error-banner': ErrorBanner,
            'document-send': ClientDocumentSend
        }
    }
</script>
