<template>
    <div>
        <div v-if="image" class="form-group row">
            <div class="col-md-4">
                <label>{{$t('Image')}}</label>
            </div>
            <div class="col-md-8">
                <div class="image-uploads mw-400 m-0">
                    <ul>
                        <li>
                            <button @click="tryDeleteImage" class="btn" type="button">
                                <i class="far fa-times"></i>
                            </button>
                            <figure>
                                <img alt="Resource image" :src="image.thumbnails.medium" width="160">
                            </figure>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <template v-else>
            <image-add :resource-id="resourceId"></image-add>
        </template>
        <validation-message v-if="error" :message="error"></validation-message>
    </div>
</template>

<script>
    import { ImageUpdateMixin } from "../Shared/Images/ImageUpdateMixin";
    import ResourceImageAdd from "./ResourceImageAdd.vue";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ResourceImageUpdate',
        mixins: [ImageUpdateMixin],
        props: {
            resourceId: String
        },
        computed: {
            ownerId() {
                return this.resourceId;
            },
            ownerTypeId() {
                return 14;
            },
            image() {
                return this.images.length > 0 ? this.images[0] : null;
            }
        },
        watch: {
            loadingImages(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'image-add': ResourceImageAdd,
            'validation-message': ValidationMessage
        }
    }
</script>
