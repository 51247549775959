<template>
    <div v-if="showAlert" class="alert-wrapper d-none-x">
        <div class="alert alert-warning alert-icon" role="alert">
            <div class="alert-content">
                {{note.text}}
            </div>
            <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { ClientAlertMixin } from '../Shared/ClientAlertMixin.js';

    export default {
        name: 'ClientAlertNotification',
        mixins: [ClientAlertMixin]
    }
</script>
