import { Api } from '../../services/Api';

const state = () => ({
    fetchingSmsCalculatorSettings: false,
    fetchedSmsCalculatorSettings: false,
    smsCalculatorSettings: null
})

const mutations = {
    setFetching(state) {
        state.fetchedSmsCalculatorSettings = false;
        state.fetchingSmsCalculatorSettings = true;
    },
    setSmsCalculatorSettings(state, settings) {
        state.smsCalculatorSettings = settings;
        state.fetchedSmsCalculatorSettings = true;
        state.fetchingSmsCalculatorSettings = false;
    },
    setFailed(state) {
        state.fetchingSmsCalculatorSettings = false;
        state.fetchedSmsCalculatorSettings = false;
    }
}

const actions = {
    getSmsCalculatorSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('SmsCredit/CalculatorSetting')
                .then(response => {
                    commit('setSmsCalculatorSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFailed');
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
