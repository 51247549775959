import moment from 'moment';
import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { OneOffHoursAddMixin } from './OneOffHoursAddMixin';

export const OneOffHoursUploadMixin = {
    mixins: [OneOffHoursAddMixin],
    props: {
        defaultHours: Object
    },
    computed: {
        oneOffHour() {
            const day = moment(this.date).day();
            return this.oneOffHours.find(ooh => ooh.resourceId === this.resourceId && moment(ooh.dateLocal).day() === day);
        },
        oneOffHourUpdateModel() {
            return !!this.oneOffHour ? Object.assign({}, this.oneOffHour, {
                startLocal: this.fullStartLocal,
                endLocal: this.fullEndLocal,
                dayOff: this.dayOff
            }) : {};
        },
        oneOffHourUpdateModelValid() {
            return !!this.oneOffHourUpdateModel.id && this.oneOffHourValidator.isValid;
        },
        oneOffHourUpdateModelModified() {
            return JSON.stringify(this.oneOffHourUpdateModel) !== JSON.stringify(this.oneOffHour);
        }
    },
    watch: {
        oneOffHour() {
            this.refreshOneOffHourModel();
        }
    },
    methods: {
        ...mapActions('OneOffHours', [
            'updateOneOffHour'
        ]),
        refreshOneOffHourModel() {
            this.startLocal = !!this.oneOffHour ? moment(this.oneOffHour.startLocal).format('HH:mm') : this.defaultHours ? this.defaultHours.startLocal : '08:00';
            this.endLocal = !!this.oneOffHour ? moment(this.oneOffHour.endLocal).format('HH:mm') : this.defaultHours ? this.defaultHours.endLocal : '17:00';
            this.dayOff = !!this.oneOffHour ? this.oneOffHour.dayOff : this.defaultHours ? this.defaultHours.dayOff : false;
        },
        tryUploadOneOffHour() {
            if (!!this.oneOffHour) {
                this.tryUpdateOneOffHour();
            } else {
                this.tryAddOneOffHour();
            }
        },
        tryUpdateOneOffHour() {
            const self = this;
            self.oneOffHourValidator.validate(self.oneOffHourUpdateModel);
            if (!self.oneOffHourUpdateModelValid) {
                return;
            }
            if (!self.oneOffHourUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateOneOffHour({ key: self.oneOffHoursSearchKey, payload: self.oneOffHourUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOneOffHourModel();
    }
}
