<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Country')}}</label>
        </div>
        <div class="col-md-8">
            <div v-if="isSearching">
                <country-search v-model="countryOption"></country-search>
                <a v-if="countryOption" @click="editing=false" class="link pointer">{{$t('Cancel')}}</a>
            </div>
            <template v-else>
                <div class="mb-1">
                    {{countryOption.name}}
                </div>
                <a v-if="modified" @click="clear" class="link pointer">{{$t('Clear')}}</a>
                <template v-else>
                    <a @click="editing=true" class="link pointer">{{$t('Search')}}</a>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import CountryOptionSearch from "../../Shared/Country/CountryOptionSearch.vue";
    import { CountryUploadMixin } from "../../Shared/Country/CountryUploadMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLocationCountry',
        mixins: [CountryUploadMixin, StatusEmitterMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            isSearching() {
                return this.editing || !this.countryOption;
            },
            modified() {
                return !!this.countryOption && (!this.country || this.country.countryId !== this.countryOption.id);
            },
            status() {
                return {
                    loading: !!this.updatingCountry || !!this.addingCountry || !!this.loadingCountries,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadCountry();
            },
            countryOption() {
                this.editing = false;
            }
        },
        methods: {
            clear() {
                this.editing = false;
                this.refreshCountryOption();
            }
        },
        components: {
            'country-search': CountryOptionSearch,
            'error-banner': ErrorBanner
        }
    }
</script>
