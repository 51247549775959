import { Api } from '../../services/Api';

const state = () => ({
    smsUnitCostCaches: []
})

const getters = {
    findSmsUnitCostCache: state => clientId => {
        return state.smsUnitCostCaches.find(succ => succ.key === clientId);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.smsUnitCostCaches.find(succ => succ.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.smsUnitCostCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setSmsUnitCost(state, { key, smsUnitCost }) {
        const existing = state.smsUnitCostCaches.find(succ => succ.key === key);
        if (!!existing) {
            existing.value = smsUnitCost;
            existing.loading = false;
        } else {
            state.smsUnitCostCaches.push({ key: key, value: smsUnitCost, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.smsUnitCostCaches.find(succ => succ.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchSmsUnitCost({ commit }, { key }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('SmsCredit/UnitCost')
                .then(response => {
                    commit('setSmsUnitCost', { key: key, smsUnitCost: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
