import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AppointmentOrdersMixin = {
    data() {
        return {
            error: '',
            fetchingAppointmentOrders: false
        }
    },
    computed: {
        ...mapGetters('Orders', [
            'findOrderCache'
        ]),
        appointmentIds() {
            console.log('OVERRIDE COMPUTED - appointmentIds');
            return [];
        },
        missingAppointmentIds() {
            return this.appointmentIds.filter(id => {
                const cache = this.findOrderCache(id);
                return !cache || cache.retry;
            });
        },
        orderSearchModel() {
            return {
                appointmentIds: this.missingAppointmentIds
            }
        },
        orderSearchModelValid() {
            return this.orderSearchModel.appointmentIds.length > 0;
        }
    },
    watch: {
        missingAppointmentIds() {
            this.tryFindAllOrders();
        }
    },
    methods: {
        ...mapActions('Orders', [
            'findAllOrders'
        ]),
        tryFindAllOrders() {
            const self = this;
            if (!self.orderSearchModelValid) {
                return;
            }
            self.error = '';
            self.fetchingAppointmentOrders = true;
            self.findAllOrders({ keys: self.missingAppointmentIds, payload: self.orderSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.fetchingAppointmentOrders = false);
        }
    },
    created() {
        this.tryFindAllOrders();
    }
}
