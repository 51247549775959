<template>
    <div class="contract-editor">
        <ckeditor ref="editor" :editor="editor" v-model="html" :config="editorConfig"></ckeditor>
        <validation-message v-if="validator.html" :message="validator.html"></validation-message>
    </div>
</template>

<script>
    import { CKEditorMixin } from '../../Shared/CKEditorMixin';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'TemplateHtmlWysiwyg',
        mixins: [CKEditorMixin],
        props: {
            value: String,
            validator: Object
        },
        computed: {
            html: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>

<style>
    .contract-editor .ck-editor__editable {
        height: calc(100vh - 21rem) !important;
    }
</style>
