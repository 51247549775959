import { mapActions, mapGetters } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const DateFormatSettingCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('DateFormatSettings', [
            'findDateFormatSettingCache'
        ]),
        dateFormatSettingCache() {
            return this.findDateFormatSettingCache(this.siteId);
        },
        dateFormatSetting() {
            return !!this.dateFormatSettingCache ? this.dateFormatSettingCache.value : null;
        },
        loadingDateFormatSetting() {
            return !!this.dateFormatSettingCache && this.dateFormatSettingCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshDateFormatSetting();
        }
    },
    methods: {
        ...mapActions('DateFormatSettings', [
            'getDateFormatSetting'
        ]),
        refreshDateFormatSetting() {
            if (!this.dateFormatSettingCache || !!this.dateFormatSettingCache.retry) {
                this.tryGetDateFormatSetting();
            }
        },
        tryGetDateFormatSetting() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getDateFormatSetting(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshDateFormatSetting();
    }
}
