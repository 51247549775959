import moment from 'moment';

export const EventHelper = {
    getEventUpdateValidator() {
        return {
            eventTypeId: '',
            startUtc: '',
            endUtc: '',
            isValid: false,
            check(model) {
                this.eventTypeId = !!model.eventType.id ? '' : 'Please select event type';
                Object.assign(this, EventHelper.dateTimeValidation(model.startUtc, model.endUtc));
                this.isValid = !this.eventTypeId && !this.startUtc && !this.endUtc;
            }
        }
    },
    getEventAddValidator() {
        return {
            resourceIds: '',
            eventTypeId: '',
            startUtc: '',
            endUtc: '',
            isValid: false,
            check(model) {
                this.resourceIds = model.resourceIds.length > 0 ? '' : 'Please select at least 1 resource';
                this.eventTypeId = !!model.eventTypeId ? '' : 'Please select an event type';
                Object.assign(this, EventHelper.dateTimeValidation(model.startUtc, model.endUtc));
                this.isValid = !this.resourceIds && !this.eventTypeId && !this.startUtc && !this.endUtc;
            }
        }
    },
    dateTimeValidation(start, end) {
        var startUtc = !!start ? '' : 'Please select a start time';
        const endUtc = !!end ? '' : 'Please select an end time';
        if (!startUtc && !endUtc && !(moment(start) < moment(end))) {
            startUtc = 'Start time must be before end time';
        }
        return {
            startUtc: startUtc,
            endUtc: endUtc
        }
    }
}
