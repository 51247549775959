export const ArrayHelper = {
    groupBy(array, key) {
        return array.reduce((result, x) => {
            const keyValue = x[key];
            const existing = result.find((r) => r && r.key === keyValue);
            if (existing) {
                existing.values.push(x);
            } else {
                result.push({ key: keyValue, values: [x] });
            }
            return result;
        }, []);
    }
}
