import moment from 'moment';

export const ResourceHelper = {
    getResourceValidator() {
        return {
            name: '',
            colour: '',
            isValid: false,
            check(resource) {
                this.name = !!resource.name ? '' : 'Please enter resource name.';
                this.colour = !!resource.colour ? '' : 'Please select resource colour.';
                this.isValid = !this.name && !this.colour;
            }
        }
    },
    getOpeningHourValidator() {
        return {
            isValid: false,
            openingLocal: '',
            validate(openingHour) {
                this.isValid = !!openingHour.closed || moment(openingHour.closingLocal).isAfter(openingHour.openingLocal);
                this.openingLocal = !!this.isValid ? '' : 'Opening time must be before closing time';
            }
        }
    },
    getResourceHourValidator() {
        return {
            isValid: false,
            startLocal: '',
            validate(resourceHour) {
                this.startLocal = !!resourceHour.dayOff || moment(resourceHour.endLocal).isAfter(resourceHour.startLocal) ? '' : 'Start time must be before end time';
                this.isValid = !this.startLocal;
            }
        }
    }
}
