<template>
    <div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="marketing" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Accept marketing')}}</span>
            </label>
        </div>
        <div class="mb-2 d-flex align-items-center">
            <label class="ps-toggle-switch">
                <input v-model="smsAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : smsAppointmentsPartiallyEnabled}"></div>
                <span class="d-inline-block ml-2">{{$t('SMS Appointment')}}</span>
            </label>
            <i ref="sms-appointments-ref" aria-expanded="false" data-target="#sms-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
        </div>
        <div class="collapse" id="sms-appointments">
            <div class="appointment-notifications-body">
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsCancellation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Cancellation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsUpdated" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Updated')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsReminder" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Reminder')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsConfirmation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Confirmation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsThankYou" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Thank You')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="smsNoShow" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('No Show')}}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="smsMissYou" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Miss You (SMS)')}}</span>
            </label>
        </div>
        <div class="mb-2 d-flex align-items-center">
            <label class="ps-toggle-switch">
                <input v-model="emailAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : emailAppointmentsPartiallyEnabled}"></div>
                <span class="d-inline-block ml-2">{{$t('Email Appointment')}}</span>
            </label>
            <i ref="email-appointments-ref" aria-expanded="false" data-target="#email-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
        </div>
        <div class="collapse" id="email-appointments">
            <div class="appointment-notifications-body">
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailCancellation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Cancellation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailUpdated" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Updated')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailReminder" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Reminder')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailConfirmation" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Confirmation')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailThankYou" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('Thank You')}}</span>
                    </label>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="emailNoShow" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{$t('No Show')}}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <label class="ps-toggle-switch">
                <input v-model="emailMissYou" class="toggle-checkbox" type="checkbox">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Miss You (Email)')}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';
    import { NotificationSettingsUploadMixin } from '../../Shared/NotificationSettingsUploadMixin';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'ClientEditNotificationSettingsSection',
        mixins: [NotificationSettingsUploadMixin, StatusEmitterMixin],
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'updateClientEvent'
            ]),
            clientId() {
                return this.id;
            },
            status() {
                return {
                    loading: this.loadingNotificationSettings,
                    error: this.error
                }
            }
        },
        watch: {
            updateClientEvent() {
                this.tryUploadNotificationSettings();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
