<template>
    <section class="mb-3">
        <div class="card card-body">
            <div class="dashboard-card">
                <span class="fa-stack fa-2x float-left">
                    <i class="fa-thin fa-square fa-stack-2x color-medium-pink"></i>
                    <i class="fa-solid fa-user color-medium-turquoise fa-stack-1x fa-inverse"></i>
                </span>
                <div class="card-content">
                    <div>
                        <h2 class="text-secondary">{{count}}</h2>
                    </div>
                    <h5>{{$t('New Clients')}}</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ClientAggregateSearchMixin } from "../Shared/Aggregates/ClientAggregateSearchMixin";

    export default {
        name: 'ClientAggregateCount',
        mixins: [ClientAggregateSearchMixin],
        computed: {
            count() {
                return this.clientAggregates.reduce((previous, current) => previous + current.count, 0);
            }
        }
    }
</script>
