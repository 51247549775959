import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AppointmentCountCacheMixin } from "../Appointments/AppointmentCountCacheMixin";
import { mapGetters, mapActions } from 'vuex';

export const AppointmentResourceUpdateMixin = {
    mixins: [AppointmentCountCacheMixin],
    data() {
        return {
            selectedOption: null,
            completed: false
        }
    },
    props: {
        resource: Object
    },
    computed: {
        ...mapGetters('Appointments', [
            'findAppointmentCache'
        ]),
        appointmentCache() {
            return this.findAppointmentCache(this.appointmentCountCacheKey);
        },
        loadingAppointments() {
            return !!this.appointmentCache && this.appointmentCache.loading;
        },
        siteId() {
            return !!this.resource ? this.resource.siteId : '';
        },
        resourceId() {
            return !!this.resource ? this.resource.id : '';
        },
        statusId() {
            return 0;
        },
        count() {
            return !!this.appointmentCount ? this.appointmentCount.count : 0;
        },
        required() {
            return this.count > 0;
        },
        selectedId() {
            return !!this.selectedOption ? this.selectedOption.id : '';
        },
        appointmentResourceUpdateModel() {
            return {
                fromId: this.resourceId,
                toId: this.selectedId
            }
        },
        appointmentResourceUpdateModelValid() {
            const model = this.appointmentResourceUpdateModel;
            return !!model.fromId && !!model.toId;
        }
    },
    methods: {
        ...mapActions('Appointments', [
            'updateAppointmentResources'
        ]),
        tryUpdateAppointmentResources() {
            const self = this;
            if (!self.required || !self.appointmentResourceUpdateModelValid) {
                return;
            }
            self.completed = false;
            self.error = '';
            self.updateAppointmentResources({ key: self.appointmentCountCacheKey, payload: self.appointmentResourceUpdateModel })
                .then(() => self.completed = true)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
