import { Api } from '../../services/Api';

const state = () => ({
    loadingEmployeeCount: false,
    loadedEmployeeCount: false,
    employeeCount: null,
    employeeCountCacheCleared: 0
})

const mutations = {
    setloadingEmployeeCount(state, value) {
        state.loadingEmployeeCount = !!value;
    },
    setEmployeeCount(state, count) {
        state.employeeCount = count;
        state.loadedEmployeeCount = true;
    },
    clearEmployeeCount(state) {
        state.employeeCount = null;
        state.employeeCountCacheCleared++;
        state.loadedEmployeeCount = false;
        state.loadingEmployeeCount = false;
    }
}

const actions = {
    fetchEmployeeCount({ commit }) {
        commit('setloadingEmployeeCount', true);
        return new Promise((resolve, reject) => {
            Api.get('Employee/Count')
                .then(response => {
                    commit('setEmployeeCount', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setloadingEmployeeCount', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
