import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const NotificationDeleteMixin = {
    data() {
        return {
            error: ''
        }
    },
    props: {
        notification: Object
    },
    computed: {
        ...mapState('Notifications', [
            'deletingNotification'
        ]),
        notificationId() {
            return !!this.notification ? this.notification.id : '';
        }
    },
    methods: {
        ...mapActions('Notifications', [
            'deleteNotification'
        ]),
        tryDeleteNotification() {
            const self = this;
            if (!self.notificationId) {
                return;
            }
            self.error = '';
            self.deleteNotification(self.notificationId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
