import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';

export const PaymentOAuthsCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentOAuths', [
            'findPaymentOAuthsCache'
        ]),
        paymentOAuthsCache() {
            return this.findPaymentOAuthsCache(this.siteId);
        },
        paymentOAuths() {
            return !!this.paymentOAuthsCache ? this.paymentOAuthsCache.values : [];
        },
        loadingPaymentOAuths() {
            return !!this.paymentOAuthsCache && this.paymentOAuthsCache.loading;
        }
    },
    watch: {
        siteId() {
            this.refreshPaymentOAuths();
        }
    },
    methods: {
        ...mapActions('PaymentOAuths', [
            'findPaymentOAuths'
        ]),
        refreshPaymentOAuths() {
            if (!this.paymentOAuthsCache || !!this.paymentOAuthsCache.retry) {
                this.tryFindPaymentOAuths();
            }
        },
        tryFindPaymentOAuths() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.findPaymentOAuths(self.siteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentOAuths();
    }
}
