import { ErrorHelper } from '../../helpers/ErrorHelper.js';
import { mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper.js';

export const CommunicationCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Communications', [
            'findCommunicationsCache'
        ]),
        clientId() {
            console.log('OVERRIDE COMPUTED - clientId');
            return '';
        },
        communicationTypeId() {
            console.log('OVERRIDE COMPUTED - communicationTypeId');
            return 0;
        },
        communicationSearchModel() {
            return {
                clientId: this.clientId,
                communicationTypeId: this.communicationTypeId
            }
        },
        communicationSearchModelValid() {
            const model = this.communicationSearchModel;
            return !!model.clientId && !!model.communicationTypeId;
        },
        communicationCacheKey() {
            return CacheHelper.objectCacheKey(this.communicationSearchModel);
        },
        communicationsCache() {
            return this.findCommunicationsCache(this.communicationCacheKey);
        },
        communications() {
            return !!this.communicationsCache ? this.communicationsCache.values : [];
        },
        loadingCommunications() {
            return !!this.communicationsCache && this.communicationsCache.loading;
        }
    },
    watch: {
        communicationSearchModel() {
            this.refreshCommunications();
        }
    },
    methods: {
        ...mapActions('Communications', [
            'searchCommunications'
        ]),
        refreshCommunications() {
            if (!this.communicationsCache || !!this.communicationsCache.retry) {
                this.trySearchCommunications();
            }
        },
        trySearchCommunications() {
            const self = this;
            if (!self.communicationSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchCommunications({ key: self.communicationCacheKey, payload: self.communicationSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCommunications();
    }
}
