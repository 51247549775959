<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { ContactAddMixin } from "../../Shared/Contacts/ContactAddMixin";
    import { SiteIdMixin } from "../../Shared/Site/SiteIdMixin";

    export default {
        name: 'PostRegisterContact',
        mixins: [ContactAddMixin, SiteIdMixin, StatusEmitterMixin],
        props: {
            contactName: String,
            contactEmail: String
        },
        computed: {
            ownerId() {
                return this.siteId;
            },
            ownerTypeId() {
                return 12;
            },
            contactTypeId() {
                return 1;
            },
            fetchedContactCache() {
                return !!this.contactCache && !this.loadingContacts && !this.contactCache.retry;
            },
            contactComplete() {
                return this.contacts.length > 0;
            },
            canAddContact() {
                return !this.contactComplete
                    && !!this.ownerQueryModelValid
                    && !!this.fetchedContactCache;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingContacts,
                    completed: this.contactComplete
                }
            }
        },
        watch: {
            canAddContact(value) {
                if (value) {
                    this.trySetAndAddContact();
                }
            }
        },
        methods: {
            trySetAndAddContact() {
                if (!this.canAddContact) {
                    return;
                }
                this.name = this.contactName;
                this.email = this.contactEmail;
                this.tryAddContact();
            }
        },
        created() {
            this.trySetAndAddContact();
        }
    }
</script>
