<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Resources')}}</label>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <label>{{$t('Display images?')}}</label>
                <label class="ps-toggle-switch">
                    <input v-model="images" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { UserSettingUploadMixin } from '../../Shared/UserSettingUploadMixin';
    import { mapState } from 'vuex';

    export default {
        name: 'CalendarResourceSettings',
        mixins: [UserSettingUploadMixin],
        data() {
            return {
                images: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveScheduleSettingsEvent'
            ]),
            defaultUserSettingValue() {
                return {
                    images: false
                }
            },
            userSettingKey() {
                return 'CalendarResource'
            },
            userSettingUploadEdit() {
                return {
                    images: this.images
                }
            }
        },
        watch: {
            saveScheduleSettingsEvent() {
                this.uploadUserSetting();
            },
            loadingUserSettings(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            getUserSettingValid() {
                return true;
            },
            refreshEditModel(userSettingValue) {
                this.images = userSettingValue.images;
            }
        }
    }
</script>
