import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { HashHelper } from "../../../helpers/HashHelper";

export const ScheduleAddConflictsMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ConflictSearches', [
            'findConflictsSearchCache'
        ]),
        appointmentScheduleAddModel() {
            console.log('!!!OVERRIDE COMPUTED - appointmentScheduleAddModel!!!');
        },
        appointmentScheduleAddModelValid() {
            console.log('!!!OVERRIDE COMPUTED - appointmentScheduleAddModelValid!!!');
        },
        conflictsSearchKey() {
            return HashHelper.createObjectHash(this.appointmentScheduleAddModel);
        },
        conflictsSearch() {
            return this.findConflictsSearchCache(this.conflictsSearchKey);
        },
        conflicts() {
            return !!this.conflictsSearch ? this.conflictsSearch.values : [];
        },
        loadingConflicts() {
            return !!this.conflictsSearch && this.conflictsSearch.loading;
        }
    },
    watch: {
        conflictsSearchKey() {
            this.refreshConflictsSearch();
        }
    },
    methods: {
        ...mapActions('ConflictSearches', [
            'searchAddConflicts'
        ]),
        refreshConflictsSearch() {
            if (!this.conflictsSearch || !!this.conflictsSearch.retry) {
                this.trySearchConflicts();
            }
        },
        trySearchConflicts() {
            const self = this;
            if (!self.appointmentScheduleAddModelValid) {
                return;
            }
            self.error = '';
            self.searchAddConflicts({ key: self.conflictsSearchKey, payload: self.appointmentScheduleAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshConflictsSearch();
    }
}
