import { mapState, mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteHelper } from "../../helpers/SiteHelper"
import { SiteIdMixin } from "./Site/SiteIdMixin";

export const SiteUpdateMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: '',
            companyName: '',
            name: '',
            email: '',
            tagLine: '',
            contactNumber: '',
            website: '',
            siteValidator: SiteHelper.getSiteValidator()
        }
    },
    computed: {
        ...mapState('Sites', [
            'sites'
        ]),
        ...mapState('Sites', [
            'updatingSite',
        ]),
        site() {
            return this.sites.find(s => s.id === this.siteId);
        },
        siteUpdateModel() {
            return !!this.site ? Object.assign({}, this.site, {
                companyName: this.companyName,
                name: this.name,
                email: this.email,
                tagLine: this.tagLine,
                contactNumber: this.contactNumber,
                website: this.website
            }) : {};
        },
        siteUpdateModelValid() {
            return !!this.siteUpdateModel.id && this.siteValidator.isValid;
        },
        siteUpdateModelModified() {
            return JSON.stringify(this.siteUpdateModel) !== JSON.stringify(this.site);
        }
    },
    watch: {
        site() {
            this.refreshSiteModel();
        },
        selected() {
            this.refreshSiteModel();
        }
    },
    methods: {
        ...mapActions('Sites', [
            'updateSite'
        ]),
        refreshSiteModel() {
            this.companyName = !!this.site ? this.site.companyName : '';
            this.name = !!this.site ? this.site.name : '';
            this.email = !!this.site ? this.site.email : '';
            this.tagLine = !!this.site ? this.site.tagLine : '';
            this.contactNumber = !!this.site ? this.site.contactNumber : '';
            this.website = !!this.site ? this.site.website : '';
        },
        tryUpdateSite() {
            const self = this;
            self.siteValidator.check(self.siteUpdateModel);
            if (!self.siteUpdateModelValid || !self.siteUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateSite(self.siteUpdateModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSiteModel();
    }
}
