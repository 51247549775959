<template>
    <div class="p-0 mr-0">
        <img v-if="image" alt="Default" :src="image.thumbnails.small" width="100">
        <template v-else>
            <img alt="Default" src="../../../assets/images/DogProfile_Blue_300.svg" width="100">
        </template>
    </div>
</template>

<script>
    import { DefaultPetImageCacheMixin } from "../../../mixins/Settings/Site/DefaultPetImageCacheMixin";

    export default {
        name: 'DefaultPetImageSmall',
        mixins: [DefaultPetImageCacheMixin]
    }
</script>
