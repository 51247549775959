<template>
    <div>
        <div class="input-group form-group">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="fal fa-lock-alt"></i></span>
            </div>
            <input v-model="password" type="password" class="form-control authBorder" :placeholder="$t('Choose a password')" aria-label="Password" required="">
        </div>
        <label v-if="!atLeast8Characters" class="auth">{{$t('At least 8 characters')}}</label>
        <label v-if="!atLeast1Uppercase" class="auth">{{$t('At least 1 uppercase')}}</label>
        <label v-if="!atLeast1Number" class="auth">{{$t('At least 1 number')}}</label>
    </div>
</template>

<script>
    export default {
        name: 'PasswordInput',
        props: {
            value: String
        },
        data() {
            return {
                password: this.value
            }
        },
        computed: {
            atLeast8Characters() {
                return this.password.length > 7;
            },
            atLeast1Uppercase() {
                return /[A-Z]/.test(this.password);
            },
            atLeast1Number() {
                return /\d/.test(this.password);
            }
        },
        watch: {
            password() {
                const valid = !!this.atLeast8Characters && !!this.atLeast1Uppercase && !!this.atLeast1Number;
                this.$emit('input', valid ? this.password : '');
            }
        }
    }
</script>
