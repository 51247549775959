<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="sent" role="alert" class="alert alert-success alert-icon">
            <div class="alert-content">
                <p>{{$t('Sent!')}}</p>
            </div>
        </div>
        <div v-else role="alert" class="alert alert-warning alert-icon">
            <div class="alert-content">
                <p>{{$t('Do you want to send a test email to:')}} <strong>{{userEmail}}</strong>?</p>
            </div>
        </div>
        <div v-if="sent" class="d-flex justify-content-center">
            <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('Close')}}</button>
        </div>
        <div v-else class="d-flex justify-content-center">
            <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('No')}}</button>
            <button @click="sendTestCampaign" v-bind:disabled="sending" type="button" class="btn btn-primary">
                {{$t('Yes')}}
                <span v-if="sending" class="inline-loader"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../../../helpers/ErrorHelper";
    import { Api } from "../../../../services/Api";
    import { UserCacheMixin } from "../../../Shared/UserCacheMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'CampaignTestSend',
        mixins: [UserCacheMixin],
        props: {
            campaign: Object
        },
        data() {
            return {
                error: '',
                sent: false,
                sending: false
            }
        },
        computed: {
            userEmail() {
                return !!this.user ? this.user.email : '';
            }
        },
        methods: {
            sendTestCampaign() {
                const self = this;
                if (!!self.sending) {
                    return;
                }
                self.error = '';
                self.sent = false;
                self.sending = true;
                Api.post('Campaign/Test/Send', self.campaign)
                    .then(() => self.sent = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.sending = false);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
