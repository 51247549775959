import { StripoUpdateMixin } from "../../components/Shared/Stripo/StripoUpdateMixin";
import { CampaignHelper } from "../../helpers/CampaignHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { EmailCampaignTemplatesCacheMixin } from "./EmailCampaignTemplatesCacheMixin";
import { mapActions } from 'vuex';
import { StripoDefaultTemplateCacheMixin } from "../../components/Shared/StripoDefaultTemplateCacheMixin";

export const EmailCampaignTemplateUpdateMixin = {
    mixins: [EmailCampaignTemplatesCacheMixin, StripoDefaultTemplateCacheMixin, StripoUpdateMixin],
    data() {
        return {
            subject: '',
            templateId: '',
            emailCampaignTemplateValidator: CampaignHelper.getEmailCampaignTemplateValidator()
        }
    },
    computed: {
        stripoTemplateId() {
            return this.templateId;
        },
        defaultStripoTemplate() {
            return this.stripoDefaultTemplate;
        },
        emailCampaignTemplateId() {
            console.log('OVERRIDE COMPUTED - emailCampaignTemplateId');
        },
        emailCampaignTemplate() {
            return this.emailCampaignTemplates.find(t => t.id === this.emailCampaignTemplateId);
        },
        emailCampaignTemplateUpdateModel() {
            return !!this.emailCampaignTemplate ? Object.assign({}, this.emailCampaignTemplate, {
                subject: this.subject,
                stripoTemplateId: this.templateId
            }) : {};
        },
        emailCampaignTemplateUpdateModelValid() {
            return this.emailCampaignTemplateValidator.isValid && !!this.templateId && !!this.siteId;
        },
        emailCampaignTemplateUpdateModelModified() {
            return JSON.stringify(this.emailCampaignTemplateUpdateModel) !== JSON.stringify(this.emailCampaignTemplate);
        }
    },
    watch: {
        emailCampaignTemplate() {
            this.refreshEmailCampaignTemplateModel();
        }
    },
    methods: {
        ...mapActions('EmailCampaignTemplates', [
            'updateEmailCampaignTemplate'
        ]),
        tryUploadEmailCampaignTemplate() {
            if (!!this.stripoTemplate) {
                this.tryUpdateStripoTemplate();
                this.tryUpdateEmailCampaignTemplate();
            } else {
                this.tryAddStripoTemplate();
            }
        },
        refreshEmailCampaignTemplateModel() {
            this.subject = !!this.emailCampaignTemplate ? this.emailCampaignTemplate.subject : '';
            this.templateId = !!this.emailCampaignTemplate ? this.emailCampaignTemplate.stripoTemplateId : '';
        },
        afterStripoTemplateAdded(stripoTemplate) {
            this.templateId = stripoTemplate.id;
            this.tryUpdateEmailCampaignTemplate();
        },
        tryUpdateEmailCampaignTemplate() {
            const self = this;
            self.emailCampaignTemplateValidator.check(self.emailCampaignTemplateUpdateModel);
            if (!self.emailCampaignTemplateUpdateModelValid || !self.emailCampaignTemplateUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updateEmailCampaignTemplate({ key: self.siteId, payload: self.emailCampaignTemplateUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEmailCampaignTemplateModel();
    }
}
