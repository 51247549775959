<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header collapsed" data-target="#collapse1" data-toggle="collapse">
            {{$t('More appointment options')}} <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse1">
            <div class="card card-body">
                <frequency></frequency>
                <occurrences v-if="showOccurrences"></occurrences>
                <notes></notes>
            </div>
        </div>
    </section>
</template>

<script>
    import AppointmentOptionsFrequency from './AppointmentOptionsFrequency.vue';
    import AppointmentOptionsOccurrences from './AppointmentOptionsOccurrences.vue';
    import AppointmentOptionsNotes from './AppointmentOptionsNotes.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'AppointmentOptions',
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'appointmentFrequencyId'
            ]),
            showOccurrences() {
                return this.appointmentFrequencyId > 0;
            }
        },
        components: {
            'frequency': AppointmentOptionsFrequency,
            'occurrences': AppointmentOptionsOccurrences,
            'notes': AppointmentOptionsNotes
        }
    }
</script>