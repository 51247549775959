import { Api } from '../../services/Api';

const state = () => ({
    addingEvent: false,
    eventCaches: []
})

const getters = {
    findEventCache: (state) => key => {
        return state.eventCaches.find(ec => ec.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.eventCaches.find(ec => ec.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.eventCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    appendEvent(state, event) {
        const local = state.eventCaches.filter(ec => ec.key !== event.id);
        local.push({ key: event.id, value: event, loading: false });
        state.eventCaches = local;
    },
    appendEvents(state, events) {
        state.eventCaches = state.eventCaches
            .concat(events.map(e => ({ key: e.id, value: e, loading: false })));
    },
    setRetry(state, key) {
        const existing = state.eventCaches.find(ec => ec.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setAdding(state, adding) {
        state.addingEvent = !!adding;
    },
    removeEvent(state, id) {
        const existing = state.eventCaches.find(ec => ec.key === id);
        if (!!existing) {
            existing.value = null;
            existing.loading = false;
        }
    }
}

const actions = {
    getEvent({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Event/' + id)
                .then(response => {
                    commit('appendEvent', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                })
        });
    },
    addEvents({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Event', payload)
                .then(response => {
                    commit('appendEvents', response.data);
                    commit('ApplicationEvents/triggerEventsAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateEvent({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: payload.id, loading: true });
            Api.put('Event', payload)
                .then(response => {
                    commit('appendEvent', response.data);
                    commit('ApplicationEvents/triggerEventUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', { key: payload.id, loading: false });
                    reject(error);
                })
        });
    },
    deleteEvent({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.delete('Event/' + id)
                .then(response => {
                    commit('removeEvent', id);
                    commit('ApplicationEvents/triggerEventDeleted', null, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', { key: id, loading: false });
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
