<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../../mixins/Shared/StatusEmitterMixin";
    import { PetCacheMixinV2 } from "../../../Shared/PetCacheMixinV2";

    export default {
        name: 'AppointmentRebookPets',
        mixins: [PetCacheMixinV2, StatusEmitterMixin],
        props: {
            appointment: Object
        },
        computed: {
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            status() {
                return {
                    completed: this.completedPets,
                    loading: this.loadingPets,
                    error: this.error,
                    pets: this.pets
                }
            }
        }
    }
</script>
