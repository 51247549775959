<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="success" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Notification sent')}}</p>
            </div>
            <button @click="success=false" type="button" class="close">
                <span>×</span>
            </button>
        </div>
        <div class="col-md-4">
            <label>{{$t('Send notification')}}</label>
        </div>
        <div class="col-md-7">
            <input v-model="text" class="form-control" type="text" maxlength="200">
            <validation-message v-if="notificationAddValidator.text" :message="notificationAddValidator.text"></validation-message>
        </div>
        <div class="col-md-1 px-md-0 mt-2">
            <button @click="tryAddNotification" class="btn btn-sm btn-outline" v-bind:disabled="addingNotification">
                <span v-if="addingNotification" class="inline-loader"></span>
                <template v-else>
                    <i class="fal fa-paper-plane"></i>
                </template>
            </button>
        </div>
    </div>
</template>

<script>
    import { NotificationAddMixin } from "./NotificationAddMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'NotificationSend',
        mixins: [NotificationAddMixin],
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
