<template>
    <div v-if="required" class="form-group row">
        <div class="col-md-4">
            <label>{{siteName}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown bootstrap-select custom-select form-control">
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Select staff">
                    <div class="filter-option">
                        <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{selectedName}}</div>
                        </div>
                    </div>
                </button>
                <div class="dropdown-menu ">
                    <div class="bs-searchbox">
                        <input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list">
                    </div>
                    <div class="inner show" role="listbox" id="bs-select-3" tabindex="-1">
                        <ul class="dropdown-menu inner show" role="presentation">
                            <li v-for="resource in options" :key="resource.id">
                                <a @click="onClick(resource)" role="option" class="dropdown-item" v-bind:class="{ 'active selected': selectedId===resource.id }">
                                    <span class=" bs-ok-default check-mark"></span>
                                    <span class="text">
                                        {{resource.name}}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { AppointmentResourceUpdateMixin } from "./AppointmentResourceUpdateMixin";
    import { mapState } from 'vuex';
    import { ResourceCacheMixin } from "./ResourceCacheMixin";

    export default {
        name: 'AppointmentResourceUpdateItem',
        mixins: [ResourceCacheMixin, AppointmentResourceUpdateMixin],
        props: {
            value: Array
        },
        computed: {
            ...mapState('Sites', [
                'sites'
            ]),
            ...mapState('ApplicationEvents', [
                'saveAppointmentResourcesEvent'
            ]),
            options() {
                return this.resources.filter(r => r.id !== this.resourceId)
            },
            site() {
                return this.sites.find(s => s.id === this.siteId);
            },
            siteName() {
                return !!this.site ? this.site.name : 'Unknown';
            },
            selectedName() {
                return !!this.selectedOption ? this.selectedOption.name : this.options.length === 0 ? 'No options available!' : `${this.$t('Please select')}...`;
            },
            status() {
                return {
                    resourceId: this.resourceId,
                    required: this.required,
                    ready: this.appointmentResourceUpdateModelValid,
                    loading: this.loadingResources || this.loadingAppointmentCount || this.loadingAppointments,
                    completed: this.completed,
                    error: this.error
                };
            }
        },
        watch: {
            status() {
                this.emitStatuses();
            },
            saveAppointmentResourcesEvent() {
                this.tryUpdateAppointmentResources();
            }
        },
        methods: {
            onClick(resource) {
                this.selectedOption = resource;
            },
            emitStatuses() {
                const statuses = this.value.filter(s => s.resourceId !== this.resourceId);
                statuses.push(this.status);
                this.$emit('input', statuses);
            }
        },
        created() {
            this.emitStatuses();
        }
    }
</script>
