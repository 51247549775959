<template>
    <div>
        <div v-if="!!timeFormatSetting" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Time Format')}} {{$t('successfully added.')}}</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Time Format')}}</label>
                </div>
                <div class="col-md-8">
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                            {{selectedOptionDescription}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="option in timeFormatOptions"
                                    class="dropdown-item pointer"
                                    :key="option.id"
                                    @click="setTimeFormatId(option.id)"
                                    v-bind:class="{'active': option.id === timeFormatId}">
                                    {{now | moment(option.momentJS)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from "vuex";
    import { TimeFormatOptionsCacheMixin } from "../../Shared/Time/TimeFormatOptionsCacheMixin";
    import { TimeFormatSettingAddMixin } from "../../Shared/Time/TimeFormatSettingAddMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';

    export default {
        name: 'SiteAddTimeFormat',
        mixins: [TimeFormatSettingAddMixin, TimeFormatOptionsCacheMixin, StatusEmitterMixin],
        props: {
            addedSiteId: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            siteId() {
                return this.addedSiteId;
            },
            now() {
                return moment();
            },
            canAddTimeFormat() {
                return !!this.addedSiteId && !this.completed;
            },
            completed() {
                return !!this.timeFormatSetting;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loadingTimeFormatSetting
                }
            },
            selectedOption() {
                return this.timeFormatOptions.find(tfo => tfo.id == this.timeFormatId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.now.format(this.selectedOption.momentJS) : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            canAddTimeFormat(value) {
                if (value) {
                    this.tryAddTimeFormatSetting();
                }
            },
            saveSiteEvent() {
                if (this.canAddTimeFormat) {
                    this.tryAddTimeFormatSetting();
                }
            }
        },
        methods: {
            setTimeFormatId(value) {
                this.timeFormatId = value;
            },
            tryGetTimeFormatSetting() {
                // do nothing
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
