import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentCheckoutsCaches: []
});

const getters = {
    findPaymentCheckoutsCache: (state) => key => state.paymentCheckoutsCaches.find(pcc => pcc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentCheckoutsCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentCheckoutsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCheckouts(state, { key, checkouts }) {
        const existing = state.paymentCheckoutsCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.values = checkouts;
            existing.loading = false;
        } else {
            state.paymentCheckoutsCaches.push({ key: key, values: checkouts, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentCheckoutsCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendCheckout(state, { key, checkout }) {
        const existing = state.paymentCheckoutsCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.values.push(checkout);
        } else {
            state.paymentCheckoutsCaches.push({ key: key, values: [checkout], loading: false });
        }
    },
    insertCheckout(state, { key, checkout }) {
        const existing = state.paymentCheckoutsCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            const old = existing.values.find(c => c.id === checkout.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, checkout);
            }
        } else {
            state.paymentCheckoutsCaches.push({ key: key, values: [checkout], loading: false });
        }
    }
}

const actions = {
    findCheckouts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.get('Checkout?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setCheckouts', { key: key, checkouts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addCheckout({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.post('Checkout', payload)
                .then(response => {
                    commit('appendCheckout', { key: key, checkout: response.data });
                    commit('PaymentCheckoutSearches/clearPaymentCheckoutSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateCheckout({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.put('Checkout/' + id)
                .then(response => {
                    commit('insertCheckout', { key: key, checkout: response.data });
                    commit('PaymentCheckoutSearches/clearPaymentCheckoutSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    cancelCheckout({ commit }, { key, id }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.put('Checkout/' + id + '/Cancel')
                .then(response => {
                    commit('insertCheckout', { key: key, checkout: response.data });
                    commit('PaymentCheckoutSearches/clearPaymentCheckoutSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    refundCheckout({ commit }, { key, id, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.put('Checkout/' + id + '/Refund', payload)
                .then(response => {
                    commit('insertCheckout', { key: key, checkout: response.data });
                    commit('PaymentCheckoutSearches/clearPaymentCheckoutSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
