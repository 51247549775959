import { mapActions } from "vuex";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { CustomDurationAddMixin } from "./CustomDurationAddMixin";

export const CustomDurationUpdateMixin = {
    mixins: [CustomDurationAddMixin],
    computed: {
        customDurationId() {
            console.log('!!!OVERRIDE COMPUTED - customDurationId!!!');
        },
        customDuration() {
            return this.customDurations.find(cd => cd.id === this.customDurationId);
        }
    },
    methods: {
        ...mapActions('CustomDurations', [
            'deleteCustomDuration'
        ]),
        tryDeleteCustomDuration() {
            const self = this;
            if (!self.customDuration) {
                return;
            }
            self.error = '';
            self.deleteCustomDuration({ key: self.siteId, id: self.customDurationId })
                .then(() => self.afterCustomDurationDelete())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterCustomDurationDelete() {
            //override if necessary
        }
    }
}
