<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Service(s)')}}</label>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <div class="dropdown bootstrap-select custom-select form-control">
                    <button type="button" data-toggle="dropdown" role="combobox" class="btn dropdown-toggle btn-light">
                        <div class="filter-option">
                            <div class="filter-option-inner">
                                <span class="text">{{$t('Click to search')}}...</span>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu" style="max-height: 300px !important; height: max-content !important; overflow-y: hidden !important;">
                        <div class="bs-searchbox">
                            <input v-model="searchTerm" type="search" autocomplete="off" role="combobox" class="form-control">
                        </div>
                        <div role="listbox" id="bs-select-1" tabindex="-1" class="inner show">
                            <ul class="dropdown-menu inner show" role="presentation" style="max-height: 217px !important; height: 100%; overflow-y: scroll !important">
                                <li v-for="option in options" :key="option.id" @click.stop="">
                                    <div class="dropdown-item">
                                        <label class="custom-control custom-checkbox">
                                            <input v-model="option.selected" @click="onClick(option)" class="custom-control-input" type="checkbox">
                                            <span class="custom-control-indicator"></span>
                                            <span class="custom-control-description">{{option.name}} (£{{option.amount.toFixed(2)}})</span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="orderItems.length > 0" class="applied-filters d-flex flex-wrap">
                    <span v-for="item in orderItems" @click="removeItem(item)" class="active-filter mr-1 mb-1">{{item.name}}</span>
                    <a @click="clearItems" class="clear-filters pointer mr-1 mb-1">Clear services</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { AppointmentProductMixin } from '../../Shared/AppointmentProductMixin.js';

    export default {
        name: 'AppointmentOrderItemSelect',
        mixins: [AppointmentProductMixin],
        props: {
            index: Number
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'clientId'
            ]),
            ...mapGetters('AppointmentScheduleUpload', [
                'indexAppointmentOrder'
            ]),
            appointmentOrder() {
                return this.indexAppointmentOrder(this.index);
            },
            orderItems() {
                return this.appointmentOrder.orderItems;
            },
            petId() {
                return !!this.appointmentOrder.pet ? this.appointmentOrder.pet.id : '';
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setOrderItems',
                'removeOrderItem',
                'clearOrderItems'
            ]),
            clearItems() {
                this.clearOrderItems(this.index);
            },
            removeItem(item) {
                this.removeOrderItem({ index: this.index, product: { id: item.productId } });
            },
            setSelected(selected) {
                this.setOrderItems({ index: this.index, items: selected });
            }
        }
    }
</script>

<style scoped>
    .applied-filters {
        padding-left: 0;
    }
</style>
