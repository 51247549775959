import { Api } from '../../services/Api';
import moment from 'moment';

const state = () => ({
    fetchingNotifications: false,
    fetchedNotifications: false,
    notificationsLastFetched: null,
    viewingNotifications: false,
    deletingNotification: false,
    addingNotification: false,
    notificationsTimeoutId: null,
    notifications: []
})

const mutations = {
    setTimeoutId(state, id) {
        state.notificationsTimeoutId = id;
    },
    setFetching(state) {
        state.fetchingNotifications = true;
        state.fetchedNotifications = false;
    },
    setFetched(state, fetched) {
        state.fetchedNotifications = !!fetched;
        state.fetchingNotifications = false;
    },
    setViewing(state, viewing) {
        state.viewingNotifications = !!viewing;
    },
    appendNotifications(state, notifications) {
        state.notifications = state.notifications.concat(notifications);
        state.notificationsLastFetched = moment();
    },
    insertNotifications(state, notifications) {
        for (var i = 0; i < notifications.length; i++) {
            const notification = notifications[i];
            const existing = state.notifications.find(n => n.id === notification.id);
            if (!!existing) {
                const index = state.notifications.indexOf(existing);
                state.notifications.splice(index, 1, notification);
            } else {
                state.notifications.push(notification);
            }
        }
    },
    setDeleting(state, deleting) {
        state.deletingNotification = !!deleting;
    },
    removeNotification(state, id) {
        state.notifications = state.notifications.filter(n => n.id !== id);
    },
    setAdding(state, adding) {
        state.addingNotification = !!adding;
    }
}

const actions = {
    findNotifications({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setFetching');
            Api.get('Notification?' + new URLSearchParams(payload))
                .then(response => {
                    commit('appendNotifications', response.data);
                    commit('setFetched', true);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetched', false);
                    reject(error);
                });
        });
    },
    viewNotifications({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setViewing', true);
            Api.put('Notification/View', payload)
                .then(response => {
                    commit('insertNotifications', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setViewing', false));
        });
    },
    deleteNotification({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setDeleting', true);
            Api.delete('Notification/' + id)
                .then(response => {
                    commit('removeNotification', id);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setDeleting', false));
        })
    },
    addNotification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('General/Notification', payload)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
