<template>
    <div>
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('Edit')}} {{title}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-nav header-nav-left">
                <a @click="cancel" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
        </header>
        <div class="content fixed-action-btns-visible">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group">
                <label>{{$t('Subject')}}<span class="required-field"></span></label>
                <input v-model="subject" class="form-control" :placeholder="$t('Subject')">
                <validation-message v-if="emailCampaignTemplateValidator.subject" :message="emailCampaignTemplateValidator.subject"></validation-message>
            </div>
            <stripo-templator ref="stripoTemplator" v-if="displayTemplator" v-model="stripoModel" :accountId="accountId"></stripo-templator>
        </div>
        <div class="fixed-action-btns action-btns fixed-action-btns--nonav">
            <div>
                <template-tags :method-type-id="1"></template-tags>
                <div>
                    <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                        {{$t('Save')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../../../routing';
    import SlottedInput from '../../Shared/Input/SlottedInput.vue';
    import StripoTemplator from '../../Shared/Stripo/StripoTemplator.vue';
    import CampaignTemplateTags from './CampaignTemplateTags.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import { EmailCampaignTemplateUpdateMixin } from '../../../mixins/Campaigns/EmailCampaignTemplateUpdateMixin';
    import { AccountCacheMixin } from '../../../mixins/Account/AccountCacheMixin';

    export default {
        name: 'CampaignEmailTemplateUpdate',
        mixins: [EmailCampaignTemplateUpdateMixin, AccountCacheMixin],
        computed: {
            emailCampaignTemplateId() {
                return this.$route.params.id;
            },
            stripoModel: {
                get() {
                    return {
                        html: this.html,
                        css: this.css,
                        templateId: this.stripoTemplate ? this.stripoTemplate.id : ''
                    };
                },
                set(value) {
                    this.html = value.html;
                    this.css = value.css;
                    this.tryUploadEmailCampaignTemplate();
                }
            },
            displayTemplator() {
                return !!this.html && !!this.css && !!this.account;
            },
            title() {
                return !!this.emailCampaignTemplate ? this.emailCampaignTemplate.subject : 'Template';
            },
            loading() {
                return this.loadingStripoTemplate || this.loadingEmailCampaignTemplates;
            }
        },
        methods: {
            cancel() {
                router.go(-1);
            },
            save() {
                this.$refs.stripoTemplator.emitTemplate();
            }
        },
        components: {
            'stripo-templator': StripoTemplator,
            'template-tags': CampaignTemplateTags,
            'slotted-input': SlottedInput,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
