import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';
import { AggregateSearchMixin } from './AggregateSearchMixin';

export const AppointmentAggregateSearchMixin = {
    mixins: [AggregateSearchMixin],
    computed: {
        ...mapGetters('AppointmentAggregateSearches', [
            'findAppointmentAggregatesSearch'
        ]),
        aggregateSearchModel() {
            return {
                startLocal: !!this.timePeriod ? this.timePeriod.startLocal : '',
                endLocal: !!this.timePeriod ? this.timePeriod.endLocal : '',
                frequencyTypeId: 2,
                siteIds: this.siteIds
            }
        },
        aggregateSearch() {
            return this.findAppointmentAggregatesSearch(this.aggregateSearchKey);
        },
        appointmentAggregates() {
            return !!this.aggregateSearch ? this.aggregateSearch.values : [];
        },
        searchingAppointmentAggregates() {
            return !!this.aggregateSearch && this.aggregateSearch.loading;
        }
    },
    methods: {
        ...mapActions('AppointmentAggregateSearches', [
            'searchAppointmentAggregates'
        ]),
        trySearchAggregates() {
            const self = this;
            if (!self.aggregateSearchModelValid) {
                return;
            }
            self.searchAppointmentAggregates({ key: self.aggregateSearchKey, payload: self.aggregateSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
