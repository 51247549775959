import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const PetDurationCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PetDurations', [
            'findPetDurationCache'
        ]),
        petId() {
            console.log('!!!OVERRIDE COMPUTED - petId!!!');
        },
        petDurationCache() {
            return this.findPetDurationCache(this.petId);
        },
        petDuration() {
            return !!this.petDurationCache ? this.petDurationCache.value : null;
        },
        loadingPetDuration() {
            return !!this.petDurationCache && this.petDurationCache.loading;
        }
    },
    watch: {
        petId() {
            this.refreshPetDuration();
        }
    },
    methods: {
        ...mapActions('PetDurations', [
            'fetchPetDuration'
        ]),
        refreshPetDuration() {
            if (!this.petDurationCache || !!this.petDurationCache.retry) {
                this.tryFetchPetDuration();
            }
        },
        tryFetchPetDuration() {
            const self = this;
            if (!self.petId) {
                return;
            }
            self.error = '';
            self.fetchPetDuration(self.petId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetDuration();
    }
}
