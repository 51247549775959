import { mapGetters } from 'vuex';

export const AppointmentScheduleOrdersCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentScheduleOrders', [
            'findAppointmentScheduleOrderCache'
        ]),
        scheduleId() {
            console.log('!!!OVERRIDE COMPUTED - scheduleId!!!');
        },
        appointmentScheduleOrdersCache() {
            return this.findAppointmentScheduleOrderCache(this.scheduleId);
        },
        loadingAppointmentScheduleOrders() {
            return !!this.findAppointmentScheduleOrderCache && this.findAppointmentScheduleOrderCache.loading;
        }
    }
}
