import moment from 'moment-timezone';

export const TimeZoneHelper = {
    getTimeZoneOptions() {
        const now = Date.now();
        return moment.tz.names().map(z => ({
            id: z,
            offset: moment.tz.zone(z).utcOffset(now),
            description: z + ' ' + moment.tz(z).format('Z')
        })).sort((a, b) => b.offset - a.offset)
    }
}
