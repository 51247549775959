import { Api } from "../../services/Api";

const state = () => ({
    breedProductGroups: [],
})

const getters = {
    findBreedProductGroup: (state) => key => state.breedProductGroups.find(bpg => bpg.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.breedProductGroups.find(bpg => bpg.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.breedProductGroups.push({ key: key, values: [], loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.breedProductGroups.find(bpg => bpg.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setBreedProducts(state, { key, products }) {
        const existing = state.breedProductGroups.find(bpg => bpg.key === key);
        if (!!existing) {
            existing.values = products;
            existing.loading = false;
        } else {
            state.breedProductGroups.push({ key: key, values: products, loading: false });
        }
    },
    appendBreedProducts(state, { key, products }) {
        const existing = state.breedProductGroups.find(bpg => bpg.key === key);
        if (!!existing) {
            existing.values = existing.values.concat(products);
        } else {
            state.breedProductGroups.push({ key: key, values: products, loading: false });
        }
    },
    insertBreedProducts(state, { key, products }) {
        const existing = state.breedProductGroups.find(bpg => bpg.key === key);
        if (!!existing) {
            for (var i = 0; i < products.length; i++) {
                const product = products[i];
                const previous = existing.values.find(p => p.id === product.id);
                if (!!previous) {
                    const index = existing.values.indexOf(previous);
                    existing.values.splice(index, 1, product);
                } else {
                    existing.values.push(product);
                }
            }
        } else {
            state.breedProductGroups.push({ key: key, values: products, loading: false });
        }
    }
}

const actions = {
    groupBreedProducts({ commit }, breedId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: breedId, loading: true });
            Api.get('BreedProduct/Group/' + breedId)
                .then(response => {
                    commit('setBreedProducts', { key: breedId, products: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', breedId);
                    reject(error);
                });
        });
    },
    addBreedProducts({ commit }, { breedId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: breedId, loading: true });
            Api.post('BreedProduct', payload)
                .then(response => {
                    commit('appendBreedProducts', { key: breedId, products: response.data });
                    commit('BreedProducts/setBreedProducts', response.data, { root: true });
                    commit('BreedProductSearches/clearBreedProductSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: breedId, loading: false }));
        });
    },
    updateBreedProducts({ commit }, { breedId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: breedId, loading: true });
            Api.put('BreedProduct', payload)
                .then(response => {
                    commit('insertBreedProducts', { key: breedId, products: response.data });
                    commit('BreedProducts/setBreedProducts', response.data, { root: true });
                    commit('BreedProductSearches/clearBreedProductSearches', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: breedId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
