import { CalendarAppointmentSettingMixin } from "../../../mixins/Settings/User/CalendarAppointmentSettingMixin";
import { BreedCacheMixin } from "../BreedCacheMixin";
import { ClientCacheMixin } from "../ClientCacheMixin";
import { PetCacheMixinV2 } from "../PetCacheMixinV2";
import { UserSettingCacheMixin } from "../UserSettingCacheMixin";

export const PetDisplayMixin = {
    mixins: [UserSettingCacheMixin, PetCacheMixinV2, ClientCacheMixin, BreedCacheMixin, CalendarAppointmentSettingMixin],
    computed: {
        petId() {
            console.log('!!!OVERRIDE COMPUTED - petId!!!');
            return '';
        },
        ownerId() {
            return this.petId;
        },
        ownerTypeId() {
            return 3;
        },
        pet() {
            return this.pets.find(p => p.id === this.petId);
        },
        breedId() {
            return !!this.pet ? this.pet.breedId : '';
        },
        petName() {
            return !!this.pet ? this.pet.name : 'Unknown';
        },
        clientLastName() {
            return !!this.client ? this.client.lastName : 'Unknown';
        },
        clientFullName() {
            return !!this.client ? this.client.fullName : 'Unknown';
        },
        clientMobile() {
            const mobile = !!this.client ? this.client.telephoneMobile : '';
            return mobile ? ' - ' + mobile : '';
        },
        lines() {
            if (this.userSettingValue.displayType === 1) {
                return [this.petName + ' ' + this.clientLastName + ' - (' + this.breedName + ')'];
            } else if (this.userSettingValue.displayType === 2) {
                return [this.petName + ' (' + this.breedName + ')'];
            } else if (this.userSettingValue.displayType === 3 || this.userSettingValue.displayType === 4) {
                return [this.petName + ' (' + this.breedName + ')', this.clientFullName + this.clientMobile];
            } else if (this.userSettingValue.displayType === 5) {
                return [this.petName + ' ' + this.clientLastName + ' (' + this.breedName + ')']
            }
            return [this.petName + ' (' + this.clientLastName + ')'];
        },
        showAppointmentNotes() {
            return this.userSettingValue.displayType === 5;
        },
        showImages() {
            return this.userSettingValue.images;
        },
        showAddressLines() {
            return this.userSettingValue.displayType === 4;
        },
        showOrders() {
            return this.userSettingValue.orders;
        }
    }
}
