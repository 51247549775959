<template>
    <div id="brand-new-client-pet-fields">
        <div v-if="!reset" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{messageSuccessful}}</p>
            </div>
            <button @click="handleReset" type="button" class="close">
                <span class="fas fa-plus"></span>
            </button>
        </div>
        <template v-else>
            <section class="rounded-card-white mb-3">
                <site-select></site-select>
                <new-client v-model="clientStatus"
                            :saving-type="savingType">
                </new-client>
                <new-pets v-model="petsStatus" 
                          :added-client-id="addedClientId">
                </new-pets>
                <invite-add v-model="inviteAddStatus"
                            :saving-type="savingType"
                            :added-client-id="addedClientId"
                            :pets-status="petsStatus">
                </invite-add>
                <invite-send v-model="inviteSendStatus"
                             :saving-type="savingType"
                             :added-client-id="addedClientId"
                             :invite-add-status="inviteAddStatus">
                </invite-send>
            </section>
            <client-options></client-options>
        </template>
        <div class="modal-footer">
            <div class="d-md-flex flex-md-row flex-column">
                <save-button v-model="savingType"
                             @save="handleSave"
                             :loading="loading" 
                             :added-client-id="addedClientId">
                </save-button>
                <button @click="setDefault" 
                        class="btn btn-outline-secondary ml-md-3 mt-3 mt-md-0" 
                        data-dismiss="modal" 
                        type="button">
                    {{$t('Cancel')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { SiteIdMixin } from '../../Shared/Site/SiteIdMixin';
    import SiteSelectColumn from '../../Shared/SiteSelectColumn.vue';
    import NewClientV2 from './NewClientV2.vue';
    import NewPets from './NewPets.vue';
    import OptionsClient from './OptionsClient.vue';
    import NewClientOnboardingInviteAdd from './NewClientOnboardingInviteAdd.vue';
    import NewClientOnboardingInviteSend from './NewClientOnboardingInviteSend.vue';
    import NewClientPetsSaveButton from './NewClientPetsSaveButton.vue';

    export default {
        name: 'NewClientPets',
        mixins: [SiteIdMixin],
        data() {
            return {
                clientStatus: {},
                petsStatus: {},
                inviteAddStatus: {},
                inviteSendStatus: {},
                addedClientId: '',
                savingType: 0,
                reset: true
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'clientAddedEvent',
                'clientOnboardingInviteAddedEvent'
            ]),
            ...mapState('PetsV2', [
                'addingPet'
            ]),
            ...mapState('Clients', [
                'addingClient'
            ]),
            completed() {
                return !!this.clientStatus.completed && !!this.petsStatus.completed && !!this.inviteAddStatus.completed && !!this.inviteSendStatus.completed;
            },
            loading() {
                return !!this.clientStatus.loading || !!this.petsStatus.loading || !!this.inviteAddStatus.loading || !!this.inviteSendStatus.loading;
            },
            canAddClientPets() {
                return !this.addingClient && !this.addingPet;
            },
            messageSuccessful() {
                if (!!this.savingType) {
                    return 'Client and pet(s) successfully added. Client registration invitation sent.';
                } else {
                    return 'Client and pet(s) successfully added.';
                }
            }
        },
        watch: {
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            },
            completed(value) {
                if (!!value) {
                    this.reset = false;
                }
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerAddClient',
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault'
            ]),
            handleSave() {
                if (!this.canAddClientPets) {
                    return;
                }
                this.triggerAddClient({ savingType: this.savingType });
            },
            handleReset() {
                this.savingType = 0;
                this.addedClientId = '';
                this.reset = true;
            }
        },
        components: {
            'site-select': SiteSelectColumn,
            'new-client': NewClientV2,
            'new-pets': NewPets,
            'client-options': OptionsClient,
            'invite-add': NewClientOnboardingInviteAdd,
            'invite-send': NewClientOnboardingInviteSend,
            'save-button': NewClientPetsSaveButton
        }
    }
</script>
