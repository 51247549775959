<template>
    <input type="text" 
           class="form-control"
           v-model="formattedInput"
           @input="removeSpaces"
           :placeholder="placeholder" />
</template>

<script>
    export default {
        name: 'MobileInput',
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: 'Enter mobile number'
            }
        },
        data() {
            return {
                formattedInput: this.value
            };
        },
        watch: {
            value(newValue) {
                this.formattedInput = newValue.replace(/\s+/g, '');
            }
        },
        methods: {
            removeSpaces() {
                this.formattedInput = this.formattedInput.replace(/\s+/g, '');
                this.$emit('input', this.formattedInput);
            }
        }
    };
</script>
