import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const ClientLogsCacheMixin = {
    data() {
        return {
            error: '',
            filter: ''
        }
    },
    computed: {
        ...mapGetters('ClientLogs', [
            'findClientLogsCache'
        ]),
        clientId() {
            console.log('OVERRIDE COMPUTED - clientId')
            return '';
        },
        clientLogsCache() {
            return this.findClientLogsCache(this.clientId);
        },
        clientLogs() {
            return !!this.clientLogsCache ? this.clientLogsCache.values : [];
        },
        loadingClientLogs() {
            return !!this.clientLogsCache && this.clientLogsCache.loading;
        },
        clientLogFilters() {
            return [...new Set(this.clientLogs.map(l => l.filter).filter(s => !!s))];
        },
        filteredClientLogs() {
            return !!this.filter ? this.clientLogs.filter(l => l.filter === this.filter) : this.clientLogs;
        }
    },
    watch: {
        clientId() {
            this.refreshClientLogs();
        }
    },
    methods: {
        ...mapActions('ClientLogs', [
            'findClientLogs'
        ]),
        tryFindClientLogs() {
            const self = this;
            if (!self.clientId) {
                return;
            }
            self.error = '';
            self.findClientLogs(self.clientId)
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshClientLogs() {
            if (!this.clientLogsCache || !!this.clientLogsCache.retry) {
                this.tryFindClientLogs();
            }
        }
    },
    created() {
        this.refreshClientLogs();
    }
}
