<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>
                    <i class="fa-duotone fa-credit-card fa-1x"></i>
                    {{$t('Billing')}}
                </h1>
            </div>
        </header>
        <div class="content">
            <error-banner v-if="cardError" :message="cardError"></error-banner>
            <div v-if="cardWarning" class="alert alert-warning  alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{ cardWarning }}</p>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span>x</span>
                </button>
            </div>
            <div v-if="cardSuccess" class="alert alert-success alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{ cardSuccess }}</p>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span>x</span>
                </button>
            </div>
            <div class="form-group">
                {{$t('Click the button below to add new card details. These will be used on your next subscription rollover.')}}
            </div>
            <div class="form-group">
                <button @click="gotoCardAdd" class="btn btn-primary">{{$t('Add card')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'PaymentUpdate',
        computed: {
            cardError() {
                return this.$route.query.error;
            },
            cardWarning() {
                return this.$route.query.warning;
            },
            cardSuccess() {
                return this.$route.query.success;
            }
        },
        methods: {
            gotoCardAdd() {
                this.$router.push({ name: 'cardAdd' });
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
