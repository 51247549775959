import axios from 'axios';
import store from '../store';
import router from '../routing';

export const Api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL || '#{Project.Api.Endpoint}'
})

Api.interceptors.request.use(config => {
    const accountSiteId = store.state.Sites.selected.id;
    if (!!accountSiteId) {
        config.headers.AccountSiteId = accountSiteId;
    }
    return config;
});

Api.interceptors.response.use(
    r => r,
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            const path = router.currentRoute.path;
            const query = router.currentRoute.query;
            store.dispatch('Authentication/logout', { rPath: path, rQuery: new URLSearchParams(query) });
        }
        return Promise.reject(error);
    });
