import { SiteCacheMixin } from "../Shared/SiteCacheMixin";

export const UserItemMixin = {
    mixins: [SiteCacheMixin],
    props: {
        user: Object
    },
    computed: {
        firstName() {
            return this.user.firstName;
        },
        lastName() {
            return this.user.lastName;
        },
        startLetter() {
            return this.lastName[0].toUpperCase();
        },
        userEmail() {
            return this.user.email;
        },
        userRole() {
            return this.user.isSiteAdmin ? 'Site Admin' : 'Employee';
        },
        siteIds() {
            return this.user.siteIds;
        },
        sitesString() {
            return this.siteIds.map(id => {
                const site = this.sites.find(s => s.id === id);
                return !!site ? site.name : '';
            }).filter(s => !!s).join(', ');
        }
    },
    methods: {
        onClick() {
            this.$emit('userClicked', this.user);
        },
        tryGetSites() {
            // do nothing
        }
    }
}
