<template>
    <tr>
        <td>{{petName}}</td>
        <td><div class="text-nowrap">{{startDate}}</div></td>
        <td>{{startTime}}</td>
        <td class="text-center"><i :class="statusClass" :title="$t(appointment.status.name)"></i></td>
        <td><div class="text-nowrap">{{createdDate}}</div></td>
        <td @click="onClick" class="text-center"><i class="far fa-search md-trigger pointer" id="" title="View"></i></td>
    </tr>
</template>

<script>
    import { PetCacheMixinV2 } from "../Shared/PetCacheMixinV2";
    import { AppointmentSectionItemMixin } from '../../mixins/Appointments/AppointmentSectionItemMixin';

    export default {
        name: 'ClientAppointmentSectionItem',
        mixins: [PetCacheMixinV2, AppointmentSectionItemMixin],
        computed: {
            clientId() {
                return this.appointment.clientId;
            },
            petId() {
                return this.appointment.petId;
            },
            pet() {
                return this.pets.find(x => x.id === this.petId);
            },
            petName() {
                return !!this.pet ? this.pet.name : 'Unknown';
            }
        }
    }
</script>
