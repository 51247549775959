<template>
    <tr v-bind:class="classType">
        <td>{{datestamp}} {{timestamp}}</td>
        <td class="text-center">{{type}}</td>
        <td v-bind:style="{ color: colour }">{{message}}</td>
    </tr>
</template>

<script>
    import { ClientLogItemMixin } from "../../../mixins/ClientLogs/ClientLogItemMixin";

    export default {
        name: 'LogItemDesktop',
        mixins: [ClientLogItemMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            }
        }
    }
</script>
