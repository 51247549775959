<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" aria-expanded="true" class="accordion-header" data-target="#collapse-product" data-toggle="collapse">
            {{$t('Price')}} ({{currencySymbol}})
            <span v-if="loadingPetProduct" class="inline-loader"></span>
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-product">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="d-flex align-items-center">
                    <i v-if="!!petProduct" class="fa-duotone fa-pen mr-2" title="Custom price was set for this pet."></i>
                    <input v-model="amountInput" @blur="processInput" class="form-control" type="text" style="width: 100%; max-width: 100%">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import { CountryCacheMixin } from '../Shared/Country/CountryCacheMixin.js';
    import { ProductInputMixin } from '../Products/ProductInputMixin.js';
    import { PetProductUploadMixinV2 } from '../../mixins/Products/PetProductUploadMixinV2.js';
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin.js';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'PetProductSection',
        mixins: [PetProductUploadMixinV2, ProductInputMixin, CountryCacheMixin, StatusEmitterMixin],
        props: {
            pet: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'savePetEvent'
            ]),
            petId() {
                return this.pet.id;
            },
            siteBreedId() {
                return this.pet.breedId;
            },
            status() {
                return {
                    loading: this.loadingPetProduct || this.loadingBreedProduct,
                    error: this.error
                }
            }
        },
        watch: {
            savePetEvent() {
                this.tryUploadPetProduct();
            }
        },
        methods: {
            refreshPetProductModel() {
                this.setInitialAmount(this.getDefaultAmount());
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
