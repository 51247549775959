import { Api } from "../../services/Api";


const state = () => ({
    fetchingBookingStatuses: false,
    fetchedBookingStatuses: false,
    bookingStatuses: []
});

const mutations = {
    setFetching(state, fetching) {
        state.fetchingBookingStatuses = !!fetching;
        state.fetchedBookingStatuses = false;
    },
    setStatuses(state, statuses) {
        state.bookingStatuses = statuses;
        state.fetchedBookingStatuses = true;
        state.fetchingBookingStatuses = false;
    }
}

const actions = {
    fetchBookingStatuses({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('Booking/StatusType')
                .then(response => {
                    commit('setStatuses', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
