<template>
    <collapsed-section id="site-location" :title="$t('Location Details')">
        <address-update v-model="addressStatus"></address-update>
        <country-update v-model="countryStatus"></country-update>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateLocationAddress from "./SiteUpdateLocationAddress.vue";
    import SiteUpdateLocationCountry from "./SiteUpdateLocationCountry.vue";

    export default {
        name: 'SiteUpdateLocation',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                addressStatus: {},
                countryStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.addressStatus,
                    this.countryStatus
                ]
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'address-update': SiteUpdateLocationAddress,
            'country-update': SiteUpdateLocationCountry
        }
    }
</script>
