<template>

</template>

<script>
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { EmailTemplateSettingAddMixin } from '../../Communication/Settings/EmailTemplateSettingAddMixin';

    export default {
        name: 'PostRegisterEmailTemplateSetting',
        mixins: [EmailTemplateSettingAddMixin, StatusEmitterMixin],
        computed: {
            canAddEmailTemplateSetting() {
                return !!this.loadedEmailTemplateSettings && !this.emailTemplateSetting && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loadingEmailTemplateSetting,
                    completed: !!this.emailTemplateSetting
                }
            }
        },
        watch: {
            canAddEmailTemplateSetting() {
                this.tryUploadEmailTemplateSetting();
            }
        },
        methods: {
            tryUploadEmailTemplateSetting() {
                if (this.canAddEmailTemplateSetting) {
                    this.tryAddEmailTemplateSetting();
                }
            }
        },
        created() {
            this.tryUploadEmailTemplateSetting();
        }
    }
</script>
