<template>
    <div class="row form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label for="number">{{$t('Notes')}}</label>
        </div>
        <div class="col-md-8">
            <textarea v-model="noteText" class="form-control" rows="3" cols="50" :placeholder="$t('Add notes (optional)')"></textarea>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { NoteUploadMixin } from "../../Shared/Notes/NoteUploadMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AppointmentClientInfoNotes',
        mixins: [NoteUploadMixin],
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ]),
            ownerId() {
                return this.id;
            },
            ownerTypeId() {
                return 2;
            },
            noteTypeId() {
                return 1;
            }
        },
        watch: {
            saveAppointmentEvent() {
                this.tryUploadNote();
            },
            loadingNote(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
