<template>
    <number-input v-model="nonBusinessHoursAmount" :title="$t('Non-Business Hours')">
        <template #tooltip>
            <tooltip>
                <span>{{$t('This setting adjusts the height of non-business hours in the schedule grid.')}}</span>
            </tooltip>
        </template>
    </number-input>
</template>

<script>
    import NumberInput from "../../Shared/Input/NumberInput.vue";
    import Tooltip from "../../Shared/Layout/Tooltip.vue";

    export default {
        name: 'ScheduleHiddenNonBusinessHours',
        props: {
            value: Number
        },
        computed: {
            nonBusinessHoursAmount: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', parseInt(value));
                }
            }
        },
        components: {
            "number-input": NumberInput,
            "tooltip": Tooltip
        }
    }
</script>
