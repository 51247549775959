import { CustomDurationCacheMixin } from "../../mixins/Settings/Site/CustomDurationCacheMixin";

export const AppointmentDurationOptionsMixin = {
    mixins: [CustomDurationCacheMixin],
    data() {
        return {
            options: [
                { value: 15, name: '15 mins' },
                { value: 30, name: '30 mins' },
                { value: 45, name: '45 mins' },
                { value: 60, name: '1 hour' },
                { value: 90, name: '1.5 hours' },
                { value: 120, name: '2 hours' },
                { value: 150, name: '2.5 hours' },
                { value: 180, name: '3 hours' },
                { value: 240, name: '4 hours' },
                { value: 300, name: '5 hours' },
                { value: 360, name: '6 hours' },
                { value: 420, name: '7 hours' },
                { value: 480, name: '8 hours' },
            ]
        }
    },
    computed: {
        combinedOptions() {
            const customOptions = this.customDurations.length > 0
                ? this.customDurations.map(customDuration => {
                    const duration = customDuration.value;
                    const hours = Math.floor(duration / 60);
                    const minutes = duration % 60;
                    return {
                        value: duration,
                        name: duration < 60 ? `${duration} mins`
                            : minutes === 0 ? `${hours} hours`
                                : `${(duration / 60).toFixed(2)} hours`
                    };
                })
                : [];
            return [...this.options, ...customOptions].sort((a, b) => a.value - b.value);;
        }
    },
    methods: {
        getOptionForDuration(duration) {
            const option = this.combinedOptions.find(o => o.value === duration);
            const hours = Math.floor(duration / 60);
            const minutes = duration % 60;

            return option || {
                value: duration,
                name: duration < 60 ? `${duration} mins`
                    : minutes === 0 ? `${hours} hours`
                        : `${(duration / 60).toFixed(2)} hours`
            };
        },
        getOptionNameValue(value) {
            return value.split(' ')[0];
        },
        getOptionNameUnit(value) {
            return value.split(' ')[1];
        }
    }
}
