<template>

</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import { AggregateCalculateMixin } from "../../Shared/Aggregates/AggregateCalculateMixin";

    export default {
        name: 'PostRegisterAggregates',
        mixins: [AggregateCalculateMixin, StatusEmitterMixin],
        computed: {
            canCalculateAggregates() {
                return !!this.aggregateCalculateModelValid && !this.completed;
            },
            status() {
                return {
                    error: this.error,
                    loading: this.loading,
                    completed: this.completed
                }
            }
        },
        watch: {
            canCalculateAggregates(value) {
                if (value) {
                    this.tryCalculateAgggregates();
                }
            }
        },
        created() {
            if (this.canCalculateAggregates) {
                this.tryCalculateAgggregates();
            }
        }
    }
</script>
