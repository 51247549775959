<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Client Notifications')}}</label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <div class="mb-2 d-flex align-items-center">
                    <label class="ps-toggle-switch">
                        <input v-model="isSmsAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : isSmsAppointmentsPartiallyEnabled}"></div>
                        <label class="d-inline-block ml-2">{{$t('SMS Appointment')}}</label>
                    </label>
                    <i ref="sms-appointments-ref" aria-expanded="false" data-target="#sms-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
                </div>
                <div class="collapse" id="sms-appointments">
                    <div class="appointment-notifications-body">
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsCancellationEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Cancellation')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsUpdatedEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Updated')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsReminderEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Reminder')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsConfirmationEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Confirmation')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsThankYouEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Thank You')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isSmsNoShowEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('No Show')}}</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="isSmsMissYouEnabled" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <label class="d-inline-block ml-2">{{$t('Miss You (SMS)')}}</label>
                    </label>
                </div>
                <div class="mb-2 d-flex align-items-center">
                    <label class="ps-toggle-switch">
                        <input v-model="isEmailAppointmentsEnabled" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch" v-bind:class="{'toggle-switch-partial' : isEmailAppointmentsPartiallyEnabled}"></div>
                        <label class="d-inline-block ml-2">{{$t('Email Appointment')}}</label>
                    </label>
                    <i ref="email-appointments-ref" aria-expanded="false" data-target="#email-appointments" data-toggle="collapse" class="far fa-chevron-circle-down appointment-notifications-icon"></i>
                </div>
                <div class="collapse" id="email-appointments">
                    <div class="appointment-notifications-body">
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailCancellationEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Cancellation')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailUpdatedEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Updated')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailReminderEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Reminder')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailConfirmationEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Confirmation')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailThankYouEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('Thank You')}}</label>
                            </label>
                        </div>
                        <div class="mb-2">
                            <label class="ps-toggle-switch">
                                <input v-model="isEmailNoShowEnabled" class="toggle-checkbox" type="checkbox">
                                <div class="toggle-switch"></div>
                                <label class="d-inline-block ml-2">{{$t('No Show')}}</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="ps-toggle-switch">
                        <input v-model="isEmailMissYouEnabled" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <label class="d-inline-block ml-2">{{$t('Miss You (Email)')}}</label>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteNotificationDefaultsSettingUploadMixin } from "../../../mixins/Settings/Site/SiteNotificationDefaultsSettingUploadMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import SlottedToggle from "../../Shared/Input/SlottedToggle.vue";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateAppointmentClient',
        mixins: [SiteNotificationDefaultsSettingUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryUploadSetting();
            }
        },
        components: {
            'toggle-input': SlottedToggle,
            'error-banner': ErrorBanner
        }
    }
</script>
