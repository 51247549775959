import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin"
import { PaymentCheckoutsCacheMixin } from "./PaymentCheckoutsCacheMixin"
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentHelper } from "../../helpers/PaymentHelper";

export const PaymentCheckoutsAddMixin = {
    mixins: [PaymentCheckoutsCacheMixin, CountryCacheMixin],
    data() {
        return {
            providerTypeId: 0,
            amount: 0,
            dueUtc: null,
            checkoutValidator: PaymentHelper.getCheckoutValidator()
        }
    },
    computed: {
        checkoutAddModel() {
            return Object.assign({
                providerTypeId: this.providerTypeId,
                reference: this.checkoutsReference,
                currency: this.currency,
                amount: this.amount
            }, this.dueUtc ? { dueUtc: this.dueUtc } : {});
        },
        checkoutAddModelValid() {
            const model = this.checkoutAddModel;
            return !!this.siteId && !!model.reference && !!model.currency && this.checkoutValidator.isValid;
        }
    },
    methods: {
        ...mapActions('PaymentCheckouts', [
            'addCheckout'
        ]),
        resetCheckoutModel() {
            this.amount = 0;
        },
        tryAddCheckout() {
            const self = this;
            self.checkoutValidator.check(self.checkoutAddModel);
            if (!self.checkoutAddModelValid) {
                return;
            }
            self.error = '';
            self.addCheckout({ key: self.checkoutsCacheKey, payload: self.checkoutAddModel })
                .then(() => self.resetCheckoutModel())
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
