<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="preview" class="image-uploads">
            <ul>
                <li class="active">
                    <button @click="clearUploads" class="btn" type="button">
                        <i class="far fa-times"></i>
                    </button>
                    <figure>
                        <img :src="preview.url" width="120">
                    </figure>
                </li>
            </ul>
        </div>
        <template v-else>
            <div class="file-upload mw-400">
                <label>
                    <span>{{$t('Browse files')}}</span>
                    <input @change="onFilePicked" type="file" accept="image/*" />
                </label>
            </div>
        </template>
    </div>
</template>

<script>
    import { DefaultPetImageUploadMixin } from "../../../mixins/Settings/Site/DefaultPetImageUploadMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { ImageAddMixin } from "../../Shared/Images/ImageAddMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdatePetImageAdd',
        mixins: [ImageAddMixin, DefaultPetImageUploadMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            preview() {
                return this.imagePreviewSet.length > 0 ? this.imagePreviewSet[0] : null;
            },
            status() {
                return {
                    loading: this.loadingImages || this.loadingSetting,
                    error: this.error
                }
            }
        },
        watch: {
            saveSiteEvent() {
                this.tryAddImages();
            }
        },
        methods: {
            afterImagesAdded(images) {
                this.defaultPetImageId = images[0].id;
                this.tryUploadSetting();
                this.defaultPetImageId = '';
                this.clearUploads();
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
