<template>
    <collapsed-section id="site-branding" :title="$t('Branding')">
        <logo-image v-model="logoImageStatus"></logo-image>
        <branding-colours v-model="brandingColoursStatus"></branding-colours>
    </collapsed-section>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import CollapsedSection from "../../Shared/Layout/CollapsedSection.vue";
    import SiteUpdateBrandingColours from "./SiteUpdateBrandingColours.vue";
    import SiteUpdateLogo from "./SiteUpdateLogo.vue";

    export default {
        name: 'SiteUpdateBranding',
        mixins: [StatusEmitterMixin],
        data() {
            return {
                logoImageStatus: {},
                brandingColoursStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.logoImageStatus,
                    this.brandingColoursStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.map(s => s.error).filter(e => !!e).join(' ')
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'logo-image': SiteUpdateLogo,
            'branding-colours': SiteUpdateBrandingColours
        }
    }
</script>
