export const CalendarAppointmentSettingMixin = {
    data() {
        return {
            displayType: 1,
            displayImages: true,
            displayTime: true,
            displayOrders: true
        }
    },
    computed: {
        defaultUserSettingValue() {
            return {
                displayType: 1,
                images: true,
                time: true,
                orders: true
            }
        },
        userSettingKey() {
            return 'CalendarAppointment'
        }
    }
}
