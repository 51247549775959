<template>
    <div class="notification-panel" v-bind:class="{ 'show': show }">
        <div class="notification-panel-header">
            <button @click="pinned=!pinned" class="btn-pin mr-3 d-none d-md-block" v-bind:class="{ 'pinned': isPinned }">
                <i class="fal fa-thumbtack"></i>
                <i class="fas fa-thumbtack"></i>
            </button>
            <button @click="close" class="btn close" type="button">
                <i class="fal fa-times"></i>
            </button>
        </div>
        <div class="notification-panel--inner">
            <notification v-for="notification in notifications" :key="notification.id" :notification="notification"></notification>
            <div v-if="noNotifications" style="text-align: center">
                {{$t('No notifications')}}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { NotificationsViewMixin } from '../Shared/Notifications/NotificationsViewMixin';
    import { UserCacheMixin } from '../Shared/UserCacheMixin';
    import NotificationPanelItem from './NotificationPanelItem.vue';

    export default {
        name: 'NotificationPanel',
        mixins: [NotificationsViewMixin, UserCacheMixin],
        data() {
            return {
                show: false,
                pinned: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'notificationBell'
            ]),
            isPinned() {
                return this.show && this.pinned;
            },
            noNotifications() {
                return this.notifications.length === 0;
            }
        },
        watch: {
            isPinned(value) {
                this.$emit('input', value);
            },
            notificationBell() {
                this.show = true;
                this.tryViewNotifications();
            }
        },
        methods: {
            close() {
                this.show = false;
                this.pinned = false;
            }
        },
        components: {
            'notification': NotificationPanelItem
        }
    }
</script>
