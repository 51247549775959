<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{templateTypeName}}</h1>
            </div>
            <div class="header-nav header-nav-left">
                <a @click="cancel" class="d-block">
                    <button class="mobile-header-btn">
                        <i class="fal fa-chevron-left"></i>
                        <span class="btn-text sr-only">Back</span>
                    </button>
                </a>
            </div>
        </header>
        <div class="content">
            <error-banner v-if="error" :message="error"></error-banner>
            <div class="form-group">
                <label for="title">{{$t('Version')}}</label>
                <div class="bootstrap-select form-control">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                        {{version}}
                    </button>
                    <div class="inner show">
                        <ul class="dropdown-menu">
                            <li v-for="i in versionList"
                                class="dropdown-item pointer"
                                :key="i"
                                @click="selectVersion(i)"
                                v-bind:class="{'active': i === version}">
                                {{i}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <signature-toggle v-model="requiresSignature"></signature-toggle>
            <html-editor v-if="displayWysiwyg" v-model="html" :validator="contractTemplateUpdateValidator"></html-editor>
        </div>
        <div class="fixed-action-btns action-btns d-flex flex-column align-items-start pt-0">
            <tag-info></tag-info>
            <div class="d-flex justify-content-between align-items-center col-12 mt-2 px-0">
                <div>
                    <button @click="tryUpdateContractTemplate" v-bind:disabled="loading" class="btn btn-primary" type="button">
                        {{$t('Save')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                    <button @click="cancel" class="btn btn-outline-secondary" type="button">{{$t('Cancel')}}</button>
                </div>
                <delete-button v-if="contractTemplate" @clicked="remove" v-bind:disabled="loading"></delete-button>
                <toggle-tags></toggle-tags>
            </div>
        </div>
        <button ref="displayButton" type="button" data-target="#delete-template-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="delete-template-modal" :title="$t('Are you sure?')">
            <error-banner v-if="error" :message="error"></error-banner>
            <div role="alert" class="alert alert-warning alert-icon">
                <div class="alert-content">
                    <p> {{$t('Are you sure you want to delete')}} {{templateTypeName}}?</p>
                </div>
                <button v-bind:disabled="loading" @click="tryDeleteContractTemplate" type="button" class="btn btn-danger" style="margin-right: 10px;">
                    <span v-if="loading" class="inline-loader"></span>
                    <template v-else>
                        Yes
                    </template>
                </button>
            </div>
            <div class="modal-footer">
                <button ref="cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('Cancel')}}</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import router from '../../../routing';
    import { ContractTemplateSiteCacheMixin } from '../../Shared/ContractTemplateSiteCacheMixin';
    import { ContractTemplateUpdateMixin } from '../../Shared/ContractTemplateUpdateMixin';
    import SignatureRequiredToggle from './SignatureRequiredToggle.vue';
    import TemplateHtmlWysiwyg from './TemplateHtmlWysiwyg.vue';
    import TemplateTagInfo from './TemplateTagInfo.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import TagsDisplayToggleButton from '../../Shared/Tags/TagsDisplayToggleButton.vue';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import DeleteButton from '../../Shared/DeleteButton.vue';

    export default {
        name: 'ContractUpdate',
        mixins: [ContractTemplateUpdateMixin, ContractTemplateSiteCacheMixin],
        data() {
            return {
                maxVersion: 0
            }
        },
        props: {
            typeId: String
        },
        computed: {
            templateTypeId() {
                return this.typeId;
            },
            displayWysiwyg() {
                return !!this.typeId;
            },
            loading() {
                return this.loadingContractTemplates || this.loadingContractTemplateHtml || this.uploadingContractTemplate || this.loadingContractTemplate;
            },
            contractTemplateForSite() {
                return this.contractTemplatesForSite.find(t => t.templateTypeId === this.templateTypeId);
            },
            latestVersion() {
                return !!this.contractTemplateForSite ? this.contractTemplateForSite.version : 0;
            },
            versionList() {
                const result = [];
                for (var i = this.maxVersion; i > 0; i--) {
                    result.push(i);
                }
                return result;
            }
        },
        watch: {
            contractTemplateUpdatedEvent(contractTemplate) {
                this.setVersion(contractTemplate.version);
            }
        },
        methods: {
            remove() {
                this.$refs.displayButton.click();
            },
            cancel() {
                router.go(-1);
            },
            setVersion(version) {
                this.maxVersion = version;
                this.version = version;
            },
            selectVersion(value) {
                this.version = value;
            },
            afterContractTemplateDelete() {
                this.$refs.cancelButton.click();
                router.go(-1);
            }
        },
        components: {
            'signature-toggle': SignatureRequiredToggle,
            'html-editor': TemplateHtmlWysiwyg,
            'tag-info': TemplateTagInfo,
            'error-banner': ErrorBanner,
            'toggle-tags': TagsDisplayToggleButton,
            'modal-dialog': ModalDialog,
            'delete-button': DeleteButton
        },
        created() {
            if (!!this.typeId) {
                this.setVersion(this.latestVersion);
            } else {
                this.cancel();
            }
        }
    }
</script>
