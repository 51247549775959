<template>
    <div @click="onClick" title="Remove" class="d-flex w-100 justify-content-between checkout-card_discount-item" style="cursor: pointer !important">
        <div class="ml-2 d-flex align-items-center justify-content-center">
            <i class="fa fas fa-tag mr-1"></i>
            <span>{{code}} ({{description}})</span>
        </div>
        <div class="ml-2">-{{amount}}</div>
    </div>
</template>

<script>
    export default {
        name: 'SubscriptionDiscountItem',
        props: {
            discount: {
                type: Object,
                required: true
            }
        },
        computed: {
            code() {
                return this.discount.code;
            },
            description() {
                return this.discount.description;
            },
            amount() {
                return this.discount.amount.toFixed(2);
            }
        },
        methods: {
            onClick() {
                this.$emit('deleted', this.code);
            }
        }
    }
</script>
