import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentApiUserCacheMixin } from "./PaymentApiUserCacheMixin";
import { mapState, mapActions } from 'vuex';

export const PaymentApiUserOwnerUpdateMixin = {
    mixins: [PaymentApiUserCacheMixin],
    computed: {
        ...mapState('PaymentApiUser', [
            'loadingPaymentApiUser'
        ]),
        paymentApiUserOwnerModel() {
            return {
                accountName: this.siteId
            }
        },
        paymentApiUserOwnerModelValid() {
            const model = this.paymentApiUserOwnerModel;
            return !!model.accountName && this.ownerAccounts.indexOf(model.accountName) < 0;
        }
    },
    methods: {
        ...mapActions('PaymentApiUser', [
            'updatePaymentApiUserOwner'
        ]),
        tryUpdatePaymentApiUserOwner() {
            const self = this;
            if (!self.paymentApiUserOwnerModelValid) {
                return;
            }
            self.error = '';
            self.updatePaymentApiUserOwner(self.paymentApiUserOwnerModel)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
