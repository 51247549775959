import { CountryFormatCacheMixin } from "./Country/CountryFormatCacheMixin";

export const MobileValidatorMixin = {
    mixins: [CountryFormatCacheMixin],
    data() {
        return {
            mobileValidation: ''
        }
    },
    computed: {
        mobileRegex() {
            return !!this.countryFormat ? new RegExp(this.countryFormat.mobileFormat) : new RegExp();
        },
        sampleFormat() {
            return !!this.countryFormat ? this.countryFormat.sampleFormat : '';
        }
    },
    methods: {
        validateMobile(mobile) {
            this.mobileValidation = '';
            if (!mobile) {
                return;
            }
            try {
                if (!mobile.match(new RegExp(this.mobileRegex))) {
                    this.mobileValidation = 'Invalid for ' + this.countryName + '. Sample format: ' + this.sampleFormat + '.';
                }
            } catch { }
        }
    }
}
