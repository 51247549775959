<template>
    <div class="form-group row">
        <div class="col-md-4">
            <label for="title">{{$t('Amount')}} ({{currencySymbol}})</label>
        </div>
        <div class="col-md-8">
            <input v-model="amountInput" @blur="processInput" class="form-control" type="number">
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ProductInputMixin } from '../Products/ProductInputMixin.js';
    import { CountryCacheMixin } from '../Shared/Country/CountryCacheMixin.js';
    import { BreedProductsAddMixin } from '../../mixins/Products/BreedProductsAddMixin.js';

    export default {
        name: 'BreedAddProduct',
        mixins: [BreedProductsAddMixin, CountryCacheMixin, ProductInputMixin],
        data() {
            return {
                breed: null
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'breedsAddedEvent'
            ]),
            breedId() {
                return !!this.breed ? this.breed.breedId : '';
            }
        },
        watch: {
            breedsAddedEvent(breeds) {
                this.breed = breeds[0];
                this.siteIds = breeds.map(b => b.siteId);
                this.tryAddBreedProducts();
                this.breed = null;
            }
        },
        methods: {
            tryGroupBreedProducts() {
                // do nothing
            }
        }
    }
</script>
