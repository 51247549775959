import { Api } from '../../services/Api';

const state = () => ({
    workingHourCaches: []
})

const getters = {
    findWorkingHourCache: (state) => key => state.workingHourCaches.find(whc => whc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.workingHourCaches.find(whc => whc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.workingHourCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setWorkingHours(state, { key, workingHours }) {
        const existing = state.workingHourCaches.find(whc => whc.key === key);
        if (!!existing) {
            existing.values = workingHours;
            existing.loading = false;
        } else {
            state.workingHourCaches.push({ key: key, values: workingHours, loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.workingHourCaches.find(whc => whc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendWorkingHour(state, { key, workingHour }) {
        const existing = state.workingHourCaches.find(whc => whc.key === key);
        if (!!existing) {
            existing.values.push(workingHour);
        } else {
            state.workingHourCaches.push({ key: key, values: [workingHour], loading: false });
        }
    },
    insertWorkingHour(state, { key, workingHour }) {
        const existing = state.workingHourCaches.find(whc => whc.key === key);
        if (!!existing) {
            const old = existing.values.find(wh => wh.id === workingHour.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, workingHour);
            } else {
                existing.values.push(workingHour);
            }
        }
    }
}

const actions = {
    findWorkingHours({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('WorkingHours?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setWorkingHours', { key: key, workingHours: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        });
    },
    addWorkingHour({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('WorkingHours', payload)
                .then(response => {
                    commit('appendWorkingHour', { key: key, workingHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateWorkingHour({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('WorkingHours', payload)
                .then(response => {
                    commit('insertWorkingHour', { key: key, workingHour: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
