<template>

</template>

<script>
    import { mapActions } from "vuex";
    import { ErrorHelper } from "../../../helpers/ErrorHelper";
    import { OpeningHoursCacheMixin } from "../../Shared/OpeningHours/OpeningHoursCacheMixin";
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";

    export default {
        name: 'PostRegisterOpeningHours',
        mixins: [OpeningHoursCacheMixin, StatusEmitterMixin],
        computed: {
            fetchedOpeningHours() {
                return !!this.openingHourCache && !this.openingHourCache.retry && !this.loadingOpeningHours;
            },
            openingHoursComplete() {
                return this.openingHours.length > 0;
            },
            canAddOpeningHours() {
                return this.fetchedOpeningHours
                    && !this.openingHoursComplete
                    && !!this.siteId;
            },
            status() {
                return {
                    error: this.error,
                    loading: !!this.loadingOpeningHours,
                    completed: this.openingHoursComplete
                }
            }
        },
        watch: {
            canAddOpeningHours(value) {
                if (!!value) {
                    this.tryAddDefaultOpeningHours();
                }
            }
        },
        methods: {
            ...mapActions('OpeningHours', [
                'addDefaultOpeningHours'
            ]),
            tryAddDefaultOpeningHours() {
                const self = this;
                self.error = '';
                self.addDefaultOpeningHours(self.openingHoursCacheKey)
                    .catch(error => self.error = ErrorHelper.getError(error));
            }
        },
        created() {
            if (this.canAddOpeningHours) {
                this.tryAddDefaultOpeningHours();
            }
        }
    }
</script>
