import { mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { BreedProductsGroupMixin } from './BreedProductsGroupMixin';
import { SiteCacheMixin } from '../../components/Shared/SiteCacheMixin';

export const BreedProductsAddMixin = {
    mixins: [BreedProductsGroupMixin, SiteCacheMixin],
    data() {
        return {
            amount: null,
            siteIds: []
        }
    },
    computed: {
        missingSiteIds() {
            return this.siteIds.filter(id => !this.breedProducts.find(p => p.siteId === id));
        },
        breedProductsAddModel() {
            return {
                breedId: this.breedId,
                amount: this.amount,
                siteIds: this.missingSiteIds
            }
        },
        breedProductsAddModelValid() {
            const model = this.breedProductsAddModel;
            return !!model.breedId && !isNaN(parseFloat(model.amount)) && model.siteIds.length > 0;
        }
    },
    watch: {
        breedId() {
            this.refreshBreedProductsModel();
        },
        sites() {
            this.refreshSiteIds();
        }
    },
    methods: {
        ...mapActions('BreedProductGroups', [
            'addBreedProducts'
        ]),
        tryAddBreedProducts() {
            const self = this;
            if (!self.breedProductsAddModelValid) {
                return;
            }
            self.error = '';
            self.addBreedProducts({ key: self.breedId, payload: self.breedProductsAddModel })
                .then(response => self.afterAddingBreedProducts(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshBreedProductsModel() {
            this.amount = null;
            this.refreshSiteIds();
        },
        refreshSiteIds() {
            this.siteIds = this.sites.map(s => s.id);
        },
        afterAddingBreedProducts() {
            // override if needed
        }
    },
    created() {
        this.refreshBreedProductsModel();
    }
}
