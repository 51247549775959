import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Site/SiteIdMixin";

export const DateFormatOptionsCacheMixin = {
    mixins: [SiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('DateFormatOptions', [
            'dateFormatOptions',
            'fetchingDateFormatOptions',
            'fetchedDateFormatOptions'
        ])
    },
    watch: {
        siteId() {
            this.refreshDateFormatOptions();
        }
    },
    methods: {
        ...mapActions('DateFormatOptions', [
            'getDateFormatOptions'
        ]),
        refreshDateFormatOptions() {
            if (!this.fetchedDateFormatOptions && !this.fetchingDateFormatOptions) {
                this.tryGetDateFormatOptions();
            }
        },
        tryGetDateFormatOptions() {
            const self = this;
            if (!self.siteId) {
                return;
            }
            self.error = '';
            self.getDateFormatOptions()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshDateFormatOptions();
    }
}
