import { mapActions } from "vuex";
import { TimeZoneAddMixin } from "./TimeZoneAddMixin";
import { ErrorHelper } from "../../../../helpers/ErrorHelper";

export const TimeZoneUpdateMixin = {
    mixins: [TimeZoneAddMixin],
    computed: {
        timeZoneUploadModel() {
            return Object.assign({}, this.timeZone, this.timeZoneAddModel);
        }
    },
    watch: {
        timeZone() {
            this.refreshUploadModel();
        }
    },
    methods: {
        ...mapActions('TimeZones', [
            'updateTimeZone'
        ]),
        refreshUploadModel() {
            this.timeZoneId = !!this.timeZone ? this.timeZone.timeZoneId : '';
        },
        uploadAndCacheTimeZone() {
            if (!!this.timeZone) {
                this.tryUpdateTimeZone();
            } else {
                this.tryAddTimeZone();
            }
        },
        tryUpdateTimeZone() {
            const self = this;
            if (!self.timeZoneUploadModel.timeZoneId) {
                return;
            }
            self.error = '';
            if (JSON.stringify(self.timeZone) === JSON.stringify(self.timeZoneUploadModel)) {
                return;
            }
            self.updateTimeZone({ key: self.timeZoneCacheKey, payload: self.timeZoneUploadModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshUploadModel();
    }
}
