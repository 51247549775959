import { mapActions } from "vuex"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { ResourceHelper } from "../../helpers/ResourceHelper"
import { UserResourceCacheMixin } from "./UserResourceCacheMixin";

export const UserResourceAddMixin = {
    mixins: [UserResourceCacheMixin],
    data() {
        return {
            colour: '#FFE781',
            userResourceValidator: ResourceHelper.getResourceValidator()
        }
    },
    computed: {
        user() {
            console.log('!!!OVERRIDE COMPUTED - user!!!');
        },
        userResourceAddModel() {
            return !!this.user ? {
                userId: this.user.id,
                name: this.user.firstName + ' ' + this.user.lastName,
                colour: this.colour
            } : {};
        },
        userResourceAddModelValid() {
            return !!this.userResourceAddModel.userId && this.userResourceValidator.isValid;
        }
    },
    methods: {
        ...mapActions('UserResources', [
            'addUserResources'
        ]),
        tryAddUserResources() {
            const self = this;
            self.userResourceValidator.check(self.userResourceAddModel);
            if (!self.userResourceAddModelValid) {
                return;
            }
            self.error = '';
            self.addUserResources({ id: self.userId, payload: self.userResourceAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
