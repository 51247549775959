<template>
    <section>
        <appointment-title :appointment="appointment"></appointment-title>
        <edit-resource v-model="resourceId" :appointment="appointment"></edit-resource>
        <edit-date v-model="startUtc"></edit-date>
        <edit-duration v-model="duration"></edit-duration>
        <div v-if="conflicts.length === 0 && !loading" class="row form-group">
            <div class="col-md-8">
                <label>
                    {{$t('Conflicts resolved!')}}
                    <i class="far fa-check text-success"></i>
                </label>
            </div>
        </div>
        <appointment-pet v-for="(conflict, index) in conflicts" :key="index" :appointment="conflict"></appointment-pet>
    </section>
</template>

<script>
    import moment from 'moment';
    import EditDateSelect from './EditDateSelect.vue';
    import EditDuration from './EditDuration.vue';
    import EditOverridePet from './EditOverridePet.vue';
    import EditOverrideTitle from './EditOverrideTitle.vue';
    import EditResource from './EditResource.vue';

    export default {
        name: 'EditOverrideItem',
        data() {
            return {
                resourceId: '',
                startUtc: '',
                duration: 30
            }
        },
        props: {
            value: Array,
            conflict: Object,
            loading: Boolean
        },
        computed: {
            conflicts() {
                return this.conflict.conflicts;
            },
            appointment() {
                return this.conflict.appointment;
            },
            overrideModel() {
                return Object.assign({}, this.appointment, {
                    resourceId: this.resourceId,
                    startUtc: this.startUtc,
                    endUtc: moment(this.startUtc).add(this.duration, 'minutes').utc().format()
                })
            },
            overrideModelModified() {
                return JSON.stringify(this.overrideModel) !== JSON.stringify(this.appointment);
            }
        },
        watch: {
            appointment() {
                this.refreshOverrideModel();
            },
            overrideModel() {
                if (this.overrideModelModified) {
                    this.emitInput();
                }
            }
        },
        methods: {
            refreshOverrideModel() {
                this.resourceId = this.appointment.resourceId;
                this.startUtc = this.appointment.startUtc;
                const endUtc = this.appointment.endUtc;
                this.duration = moment.duration(moment(endUtc).diff(moment(this.startUtc))).asMinutes();
            },
            emitInput() {
                const overrides = [...this.value];
                const existing = overrides.find(o => o.petId === this.appointment.petId && o.sequenceNumber === this.appointment.sequenceNumber);
                if (!!existing) {
                    overrides.splice(overrides.indexOf(existing), 1, this.overrideModel);
                } else {
                    overrides.push(this.overrideModel);
                }
                this.$emit('input', overrides);
            }
        },
        components: {
            'appointment-title': EditOverrideTitle,
            'appointment-pet': EditOverridePet,
            'edit-date': EditDateSelect,
            'edit-duration': EditDuration,
            'edit-resource': EditResource
        },
        created() {
            this.refreshOverrideModel();
        }
    }
</script>
