<template>
    <div>
        <div class="d-flex" v-if="subscriptionFeatureAllowed">
            <div class="bootstrap-select form-control custom-select-save">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light" v-bind:disabled="disabled">
                    {{selectedOption.name}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu" style="padding-block: 0;">
                        <li v-for="option in options"
                            class="dropdown-item pointer px-0 text-center"
                            :key="option.value"
                            @click="savingType = option.value"
                            v-bind:class="{'active': option.value === selectedOption.value}">
                            {{option.name}}
                        </li>
                    </ul>
                </div>
            </div>
            <button @click="handleSave" class="btn btn-primary btn-save" type="button" v-bind:disabled="loading">
                <i class="text-white" v-bind:class="savingType === 0 ? 'fal fa-floppy-disk' : 'fal fa-paper-plane'"></i>
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
        <div v-else>
            <button @click="handleSave" class="btn btn-primary" type="button" v-bind:disabled="loading">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { SubscriptionFeatureMixin } from "../../../mixins/Subscriptions/SubscriptionFeatureMixin";

    export default {
        name: 'NewClientPetsSaveButton',
        mixins: [SubscriptionFeatureMixin],
        props: {
            value: Number,
            loading: Boolean,
            addedClientId: ''
        },
        data() {
            return {
                options: [
                    { value: 0, name: 'Save' },
                    { value: 1, name: 'Save & Invite (Email)' },
                    { value: 2, name: 'Save & Invite (SMS)' }
                ]
            }
        },
        computed: {
            savingType: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', parseInt(value));
                }
            },
            computedFeatureKey() {
                return 'clientOnboarding';
            },
            selectedOption() {
                return this.options.find(o => o.value == this.savingType);
            },
            disabled() {
                return !!this.addedClientId;
            }
        },
        methods: {
            handleSave() {
                this.$emit('save')
            }
        },
        created() {
            this.savingType = this.value;
        }
    }
</script>
