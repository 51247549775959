import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

export const RegistrationSettingsMixin = {
    data() {
        return {
            error: '',
            registrationSettings: null,
            loadingRegistrationSettings: false,
            loadedRegistrationSettings: false
        }
    },
    computed: {
        recaptchaEnabled() {
            return !!this.registrationSettings && this.registrationSettings.recaptchaEnabled;
        }
    },
    methods: {
        getRegistrationSettings() {
            const self = this;
            if (!!self.loadedRegistrationSettings) {
                return;
            }
            self.error = '';
            self.loadingRegistrationSettings = true;
            Api.get('Registration/Settings')
                .then(response => {
                    self.registrationSettings = response.data;
                    self.loadedRegistrationSettings = true;
                })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.loadingRegistrationSettings = false);
        }
    },
    created() {
        this.getRegistrationSettings();
    }
}