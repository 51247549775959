import { Api } from '../../services/Api';

const state = () => ({
    bookingSearches: []
})

const getters = {
    findBookingSearch: (state) => key => state.bookingSearches.find(bs => bs.key === key),
    findBookingSearches: (state) => (key) => state.bookingSearches.filter(bs => bs.key.includes(key))
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.bookingSearches.find(bs => bs.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.bookingSearches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setBookingSearch(state, { key, bookings }) {
        const existing = state.bookingSearches.find(bs => bs.key === key);
        if (!!existing) {
            existing.values = bookings;
            existing.loading = false;
        } else {
            state.bookingSearches.push({ key: key, values: bookings, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.bookingSearches.find(bs => bs.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearBookingSearches(state) {
        state.bookingSearches = [];
    }
}

const actions = {
    searchBookings({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Booking/Search', payload)
                .then(response => {
                    commit('setBookingSearch', { key: key, bookings: response.data });
                    commit('Bookings/setBookings', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
